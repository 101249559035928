import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        loading: true,
        sidebar: {
            openKeys: [],
            selectedKeys: [],
        },
    },
    reducers: {
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        },
        setSidebarOpenKeys(state, action) {
            state.sidebar.openKeys = action.payload
        },
        setSidebarSelectedKeys(state, action) {
            state.sidebar.selectedKeys = action.payload
        },
    },
})

export const { startLoading, stopLoading, setSidebarOpenKeys, setSidebarSelectedKeys } = uiSlice.actions

export default uiSlice.reducer
