const ClassificationTypeEnum = Object.freeze({
    BUSINESS_STATUS: { value: '900', text: '業務ステータス区分' },
    CUSTOMER_TITLE: { value: '998', text: '敬称区分' },
    BILL_DATE: { value: '200', text: '請求締日区分' },
    PAYMENT_DATE: { value: '201', text: '支払締日区分' },
    COLLECTION_SCHEDULE_TYPE: { value: '006', text: '回収種別区分' },
    COLLECTION_SCHEDULE_METHOD: { value: '007', text: '回収方法区分' },
    CUSTOMER_INDUSTRY: { value: '001', text: '顧客業類区分' },
    CUSTOMER: { value: '002', text: '顧客区分' },
    ESTIMATION_SENDER: { value: '003', text: '顧客の見積書差出名区分' },
    DELIVERY_NOTE_SENDER: { value: '004', text: '顧客の納品書差出名区分' },
    BILL_SENDER: { value: '005', text: '顧客の請求書差出名区分' },
    PRODUCT_1: { value: '100', text: '商品区分１' },
    PRODUCT_2: { value: '101', text: '商品区分２' },
    PRODUCT_UNIT: { value: '102', text: '商品単位' },
    TAX_RATE: { value: '103', text: '税金区分' },
    PREFECTURE: { value: '999', text: '都道府県' },
    INSTRUCTION: { value: '300', text: '作業指示書区分' },
    IN_HOUSE_PROCESSED: { value: '301', text: '社内加工物区分' },
    OUTSIDE_PROCESSED: { value: '302', text: '社外加工物区分' },
    WORKPIECE_UNIT: { value: '303', text: '加工物単位区分' },
    LAMINATED_PRODUCT_THICKNESS: { value: '304', text: '加工物ラミ厚区分' },
    PROCESSED_PRODUCT_MIMI: { value: '305', text: '加工物ミミ区分' },
    LARGE_FORMAT_PROCESSED_MEDIA: { value: '306', text: '大判加工物メディア区分' },
    SOLVENT_PROCESSED_MEDIA: { value: '307', text: '溶剤加工物メディア区分' },
    PANEL_PROCESSED_MEDIA: { value: '308', text: 'パネル加工物メディア区分' },
    WORKPIECE_PRINTING: { value: '309', text: '加工物印刷区分' },
    WORKPIECE_PAPER: { value: '310', text: '加工物用紙区分' },
    WORKPIECE_SHAPE: { value: '311', text: '加工物形区分' },
    PROCESSED_GOLD: { value: '312', text: '加工物ゴールド区分' },
    WORKPIECE_OVER_LAMINATION: { value: '313', text: '加工物オーバーラミ区分' },
    WORKPIECE_PANEL_THICKNESS: { value: '314', text: '加工物パネル厚区分' },
    CLIMBING_PRODUCT_NAME_CATEGORY: { value: '315', text: 'のぼり商品名区分' },
    CHAIR_COVER_PRODUCT_NAME_CATEGORY: { value: '316', text: 'イスカバー商品名区分' },
    TRIANGLE_FLAG_PRODUCT_NAME_CATEGORY: { value: '317', text: '三角フラッグ商品名区分' },
    PROCESSED_MATERIAL: { value: '318', text: '加工物素材区分' },
    PROCESSED_PRODUCT_CLASS: { value: '319', text: '加工物チチ区分' },
    PROCESSED_PRODUCT_HEAT_CUT: { value: '320', text: '加工物ヒートカット区分' },
    THREE_PIECE_WORKPIECE: { value: '321', text: '加工物三巻区分' },
    WORKPIECE_POCKET: { value: '322', text: '加工物ポケット区分' },
    WORKPIECE_BAG_STOP: { value: '323', text: '加工物袋止め区分' },
    WORKPIECE_CAP: { value: '324', text: '加工物キャップ区分' },
    WORKPIECE_POLE_MATERIAL: { value: '325', text: '加工物ポール素材区分' },
    WORKPIECE_POLE_LENGTH: { value: '326', text: '加工物ポール長さ区分' },
    INSTRUCTION_ARRIVAL_DATE: { value: '390', text: '作業指示書の入荷日の時間帯区分' },
    INSTRUCTION_ARRIVAL_SCHEDULED: { value: '391', text: '作業指示書の着予定日の時間帯区分' },
    INSTRUCTION_NO_INVOICE: { value: '392', text: '作業指示書の送り状NOお知らせ区分' },
    OUTSOURCE_TIMING: { value: '393', text: '発注タイミング' },
    PLACE_ORDER_CLASSIFICATION: { value: '400', text: '加工外注' },
    DELIVERY_TYPE: { value: '902', text: '納入先区分' },
    DELIVERY_CATEGORY_1: { value: '903', text: '届け区分' },
    DELIVERY_CATEGORY_2: { value: '904', text: '配送区分' },
})

export default ClassificationTypeEnum
