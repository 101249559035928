import React from 'react'
import PropTypes from 'prop-types'
import { InputNumber } from 'antd'

const InsInputNumber = ({ onChange, className, withTriangle = false, ...rest }) => {
    let inputClassName = 'ins-input-number '
    if (className) {
        inputClassName += className
    }
    return (
        <InputNumber
            {...rest}
            className={inputClassName}
            formatter={value => `${withTriangle ? '△' : ''}${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={onChange}
            step={0}
            autoComplete="off"
        />
    )
}

export default InsInputNumber

InsInputNumber.propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
    withTriangle: PropTypes.bool,
}
