import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import BusinessPageEnum from 'enums/business_page.enum'
import PropTypes from 'prop-types'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { getEnumText } from 'helpers/enum.helper'
import BusinessStatusEnum from 'enums/business_status.enum'
import { showWarning } from 'helpers/notification.helper'
import InsModal from 'components/InsModal'

const FloatBarButtonDelete = ({ selectedIds, setSelectedIds, documentType, fetchData }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)

    const [checkDeleteDocs, setCheckDeleteDocs] = useState({
        docIds: null,
        canDelete: false,
        data: null,
    })
    const checkCanDelete = async ids => {
        const data =
            documentType === BusinessPageEnum.ESTIMATIONS.value
                ? await api.post(APP_URLS.ESTIMATIONS_CHECK_CAN_DELETE, {
                      doc_ids: ids,
                  })
                : documentType === BusinessPageEnum.INSTRUCTIONS.value
                ? await api.post(APP_URLS.INSTRUCTION_CHECK_CAN_DELETE, {
                      doc_ids: ids,
                  })
                : documentType === BusinessPageEnum.PURCHASE_ORDER.value
                ? await api.post(APP_URLS.PLACE_ORDER_CHECK_CAN_DELETE, {
                      doc_ids: ids,
                  })
                : documentType === BusinessPageEnum.SALES.value
                ? await api.post(APP_URLS.SALE_CHECK_CAN_DELETE, {
                      doc_ids: ids,
                  })
                : documentType === BusinessPageEnum.INVOICES.value
                ? await api.post(APP_URLS.INVOICE_CHECK_CAN_DELETE, {
                      doc_ids: ids,
                  })
                : documentType === BusinessPageEnum.RED_SLIPS.value
                ? await api.post(APP_URLS.RED_SLIP_CHECK_CAN_DELETE, {
                      doc_ids: ids,
                  })
                : null
        if (data) {
            setCheckDeleteDocs({
                docIds: ids,
                canDelete: data.can_delete,
                data: data.data,
            })
        }
    }

    const handleDelete = () => {
        checkCanDelete(selectedIds).then(() => {})
    }

    const bulkDeleteDocs = async ids => {
        const url =
            documentType === BusinessPageEnum.ESTIMATIONS.value
                ? APP_URLS.ESTIMATIONS_BULK_DELETE
                : documentType === BusinessPageEnum.INSTRUCTIONS.value
                ? APP_URLS.INSTRUCTION_BULK_DELETE
                : documentType === BusinessPageEnum.PURCHASE_ORDER.value
                ? APP_URLS.PLACE_ORDER_BULK_DELETE
                : documentType === BusinessPageEnum.SALES.value
                ? APP_URLS.SALE_BULK_DELETE
                : documentType === BusinessPageEnum.INVOICES.value
                ? APP_URLS.INVOICE_BULK_DELETE
                : documentType === BusinessPageEnum.RED_SLIPS.value
                ? APP_URLS.RED_SLIP_BULK_DELETE
                : ''
        await api.post(url, { doc_ids: ids })
        setSelectedIds([])
        fetchData()
    }

    // handle Delete
    const handleDeleteButton = () => {
        if (isProcessing) return
        // start process
        setIsProcessing(true)
        handleDelete()
    }

    useEffect(() => {
        if (!checkDeleteDocs.canDelete && checkDeleteDocs.data) {
            let text = ''
            let arr = checkDeleteDocs.data
            arr.forEach(doc => {
                // estimation
                if (documentType === BusinessPageEnum.ESTIMATIONS.value) {
                    if (doc.project && doc.project.status === BusinessStatusEnum.ORDER_FAILED.value) {
                        text += `${doc.estimation_code} : ${getEnumText(BusinessStatusEnum, doc.status)} (${
                            doc.project.project_code
                        } : ${getEnumText(BusinessStatusEnum, doc.project.status)})\n`
                    } else {
                        text += `${doc.estimation_code} : ${getEnumText(BusinessStatusEnum, doc.status)}\n`
                    }
                } else if (documentType === BusinessPageEnum.INSTRUCTIONS.value) {
                    text += `${doc.instruction_code} : ${getEnumText(BusinessStatusEnum, doc.status)}\n`
                } else if (documentType === BusinessPageEnum.PURCHASE_ORDER.value) {
                    text += `${doc.place_order_code} : ${getEnumText(BusinessStatusEnum, doc.status)}\n`
                } else if (documentType === BusinessPageEnum.SALES.value) {
                    text += `${doc.sale_code} : ${getEnumText(BusinessStatusEnum, doc.status)}\n`
                } else if (documentType === BusinessPageEnum.INVOICES.value) {
                    text += `${doc.invoice_code} : ${getEnumText(BusinessStatusEnum, doc.status)}\n`
                } else if (documentType === BusinessPageEnum.RED_SLIPS.value) {
                    text += `${doc.red_slip_code} : ${getEnumText(BusinessStatusEnum, doc.status)}\n`
                }
            })
            showWarning('', <pre>{text}</pre>)
            // end process
            setIsProcessing(false)
        }
    }, [checkDeleteDocs])

    const deleteLabel = () => {
        if (documentType === BusinessPageEnum.ESTIMATIONS.value) {
            return t('見積書削除')
        } else if (documentType === BusinessPageEnum.INSTRUCTIONS.value) {
            return t('作業指示書削除')
        } else if (documentType === BusinessPageEnum.PURCHASE_ORDER.value) {
            return t('発注書削除')
        } else if (documentType === BusinessPageEnum.SALES.value) {
            return t('納品書削除')
        } else if (documentType === BusinessPageEnum.INVOICES.value) {
            return t('請求書削除')
        } else if (documentType === BusinessPageEnum.RED_SLIPS.value) {
            return t('赤伝削除')
        }
    }

    return (
        <>
            <InsModal
                title={t('common:Delete')}
                visible={checkDeleteDocs.canDelete}
                onCancel={() => {
                    setCheckDeleteDocs({
                        docIds: null,
                        canDelete: false,
                        data: null,
                    })
                    // end process
                    setIsProcessing(false)
                }}
                onOk={async () => {
                    if (checkDeleteDocs.docIds.length > 0) {
                        await bulkDeleteDocs(checkDeleteDocs.docIds)
                        setCheckDeleteDocs({
                            docIds: null,
                            canDelete: false,
                            data: null,
                        })
                    }
                    // end process
                    setIsProcessing(false)
                }}
                okText="はい"
                cancelText="いいえ"
            >
                <h2>{t('common:Are you sure')}</h2>
            </InsModal>
            <InsButton
                icon={<DeleteIcon size={16} className="color-pantone-213-c" />}
                buttonStyle="default"
                className="mr-8"
                style={{ minWidth: 112 }}
                onClick={handleDeleteButton}
            >
                {deleteLabel()}
            </InsButton>
        </>
    )
}

export default FloatBarButtonDelete

FloatBarButtonDelete.propTypes = {
    selectedIds: PropTypes.array,
    setSelectedIds: PropTypes.func,
    documentType: PropTypes.any,
    fetchData: PropTypes.func,
}
