export const APP_URLS = {
    // eslint-disable-next-line no-undef
    BASE_URL: process.env.REACT_APP_BASE_URL,

    UTIL_POST_CODE: '/util/post-code',
    UTIL_CONVERT_KANA: 'util/convert-kana?value=:value',

    // Auth
    LOGIN: '/login',
    LOGOUT: '/logout',
    AUTH: '/auth',
    CHANGE_PASSWORD: '/change-password',
    // MASTER
    USERS: '/accounts',
    USERS_DETAIL: 'accounts/:id',
    USERS_CHECK_EXISTS_PICCODE: 'accounts/check-pic-code',
    USERS_CHECK_EXISTS_USERNAME: 'accounts/check-username',

    TEAMS: '/teams',
    TEAMS_DETAIL: '/teams/:id',
    LIST_ACCOUNT: 'list-accounts',
    CLASSIFICATIONS: '/classifications',
    CLASSIFICATIONS_DETAIL: '/classifications/:id',
    OUTSOURCES: '/outsources',
    OUTSOURCES_DETAIL: '/outsources/:id',
    OUTSOURCES_CHECK_EXISTS_CODE: '/outsources/check-exists-code',

    CUSTOMERS: '/customers',
    CUSTOMERS_DETAIL: '/customers/:id',
    CUSTOMERS_CHECK_EXISTS_CODE: '/customers/check-exists-code',

    PRODUCTS: '/products',
    PRODUCT_CHECK_CODE: '/products/check-exists-code',
    PRODUCTS_DETAIL: '/products/:id',
    PRODUCTS_SEARCH: '/products/search',
    PRODUCT_COSTS: '/product-costs',
    PRODUCT_COSTS_DETAIL: '/product-costs/:id',
    SUPPLIER: 'suppliers',

    SUPPLIERS: '/suppliers',
    SUPPLIERS_DETAIL: '/suppliers/:id',
    SUPPLIERS_CHECK_EXISTS_CODE: '/suppliers/check-exists-code',

    SHIPPING_ADDRESS: '/shipping_addresses',
    SHIPPING_ADDRESS_DETAIL: '/shipping_addresses/:id',
    SHIPPING_CHECK_EXISTS_CODE: '/shipping_addresses/check-exists-code',

    CLIENTS: '/clients',
    CLIENTS_DETAIL: '/clients/:id',
    CLIENTS_CHECK_EXISTS_CODE: '/clients/check-exists-code',

    STOCKS: '/stocks',
    STOCKS_DETAIL: '/stocks/:id',
    STOCKS_CHECK_OUT_OF_STOCK: '/stocks/check-out-of-stock',

    INSTRUCTION: '/instructions',
    INSTRUCTION_DETAIL: '/instructions/:id',
    INSTRUCTION_BY_TYPE: '/instructions/:projectId/:instructionType',
    INSTRUCTION_UPDATE_STATUS: '/instructions/update-status',
    INSTRUCTION_CHECK_EXISTS_CODE: '/instructions/check-exists-code',
    SHIPPERS: '/shippers',
    INSTRUCTION_CHECK_CAN_DELETE: '/instructions/check-can-delete',
    INSTRUCTION_BULK_DELETE: '/instructions/bulk-delete',

    PROJECT: '/projects',
    PROJECT_RANDOM_CODE: '/projects/random-code',
    PROJECT_DETAIL: '/projects/:id',
    PROJECT_UPDATE_STATUS: '/projects/update-status',
    PROJECT_ROLLBACK_STATUS: '/projects/rollback-status',
    PROJECT_CLONE_ESTIMATION: '/projects/clone-estimation',
    PROJECT_CLONE_INSTRUCTION_INTERNAL: '/projects/clone-instruction-internal',
    PROJECT_CLONE_INSTRUCTION_OUTSOURCE: '/projects/clone-instruction-outsource',
    PROJECT_CLONE_PLACE_ORDER: '/projects/clone-place-order',
    PROJECT_CLONE_SALE: '/projects/clone-sale',
    PROJECT_UPDATE_LOCK: '/projects/locked',
    PROJECT_CHECK_CAN_DELETE: '/projects/check-can-delete',
    ESTIMATIONS: '/estimations',
    ESTIMATIONS_DETAIL: '/estimations/:id',
    ESTIMATIONS_CHECK_EXISTS_CODE: '/estimations/check-exists-code',
    ESTIMATIONS_UPDATE_STATUS: '/estimations/update-status',
    ESTIMATIONS_BY_PROJECT: '/estimations/project/:projectId',
    ESTIMATIONS_CHECK_CAN_DELETE: '/estimations/check-can-delete',
    ESTIMATIONS_BULK_DELETE: '/estimations/bulk-delete',

    PLACE_ORDER: '/place-orders',
    PLACE_ORDER_DETAIL: '/place-orders/:id',
    PLACE_ORDER_BY_PROJECT: '/place-orders/project/:projectId',
    PLACE_ORDER_CHECK_EXISTS_CODE: '/place-orders/check-exists-code',
    PLACE_ORDER_UPDATE_STATUS: 'place-orders/update-status',
    PLACE_ORDER_CHECK_CAN_DELETE: '/place-orders/check-can-delete',
    PLACE_ORDER_BULK_DELETE: '/place-orders/bulk-delete',

    INVOICE: '/invoices',
    INVOICE_DETAIL: '/invoices/:id',
    INVOICE_BY_PROJECT: '/invoices/project/:projectId',
    INVOICE_COUNT_PROJECT: '/invoices/:projectId/count-project',
    INVOICE_GET_SALES: '/invoices/get-sales',
    INVOICE_CHECK_EXISTS_CODE: '/invoices/check-exists-code',
    INVOICE_UPDATE_STATUS: '/invoices/update-status',
    INVOICE_CHECK_CAN_DELETE: '/invoices/check-can-delete',
    INVOICE_BULK_DELETE: '/invoices/bulk-delete',
    SUMMARY_INVOICE: '/summary-invoices',
    SUMMARY_INVOICE_REISSUE: '/summary-invoices/re-issue-invoices',

    SALES: '/sales',
    SALES_DETAIL: '/sales/:id',
    SALES_CHECK_EXISTS_CODE: '/sales/check-exists-code',
    SALES_UPDATE_STATUS: '/sales/update-status',
    SALE_BY_PROJECT: 'sales/project/:projectId',
    SALE_CHECK_CAN_DELETE: '/sales/check-can-delete',
    SALE_BULK_DELETE: '/sales/bulk-delete',

    RED_SLIP: '/red-slips',
    RED_SLIP_DETAIL: '/red-slips/:id',
    RED_SLIP_BY_PROJECT: '/red-slips/project/:projectId',
    RED_SLIP_CHECK_EXISTS_CODE: '/red-slips/check-exists-code',
    RED_SLIP_UPDATE_STATUS: '/red-slips/update-status',
    RED_SLIP_CHECK_CAN_DELETE: '/red-slips/check-can-delete',
    RED_SLIP_BULK_DELETE: '/red-slips/bulk-delete',

    TRACKING: '/trackings',
    TRACKING_DETAIL: '/trackings/:id',
    TRACKING_UPDATE_STATUS: '/trackings/update-status',

    COMPANIES: '/companies',
    COMPANIES_DETAIL: '/companies/:id',
    COMPANIES_DETAIL_BY_CODE: '/companies/by-code/:code',
    GENERATE_PASSWORD: '/random-password',

    SHIPMENTS: '/shipments',
    SHIPMENTS_OUTGOING: 'shipments/outgoing',
    SHIPMENTS_OUTGOING_DETAIL: 'shipments/outgoing/:id',

    PAYMENTS: '/payments',
    PAYMENTS_COLLECT: '/payments/collect',
    PAYMENTS_TRANSFER: '/payments/transfer',
    PAYMENTS_EXPORT: '/payments/export-collect-money',

    REPORTS: '/reports',

    ACTION_LOGS: '/action-logs',
}
