import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import { AddIcon, CloseIcon, EditIcon } from 'components/InsIcon'
import Routes from 'routes'
import InsTextArea from 'components/InsTextArea'
import ProductSelect from 'modules/master/product/ProductSelect'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import InsInputNumber from 'components/InsInputNumber'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import InsModal from 'components/InsModal'

const StockForm = ({ isEdit, initialValues = {}, onSubmit, modalConfirmSave, setModalConfirmSave }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    if (!checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => onSubmit(form.getFieldsValue(), null, false)}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className="ins-form pt-0"
                layout="vertical"
                form={form}
                size={'middle'}
                onFinish={values => onSubmit(values)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <Row>
                    <Col>
                        <Form.Item
                            name="product_id"
                            label={t('entity:Product')}
                            rules={[
                                {
                                    // validateTrigger: 'onBlur',
                                    required: true,
                                    message: t('validation:Please enter {{item}}', { item: t('entity:Product') }),
                                },
                            ]}
                        >
                            <ProductSelect inStock={true} record={initialValues.product} style={{ width: 280 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="remain_quantity"
                            label={t('column:Remain quantity')}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter {{item}}', {
                                        item: t('column:Remain quantity'),
                                    }),
                                },
                                {
                                    type: 'number',
                                    message: t('validation:Please enter remain quantity in number'),
                                },
                            ]}
                        >
                            <InsInputNumber className="no-spinner" style={{ width: 104 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item
                            name="note"
                            label={t('column:Note')}
                            className="mb-80"
                            rules={[
                                { max: 3000, message: t('validation:{{item}} is invalid', { item: t('column:Note') }) },
                            ]}
                        >
                            <InsTextArea style={{ width: 368, height: 135 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <InsFloatBar visible={true} className="with-sidebar">
                    <Row gutter={8} justify="end">
                        <Col>
                            <InsButton
                                buttonStyle="outline"
                                buttonSize="big"
                                centered
                                width="120px"
                                icon={<CloseIcon />}
                                linkTo={Routes.private.master.STOCK_LIST.path}
                                buttonType="link"
                            >
                                {t('common:Cancel')}
                            </InsButton>
                        </Col>
                        <Col>
                            {isEdit ? (
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<EditIcon size={20} />}
                                    isSubmitButton={true}
                                >
                                    {t('navigation:{{entity}} update new', { entity: t('entity:Stock') })}
                                </InsButton>
                            ) : (
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<AddIcon />}
                                    isSubmitButton={true}
                                >
                                    {t('navigation:{{entity}} create new', { entity: t('entity:Stock') })}
                                </InsButton>
                            )}
                        </Col>
                    </Row>
                </InsFloatBar>
            </Form>
        </>
    )
}

export default StockForm

StockForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
}
