import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import PlaceOrderForm from 'modules/business/placeOrder/form/PlaceOrderForm'
import { setUser } from 'modules/common/auth/auth.slice'
import { InsBlockLoader } from 'components/InsLoader'
import { setDefaultState } from 'modules/business/placeOrder/place_order_detail.slice'

const PlaceOrderUpdate = ({ project, placeOrder, updateLink, setUser, currentUser, setDefaultState }) => {
    const [loading, setLoading] = useState(false)
    const updatePlaceOrder = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.put(APP_URLS.PLACE_ORDER_DETAIL.replace(':id', placeOrder.id), values)
        if (data) {
            setUser({
                ...currentUser,
                processing_product: data.processing_product,
                processing_spec: data.processing_spec,
                processing_size: data.processing_size,
            })
            updateLink(redirectTo).then(() => {
                setLoading(false)
            })
            setDefaultState()
        }
    }

    return (
        <div className="p-24">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <PlaceOrderForm
                    isEdit={true}
                    project={project}
                    initialValues={placeOrder}
                    onSubmit={(values, redirectTo) => updatePlaceOrder(values, redirectTo)}
                />
            )}
        </div>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUser,
    setDefaultState,
}

export default connect(mapState, mapDispatch)(PlaceOrderUpdate)

PlaceOrderUpdate.propTypes = {
    project: PropTypes.object.isRequired,
    placeOrder: PropTypes.object.isRequired,
    updateLink: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
    setDefaultState: PropTypes.func,
}
