import React from 'react'
import { Col, Row, Select } from 'antd'
import InsSelect from 'components/select/InsSelect'
import { AddIcon, ArrowDropdownIcon, SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'

const SelectDemo = () => {
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">InsSelect</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsSelect defaultValue="0" onSelect={value => console.log(value)} style={{ width: '200px' }}>
                        <Select.Option value="0">Default Style</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        placeholder="With placeholder"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                    >
                        <Select.Option value="0">Option 0</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        defaultValue="0"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        suffixIcon={<ArrowDropdownIcon size={16} />}
                    >
                        <Select.Option value="0">Small dropdown</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        defaultValue="0"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        suffixIcon={<SearchIcon size={16} />}
                    >
                        <Select.Option value="0">With suffix icon</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        defaultValue="0"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        disabled
                    >
                        <Select.Option value="0">Disabled</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsSelect
                        placeholder="Small size"
                        selectSize="small"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                    >
                        <Select.Option value="0">Option 0</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        placeholder="Medium size"
                        selectSize="medium"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                    >
                        <Select.Option value="0">Medium size</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        placeholder="Big size"
                        selectSize="big"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                    >
                        <Select.Option value="0">Big size</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsSelect
                        placeholder="With search"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        searchOptions={true}
                    >
                        <Select.Option value="Option 0">Option 0</Select.Option>
                        <Select.Option value="Option 1">Option 1</Select.Option>
                        <Select.Option value="Option 2">Option 2</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        placeholder="Remote search"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        searchOptions={{
                            url: 'http://faker.vdemo.xyz/users?query=',
                            text: 'firstName',
                        }}
                    />
                </Col>
                <Col>
                    <InsSelect
                        placeholder="Custom dropdown render"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        dropdownMatchSelectWidth={false}
                        searchOptions={{
                            url: 'http://faker.vdemo.xyz/users?len=4&query=',
                            itemRenderer: (item, index) => {
                                return (
                                    <Select.Option key={index} value={index} label={'xxx'}>
                                        <strong>
                                            {item.firstName} {item.lastName}
                                        </strong>{' '}
                                        ({item.email}, {item.phone})
                                    </Select.Option>
                                )
                            },
                        }}
                    />
                </Col>
                <Col>
                    <InsSelect
                        placeholder="Custom selected render"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        dropdownMatchSelectWidth={false}
                        optionLabelProp="label"
                        searchOptions={{
                            url: 'http://faker.vdemo.xyz/users?len=4&query=',
                            itemRenderer: (item, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={index}
                                        label={<span style={{ color: 'red' }}>{item.email}</span>}
                                    >
                                        <strong>
                                            {item.firstName} {item.lastName}
                                        </strong>{' '}
                                        ({item.email}, {item.phone})
                                    </Select.Option>
                                )
                            },
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsSelect
                        placeholder="With add button"
                        onSelect={value => console.log(value)}
                        style={{ width: '200px' }}
                        dropdownMatchSelectWidth={false}
                        dropdownSuffix={
                            <InsButton
                                buttonStyle="default"
                                className="color-pantone-2738-c w-100p"
                                buttonSize="big"
                                icon={<AddIcon size={24} />}
                                onClick={e => {
                                    e.stopPropagation()
                                    console.log('新規顧客作成 clicked')
                                }}
                            >
                                新規顧客作成
                            </InsButton>
                        }
                        searchOptions={{
                            url: 'http://faker.vdemo.xyz/users?len=4&query=',
                            itemRenderer: (item, index) => {
                                return (
                                    <Select.Option key={index} value={index}>
                                        {item.firstName} {item.lastName} - {item.email}
                                    </Select.Option>
                                )
                            },
                        }}
                    />
                </Col>
                <Col>
                    <InsSelect
                        placeholder="Multiple select with a very long placeholder"
                        mode="multiple"
                        onSelect={value => console.log(value)}
                        style={{ width: '300px' }}
                    >
                        <Select.Option value="0">This is also a very very very long option too</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                        <Select.Option value="3">Option 3</Select.Option>
                        <Select.Option value="4">Option 4</Select.Option>
                        <Select.Option value="5">Option 5</Select.Option>
                        <Select.Option value="6">Option 6</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <InsSelect
                        placeholder="Multiple select with a very long placeholder"
                        mode="multiple"
                        selectSize="big"
                        onSelect={value => console.log(value)}
                        style={{ width: '300px' }}
                    >
                        <Select.Option value="0">Option 0</Select.Option>
                        <Select.Option value="1">Option 1</Select.Option>
                        <Select.Option value="2">Option 2</Select.Option>
                        <Select.Option value="3">Option 3</Select.Option>
                        <Select.Option value="4">Option 4</Select.Option>
                        <Select.Option value="5">Option 5</Select.Option>
                        <Select.Option value="6">Option 6</Select.Option>
                    </InsSelect>
                </Col>
            </Row>
        </div>
    )
}

export default SelectDemo
