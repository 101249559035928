import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import moment from 'moment'

const name = 'project'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.PROJECT, ...props })
)

const dateFormatStart = 'YYYY/MM/DD 00:00:01'
const dateFormatEnd = 'YYYY/MM/DD 23:59:59'

const projectSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: {
            'status': '0',
            'created_at:gte': moment().subtract(2, 'months').format(dateFormatStart),
            'created_at:lte': moment().format(dateFormatEnd),
        },
        detailSearch: {
            // 'estimation|delivery_date:gte': '',
            // 'estimation|delivery_date:lte': '',
            // 'estimation|total:gte': null,
            // 'estimation|total:lte': null,
        },
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setSorter,
    setSelection,
} = projectSlice.actions

export default projectSlice.reducer
