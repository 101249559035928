import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import EstimationForm from 'modules/business/estimation/form/EstimationForm'
import { setUser } from 'modules/common/auth/auth.slice'
import { InsBlockLoader } from 'components/InsLoader'
import { setDefaultState } from 'modules/business/estimation/estimation_detail.slice'

const EstimationUpdate = ({ estimation, response, setUser, currentUser, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const updateEstimation = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.put(APP_URLS.ESTIMATIONS_DETAIL.replace(':id', estimation.id), values)
        if (data) {
            setUser({
                ...currentUser,
                processing_product: data.processing_product,
                processing_spec: data.processing_spec,
                processing_size: data.processing_size,
            })
            response(redirectTo).then(() => {
                setLoading(false)
            })
            dispatch(setDefaultState())
        }
    }

    return (
        <>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <EstimationForm
                    isEdit={true}
                    initialValues={estimation}
                    onSubmit={(values, redirectTo) => updateEstimation(values, redirectTo)}
                    {...rest}
                />
            )}
        </>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUser,
}

export default connect(mapState, mapDispatch)(EstimationUpdate)

EstimationUpdate.propTypes = {
    estimation: PropTypes.object.isRequired,
    response: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
}
