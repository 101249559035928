import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import UserForm from 'modules/master/user/form/UserForm'
import Routes from 'routes'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import { InsBlockLoader } from 'components/InsLoader'

const UserCreateComponent = ({ inModal = false, onCreated, onCancel }) => {
    const [loading, setLoading] = useState(false)

    const createUser = async values => {
        setLoading(true)
        const data = await api.post(APP_URLS.USERS, values)
        if (data) {
            if (inModal) {
                onCreated && onCreated(data)
            } else {
                history.push(Routes.private.master.USER_LIST.path)
            }
        }
        setLoading(false)
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    if (!checkAdmin) {
        return <NoPermission />
    }
    return loading ? (
        <InsBlockLoader />
    ) : (
        <UserForm
            modalConfirmSave={modalConfirmSave}
            setModalConfirmSave={setModalConfirmSave}
            inModal={inModal}
            onSubmit={(values, redirectTo, confirmSave = true) => {
                if (confirmSave) setModalConfirmSave(true)
                else {
                    createUser(values, redirectTo, confirmSave)
                    setModalConfirmSave(false)
                }
            }}
            onCancel={() => onCancel()}
        />
    )
}

export default UserCreateComponent

UserCreateComponent.propTypes = {
    inModal: PropTypes.bool,
    onCreated: PropTypes.func,
    onCancel: PropTypes.func,
}

UserCreateComponent.defaultProps = {
    inModal: false,
}
