import React from 'react'
import PropTypes from 'prop-types'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { formatNumber } from 'helpers/number.helper'

const DetailRow = ({ detail }) => {
    const isHeadline = !!detail.headline || !detail.unit

    const renderHeadline = () => {
        return <div className="detail-row-headline">{detail.headline}</div>
    }

    const renderDetail = () => {
        return (
            <div className="detail-row">
                <div className="detail-row-wrap">
                    <div style={{ flex: '0 0 20px' }} className="line-absolute" />
                    <div style={{ flex: 184 }}>{detail.title}</div>
                    <div style={{ flex: 182 }}>{detail.spec}</div>
                    <div style={{ flex: 134 }}>{detail.size}</div>
                    <div style={{ flex: '0 0 55px' }}>{formatNumber(detail.quantity)}</div>
                    <div style={{ flex: '0 0 63px' }}>
                        {getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, detail.unit)}
                    </div>
                    <div style={{ flex: '0 0 81px' }}>{formatNumber(detail.unit_cost)}</div>
                    <div style={{ flex: '0 0 61px' }}>{formatNumber(detail.unit_price)}</div>
                    <div style={{ flex: '0 0 77px' }}>{`${detail.tax_rate}%`}</div>
                    <div style={{ flex: '0 0 65px' }}>{formatNumber(detail.amount)}</div>
                    <div style={{ flex: '0 0 24px' }} />
                </div>
            </div>
        )
    }

    return isHeadline ? renderHeadline() : renderDetail()
}

export default DetailRow

DetailRow.propTypes = {
    detail: PropTypes.object.isRequired,
}
