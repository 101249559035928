import moment from 'moment'

export const dateFormat = x => (x ? moment(x).format('YYYY/MM/DD') : '')

// export const isValidDate = (value, format) => {
//     return moment(value, format).isValid()
// }

// export const isValidDate = (value, format) => {
//     return moment(value, format).format(format) === value
// }

export const dateToStr = (str, format) => (str ? moment(str).format(format || 'YYYY年M月DD日') : '')
