import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row } from 'antd'
import InsButton from 'components/InsButton'
import InsDragAndDrop from 'components/draggable/InsDragAndDrop'
import { AddIcon, ReportProblemIcon, TruckIcon, UnfoldLessIcon, UnfoldMoreIcon } from 'components/InsIcon'
import InsDragAndDropGroup from 'components/draggable/InsDragAndDropGroup'
import InstructionDestinationRow from 'modules/business/instruction/form/draggable/InstructionDestinationRow'
import InstructionRow from 'modules/business/instruction/form/draggable/InstructionDetailRow'
import {
    addDestinations,
    moveDestination,
    moveDetail,
    removeDestination,
    removeDetail,
    setCollapse,
} from 'modules/business/instruction/instruction_detail.slice'

const InstructionDestinationDragAndDrop = React.memo(
    ({ destinations, destinationsOrder, moveDestination, removeDestination }) => {
        return (
            <div className="ins-drag-drop">
                <div className="ins-drag-drop-header">
                    <div className="ml-32" style={{ flex: '0 0 20px' }} />
                    <div style={{ flex: 254 }}>納入先</div>
                    <div style={{ flex: 440 }}>住所</div>
                    <div style={{ flex: 110 }}>直送先担当者</div>
                    <div style={{ flex: 60 }}>敬称</div>
                    <div style={{ flex: '0 0 24px' }} />
                </div>
                <div className="ins-draggable-body">
                    <InsDragAndDrop
                        data={destinations}
                        order={destinationsOrder}
                        onOrderChange={(srcIndex, desIndex) => moveDestination({ srcIndex, desIndex })}
                        onRemove={id => destinationsOrder.length > 1 && removeDestination({ id })}
                        rowComponent={InstructionDestinationRow}
                    />
                </div>
            </div>
        )
    }
)

const InstructionDetailDragAndDrop = React.memo(
    ({
        details,
        detailsOrder,
        destinations,
        destinationsOrder,
        moveDetail,
        removeDetail,
        optDeliveryDestination,
        setCollapse,
    }) => {
        return (
            <div className="ins-drag-drop">
                <div className="ins-drag-drop-header">
                    <div style={{ flex: '0 0 20px' }} />
                    <div style={{ flex: 180 }}>タイトル</div>
                    <div style={{ flex: 174 }}>仕様</div>
                    <div style={{ flex: 116 }}>サイズ</div>
                    <div className="text-center" style={{ flex: '0 0 74px' }}>
                        数量
                    </div>
                    <div className="text-center" style={{ flex: '0 0 38px' }}>
                        単位
                    </div>
                    <div className="text-center" style={{ flex: '0 0 90px' }}>
                        原価
                    </div>
                    <div className="text-center" style={{ flex: '0 0 84px' }}>
                        単価
                    </div>
                    <div className="text-center" style={{ flex: '0 0 66px' }}>
                        税区分
                    </div>
                    <div className="text-center" style={{ flex: '0 0 84px' }}>
                        金額
                    </div>
                    <div style={{ flex: '0 0 24px' }} />
                    <div style={{ flex: '0 0 24px' }} />
                </div>
                <div className="ins-draggable-body">
                    <InsDragAndDropGroup
                        data={details}
                        order={detailsOrder}
                        groupOrder={destinationsOrder}
                        onOrderChange={(srcIndex, desIndex, srcId, desId) =>
                            moveDetail({ srcIndex, desIndex, srcId, desId })
                        }
                        onRemove={(detailId, destinationId) => removeDetail({ destinationId, detailId })}
                        rowComponent={InstructionRow}
                        headerRenderer={
                            !(optDeliveryDestination.indexOf('1') === 0)
                                ? () => {}
                                : destinationId => {
                                      const destination = destinations[destinationId]
                                      if (destination.isUnknown) {
                                          return (
                                              <div className="ins-drop-header">
                                                  <ReportProblemIcon
                                                      size={20}
                                                      className="color-pantone-213-c ins-flex flex-middle ml-16 mr-8"
                                                      style={{ flex: '0 0 20px' }}
                                                  />
                                                  <div className="fw-bold">納入先： </div>
                                                  <div>未指定</div>
                                              </div>
                                          )
                                      } else {
                                          return (
                                              <div className="destination-group">
                                                  <div className="destination-group-header">
                                                      <div className="destination-group-info">
                                                          <div className="ins-drop-group">
                                                              <div className="ins-drop-header">
                                                                  <TruckIcon
                                                                      size={20}
                                                                      className="color-pantone-2422-c ins-flex flex-middle ml-16 mr-8"
                                                                      style={{ flex: '0 0 20px' }}
                                                                  />
                                                                  <div className="fw-bold">納入先： </div>
                                                                  {destination.delivery_destination_name && (
                                                                      <div>{destination.delivery_destination_name}</div>
                                                                  )}
                                                                  {destination.delivery_destination_address && (
                                                                      <div className="ml-24">
                                                                          {destination.delivery_destination_address}
                                                                      </div>
                                                                  )}
                                                                  {destination.delivery_destination_pic_name && (
                                                                      <div className="ml-24">
                                                                          {destination.delivery_destination_pic_name}
                                                                      </div>
                                                                  )}
                                                                  {destination.delivery_destination_pic_title && (
                                                                      <div className="ml-24">
                                                                          {destination.delivery_destination_pic_title}
                                                                      </div>
                                                                  )}
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div
                                                          className="des-group-collapse"
                                                          onClick={() => setCollapse(destinationId)}
                                                      >
                                                          {destination.isCollapse ? (
                                                              <UnfoldMoreIcon className="mdi_unfold_less" size={24} />
                                                          ) : (
                                                              <UnfoldLessIcon className="mdi_unfold_more" size={24} />
                                                          )}
                                                      </div>
                                                  </div>
                                              </div>
                                          )
                                      }
                                  }
                        }
                        collapse={destinationId => destinations[destinationId].isCollapse}
                    />
                </div>
            </div>
        )
    }
)

const InstructionDragAndDrop = ({
    destinations,
    destinationsOrder,
    details,
    detailsOrder,

    addDestinations,
    moveDestination,
    removeDestination,

    moveDetail,
    removeDetail,

    optDeliveryDestination, // 1 (11, 12), 2, 3
    setCollapse,
}) => {
    return (
        <React.Fragment>
            {optDeliveryDestination && optDeliveryDestination.indexOf('1') === 0 && (
                <>
                    <InstructionDestinationDragAndDrop
                        destinations={destinations}
                        destinationsOrder={destinationsOrder}
                        moveDestination={moveDestination}
                        removeDestination={removeDestination}
                    />
                    {optDeliveryDestination === '11' && (
                        <Row className="bl-form-detail-control bl-form-detail-control-trans-bg" justify="space-between">
                            <InsButton
                                icon={<AddIcon className="mdi-add" />}
                                buttonStyle="default"
                                onClick={() => addDestinations()}
                            >
                                納入先追加
                            </InsButton>
                        </Row>
                    )}
                </>
            )}

            <InstructionDetailDragAndDrop
                destinations={destinations}
                destinationsOrder={destinationsOrder}
                details={details}
                detailsOrder={detailsOrder}
                moveDetail={moveDetail}
                removeDetail={removeDetail}
                optDeliveryDestination={optDeliveryDestination}
                setCollapse={setCollapse}
            />
        </React.Fragment>
    )
}

const mapState = state => ({
    destinations: state.instructionDetail.destinations,
    destinationsOrder: state.instructionDetail.destinationsOrder,
    details: state.instructionDetail.details,
    detailsOrder: state.instructionDetail.detailsOrder,
})
const mapDispatch = {
    addDestinations,
    moveDestination,
    removeDestination,
    moveDetail,
    removeDetail,
    setCollapse,
}
export default connect(mapState, mapDispatch)(InstructionDragAndDrop)

InstructionDragAndDrop.propTypes = {
    addDestinations: PropTypes.func.isRequired,
    destinations: PropTypes.object.isRequired,
    destinationsOrder: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.object.isRequired,
    moveDestination: PropTypes.func.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDestination: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    optDeliveryDestination: PropTypes.string,
    setCollapse: PropTypes.func,
}

InstructionDestinationDragAndDrop.propTypes = {
    destinations: PropTypes.object.isRequired,
    destinationsOrder: PropTypes.array.isRequired,
    moveDestination: PropTypes.func.isRequired,
    removeDestination: PropTypes.func.isRequired,
}

InstructionDetailDragAndDrop.propTypes = {
    destinations: PropTypes.object.isRequired,
    destinationsOrder: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.object.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    optDeliveryDestination: PropTypes.string,
    setCollapse: PropTypes.func,
}
