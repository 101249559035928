import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import EstimationForm from 'modules/business/estimation/form/EstimationForm'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { setUser } from 'modules/common/auth/auth.slice'
import { InsBlockLoader } from 'components/InsLoader'
import { setDefaultState } from 'modules/business/estimation/estimation_detail.slice'

const EstimationCreate = ({ project, response, setUser, currentUser, createDocumentFrom, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const createEstimation = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.post(APP_URLS.ESTIMATIONS, values)
        if (data) {
            setUser({
                ...currentUser,
                processing_product: data.processing_product,
                processing_spec: data.processing_spec,
                processing_size: data.processing_size,
            })
            response(redirectTo).then(() => {
                setLoading(false)
            })
            dispatch(setDefaultState())
        }
    }

    const getInitialValues = () => {
        let estimation = {}
        let document = null

        if (createDocumentFrom.place_order) {
            document = project.place_order
        } else if (createDocumentFrom.instruction_internal) {
            document = project.instruction_internal
        } else if (createDocumentFrom.instruction_outsource) {
            document = project.instruction_outsource
        } else if (createDocumentFrom.invoice) {
            document = project.invoice
        } else if (createDocumentFrom.sale) {
            document = project.sale
        }
        if (document) {
            let details = []
            if (document.delivery_destinations) {
                document.delivery_destinations.forEach(destination => {
                    destination['details'].forEach(detail => {
                        details.push({ ...detail, ...{ product_name: detail.title } })
                    })
                })
            } else {
                document.details.forEach(detail => {
                    details.push({ ...detail, ...{ product_name: detail.title } })
                })
            }
            estimation = { ...document, ['detail']: details }
        }
        return estimation
    }

    return (
        <>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <EstimationForm
                    project={project}
                    onSubmit={(values, redirectTo) => createEstimation(values, redirectTo)}
                    initialValues={getInitialValues()}
                    {...rest}
                />
            )}
        </>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUser,
}

export default connect(mapState, mapDispatch)(EstimationCreate)

EstimationCreate.propTypes = {
    project: PropTypes.object,
    response: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
    createDocumentFrom: PropTypes.object,
}
