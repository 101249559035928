import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { ArrowDropdownIcon } from 'components/InsIcon'

const SelectStyles = ['default', 'alternative']
const SelectSizes = ['small', 'medium', 'big']

const InsSelect = ({
    className,
    selectStyle = 'default',
    selectSize = 'medium',
    children,
    suffixIcon,
    dropdownRender,
    notFoundContent = null,
    dropdownSuffix,
    dropdownClassName,
    open,
    onDropdownVisibleChange,
    dropdownInnerStyle,
    dropdownAutoWidth = true,
    ...rest
}) => {
    const [openSelected, setOpenSelected] = useState(false)

    useEffect(() => {
        setOpenSelected(open)
    }, [open])

    let selectClassName = `ins-select ins-select-${selectStyle} ins-select-${selectSize} `
    let insDropdownClassName = `ins-dropdown ins-dropdown-select-${selectStyle} `
    let iconSize = 24
    if (selectSize === 'small') {
        iconSize = 16
    } else if (selectSize === 'big') {
        iconSize = 32
    }
    if (className) {
        selectClassName += className
    }
    if (dropdownSuffix) {
        insDropdownClassName += 'ins-dropdown-has-suffix '
    }
    if (dropdownClassName) {
        insDropdownClassName += dropdownClassName
    }

    if (dropdownInnerStyle) {
        insDropdownClassName += `ovr-dropdown-inner `
    }

    // width auto
    if (dropdownAutoWidth) {
        insDropdownClassName += ` ovr-dropdown-width-auto `
    }

    let props = {}

    if (suffixIcon) {
        props.suffixIcon = suffixIcon
    } else {
        props.suffixIcon = (
            <ArrowDropdownIcon
                onClick={() => {
                    setOpenSelected(!openSelected)
                    if (onDropdownVisibleChange) {
                        onDropdownVisibleChange(!openSelected)
                    }
                }}
                size={iconSize}
            />
        )
    }

    return (
        <Select
            {...rest}
            className={selectClassName}
            dropdownClassName={insDropdownClassName}
            open={openSelected}
            onDropdownVisibleChange={setOpenSelected}
            dropdownRender={
                dropdownRender
                    ? dropdownRender
                    : menu => (
                          <div style={dropdownInnerStyle}>
                              {menu}
                              {dropdownSuffix && <div className="p-8 m-0">{dropdownSuffix}</div>}
                          </div>
                      )
            }
            notFoundContent={notFoundContent ? notFoundContent : <div style={{ display: 'none' }} />}
            virtual={false}
            {...props}
            autoComplete="new-password"
        >
            {children}
        </Select>
    )
}

export default InsSelect

InsSelect.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    compact: PropTypes.bool,
    selectSize: PropTypes.oneOf(SelectSizes),
    selectStyle: PropTypes.oneOf(SelectStyles),
    suffixIcon: PropTypes.any,
    dropdownRender: PropTypes.func,
    notFoundContent: PropTypes.any,
    dropdownSuffix: PropTypes.any,
    dropdownClassName: PropTypes.string,
    open: PropTypes.bool,
    onDropdownVisibleChange: PropTypes.func,
    dropdownInnerStyle: PropTypes.any,
    dropdownAutoWidth: PropTypes.bool,
}

InsSelect.defaultProps = {
    selectSize: 'medium',
    selectStyle: 'default',
}
