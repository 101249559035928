import React from 'react'

// or use white-space: pre-line;
export const convertLineBreak = str => {
    return str
        ? str
              .trim()
              .split('\n')
              .map((item, index) => (
                  <span key={index}>
                      {item}
                      <br />
                  </span>
              ))
        : ''
}
