import React from 'react'
import { Col, Row } from 'antd'
import InsDropdown from 'components/dropdown/InsDropdown'
import InsButton from 'components/InsButton'
import InsLinkDropdown from 'components/dropdown/InsLinkDropdown'
import { AddIcon, ArrowDropdownIcon, SettingIcon } from 'components/InsIcon'

const DropdownDemo = () => {
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">InsDropdown</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsLinkDropdown
                        text="Link dropdown"
                        onSelect={key => console.log(key)}
                        options={[
                            { key: 'key1', text: 'Option 1' },
                            { key: 'key2', text: 'Option 2' },
                        ]}
                    />
                </Col>
                <Col>
                    <InsLinkDropdown
                        text="Link dropdown (with icon)"
                        icon={<SettingIcon />}
                        onSelect={key => console.log(key)}
                        options={[
                            { key: 'key1', text: 'Option 1' },
                            { key: 'key2', text: 'Option 2' },
                        ]}
                    />
                </Col>
                <Col>
                    <InsDropdown
                        onSelect={key => console.log(key)}
                        options={[
                            { key: 'key1', text: 'Option 1' },
                            { key: 'key2', text: 'Option 2' },
                        ]}
                    >
                        <InsButton icon={<AddIcon />} suffixIcon={<ArrowDropdownIcon />}>
                            Button dropdown
                        </InsButton>
                    </InsDropdown>
                </Col>
                <Col>
                    <InsDropdown
                        onSelect={key => console.log(key)}
                        trigger={'click'}
                        options={[
                            { key: 'key1', text: 'Option 1' },
                            { key: 'key2', text: 'Option 2' },
                        ]}
                    >
                        <InsButton buttonStyle="secondary" icon={<AddIcon />} suffixIcon={<ArrowDropdownIcon />}>
                            Button dropdown (click)
                        </InsButton>
                    </InsDropdown>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsDropdown
                        onSelect={key => console.log(key)}
                        trigger={'contextMenu'}
                        options={[
                            {
                                key: 'key1',
                                text: 'Option 1',
                                children: [
                                    { key: 'sub1', text: 'Subitem 1' },
                                    { key: 'sub2', text: 'Subitem 2' },
                                ],
                            },
                            { key: 'key2', text: 'Option 2' },
                        ]}
                    >
                        <div style={{ width: '200px', height: '100px', backgroundColor: '#ccc' }}>Context menu</div>
                    </InsDropdown>
                </Col>
            </Row>
        </div>
    )
}

export default DropdownDemo
