import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import api from 'helpers/api.helper'
import App from 'App'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from 'modules/root.reducer'
import thunk from 'redux-thunk'
import jaJP from 'antd/es/locale/ja_JP'
import { ConfigProvider } from 'antd'
import 'styles/app.scss'
import 'moment/locale/ja'

api.accessToken = localStorage.getItem('access_token')

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
})

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={jaJP}>
            <App />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
)
