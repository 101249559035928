import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InsFloatBar from 'components/InsFloatBar'
import { Checkbox, Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import { DeleteIcon, EditIcon, FileCopyIcon } from 'components/InsIcon'
import ProjectCloneModal from 'modules/business/project/ProjectCloneModal'
import BusinessStatusEnum from 'enums/business_status.enum'
import CloneDocumentEnum from 'enums/clone_document.enum'
import ReactToPrint from 'react-to-print'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import { APP_URLS } from 'constants/url.constant'
import InsModal from 'components/InsModal'
import Routes from 'routes'
import { can } from 'helpers/permission.helper'
import SaleDOMPrint from 'modules/business/sale/SaleDOMPrint'
import { InsBlockLoader } from 'components/InsLoader'

const SalePreview = ({ sale, project, inModal, updateLink, response }) => {
    const canEditSale = can('SALE_EDITABLE') && !project.is_locked
    const [modalCloneVisible, setModalCloneVisible] = useState(false)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const componentRef = useRef()
    const deleteSale = async () => {
        setLoading(true)
        api.delete(APP_URLS.SALES_DETAIL.replace(':id', sale.id)).then(r => {
            project.sale = null
            history.push(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
            response()
            setModalDeleteVisible(false)
            setLoading(false)
        })
    }

    const updateStatusSale = async status => {
        const data = await api.post(APP_URLS.SALES_UPDATE_STATUS, {
            status: status,
            sale_id: sale.id,
        })
        if (data) {
            response()
        }
    }
    const [hideColumns, setHideColumns] = useState([])
    const renderCheckboxGroup = () => {
        const checkboxGroup = [
            {
                text: t('金額非表示'),
                name: 'hide_amount',
            },
            {
                text: t('作業指示内容非表示'),
                name: 'hide_instruction',
            },
        ]
        const handleChange = (column, checked) => {
            setHideColumns(checked ? [...hideColumns, column] : hideColumns.filter(hideColumn => hideColumn !== column))
        }
        return (
            <>
                {checkboxGroup.map((checkbox, index) => (
                    <Checkbox
                        key={index}
                        onChange={value => handleChange(checkbox.name, value.target.checked)}
                        defaultChecked={checkbox.init}
                    >
                        {checkbox.text}
                    </Checkbox>
                ))}
            </>
        )
    }

    const isSaleProcessed = sale.status === BusinessStatusEnum.SALE_PROCESSED.value
    const isSaleShipped = sale.status === BusinessStatusEnum.SALE_SHIPPED.value

    const isSaleCreating = sale.status === BusinessStatusEnum.SALE_CREATING.value
    const isSaleIssued = sale.status === BusinessStatusEnum.SALE_ISSUED.value

    return (
        <>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <div className="page-container">
                    <SaleDOMPrint
                        project={project}
                        salePrint={sale}
                        componentRef={componentRef}
                        hideColumns={hideColumns}
                        showPreview={true}
                    />

                    {canEditSale && (
                        <InsFloatBar visible={!inModal}>
                            <Row justify="space-between" align="middle">
                                <Col>
                                    <InsButton
                                        className="sale-left-btn "
                                        disabled={isSaleProcessed || isSaleShipped}
                                        icon={
                                            <DeleteIcon
                                                size={20}
                                                className={
                                                    isSaleProcessed || isSaleShipped ? '' : 'color-pantone-213-c'
                                                }
                                            />
                                        }
                                        buttonStyle="default"
                                        onClick={() => setModalDeleteVisible(true)}
                                    >
                                        {isSaleProcessed || isSaleShipped ? t('納品書削除不可') : t('納品書削除')}
                                    </InsButton>
                                    <InsModal
                                        className="ins-modal-w-550"
                                        title="削除"
                                        visible={modalDeleteVisible}
                                        onCancel={() => setModalDeleteVisible(false)}
                                        onOk={deleteSale}
                                        okText="はい"
                                        cancelText="いいえ"
                                    >
                                        <h3>{'納品書を削除してもよろしいですか？'}</h3>
                                        <p>{'※削除されたドキュメントは各ドキュメントの「削除」タブで確認できます。'}</p>
                                    </InsModal>
                                    <ProjectCloneModal
                                        title={CloneDocumentEnum.SALES.text}
                                        cloneDocument={CloneDocumentEnum.SALES.value}
                                        visible={modalCloneVisible}
                                        project={{ ...Object.assign({}, project), ...{ sale: sale } }}
                                        onCancel={() => {
                                            setModalCloneVisible(false)
                                        }}
                                    >
                                        <InsButton
                                            className="sale-clone-btn"
                                            icon={<FileCopyIcon size={20} className="color-pantone-2738-c" />}
                                            buttonStyle="default"
                                            style={{ marginLeft: 8 }}
                                            onClick={() => {
                                                setModalCloneVisible(true)
                                            }}
                                        >
                                            {t('Sale clone document')}
                                        </InsButton>
                                    </ProjectCloneModal>
                                    <InsButton
                                        disabled={isSaleProcessed || isSaleShipped}
                                        icon={
                                            <EditIcon
                                                size={20}
                                                className={
                                                    isSaleProcessed || isSaleShipped ? '' : 'color-pantone-2738-c'
                                                }
                                            />
                                        }
                                        buttonStyle="default"
                                        onClick={updateLink}
                                        className="sale-left-btn ml-8"
                                    >
                                        {isSaleProcessed || isSaleShipped ? t('納品書編集不可') : t('納品書編集')}
                                    </InsButton>
                                </Col>
                                <Col className="ins-flex flex-middle btn-update-sale-bottom">
                                    {renderCheckboxGroup()}
                                    {project.status !== BusinessStatusEnum.ORDER_FAILED.value && (
                                        <Col className="ins-flex flex-middle btn-update-project-bottom">
                                            <ReactToPrint
                                                trigger={() => (
                                                    <InsButton
                                                        className="sale-right-btn fw-bold"
                                                        buttonStyle={isSaleCreating ? 'primary' : 'outline'}
                                                        buttonSize="big"
                                                        onClick={() => {}}
                                                    >
                                                        {isSaleCreating ? t('納品書 発行') : t('納品書 再発行')}
                                                    </InsButton>
                                                )}
                                                content={() => componentRef.current}
                                                onAfterPrint={() => {
                                                    isSaleCreating &&
                                                        updateStatusSale(BusinessStatusEnum.SALE_ISSUED.value)
                                                }}
                                            />

                                            <InsButton
                                                //disabled2={isSaleProcessed}
                                                disabled={project.status > BusinessStatusEnum.SALE_PROCESSED.value}
                                                className="sale-right-btn fw-bold"
                                                buttonStyle={
                                                    isSaleProcessed || isSaleShipped ? 'outline-secondary' : 'primary'
                                                }
                                                buttonSize="big"
                                                onClick={() => {
                                                    if (isSaleProcessed || isSaleShipped) {
                                                        updateStatusSale(BusinessStatusEnum.SALE_ISSUED.value)
                                                    }
                                                    if (isSaleCreating || isSaleIssued) {
                                                        updateStatusSale(BusinessStatusEnum.SALE_SHIPPED.value)
                                                    }
                                                }}
                                            >
                                                {isSaleProcessed || isSaleShipped ? t('在庫処理 取消') : t('在庫処理')}
                                            </InsButton>

                                            <InsButton
                                                //disabled2={isSaleProcessed}
                                                disabled={project.status > BusinessStatusEnum.SALE_PROCESSED.value}
                                                className="sale-right-btn fw-bold"
                                                buttonStyle={!isSaleProcessed ? 'secondary' : 'outline-secondary'}
                                                buttonSize="big"
                                                onClick={() => {
                                                    if (!isSaleProcessed) {
                                                        updateStatusSale(BusinessStatusEnum.SALE_PROCESSED.value)
                                                    } else {
                                                        updateStatusSale(BusinessStatusEnum.SALE_ISSUED.value)
                                                    }
                                                }}
                                            >
                                                {!isSaleProcessed ? t('売上 処理') : t('売上処理 取消')}
                                            </InsButton>
                                        </Col>
                                    )}
                                </Col>
                            </Row>
                        </InsFloatBar>
                    )}
                </div>
            )}
        </>
    )
}
export default SalePreview

SalePreview.propTypes = {
    sale: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    inModal: PropTypes.bool,
    updateLink: PropTypes.func,
    response: PropTypes.func,
}
