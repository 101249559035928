import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row, Select, InputNumber } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import InsCollapse from 'components/InsCollapse'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import InsSelect from 'components/select/InsSelect'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import moment from 'moment'
import {
    setDetailSearch,
    setKeywords,
    updateDetailSearch,
    setFilters,
} from 'modules/business/actionLog/action_log.slice'
import { ActionLogObject } from 'enums/action_log_object.enum'
import { ActionLogType } from 'enums/action_log_type.enum'

const ActionLogListSearch = ({
    keywords,
    detailSearch,
    setKeywords,
    setDetailSearch,
    pagination,
    filters,
    getActionLogs,
    updateDetailSearch,
    onSearch,
    onReset,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const defaultValue = [moment().subtract(1, 'weeks'), moment()]

    const actionDateChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            'action_datetime:gte': value[0] + '\t00:00:01',
            'action_datetime:lte': value[1] + '\t23:59:59',
        })
        dispatch(setFilters(newFilters))
        getActionLogs(pagination, newFilters)
    }

    return (
        <InsCollapse
            header={
                <div>
                    <InsDateRangePicker
                        className="mr-8"
                        defaultValue={defaultValue}
                        onChange={(mm, value) => {
                            actionDateChangeHandler(value)
                        }}
                    />
                    <InsInputSearch
                        style={{ width: '500px' }}
                        placeholder={t('Project name, pic name')}
                        value={keywords}
                        onChange={values => setKeywords(values)}
                        onSearch={values => {
                            setDetailSearch({})
                            onSearch({ keyword: values })
                        }}
                    />
                </div>
            }
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'140px'}>
                    <strong>{t('column:Action type')}</strong>
                </Col>
                <Col>
                    <InsSelect
                        style={{ width: 180 }}
                        onSelect={value => updateDetailSearch({ field: 'action_type', value })}
                        value={detailSearch['action_type'] || ''}
                    >
                        <Select.Option value="">{t('common:All')}</Select.Option>
                        {Object.values(ActionLogType).map(clf => (
                            <Select.Option key={clf.value} value={parseInt(clf.value)}>
                                {clf.text}
                            </Select.Option>
                        ))}
                    </InsSelect>
                </Col>
                <Col className="ml-32" flex={'140px'}>
                    <strong>{t('column:Action object')}</strong>
                </Col>
                <Col>
                    <InsSelect
                        onSelect={value => updateDetailSearch({ field: 'action_object', value })}
                        style={{ width: 180 }}
                        value={detailSearch['action_object'] || ''}
                    >
                        <Select.Option value="">{t('common:All')}</Select.Option>
                        {Object.values(ActionLogObject).map(clf => (
                            <Select.Option key={clf.value} value={clf.value}>
                                {clf.text}
                            </Select.Option>
                        ))}
                    </InsSelect>
                </Col>
            </Row>
            {/*<Row gutter={[8, 16]}>
                <Col flex={'140px'}>
                    <strong>{t('column:Pic name')}</strong>
                </Col>
                <Col>
                    <UserSelect
                        style={{ width: 180 }}
                        dropdownMatchSelectWidth={false}
                        onChange={value => updateDetailSearch({ field: 'pic_id', value })}
                    />
                </Col>
                <Col className="ml-32" flex={'140px'}>
                    <strong>{t('column:Project name')}</strong>
                </Col>
                <Col>
                    <InsRemoteSelect
                        url={`${APP_URLS.PROJECT}?page_size=100&is_enabled=1&id=`}
                        optionIndexKey="project_name"
                        optionValueKey="project_name"
                        optionTextKey="project_name"
                        name="project_id"
                        style={{ width: '180px', marginRight: '37px' }}
                        onChange={value => updateDetailSearch({ field: 'project_id', value })}
                    />
                </Col>
            </Row>*/}

            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    keywords: state.actionLog.keywords,
    detailSearch: state.actionLog.detailSearch,
    filters: state.actionLog.filters,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(ActionLogListSearch)

ActionLogListSearch.propTypes = {
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    getActionLogs: PropTypes.func.isRequired,
}
