import React from 'react'
import PropTypes from 'prop-types'
import InsModal from 'components/InsModal'
import InstructionPreviewComponent from 'modules/business/instruction/InstructionPreviewComponent'

const InstructionPreviewModal = ({ project, instructionType, instruction, children, visible, onCancel }) => {
    return (
        <>
            <InsModal width={'90%'} visible={visible} onCancel={onCancel} footer={null}>
                <InstructionPreviewComponent
                    project={project}
                    instructionType={instructionType}
                    instruction={instruction}
                />
            </InsModal>
            {children}
        </>
    )
}

export default InstructionPreviewModal

InstructionPreviewModal.propTypes = {
    project: PropTypes.object,
    instructionType: PropTypes.number,
    instruction: PropTypes.object,
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
}
