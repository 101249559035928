import React from 'react'
import { useTranslation } from 'react-i18next'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import UserCreateComponent from 'modules/master/user/UserCreateComponent'

const UserCreate = () => {
    const { t } = useTranslation()

    if (!checkAdmin) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return (
        <div>
            <UserCreateComponent />
        </div>
    )
}

export default UserCreate
