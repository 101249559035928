import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InstructionForm from 'modules/business/instruction/form/InstructionForm'
import Routes from 'routes'
import { setUser } from 'modules/common/auth/auth.slice'
import { InsBlockLoader } from 'components/InsLoader'
import { isHeadline } from 'helpers/detail.helper'
import { setDefaultState } from 'modules/business/instruction/instruction_detail.slice'

const nullableExtFields = {
    lam_thickness: null,
    mimi: null,
    rounded_corners: null,
    suji_oshi: null,
    cutting: null,
    media: null,
    printing: null,
    printing_paper: null,
    printing_form: null,
    cold: null,
    oberami: null,
    panel_thickness: null,
    product_name: null,
    material: null,
    chichi: null,
    heat_cut: null,
    mimaki: null,
    bias: null,
    back: null,
    pocket: null,
    bag_stopper: null,
    cap: null,
    pole_material: null,
    pole_length: null,
}

const instructionOutsourceDetailDefault = {
    processed_product_classification: '1',
    product_name: '1',
    material: '8',
    chichi: '3',
    heat_cut: '0',
    mimaki: '1',
}

const instructionInternalDetailDefault = {
    processed_product_classification: '1',
    lam_thickness: '7',
    mimi: '1',
    rounded_corners: '-',
    suji_oshi: '×',
    cutting: '〇',
}

const InstructionCreate = ({ project, response, instructionType, setUser, currentUser, createDocumentFrom }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const createInstruction = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.post(APP_URLS.INSTRUCTION, values)
        if (data) {
            setUser({
                ...currentUser,
                processing_product: data.processing_product,
                processing_spec: data.processing_spec,
                processing_size: data.processing_size,
            })
            history.push(
                Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                    (instructionType === 1 ? '/instruction-internal' : '/instruction-outsource')
            )
            response(redirectTo).then(() => {
                setLoading(false)
            })
            dispatch(setDefaultState())
        }
    }

    const convertToDetails = (details, isEstimation = false) => {
        let instructionDetails = []
        details.forEach(detail => {
            if (isEstimation) {
                detail.title = detail.product_name
            }
            if (!isHeadline(detail)) {
                if (instructionType === 1) {
                    instructionDetails.push({
                        ...detail,
                        ...nullableExtFields,
                        ...instructionInternalDetailDefault,
                    })
                } else {
                    instructionDetails.push({
                        ...detail,
                        ...nullableExtFields,
                        ...instructionOutsourceDetailDefault,
                    })
                }
            } else instructionDetails.push({ ...detail })
        })
        return instructionDetails
    }

    const convertToDeliveryDestinations = destinations => {
        let instructionDeliveryDestinations = []
        destinations.forEach(deliveryDestination => {
            instructionDeliveryDestinations.push({
                ...deliveryDestination,
                ...{ details: convertToDetails(deliveryDestination.details) },
            })
        })
        return instructionDeliveryDestinations
    }

    const convertToInstruction = document => {
        let instruction = {}
        instruction = {
            ...document,
            ...{
                delivery_destination: document.delivery_destination,
                details: convertToDetails(document.details),
                delivery_destinations: convertToDeliveryDestinations(document.delivery_destinations),
            },
        }
        return instruction
    }

    const getInitialValues = () => {
        let instruction = {}
        if (createDocumentFrom.place_order) {
            instruction = convertToInstruction(project.place_order)
        } else if (createDocumentFrom.estimation) {
            instruction = {
                ...project.estimation,
                ...{ details: convertToDetails(project.estimation.detail, true), delivery_destinations: [] },
            }
        } else if (createDocumentFrom.instruction_internal) {
            instruction = convertToInstruction(project.instruction_internal)
            instruction['instruction_code'] = project.project_code + `-GC-${project.count_instruction_outsource || 1}`
            instruction['instruction_classification'] = 2
        } else if (createDocumentFrom.instruction_outsource) {
            instruction = convertToInstruction(project.instruction_outsource)
            instruction['instruction_code'] = project.project_code + `-SN-${project.count_instruction_internal || 1}`
            instruction['instruction_classification'] = 1
        } else if (createDocumentFrom.sale) {
            instruction = convertToInstruction(project.sale)
        }
        return instruction
    }

    return (
        <div className="p-24">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <InstructionForm
                    project={project}
                    instructionType={instructionType}
                    initialValues={getInitialValues()}
                    onSubmit={(values, redirectTo) => createInstruction(values, redirectTo)}
                />
            )}
        </div>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUser,
}

export default connect(mapState, mapDispatch)(InstructionCreate)

InstructionCreate.propTypes = {
    project: PropTypes.object.isRequired,
    instructionType: PropTypes.number.isRequired,
    response: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
    createDocumentFrom: PropTypes.object,
}
