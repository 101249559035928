import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'antd'
import { CloseIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'

const InsModal = ({
    className,
    children,
    onCancel,
    cancelText,
    onOk,
    okText,
    cancelButtonStyle = 'outline',
    okButtonStyle = 'primary',
    footer,
    ...rest
}) => {
    let modalClassName = 'ins-modal '
    if (className) {
        modalClassName += className
    }
    return (
        <Modal
            {...rest}
            className={modalClassName}
            closeIcon={<CloseIcon size={24} onClick={() => onCancel && onCancel()} />}
            onCancel={onCancel}
            footer={
                typeof footer !== 'undefined'
                    ? footer
                    : [
                          <InsButton
                              centered
                              key="cancel"
                              onClick={() => onCancel && onCancel()}
                              buttonStyle={cancelButtonStyle}
                          >
                              {cancelText || 'キャンセル'}
                          </InsButton>,
                          <InsButton centered key="ok" onClick={() => onOk && onOk()} buttonStyle={okButtonStyle}>
                              {okText || '確認'}
                          </InsButton>,
                      ]
            }
        >
            {children}
        </Modal>
    )
}

export default InsModal

InsModal.propTypes = {
    className: PropTypes.string,
    cancelButtonStyle: PropTypes.string,
    cancelText: PropTypes.string,
    children: PropTypes.any.isRequired,
    okButtonStyle: PropTypes.string,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
}

InsModal.defaultProps = {
    cancelButtonStyle: 'outline',
    okButtonStyle: 'primary',
}
