import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { AssignmentIcon } from 'components/InsIcon'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InstructionPrintTemplate from 'modules/business/instruction/InstructionPrintTemplate'
import { isHeadline } from 'helpers/detail.helper'

const InstructionPreviewComponent = ({ instruction, instructionType, project, hideColumns = [] }) => {
    const [tabs, setTabs] = useState([])
    const makeGroup = () => {
        let group = []
        instruction.details.map(d => {
            // check isHeadline = .headline ><; use d.processed_product_classification
            if (
                !isHeadline(d) &&
                !!d.processed_product_classification &&
                group.indexOf(d.processed_product_classification) < 0
            ) {
                group.push(d.processed_product_classification)
            }

            return null
        })
        setTabs(group)
        setCurrentProductType(group[0])
    }

    // true: IN_HOUSE_PROCESSED, false: OUTSIDE_PROCESSED
    const isInHouse = instruction.instruction_classification === 1
    const getTabText = value => {
        return getClassificationItemName(
            isInHouse
                ? ClassificationTypeEnum.IN_HOUSE_PROCESSED.value
                : ClassificationTypeEnum.OUTSIDE_PROCESSED.value,
            value
        )
    }

    const [currentProductType, setCurrentProductType] = useState(tabs.length > 0 && tabs[0])

    useEffect(() => {
        makeGroup()
    }, [instruction])

    return (
        <>
            <div className="prv-tab-actions">
                <Tabs activeKey={currentProductType} onChange={value => setCurrentProductType(value)} animated={false}>
                    {tabs.map(t => (
                        <Tabs.TabPane
                            tab={
                                <>
                                    <AssignmentIcon size={24} className="mdi_assignment" />
                                    {getTabText(t)}
                                    {isInHouse ? '加工' : ''}
                                </>
                            }
                            key={t}
                        />
                    ))}
                </Tabs>
            </div>
            <div className="prv-instruction" style={{ padding: 48 }}>
                <InstructionPrintTemplate
                    project={project}
                    instructionType={instructionType}
                    instruction={instruction}
                    filterDetailType={detail =>
                        !isHeadline(detail) && detail.processed_product_classification === currentProductType
                    }
                    titleText={getTabText(currentProductType)}
                    hideColumns={hideColumns}
                />
            </div>
        </>
    )
}

export default InstructionPreviewComponent

InstructionPreviewComponent.propTypes = {
    instruction: PropTypes.object.isRequired,
    instructionType: PropTypes.number,
    project: PropTypes.object.isRequired,
    hideColumns: PropTypes.array,
}
