import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsDataTable from 'components/datatable/InsDataTable'
import InsLoader from 'components/InsLoader'

const StockListInsufficient = ({ state, data }) => {
    const { t } = useTranslation()

    const stockColumns = [
        {
            title: t('column:Product name'),
            dataIndex: 'product_name',
            width: '40%',
            sorter: (a, b) => {
                return a.product_name.localeCompare(b.product_name)
            },
        },
        {
            title: t('column:Remain quantity'),
            dataIndex: 'remain',
            width: '20%',
            sorter: (a, b) => a.remain - b.remain,
        },
        {
            title: t('column:Input quantity'),
            dataIndex: 'required',
            width: '20%',
            sorter: (a, b) => a.required - b.required,
        },
        {
            title: t('column:Insufficient quantity'),
            dataIndex: 'insufficient_quantity',
            width: '20%',
            render: (text, record) => record.remain - record.required,
            sorter: (a, b) => a.remain - a.required - (b.remain - b.required),
        },
    ]

    return (
        <div>
            <h3 className="page-title mb-32">
                {t('下記の資材の在庫が不足しています。このまま登録してもよろしいですか？')}
            </h3>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsDataTable
                        customClassName="stock-insufficient-table"
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered
                        columns={stockColumns}
                        data={data}
                        scroll={{ y: 400 }}
                        onChange={(pagination, filters, sorter, extra) => {
                            //
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

const mapState = state => ({ state: state.stock })
export default connect(mapState)(StockListInsufficient)

StockListInsufficient.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    data: PropTypes.array,
}
