import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Select } from 'antd'
import InsSelect from 'components/select/InsSelect'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import InsInput from 'components/InsInput'
import { TruckIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import history from 'helpers/history.helper'
import InsFloatBar from 'components/InsFloatBar'
import InsInputNumber from 'components/InsInputNumber'
import moment from 'moment'
import { ShippingType, DeliveryTime, DeliveryTime_DM_FLIGHT, ProductType, PrintType } from 'enums/tracking.enum'

const TrackingForm = ({ project, isEdit, initialValues = {}, onSubmit }) => {
    const { t } = useTranslation()

    const [form] = Form.useForm()
    const dateFormat = 'YYYY/MM/DD'

    initialValues = Object.assign(
        {
            shipping_type: ShippingType.PAYMENT.value,
            delivery_time: DeliveryTime.UNSPECIFIED.value,
            // shipping 1
            shipping1_product1_type: ProductType.WORKPIECE.value,
            shipping1_product2_type: ProductType.FREE_INPUT.value,
            shipping1_quantity: 1,
            shipping1_quantity_print_type: PrintType.DO_NOT.value,
            // shipping 2
            shipping2_product1_type: ProductType.WORKPIECE.value,
            shipping2_product2_type: ProductType.FREE_INPUT.value,
            shipping2_quantity: 1,
            shipping2_quantity_print_type: PrintType.DO_NOT.value,
            // fix
            shipping_code:
                'MS' + moment().format('YYYYMM').toString() + 'D-' + ((project && project['count_tracking']) || '1'),
            project_id: project.id,
            management_number: '01',
            invoice_code: '035664341201',
        },
        initialValues
    )

    if (initialValues.shipping_plan_date) {
        // moment.utc?
        initialValues.shipping_plan_date = moment.utc(moment(initialValues.shipping_plan_date))
    } else {
        delete initialValues['shipping_plan_date']
    }
    if (initialValues.delivery_plan_date) {
        // moment.utc?
        initialValues.delivery_plan_date = moment.utc(moment(initialValues.delivery_plan_date))
    } else {
        delete initialValues['delivery_plan_date']
    }

    const [shippingType, setShippingType] = useState(initialValues.shipping_type)
    const handleShippingTypeChange = value => {
        if (value === ShippingType.DM_FLIGHT.value) {
            form.setFieldsValue({ delivery_time: DeliveryTime_DM_FLIGHT.UNTIL_10AM.value })
        } else if (shippingType === ShippingType.DM_FLIGHT.value) {
            form.setFieldsValue({ delivery_time: DeliveryTime.UNSPECIFIED.value })
        }
        setShippingType(value)
    }

    const [shipping1Product1Type, setShipping1Product1Type] = useState(initialValues.shipping1_product1_type)
    const [shipping1Product2Type, setShipping1Product2Type] = useState(initialValues.shipping1_product2_type)
    const [shipping2Product1Type, setShipping2Product1Type] = useState(initialValues.shipping2_product1_type)
    const [shipping2Product2Type, setShipping2Product2Type] = useState(initialValues.shipping2_product2_type)

    return (
        <>
            <div className="p-24 page-tracking">
                <div className="tracking-head">
                    <h1 className="tracking-title">{t('送り状作成')}</h1>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <th>
                                        {t('送り状番号')}
                                        <span>:</span>
                                    </th>
                                    <td>{initialValues.shipping_code}</td>
                                </tr>
                                <tr>
                                    <th>
                                        {t('請求先顧客コード')}
                                        <span>:</span>
                                    </th>
                                    <td>{initialValues.invoice_code}</td>
                                </tr>
                                <tr>
                                    <th>
                                        {t('運賃管理番号')}
                                        <span>:</span>
                                    </th>
                                    <td>{initialValues.management_number}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Form
                    className="ins-form pt-0 tracking-form"
                    form={form}
                    onFinish={values => onSubmit(values)}
                    initialValues={initialValues}
                >
                    <Form.Item name="shipping_code" style={{ display: 'none' }}>
                        <InsInput />
                    </Form.Item>
                    <Form.Item name="project_id" style={{ display: 'none' }}>
                        <InsInput />
                    </Form.Item>
                    <Form.Item name="management_number" style={{ display: 'none' }}>
                        <InsInput />
                    </Form.Item>
                    <Form.Item name="invoice_code" style={{ display: 'none' }}>
                        <InsInput />
                    </Form.Item>
                    <Row>
                        <Col span={12} className="pr-28">
                            <label className="dt-label dt-is-form">{'基本情報'}</label>

                            <Form.Item name="shipping_type" label={t('送り状種類')}>
                                <InsSelect style={{ width: 180 }} onChange={value => handleShippingTypeChange(value)}>
                                    {Object.values(ShippingType).map(option => (
                                        <Select.Option key={option.value} value={option.value}>
                                            {option.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                            <Form.Item name="shipping_plan_date" label={t('出荷予定日')}>
                                <InsDatePicker
                                    format={dateFormat}
                                    placeholder="出荷予定日"
                                    style={{ width: 180 }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item name="delivery_plan_date" label={t('お届け予定日')}>
                                <InsDatePicker
                                    format={dateFormat}
                                    placeholder="出荷予定日"
                                    style={{ width: 180 }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item name="delivery_time" label={t('配達時間帯')}>
                                <InsSelect style={{ width: 180 }}>
                                    {Object.values(
                                        shippingType !== ShippingType.DM_FLIGHT.value
                                            ? DeliveryTime
                                            : DeliveryTime_DM_FLIGHT
                                    ).map(option => (
                                        <Select.Option key={option.value} value={option.value}>
                                            {option.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col span={12} className="pl-28">
                            <label className="dt-label dt-is-form">{'ご依頼主情報'}</label>
                            <Form.Item name="client_delivery_name" label={t('お届け先名称')}>
                                <InsInput style={{ width: 310 }} allowClear />
                            </Form.Item>
                            <Form.Item name="client_tel" label={t('電話番号')}>
                                <InsInput style={{ width: 180 }} allowClear />
                            </Form.Item>
                            <Form.Item name="client_postcode" label={t('郵便番号')}>
                                <InsInput style={{ width: 180 }} allowClear />
                            </Form.Item>
                            <Form.Item name="client_address" label={t('住所')}>
                                <InsInput style={{ width: 310 }} allowClear />
                            </Form.Item>
                            <Form.Item name="client_building_name" label={' '}>
                                <InsInput style={{ width: 310 }} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="box-delivery mb-16">
                        <div className="box-delivery-head">
                            <TruckIcon className="color-pantone-2422-c mr-8" />
                            <label>{'納入先 1'}</label>
                        </div>
                        <Row>
                            <Col span={12} className="pr-28">
                                <Form.Item name="shipping1_delivery_name" label={t('お届け先名称')}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping1_tel" label={t('電話番号')}>
                                    <InsInput style={{ width: 180 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping1_postcode" label={t('郵便番号')}>
                                    <InsInput style={{ width: 180 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping1_address" label={t('住所')}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping1_building_name" label={'建物名'}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="pl-28">
                                <Form.Item label={t('品名1')}>
                                    <Form.Item name="shipping1_product1_type" noStyle>
                                        <InsSelect
                                            style={{ width: 120 }}
                                            onChange={value => setShipping1Product1Type(value)}
                                        >
                                            {Object.values(ProductType).map(option => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                    {shipping1Product1Type === ProductType.FREE_INPUT.value && (
                                        <Form.Item name="shipping1_product1_name" noStyle>
                                            <InsInput className="ml-4" style={{ width: 186 }} />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item label={t('品名2')}>
                                    <Form.Item name="shipping1_product2_type" noStyle>
                                        <InsSelect
                                            style={{ width: 120 }}
                                            onChange={value => setShipping1Product2Type(value)}
                                        >
                                            {Object.values(ProductType).map(option => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                    {shipping1Product2Type === ProductType.FREE_INPUT.value && (
                                        <Form.Item name="shipping1_product2_name" noStyle>
                                            <InsInput className="ml-4" style={{ width: 186 }} />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item label={t('出荷予定個数')}>
                                    <Form.Item name="shipping1_quantity" noStyle>
                                        <InsInputNumber min={0} style={{ width: 120 }} allowClear />
                                    </Form.Item>
                                    <Form.Item name="shipping1_quantity_print_type" noStyle>
                                        <InsSelect className="ml-4" style={{ width: 186 }}>
                                            {Object.values(PrintType).map(option => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item name="shipping1_note" label={t('記事（メモ）')}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div className="box-delivery">
                        <div className="box-delivery-head">
                            <TruckIcon className="color-pantone-2422-c mr-8" />
                            <label>{'納入先 2'}</label>
                        </div>
                        <Row>
                            <Col span={12} className="pr-28">
                                <Form.Item name="shipping2_delivery_name" label={t('お届け先名称')}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping2_tel" label={t('電話番号')}>
                                    <InsInput style={{ width: 180 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping2_postcode" label={t('郵便番号')}>
                                    <InsInput style={{ width: 180 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping2_address" label={t('住所')}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                                <Form.Item name="shipping2_building_name" label={'建物名'}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="pl-28">
                                <Form.Item label={t('品名1')}>
                                    <Form.Item name="shipping2_product1_type" noStyle>
                                        <InsSelect
                                            style={{ width: 120 }}
                                            onChange={value => setShipping2Product1Type(value)}
                                        >
                                            {Object.values(ProductType).map(option => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                    {shipping2Product1Type === ProductType.FREE_INPUT.value && (
                                        <Form.Item name="shipping2_product1_name" noStyle>
                                            <InsInput className="ml-4" style={{ width: 186 }} />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item label={t('品名2')}>
                                    <Form.Item name="shipping2_product2_type" noStyle>
                                        <InsSelect
                                            style={{ width: 120 }}
                                            onChange={value => setShipping2Product2Type(value)}
                                        >
                                            {Object.values(ProductType).map(option => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                    {shipping2Product2Type === ProductType.FREE_INPUT.value && (
                                        <Form.Item name="shipping2_product2_name" noStyle>
                                            <InsInput className="ml-4" style={{ width: 186 }} />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item label={t('出荷予定個数')}>
                                    <Form.Item name="shipping2_quantity" noStyle>
                                        <InsInputNumber min={0} style={{ width: 120 }} allowClear />
                                    </Form.Item>
                                    <Form.Item name="shipping2_quantity_print_type" noStyle>
                                        <InsSelect className="ml-4" style={{ width: 186 }}>
                                            {Object.values(PrintType).map(option => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item name="shipping2_note" label={t('記事（メモ）')}>
                                    <InsInput style={{ width: 310 }} allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <InsFloatBar visible={true} className="with-sidebar z-9999">
                        <Row gutter={8} justify="end">
                            <Col>
                                <InsButton
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    centered
                                    width="140px"
                                    onClick={() => history.goBack()}
                                >
                                    {t('common:Cancel')}
                                </InsButton>
                            </Col>
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    width="140px"
                                    isSubmitButton={true}
                                >
                                    {isEdit ? t('common:Save') : t('common:Save')}
                                </InsButton>
                            </Col>
                        </Row>
                    </InsFloatBar>
                </Form>
            </div>
        </>
    )
}

export default TrackingForm

TrackingForm.propTypes = {
    project: PropTypes.object,
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
}
