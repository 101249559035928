const NavigationEnum = Object.freeze({
    PROJECTS: 'projects',
    SUMMARY_INVOICES: 'summary-invoices',
    SHIPMENTS: 'shipments',
    PAYMENTS: 'payments',
    REPORTS: 'reports',
    LIST_ESTIMATIONS: 'list-estimations',
    LIST_INSTRUCTIONS: 'list-instructions',
    LIST_PLACE_ORDERS: 'list-place-orders',
    LIST_SALES: 'list-sales',
    LIST_INVOICES: 'list-invoices',
    LIST_RED_SLIPS: 'list-red-slips',
})

export default NavigationEnum
