import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsInput from 'components/InsInput'
import InsButton from 'components/InsButton'
import InsCollapse from 'components/InsCollapse'
import { setDetailSearch, setKeywords, updateDetailSearch } from 'modules/master/shipper/shipper_modal.slice'

const ShipperListSearch = ({
    filters,
    keywords,
    detailSearch,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    onSearch,
    onReset,
}) => {
    const { t } = useTranslation()
    const collapseRef = useRef()

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <InsInputSearch
                    style={{ width: '340px' }}
                    placeholder={t('Shipper code, shipper name, etc')}
                    value={keywords}
                    onChange={values => setKeywords(values)}
                    onSearch={values => {
                        setDetailSearch({})
                        onSearch({ keyword: values })
                    }}
                />
            }
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'100px'}>
                    <strong>{t('column:Shipper code')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={[detailSearch['customer_code:like'], detailSearch['shipping_address_code:like']]}
                        onChange={value => {
                            updateDetailSearch({ field: 'customer_code:like', value })
                            updateDetailSearch({ field: 'shipping_address_code:like', value })
                        }}
                    />
                </Col>
                <Col className="ml-32" flex={'80px'}>
                    <strong>{t('column:Shipper name')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={[detailSearch['customer_name:like'], detailSearch['shipping_address_name1:like']]}
                        onChange={value => {
                            updateDetailSearch({ field: 'customer_name:like', value })
                            updateDetailSearch({ field: 'shipping_address_name1:like', value })
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'100px'}>
                    <strong>{t('column:Phone number')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['phone_number:like']}
                        onChange={value => updateDetailSearch({ field: 'phone_number:like', value })}
                    />
                </Col>
            </Row>
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    filters: state.shipperModal.filters,
    keywords: state.shipperModal.keywords,
    detailSearch: state.shipperModal.detailSearch,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(ShipperListSearch)

ShipperListSearch.propTypes = {
    filters: PropTypes.object.isRequired,
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
}
