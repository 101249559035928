import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import { getData, setPagination, setFilters, setSorter } from 'modules/master/shipper/shipper_modal.slice'
import ShipperListComponent from 'modules/master/shipper/ShipperListComponent'
import CustomerListComponent from 'modules/master/customer/CustomerListComponent'
import CustomerCreateComponent from 'modules/master/customer/CustomerCreateComponent'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import ShipperTypeEnum from 'enums/shipper_type.enum'
import ShippingAddressDetailComponent from 'modules/master/shippingAddress/ShippingAddressDetailComponent'

const ShipperSelect = ({
    state,
    record,
    showListLink = true,
    showCreateButton = true,
    showDetailLink = true,
    ...rest
}) => {
    const { t } = useTranslation()
    let initialOptions = []
    if (record) {
        initialOptions.push({
            id: record.id,
            shipper_name: record.shipper_name,
        })
    }

    let listModal, createModal, detailModal
    if (showListLink) {
        listModal = {
            title: t('Shipper list'),
            component: ShipperListComponent,
            props: {
                state,
                getData,
                setPagination,
                setFilters,
                setSorter,
            },
        }
    }
    if (showCreateButton) {
        createModal = {
            title: t('navigation:{{entity}} create', { entity: t('entity:Customer') }),
            component: CustomerCreateComponent,
        }
    }
    if (showDetailLink) {
        detailModal = {
            title: t('navigation:{{entity}} information', { entity: t('column:Shipper') }),
            linkText: t('navigation:{{entity}} information', { entity: t('column:Shipper') }),
            component: CustomerDetailComponent,
            component2: ShippingAddressDetailComponent,
        }
    }

    return (
        <InsRemoteSelect
            {...rest}
            url={`${APP_URLS.SHIPPERS}?page_size=100&is_enabled=1&order_by=-id&keyword[]=`}
            createText="新規顧客作成"
            initialOptions={initialOptions}
            optionIndexKey="id"
            optionValueKey="id"
            optionTextKey="shipper_name"
            listModal={listModal}
            createModal={createModal}
            detailModal={detailModal}
            optionTypeKey="shipper_type"
        />
    )
}

const mapState = state => ({
    state: state.shipperModal,
})
export default connect(mapState)(ShipperSelect)

ShipperSelect.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.any,
        shipper_name: PropTypes.string,
    }),
    showListLink: PropTypes.bool,
    showCreateButton: PropTypes.bool,
    showDetailLink: PropTypes.bool,
    state: PropTypes.shape(BaseStatePropShape),
}
