export const SENDER_CODE = {
    ESTIMATION_DEFAULT: '0000000001',
    ESTIMATION_OTHER: '0000000000',
    DELIVERY_NOTE_DEFAULT: '0000000001',
    DELIVERY_NOTE_OTHER: '0000000000',
    PLACE_ORDER_DEFAULT: '0000000001',
    BILL_DEFAULT: '0000000001',
    BILL_OTHER: '0000000000',
}

export const BILL_DATE = {
    BILL_DATE_DEFAULT: '99',
}

export const COLLECTION_SCHEDULE1 = {
    TYPE_DEFAULT: '2',
    METHOD_DEFAULT: '0',
}

export const COLLECTION_SCHEDULE2 = {
    TYPE_DEFAULT: '2',
    METHOD_DEFAULT: '0',
}

export const PAYMENT_SCHEDULE = {
    TYPE_DEFAULT: '2',
    METHOD_DEFAULT: '2',
}
