import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import InvoiceForm from 'modules/business/invoice/form/InvoiceForm'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import Routes from 'routes'
import { InsBlockLoader } from 'components/InsLoader'

const InvoiceUpdate = ({ project, sale, invoice, response }) => {
    const updateInvoice = async (values, redirectTo) => {
        const data = await api.put(APP_URLS.INVOICE_DETAIL.replace(':id', invoice.id), values)
        if (data) {
            if (!redirectTo) {
                redirectTo =
                    Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) + '/invoices/preview'
            }
            response(redirectTo)
        }
    }

    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)

    const countProject = async projectId => {
        const data = await api.get(APP_URLS.INVOICE_COUNT_PROJECT.replace(':projectId', projectId))
        setCount(data)
        setLoading(false)
    }

    useEffect(() => {
        countProject(project.id)
    }, [])

    return (
        <div className="p-24">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <InvoiceForm
                    isEdit={true}
                    project={project}
                    sale={sale}
                    initialValues={invoice}
                    onSubmit={(values, redirectTo) => updateInvoice(values, redirectTo)}
                    countProject={count}
                />
            )}
        </div>
    )
}

export default InvoiceUpdate

InvoiceUpdate.propTypes = {
    project: PropTypes.object.isRequired,
    sale: PropTypes.object.isRequired,
    invoice: PropTypes.object,
    response: PropTypes.func,
}
