import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { CheckIcon, LockIcon } from 'components/InsIcon'
import InsTab from 'components/InsTab'
import SummaryInvoiceListSearch from 'modules/business/summaryInvoice/SummaryInvoiceListSearch'
import InsFloatBar from 'components/InsFloatBar'
import { formatNumberComma } from 'helpers/number.helper'
import InsLoader from 'components/InsLoader'
import Routes from 'routes'
import { dateFormat, dateToStr } from 'helpers/date.helper'
import { getClassificationItemNameFromValue } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import SummaryInvoicingModal from './SummaryInvoicingModal'
import {
    getData,
    setFilters,
    setSorter,
    setKeywords,
    setPagination,
} from 'modules/business/summaryInvoice/summary_invoice.slice'
import { can } from 'helpers/permission.helper'
import BusinessStatusEnum from 'enums/business_status.enum'
import moment from 'moment'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import InsModal from 'components/InsModal'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { userSettingColumns } from 'helpers/user_setting.helper'
import NavigationEnum from 'enums/navigation.enum'

const SummaryInvoiceList = ({ state, setKeywords, currentUser }) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const componentRef = useRef()

    const isIssuedFunc = target => {
        let tmp
        if (target.length) {
            tmp = target[0].issue_date ? '1' : '0'
        } else {
            tmp = state.filters.is_issued
        }
        return tmp === '1'
    }

    const projectGroup = state.data

    const getSummaryInvoices = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    const [modalInvoicingVisible, setModalInvoicingVisible] = useState(false)

    const headOfficeColumns = [
        {
            title: '請求先名',
            dataIndex: 'customer_name',
            sorter: true,
            // width: 500,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        setModalCustomerDetail({
                            customerId: record.id,
                            visible: true,
                        })
                    }}
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: '締め区分',
            dataIndex: 'bill_date_classification',
            sorter: true,
            width: 200,
            render: (text, record) => (
                <>
                    {getClassificationItemNameFromValue(
                        ClassificationTypeEnum.BILL_DATE.value,
                        record.bill_date_classification
                    )}
                </>
            ),
        },
        {
            title: '小計',
            dataIndex: 'total',
            sorter: true,
            width: 160,
            render: text => <>{formatNumberComma(text)}</>,
        },
    ]

    const issuedHeadColumns = [
        {
            title: '請求書 ID',
            dataIndex: 'invoice_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    linkTo={
                        Routes.private.business.PROJECT_DETAIL.path.replace(
                            ':id',
                            record.project_id || record.group_projects_for_summary[0]?.id
                        ) +
                        '/invoices?from=' +
                        NavigationEnum.SUMMARY_INVOICES
                    }
                    buttonType="link"
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: '請求先名',
            dataIndex: 'customer_name',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        setModalCustomerDetail({
                            customerId: record.customer?.id,
                            visible: true,
                        })
                    }}
                >
                    {record.customer?.customer_name}
                </InsButton>
            ),
        },
        {
            title: '発行日',
            dataIndex: 'issue_date',
            sorter: true,
            width: 180,
            render: text => <>{dateFormat(text)}</>,
        },
        {
            title: '発行者',
            dataIndex: 'pic_name',
            sorter: true,
            width: 120,
            render: (text, record) => <>{record.pic && record.pic.pic_name}</>,
        },
        {
            title: '調整額',
            dataIndex: 'adjust_amount',
            sorter: true,
            width: 120,
            render: text => <>{formatNumberComma(text)}</>,
        },
        {
            title: '小計',
            dataIndex: 'total',
            sorter: true,
            width: 120,
            render: text => <>{formatNumberComma(text)}</>,
        },
    ]

    const defaultHeadOfficeColumns = ['customer_name', 'bill_date_classification', 'total']

    const defaultIssuedHeadColumns = [
        'invoice_code',
        'customer_name',
        'issue_date',
        'pic_name',
        'adjust_amount',
        'total',
    ]

    const userHeadOfficeColumns = userSettingColumns(
        currentUser.user_setting,
        'summary_invoices',
        headOfficeColumns,
        defaultHeadOfficeColumns
    )
    const userIssuedHeadColumns = userSettingColumns(
        currentUser.user_setting,
        'history_summary_invoices',
        issuedHeadColumns,
        defaultIssuedHeadColumns
    )

    const projectColumns = [
        {
            title: '案件番号',
            dataIndex: 'project_code',
            //sorter: true,
            sorter: (a, b) => {
                return a.project_code.localeCompare(b.project_code)
            },
            width: 110,
            render: (text, record) => (
                <>
                    {record.is_locked ? <LockIcon className="color-blue-gray-600 mr-4" size={14} /> : null}
                    <InsButton
                        buttonStyle="plain"
                        linkTo={
                            Routes.private.business.PROJECT_DETAIL.path.replace(':id', record.id) +
                            '?from=' +
                            NavigationEnum.SUMMARY_INVOICES
                        }
                        buttonType="link"
                    >
                        {text}
                    </InsButton>
                </>
            ),
        },
        {
            title: '顧客名',
            dataIndex: 'customer_name',
            //sorter: true,
            sorter: (a, b) => {
                return a.customer?.customer_name.localeCompare(b.customer?.customer_name)
            },
            width: 200,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        setModalCustomerDetail({
                            customerId: record.customer?.id,
                            visible: true,
                        })
                    }}
                >
                    {record.customer && record.customer.customer_name}
                </InsButton>
            ),
        },
        {
            title: '件名',
            dataIndex: 'sale_name',
            //sorter: true,
            sorter: (a, b) => {
                return a.sale?.sale_name.localeCompare(b.sale?.sale_name)
            },
            width: 275,
            render: (text, record) => <>{record.sale.sale_name}</>,
        },
        {
            title: '納品日',
            dataIndex: 'delivery_date',
            //sorter: true,
            sorter: (a, b) => {
                return a.sale?.delivery_date.localeCompare(b.sale?.delivery_date)
            },
            width: 94,
            render: (text, record) => dateToStr(record.sale.delivery_date, 'YYYY/MM/DD'),
        },
        {
            title: '担当者名',
            dataIndex: 'pic_name',
            //sorter: true,
            sorter: (a, b) => {
                return a.pic?.pic_name.localeCompare(b.pic?.pic_name)
            },
            width: 94,
            render: (text, record) => <>{record.pic && record.pic.pic_name}</>,
        },
        {
            title: '請求額',
            dataIndex: 'total',
            //sorter: true,
            sorter: (a, b) => {
                if (a.sale?.total === b.sale?.total) return 0
                return a.sale?.total > b.sale?.total ? 1 : -1
            },
            width: 94,
            render: (text, record) => formatNumberComma(record.sale.total),
        },
    ]
    const projectColumnsWithRedSlip = [
        ...projectColumns,
        {
            title: '赤伝',
            width: 56,
            sorter: (a, b) => {
                const aRedSlip = a.red_slip && a.red_slip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value
                const bRedSlip = b.red_slip && b.red_slip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value
                return aRedSlip && bRedSlip ? 0 : aRedSlip ? 1 : -1
            },
            render: (text, record) =>
                record.red_slip && record.red_slip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value ? (
                    <span className="color-pantone-213-c">{'赤伝'}</span>
                ) : (
                    ''
                ),
        },
    ]

    const [selectedKeys, setSelectedKeys] = useState({})

    const handleSelectedKeysChanged = (keys, rootKey) => {
        if (rootKey) {
            if (keys.length > 0) {
                setSelectedKeys({ ...selectedKeys, [rootKey]: keys })
            } else {
                let tmpSelectedKeys = Object.assign({}, selectedKeys)
                delete tmpSelectedKeys[rootKey]
                setSelectedKeys(tmpSelectedKeys)
            }
        } else {
            let currentRootKeys = Object.keys(selectedKeys).map(k => Number(k))
            let diffAddKeys = keys.filter(k => !currentRootKeys.includes(k))
            let diffRemoveKeys = currentRootKeys.filter(k => !keys.includes(k))
            let tmpSelectedKeys = Object.assign({}, selectedKeys)

            diffRemoveKeys.map(k => {
                delete tmpSelectedKeys[k]
            })

            diffAddKeys.map(k => {
                const tmp = projectGroup.find(g => g.id === k)
                tmpSelectedKeys[k] = [...tmp.group_projects_for_summary, tmp.project]
                    .filter(p => p && (isIssuedFunc(state.data) || !p.is_locked))
                    .map(p => p.id)
            })

            setSelectedKeys(tmpSelectedKeys)
        }
    }

    const selectAll = () => {
        let tmpSelectedKeys = {}
        projectGroup.map(group => {
            tmpSelectedKeys[group.id] = [...group.group_projects_for_summary, group.project]
                .filter(p => p && (isIssuedFunc(state.data) || !p.is_locked))
                .map(p => p.id)
        })
        setSelectedKeys(tmpSelectedKeys)
    }

    const statCalculation = () => {
        let totalAmountExtracted = 0
        let totalAmountSelected = 0
        let countProjectSelected = 0
        projectGroup.map(group => {
            let tmpGroup = selectedKeys[group.id] || []

            let tmp = [...group.group_projects_for_summary, group.project]
                .filter(p => p && !(p.red_slip && p.red_slip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value))
                .map(p => {
                    totalAmountExtracted = totalAmountExtracted + ((p.sale || p.invoice_project).total || 0)
                    if (p && tmpGroup.includes(p.id)) {
                        totalAmountSelected = totalAmountSelected + ((p.sale || p.invoice_project).total || 0)
                        countProjectSelected++
                    }
                })
        })

        return { totalAmountExtracted, totalAmountSelected, countProjectSelected }
    }
    const stat = statCalculation()

    const updateFilters = obj => {
        // const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        const newFilters = Object.assign({}, state.filters, obj)
        dispatch(setFilters(newFilters))
        getSummaryInvoices(state.pagination, newFilters)
    }

    const tabChangeHandler = key => {
        setSelectedKeys({})
        // reset
        setKeywords([])
        const newFilters = Object.assign({}, state.filters, {
            'is_issued': key.toString(),
            'created_at:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:01'),
            'created_at:lte': moment().format('YYYY/MM/DD 23:59:59'),
            'keyword': [],
        })
        dispatch(setFilters(newFilters))
        const newSorter = {}
        dispatch(setSorter(newSorter))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getSummaryInvoices(newPagination, newFilters, newSorter)
    }

    const [companyInfo, setCompanyInfo] = useState()
    const getCompanyInfo = async () => {
        const senderId = '0000000001'
        const companyInfoResp = await api.get(APP_URLS.COMPANIES_DETAIL_BY_CODE.replace(':code', senderId))
        if (companyInfoResp) {
            setCompanyInfo(companyInfoResp)
        }
    }

    useEffect(() => {
        getSummaryInvoices()
        getCompanyInfo()
    }, [])
    const [modalCustomerDetail, setModalCustomerDetail] = useState({
        customerId: null,
        visible: false,
    })

    return (
        <>
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalCustomerDetail.visible}
                onCancel={() =>
                    setModalCustomerDetail({
                        customerId: modalCustomerDetail.customerId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <CustomerDetailComponent inModal={true} id={modalCustomerDetail.customerId} />
            </InsModal>
            <div className="page-container mb-64 summary-invoices-page">
                <Row className="mt-8 mb-16" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-0">まとめ請求</h1>
                    </Col>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Col span={24}>
                        <SummaryInvoiceListSearch
                            onSearch={condition => updateFilters(condition)}
                            onReset={() => {}}
                            pagination={state.pagination}
                            getSummaryInvoices={getSummaryInvoices}
                            isIssuedSearch={isIssuedFunc(state.data)}
                        />
                    </Col>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Col span={24} className={'project-datatable'}>
                        <InsTab
                            type="card"
                            metaComponent={
                                <InsPagination
                                    {...state.pagination}
                                    onChange={pagination => getSummaryInvoices(pagination)}
                                    pageSizeOptions={[100, 200, 300]}
                                />
                            }
                            onChange={key => tabChangeHandler(key)}
                            activeKey={state.filters.is_issued.toString()}
                            defaultActiveKey="0"
                        >
                            <Tabs.TabPane tab={t('未請求')} key="0" />
                            <Tabs.TabPane tab={t('請求履歴')} key="1" />
                        </InsTab>
                        <InsDataTable
                            loading={state.loading ? { indicator: <InsLoader /> } : false}
                            data={projectGroup}
                            columns={!isIssuedFunc(state.data) ? userHeadOfficeColumns : userIssuedHeadColumns}
                            hasSelection={true}
                            rowKey={record => record.id}
                            onSelectionChanged={keys => handleSelectedKeysChanged(keys)}
                            subSelectedKeys={Object.keys(selectedKeys).map(k => Number(k))}
                            checkboxProps={record => ({
                                disabled:
                                    !isIssuedFunc(state.data) &&
                                    ![...record.group_projects_for_summary, record.project].find(
                                        p => p && !p.is_locked
                                    ),
                            })}
                            expandable={{
                                expandedRowRender: record => {
                                    return (
                                        <div style={{ padding: 16 }}>
                                            <InsDataTable
                                                columns={
                                                    !isIssuedFunc(state.data)
                                                        ? projectColumns
                                                        : projectColumnsWithRedSlip
                                                }
                                                data={
                                                    record.project
                                                        ? [...record.group_projects_for_summary, record.project]
                                                        : record.group_projects_for_summary
                                                }
                                                rowKey={e => e.id}
                                                pagination={false}
                                                hasSelection={true}
                                                bordered={true}
                                                onSelectionChanged={keys => handleSelectedKeysChanged(keys, record.id)}
                                                subSelectedKeys={selectedKeys[record.id] || []}
                                                checkboxProps={record => ({
                                                    disabled: !isIssuedFunc(state.data) && !!record.is_locked,
                                                })}
                                                onChange={() => {}}
                                            />
                                        </div>
                                    )
                                },
                                rowExpandable: r => r.name !== null,
                                expandIconColumnIndex: 8,
                            }}
                            onChange={(pagination, filter, sorter) => {
                                getSummaryInvoices(state.pagination, state.filters, sorter)
                            }}
                            pagination={state.pagination}
                            filters={state.filters}
                            sorter={state.sorter}
                            ctlSortOrder={true}
                        />
                    </Col>
                </Row>
            </div>
            <InsFloatBar visible={true}>
                <Row justify="space-between" align="middle">
                    <Col style={{ paddingLeft: 16 }}>
                        <Row className="mt-4">
                            <InsButton
                                disabled={!projectGroup.length}
                                style={{ width: 94 }}
                                icon={<CheckIcon size={16} />}
                                buttonStyle="default"
                                onClick={() => selectAll()}
                            >
                                {t('全て選択')}
                            </InsButton>
                        </Row>
                    </Col>
                    <Col className="summary-invoice-list-floatbar">
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">抽出された金額合計</label>
                            <span className="project-status-label summary-invoice-label ">
                                {formatNumberComma(stat.totalAmountExtracted)}
                            </span>
                        </span>
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">選択された金額合計</label>
                            <span className="project-status-label summary-invoice-label si-bigger">
                                {formatNumberComma(stat.totalAmountSelected)}
                            </span>
                        </span>

                        <SummaryInvoicingModal
                            title="請求書発行"
                            visible={modalInvoicingVisible}
                            onOk={() => {}}
                            onCancel={() => setModalInvoicingVisible(false)}
                            stat={stat}
                            componentRef={componentRef}
                            selectedKeys={selectedKeys}
                            setSelectedKeys={setSelectedKeys}
                            getSummaryInvoices={getSummaryInvoices}
                            reissueMode={
                                can('SUMMARY_INVOICE_EDITABLE') &&
                                !!Object.keys(selectedKeys).length &&
                                isIssuedFunc(state.data)
                            }
                            companyInfo={companyInfo}
                        >
                            {isIssuedFunc(state.data) ? (
                                <>
                                    {!Object.keys(selectedKeys).length && can('SUMMARY_INVOICE_EDITABLE') && (
                                        <InsButton
                                            className="fw-bold pl-16 pr-16"
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            disabled2={!Object.keys(selectedKeys).length}
                                        >
                                            {'請求書再発行'}
                                        </InsButton>
                                    )}
                                </>
                            ) : (
                                <>
                                    {can('SUMMARY_INVOICE_EDITABLE') && (
                                        <InsButton
                                            className="fw-bold pl-16 pr-16"
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            onClick={() =>
                                                Object.keys(selectedKeys).length && setModalInvoicingVisible(true)
                                            }
                                            disabled2={!Object.keys(selectedKeys).length}
                                        >
                                            {'まとめて請求書発行'}
                                        </InsButton>
                                    )}
                                </>
                            )}
                        </SummaryInvoicingModal>
                    </Col>
                </Row>
            </InsFloatBar>
        </>
    )
}

const mapState = state => ({ state: state.summaryInvoice, currentUser: state.auth.user })
const mapDispatch = { setKeywords }
export default connect(mapState, mapDispatch)(SummaryInvoiceList)

SummaryInvoiceList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    setKeywords: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
}
