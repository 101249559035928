import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Tabs, Checkbox } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getEnumText } from 'helpers/enum.helper'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import PropTypes from 'prop-types'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import { ActionLogType } from 'enums/action_log_type.enum'
import { ActionLogObject } from 'enums/action_log_object.enum'
import ActionLogListSearch from 'modules/business/actionLog/ActionLogListSearch'
import { setPagination } from 'modules/business/actionLog/action_log.slice'

const ActionLogListDetailComponent = ({ inModal = false, onSelect, state, getData, setFilters, setSorter }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const actionLogColumns = [
        {
            title: t('column:Action type'),
            dataIndex: 'action_type',
            sorter: true,
            render: text => t(`enum:${getEnumText(ActionLogType, text.toString())}`),
        },
        {
            title: t('column:Action object'),
            dataIndex: 'action_object',
            sorter: true,
            render: text => t(`enum:${getEnumText(ActionLogObject, text)}`),
        },
        {
            title: t('column:Project code'),
            dataIndex: 'project|project_code',
            sorter: true,
            render: (text, record) => record.project && record.project.project_code,
        },
        {
            title: t('column:Pic name'),
            dataIndex: 'pic|pic_name',
            sorter: true,
            render: (text, record) => record.pic && record.pic.pic_name,
        },
        {
            title: t('column:Action datetime'),
            dataIndex: 'action_datetime',
            sorter: true,
        },
    ]

    const getActionLogs = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(getData({ pagination, filters, sorter }))
    }

    const updateFilters = obj => {
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        const newFilters = Object.assign(obj)
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getActionLogs(newPagination, newFilters)
    }

    useEffect(() => {
        getActionLogs()
        // eslint-disable-next-line
    }, [])

    const tabChangeHandler = key => {
        const newFilters = Object.assign({}, state.filters, { is_enabled: key })
        dispatch(setFilters(newFilters))
        getActionLogs(state.pagination, newFilters)
    }
    return (
        <div>
            {!inModal && (
                <>
                    <Row justify="space-between">
                        <Col>
                            <h1 className="page-title mb-32">
                                {t('navigation:{{entity}} management', { entity: t('entity:ActionLog') })}
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[8, 16]} align="middle">
                        <Col span={24}>
                            <ActionLogListSearch
                                onSearch={condition => updateFilters(condition)}
                                onReset={() => updateFilters({})}
                                getActionLogs={getActionLogs}
                                pagination={state.pagination}
                            />
                        </Col>
                    </Row>
                </>
            )}

            <Row gutter={[8, 16]} align="middle">
                <Col span={24} className={'project-datatable'}>
                    {inModal && (
                        <>
                            <InsPagination {...state.pagination} onChange={pagination => getActionLogs(pagination)} />
                        </>
                    )}
                    {!inModal && (
                        <>
                            <InsTab
                                type="card"
                                className="hide-tabs"
                                metaComponent={
                                    <InsPagination
                                        {...state.pagination}
                                        onChange={pagination => getActionLogs(pagination)}
                                    />
                                }
                                onChange={key => tabChangeHandler(key)}
                            >
                                <Tabs.TabPane tab={'placeholder'} key="0" />
                            </InsTab>
                        </>
                    )}
                    <InsDataTable
                        // ref={r => (ref.current = r)}
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered={true}
                        data={state.data}
                        columns={actionLogColumns}
                        onChange={(pagination, filter, sorter) => {
                            getActionLogs(state.pagination, state.filters, sorter)
                        }}
                        hasSelection={true}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ActionLogListDetailComponent

ActionLogListDetailComponent.propTypes = {
    inModal: PropTypes.bool,
    onSelect: PropTypes.func,
    state: PropTypes.shape(BaseStatePropShape),
    getData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
}

ActionLogListDetailComponent.propTypes = {
    inModal: false,
}
