import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Radio, Row, Select } from 'antd'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import InsSelect from 'components/select/InsSelect'
import InsHelpText from 'components/InsHelpText'
import { AddIcon, CloseIcon, EditIcon } from 'components/InsIcon'
import UsageStatusEnum from 'enums/usage_status.enum'
import {
    CollectionScheduleDayEnum,
    CollectionScheduleFlagEnum,
    CollectionScheduleMonthEnum,
} from 'enums/collection_schedule.enum'
import { getClassificationsByType } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { BILL_DATE, COLLECTION_SCHEDULE1, COLLECTION_SCHEDULE2, SENDER_CODE } from 'constants/classification.constant'
import { BillAddressEnum } from 'enums/bill_address.enum'
import InsPostCodeInput from 'components/InsPostCodeInput'
import InsInput from 'components/InsInput'
import InsTextArea from 'components/InsTextArea'
import history from 'helpers/history.helper'
import UserSelect from 'modules/master/user/UserSelect'
import CustomerSelect from 'modules/master/customer/CustomerSelect'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsInputNumber from 'components/InsInputNumber'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import InsInputNumber2 from 'components/InsInputNumber2'
import InsModal from 'components/InsModal'

const CustomerForm = ({
    isEdit,
    initialValues = {},
    onCancel,
    onSubmit,
    inModal = false,
    modalConfirmSave,
    setModalConfirmSave,
}) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const clfCollectionScheduleType = getClassificationsByType(ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value)
    const clfCollectionScheduleMethod = getClassificationsByType(
        ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value
    )

    const clfBillDate = getClassificationsByType(ClassificationTypeEnum.BILL_DATE.value)

    const clfEstimationSender = getClassificationsByType(ClassificationTypeEnum.ESTIMATION_SENDER.value)
    const clfDeliveryNoteSender = getClassificationsByType(ClassificationTypeEnum.DELIVERY_NOTE_SENDER.value)
    const clfBillSender = getClassificationsByType(ClassificationTypeEnum.BILL_SENDER.value)

    initialValues = Object.assign(
        {
            is_enabled: UsageStatusEnum.ENABLED.value,
            address: '',
            customer_title: '2', // t('common:Mr'),
            credit_amount: 500000,

            bill_date_classification: BILL_DATE.BILL_DATE_DEFAULT,

            collection_schedule1_month: CollectionScheduleMonthEnum.NEXT_MONTH.value,
            // collection_schedule1_day: CollectionScheduleDayEnum.DATE_SELECTION.value,
            collection_schedule1_day: 25,

            collection_schedule1_collection_type: COLLECTION_SCHEDULE1.TYPE_DEFAULT,
            collection_schedule1_collection_method: COLLECTION_SCHEDULE1.METHOD_DEFAULT,

            collection_schedule2_collection_type: COLLECTION_SCHEDULE2.TYPE_DEFAULT,
            collection_schedule2_collection_method: COLLECTION_SCHEDULE2.METHOD_DEFAULT,

            collection_schedule2_month: CollectionScheduleMonthEnum.NEXT_MONTH.value,
            collection_schedule2_day: CollectionScheduleDayEnum.END_OF_THE_MONTH.value,
            collection_schedule2_amount: 0,
            collection_schedule2_flag: CollectionScheduleFlagEnum.NOT.value,

            estimation_sender_code: SENDER_CODE.ESTIMATION_DEFAULT,
            delivery_note_sender_code: SENDER_CODE.DELIVERY_NOTE_DEFAULT,
            bill_sender_code: SENDER_CODE.BILL_DEFAULT,

            bill_address_type: BillAddressEnum.SAME_AS_CUSTOMER_NAME.value,

            // customer_classification: 'P点',
            customer_category: '一般',
        },
        initialValues
    )

    if (initialValues.id && initialValues.bill_address_id !== initialValues.id) {
        initialValues.bill_address_type = BillAddressEnum.SET_SEPARATELY.value
    }

    initialValues.collection_schedule1_month_custom =
        [
            CollectionScheduleMonthEnum.CURRENT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_NEXT_MONTH.value,
        ].indexOf(initialValues.collection_schedule1_month) > -1
            ? initialValues.collection_schedule1_month
            : CollectionScheduleMonthEnum.DIRECT_SETTING.value
    const [collectionSchedule1MonthCustom, setCollectionSchedule1MonthCustom] = useState(
        initialValues.collection_schedule1_month_custom
    )

    initialValues.collection_schedule1_day_custom =
        initialValues.collection_schedule1_day === CollectionScheduleDayEnum.END_OF_THE_MONTH.value
            ? initialValues.collection_schedule1_day
            : CollectionScheduleDayEnum.DATE_SELECTION.value
    const [collectionSchedule1DayCustom, setCollectionSchedule1DayCustom] = useState(
        initialValues.collection_schedule1_day_custom
    )

    initialValues.collection_schedule2_month_custom =
        [
            CollectionScheduleMonthEnum.CURRENT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_NEXT_MONTH.value,
        ].indexOf(initialValues.collection_schedule2_month) > -1
            ? initialValues.collection_schedule2_month
            : CollectionScheduleMonthEnum.DIRECT_SETTING.value
    const [collectionSchedule2MonthCustom, setCollectionSchedule2MonthCustom] = useState(
        initialValues.collection_schedule2_month_custom
    )

    initialValues.collection_schedule2_day_custom =
        initialValues.collection_schedule2_day === CollectionScheduleDayEnum.END_OF_THE_MONTH.value
            ? initialValues.collection_schedule2_day
            : CollectionScheduleDayEnum.DATE_SELECTION.value
    const [collectionSchedule2DayCustom, setCollectionSchedule2DayCustom] = useState(
        initialValues.collection_schedule2_day_custom
    )

    const [collectionSchedule2Flag, setCollectionSchedule2Flag] = useState(initialValues.collection_schedule2_flag)

    const [estimationSenderCode, setEstimationSenderCode] = useState(initialValues.estimation_sender_code)
    const [deliveryNoteSenderCode, setDeliveryNoteSenderCode] = useState(initialValues.delivery_note_sender_code)
    const [billSenderCode, setBillSenderCode] = useState(initialValues.bill_sender_code)
    const [billAddressType, setBillAddressType] = useState(initialValues.bill_address_type)

    const convertToKana = async value => {
        const data = await api.get(APP_URLS.UTIL_CONVERT_KANA.replace(':value', value.target.value))
        if (data) {
            form.setFieldsValue({ customer_name_furigana: data })
        }
    }

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => onSubmit(form.getFieldsValue(), null, false)}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className="ins-form pt-0"
                layout="vertical"
                form={form}
                size={'middle'}
                onFinish={values => onSubmit(values)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <div className="ins-form-items">
                    <h2 className="section-title mb-24">{t('common:Basic information')}</h2>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="customer_code"
                                label={t('column:Customer code')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter customer code'),
                                    },
                                    {
                                        // trigger: 'onBlur',
                                        validator: async (rule, value) => {
                                            if (isEdit && initialValues.customer_code) return Promise.resolve()

                                            if (value) {
                                                const data = await api.post(APP_URLS.CUSTOMERS_CHECK_EXISTS_CODE, {
                                                    customer_code: value,
                                                })
                                                if (data && data.is_existed) {
                                                    return Promise.reject(t('validation:Customer code existed'))
                                                }
                                            }

                                            return Promise.resolve()
                                        },
                                    },
                                ]}
                                validateTrigger={['onBlur']}
                            >
                                <InsInput style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="customer_name"
                                label={t('column:Customer name')}
                                onBlur={convertToKana}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter customer name'),
                                    },
                                ]}
                                validateTrigger={['onBlur']}
                            >
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="customer_name_furigana" label={t('common:Furigana')}>
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="customer_short_name" label={t('column:Customer short name')}>
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="is_enabled" label={t('Usage status')}>
                                <Radio.Group>
                                    {Object.values(UsageStatusEnum).map(status => (
                                        <Radio key={status.value} value={status.value}>
                                            {t(`enum:${status.text}`)}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="post_code"
                                label={t('column:Post code')}
                                extra={
                                    <InsHelpText>
                                        {t('Please enter half size number in this format 000-0000')}
                                    </InsHelpText>
                                }
                                rules={[
                                    {
                                        pattern: /^\d{3}-?\d{4}$/,
                                        message: t('validation:{{item}} is invalid', {
                                            item: t('column:Post code'),
                                        }),
                                    },
                                ]}
                            >
                                <InsPostCodeInput
                                    onSearchSuccess={data => {
                                        if (data.prefecture_code) {
                                            form.setFieldsValue({ prefecture_code: data.prefecture_code })
                                        }
                                        if (data.address) {
                                            form.setFieldsValue({ address1: data.address })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="prefecture_code" label={t('column:Address')} className="mb-4">
                                <InsSelect style={{ width: 140 }}>
                                    {getClassificationsByType(ClassificationTypeEnum.PREFECTURE.value).map(c => (
                                        <Select.Option key={c.value} value={c.value}>
                                            {c.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                            <Form.Item name="address1" className="mb-4">
                                <InsInput style={{ width: 280 }} />
                            </Form.Item>
                            <Form.Item name="address2">
                                <InsInput style={{ width: 280 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="customer_title" label={t('column:Customer title')}>
                                <ClassificationSelect
                                    showCreateButton={false}
                                    typeEnum={ClassificationTypeEnum.CUSTOMER_TITLE}
                                    style={{ width: 140 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="phone_number"
                                label={t('column:Phone number')}
                                extra={<InsHelpText>{t('Help Phone number')}</InsHelpText>}
                                rules={[
                                    {
                                        pattern: /^[0-9]?([-]?[\s]?[0-9])+$/,
                                        message: t('validation:Please enter half-width numbers'),
                                    },
                                ]}
                                validateTrigger={['onBlur']}
                            >
                                <InsInput placeholder={'0000-0000-0000'} style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="fax_number" label={t('column:Fax number')} className="mb-0">
                                <InsInput style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <React.Fragment>
                        <h2 className="section-title mb-24">{t('common:Additional Information')}</h2>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="customer_classification" label={t('column:Customer classification')}>
                                    <ClassificationSelect
                                        showCreateButton={false}
                                        typeEnum={ClassificationTypeEnum.CUSTOMER}
                                        style={{ width: 140 }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item
                                    name="customer_industry_classification"
                                    label={t('column:Customer category')}
                                >
                                    <ClassificationSelect
                                        showCreateButton={false}
                                        typeEnum={ClassificationTypeEnum.CUSTOMER_INDUSTRY}
                                        style={{ width: 140 }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="pic_id" label={t('column:Inashin pic name')}>
                                    <UserSelect
                                        record={initialValues.pic}
                                        style={{ width: 180 }}
                                        placeholder={t('Search for inashin pic')}
                                        dropdownMatchSelectWidth={false}
                                        onChange={(id, user) => {
                                            form.setFieldsValue({ pic_code: (user.item || user)?.team?.team_name })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="pic_code" label={t('column:Team')}>
                                    <InsInput style={{ width: 140 }} maxLength={100} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="note" label={t('column:Note')} className="mb-24">
                                    <InsTextArea style={{ width: 280 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </React.Fragment>

                    <React.Fragment>
                        <h2 className="section-title mb-24">{t('common:Billing information')}</h2>
                        <Row className="ml-16 mb-28">
                            <Col>
                                <Form.Item
                                    name="bill_address_type"
                                    label={t('column:Bill address code')}
                                    className="mb-4"
                                >
                                    <InsSelect
                                        onSelect={value => {
                                            setBillAddressType(value)
                                            if (
                                                !isEdit ||
                                                initialValues.bill_customer?.id !==
                                                    form.getFieldValue('bill_address_id')
                                            ) {
                                                form.setFieldsValue({ bill_address_id: undefined })
                                            }
                                        }}
                                        style={{ width: 140 }}
                                    >
                                        {Object.values(BillAddressEnum).map(m => (
                                            <Select.Option key={m.value} value={m.value}>
                                                {m.text}
                                            </Select.Option>
                                        ))}
                                    </InsSelect>
                                </Form.Item>
                                {billAddressType === BillAddressEnum.SET_SEPARATELY.value && (
                                    <Form.Item name="bill_address_id" className="mb-4">
                                        <CustomerSelect
                                            record={initialValues.bill_customer}
                                            style={{ width: 280 }}
                                            placeholder={t('Search by customer name')}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>

                        {billAddressType !== BillAddressEnum.SET_SEPARATELY.value && (
                            <>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="credit_amount"
                                            label={t('column:Credit amount')}
                                            extra={<InsHelpText>{t('Help Credit amount')}</InsHelpText>}
                                        >
                                            <InsInputNumber
                                                min={0}
                                                className="no-spinner no-spinner-right"
                                                style={{ width: 140, textAlign: 'right' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="bill_date_classification"
                                            label={t('column:Billing deadline classification')}
                                        >
                                            <InsSelect style={{ width: 140 }}>
                                                {clfBillDate.map(clf => (
                                                    <Select.Option key={clf.item_value} value={clf.item_value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="collection_schedule1_month_custom"
                                            label={t('column:Collection schedule')}
                                            className="mr-4"
                                        >
                                            <InsSelect
                                                onSelect={value => {
                                                    form.setFieldsValue({
                                                        collection_schedule1_month:
                                                            value === CollectionScheduleMonthEnum.DIRECT_SETTING.value
                                                                ? 3
                                                                : value,
                                                    })
                                                    setCollectionSchedule1MonthCustom(value)
                                                }}
                                                style={{ width: 140 }}
                                            >
                                                {Object.values(CollectionScheduleMonthEnum).map(m => (
                                                    <Select.Option key={m.value} value={m.value}>
                                                        {m.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        style={{
                                            display:
                                                collectionSchedule1MonthCustom ===
                                                CollectionScheduleMonthEnum.DIRECT_SETTING.value
                                                    ? 'unset'
                                                    : 'none',
                                        }}
                                    >
                                        <Form.Item
                                            name="collection_schedule1_month"
                                            label={' '}
                                            className="mr-4 hide-require fix-schedule-style"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'validation:Please enter customer collection schedule month'
                                                    ),
                                                },
                                            ]}
                                            validateTrigger={['onBlur']}
                                        >
                                            <InsInputNumber
                                                className="no-spinner"
                                                min={0}
                                                max={127}
                                                style={{ width: 70 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="collection_schedule1_day_custom" label={' '} className="mr-4">
                                            <InsSelect
                                                onSelect={value => {
                                                    form.setFieldsValue({
                                                        collection_schedule1_day:
                                                            value === CollectionScheduleDayEnum.DATE_SELECTION.value
                                                                ? 1
                                                                : value,
                                                    })
                                                    setCollectionSchedule1DayCustom(value)
                                                }}
                                                style={{ width: 140 }}
                                            >
                                                {Object.values(CollectionScheduleDayEnum).map(d => (
                                                    <Select.Option key={d.value} value={d.value}>
                                                        {d.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        style={{
                                            display:
                                                collectionSchedule1DayCustom ===
                                                CollectionScheduleDayEnum.DATE_SELECTION.value
                                                    ? 'unset'
                                                    : 'none',
                                        }}
                                    >
                                        <Form.Item
                                            name="collection_schedule1_day"
                                            label={' '}
                                            className="hide-require"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'validation:Please enter customer collection schedule day'
                                                    ),
                                                },
                                            ]}
                                            validateTrigger={['onBlur']}
                                        >
                                            <InsInputNumber
                                                className="no-spinner"
                                                min={1}
                                                max={31}
                                                style={{ width: 70 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="collection_schedule1_collection_type"
                                            label={t('column:Collection type')}
                                        >
                                            <InsSelect style={{ width: 140 }}>
                                                {clfCollectionScheduleType.map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="collection_schedule1_collection_method"
                                            label={t('column:Collection method')}
                                        >
                                            <InsSelect style={{ width: 140 }}>
                                                {clfCollectionScheduleMethod.map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="collection_schedule2_flag"
                                            label={t('column:Collection schedule 2 flag')}
                                        >
                                            <Radio.Group
                                                onChange={e => setCollectionSchedule2Flag(e.target.value)}
                                                style={{ width: 150 }}
                                            >
                                                {Object.values(CollectionScheduleFlagEnum).map(f => (
                                                    <Radio key={f.value} value={f.value}>
                                                        {f.text}
                                                    </Radio>
                                                ))}
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {collectionSchedule2Flag === CollectionScheduleFlagEnum.MAKE.value && (
                                    <React.Fragment>
                                        <Row className="ml-16">
                                            <Col>
                                                <Form.Item
                                                    name="collection_schedule2_amount"
                                                    label={t('column:Collection schedule 2 amount')}
                                                    extra={
                                                        <InsHelpText>
                                                            {t('Help Collection schedule 2 amount')}
                                                        </InsHelpText>
                                                    }
                                                >
                                                    <InsInputNumber2 style={{ width: 140 }} min={0} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="ml-16">
                                            <Col>
                                                <Form.Item
                                                    name="collection_schedule2_collection_type"
                                                    label={t('column:Collection type (Collection schedule 2)')}
                                                >
                                                    <InsSelect style={{ width: 140 }}>
                                                        {clfCollectionScheduleType.map(clf => (
                                                            <Select.Option key={clf.value} value={clf.value}>
                                                                {clf.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="ml-16">
                                            <Col>
                                                <Form.Item
                                                    name="collection_schedule2_collection_method"
                                                    label={t('column:Collection method (Collection schedule 2)')}
                                                >
                                                    <InsSelect style={{ width: 140 }}>
                                                        {clfCollectionScheduleMethod.map(clf => (
                                                            <Select.Option key={clf.value} value={clf.value}>
                                                                {clf.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="ml-16">
                                            <Col>
                                                <Form.Item
                                                    name="collection_schedule2_month_custom"
                                                    label={t('column:Collection schedule (Collection schedule 2)')}
                                                    className="mb-0"
                                                >
                                                    <InsSelect
                                                        onSelect={value => {
                                                            form.setFieldsValue({
                                                                collection_schedule2_month:
                                                                    value ===
                                                                    CollectionScheduleMonthEnum.DIRECT_SETTING.value
                                                                        ? 3
                                                                        : value,
                                                            })
                                                            setCollectionSchedule2MonthCustom(value)
                                                        }}
                                                        className="mr-4"
                                                        style={{ width: 160 }}
                                                    >
                                                        {Object.values(CollectionScheduleMonthEnum).map(m => (
                                                            <Select.Option key={m.value} value={m.value}>
                                                                {m.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                style={{
                                                    display:
                                                        collectionSchedule2MonthCustom ===
                                                        CollectionScheduleMonthEnum.DIRECT_SETTING.value
                                                            ? 'unset'
                                                            : 'none',
                                                }}
                                            >
                                                <Form.Item
                                                    name="collection_schedule2_month"
                                                    label={' '}
                                                    className="mr-4 hide-require fix-schedule-style"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'validation:Please enter customer collection schedule month'
                                                            ),
                                                        },
                                                    ]}
                                                    validateTrigger={['onBlur']}
                                                >
                                                    <InsInputNumber
                                                        className="no-spinner"
                                                        min={0}
                                                        max={127}
                                                        style={{ width: 70 }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item
                                                    name="collection_schedule2_day_custom"
                                                    label={' '}
                                                    className="mr-4"
                                                >
                                                    <InsSelect
                                                        onSelect={value => {
                                                            form.setFieldsValue({
                                                                collection_schedule2_day:
                                                                    value ===
                                                                    CollectionScheduleDayEnum.DATE_SELECTION.value
                                                                        ? 1
                                                                        : value,
                                                            })
                                                            setCollectionSchedule2DayCustom(value)
                                                        }}
                                                        style={{ width: 140 }}
                                                    >
                                                        {Object.values(CollectionScheduleDayEnum).map(d => (
                                                            <Select.Option key={d.value} value={d.value}>
                                                                {d.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                style={{
                                                    display:
                                                        collectionSchedule2DayCustom ===
                                                        CollectionScheduleDayEnum.DATE_SELECTION.value
                                                            ? 'unset'
                                                            : 'none',
                                                }}
                                            >
                                                <Form.Item
                                                    name="collection_schedule2_day"
                                                    label={' '}
                                                    className="hide-require"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'validation:Please enter customer collection schedule day'
                                                            ),
                                                        },
                                                    ]}
                                                    validateTrigger={['onBlur']}
                                                >
                                                    <InsInputNumber
                                                        className="no-spinner"
                                                        min={1}
                                                        max={127}
                                                        style={{ width: 70 }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </>
                        )}
                    </React.Fragment>

                    <React.Fragment>
                        <h2 className="section-title mb-24">{t('common:Display information')}</h2>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="estimation_sender_code" label={t('column:Estimation sender code')}>
                                    <InsSelect
                                        onSelect={value => setEstimationSenderCode(value)}
                                        style={{ width: 140 }}
                                    >
                                        {clfEstimationSender.map(clf => (
                                            <Select.Option key={clf.value} value={clf.value}>
                                                {clf.text}
                                            </Select.Option>
                                        ))}
                                    </InsSelect>
                                </Form.Item>
                                {estimationSenderCode === SENDER_CODE.ESTIMATION_OTHER && (
                                    <>
                                        <Form.Item name="estimation_sender_name" className="mb-8">
                                            <InsInput
                                                placeholder={t('Sender name')}
                                                style={{ width: 140 }}
                                                maxLength={200}
                                            />
                                        </Form.Item>
                                        <Form.Item name="estimation_sender_address">
                                            <InsTextArea
                                                placeholder={t('Sender address')}
                                                style={{ width: 280 }}
                                                maxLength={200}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item
                                    name="delivery_note_sender_code"
                                    label={t('column:Delivery note sender code')}
                                >
                                    <InsSelect
                                        onSelect={value => setDeliveryNoteSenderCode(value)}
                                        style={{ width: 140 }}
                                    >
                                        {clfDeliveryNoteSender.map(clf => (
                                            <Select.Option key={clf.value} value={clf.value}>
                                                {clf.text}
                                            </Select.Option>
                                        ))}
                                    </InsSelect>
                                </Form.Item>
                                {deliveryNoteSenderCode === SENDER_CODE.DELIVERY_NOTE_OTHER && (
                                    <>
                                        <Form.Item name="delivery_note_sender_name" className="mb-8">
                                            <InsInput
                                                placeholder={t('Sender name')}
                                                style={{ width: 140 }}
                                                maxLength={200}
                                            />
                                        </Form.Item>
                                        <Form.Item name="delivery_note_sender_address">
                                            <InsTextArea
                                                placeholder={t('Sender address')}
                                                style={{ width: 280 }}
                                                maxLength={200}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row className="ml-16" style={{ marginBottom: 120 }}>
                            <Col>
                                <Form.Item
                                    name="bill_sender_code"
                                    label={t('column:Bill sender code')}
                                    className="mb-8"
                                >
                                    <InsSelect onSelect={value => setBillSenderCode(value)} style={{ width: 140 }}>
                                        {clfBillSender.map(clf => (
                                            <Select.Option key={clf.value} value={clf.value}>
                                                {clf.text}
                                            </Select.Option>
                                        ))}
                                    </InsSelect>
                                </Form.Item>
                                {billSenderCode === SENDER_CODE.BILL_OTHER && (
                                    <>
                                        <Form.Item name="bill_sender_name" className="mb-8">
                                            <InsInput
                                                placeholder={t('Sender name')}
                                                style={{ width: 140 }}
                                                maxLength={200}
                                            />
                                        </Form.Item>
                                        <Form.Item name="bill_sender_address">
                                            <InsTextArea
                                                placeholder={t('Sender address')}
                                                style={{ width: 280 }}
                                                maxLength={200}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                </div>

                <InsFloatBar visible={true}>
                    <Row gutter={8} justify={inModal ? 'center' : 'end'}>
                        <Col>
                            <InsButton
                                buttonStyle="outline"
                                buttonSize="big"
                                centered
                                width="120px"
                                icon={<CloseIcon />}
                                onClick={() => {
                                    if (!inModal) {
                                        // history.push(Routes.private.master.CUSTOMER_LIST.path)
                                        history.goBack()
                                    } else {
                                        onCancel && onCancel()
                                    }
                                }}
                            >
                                {t('common:Cancel')}
                            </InsButton>
                        </Col>
                        {isEdit ? (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<EditIcon size={20} />}
                                    isSubmitButton={true}
                                >
                                    {t('navigation:{{entity}} update new', { entity: t('entity:Customer') })}
                                </InsButton>
                            </Col>
                        ) : (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<AddIcon />}
                                    isSubmitButton={true}
                                >
                                    {t('navigation:{{entity}} create new', { entity: t('entity:Customer') })}
                                </InsButton>
                            </Col>
                        )}
                    </Row>
                </InsFloatBar>
            </Form>
        </>
    )
}

export default CustomerForm

CustomerForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    inModal: PropTypes.bool,
    onCancel: PropTypes.func,
}
