import React from 'react'
import InsButton from 'components/InsButton'
import { AddIcon } from 'components/InsIcon'
import InsDropdown from 'components/dropdown/InsDropdown'
import { addDetail } from 'modules/business/sale/sale_detail.slice'
import { connect } from 'react-redux'

const SaleDetailAddButton = ({ addDetail }) => {
    return (
        <InsDropdown
            onSelect={key => {
                let isHeadline = true
                if (key.key === 'product') {
                    isHeadline = false
                }
                addDetail({ isHeadline })
            }}
            options={[
                { key: 'product', text: '商品入力' },
                { key: 'headline', text: '見出し' },
            ]}
            trigger={['click']}
        >
            <InsButton icon={<AddIcon className="color-pantone-2738-c" />} buttonStyle="default">
                行追加
            </InsButton>
        </InsDropdown>
    )
}

const mapDispatch = { addDetail }
export default connect(null, mapDispatch)(SaleDetailAddButton)
