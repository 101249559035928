import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Checkbox, Col, Form, Radio, Row, Select, Tooltip } from 'antd'
import InsInput from 'components/InsInput'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsSelect from 'components/select/InsSelect'
import { HelpOutlineIcon, PreviewIcon, SearchIcon } from 'components/InsIcon'
import { getClassificationItemName, getClassificationsByType } from 'helpers/classification.helper'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import UserSelect from 'modules/master/user/UserSelect'
import InsFloatBar from 'components/InsFloatBar'
import InsButton from 'components/InsButton'
import InsTextArea from 'components/InsTextArea'
import moment from 'moment'
import InsInputNumber from 'components/InsInputNumber'
import {
    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,
    setPlaceOrderClassification,
    setDeliveryDestinationType,
    parseData,
    setDefaultState,
} from 'modules/business/placeOrder/place_order_detail.slice'
import PlaceOrderDragAndDrop from 'modules/business/placeOrder/form/PlaceOrderDragAndDrop'
import PlaceOrderDetailAddButton from 'modules/business/placeOrder/form/PlaceOrderDetailAddButton'
import { SENDER_CODE } from 'constants/classification.constant'
import OutsourceSelect from 'modules/master/outsource/OutsourceSelect'
import history from 'helpers/history.helper'
import { DeliveryTimeEnum } from 'enums/delivery_time.enum'
import InsModal from 'components/InsModal'
import Routes from 'routes'
import { calculateTotal } from 'helpers/calculate.helper'
import SupplierSelect from 'modules/master/supplier/SupplierSelect'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import RouterPrompt from 'modules/common/ui/RouterPrompt'
import { useLocation } from 'react-router-dom'
import { isHeadline } from 'helpers/detail.helper'
import { showWarning } from 'helpers/notification.helper'
import PlaceOrderDOMPrint from 'modules/business/placeOrder/PlaceOrderDOMPrint'
import InsAutoComplete from 'components/InsAutoComplete'
import { handleScrollOnFocus } from 'helpers/form_event.helper'

const PlaceOrderForm = ({
    isEdit,
    initialValues = {},
    onSubmit,
    project,
    destinations,
    destinationsOrder,
    details,
    detailsOrder,
    deliveryDestinationType,
    directDeliveryType,
    parseData,
    changedData,
    projectForm,
}) => {
    const { t } = useTranslation()
    const [form] = projectForm ? [projectForm] : Form.useForm()
    const dispatch = useDispatch()
    const [modalPlaceOrderPreview, setModalPlaceOrderPreview] = useState(false)
    const [placeOrderPreviewModal, setPlaceOrderPreviewModal] = useState({})
    const [firstRender, setFirstRender] = useState(true)
    const [isValueChange, setIsValueChange] = useState(!isEdit)
    const isPurchaseOrder = project.is_purchase_order
    const [companies, setCompanies] = useState([])
    const [modalConfirmSave, setModalConfirmSave] = useState(false)
    const location = useLocation()
    const text = '「チェックをすると、入力された「商品単価」を原価に反映しないように設定できます。」'

    // parse data for update
    const parseDataForUpdate = () => {
        if (initialValues.id) {
            parseData({
                destinations: initialValues.delivery_destinations,
                details: initialValues.details,
            })
        }
    }
    const calculateSum = details => {
        const result = calculateTotal(details)

        setPlaceOrderPreviewModal({
            ...placeOrderPreviewModal,
            ...result,
        })
        form.setFieldsValue(result)
    }

    // copy sender
    let useSenderCopy = false
    if (!isEdit) {
        if (initialValues.sender_id && initialValues.sender_id === project?.customer?.estimation_sender_code) {
            useSenderCopy = true
        } else {
            delete initialValues['sender_id']
            delete initialValues['sender_name']
            delete initialValues['sender_address']
        }
    }

    const DIRECT_DELIVERY = '1' // 1: 直送 - classification 391
    initialValues = Object.assign(
        {
            project_id: project.id,
            // outsource_id: initialValues.outsource.id,
            outsource_pic_title: 1,
            place_order_code: project.project_code + '-OD-' + (project?.count_place_order ?? '1'),
            place_order_classification: isPurchaseOrder ? 3 : 1, // 400
            delivery_destination: DIRECT_DELIVERY, // 1: 直送 - classification 391
            delivery_type: '1', // 392
            transport_type: '1', // 393
            sender_id: project?.customer?.estimation_sender_code || SENDER_CODE.PLACE_ORDER_DEFAULT,
            sender_name: project?.customer?.estimation_sender_name || '',
            sender_address: project?.customer?.estimation_sender_address || '',
            // delivery_destinations: [{}],
            delivery_time: 0,
            pic_id: project.pic_id,
            pic: project.pic,
            details: [],
            gross_profit: 0,
            subtotal: 0,
            consumption_tax: 0,
            total: 0,
            place_order_pic_id: project.pic_id,
            place_order_name: project?.project_name,
            outsource_pic_name: initialValues?.outsource?.pic?.pic_name,
        },
        initialValues
    )

    // clone - generate project_code -> place_order_code
    useEffect(() => {
        if (!initialValues.place_order_code.startsWith(project.project_code)) {
            form.setFieldsValue({ place_order_code: project.project_code + '-OD-' + 1 })
        }
    }, [project.project_code])

    // set place_order_classification via estimation
    if (initialValues.estimation_classification) {
        initialValues.place_order_classification = initialValues.estimation_classification
    }
    // set place_order_classification via sale
    if (initialValues.sale_classification) {
        initialValues.place_order_classification = initialValues.sale_classification
    }

    const dateFormat = 'YYYY年M月DD日'
    const [customer, setCustomer] = useState(initialValues.customer || {})
    const [outsourcePicName, setOutsourcePicName] = useState(initialValues.outsource_pic_name)
    const [supplierPicName, setSupplierPicName] = useState(initialValues.supplier_pic_name)

    initialValues.issued_date = initialValues.issued_date ? moment(initialValues.issued_date, 'YYYY-MM-DD') : moment()
    initialValues.delivery_date = initialValues.delivery_date
        ? moment(initialValues.delivery_date, 'YYYY-MM-DD')
        : moment()

    const getCompanies = async () => {
        const data = await api.get(APP_URLS.COMPANIES)
        if (data && data.list) {
            setCompanies(data.list)
            // special
            if (!isEdit && initialValues.sender_id !== SENDER_CODE.DELIVERY_NOTE_OTHER) {
                // set title
                const company = getCompanyByCode(initialValues.sender_id, data.list)
                form.setFieldsValue({
                    sender_name: company.company_name,
                    sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
                })
            }
        }
    }

    const getCompanyByCode = (company_code, tmpCompanies) => {
        const filterCompanies = (tmpCompanies || companies).filter(c => c.company_code === company_code)
        return filterCompanies.length > 0 ? filterCompanies[0] : {}
    }

    const makeCompanyInfo = company => {
        let prefecture = getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, company.prefecture_code)
        return `〒${company.post_code}${prefecture}${company.address1}${company.address2 || ''}\nTEL：${
            company.phone_number
            // eslint-disable-next-line no-irregular-whitespace
        }　　FAX：${company.fax_number ? company.fax_number : ''}`
    }

    const handlerSenderIdChange = senderId => {
        if (senderId === SENDER_CODE.ESTIMATION_OTHER) {
            if (!customer?.id || form.getFieldValue('customer_id') === project?.customer_id) {
                form.setFieldsValue({
                    sender_name: project?.customer?.estimation_sender_name || '',
                    sender_address: project?.customer?.estimation_sender_address || '',
                })
            } else
                form.setFieldsValue({
                    sender_name: customer?.estimation_sender_name || '',
                    sender_address: customer?.estimation_sender_address || '',
                })
        } else {
            const company = getCompanyByCode(senderId)
            form.setFieldsValue({
                sender_name: company.company_name,
                sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
            })
        }
    }

    const parseDataForSubmit = (values, isPreview = false) => {
        values.delivery_destinations = []
        values.details = []
        values.delivery_date =
            values.delivery_date && moment(values.delivery_date, dateFormat, true).format('YYYY-MM-DD')
        values.issued_date = values.issued_date && moment(values.issued_date, dateFormat, true).format('YYYY-MM-DD')
        if (isPurchaseOrder || deliveryDestinationType !== '1') {
            Object.values(detailsOrder)
                .flat()
                .map(key => {
                    let detail = details[key]
                    values.details.push(detail)
                })
        } else {
            if (detailsOrder['destinationUnknown'] && detailsOrder['destinationUnknown'].length > 0 && !isPreview) {
                showWarning('', '納入先を入力してください。')
                return null
            } else {
                if (destinationsOrder.length > 0) {
                    destinationsOrder.map(destinationOrder => {
                        if (destinationOrder === 'destinationUnknown') return null
                        let destination = { ...destinations[destinationOrder] }
                        destination.details = []
                        detailsOrder[destinationOrder].map(detailOrder => {
                            let detail = details[detailOrder]
                            destination.details.push(detail)
                            return null
                        })
                        values.delivery_destinations.push(destination)
                        return null
                    })
                }
            }
        }
        return values
    }

    const processOnSubmit = (values, redirectTo, confirmSave = true) => {
        if (isValueChange && isCreateProject) {
            setIsValueChange(false)
        }
        values = parseDataForSubmit(values)
        if (values.delivery_destinations.length === 0) {
            let filterDetails = values.details.filter(detail => !isHeadline(detail))
            if (filterDetails.length < 1 || filterDetails.filter(detail => !detail.title).length > 0) {
                showWarning('', '品名を入力してください。')
                return
            }
        } else {
            let filterDeliveryDestinations = values.delivery_destinations.filter(deliveryDestination => {
                let filterDetails = deliveryDestination.details.filter(detail => !isHeadline(detail))
                return filterDetails.length > 0
            })
            if (
                filterDeliveryDestinations.length < 1 ||
                filterDeliveryDestinations.filter(des => des.details.filter(d => !isHeadline(d) && !d.title).length > 0)
                    .length > 0
            ) {
                showWarning('', '品名を入力してください。')
                return
            }
        }

        if (confirmSave) setModalConfirmSave(true)

        if (values && !confirmSave) {
            if (projectForm) {
                const projectValues = {
                    id: project.id,
                    status: values.status,
                    project_name: values.project_name,
                    project_code: project.project_code,
                    customer_id: values.customer_id,
                    is_purchase_order: values.is_purchase_order,
                    pic_id: values.pic_id,
                    note: values.note,
                    place_order: { ...values, ...{ note: values.place_order_note, pic_id: values.place_order_pic_id } },
                }
                onSubmit(projectValues, redirectTo)
            } else {
                onSubmit(values, redirectTo)
            }
            return true
        }
    }

    const tmpDirectDeliveryType =
        initialValues.delivery_destinations && initialValues.delivery_destinations.length === 1 ? 2 : 1
    useEffect(() => {
        calculateSum(details)
        if (firstRender) {
            getCompanies().then(r => {})
            dispatch(setDefaultState())
            dispatch(setPlaceOrderClassification(initialValues.place_order_classification))
            dispatch(
                setDeliveryDestinationType({
                    deliveryDestinationType: initialValues.delivery_destination,
                    directDeliveryType: tmpDirectDeliveryType,
                })
            )
            if (isEdit || initialValues.id) {
                parseDataForUpdate()
            }
        }
        setFirstRender(false)
    }, [details])

    useEffect(() => {
        if (isEdit && changedData && !isValueChange) {
            setIsValueChange(true)
        }
    }, [changedData])

    const isCreateProject = location.pathname.includes(Routes.private.business.PROJECT_CREATE.path)
    const canEditPlaceOrder = can('PLACE_ORDER_EDITABLE') && !project.is_locked

    return (
        <>
            {canEditPlaceOrder ? (
                <>
                    {modalConfirmSave && (
                        <InsModal
                            title="確認"
                            visible={modalConfirmSave}
                            onCancel={() => setModalConfirmSave(false)}
                            onOk={() => processOnSubmit(form.getFieldsValue(), null, false)}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <p>保存しますか？</p>
                        </InsModal>
                    )}
                    <RouterPrompt
                        handleSave={path => {
                            let values = form.getFieldsValue()
                            return processOnSubmit(values, path, false)
                        }}
                        isValueChange={isValueChange}
                        navigate={path => {
                            this.context.history.push(path)
                        }}
                        setDefaultState={() => dispatch(setDefaultState())}
                    />
                    <div className="form-place-order bl-form">
                        <InsModal
                            title={t('Place order preview')}
                            visible={modalPlaceOrderPreview}
                            onCancel={() => setModalPlaceOrderPreview(false)}
                            okText="はい"
                            cancelText="いいえ"
                            footer={null}
                            className="preview-modal"
                        >
                            <PlaceOrderDOMPrint
                                project={project}
                                placeOrderPrint={Object.assign(initialValues, placeOrderPreviewModal)}
                                showPreview={true}
                                processPrint={false}
                            />
                        </InsModal>
                        <Form
                            form={form}
                            initialValues={initialValues}
                            onFinish={values => processOnSubmit(values)}
                            onValuesChange={() => {
                                if (isEdit && !isValueChange) {
                                    setIsValueChange(true)
                                }
                            }}
                        >
                            <Row>
                                <Col>
                                    <h1 className="bl-form-title">{t('entity:PlaceOrder')}作成</h1>
                                </Col>
                            </Row>

                            <Form.Item name="project_id" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>

                            <Row className="mb-16">
                                <Col span={12}>
                                    <Form.Item name="place_order_classification" label="区分">
                                        <InsSelect
                                            style={{ width: 180 }}
                                            onChange={value => {
                                                dispatch(setPlaceOrderClassification(value))
                                            }}
                                            defaultValue={initialValues.place_order_classification}
                                            disabled={isPurchaseOrder}
                                        >
                                            {getClassificationsByType(
                                                ClassificationTypeEnum.PLACE_ORDER_CLASSIFICATION.value
                                            ).map(clf => (
                                                <Select.Option
                                                    key={clf.value}
                                                    value={parseInt(clf.value)}
                                                    disabled={!isPurchaseOrder ? parseInt(clf.value) === 3 : false}
                                                >
                                                    {clf.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>

                                    {isPurchaseOrder ? (
                                        <>
                                            <Form.Item label={t('column:Supplier name')}>
                                                <Form.Item
                                                    name="supplier_id"
                                                    noStyle
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('validation:Please enter {{item}}', {
                                                                item: t('column:Supplier name'),
                                                            }),
                                                        },
                                                    ]}
                                                >
                                                    <SupplierSelect
                                                        placeholder={t('Supplier search')}
                                                        record={initialValues.supplier}
                                                        style={{ width: 274 }}
                                                        onChange={(id, supplier) => {
                                                            placeOrderPreviewModal.supplier = supplier.item || supplier
                                                            setSupplierPicName(
                                                                (supplier.item || supplier)?.supplier_pic_name
                                                            )
                                                            form.setFieldsValue({
                                                                supplier_pic_name: (supplier.item || supplier)
                                                                    ?.supplier_pic_name,
                                                                supplier_pic_title: (supplier.item || supplier)
                                                                    ?.supplier_title,
                                                            })
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item label={t('column:Customer pic name')}>
                                                <Form.Item
                                                    name="supplier_pic_name"
                                                    noStyle
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: t('validation:Please enter {{item}}', {
                                                    //             item: t('column:Supplier pic name'),
                                                    //         }),
                                                    //     },
                                                    // ]}
                                                >
                                                    <InsAutoComplete
                                                        options={supplierPicName ? [supplierPicName] : []}
                                                        onSearch={() => {}}
                                                        placeholder="担当者検索"
                                                        className="inline"
                                                        style={{ width: 180 }}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="supplier_pic_title" noStyle>
                                                    <InsSelect style={{ width: 90, marginLeft: 4 }}>
                                                        {getClassificationsByType(
                                                            ClassificationTypeEnum.CUSTOMER_TITLE.value
                                                        ).map(clf => (
                                                            <Select.Option key={clf.value} value={clf.value}>
                                                                {clf.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Form.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Form.Item label={t('column:Outsource name')}>
                                                <Form.Item name="outsource_id" noStyle>
                                                    <OutsourceSelect
                                                        placeholder={t('Outsource search')}
                                                        record={initialValues.outsource}
                                                        style={{ width: 274 }}
                                                        onChange={(id, outsource) => {
                                                            placeOrderPreviewModal.outsource =
                                                                outsource.item || outsource
                                                            setOutsourcePicName(
                                                                (outsource.pic || outsource.item?.pic)?.pic_name
                                                            )
                                                            form.setFieldsValue({
                                                                outsource_pic_name: (
                                                                    outsource.pic || outsource.item?.pic
                                                                )?.pic_name,
                                                            })
                                                        }}
                                                        // showCreateButton={false}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item label={t('column:Customer pic name')}>
                                                <Form.Item name="outsource_pic_name" noStyle>
                                                    <InsAutoComplete
                                                        options={outsourcePicName ? [outsourcePicName] : []}
                                                        onSearch={() => {}}
                                                        placeholder="担当者検索"
                                                        className="inline"
                                                        style={{ width: 180 }}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="outsource_pic_title" noStyle>
                                                    <InsSelect style={{ width: 90, marginLeft: 4 }}>
                                                        {getClassificationsByType(
                                                            ClassificationTypeEnum.CUSTOMER_TITLE.value
                                                        ).map(clf => (
                                                            <Select.Option key={clf.value} value={parseInt(clf.value)}>
                                                                {clf.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Form.Item>
                                        </>
                                    )}
                                    <Form.Item name="place_order_name" label={t('column:件名')}>
                                        <InsInput
                                            style={{ width: 340 }}
                                            placeholder="件名入力"
                                            showMaxLength
                                            maxLength={40}
                                        />
                                    </Form.Item>
                                    <Form.Item label={t('column:納期')}>
                                        <Form.Item name="delivery_date" noStyle>
                                            <InsDatePicker
                                                format={dateFormat}
                                                placeholder={t('Enter delivery date')}
                                                style={{ width: 180 }}
                                                allowClear={false}
                                            />
                                        </Form.Item>
                                        <Form.Item name="delivery_time" noStyle>
                                            <Radio.Group
                                                onChange={() => {}}
                                                value={initialValues.delivery_time}
                                                style={{ marginLeft: 8 }}
                                            >
                                                {Object.values(DeliveryTimeEnum).map(option => (
                                                    <Radio value={option.value}> {option.text}</Radio>
                                                ))}
                                            </Radio.Group>
                                        </Form.Item>
                                    </Form.Item>
                                    {!isPurchaseOrder && (
                                        <Form.Item label={t('column:Delivery destination')}>
                                            <Form.Item name="delivery_destination" noStyle>
                                                <InsSelect
                                                    style={{ width: 92 }}
                                                    onSelect={value => {
                                                        dispatch(
                                                            setDeliveryDestinationType({
                                                                deliveryDestinationType: value,
                                                                directDeliveryType: parseInt(DIRECT_DELIVERY),
                                                            })
                                                        )
                                                    }}
                                                >
                                                    {getClassificationsByType(
                                                        ClassificationTypeEnum.DELIVERY_TYPE.value
                                                    ).map(clf => (
                                                        <Select.Option key={clf.value} value={clf.value}>
                                                            {clf.text}
                                                        </Select.Option>
                                                    ))}
                                                </InsSelect>
                                            </Form.Item>
                                            {deliveryDestinationType === DIRECT_DELIVERY && (
                                                <Form.Item noStyle>
                                                    <InsSelect
                                                        defaultValue={tmpDirectDeliveryType}
                                                        style={{ width: 180, marginLeft: 5 }}
                                                        onSelect={value => {
                                                            dispatch(
                                                                setDeliveryDestinationType({
                                                                    deliveryDestinationType: deliveryDestinationType,
                                                                    directDeliveryType: value,
                                                                })
                                                            )
                                                        }}
                                                    >
                                                        <Select.Option value={1}>{'商品ごと設定'}</Select.Option>
                                                        <Select.Option value={2}>{'全ての直送先に送る'}</Select.Option>
                                                    </InsSelect>
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="place_order_code"
                                        label={t('column:Place order code')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter PlaceOrder code'),
                                                // check unique
                                            },
                                            {
                                                // trigger: 'onBlur',
                                                validator: async (rule, value) => {
                                                    if (
                                                        isEdit &&
                                                        initialValues.place_order_code.trim() === value.trim()
                                                    )
                                                        return Promise.resolve()

                                                    if (value.trim()) {
                                                        const data = await api.post(
                                                            APP_URLS.PLACE_ORDER_CHECK_EXISTS_CODE,
                                                            {
                                                                place_order_code: value,
                                                            }
                                                        )
                                                        if (data && data.is_existed) {
                                                            return Promise.reject(
                                                                t('validation:PlaceOrder code existed')
                                                            )
                                                        }
                                                    }

                                                    return Promise.resolve()
                                                },
                                            },
                                            {
                                                max: 20,
                                                message: t('validation:{{item}} is invalid', {
                                                    item: t('column:Place order code'),
                                                }),
                                            },
                                        ]}
                                        validateTrigger={['onBlur']}
                                        className="hide-require"
                                    >
                                        <InsInput style={{ width: 180 }} />
                                    </Form.Item>
                                    <Form.Item name="issued_date" label={t('column:発注日')}>
                                        <InsDatePicker
                                            format={dateFormat}
                                            placeholder="発行日入力"
                                            style={{ width: 180 }}
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                    <Form.Item name="sender_id" label="差出名">
                                        <InsSelect
                                            style={{ width: 180 }}
                                            onChange={value => handlerSenderIdChange(value)}
                                        >
                                            {getClassificationsByType(
                                                ClassificationTypeEnum.ESTIMATION_SENDER.value
                                            ).map(clf => (
                                                <Select.Option key={clf.value} value={clf.value}>
                                                    {clf.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                    <Form.Item name="sender_name" label=" ">
                                        <InsInput style={{ width: 180 }} defaultValue={'株式会社稲進'} allowClear />
                                    </Form.Item>
                                    <Form.Item name="sender_address" label=" " style={{ width: 420, marginBottom: 8 }}>
                                        <InsTextArea defaultValue={initialValues.sender_address} />
                                    </Form.Item>
                                    <Form.Item
                                        name={projectForm ? 'place_order_pic_id' : 'pic_id'}
                                        label={t('column:Pic name')}
                                    >
                                        <UserSelect
                                            record={projectForm ? initialValues.place_order_pic : initialValues.pic}
                                            style={{ width: 180 }}
                                            placeholder="稲進担当者検索"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <PlaceOrderDragAndDrop isPurchaseOrder={isPurchaseOrder} />

                            <Row className="bl-form-detail-control" justify="space-between">
                                <PlaceOrderDetailAddButton isPurchaseOrder={isPurchaseOrder} />
                                <Row className="total">
                                    {!isPurchaseOrder && (
                                        <Form.Item name="gross_profit" label="粗利">
                                            <InsInputNumber disabled style={{ width: 104 }} />
                                        </Form.Item>
                                    )}
                                    <Form.Item name="subtotal" label="小計">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="consumption_tax" label="消費税">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="total" label="合計	：:">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                </Row>
                            </Row>
                            <h3>備考</h3>
                            <Form.Item name={projectForm ? 'place_order_note' : 'note'}>
                                <InsTextArea allowClear={false} />
                            </Form.Item>
                            <InsFloatBar visible={!modalPlaceOrderPreview} className="with-sidebar z-9999">
                                <Row gutter={8} justify="space-between" align="middle">
                                    <Col>
                                        <InsButton
                                            icon={<PreviewIcon size={16} className="color-pantone-2738-c" />}
                                            buttonStyle="default"
                                            buttonSize="big"
                                            onClick={() => {
                                                setPlaceOrderPreviewModal(
                                                    Object.assign(
                                                        {},
                                                        placeOrderPreviewModal,
                                                        parseDataForSubmit(form.getFieldsValue(), true)
                                                    )
                                                )
                                                setModalPlaceOrderPreview(true)
                                            }}
                                        >
                                            {t('プレビュー')}
                                        </InsButton>
                                    </Col>
                                    <Col className={'btn-update-project-bottom'}>
                                        <Form.Item
                                            name="is_purchase_order"
                                            style={{ display: 'none' }}
                                            valuePropName="checked"
                                        >
                                            <Checkbox defaultChecked={isPurchaseOrder}>{t('Purchase order')}</Checkbox>
                                        </Form.Item>
                                        {isPurchaseOrder ? (
                                            <Form.Item
                                                name="dont_update_purchase_price"
                                                noStyle
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    <Tooltip placement="top" title={text}>
                                                        原価に反映しない
                                                        <HelpOutlineIcon className="ml-4" size={20} />
                                                    </Tooltip>
                                                </Checkbox>
                                            </Form.Item>
                                        ) : null}

                                        <InsButton
                                            buttonStyle="outline"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            onClick={() => {
                                                if (isCreateProject) {
                                                    history.push(`${Routes.private.business.PROJECT.path}`)
                                                } else {
                                                    history.push(
                                                        `${Routes.private.business.PROJECT_DETAIL.path.replace(
                                                            ':id',
                                                            project.id
                                                        )}`
                                                    )
                                                }
                                            }}
                                        >
                                            {t('common:Cancel')}
                                        </InsButton>

                                        <InsButton
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            isSubmitButton={true}
                                        >
                                            {isEdit ? t('common:Save') : t('common:Save')}
                                        </InsButton>
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        </Form>
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    )
}

const mapState = state => ({
    destinations: state.placeOrderDetail.destinations,
    destinationsOrder: state.placeOrderDetail.destinationsOrder,
    details: state.placeOrderDetail.details,
    detailsOrder: state.placeOrderDetail.detailsOrder,
    placeOrderClassification: state.placeOrderDetail.placeOrderClassification,
    deliveryDestinationType: state.placeOrderDetail.deliveryDestinationType,
    changedData: state.placeOrderDetail.changedData,
})

const mapDispatch = {
    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,
    setDeliveryDestinationType,
    parseData,
    setDefaultState,
}

export default connect(mapState, mapDispatch)(PlaceOrderForm)

PlaceOrderForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    project: PropTypes.object.isRequired,
    destinations: PropTypes.object,
    destinationsOrder: PropTypes.array,
    details: PropTypes.object,
    detailsOrder: PropTypes.object,
    deliveryDestinationType: PropTypes.string,
    directDeliveryType: PropTypes.number,
    setDestinations: PropTypes.func.isRequired,
    setDestinationsOrder: PropTypes.func.isRequired,
    setDetails: PropTypes.func.isRequired,
    setDetailsOrder: PropTypes.func.isRequired,
    setDefaultState: PropTypes.func.isRequired,
    parseData: PropTypes.func,
    changedData: PropTypes.bool.isRequired,
    projectForm: PropTypes.any,
}
