import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import InvoicePreview from 'modules/business/invoice/InvoicePreview'
import InvoiceCreate from 'modules/business/invoice/InvoiceCreate'
import InvoiceUpdate from 'modules/business/invoice/InvoiceUpdate'
import Routes from 'routes'

const InvoiceTab = ({ project, response }) => {
    const [loading, setLoading] = useState(true)
    const [invoice, setInvoice] = useState({})
    const [sale, setSale] = useState({})
    const location = useLocation()

    const getInvoiceByProject = async projectId => {
        setLoading(true)

        const data = await api.get(APP_URLS.INVOICE_BY_PROJECT.replace(':projectId', projectId))
        setInvoice(data)

        if (data && data.id) {
            setSale(data.project ? { ...data.project.sale, project: data.project } : {})
        } else {
            const currentSale = await api.get(APP_URLS.SALE_BY_PROJECT.replace(':projectId', projectId))
            setSale(currentSale)
        }

        setLoading(false)
    }

    useEffect(() => {
        getInvoiceByProject(project.id).then(r => {})
    }, [project.id])

    const isExisted = !!(invoice && invoice.id)
    // current Route
    let { path, url } = useRouteMatch()
    const renderInvoiceRoute = () => {
        return (
            <Switch>
                <Route exact path={`${path}`}>
                    {isExisted ? (
                        <InvoicePreview
                            project={project}
                            invoice={invoice}
                            setInvoice={setInvoice}
                            response={r => {
                                response(r ? r : Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
                            }}
                            updateLink={`${url}/update` + location.search}
                        />
                    ) : (
                        <Redirect to={`${url}/create`} />
                    )}
                </Route>
                <Route exact path={`${path}/create`}>
                    {isExisted ? (
                        <Redirect to={`${url}/preview`} />
                    ) : (
                        <InvoiceCreate
                            project={project}
                            sale={sale}
                            response={redirectTo => {
                                getInvoiceByProject(project.id).then(r => {
                                    if (!redirectTo) {
                                        redirectTo =
                                            Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                            APP_URLS.INVOICE +
                                            '/preview'
                                    }
                                    return response(redirectTo)
                                })
                            }}
                        />
                    )}
                </Route>
                <Route exact path={`${path}/update`}>
                    {isExisted ? (
                        <InvoiceUpdate
                            project={project}
                            sale={sale}
                            invoice={invoice}
                            response={redirectTo => {
                                getInvoiceByProject(project.id).then(r => {
                                    if (!redirectTo) {
                                        redirectTo =
                                            Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                            APP_URLS.INVOICE +
                                            '/preview'
                                    }
                                    return response(redirectTo)
                                })
                            }}
                        />
                    ) : (
                        <Redirect to={`${url}/create`} />
                    )}
                </Route>
                <Route exact path={`${path}/preview`}>
                    <InvoicePreview
                        project={project}
                        invoice={invoice}
                        setInvoice={setInvoice}
                        response={r => {
                            response(
                                r
                                    ? r
                                    : Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                          '?from=' +
                                          location.search
                            )
                        }}
                        updateLink={`${url}/update` + location.search}
                    />
                </Route>
            </Switch>
        )
    }

    return <>{loading ? <InsBlockLoader /> : renderInvoiceRoute()}</>
}

export default InvoiceTab

InvoiceTab.propTypes = {
    project: PropTypes.object.isRequired,
    response: PropTypes.func,
}
