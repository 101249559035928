import React, { useEffect, useState } from 'react'
import { Form, Row, Select } from 'antd'
import InsInput from 'components/InsInput'
import { getClassificationsByType } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsSelect from 'components/select/InsSelect'
import InsInputNumber from 'components/InsInputNumber'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import { ClearInputIcon, CommentIcon, SubdirectoryArrowRightIcon } from 'components/InsIcon'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateDetail } from 'modules/business/placeOrder/place_order_detail.slice'
import { isEmpty, rdStr } from 'helpers/string.helper'
import PropTypes from 'prop-types'
import InsInputNumber2 from 'components/InsInputNumber2'
import ProductSelect from 'modules/master/product/ProductSelect'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsInputWithEditBehavior from 'components/InsInputWithEditBehavior'
import { formCalculateDetailAmount } from 'helpers/calculate.helper'
import { isHeadline } from 'helpers/detail.helper'
import InsTextArea from 'components/InsTextArea'

const PlaceOrderDetailRow = React.memo(({ id, value, updateDetail }) => {
    const { t } = useTranslation()
    const [noteVisible, setNoteVisible] = useState(!isEmpty(value.note))
    const [form] = Form.useForm()

    useEffect(() => {
        let values = Object.assign({}, value)
        if (values.unit) {
            values.unit = `${values.unit}`
        }
        form.setFieldsValue(values)
    }, [form])

    let timeout = null
    const changeHandle = () => {
        if (timeout !== null) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            syncFormData(product)
        }, 300)
    }

    const syncFormData = product => {
        updateDetail({
            id,
            value: Object.assign({}, { product: product }, form.getFieldsValue()),
        })
    }

    const calculateDetailAmount = () => formCalculateDetailAmount(form)

    const [product, setProduct] = useState({})
    const handlerProductSelect = async productId => {
        const product = await api.get(APP_URLS.PRODUCTS_DETAIL.replace(':id', productId))
        if (product) {
            setProduct(product)
            form.setFieldsValue({
                product: product,
                product_id: product.id,
                title: product.product_name,
                unit: product.unit,
                unit_cost: product.unit_cost,
                unit_price: product.price,
                amount: form.getFieldValue('quantity') * product.price || 0,
            })
            syncFormData(product)
            setProductNameEditable(true)
        }
    }

    const [productNameEditable, setProductNameEditable] = useState(!!value.title)

    const clearProduct = () => {
        setProductNameEditable(!productNameEditable)
        form.setFieldsValue({
            product: null,
            product_id: undefined,
            product_name: null,
            quantity: 0,
            unit: '2',
            unit_cost: 0.0,
            unit_price: 0,
            tax_rate: '10',
            amount: 0,
            note: null,
        })
        changeHandle()
    }

    return (
        <Form form={form} onValuesChange={changeHandle} name={rdStr()}>
            {isHeadline(value) ? (
                <Form.Item name="headline">
                    <InsInput placeholder={t('column:Headline')} />
                </Form.Item>
            ) : (
                <>
                    <Row align="middle">
                        <Form.Item style={{ flex: 180 }}>
                            <Form.Item name="product_id" noStyle>
                                <ProductSelect
                                    record={value.product}
                                    style={{ width: '100%', display: productNameEditable ? 'none' : 'inline-flex' }}
                                    placeholder="品番・品名"
                                    showCreateButton={false}
                                    showDetailLink={false}
                                    // showListLink={false}
                                    onSelect={productId => handlerProductSelect(productId)}
                                    onChange={productId => handlerProductSelect(productId)}
                                />
                            </Form.Item>

                            <div className="product-name-editable">
                                <Form.Item name="title" noStyle>
                                    <InsInput
                                        placeholder="タイトル"
                                        style={{ display: productNameEditable ? 'inline-flex' : 'none' }}
                                        onChange={value => value.length < 1 && clearProduct()}
                                    />
                                </Form.Item>
                                {productNameEditable && (
                                    <ClearInputIcon
                                        size={16}
                                        className="mdi-clear-input"
                                        onClick={() => clearProduct()}
                                    />
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name="quantity" style={{ flex: '0 0 74px' }}>
                            <InsInputNumber2
                                min={1}
                                onChange={value => {
                                    form.setFieldsValue({
                                        quantity: value,
                                    })
                                    calculateDetailAmount()
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="unit" className="form-item-select-hide-search" style={{ flex: '0 0 48px' }}>
                            <ClassificationSelect
                                typeEnum={ClassificationTypeEnum.PRODUCT_UNIT}
                                showCreateButton={false}
                            />
                        </Form.Item>
                        <Form.Item name="unit_cost" style={{ flex: '0 0 90px' }}>
                            <InsInputWithEditBehavior />
                        </Form.Item>
                        <Form.Item
                            name="unit_price"
                            style={{ flex: '0 0 84px' }}
                            onChange={() => calculateDetailAmount()}
                        >
                            <InsInputNumber min={0} />
                        </Form.Item>
                        <Form.Item name="tax_rate" style={{ flex: '0 0 66px' }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.TAX_RATE.value).map(c => (
                                    <Select.Option key={c.item_value} value={c.item_value}>
                                        {`${c.text}%`}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                        <Form.Item name="amount" className="border-transparent" style={{ flex: '0 0 84px' }}>
                            <InsInputNumber disabled min={0} />
                        </Form.Item>
                        <CommentIcon
                            className={`mr-4 ${noteVisible ? 'color-pantone-2738-c' : 'color-blue-gray-200'}`}
                            onClick={() => setNoteVisible(!noteVisible)}
                        />
                    </Row>
                    <Row align="middle" className="mt-4" style={{ display: noteVisible ? 'flex' : 'none' }}>
                        <SubdirectoryArrowRightIcon size={20} style={{ marginRight: 2 }} />
                        <div className="fw-bold mr-4">コメント</div>
                        <Form.Item name="note" style={{ flex: 1 }}>
                            <InsTextArea
                                className="color-gray-678 textarea-auto-size"
                                placeholder="コメント入力　※コメントは印刷されません"
                                autoSize={true}
                            />
                        </Form.Item>
                    </Row>
                </>
            )}
        </Form>
    )
})

const mapState = (state, props) => ({
    value: state.placeOrderDetail.details[props.id],
})
const mapDispatch = { updateDetail }
export default connect(mapState, mapDispatch)(PlaceOrderDetailRow)

PlaceOrderDetailRow.propTypes = {
    id: PropTypes.number,
    value: PropTypes.object,
    updateDetail: PropTypes.func,
}
