import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { getClassificationItemName } from 'helpers/classification.helper'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import ShipperListSearch from 'modules/master/shipper/ShipperListSearch'
import { getEnumText } from 'helpers/enum.helper'
import ShipperTypeEnum from 'enums/shipper_type.enum'

const ShipperListComponent = ({
    inModal = false,
    onSelect,
    state,
    getData,
    setPagination,
    setFilters,
    setSorter,
    setKeywords,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const shipperColumns = [
        {
            title: t('column:Shipper type'),
            dataIndex: 'shipper_type',
            sorter: true,
            render: (text, r) => {
                return (
                    <div className="label-select">
                        <span
                            className={`type-label ${
                                r.shipper_type === ShipperTypeEnum.CUSTOMER.value ? 'customer' : 'shipping-address'
                            } `}
                        >
                            {getEnumText(ShipperTypeEnum, r.shipper_type)}
                        </span>
                    </div>
                )
            },
            width: 12,
        },
        {
            title: t('column:Shipper code'),
            dataIndex: 'shipper_code',
            sorter: true,
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            if (inModal) {
                                onSelect(r)
                            }
                        }}
                    >
                        {text}
                    </InsButton>
                )
            },
            width: 20,
        },
        {
            title: t('column:Shipper name'),
            dataIndex: 'shipper_name',
            sorter: true,
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            if (inModal) {
                                onSelect(r)
                            }
                        }}
                    >
                        {text}
                    </InsButton>
                )
            },
            width: 20,
        },
        {
            title: t('column:Address'),
            dataIndex: 'prefecture',
            sorter: true,
            render: (text, r) => {
                let address = getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, r.prefecture_code)
                if (r.address1) address += r.address1
                if (r.address2) address += r.address2
                return address
            },
            width: 28,
        },
        { title: t('column:Phone number'), dataIndex: 'phone_number', sorter: true, width: 20 },
    ]

    const getShippers = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        if (!state.fetched) {
            getShippers()
        }
        // eslint-disable-next-line
    }, [])

    const updateFilters = obj => {
        const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getShippers(newPagination, newFilters)
    }

    const tabChangeHandler = key => {
        dispatch(setKeywords([]))
        const newFilters = Object.assign({}, state.filters, {
            keyword: [],
            is_enabled: key,
        })
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setFilters(newFilters))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getShippers(newPagination, newFilters, newSorter)
    }

    return (
        <React.Fragment>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <ShipperListSearch
                        onSearch={condition => updateFilters(condition)}
                        onReset={() => updateFilters({})}
                    />
                </Col>
            </Row>

            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsTab
                        activeKey={state.filters.is_enabled}
                        type="card"
                        onChange={key => tabChangeHandler(key)}
                        metaComponent={
                            <InsPagination
                                {...state.pagination}
                                pageSizeOptions={[15, 25, 50]}
                                onChange={pagination => getShippers(pagination)}
                            />
                        }
                    >
                        <Tabs.TabPane tab={t('common:Enabled')} key="1" />
                        <Tabs.TabPane tab={t('common:Disabled')} key="0" />
                    </InsTab>
                    <InsDataTable
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered
                        columns={shipperColumns}
                        data={state.data}
                        onChange={(pagination, filter, sorter) => {
                            getShippers(state.pagination, state.filters, sorter)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        ctlSortOrder={true}
                        scroll={inModal ? { y: 400 } : null}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ShipperListComponent

ShipperListComponent.propTypes = {
    inModal: PropTypes.bool,
    onSelect: PropTypes.func,
    state: PropTypes.shape(BaseStatePropShape),
    getData: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
}

ShipperListComponent.defaultProps = {
    inModal: false,
}
