import PropTypes from 'prop-types'
import React from 'react'

const FloatBarStyles = ['float', 'stick-bottom']

const InsFloatBar = ({ className, children, floatBarStyle = 'stick-bottom', visible }) => {
    let floatBarClassName = `ins-float-bar ${visible ? 'active' : ''} `
    if (floatBarStyle) {
        floatBarClassName += `ins-float-bar-${floatBarStyle} `
    }
    if (className) {
        floatBarClassName += className
    }
    return <div className={floatBarClassName}>{children}</div>
}

export default InsFloatBar

InsFloatBar.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    floatBarStyle: PropTypes.oneOf(FloatBarStyles),
    visible: PropTypes.bool,
}

InsFloatBar.defaultProps = {
    floatBarStyle: 'stick-bottom',
}
