const getHeight = elem => elem.offsetHeight
const getOuterHeight = (elem, excludeBottom = false) => {
    const style = getComputedStyle(elem) // IE8: el.currentStyle[prop]
    return getHeight(elem) + parseInt(style.marginTop || 0) + parseInt(excludeBottom ? 0 : style.marginBottom || 0)
}
const getHTML = elem => (elem ? elem.outerHTML : '')

export const splittingContent = (
    refs,
    firstItemOfNewPage,
    elemPageBreak,
    isSaleDoc = false,
    nextRefInstruction,
    nextRefInvoice
) => {
    // page size
    const PAGE = {
        // WIDTH: 1036,
        // HEIGHT: 1318, // ~1220 + 48 + 48 // Letter
        HEIGHT: 1448, // A4: 1446
        SCALE: 1,
        M_TOP: 48,
        // M_RIGHT: 64,
        M_BOTTOM: 48,
        // M_LEFT: 64,
    }

    // page-break class
    elemPageBreak = elemPageBreak || `<div class="page-break"></div>`

    const pageNumOverride = pageNum => {
        let pageNumString = pageNum < 10 ? '0' + pageNum : pageNum
        return `<span class="dom-page-num-ovr">Page - ${pageNumString}</span>`
    }

    const maxHeight = (PAGE.HEIGHT - PAGE.M_TOP - PAGE.M_BOTTOM) * PAGE.SCALE
    let filledHeight = 0
    let currentPage = 1
    let page = {}

    let repeatTHead = {
        isTHead: false,
        elem: null,
    }
    let repeatSimilarTHead = {
        similarTHead: false,
        elem: null,
    }

    // for instruction
    let currentTypeKey = 1
    let ovrNextRefNewPage = nextRefInstruction && nextRefInstruction[currentTypeKey]

    // for invoice summary list
    let currentInvoice = 1
    let ovrNextRefInvoice = nextRefInvoice && nextRefInvoice[currentInvoice]
    let tmpCurrentPageForInvoice = 1

    // $items: DOMs page - no pagination
    Object.keys(refs).forEach(k => {
        const elem = refs[k].elem

        // special for instruction split by cls
        if (refs[k].splitByCls) {
            currentPage++
            filledHeight = 0
            // for instruction next ref
            if (nextRefInstruction) {
                currentTypeKey++
                ovrNextRefNewPage = nextRefInstruction[currentTypeKey]
            }
            return
        }

        // special for summary invoice list - split per invoice
        if (refs[k].splitPerInvoice) {
            currentPage++
            tmpCurrentPageForInvoice++
            filledHeight = 0
            // for invoice next ref
            if (nextRefInvoice) {
                currentInvoice++
                ovrNextRefInvoice = nextRefInvoice[currentInvoice]
                tmpCurrentPageForInvoice = 1
            }
            return
        }

        if (refs[k].isTHead) {
            repeatTHead.isTHead = true
            repeatTHead.elem = elem
        }
        if (refs[k].similarTHead) {
            repeatSimilarTHead.similarTHead = true
            repeatSimilarTHead.elem = elem
        }

        const elemHeight = getOuterHeight(elem)
        if (maxHeight - filledHeight < getOuterHeight(elem, true)) {
            currentPage++
            tmpCurrentPageForInvoice++
            filledHeight = 0
            // new page
            const elemOfNewPage = nextRefInstruction
                ? ovrNextRefNewPage['elem']
                : nextRefInvoice
                ? ovrNextRefInvoice['elem']
                : firstItemOfNewPage['elem']

            page[currentPage] = []
            // re-render
            // page[currentPage].push(firstItemOfNewPage['reRender'](currentPage))
            page[currentPage].push(elemOfNewPage)
            page[currentPage].push(pageNumOverride(nextRefInvoice ? tmpCurrentPageForInvoice : currentPage))
            filledHeight += getOuterHeight(elemOfNewPage)

            // add repeated content
            if (!refs[k].isTHead && !refs[k].withoutTHead && repeatTHead.isTHead && !refs[k].similarTHead) {
                if (repeatSimilarTHead.similarTHead) {
                    page[currentPage].push(repeatSimilarTHead.elem)
                    filledHeight += getOuterHeight(repeatSimilarTHead.elem)
                }
                page[currentPage].push(repeatTHead.elem)
                filledHeight += getOuterHeight(repeatTHead.elem)
            }
            // new page start with thead
            if (refs[k].isTHead) {
                if (repeatSimilarTHead.similarTHead) {
                    page[currentPage].push(repeatSimilarTHead.elem)
                    filledHeight += getOuterHeight(repeatSimilarTHead.elem)
                }
            }
        }

        filledHeight += elemHeight
        if (!page[currentPage]) {
            page[currentPage] = []
        }

        page[currentPage].push(elem)
    })

    // join
    let result = []
    const keys = Object.keys(page)
    keys.map((k, index) => {
        result.push(`<div class="dom-page">`)
        page[k].map(p => {
            result.push(p.tagName ? getHTML(p) : p)
        })
        result.push(`</div>`)

        if (index < keys.length - 1) {
            result.push(elemPageBreak)
        }
    })

    // case: sale - 3 docs
    if (isSaleDoc) {
        // add page-break after doc 1
        result.push(elemPageBreak)
        // push to doc 2
        keys.map((k, index) => {
            result.push(`<div class="dom-page">`)
            // pin doc title (1st page)
            if (index === 0) {
                result.push(
                    `<div class="ant-row ant-row-space-between ant-row-bottom dom-page-sale-doc-pin">
                        <div class="ant-col ant-col-8 ant-col-offset-8">
                            <strong class="c-preview-title">納品書(控)</strong>
                        </div>
                    </div>`
                )
            }
            page[k].map(p => {
                result.push(p.tagName ? getHTML(p) : p)
            })
            result.push(`</div>`)

            if (index < keys.length - 1) {
                result.push(elemPageBreak)
            }
        })

        // add page-break after doc 2
        result.push(elemPageBreak)
        // push to doc 3
        keys.map((k, index) => {
            result.push(`<div class="dom-page">`)
            // pin doc title (1st page)
            if (index === 0) {
                result.push(
                    `<div class="ant-row ant-row-space-between ant-row-bottom dom-page-sale-doc-pin">
                        <div class="ant-col ant-col-8 ant-col-offset-8">
                            <strong class="c-preview-title">物品受領書</strong>
                        </div>
                    </div>`
                )
            }
            page[k].map(p => {
                result.push(p.tagName ? getHTML(p) : p)
            })
            // pin stamp last page
            if (index === keys.length - 1) {
                result.push(
                    `<div class="dom-page-print-stamp">
                        <div class="c-print-stamp">
                            <label class="label-stamp">受領印</label>
                            <div class="print-stamp">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="19.5" fill="white" stroke="#90A4AE" stroke-dasharray="2 3"/>
                                    <path d="M18.46 14.142C17.648 14.632 16.444 15.192 15.254 15.598L14.344 15.262V25.916H16.024V24.81H19.468V23.2H16.024V20.442H19.44V18.846H16.024V16.956C17.312 16.578 18.684 16.102 19.832 15.556L18.46 14.142ZM26.034 15.094H20.196V27.176H21.876V16.746H24.34V23.27C24.34 23.452 24.284 23.522 24.074 23.536C23.864 23.536 23.192 23.536 22.548 23.508C22.814 23.956 23.108 24.782 23.178 25.258C24.13 25.258 24.83 25.23 25.362 24.936C25.88 24.642 26.034 24.096 26.034 23.312V15.094Z" fill="#90A4AE"/>
                                </svg>
                            </div>
                        </div>
                    </div>`
                )
            }

            result.push(`</div>`)

            if (index < keys.length - 1) {
                result.push(elemPageBreak)
            }
        })
    }

    return result.join('')
}
