import React from 'react'
import PropTypes from 'prop-types'
import { AutoComplete } from 'antd'
import { SearchIcon } from 'components/InsIcon'

const { Option } = AutoComplete

const InsAutoComplete = ({ className, placeholder, onSearch, options = [], value, ...rest }) => {
    let divClassName = 'ins-autocomplete '
    if (className) {
        divClassName += className
    }

    const showClear = value && value.length > 0
    return (
        <div className={`ins-autocomplete-wrapper ${divClassName}`}>
            <AutoComplete {...rest} onSearch={onSearch} placeholder={placeholder} value={value} allowClear={showClear}>
                {options.map(
                    text =>
                        !!text && (
                            <Option key={text} value={text}>
                                {text}
                            </Option>
                        )
                )}
            </AutoComplete>
            {!showClear && (
                <SearchIcon
                    size={16}
                    className={`ins-input-search-icon ins-input-search-icon-16`}
                    onClick={e => {
                        e.stopPropagation()
                    }}
                />
            )}
        </div>
    )
}

export default InsAutoComplete

InsAutoComplete.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
}
