import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import InvoiceForm from 'modules/business/invoice/form/InvoiceForm'
import { InsBlockLoader } from 'components/InsLoader'

const InvoiceCreate = ({ project, sale, response }) => {
    const createInvoice = async (values, redirectTo) => {
        await api.post(APP_URLS.INVOICE, values)
        response(redirectTo)
    }

    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)

    const countProject = async projectId => {
        const data = await api.get(APP_URLS.INVOICE_COUNT_PROJECT.replace(':projectId', projectId))
        setCount(data)
        setLoading(false)
    }

    useEffect(() => {
        countProject(project.id)
    }, [])

    return (
        <div className="p-24">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <InvoiceForm
                    project={project}
                    sale={sale}
                    onSubmit={(values, redirectTo) => createInvoice(values, redirectTo)}
                    countProject={count}
                />
            )}
        </div>
    )
}

export default InvoiceCreate

InvoiceCreate.propTypes = {
    project: PropTypes.object.isRequired,
    sale: PropTypes.object.isRequired,
    response: PropTypes.func,
}
