import React from 'react'
import { connect } from 'react-redux'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getData, setFilters, setSorter } from 'modules/master/company/company.slice'
import CompanyListComponent from 'modules/master/company/CompanyListComponent'

const CompanyList = ({ state }) => {
    return (
        <div className="page-container with-sidebar">
            <CompanyListComponent state={state} getData={getData} setFilters={setFilters} setSorter={setSorter} />
        </div>
    )
}

const mapState = state => ({ state: state.company })
export default connect(mapState)(CompanyList)

CompanyList.propTypes = {
    state: BaseStatePropShape,
}
