import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'

const name = 'classification'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.CLASSIFICATIONS, ...props })
)

const classificationSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, { filters: { typeCode: '0' }, sorter: {} }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const { setData, setFilters, setSorter } = classificationSlice.actions

export default classificationSlice.reducer
