const BusinessStatusEnum = Object.freeze({
    PROJECT_CREATE: { value: 100, text: '案件生成' },
    ESTIMATION_CREATE: { value: 200, text: '見積書作成中' },
    ESTIMATION_ISSUED: { value: 201, text: '見積書発行済' },
    ORDER_RECEIVED: { value: 202, text: '受注済' },
    INSTRUCTION_CREATING: { value: 300, text: '作業指示書作成中' },
    WORK_REQUESTED: { value: 301, text: '作業依頼済' },
    ORDER_NOTE_CREATING: { value: 400, text: '発注書作成中' },
    ORDER_NOTE_ISSUED: { value: 401, text: '発注書発行済' },
    SALE_CREATING: { value: 500, text: '納品書作成中' },
    SALE_ISSUED: { value: 501, text: '納品書発行済み' },
    SALE_SHIPPED: { value: 502, text: '出荷済み' },
    SALE_PROCESSED: { value: 503, text: '売上処理済み' },
    TRACKING_CREATING: { value: 600, text: '送り状作成中' },
    TRACKING_ISSUED: { value: 601, text: '送り状発行済' },
    BILL_CREATING: { value: 700, text: '請求書作成中' },
    BILL_ISSUED: { value: 701, text: '請求書発行済' },
    RED_SLIP_CREATING: { value: 800, text: '赤伝作成中' },
    RED_SLIP_ISSUED: { value: 801, text: '赤伝発行済み' },
    PROCESSED: { value: 900, text: '処理済み' },
    GARBAGE_CAN: { value: 901, text: 'ゴミ箱' },
    ORDER_FAILED: { value: 902, text: '失注' },
})

export default BusinessStatusEnum
