import PropTypes from 'prop-types'
import React from 'react'
import InsInputSearch from 'components/InsInputSearch'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setKeywords } from 'modules/master/stock/stock.slice'

const StockListSearch = ({ keywords, setKeywords, onSearch }) => {
    const { t } = useTranslation()

    return (
        <div className="ant-collapse ant-collapse-icon-position-left ins-collapse ">
            <div className="ant-collapse-item">
                <div className="ant-collapse-header">
                    <InsInputSearch
                        style={{ width: '340px' }}
                        placeholder={t('Product code, product name, etc')}
                        value={keywords}
                        onChange={values => setKeywords(values)}
                        onSearch={values => {
                            onSearch({ keyword: values })
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

const mapState = state => ({
    keywords: state.stock.keywords,
})
const mapDispatch = { setKeywords }
export default connect(mapState, mapDispatch)(StockListSearch)

StockListSearch.propTypes = {
    keywords: PropTypes.array.isRequired,
    onSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
}
