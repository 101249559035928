import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import api, { createQuery } from 'helpers/api.helper'
import moment from 'moment'

const name = 'report'

const baseGetData = async ({ url, pagination, filters, sorter }) => {
    const data = await api.get(url, createQuery(pagination, filters, sorter), null, true)
    return { data: data }
}

const dateFormatStart = 'YYYY/MM/01'
const dateFormatEnd = 'YYYY/MM/01'
export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.REPORTS, ...props })
)

const defaultFilters = {
    start_time: moment().subtract(2, 'months').format(dateFormatStart),
    end_time: moment().format(dateFormatEnd),
    group_item_1_type: 'area',
    group_item_1_limit: 10,
    group_item_2_type: 'person_in_charge',
    group_item_2_limit: 5,
    metric_type: 'sales_amount',
    metric_period: 1,
}

const reportSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: defaultFilters,
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setSorter,
    setSelection,
} = reportSlice.actions

export default reportSlice.reducer
