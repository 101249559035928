import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TruckIcon, UnfoldLessIcon, UnfoldMoreIcon } from 'components/InsIcon'
import DetailRow from 'modules/business/invoice/invoiceProject/DetailRow'

const DestinationGroup = ({ destination }) => {
    const [collapsed, setCollapsed] = useState(false)
    const { details } = destination

    return (
        <div className="destination-group">
            <div className="destination-group-header">
                <div className="destination-group-info">
                    <div className="ins-drop-group">
                        <div className="ins-drop-header">
                            <TruckIcon
                                size={20}
                                className="color-pantone-2422-c ins-flex flex-middle ml-16 mr-8"
                                style={{ flex: '0 0 20px' }}
                            />
                            <div className="fw-bold">納入先： </div>
                            {destination.delivery_destination_name && (
                                <div>{destination.delivery_destination_name}</div>
                            )}
                            {destination.delivery_destination_address && (
                                <div className="ml-24">{destination.delivery_destination_address}</div>
                            )}
                            {destination.delivery_destination_pic_name && (
                                <div className="ml-24">{destination.delivery_destination_pic_name}</div>
                            )}
                            {destination.delivery_destination_pic_title && (
                                <div className="ml-24">{destination.delivery_destination_pic_title}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="des-group-collapse" onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? (
                        <UnfoldMoreIcon className="mdi_unfold_less" size={24} />
                    ) : (
                        <UnfoldLessIcon className="mdi_unfold_more" size={24} />
                    )}
                </div>
            </div>
            <div className="destination-group-body" style={{ display: collapsed ? 'none' : 'block' }}>
                {(details || []).map(detail => (
                    <DetailRow detail={detail} key={detail.id} />
                ))}
            </div>
        </div>
    )
}

export default DestinationGroup

DestinationGroup.propTypes = {
    destination: PropTypes.object.isRequired,
}
