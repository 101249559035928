import React, { useState } from 'react'
import { Row, Col, Checkbox, Radio } from 'antd'

const CheckboxGroup = Checkbox.Group

const CheckboxDemo = () => {
    const plainOptions = ['Option 1', 'Option 2', 'Option 3']
    const defaultCheckedList = ['Option 1', 'Option 2']

    const [state, setState] = useState({
        checkedList: defaultCheckedList,
        indeterminate: true,
        checkAll: false,
    })
    const [radioState, setRadioState] = useState({ value: 1 })

    const onChange = checkedList => {
        setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        })
    }

    const onCheckAllChange = e => {
        setState({
            checkedList: e.target.checked ? plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked,
        })
    }

    const onRadioChange = e => {
        setRadioState({
            value: e.target.value,
        })
    }

    return (
        <div className="page-container">
            <h1 className="page-title mb-32">Checkbox & Radio (CSS Only)</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <Checkbox indeterminate={state.indeterminate} onChange={onCheckAllChange} checked={state.checkAll}>
                        Check all
                    </Checkbox>
                </Col>
                <Col>
                    <CheckboxGroup options={plainOptions} value={state.checkedList} onChange={onChange} />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <Checkbox disabled>Disabled</Checkbox>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom" className="mt-32">
                <Col>
                    <Radio.Group onChange={onRadioChange} value={radioState.value}>
                        <Radio value={1}>Option 1</Radio>
                        <Radio value={2}>Option 2</Radio>
                        <Radio value={3}>Option 3</Radio>
                        <Radio value={4} disabled>
                            Option 4
                        </Radio>
                    </Radio.Group>
                </Col>
            </Row>
        </div>
    )
}

export default CheckboxDemo
