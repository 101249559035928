import React, { useEffect, useState } from 'react'
import InsDataTable from 'components/datatable/InsDataTable'
import { Col, Row, Tabs } from 'antd'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getData, setFilters, setSorter } from 'modules/business/redSlip/redSlip.slice'
import { useTranslation } from 'react-i18next'
import InsTab from 'components/InsTab'
import InsPagination from 'components/datatable/InsPagination'
import InsLoader from 'components/InsLoader'
import { dateFormat } from 'helpers/date.helper'
import InsButton from 'components/InsButton'
import RedSlipListSearch from 'modules/business/redSlip/RedSlipListSearch'
import Routes from 'routes'
import { AddIcon } from 'components/InsIcon'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { userSettingColumns } from 'helpers/user_setting.helper'
import BusinessPageEnum from 'enums/business_page.enum'
import FloatBarBehavior from 'modules/business/common/FloatBarBehavior'
import NavigationEnum from 'enums/navigation.enum'

const RedSlipList = ({ state, currentUser }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const redSlipColumns = [
        {
            title: t('column:ID'),
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: t('案件ID'),
            dataIndex: 'project_id',
            sorter: true,
        },
        {
            title: t('column:RedSlip Code'),
            dataIndex: 'red_slip_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    linkTo={
                        Routes.private.business.PROJECT_DETAIL.path.replace(
                            ':id',
                            record.project && record.project.id
                        ) +
                        '/red-slips?from=' +
                        NavigationEnum.LIST_RED_SLIPS
                    }
                    buttonType="link"
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: t('赤発行日'),
            dataIndex: 'red_slip_issued_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('column:Customer name'),
            dataIndex: 'customer_name',
            sorter: true,
            render: (text, record) => <>{record.project.customer && record.project.customer.customer_name}</>,
        },
        {
            title: t('column:Status'),
            dataIndex: 'status',
            sorter: true,
            render: (text, record) =>
                getClassificationItemName(
                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                    record.project?.status?.toString()
                ),
        },
        {
            title: t('column:Red slip status'),
            dataIndex: 'red_slip_status',
            sorter: true,
            render: (text, record) =>
                getClassificationItemName(ClassificationTypeEnum.BUSINESS_STATUS.value, record.status.toString()),
        },
        {
            title: t('column:Created at'),
            dataIndex: 'created_at',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('作成者'),
            dataIndex: 'created_by',
            sorter: true,
        },
        {
            title: t('更新日'),
            dataIndex: 'updated_at',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('更新者'),
            dataIndex: 'updated_by',
            sorter: true,
        },
        {
            title: t('column:Pic name'),
            dataIndex: 'pic_name',
            sorter: true,
            render: (text, record) => <>{record.project.pic && record.project.pic.pic_name}</>,
        },
    ]
    const defaultColumns = ['red_slip_code', 'customer_name', 'status', 'pic_name', 'created_at']
    const userRedSlipColumns = userSettingColumns(currentUser.user_setting, 'red_slips', redSlipColumns, defaultColumns)

    const updateFilters = obj => {
        // const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        const newFilters = Object.assign(
            {},
            {
                'status': state.filters.status,
                'created_at:gte': state.filters['created_at:gte'],
                'created_at:lte': state.filters['created_at:lte'],
                'keyword': state.filters['keyword'],
                'is_deleted': state.filters['is_deleted'],
            },
            obj
        )
        dispatch(setFilters(newFilters))
        getRedSlips(state.pagination, newFilters)
    }

    const getRedSlips = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSelectedKeys([])
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        getRedSlips()
        // eslint-disable-next-line
    }, [])

    const [selectedKeys, setSelectedKeys] = useState([])

    return (
        <div className="page-container estimation-list-page">
            <Row className="mt-8 mb-16" justify="space-between">
                <Col>
                    <h1 className="page-title mb-0">
                        {t('navigation:{{entity}} list', { entity: t('entity:Red slip') })}
                    </h1>
                </Col>
                <Col>
                    <InsButton
                        width={120}
                        buttonType="link"
                        linkTo={Routes.private.business.PROJECT_CREATE.path}
                        icon={<AddIcon size={24} />}
                        buttonStyle="secondary"
                    >
                        {t('entity:Item create')}
                    </InsButton>
                </Col>
            </Row>
            <RedSlipListSearch
                onSearch={condition => updateFilters(condition)}
                onReset={() => updateFilters({ keyword: [] })}
                getRedSlips={getRedSlips}
                pagination={state.pagination}
            />
            <InsTab
                type="card"
                metaComponent={
                    <InsPagination
                        {...state.pagination}
                        onChange={pagination => getRedSlips(pagination)}
                        pageSizeOptions={[100, 200, 300]}
                    />
                }
                className="hide-tabs"
            >
                <Tabs.TabPane tab={'placeholder'} key="0" />
            </InsTab>
            <InsDataTable
                tableStyle="compact"
                striped
                loading={state.loading ? { indicator: <InsLoader /> } : false}
                hasSelection={true}
                onSelectionChanged={keys => setSelectedKeys(keys)}
                subSelectedKeys={selectedKeys}
                data={state.data}
                columns={userRedSlipColumns}
                onChange={(pagination, filter, sorter) => {
                    getRedSlips(state.pagination, state.filters, sorter)
                }}
                pagination={state.pagination}
                filters={state.filters}
                sorter={state.sorter}
            />
            <FloatBarBehavior
                visible={selectedKeys.length > 0}
                data={state.data}
                selectedIds={selectedKeys}
                setSelectedIds={setSelectedKeys}
                documentType={BusinessPageEnum.RED_SLIPS.value}
                fetchData={getRedSlips}
            />
        </div>
    )
}

const mapState = state => ({ state: state.redSlip, currentUser: state.auth.user })
export default connect(mapState)(RedSlipList)

RedSlipList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    currentUser: PropTypes.object,
}
