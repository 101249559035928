import React, { useEffect, useState } from 'react'
import InsModal from 'components/InsModal'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect, useStore } from 'react-redux'
import InsDragAndDrop from 'components/draggable/InsDragAndDrop'
import {
    moveDetail,
    removeDetail,
    addDetail,
    parseData,
    setDefaultState,
} from 'modules/business/shipment/shipment_process.slice'
import InsButton from 'components/InsButton'
import { AddIcon, DeleteIcon } from 'components/InsIcon'
import OutgoingProcessRow from 'modules/business/shipment/draggable/OutgoingProcessRow'
import moment from 'moment'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'

const ShipmentProcessDragAndDrop = React.memo(({ details, detailsOrder, moveDetail, removeDetail, addDetail }) => {
    const { t } = useTranslation()
    return (
        <div className="ins-drag-drop">
            <div className="ins-drag-drop-header">
                <div style={{ flex: '0 0 20px' }} />
                <div style={{ flex: 384, borderRight: '2px solid #B0BEC5' }}>{t('品番・品名')}</div>
                <div style={{ flex: `0 0 106px`, borderRight: '2px solid #B0BEC5' }}>{t('column:Quantity')}</div>
                <div style={{ flex: `0 0 56px` }}>{t('column:Unit')}</div>
                <div style={{ flex: '0 0 24px' }} />
            </div>
            <div className="ins-draggable-body">
                <InsDragAndDrop
                    data={details}
                    order={detailsOrder}
                    onOrderChange={(srcIndex, desIndex) => moveDetail({ srcIndex, desIndex })}
                    onRemove={id => detailsOrder.length > 1 && removeDetail({ id })}
                    rowComponent={OutgoingProcessRow}
                />
            </div>
            <InsButton
                style={{ width: 104, marginTop: 16 }}
                icon={<AddIcon className="color-pantone-2738-c" />}
                buttonStyle="default"
                onClick={() => {
                    addDetail()
                }}
            >
                行追加
            </InsButton>
        </div>
    )
})

const ShipmentProcessModal = ({
    isEdit,
    outgoingProductId,
    visible,
    onCancel,
    details,
    detailsOrder,
    moveDetail,
    removeDetail,
    addDetail,
    parseData,
    setDefaultState,
}) => {
    const { t } = useTranslation()
    const store = useStore()
    const [outgoingProduct, setOutgoingProduct] = useState()
    const [loading, setLoading] = useState(false)

    const getOutgoingProduct = async () => {
        setLoading(true)
        await api.get(APP_URLS.SHIPMENTS_OUTGOING_DETAIL.replace(':id', outgoingProductId)).then(response => {
            if (response) {
                parseDataForUpdate(response)
            }
        })
        setLoading(false)
    }

    const createOutgoingProduct = async () => {
        let outgoingProduct = {
            outgoing_product_date: moment().format('YYYY-MM-DD'),
            pic_id: store.getState().auth.user.id,
        }
        outgoingProduct.details = []
        detailsOrder.map(detailOrder => outgoingProduct.details.push(details[detailOrder]))
        await api.post(APP_URLS.SHIPMENTS_OUTGOING, outgoingProduct).then(r => {
            setDefaultState()
            onCancel()
        })
    }

    const updateOutgoingProduct = async () => {
        let newOutgoingProduct = Object.assign({}, outgoingProduct)
        newOutgoingProduct.details = []
        detailsOrder.map(detailOrder => newOutgoingProduct.details.push(details[detailOrder]))
        await api
            .put(APP_URLS.SHIPMENTS_OUTGOING_DETAIL.replace(':id', newOutgoingProduct.id), newOutgoingProduct)
            .then(r => {
                onCancel()
            })
    }

    const deleteOutgoingProduct = async () => {
        await api.delete(APP_URLS.SHIPMENTS_OUTGOING_DETAIL.replace(':id', outgoingProduct.id)).then(r => {
            onCancel()
        })
    }

    const parseDataForUpdate = outgoingProduct => {
        setOutgoingProduct(outgoingProduct)
        if (outgoingProduct) {
            parseData(outgoingProduct.details)
        }
    }

    useEffect(() => {
        if (isEdit) {
            getOutgoingProduct()
        }
    }, [outgoingProductId])

    const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] = useState(false)

    return (
        <InsModal
            className="shipment-modal"
            title="出荷処理"
            width={875}
            visible={visible}
            onCancel={onCancel}
            footer={[
                isEdit ? (
                    <>
                        <InsButton
                            className="delete-outgoing"
                            icon={<DeleteIcon size={24} className="color-pantone-213-c" />}
                            buttonStyle="default"
                            onClick={() => setModalConfirmDeleteVisible(true)}
                        >
                            {'削除'}
                        </InsButton>
                        <InsModal
                            title="削除を確認"
                            visible={modalConfirmDeleteVisible}
                            onCancel={() => setModalConfirmDeleteVisible(false)}
                            onOk={() => deleteOutgoingProduct()}
                        >
                            <p>{'このアイテムを削除しますか？'}</p>
                        </InsModal>
                    </>
                ) : null,
                <InsButton centered key="cancel" buttonStyle="outline" onClick={() => onCancel()}>
                    {'キャンセル'}
                </InsButton>,
                <InsButton
                    centered
                    key="ok"
                    onClick={() => {
                        if (isEdit) {
                            return updateOutgoingProduct()
                        } else {
                            return createOutgoingProduct()
                        }
                    }}
                >
                    {isEdit ? '確認' : '出荷処理'}
                </InsButton>,
            ]}
        >
            {loading ? (
                <InsBlockLoader />
            ) : (
                <ShipmentProcessDragAndDrop
                    details={details}
                    detailsOrder={detailsOrder}
                    moveDetail={moveDetail}
                    removeDetail={removeDetail}
                    addDetail={addDetail}
                />
            )}
        </InsModal>
    )
}

const mapState = state => ({
    details: state.shipmentProcess.details,
    detailsOrder: state.shipmentProcess.detailsOrder,
})

const mapDispatch = {
    moveDetail,
    removeDetail,
    addDetail,
    parseData,
    setDefaultState,
}

export default connect(mapState, mapDispatch)(ShipmentProcessModal)

ShipmentProcessModal.propTypes = {
    isEdit: PropTypes.bool,
    outgoingProductId: PropTypes.number,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.array.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    parseData: PropTypes.func,
    setDefaultState: PropTypes.func,
}

ShipmentProcessDragAndDrop.propTypes = {
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.array.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    addDetail: PropTypes.func.isRequired,
}
