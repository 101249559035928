import PropTypes from 'prop-types'
import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import { CalendarTodayIcon } from 'components/InsIcon'

const InsDatePicker = ({ className, format = 'YYYY/MM/DD', onChange, ...rest }) => {
    let pickerClassName = 'ins-date-picker '
    if (className) {
        pickerClassName += className
    }
    return (
        <DatePicker
            {...rest}
            className={pickerClassName}
            suffixIcon={<CalendarTodayIcon size={16} />}
            format={format}
            onChange={(mm, value) => {
                onChange && onChange(moment.utc(mm.format('YYYY-MM-DD')), value)
            }}
        />
    )
}

export default InsDatePicker

InsDatePicker.propTypes = {
    className: PropTypes.string,
    format: PropTypes.string,
    onChange: PropTypes.func,
}

InsDatePicker.defaultProps = {
    format: 'YYYY/MM/DD',
}
