import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import Routes from 'routes'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon } from 'components/InsIcon'
import InsLoader from 'components/InsLoader'
import InsTab from 'components/InsTab'
import {
    getData,
    setFilters,
    setPagination,
    setKeywords,
    setSorter,
    setDetailSearch,
} from 'modules/business/instruction/instruction.slice'
import InstructionListSearch from 'modules/business/instruction/InstructionListSearch'
import { formatNumber } from 'helpers/number.helper'
import { getClassificationItemName, getClassificationItemNameFromValue } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { userSettingColumns } from 'helpers/user_setting.helper'
import { dateFormat } from 'helpers/date.helper'
import { isHeadline } from 'helpers/detail.helper'
import moment from 'moment'
import BusinessPageEnum from 'enums/business_page.enum'
import FloatBarBehavior from 'modules/business/common/FloatBarBehavior'
import NavigationEnum from 'enums/navigation.enum'

const InstructionList = ({ state, currentUser }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const instructionColumns = [
        {
            title: t('column:ID'),
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: t('案件ID'),
            dataIndex: 'project_id',
            sorter: true,
        },
        {
            title: t('column:Instruction code'),
            dataIndex: 'instruction_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    linkTo={
                        Routes.private.business.PROJECT_DETAIL.path.replace(
                            ':id',
                            record.project && record.project.id
                        ) +
                        `/${
                            record.instruction_classification === 2 ? 'instruction-outsource' : 'instruction-internal'
                        }?from=` +
                        NavigationEnum.LIST_INSTRUCTIONS
                    }
                    buttonType="link"
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: t('作業指示書区分'),
            dataIndex: 'instruction_classification',
            sorter: true,
            render: text => <>{getClassificationItemName(ClassificationTypeEnum.INSTRUCTION.value, text.toString())}</>,
        },
        {
            title: t('column:Project code'),
            dataIndex: 'project_code',
            sorter: true,
            render: (text, record) => <>{record.project && record.project.project_code}</>,
        },
        {
            title: t('顧客名'),
            dataIndex: 'customer_id',
            sorter: true,
        },
        {
            title: t('column:Customer name'),
            dataIndex: 'customer',
            sorter: true,
            render: text => (text ? text.customer_name : ''),
        },
        {
            title: t('顧客担当者'),
            dataIndex: 'customer_pic_name',
            sorter: true,
        },
        {
            title: t('顧客担当者敬称'),
            dataIndex: 'customer_pic_title',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.CUSTOMER_TITLE.value, text),
        },
        {
            title: t('入荷日'),
            dataIndex: 'arrival_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('加工入荷日'),
            dataIndex: 'processing_arrival_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('加工入荷日の時間帯'),
            dataIndex: 'processing_arrival_time',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value, text),
        },
        {
            title: t('入荷日の時間帯'),
            dataIndex: 'arrival_time',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value, text),
        },
        {
            title: t('荷主'),
            dataIndex: 'client_id',
            sorter: true,
        },
        {
            title: t('納入先'),
            dataIndex: 'delivery_destination',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.DELIVERY_TYPE.value, text),
        },
        {
            title: t('届け区分'),
            dataIndex: 'delivery_type',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.DELIVERY_CATEGORY_1.value, text),
        },
        {
            title: t('配送区分'),
            dataIndex: 'transport_type',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.DELIVERY_CATEGORY_2.value, text),
        },
        {
            title: t('着予定日'),
            dataIndex: 'scheduled_arrival_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('着予定日の時間帯'),
            dataIndex: 'scheduled_arrival_time',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.INSTRUCTION_ARRIVAL_SCHEDULED.value, text),
        },
        {
            title: t('売上日指定'),
            dataIndex: 'is_specify_sales_date',
            sorter: true,
        },
        {
            title: t('連番売上'),
            dataIndex: 'is_serial_number_sales',
            sorter: true,
        },
        {
            title: t('追加同梱'),
            dataIndex: 'is_additional_included',
            sorter: true,
        },
        {
            title: t('差し替え'),
            dataIndex: 'is_replacement',
            sorter: true,
        },
        {
            title: t('タイムサービス'),
            dataIndex: 'is_time_service',
            sorter: true,
        },
        {
            title: t('送り状NOお知らせ'),
            dataIndex: 'is_invoice_no_notice',
            sorter: true,
        },
        {
            title: t('売上日指定'),
            dataIndex: 'specified_sales_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('連番売上'),
            dataIndex: 'serial_number_sales',
            sorter: true,
        },
        {
            title: t('送り状NOお知らせ'),
            dataIndex: 'invoice_no_notice',
            sorter: true,
        },
        {
            title: t('備考'),
            dataIndex: 'note',
            sorter: true,
        },
        {
            title: t('同梱あり'),
            dataIndex: 'is_included',
            sorter: true,
        },
        {
            title: t('売上保留'),
            dataIndex: 'is_hold_sales',
            sorter: true,
        },
        {
            title: t('担当者'),
            dataIndex: 'pic_id',
            sorter: true,
        },
        {
            title: t('差出名'),
            dataIndex: 'sender_name',
            sorter: true,
        },
        {
            title: t('差出住所'),
            dataIndex: 'sender_address',
            sorter: true,
        },
        {
            title: t('支払条件'),
            dataIndex: 'payment_condition',
            sorter: true,
        },
        {
            title: t('削除フラグ'),
            dataIndex: 'is_deleted',
            sorter: true,
            render: text => (text ? 'はい' : 'いいえ'),
        },
        {
            title: t('作成日'),
            dataIndex: 'created_at',
            sorter: true,
        },
        {
            title: t('作成者'),
            dataIndex: 'created_by',
            sorter: true,
        },
        {
            title: t('更新者'),
            dataIndex: 'updated_by',
            sorter: true,
        },
        {
            title: t('更新日'),
            dataIndex: 'updated_at',
            sorter: true,
        },
        {
            title: t('発行日'),
            dataIndex: 'issued_date',
            sorter: true,
        },
        {
            title: t('受注済日'),
            dataIndex: 'ordered_date',
            sorter: true,
        },
        {
            title: t('納期'),
            dataIndex: 'delivery_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('有効期限'),
            dataIndex: 'expiration_date',
            sorter: true,
        },
        {
            title: t('column:Status'),
            dataIndex: 'status',
            sorter: true,
            render: (text, record) =>
                getClassificationItemName(
                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                    record.project.status.toString()
                ),
        },
        {
            title: t('column:Instruction status'),
            dataIndex: 'instruction_status',
            sorter: true,
            render: (text, record) =>
                getClassificationItemName(ClassificationTypeEnum.BUSINESS_STATUS.value, record.status.toString()),
        },
        { title: '粗利', dataIndex: 'gross_profit', sorter: true, render: text => formatNumber(text) },
        { title: '小計', dataIndex: 'subtotal', sorter: true, render: text => formatNumber(text) },
        { title: '消費税', dataIndex: 'consumption_tax', sorter: true, render: text => formatNumber(text) },
        { title: t('column:Total'), dataIndex: 'total', sorter: true, render: text => formatNumber(text) },
    ]
    const defaultColumns = [
        'project_code',
        'instruction_code',
        'customer',
        'status',
        'gross_profit',
        'consumption_tax',
        'total',
    ]
    const userInstructionColumns = userSettingColumns(
        currentUser.user_setting,
        'instructions',
        instructionColumns,
        defaultColumns
    )

    const instructionDetailColumns = [
        {
            title: 'タイトル',
            dataIndex: 'title',
        },
        {
            title: '仕様',
            dataIndex: 'spec',
        },
        {
            title: 'サイズ',
            dataIndex: 'size',
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            render: q => formatNumber(q),
        },
        {
            title: '単位',
            dataIndex: 'unit',
            render: q => getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, q),
        },
        {
            title: '原価',
            dataIndex: 'unit_cost',
        },
        {
            title: '単価',
            dataIndex: 'unit_price',
            render: unitPrice => formatNumber(unitPrice),
        },

        {
            title: '税区分',
            dataIndex: 'tax_rate',
            render: q => getClassificationItemNameFromValue(ClassificationTypeEnum.TAX_RATE.value, q),
        },
        {
            title: '金額',
            dataIndex: 'amount',
            render: amount => formatNumber(amount),
        },
    ]

    const updateFilters = obj => {
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        const newFilters = Object.assign(
            {
                'status': state.filters.status,
                'created_at:gte': state.filters['created_at:gte'],
                'created_at:lte': state.filters['created_at:lte'],
                'keyword': state.filters['keyword'],
                'is_deleted': state.filters['is_deleted'],
            },
            obj
        )
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getInstruction(newPagination, newFilters)
    }

    const tabChangeHandler = key => {
        dispatch(setKeywords([]))
        const newFilters = Object.assign(
            {},
            {
                'status': '0',
                'created_at:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:01'),
                'created_at:lte': moment().format('YYYY/MM/DD 23:59:59'),
                'keyword': [],
                'is_deleted': key,
            }
        )
        dispatch(setFilters(newFilters))
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setDetailSearch({}))
        getInstruction(state.pagination, newFilters, newSorter)
        dispatch(setFilters(newFilters))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getInstruction(newPagination, newFilters, newSorter)
    }

    const getInstruction = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSelectedKeys([])
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    const [selectedKeys, setSelectedKeys] = useState([])

    useEffect(() => {
        getInstruction()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="page-container">
            <Row className="mt-8 mb-16" justify="space-between">
                <Col>
                    <h1 className="page-title mb-0">
                        {t('navigation:{{entity}} list', { entity: t('entity:Instruction') })}
                    </h1>
                </Col>
                <Col>
                    <InsButton
                        width={120}
                        buttonType="link"
                        linkTo={Routes.private.business.PROJECT_CREATE.path}
                        icon={<AddIcon size={24} />}
                        buttonStyle="secondary"
                    >
                        {'案件作成'}
                    </InsButton>
                </Col>
            </Row>
            <InstructionListSearch
                onSearch={condition => updateFilters(condition)}
                onReset={() => updateFilters({ keyword: [] })}
                getInstruction={getInstruction}
                pagination={state.pagination}
            />
            <InsTab
                type="card"
                metaComponent={
                    <InsPagination
                        {...state.pagination}
                        onChange={pagination => getInstruction(pagination)}
                        pageSizeOptions={[100, 200, 300]}
                    />
                }
                onChange={key => tabChangeHandler(key)}
                activeKey={state.filters.is_deleted.toString()}
                defaultActiveKey="0"
            >
                <Tabs.TabPane tab={t('common:Processing')} key="0" />
                <Tabs.TabPane tab={t('common:Delete')} key="1" />
            </InsTab>
            <InsDataTable
                tableStyle="compact"
                striped
                loading={state.loading ? { indicator: <InsLoader /> } : false}
                hasSelection={state.filters.is_deleted !== '1'}
                onSelectionChanged={keys => setSelectedKeys(keys)}
                subSelectedKeys={selectedKeys}
                data={state.data}
                columns={userInstructionColumns}
                expandable={{
                    expandedRowRender: record => (
                        <InsDataTable
                            data={record.details.filter(detail => !isHeadline(detail))}
                            columns={instructionDetailColumns}
                            bordered={true}
                            tableStyle="compact"
                            style={{ padding: 12 }}
                        />
                    ),
                    rowExpandable: r => r.details.length > 0,
                    expandIconColumnIndex: 9,
                }}
                onChange={(pagination, filter, sorter) => {
                    getInstruction(state.pagination, state.filters, sorter)
                }}
                pagination={state.pagination}
                filters={state.filters}
                sorter={state.sorter}
                ctlSortOrder={true}
            />
            <FloatBarBehavior
                visible={selectedKeys.length > 0}
                data={state.data}
                selectedIds={selectedKeys}
                setSelectedIds={setSelectedKeys}
                documentType={BusinessPageEnum.INSTRUCTIONS.value}
                fetchData={getInstruction}
            />
        </div>
    )
}

const mapState = state => ({ state: state.instruction, currentUser: state.auth.user })
export default connect(mapState)(InstructionList)

InstructionList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    currentUser: PropTypes.object,
}
