import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import PaymentListSearch from 'modules/business/payment/PaymentListSearch'
import {
    getData,
    setData,
    setFilters,
    setPagination,
    setLoading,
    setSorter,
    setPaymentType,
} from 'modules/business/payment/payment.slice'
import history from 'helpers/history.helper'
import Routes from 'routes'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import CollectDataTable from 'modules/business/payment/datatable/CollectDataTable'
import TransferDataTable from 'modules/business/payment/datatable/TransferDataTable'
import { PaymentType } from 'enums/payment.enum'
import InsButton from 'components/InsButton'
import { AssignmentIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import { dateToStr } from 'helpers/date.helper'

const PaymentList = ({ state }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    let { path, url } = useRouteMatch()

    const getPayments = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setData([]))
        dispatch(setLoading(true))
        dispatch(getData({ pagination, filters, sorter }))
    }

    const updateFilters = (pagination, filters, sorter) => {
        const newPagination = Object.assign({}, state.pagination, { current: 1 }, pagination)
        const newFilters = Object.assign({}, state.filters, filters)
        const newSorter = Object.assign({}, state.sorter, sorter)
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        dispatch(setSorter(newSorter))
        getPayments(newPagination, newFilters, newSorter)
    }

    const exportCollectMoney = async () => {
        const blob = await api.getBlob(APP_URLS.PAYMENTS_EXPORT, state.filters, {
            'Accept':
                'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
            'Accept-Encoding': 'gzip, deflate, br',
        })
        saveAs(blob, '請求先別売掛残高一覧表 ' + dateToStr(new Date(), 'YYYY/MM/DD H:mm:ss') + '.xlsx')
    }

    const setDefaultCollectFilters = moneyReceivedStatus => {
        const defaultFilter = {
            'issued_date:gte': moment().subtract(1, 'months').format('YYYY/MM/DD'),
            'issued_date:lte': moment().add(2, 'months').format('YYYY/MM/DD'),
            'delay_status': 0,
            'is_purchase_order': false,
            'keyword': [],
        }
        updateFilters(
            { current: 1 },
            {
                ...defaultFilter,
                money_received_status: moneyReceivedStatus,
            },
            {
                order: 'descend',
                field: 'invoices.id',
            }
        )
    }

    const setDefaultTransferFilters = paidStatus => {
        const defaultFilter = {
            'issued_date:gte': moment().subtract(1, 'months').format('YYYY/MM/DD'),
            'issued_date:lte': moment().add(2, 'months').format('YYYY/MM/DD'),
            'delay_status': 0,
            'is_purchase_order': true,
            'keyword': [],
        }
        updateFilters(
            { current: 1 },
            {
                ...defaultFilter,
                paid_status: paidStatus,
            },
            {
                order: 'descend',
                field: 'project_id',
            }
        )
    }

    useEffect(() => {
        if (state.paymentType === PaymentType.COLLECT.value) {
            history.push(`${Routes.private.business.PAYMENT.path}/collect`)
        } else if (state.paymentType === PaymentType.TRANSFER.value) {
            history.push(`${Routes.private.business.PAYMENT.path}/transfer`)
        }
        getPayments()
    }, [])

    if (can('PAYMENT_HIDDEN')) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }

    return (
        <div className="page-container">
            <Row className="mt-8 mb-16" justify="space-between">
                <Col>
                    <h1 className="page-title mb-0">回収･支払</h1>
                </Col>
                <Col>
                    {state.paymentType === PaymentType.COLLECT.value && (
                        <InsButton
                            style={{ width: 196, marginLeft: 5 }}
                            icon={<AssignmentIcon size={16} className="color-pantone-2422-c" />}
                            buttonStyle="default"
                            onClick={() => exportCollectMoney()}
                        >
                            {t('請求先別売掛残高一覧表')}
                        </InsButton>
                    )}
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <PaymentListSearch
                        paymentType={state.paymentType}
                        changePaymentType={value => {
                            dispatch(setData([]))
                            dispatch(setLoading(true))
                            dispatch(setPaymentType(value))
                            if (value === PaymentType.COLLECT.value) {
                                history.push(`${Routes.private.business.PAYMENT.path}/collect`)
                                setDefaultCollectFilters(false)
                            } else if (value === PaymentType.TRANSFER.value) {
                                history.push(`${Routes.private.business.PAYMENT.path}/transfer`)
                                setDefaultTransferFilters(false)
                            }
                            const newPagination = {
                                current: 1,
                                pageSize: 100,
                                total: 1,
                            }
                            dispatch(setPagination(newPagination))
                        }}
                        updateFilters={(pagination, filters, sorter) => updateFilters(pagination, filters, sorter)}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${url}/collect`} />
                        </Route>
                        <Route exact={true} path={`${path}/collect`}>
                            <CollectDataTable
                                updateFilters={(pagination, filters, sorter) =>
                                    updateFilters(pagination, filters, sorter)
                                }
                                setDefaultFilters={value => setDefaultCollectFilters(value)}
                            />
                        </Route>
                        <Route exact={true} path={`${path}/transfer`}>
                            <TransferDataTable
                                updateFilters={(pagination, filters, sorter) =>
                                    updateFilters(pagination, filters, sorter)
                                }
                                setDefaultFilters={value => setDefaultTransferFilters(value)}
                            />
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </div>
    )
}

const mapState = state => ({ state: state.payment })
export default connect(mapState)(PaymentList)

PaymentList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
}
