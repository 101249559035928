import PropTypes from 'prop-types'
import React from 'react'
import { Select } from 'antd'
import history from 'helpers/history.helper'
import Routes from 'routes'
import BusinessPageEnum from 'enums/business_page.enum'
import InsSelect from 'components/select/InsSelect'

const BusinessSelect = ({ pageId }) => {
    return (
        <>
            <InsSelect
                value={pageId}
                onChange={value => {
                    switch (value) {
                        case BusinessPageEnum.PROJECTS.value:
                            history.push(Routes.private.business.PROJECT.path)
                            break
                        case BusinessPageEnum.ESTIMATIONS.value:
                            history.push(Routes.private.business.ESTIMATION.path)
                            break
                        case BusinessPageEnum.INSTRUCTIONS.value:
                            history.push(Routes.private.business.INSTRUCTION.path)
                            break
                        case BusinessPageEnum.PURCHASE_ORDER.value:
                            history.push(Routes.private.business.PLACE_ORDER.path)
                            break
                        case BusinessPageEnum.SALES.value:
                            history.push(Routes.private.business.SALE.path)
                            break
                        case BusinessPageEnum.INVOICES.value:
                            history.push(Routes.private.business.INVOICE.path)
                            break
                        case BusinessPageEnum.RED_SLIPS.value:
                            history.push(Routes.private.business.RED_SLIP.path)
                            break
                        default:
                            break
                    }
                }}
                style={{ width: '120px', marginRight: '4px' }}
            >
                {Object.values(BusinessPageEnum).map(option => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.text}
                    </Select.Option>
                ))}
            </InsSelect>
        </>
    )
}

export default BusinessSelect

BusinessSelect.propTypes = {
    pageId: PropTypes.number,
}
