import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

const InsInputNumber2 = ({
    className,
    value,
    min,
    max,
    step,
    style,
    disabled,
    showSpinButton,
    formatNumber,
    onChange,
    ...rest
}) => {
    const [inputValue, setInputValue] = useState(value)

    let inputClassName = 'custom-input'
    if (className) {
        inputClassName = className
    }

    let btnUp = () => {
        let val = 1
        let oldValue = parseInt(inputValue)
        if (max === undefined) {
            val = oldValue + step
            setInputValue(val)
        } else if (oldValue < max) {
            let val = oldValue + step
            if (val > max) {
                setInputValue(max)
            } else setInputValue(val)
        }
        onChange && onChange(val)
    }

    let btnDown = () => {
        let val = 1
        let oldValue = parseInt(inputValue)
        if (min === undefined) {
            val = oldValue - step
            setInputValue(val)
        } else if (oldValue > min) {
            val = oldValue - step
            if (val < min) setInputValue(min)
            else setInputValue(val)
        }
        onChange && onChange(val)
    }

    let changeValue = value => {
        let val = value.target.value

        if (formatNumber) {
            val = val.replace(/[^0-9$]/g, '')
        }
        val = parseInt(val)
        val = isNaN(val) ? 0 : val
        if (val > max) {
            val = max
        } else if (val < min) {
            val = min
        }

        setInputValue(val)
        onChange && onChange(val)
    }

    const handleKeyUp = e => {
        const keyCode = e.keyCode || e.which
        if (keyCode === 38) {
            btnUp()
        } else if (keyCode === 40) {
            btnDown()
        }
    }

    useEffect(() => {
        setInputValue(value)
    }, [value])

    return (
        <Input
            type="string"
            className={inputClassName}
            disabled={disabled}
            value={inputValue}
            step={step}
            style={style}
            min={min}
            max={max}
            onChange={changeValue}
            onKeyUp={handleKeyUp}
            suffix={
                showSpinButton ? (
                    <div>
                        <span className="custom-input-number-btn custom-input-number-btn-up" onClick={btnUp} />
                        <span className="custom-input-number-btn custom-input-number-btn-down" onClick={btnDown} />
                    </div>
                ) : null
            }
            {...rest}
        />
    )
}

InsInputNumber2.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    showSpinButton: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func.isRequired,
}

InsInputNumber2.defaultProps = {
    step: 1,
    showSpinButton: true,
    formatNumber: false,
    min: 0,
}

export default InsInputNumber2
