import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { InsBlockLoader } from 'components/InsLoader'
import TrackingCreate from 'modules/business/tracking/TrackingCreate'
import TrackingUpdate from 'modules/business/tracking/TrackingUpdate'
import TrackingPreview from 'modules/business/tracking/TrackingPreview'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'

const TrackingTab = ({ project, response }) => {
    const [loading, setLoading] = useState(true)
    const [tracking, setTracking] = useState({})

    useEffect(() => {
        setLoading(true)
        setTracking(project.tracking)
        setLoading(false)
    }, [project.tracking])

    const isExisted = tracking && tracking.id
    // current Route
    let { path, url } = useRouteMatch()

    const updateLink = () => {
        response(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) + APP_URLS.TRACKING + '/update')
    }

    const redirect = redirectTo => {
        if (!redirectTo) {
            redirectTo = Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) + APP_URLS.TRACKING
        }
        return response(redirectTo)
    }

    const renderTrackingRoute = () => {
        return (
            <Switch>
                <Route exact path={`${path}`}>
                    {isExisted ? (
                        <TrackingPreview
                            project={project}
                            tracking={tracking}
                            response={response}
                            updateLink={updateLink}
                        />
                    ) : (
                        <Redirect to={`${url}/create`} />
                    )}
                </Route>

                <Route exact path={`${path}/create`}>
                    {isExisted ? (
                        <Redirect to={`${url}/preview`} />
                    ) : (
                        <TrackingCreate
                            project={project}
                            response={response}
                            updateLink={redirectTo => redirect(redirectTo)}
                        />
                    )}
                </Route>

                <Route exact path={`${path}/update`}>
                    {isExisted ? (
                        <TrackingUpdate
                            project={project}
                            tracking={tracking}
                            updateLink={redirectTo => redirect(redirectTo)}
                        />
                    ) : (
                        <Redirect to={`${url}/create`} />
                    )}
                </Route>

                <Route exact path={`${path}/preview`}>
                    <TrackingPreview
                        project={project}
                        tracking={tracking}
                        response={response}
                        updateLink={updateLink}
                    />
                </Route>
            </Switch>
        )
    }

    return <>{loading ? <InsBlockLoader /> : renderTrackingRoute()}</>
}

export default TrackingTab

TrackingTab.propTypes = {
    project: PropTypes.object.isRequired,
    response: PropTypes.func,
}
