import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutsourceForm from 'modules/master/outsource/OutsourceForm'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import { useParams } from 'react-router-dom'
import InsBackLink from 'components/InsBackLink'
import history from 'helpers/history.helper'

const OutsourceUpdate = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [outsource, setOutsource] = useState(null)

    useEffect(() => {
        const getOutsource = async id => {
            const data = await api.get(APP_URLS.OUTSOURCES_DETAIL.replace(':id', id))
            if (data) {
                setOutsource(data)
                setLoading(false)
            }
        }

        getOutsource(id)
    }, [id])

    const updateOutsource = async (values, redirectTo) => {
        const data = await api.put(APP_URLS.OUTSOURCES_DETAIL.replace(':id', id), values)
        if (data) {
            if (!redirectTo) {
                history.goBack()
            } else history.push(redirectTo)
        }
    }

    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} edit', { entity: t('entity:Outsource') })}
            </h1>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <OutsourceForm
                    modalConfirmSave={modalConfirmSave}
                    setModalConfirmSave={setModalConfirmSave}
                    isEdit={true}
                    initialValues={outsource}
                    onSubmit={(values, redirectTo, confirmSave = true) => {
                        if (confirmSave) setModalConfirmSave(true)
                        else updateOutsource(values, redirectTo, confirmSave)
                    }}
                />
            )}
        </div>
    )
}

export default OutsourceUpdate
