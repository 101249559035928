import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ClearInputIcon, SearchIcon } from 'components/InsIcon'
import InsSelect from 'components/select/InsSelect'
import InsButton from 'components/InsButton'
import { useTranslation } from 'react-i18next'

const InputSizes = ['medium', 'big']

const InsInputSearch = ({
    defaultValue = [],
    value = null,
    onChange,
    onSearch,
    inputSize = 'medium',
    iconSize = 'medium',
    ...rest
}) => {
    const [selectedValues, setSelectedValues] = useState(defaultValue)
    const { t } = useTranslation()
    useEffect(() => {
        if (value !== null) {
            setSelectedValues(value)
        }
    }, [value])

    const changeHandler = value => {
        onChange && onChange(value)
        if (value.length === 0) {
            onSearch && onSearch([])
        }
    }

    const [currentInput, setCurrentInput] = useState(null)
    return (
        <>
            <div className="ins-input-search-wrapper">
                <InsSelect
                    {...rest}
                    mode="tags"
                    value={value === null ? selectedValues : value}
                    onChange={v => changeHandler(v)}
                    dropdownClassName="ins-hidden"
                    selectSize={inputSize}
                    onKeyUp={input => setCurrentInput(input.target.value)}
                    dropdownAutoWidth={false}
                    onInputKeyDown={input => {
                        if (input.key === 'Enter' && currentInput?.length === 0) {
                            input.stopPropagation()
                            onSearch && onSearch(value)
                        }
                    }}
                />

                {(selectedValues.length > 0 || currentInput?.length > 0) && (
                    <ClearInputIcon
                        className="ins-input-clear-icon active"
                        size={16}
                        onClick={e => {
                            setCurrentInput(null)
                            setSelectedValues([])
                            onChange && onChange([])
                            onSearch && onSearch([])
                        }}
                    />
                )}
                <InsButton
                    disabled={!(selectedValues.length > 0 || currentInput?.length > 0)}
                    className={`ml-4 ins-input-search-button${
                        selectedValues.length > 0 || currentInput?.length > 0 ? '-active' : ''
                    }`}
                    width={70}
                    buttonStyle="default"
                    icon={
                        <SearchIcon
                            className={
                                currentInput?.length > 0 || selectedValues.length > 0
                                    ? 'color-pantone-2738-c'
                                    : 'color-blue-gray-300'
                            }
                            size={16}
                            width={90}
                        />
                    }
                    onClick={e => {
                        e.stopPropagation()
                        onSearch(selectedValues)
                    }}
                >
                    {'検索'}
                </InsButton>
            </div>
        </>
    )
}

export default InsInputSearch

InsInputSearch.propTypes = {
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    value: PropTypes.array,
    inputSize: PropTypes.oneOf(InputSizes),
    iconSize: PropTypes.oneOf(InputSizes),
}

InsInputSearch.defaultProps = {
    defaultValue: [],
    value: null,
}
