import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import { AssignmentIcon } from 'components/InsIcon'
import InsFloatBar from 'components/InsFloatBar'
import ReportListSearch from 'modules/business/report/ReportListSearch'
import InsDataTable from 'components/datatable/InsDataTable'
import { formatNumberComma } from 'helpers/number.helper'
import { connect, useDispatch } from 'react-redux'
import { getData, setFilters } from 'modules/business/report/report.slice'
import InsLoader from 'components/InsLoader'
import { dataIndexNextYear } from 'helpers/string.helper'
import ReportExport from 'modules/business/report/ReportExport'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import moment from 'moment'

const ReportList = ({ state }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [isIncludingYearOnYearChange, setIsIncludingYearOnYearChange] = useState(false)
    const isYearOnYearInclude = !!state.data.including_year_on_year

    const [keywordsFilter, setKeywordsFilter] = useState([])
    const dateFormatFileName = 'YYYYMMDDHHmmss'
    const [fileName, setFileName] = useState(`レポート-${moment.utc().format(dateFormatFileName)}`)

    const groupItem1 = [
        {
            label: '顧客',
            name: 'customer',
            pair_list: ['none', 'team', 'product.product_classification1', 'product.product_classification2'],
        },
        {
            label: 'チーム',
            name: 'team',
            pair_list: [
                'none',
                'customer',
                'person_in_charge',
                'area',
                'customer_classification',
                'customer.customer_industry_classification',
                'product.product_classification1',
                'product.product_classification2',
            ],
        },
        {
            label: '担当者',
            name: 'person_in_charge',
            pair_list: [
                'none',
                'customer',
                'area',
                'customer_classification',
                'customer.customer_industry_classification',
                'product.product_classification1',
                'product.product_classification2',
            ],
        },
        {
            label: '地域',
            name: 'area',
            pair_list: [
                'none',
                'customer',
                'team',
                'person_in_charge',
                'customer_classification',
                'customer.customer_industry_classification',
                'product.product_classification1',
                'product.product_classification2',
            ],
        },
        {
            label: '顧客区分',
            name: 'customer_classification',
            pair_list: [
                'none',
                'customer',
                'team',
                'person_in_charge',
                'area',
                'product.product_classification1',
                'product.product_classification2',
            ],
        },
        {
            label: '業種区分',
            name: 'customer.customer_industry_classification',
            pair_list: [
                'none',
                'customer',
                'team',
                'person_in_charge',
                'area',
                'product.product_classification1',
                'product.product_classification2',
            ],
        },
        {
            label: '商品区分1',
            name: 'product.product_classification1',
            pair_list: [
                'none',
                'customer',
                'team',
                'person_in_charge',
                'area',
                'customer_classification',
                'customer.customer_industry_classification',
                'product.product_classification2',
            ],
        },
        {
            label: '商品区分2',
            name: 'product.product_classification2',
            pair_list: [
                'none',
                'customer',
                'team',
                'person_in_charge',
                'area',
                'customer_classification',
                'customer.customer_industry_classification',
                'product.product_classification1',
            ],
        },
    ]

    const groupItem2 = [
        {
            label: 'なし',
            name: 'none', // none
        },
        {
            label: '顧客',
            name: 'customer',
        },
        {
            label: 'チーム',
            name: 'team',
        },
        {
            label: '担当者',
            name: 'person_in_charge',
        },
        {
            label: '地域',
            name: 'area',
        },
        {
            label: '顧客区分',
            name: 'customer_classification',
        },
        {
            label: '業種区分',
            name: 'customer.customer_industry_classification',
        },
        // {
        //     label: '商品',
        //     name: 'product',
        // },
        {
            label: '商品区分1',
            name: 'product.product_classification1',
        },
        {
            label: '商品区分2',
            name: 'product.product_classification2',
        },
    ]

    const metricType = [
        {
            label: '売上額',
            name: 'sales_amount',
        },
        {
            label: '売上件数',
            name: 'number_of_sales',
        },
        {
            label: '見積額',
            name: 'estimations_amount',
        },
        {
            label: '見積件数',
            name: 'number_of_estimations',
        },
        {
            label: '粗利額',
            name: 'gross_profit',
        },
    ]

    const getLabel = (arr, name) => {
        return (arr.find(m => m.name === name) || {}).label
    }

    const metricLabel = getLabel(metricType, state.data.metric_type)
    const renderMetricColumns = (data, isExpandedColumn) => {
        if (!data || !data.metric_range) {
            return []
        }

        return data.metric_range.map(m => ({
            //title: `${m} ${metricLabel} `,
            className: isExpandedColumn
                ? 'expanded-table-custom-cell' + (isYearOnYearInclude ? '' : ' align-right')
                : 'table-custom-cell',
            title: `${m}${isExpandedColumn ? ' ' : '\n'}${metricLabel} `,
            dataIndex: m,
            width: 250,
            // sorter: true,
            defaultSortOrder: 'descend',
            sorter: isExpandedColumn
                ? (a, b) => {
                      if (!a['metric_data'][m] && !b['metric_data'][m]) return 0
                      return (a['metric_data'][m] || 0) > (b['metric_data'][m] || 0) ? 1 : -1
                  }
                : true,
            render: (text, record) => {
                return (
                    <>
                        {isExpandedColumn ? (
                            <Row justify="space-around" align="middle">
                                <Col>{formatNumberComma(record.metric_data && record.metric_data[m])}</Col>
                                <Col>{isYearOnYearInclude && renderYearOnYearCompare(record, m, true)}</Col>
                            </Row>
                        ) : (
                            <span className="summary-invoice-label">
                                <Col>
                                    {formatNumberComma(record.group_1_metric_data && record.group_1_metric_data[m])}{' '}
                                </Col>
                                <Col>{isYearOnYearInclude && renderYearOnYearCompare(record, m, false)}</Col>
                            </span>
                        )}
                    </>
                )
            },
        }))
    }

    const renderYearOnYearCompare = (record, dataIndex, isExpandedColumn) => {
        const group_items_1 = processWithKeywordsFilter(state.data.group_items_1)
        const year_on_year = processWithKeywordsFilter(state.data.year_on_year)

        let curSum = 0
        let targetSum = 0
        if (isExpandedColumn) {
            curSum = (record.metric_data && record.metric_data[dataIndex]) || 0
            let finder = group_items_1.find(g => g.group_2_items.find(x => x === record))
            let target = (year_on_year || []).find(d => d.group_1_id === finder.group_1_id)
            let nested = ((target && target.group_2_items) || []).find(d => d.group_2_id === record.group_2_id)
            targetSum =
                (nested &&
                    nested.metric_data &&
                    nested.metric_data[dataIndexNextYear(dataIndex, state.data.metric_period === 1)]) ||
                0
        } else {
            curSum = (record.group_1_metric_data && record.group_1_metric_data[dataIndex]) || 0
            let target = (year_on_year || []).find(d => d.group_1_id === record.group_1_id)
            targetSum =
                (target &&
                    target.group_1_metric_data &&
                    target.group_1_metric_data[dataIndexNextYear(dataIndex, state.data.metric_period === 1)]) ||
                0
        }
        return (
            <>
                {curSum - targetSum !== 0 && (
                    <span className={curSum - targetSum < 0 ? 'color-pantone-213-c' : 'color-pantone-2422-c'}>
                        ( {curSum - targetSum < 0 ? '-' : '+'}
                        {formatNumberComma(Math.abs(curSum - targetSum))} )
                    </span>
                )}
            </>
        )
    }
    // for export
    const renderYearOnYearCompareForExport = (record, dataIndex, isExpandedColumn) => {
        const group_items_1 = processWithKeywordsFilter(state.data.group_items_1)
        const year_on_year = processWithKeywordsFilter(state.data.year_on_year)

        let curSum = 0
        let targetSum = 0
        if (isExpandedColumn) {
            curSum = (record.metric_data && record.metric_data[dataIndex]) || 0
            let finder = group_items_1.find(g => g.group_2_items.find(x => x === record))
            let target = (year_on_year || []).find(d => d.group_1_id === finder.group_1_id)
            let nested = ((target && target.group_2_items) || []).find(d => d.group_2_id === record.group_2_id)
            targetSum =
                (nested &&
                    nested.metric_data &&
                    nested.metric_data[dataIndexNextYear(dataIndex, state.data.metric_period === 1)]) ||
                0
        } else {
            curSum = (record.group_1_metric_data && record.group_1_metric_data[dataIndex]) || 0
            let target = (year_on_year || []).find(d => d.group_1_id === record.group_1_id)
            targetSum =
                (target &&
                    target.group_1_metric_data &&
                    target.group_1_metric_data[dataIndexNextYear(dataIndex, state.data.metric_period === 1)]) ||
                0
        }
        return curSum - targetSum !== 0
            ? `(${curSum - targetSum < 0 ? '-' : '+'}${formatNumberComma(Math.abs(curSum - targetSum))})`
            : ''
    }

    const reportColumnLabel = getLabel(groupItem1, state.data.group_item_1_type)
    const reportColumns = [
        {
            title: reportColumnLabel,
            dataIndex: 'group_1_text',
            sorter: true,
            className: 'white-space-nowrap',
            minWidth: 300,
            //fixed: 'left',
        },
        ...renderMetricColumns(state.data),
    ]

    const expandedColumnLabel = getLabel(groupItem2, state.data.group_item_2_type)
    const expandedColumns = [
        {
            title: expandedColumnLabel,
            dataIndex: 'group_2_text',
            // sorter: true,
            defaultSortOrder: 'descend',
            minWidth: 300,
            sorter: (a, b) => {
                return a.group_2_text.localeCompare(b.group_2_text)
            },
        },
        ...renderMetricColumns(state.data, true),
    ]

    const [expanderLocalSorter, setExpanderLocalSorter] = useState({})
    const expanderSortByRecord = () => {
        // return expanderLocalSorter[record.group_1_id]
    }
    const expanderSetSorter = (record, sorter) => {
        setExpanderLocalSorter(Object.assign({}, expanderLocalSorter, { [record.group_1_id]: sorter }))
    }

    const expandedRowRender = record => {
        if (!record.group_2_items.length) return null
        return (
            <InsDataTable
                columns={expandedColumns}
                data={record.group_2_items}
                rowKey={e => e.group_2_id}
                pagination={false}
                style={{ padding: 4 }}
                sorter={expanderSortByRecord(record)}
                onChange={(pagination, filter, sorter) => expanderSetSorter(record, sorter)}
                customClassName="report-expanded-table-custom"
            />
        )
    }

    const getReports = (filters = state.filters, sorter = state.sorter) => {
        const pagination = {}
        dispatch(getData({ pagination, filters, sorter }))
    }

    // useEffect(() => {
    //     getReports()
    // }, [])

    const isNotLike = (keywords, target) => {
        return keywords.some(keyword => !target.toLowerCase().includes(keyword.toLowerCase()))
    }

    const processWithKeywordsFilter = data => {
        if (keywordsFilter.some(keyword => keyword.length > 0)) {
            if (state.data.group_item_2_type === 'none') {
                return data.filter(d => !isNotLike(keywordsFilter, d.group_1_text))
            }
            return data
                .map(d => {
                    // group_1_text !== keyword => check group 2
                    if (isNotLike(keywordsFilter, d.group_1_text)) {
                        let group2Items = d.group_2_items
                        group2Items = group2Items.filter(item => !isNotLike(keywordsFilter, item.group_2_text))

                        let tmp = {}
                        let orgMetricData = d.group_1_metric_data
                        Object.keys(orgMetricData).map(key => {
                            tmp[key] = group2Items.reduce(
                                (accumulator, current) =>
                                    accumulator + ((current.metric_data && current.metric_data[key]) || 0),
                                0
                            )
                        })
                        return Object.assign({}, d, {
                            group_2_items: group2Items,
                            group_1_total: group2Items.reduce(
                                (accumulator, current) => accumulator + current.group_1_total,
                                0
                            ),
                            group_1_metric_data: tmp,
                        })
                    }
                    return d
                })
                .filter(d => d.group_2_items.length > 0)
        }
        return data
    }

    const handleTableSort = sorter => {
        // reset expander sort + special case check
        if (!(state.filters.group_1_sorter && sorter.field === state.filters.group_1_sorter.field && !sorter.order)) {
            setExpanderLocalSorter({})
        }

        const newFilters = Object.assign({}, state.filters, {
            group_1_sorter: sorter,
        })
        dispatch(setFilters(newFilters))
        getReports(newFilters)
    }

    if (can('REPORT_HIDDEN')) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }

    return (
        <>
            <div className="page-container">
                <Row className="mt-8 mb-16" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-0">{'レポート'}</h1>
                    </Col>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Col span={24}>
                        <ReportListSearch
                            onSearch={() => {}}
                            keywordsFilter={keywordsFilter}
                            setKeywordsFilter={setKeywordsFilter}
                            getReports={getReports}
                            pagination={state.pagination}
                            isIncludingYearOnYearChange={isIncludingYearOnYearChange}
                            setIsIncludingYearOnYearChange={setIsIncludingYearOnYearChange}
                            groupItem1={groupItem1}
                            groupItem2={groupItem2}
                            metricType={metricType}
                        />
                    </Col>
                </Row>
                <div className="report-table mb-48" style={{ overflow: 'auto', maxHeight: 580 }}>
                    <InsDataTable
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        data={processWithKeywordsFilter(state.data.group_items_1)}
                        columns={reportColumns}
                        rowKey={e => e.group_1_id}
                        defaultExpandAllRows={true}
                        expandable={{
                            expandedRowRender,
                        }}
                        onChange={(pagination, filter, sorter) => handleTableSort(sorter)}
                        filters={state.filters}
                        // scroll={{ x: true, y: 580 }}
                        expandedRowKeys={(state.data.group_items_1 || []).map(g => g.group_1_id)}
                        customClassName="report-table-custom"
                    />
                </div>
                <InsFloatBar visible={true}>
                    <Row justify="space-between" align="middle">
                        <ReportExport
                            data={state.data}
                            element={
                                <InsButton
                                    className="ml-16"
                                    style={{ width: 106 }}
                                    icon={<AssignmentIcon size={16} className="color-pantone-2422-c" />}
                                    buttonStyle="default"
                                    onClick={() => setFileName(`レポート-${moment.utc().format(dateFormatFileName)}`)}
                                >
                                    {t('Excel作成')}
                                </InsButton>
                            }
                            columns={reportColumns}
                            items={processWithKeywordsFilter(state.data.group_items_1) || []}
                            fileName={fileName}
                            info={{
                                group_1_label: reportColumnLabel,
                                group_2_label: expandedColumnLabel,
                                metricLabel: metricLabel,
                            }}
                            expandedSorter={expanderLocalSorter}
                            isYearOnYearInclude={isYearOnYearInclude}
                            renderYearOnYearCompareForExport={renderYearOnYearCompareForExport}
                        />
                    </Row>
                </InsFloatBar>
            </div>
        </>
    )
}

const mapState = state => ({
    state: state.report,
})

export default connect(mapState)(ReportList)

ReportList.propTypes = {
    state: PropTypes.any,
}
