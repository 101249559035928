import React from 'react'
import { Col, Row, Tabs } from 'antd'
import InsTab from 'components/InsTab'
import InsPagination from 'components/datatable/InsPagination'

const TabDemo = () => {
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">InsTab</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col span={24}>
                    <InsTab type="card" metaComponent={<InsPagination pageSize={300} current={1} total={12345} />}>
                        <Tabs.TabPane tab="有効" key="1">
                            有効
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="無効" key="0">
                            有効
                        </Tabs.TabPane>
                    </InsTab>
                </Col>
            </Row>
        </div>
    )
}

export default TabDemo
