import React from 'react'
import PropTypes from 'prop-types'
import InsBackLink from 'components/InsBackLink'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'
import { useParams } from 'react-router-dom'
import ProductDetailComponent from 'modules/master/product/ProductDetailComponent'
import Routes from 'routes'

const ProductDetail = () => {
    const { id } = useParams()

    const deleteProduct = async () => {
        await api.delete(APP_URLS.PRODUCTS_DETAIL.replace(':id', id))
        history.push(Routes.private.master.PRODUCT_LIST.path)
    }

    return (
        <div className="page-container with-sidebar mb-64">
            <InsBackLink />
            <ProductDetailComponent id={id} onDelete={() => deleteProduct()} />
        </div>
    )
}

export default ProductDetail

ProductDetail.propTypes = {
    match: PropTypes.object.isRequired,
}
