export const CollectionScheduleMonthEnum = Object.freeze({
    CURRENT_MONTH: { value: 0, text: '当月' },
    NEXT_MONTH: { value: 1, text: '翌月' },
    NEXT_NEXT_MONTH: { value: 2, text: '翌々月' },
    DIRECT_SETTING: { value: -1, text: '直接設定' },
})

export const CollectionScheduleDayEnum = Object.freeze({
    END_OF_THE_MONTH: { value: 99, text: '月末' },
    DATE_SELECTION: { value: -1, text: '日付選択' },
})

export const CollectionScheduleFlagEnum = Object.freeze({
    MAKE: { value: 1, text: 'する' },
    NOT: { value: 0, text: 'しない' },
})
