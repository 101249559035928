import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Radio, Row, Select } from 'antd'
import InsButton from 'components/InsButton'
import { AddIcon, CloseIcon, EditIcon } from 'components/InsIcon'
import Routes from 'routes'
import InsFloatBar from 'components/InsFloatBar'
import { getClassificationsByType } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { useParams } from 'react-router-dom'
import InsSelect from 'components/select/InsSelect'
import InsHelpText from 'components/InsHelpText'
import InsPostCodeInput from 'components/InsPostCodeInput'
import InsInput from 'components/InsInput'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import InsModal from 'components/InsModal'

const ShippingAddressForm = ({ isEdit, initialValues = {}, onSubmit, modalConfirmSave, setModalConfirmSave }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const { id } = useParams()
    const shippingAddressTitle = getClassificationsByType(ClassificationTypeEnum.CUSTOMER_TITLE.value)
    const prefectureCode = getClassificationsByType(ClassificationTypeEnum.PREFECTURE.value)

    initialValues = Object.assign(
        {
            shipping_address_code: '',
            shipping_address_name1: '',
            shipping_address_name_department1: '',
            shipping_address_name_department2: '',
            shipping_address_title: shippingAddressTitle.length > 0 && shippingAddressTitle[0].value,
            post_code: '',
            prefecture_code: prefectureCode.length > 0 && prefectureCode[0].value,
            address1: '',
            address2: '',
            phone_number: '',
            fax_number: '',
            // client: '',
            // client_id: initialValues.client,
            direct_delivery_pic_name: '',
            is_enabled: 1,
        },
        initialValues
    )

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => onSubmit(form.getFieldsValue(), null, false)}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className="ins-form pt-0"
                layout="vertical"
                form={form}
                onFinish={values => onSubmit(values)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            name="shipping_address_code"
                            label={t('column:Shipping address management code')}
                            validateTrigger={['onBlur']}
                            rules={[
                                { required: true, message: t('validation:Please enter shipping address code') },
                                { max: 20, message: t('Shipping address code must be max 20 characters') },
                                {
                                    validator: async (rule, value) => {
                                        if (value) {
                                            if (isEdit && initialValues.shipping_address_code.trim() === value.trim())
                                                return Promise.resolve()
                                            const data = await api.post(APP_URLS.SHIPPING_CHECK_EXISTS_CODE, {
                                                shipping_address_code: value,
                                            })
                                            if (data && data.is_existed) {
                                                return Promise.reject(t('validation:Shipping address code existed'))
                                            }
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}
                        >
                            <InsInput style={{ width: 140 }} maxLength={20} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            label={t('common:Shipping address name1')}
                            name="shipping_address_name1"
                            validateTrigger={['onBlur']}
                            rules={[
                                { required: true, message: t('validation:Please enter shipping address name1') },
                                { max: 100, message: t('Shipping address name1 must be max 100 characters') },
                            ]}
                        >
                            <InsInput style={{ width: 280 }} maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            label={t('common:Shipping address name department1')}
                            name="shipping_address_name_department1"
                        >
                            <InsInput style={{ width: 280 }} maxLength={200} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            label={t('common:Shipping address department2')}
                            name="shipping_address_name_department2"
                        >
                            <InsInput style={{ width: 280 }} maxLength={200} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            label={t('common:Shipping address title')}
                            name="shipping_address_title"
                            style={{ width: 280 }}
                        >
                            <InsSelect placeholder="様" style={{ width: '114px' }} searchOptions={true}>
                                {shippingAddressTitle.map(clf => (
                                    <Select.Option key={clf.value} value={clf.value} title={clf.text}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item
                            name="post_code"
                            label={t('column:Post code')}
                            extra={
                                <InsHelpText>{t('Please enter half size number in this format 000-0000')}</InsHelpText>
                            }
                            rules={[
                                {
                                    pattern: /^\d{3}-?\d{4}$/,
                                    message: t('validation:{{item}} is invalid', {
                                        item: t('column:Post code'),
                                    }),
                                },
                            ]}
                        >
                            <InsPostCodeInput
                                onSearchSuccess={data => {
                                    if (data.prefecture_code) {
                                        form.setFieldsValue({ prefecture_code: data.prefecture_code })
                                    }
                                    if (data.address) {
                                        form.setFieldsValue({ address1: data.address })
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            label={t('common:Street address')}
                            name="prefecture_code"
                            style={{ width: 280 }}
                            className={'street_address'}
                        >
                            <InsSelect onSelect={value => console.log(value)} style={{ width: 140 }}>
                                {prefectureCode.map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                        <Form.Item name="address1" style={{ width: 280 }} className={'street_address'}>
                            <InsInput placeholder={t('Placeholder address1')} maxLength={200} />
                        </Form.Item>
                        <Form.Item name="address2" style={{ width: 280 }}>
                            <InsInput placeholder={t('Placeholder address2')} maxLength={200} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item
                            name="phone_number"
                            label={t('column:Phone number')}
                            extra={<InsHelpText>{t('Help Phone number')}</InsHelpText>}
                            rules={[
                                {
                                    pattern: /^[0-9]?([-]?[\s]?[0-9])+$/,
                                    message: t('validation:Please enter half-width numbers'),
                                },
                            ]}
                            validateTrigger={['onBlur']}
                        >
                            <InsInput placeholder={'0000-0000-0000'} style={{ width: 140 }} maxLength={20} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Form.Item name="fax_number" label={t('column:Fax number')} style={{ width: 140 }}>
                            <InsInput maxLength={20} />
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row gutter={16}>*/}
                {/*    <Col>*/}
                {/*        <Form.Item name="client_id" label={t('column:Client')} style={{ width: 280 }}>*/}
                {/*            <ClientSelect*/}
                {/*                placeholder="依頼主を検索"*/}
                {/*                record={initialValues.client}*/}
                {/*                style={{ width: 280 }}*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row>
                    <Col>
                        <Form.Item
                            name="direct_delivery_pic_name"
                            label={t('column:Direct delivery pic')}
                            style={{ width: 140 }}
                        >
                            <InsInput placeholder="直送先担当者を検索" style={{ width: '180px' }} maxLength={255} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className="mb-36">
                    <Col>
                        <Form.Item label={t('Usage status')} name="is_enabled">
                            <Radio.Group>
                                <Radio value={1}>{t('common:Enabled')}</Radio>
                                <Radio value={0}>{t('common:Disabled')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <InsFloatBar visible={true} className="with-sidebar">
                    <Row gutter={8} justify="end">
                        <Col>
                            <InsButton
                                buttonStyle="outline"
                                buttonSize="big"
                                centered
                                width="120px"
                                icon={<CloseIcon />}
                                linkTo={
                                    isEdit
                                        ? Routes.private.master.SHIPPING_ADDRESS_DETAIL.path.replace(':id', id)
                                        : Routes.private.master.SHIPPING_ADDRESS_LIST.path
                                }
                                buttonType="link"
                            >
                                {t('common:Cancel')}
                            </InsButton>
                        </Col>
                        {isEdit ? (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<EditIcon size={20} />}
                                    isSubmitButton={true}
                                >
                                    {isEdit
                                        ? t('navigation:{{entity}} update new', { entity: t('entity:ShippingAddress') })
                                        : t('navigation:{{entity}} create new', {
                                              entity: t('entity:ShippingAddress'),
                                          })}
                                </InsButton>
                            </Col>
                        ) : (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<AddIcon />}
                                    isSubmitButton={true}
                                >
                                    {isEdit
                                        ? t('navigation:{{entity}} update new', { entity: t('entity:ShippingAddress') })
                                        : t('navigation:{{entity}} create new', {
                                              entity: t('entity:ShippingAddress'),
                                          })}
                                </InsButton>
                            </Col>
                        )}
                    </Row>
                </InsFloatBar>
            </Form>
        </>
    )
}

export default ShippingAddressForm

ShippingAddressForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
}
