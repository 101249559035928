import React, { useEffect, useState } from 'react'
import InsDataTable from 'components/datatable/InsDataTable'
import { Col, Row, Tabs } from 'antd'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import {
    getData,
    setFilters,
    setSorter,
    setKeywords,
    setDetailSearch,
    setPagination,
} from 'modules/business/placeOrder/place_order.slice'
import { useTranslation } from 'react-i18next'
import InsTab from 'components/InsTab'
import InsPagination from 'components/datatable/InsPagination'
import InsLoader from 'components/InsLoader'
import { dateFormat } from 'helpers/date.helper'
import { formatNumber, formatNumberComma } from 'helpers/number.helper'
import InsButton from 'components/InsButton'
import PlaceOrderListSearch from 'modules/business/placeOrder/PlaceOrderListSearch'
import Routes from 'routes'
import { AddIcon } from 'components/InsIcon'
import { getClassificationItemName, getClassificationItemNameFromValue } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { userSettingColumns } from 'helpers/user_setting.helper'
import { isHeadline } from 'helpers/detail.helper'
import moment from 'moment'
import BusinessPageEnum from 'enums/business_page.enum'
import FloatBarBehavior from 'modules/business/common/FloatBarBehavior'
import NavigationEnum from 'enums/navigation.enum'

const PlaceOrderList = ({ state, currentUser }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const placeOrderColumns = [
        {
            title: t('column:ID'),
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: t('案件ID'),
            dataIndex: 'project_id',
            sorter: true,
        },
        {
            title: t('column:Project code'),
            dataIndex: 'project_code',
            sorter: true,
            render: (text, record) => <>{record.project_for_list && record.project_for_list.project_code}</>,
        },
        {
            title: t('column:Place order name'),
            dataIndex: 'place_order_name',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    linkTo={
                        Routes.private.business.PROJECT_DETAIL.path.replace(
                            ':id',
                            record.project_for_list && record.project_for_list.id
                        ) +
                        '/place-orders?from=' +
                        NavigationEnum.LIST_PLACE_ORDERS
                    }
                    buttonType="link"
                >
                    {text}
                </InsButton>
            ),
        },

        {
            title: t('column:Outsource name'),
            dataIndex: 'outsource_name',
            sorter: true,
            render: (text, record) => <>{record.outsource && record.outsource.outsource_name}</>,
        },
        {
            title: t('column:Place order status'),
            dataIndex: 'place_order_status',
            sorter: true,
            render: (text, record) =>
                getClassificationItemName(ClassificationTypeEnum.BUSINESS_STATUS.value, record.status.toString()),
        },
        {
            title: t('column:Status'),
            dataIndex: 'status',
            sorter: true,
            render: (text, record) =>
                getClassificationItemName(
                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                    record.project_for_list.status.toString()
                ),
        },
        {
            title: t('column:Created at'),
            dataIndex: 'created_at',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('column:Pic name'),
            dataIndex: 'pic_name',
            sorter: true,
            render: (text, record) => <>{record.pic && record.pic.pic_name}</>,
        },
        {
            title: t('column:Total'),
            dataIndex: 'total',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: t('発注書区分'),
            dataIndex: 'place_order_classification',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.PLACE_ORDER_CLASSIFICATION.value, text),
        },
        {
            title: t('発注番号'),
            dataIndex: 'place_order_code',
            sorter: true,
        },
        {
            title: t('外注先'),
            dataIndex: 'outsource_id',
            sorter: true,
        },
        {
            title: t('仕入先'),
            dataIndex: 'supplier_id',
            sorter: true,
        },
        {
            title: t('発注先担当者'),
            dataIndex: 'outsource_pic_name',
            sorter: true,
        },
        {
            title: t('発注先担当者敬称'),
            dataIndex: 'outsource_pic_title',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.CUSTOMER_TITLE.value, text),
        },
        {
            title: t('納入先'),
            dataIndex: 'delivery_destination',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.DELIVERY_TYPE.value, text),
        },
        {
            title: t('届け区分'),
            dataIndex: 'delivery_type',
            sorter: true,
        },
        {
            title: t('配送区分'),
            dataIndex: 'transport_type',
            sorter: true,
        },
        {
            title: t('担当者'),
            dataIndex: 'pic_id',
            sorter: true,
        },
        {
            title: t('差出名'),
            dataIndex: 'sender_name',
            sorter: true,
        },
        {
            title: t('差出名ID'),
            dataIndex: 'sender_id',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.ESTIMATION_SENDER.value, text),
        },
        {
            title: t('差出住所'),
            dataIndex: 'sender_address',
            sorter: true,
        },
        {
            title: t('粗利'),
            dataIndex: 'gross_profit',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: t('小計'),
            dataIndex: 'subtotal',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: t('消費税'),
            dataIndex: 'consumption_tax',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: t('備考'),
            dataIndex: 'note',
            sorter: true,
        },
        {
            title: t('削除フラグ'),
            dataIndex: 'is_deleted',
            sorter: true,
        },
        {
            title: t('作成者'),
            dataIndex: 'created_by',
            sorter: true,
        },
        {
            title: t('更新者'),
            dataIndex: 'updated_by',
            sorter: true,
        },
        {
            title: t('更新日'),
            dataIndex: 'updated_at',
            sorter: true,
        },
        {
            title: t('発行日'),
            dataIndex: 'issued_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('受注済日'),
            dataIndex: 'ordered_date',
            sorter: true,
        },
        {
            title: t('納期'),
            dataIndex: 'delivery_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('納期の時間帯'),
            dataIndex: 'delivery_time',
            sorter: true,
        },
        {
            title: t('発注先名'),
            dataIndex: 'supplier_pic_name',
            sorter: true,
        },
    ]
    const defaultColumns = [
        'project_code',
        'place_order_name',
        'outsource_name',
        'status',
        'created_at',
        'pic_name',
        'total',
    ]
    const userPlaceOrderColumns = userSettingColumns(
        currentUser.user_setting,
        'place_orders',
        placeOrderColumns,
        defaultColumns
    )

    const placeOrderDetailColumns = [
        {
            title: 'タイトル',
            dataIndex: 'title',
        },
        {
            title: '仕様',
            dataIndex: 'spec',
        },
        {
            title: 'サイズ',
            dataIndex: 'size',
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            render: q => formatNumber(q),
        },
        {
            title: '単位',
            dataIndex: 'unit',
            render: q => getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, q),
        },
        {
            title: '原価',
            dataIndex: 'unit_cost',
        },
        {
            title: '単価',
            dataIndex: 'unit_price',
            render: unitPrice => formatNumber(unitPrice),
        },

        {
            title: '税区分',
            dataIndex: 'tax_rate',
            render: q => getClassificationItemNameFromValue(ClassificationTypeEnum.TAX_RATE.value, q),
        },
        {
            title: '金額',
            dataIndex: 'amount',
            render: amount => formatNumberComma(amount),
        },
    ]

    const updateFilters = obj => {
        // const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        const newFilters = Object.assign(
            {},
            {
                'status': state.filters.status,
                'created_at:gte': state.filters['created_at:gte'],
                'created_at:lte': state.filters['created_at:lte'],
                'keyword': state.filters['keyword'],
                'is_deleted': state.filters['is_deleted'],
            },
            obj
        )
        dispatch(setFilters(newFilters))
        getPlaceOrders(state.pagination, newFilters)
    }

    const tabChangeHandler = key => {
        dispatch(setKeywords([]))
        const newFilters = Object.assign({}, state.filters, {
            'status': '0',
            'created_at:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:01'),
            'created_at:lte': moment().format('YYYY/MM/DD 23:59:59'),
            'keyword': [],
            'is_deleted': key,
        })
        dispatch(setFilters(newFilters))
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setDetailSearch({}))
        getPlaceOrders(state.pagination, newFilters, newSorter)
        dispatch(setFilters(newFilters))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getPlaceOrders(newPagination, newFilters, newSorter)
    }

    const getPlaceOrders = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSelectedKeys([])
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    const getDetails = record => {
        let details = record.details.filter(detail => !isHeadline(detail))
        let detailsDelivery = []
        record.delivery_destinations.map(deliveryDestination => {
            deliveryDestination.details.map(detail => {
                if (!isHeadline(detail)) {
                    detailsDelivery.push(detail)
                }
            })
        })
        return detailsDelivery.concat(details)
    }

    useEffect(() => {
        getPlaceOrders()
        // eslint-disable-next-line
    }, [])

    const [selectedKeys, setSelectedKeys] = useState([])

    return (
        <div className="page-container estimation-list-page">
            <Row className="mt-8 mb-16" justify="space-between">
                <Col>
                    <h1 className="page-title mb-0">
                        {t('navigation:{{entity}} list', { entity: t('entity:PlaceOrder') })}
                    </h1>
                </Col>
                <Col>
                    <InsButton
                        width={120}
                        buttonType="link"
                        linkTo={Routes.private.business.PROJECT_CREATE.path}
                        icon={<AddIcon size={24} />}
                        buttonStyle="secondary"
                    >
                        {t('entity:Item create')}
                    </InsButton>
                </Col>
            </Row>
            <PlaceOrderListSearch
                onSearch={condition => updateFilters(condition)}
                onReset={() => updateFilters({ keyword: [] })}
                getPlaceOrders={getPlaceOrders}
                pagination={state.pagination}
            />
            <InsTab
                type="card"
                metaComponent={
                    <InsPagination
                        {...state.pagination}
                        onChange={pagination => getPlaceOrders(pagination)}
                        pageSizeOptions={[100, 200, 300]}
                    />
                }
                onChange={key => tabChangeHandler(key)}
                activeKey={state.filters.is_deleted.toString()}
                defaultActiveKey="0"
            >
                <Tabs.TabPane tab={t('common:Processing')} key="0" />
                <Tabs.TabPane tab={t('common:Delete')} key="1" />
            </InsTab>
            <InsDataTable
                tableStyle="compact"
                striped
                loading={state.loading ? { indicator: <InsLoader /> } : false}
                hasSelection={state.filters.is_deleted !== '1'}
                onSelectionChanged={keys => setSelectedKeys(keys)}
                subSelectedKeys={selectedKeys}
                data={state.data}
                columns={userPlaceOrderColumns}
                expandable={{
                    expandedRowRender: record => (
                        <InsDataTable
                            data={getDetails(record)}
                            columns={placeOrderDetailColumns}
                            bordered={true}
                            tableStyle="compact"
                            style={{ padding: 12 }}
                        />
                    ),
                    rowExpandable: r => r.details || r.delivery_destinations,
                    expandIconColumnIndex: 8,
                }}
                onChange={(pagination, filter, sorter) => {
                    getPlaceOrders(state.pagination, state.filters, sorter)
                }}
                pagination={state.pagination}
                filters={state.filters}
                sorter={state.sorter}
                ctlSortOrder={true}
            />
            <FloatBarBehavior
                visible={selectedKeys.length > 0}
                data={state.data}
                selectedIds={selectedKeys}
                setSelectedIds={setSelectedKeys}
                documentType={BusinessPageEnum.PURCHASE_ORDER.value}
                fetchData={getPlaceOrders}
            />
        </div>
    )
}

const mapState = state => ({ state: state.placeOrder, currentUser: state.auth.user })
export default connect(mapState)(PlaceOrderList)

PlaceOrderList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    currentUser: PropTypes.object,
}
