import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InsBlockLoader } from 'components/InsLoader'
import TrackingForm from 'modules/business/tracking/form/TrackingForm'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'

const TrackingUpdate = ({ project, tracking, updateLink }) => {
    const [loading, setLoading] = useState(false)

    const updateTracking = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.put(APP_URLS.TRACKING_DETAIL.replace(':id', tracking.id), values)
        if (data) {
            updateLink(redirectTo).then(() => {
                setLoading(false)
            })
        }
    }

    return (
        <>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <TrackingForm
                    isEdit={true}
                    project={project}
                    initialValues={tracking}
                    onSubmit={(values, redirectTo) => updateTracking(values, redirectTo)}
                />
            )}
        </>
    )
}

export default TrackingUpdate

TrackingUpdate.propTypes = {
    project: PropTypes.object.isRequired,
    tracking: PropTypes.object,
    updateLink: PropTypes.func,
}
