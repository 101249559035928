import PropTypes from 'prop-types'
import React from 'react'
import InsInputSearch from 'components/InsInputSearch'
import InsCollapse from 'components/InsCollapse'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { setDetailSearch, setKeywords, updateDetailSearch, setFilters } from 'modules/master/user/user.slice'

const UserListSearch = ({ keywords, setKeywords, setDetailSearch, onSearch }) => {
    const { t } = useTranslation()

    return (
        <InsCollapse
            hasAdvancedSearch={false}
            header={
                <div>
                    <InsInputSearch
                        style={{ width: '500px' }}
                        placeholder={t('Contact code, contact name, etc')}
                        value={keywords}
                        onChange={values => setKeywords(values)}
                        onSearch={values => {
                            setDetailSearch({})
                            onSearch({ keyword: values })
                        }}
                    />
                </div>
            }
        />
    )
}

const mapState = state => ({
    keywords: state.user.keywords,
    detailSearch: state.user.detailSearch,
    filters: state.user.filters,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(UserListSearch)

UserListSearch.propTypes = {
    keywords: PropTypes.array.isRequired,
    onSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
}
