import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'

const name = 'scanner'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.COMPANIES, ...props })
)

const companySlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, { filters: { is_enabled: 1 } }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const { setLoading, setData, setPagination, setFilters, setSorter, setSelection } = companySlice.actions

export default companySlice.reducer
