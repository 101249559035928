import React from 'react'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InsBackLink from 'components/InsBackLink'
import StockForm from 'modules/master/stock/StockForm'

const StockCreate = () => {
    const { t } = useTranslation()

    const createStock = async values => {
        const data = await api.post(APP_URLS.STOCKS, values)
        if (data) {
            history.goBack()
        }
    }

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">{t('navigation:{{entity}} create', { entity: t('entity:Stock') })}</h1>
            <StockForm initialValues={{ remain_quantity: 0 }} onSubmit={values => createStock(values)} />
        </div>
    )
}

export default StockCreate
