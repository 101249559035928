import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Input, Radio, Row, Select } from 'antd'
import UserRoleEnum from 'enums/user_role.enum'
import InsSelect from 'components/select/InsSelect'
import InsButton from 'components/InsButton'
import { EditIcon, AddIcon, CloseIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsFloatBar from 'components/InsFloatBar'
import {
    EstimationPermissionEnum,
    InstructionPermissionEnum,
    InvoicePermissionEnum,
    PaymentPermissionEnum,
    PlaceOrderPermissionEnum,
    ProjectPermissionEnum,
    RedSlipPermissionEnum,
    ReportPermissionEnum,
    SalePermissionEnum,
    ShipmentPermissionEnum,
    SummaryInvoicePermissionEnum,
    // TrackingPermissionEnum,
} from 'enums/permission.enum'
import UserSettingModal from 'modules/master/user/UserSettingModal'
import InsDataTable from 'components/datatable/InsDataTable'
import { getUserSettingEnumKeyObject, getUserSettingEnumObject } from 'helpers/user_setting.helper'
import { getEnumManyText } from 'helpers/enum.helper'
import { connect } from 'react-redux'
import { setUser } from 'modules/common/auth/auth.slice'
import PasswordChangeModal from 'modules/master/user/PasswordChangeModal'
import { checkAdmin } from 'helpers/permission.helper'
import queryString from 'query-string'
import history from 'helpers/history.helper'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import InsBackLink from 'components/InsBackLink'
import InsModal from 'components/InsModal'

const scrollToRef = () => {
    window.scrollTo(0, 99999)
}

const UserForm = ({
    userSetting,
    isEdit,
    inModal,
    initialValues,
    onSubmit,
    onCancel,
    isAccountSetting,
    modalConfirmSave,
    setModalConfirmSave,
}) => {
    const executeScroll = () => scrollToRef()

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const isAdmin = checkAdmin()
    const [userSettingVisible, setUserSettingVisible] = useState({
        visible: false,
        userSettingEnum: {},
        userSettings: [],
    })
    let isGotoUserSetting = false
    let params = queryString.parse(window.location.search)
    if (params.user_setting) {
        isGotoUserSetting = true
    }
    const [userSettings, setUserSettings] = useState()
    const [userSettingDataTable, setUserSettingDataTable] = useState(null)
    const userSettingColumns = [
        {
            title: '',
            width: 140,
            dataIndex: 'name',
            render: text => {
                return <div>{t(text)}</div>
            },
        },
        {
            title: '',
            sorter: true,
            dataIndex: 'setting_text',
            render: (text, r) => {
                return (
                    <Row justify="space-between" align="middle">
                        <Col>
                            {r.setting_text.map(setting => {
                                return <span className="row-detail-item">{setting}</span>
                            })}
                        </Col>
                        <Col>
                            <InsButton
                                className="btn-update-row"
                                icon={<EditIcon size={20} className="color-pantone-2738-c" />}
                                buttonStyle="default"
                                onClick={() =>
                                    setUserSettingVisible({
                                        visible: true,
                                        userSettings: r.setting_value,
                                        userSettingEnum: getUserSettingEnumObject(r.name),
                                    })
                                }
                            >
                                {t(`common:Edit`)}
                            </InsButton>
                        </Col>
                    </Row>
                )
            },
        },
    ]
    const [listTeam, setListTeam] = useState([])
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false)
    const setInitialPermissionValues = () => {
        let authorities = initialValues.authorities
        authorities.forEach(value => {
            if (value.authority_name in ProjectPermissionEnum) {
                form.setFieldsValue({ project: value.authority_name })
            }
            if (value.authority_name in SalePermissionEnum) {
                form.setFieldsValue({ sale: value.authority_name })
            }
            if (value.authority_name in EstimationPermissionEnum) {
                form.setFieldsValue({ estimation: value.authority_name })
            }
            // if (value.authority_name in TrackingPermissionEnum) {
            //     form.setFieldsValue({ tracking: value.authority_name })
            // }
            if (value.authority_name in InstructionPermissionEnum) {
                form.setFieldsValue({ instruction: value.authority_name })
            }
            if (value.authority_name in InvoicePermissionEnum) {
                form.setFieldsValue({ invoice: value.authority_name })
            }
            if (value.authority_name in PlaceOrderPermissionEnum) {
                form.setFieldsValue({ place_order: value.authority_name })
            }
            if (value.authority_name in ReportPermissionEnum) {
                form.setFieldsValue({ report: value.authority_name })
            }
            if (value.authority_name in RedSlipPermissionEnum) {
                form.setFieldsValue({ red_slip: value.authority_name })
            }
            if (value.authority_name in SummaryInvoicePermissionEnum) {
                form.setFieldsValue({ summary_invoice: value.authority_name })
            }
            if (value.authority_name in ShipmentPermissionEnum) {
                form.setFieldsValue({ shipment: value.authority_name })
            }
            if (value.authority_name in PaymentPermissionEnum) {
                form.setFieldsValue({ payment: value.authority_name })
            }
        })
    }

    initialValues = Object.assign(
        {
            password: null,
            is_enabled: 1,
            user_role: UserRoleEnum.USER.value,
            project: ProjectPermissionEnum.PROJECT_EDITABLE.value,
            sale: SalePermissionEnum.SALE_EDITABLE.value,
            estimation: EstimationPermissionEnum.ESTIMATION_EDITABLE.value,
            // tracking: TrackingPermissionEnum.TRACKING_EDITABLE.value,
            instruction: InstructionPermissionEnum.INSTRUCTION_EDITABLE.value,
            invoice: InvoicePermissionEnum.INVOICE_EDITABLE.value,
            place_order: PlaceOrderPermissionEnum.PLACE_ORDER_EDITABLE.value,
            report: ReportPermissionEnum.REPORT_HIDDEN.value,
            red_slip: RedSlipPermissionEnum.RED_SLIP_EDITABLE.value,
            summary_invoice: SummaryInvoicePermissionEnum.SUMMARY_INVOICE_EDITABLE.value,
            shipment: ShipmentPermissionEnum.SHIPMENT_EDITABLE.value,
            payment: PaymentPermissionEnum.PAYMENT_HIDDEN.value,
        },
        initialValues
    )
    useEffect(() => {
        //get list team
        api.get(APP_URLS.TEAMS).then(data => {
            if (data && data.list) {
                setListTeam(data.list)
            }
        })
        if (isGotoUserSetting) {
            executeScroll()
        }
        if (isEdit) {
            setInitialPermissionValues()
            let userSettings = {}
            if (initialValues.user_setting) {
                userSettings = Object.assign({}, JSON.parse(initialValues.user_setting))[0]
                delete userSettings['deliveries']
            } else {
                userSettings = {
                    projects: [],
                    estimations: [],
                    instructions: [],
                    place_orders: [],
                    sales: [],
                    // deliveries: [],
                    red_slips: [],
                    summary_invoices: [],
                    history_summary_invoices: [],
                }
            }
            setUserSettings(userSettings)
            let dataTable = Object.keys(userSettings).map(keyObject => ({
                name: keyObject,
                setting_text: getEnumManyText(getUserSettingEnumObject(keyObject), userSettings[keyObject]),
                setting_value: userSettings[keyObject],
            }))
            setUserSettingDataTable(dataTable)
        }
    }, [])

    const getNewPassWord = async () => {
        const data = await api.get(APP_URLS.GENERATE_PASSWORD)
        if (data) {
            form.setFieldsValue({ password: data.password })
        }
    }

    const addSettingParams = () => {
        let keyObject = getUserSettingEnumKeyObject(userSettingVisible.userSettingEnum)
        userSettings[keyObject] = userSetting.settingsOrder
        setUserSettings(userSettings)
        let dataTable = Object.keys(userSettings).map(keyObject => ({
            name: keyObject,
            setting_text: getEnumManyText(getUserSettingEnumObject(keyObject), userSettings[keyObject]),
            setting_value: userSettings[keyObject],
        }))
        setUserSettingDataTable(dataTable)
        setUserSettingVisible({ visible: false, userSettings: null, userSettingEnum: null })

        //generate param user_settings
        let userSettingString = '[' + JSON.stringify(userSettings) + ']'
        form.setFieldsValue({ user_setting: userSettingString })
    }

    return (
        <div className={inModal ? 'page-container ins-form-items' : 'page-container with-sidebar'}>
            {!inModal && (
                <>
                    <InsBackLink />
                    {isEdit ? (
                        <h1 className="page-title-big m-24">
                            {isAccountSetting ? t('Edit account information') : t('Edit user information')}
                        </h1>
                    ) : (
                        <h1 className="page-title-big m-24">
                            {t('navigation:{{entity}} create', { entity: t('entity:User') })}
                        </h1>
                    )}
                </>
            )}

            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => onSubmit(form.getFieldsValue(), null, false)}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <div className="user-form">
                <Form
                    className="ins-form"
                    layout="vertical"
                    form={form}
                    onFinish={values => onSubmit(values)}
                    initialValues={initialValues}
                    onFocus={handleScrollOnFocus}
                >
                    {isEdit && userSettingVisible.visible && (
                        <UserSettingModal
                            userSettingEnum={userSettingVisible.userSettingEnum}
                            userSettings={userSettingVisible.userSettings}
                            visible={userSettingVisible.visible}
                            onCancel={() =>
                                setUserSettingVisible({ visible: false, userSettings: null, userSettingEnum: null })
                            }
                            onOk={() => {
                                addSettingParams()
                            }}
                        />
                    )}
                    <PasswordChangeModal
                        userSettingEnum={userSettingVisible.userSettingEnum}
                        userSettings={userSettingVisible.userSettings}
                        visible={changePasswordModalVisible}
                        onCancel={() => setChangePasswordModalVisible(false)}
                        onOk={() => {
                            //
                        }}
                    />
                    <h2 className={`section-title mb-24 ${inModal ? 'mt-4' : ''}`}>{t('common:Basic information')}</h2>
                    {isEdit && (
                        <Row gutter={16} className="row-generate-pw">
                            <Col>
                                {isAdmin ? (
                                    <Form.Item>
                                        <InsButton
                                            className="btn-generate-pw"
                                            icon={<EditIcon size={20} className="color-pantone-2738-c" />}
                                            buttonStyle="default"
                                            onClick={getNewPassWord}
                                        >
                                            {t(`Generate Password`)}
                                        </InsButton>
                                    </Form.Item>
                                ) : (
                                    <InsButton
                                        className="btn-generate-pw"
                                        icon={<EditIcon size={20} className="color-pantone-2738-c" />}
                                        buttonStyle="default"
                                        onClick={() => setChangePasswordModalVisible(true)}
                                    >
                                        {t(`Generate Password`)}
                                    </InsButton>
                                )}
                            </Col>
                        </Row>
                    )}
                    <Row gutter={16}>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item
                                name="pic_code"
                                label={t('column:Person code')}
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter person code'),
                                    },
                                    {
                                        // trigger: 'onBlur',
                                        validator: async (rule, value) => {
                                            if (isEdit && initialValues.pic_code === value) return Promise.resolve()

                                            if (value) {
                                                const data = await api.post(APP_URLS.USERS_CHECK_EXISTS_PICCODE, {
                                                    pic_code: value,
                                                })
                                                if (data && data.is_existed) {
                                                    return Promise.reject(t('validation:Person code existed'))
                                                }
                                            }

                                            return Promise.resolve()
                                        },
                                    },
                                    {
                                        max: 20,
                                        message: t('validation:{{item}} is invalid', { item: t('column:Person code') }),
                                    },
                                ]}
                            >
                                <Input disabled={!isAdmin} autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item
                                name="username"
                                label={t('column:Username (ID)')}
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter user name'),
                                        // check unique
                                    },
                                    {
                                        // trigger: 'onBlur',
                                        validator: async (rule, value) => {
                                            if (isEdit) {
                                                if (initialValues.username === value) return Promise.resolve()
                                            }
                                            if (value) {
                                                const data = await api.post(APP_URLS.USERS_CHECK_EXISTS_USERNAME, {
                                                    username: value,
                                                })
                                                if (data && data.is_existed) {
                                                    return Promise.reject(t('validation:Username existed'))
                                                }
                                            }

                                            return Promise.resolve()
                                        },
                                    },
                                    {
                                        max: 20,
                                        message: t('validation:{{item}} is invalid', { item: t('column:Username') }),
                                    },
                                ]}
                            >
                                <Input disabled={!isAdmin} autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {isAdmin && (
                        <Row gutter={16}>
                            <Col xs={22} sm={18} md={14} lg={10}>
                                <Form.Item
                                    label={t('common:Password')}
                                    name="password"
                                    validateTrigger={['onBlur']}
                                    rules={[{ required: !isEdit, message: t('validation:Please enter password') }]}
                                >
                                    <Input.Password autocomplete="new-password" />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={16}>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item
                                name="pic_name"
                                label={t('column:Pic name')}
                                validateTrigger={['onBlur']}
                                rules={[{ required: true, message: t('validation:Please enter pic name') }]}
                            >
                                <Input disabled={!isAdmin} autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item
                                name="pic_name_kana"
                                label={t('column:Pic name kana')}
                                validateTrigger={['onBlur']}
                                rules={[{ required: true, message: t('validation:Please enter pic name kana') }]}
                            >
                                <Input disabled={!isAdmin} autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item name="user_role" label={t('column:User role')} validateTrigger={['onBlur']}>
                                <InsSelect
                                    disabled={!isAdmin}
                                    className="w-100p"
                                    onChange={value => {
                                        if (value === UserRoleEnum.USER.value) {
                                            form.setFieldsValue({
                                                payment: PaymentPermissionEnum.PAYMENT_HIDDEN.value,
                                                report: ReportPermissionEnum.REPORT_HIDDEN.value,
                                            })
                                        } else {
                                            form.setFieldsValue({
                                                payment: PaymentPermissionEnum.PAYMENT_EDITABLE.value,
                                                report: ReportPermissionEnum.REPORT_EDITABLE.value,
                                            })
                                        }
                                    }}
                                >
                                    {Object.values(UserRoleEnum).map(role => (
                                        <Select.Option key={role.value} value={role.value}>
                                            {t(`enum:${role.text}`)}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item name="team_id" label={t('column:Team')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {listTeam.map(team => (
                                        <Select.Option key={team.id} value={team.id}>
                                            {team.team_name}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={inModal ? 'mb-4' : 'mb-100'}>
                        <Col span={24}>
                            <Form.Item name="is_enabled" label={t('Usage status')}>
                                <Radio.Group disabled={!isAdmin}>
                                    <Radio value={1}>{t(`common:Enabled`)}</Radio>
                                    <Radio value={0}>{t(`common:Disabled`)}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="title-form-setting">
                        <h2 className="section-title mb-24">{t('common:Permission setting')}</h2>
                    </div>
                    <Row gutter={[16]}>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item name="project" label={t('column:Project')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(ProjectPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item name="sale" label={t('column:Sale')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(SalePermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item name="estimation" label={t('column:Estimation')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(EstimationPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={22} sm={18} md={14} lg={10}>
                        <Form.Item name="tracking" label={t('column:Tracking')}>
                            <InsSelect disabled={!isAdmin} className="w-100p">
                                {Object.values(TrackingPermissionEnum).map(m => (
                                    <Select.Option key={m.value} value={m.value}>
                                        {m.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </Col>*/}
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="instruction" label={t('column:Instruction')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(InstructionPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="invoice" label={t('column:Invoice')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(InvoicePermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="place_order" label={t('column:Place order')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(PlaceOrderPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="report" label={t('column:Report')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(ReportPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="red_slip" label={t('column:Red slip')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(RedSlipPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="summary_invoice" label={t('column:Summary invoice')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(SummaryInvoicePermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="shipment" label={t('column:Shipment')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(ShipmentPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={18} md={14} lg={10}>
                            <Form.Item disabled={!isAdmin} name="payment" label={t('column:Payment')}>
                                <InsSelect disabled={!isAdmin} className="w-100p">
                                    {Object.values(PaymentPermissionEnum).map(m => (
                                        <Select.Option key={m.value} value={m.value}>
                                            {m.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    {isEdit && (
                        <Form.Item name="user_setting">
                            <div className={isGotoUserSetting && 'user-setting-transition'}>
                                <h2 className="section-title">{t('common:User display settings')}</h2>
                                <InsDataTable
                                    tableStyle="compact"
                                    // loading={state.loading ? { indicator: <InsLoader /> } : false}
                                    striped
                                    columns={userSettingColumns}
                                    data={userSettingDataTable}
                                />
                            </div>
                        </Form.Item>
                    )}
                    <div style={{ marginTop: inModal ? '8px' : '100px' }}>
                        <InsFloatBar visible={true} className="with-sidebar">
                            <Row gutter={8} justify={inModal ? 'center' : 'end'}>
                                <Col>
                                    <InsButton
                                        buttonStyle="outline"
                                        buttonSize="big"
                                        centered
                                        width="140px"
                                        icon={<CloseIcon />}
                                        onClick={() => {
                                            if (!inModal) {
                                                history.goBack()
                                            } else {
                                                onCancel && onCancel()
                                            }
                                        }}
                                    >
                                        {t('common:Cancel')}
                                    </InsButton>
                                </Col>
                                <Col>
                                    <InsButton
                                        buttonStyle="primary"
                                        buttonSize="big"
                                        centered
                                        width={isEdit ? '140px' : '154px'}
                                        icon={isEdit ? <></> : <AddIcon />}
                                        isSubmitButton={true}
                                    >
                                        {isEdit ? t('common:Save') : t('New user registration')}
                                    </InsButton>
                                </Col>
                            </Row>
                        </InsFloatBar>
                    </div>
                </Form>
            </div>
        </div>
    )
}

const mapState = state => ({
    userSetting: state.userSetting,
})

const mapDispatch = {
    setUser,
}

export default connect(mapState, mapDispatch)(UserForm)

UserForm.propTypes = {
    isEdit: PropTypes.bool,
    isAdmin: PropTypes.bool,
    inModal: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    isAccountSetting: PropTypes.bool,
}
