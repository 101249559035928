import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import RedSlipPreview from 'modules/business/redSlip/RedSlipPreview'
import RedSlipCreate from 'modules/business/redSlip/RedSlipCreate'
import Routes from 'routes'
import RedSlipUpdate from 'modules/business/redSlip/RedSlipUpdate'

const RedSlipTab = ({ project, response }) => {
    const [loading, setLoading] = useState(true)
    const [redSlip, setRedSlip] = useState({})
    const [sale, setSale] = useState({})
    const location = useLocation()

    const getRedSlipByProject = async projectId => {
        setLoading(true)
        const data = await api.get(APP_URLS.RED_SLIP_BY_PROJECT.replace(':projectId', projectId))
        setRedSlip(data)
        // get sale
        const currentSale = await api.get(APP_URLS.SALE_BY_PROJECT.replace(':projectId', projectId))
        setSale(currentSale)
        setLoading(false)
    }

    useEffect(() => {
        getRedSlipByProject(project.id)
    }, [project.red_slip])

    const isExisted = redSlip && redSlip.id
    // current Route
    let { path, url } = useRouteMatch()

    const renderInvoiceRoute = () => {
        return (
            <Switch>
                <Route exact path={`${path}`}>
                    {isExisted ? (
                        <RedSlipPreview
                            project={project}
                            redSlip={redSlip}
                            sale={sale}
                            response={() => {
                                response(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
                            }}
                            updateLink={() =>
                                response(
                                    Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                        APP_URLS.RED_SLIP +
                                        '/update'
                                )
                            }
                        />
                    ) : (
                        <Redirect to={`${url}/create` + location.search} />
                    )}
                </Route>
                <Route exact={false} path={`${path}/create`}>
                    {isExisted ? (
                        <Redirect to={`${url}/preview`} />
                    ) : (
                        <RedSlipCreate
                            project={project}
                            sale={sale}
                            redSlip={redSlip}
                            response={response}
                            updateLink={redirectTo => {
                                if (!redirectTo) {
                                    redirectTo =
                                        Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                        APP_URLS.RED_SLIP
                                }
                                return response(redirectTo)
                            }}
                        />
                    )}
                </Route>
                <Route exact path={`${path}/update`}>
                    {isExisted ? (
                        <RedSlipUpdate
                            project={project}
                            redSlip={redSlip}
                            sale={sale}
                            response={response}
                            updateLink={redirectTo => {
                                if (!redirectTo) {
                                    redirectTo =
                                        Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                        APP_URLS.RED_SLIP
                                }
                                return response(redirectTo)
                            }}
                        />
                    ) : (
                        <Redirect to={`${url}/create` + location.search} />
                    )}
                </Route>
                <Route exact path={`${path}/preview`}>
                    <RedSlipPreview
                        project={project}
                        redSlip={redSlip}
                        sale={sale}
                        response={() => {
                            response(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
                        }}
                        updateLink={() =>
                            response(
                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                    APP_URLS.RED_SLIP +
                                    '/update'
                            )
                        }
                    />
                </Route>
            </Switch>
        )
    }

    return <>{loading ? <InsBlockLoader /> : renderInvoiceRoute()}</>
}

export default RedSlipTab

RedSlipTab.propTypes = {
    project: PropTypes.object.isRequired,
    sale: PropTypes.object,
    redSlip: PropTypes.object,
    response: PropTypes.func,
}
