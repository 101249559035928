import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import OutsourceListComponent from 'modules/master/outsource/OutsourceListComponent'
import OutsourceCreateComponent from 'modules/master/outsource/OutsourceCreateComponent'
import OutsourceDetailComponent from 'modules/master/outsource/OutsourceDetailComponent'
import { getData, setPagination, setFilters, setSorter } from 'modules/master/outsource/outsource_modal.slice'
import { Select } from 'antd'

const OutsourceSelect = ({
    entity,
    state,
    record,
    showListLink = true,
    showCreateButton = true,
    showDetailLink = true,
    ...rest
}) => {
    const { t } = useTranslation()
    if (!entity) {
        entity = t('entity:Outsource')
    }
    let initialOptions = []
    if (record) {
        initialOptions.push({
            id: record.id,
            outsource_code: record.outsource_code,
            outsource_name: record.outsource_name,
        })
    }

    let listModal, createModal, detailModal
    if (showListLink) {
        listModal = {
            title: t('navigation:{{entity}} list', { entity: entity }),
            component: OutsourceListComponent,
            props: {
                state,
                getData,
                setPagination,
                setFilters,
                setSorter,
            },
        }
    }

    if (showCreateButton) {
        createModal = {
            title: t('navigation:{{entity}} create', { entity: entity }),
            component: OutsourceCreateComponent,
        }
    }

    if (showDetailLink) {
        detailModal = {
            linkText: t('navigation:{{entity}} information', { entity: entity }),
            component: OutsourceDetailComponent,
        }
    }

    return (
        <InsRemoteSelect
            {...rest}
            url={`${APP_URLS.OUTSOURCES}?page_size=100&is_enabled=1&keyword=`}
            initialOptions={initialOptions}
            optionIndexKey="id"
            optionValueKey="id"
            optionTextKey="outsource_name"
            optionLabelProp="label"
            optionRenderer={item => (
                <Select.Option key={item.id} value={item.id} item={item} label={item.outsource_name}>
                    <span style={{ fontWeight: 'bold' }}>{item.outsource_code}</span>
                    {'　'}
                    {item.outsource_name}
                </Select.Option>
            )}
            listModal={listModal}
            createModal={createModal}
            detailModal={detailModal}
        />
    )
}

const mapState = state => ({
    state: state.outsourceModal,
})
export default connect(mapState)(OutsourceSelect)

OutsourceSelect.propTypes = {
    entity: PropTypes.string,
    record: PropTypes.shape({
        id: PropTypes.any,
        outsource_code: PropTypes.string,
        outsource_name: PropTypes.string,
    }),
    showListLink: PropTypes.bool,
    showCreateButton: PropTypes.bool,
    showDetailLink: PropTypes.bool,
    state: PropTypes.shape(BaseStatePropShape),
}
