import React, { useRef } from 'react'
import { Col, Row, Select } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { useTranslation } from 'react-i18next'
import InsSelect from 'components/select/InsSelect'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import { PaymentType } from 'enums/payment.enum'
import { setKeywords } from 'modules/business/payment/payment.slice'
import { connect, useDispatch } from 'react-redux'
import InsCollapse from 'components/InsCollapse'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsButton from 'components/InsButton'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import { setDetailSearch, updateDetailSearch } from 'modules/business/payment/payment.slice'

const PaymentListSearch = ({
    state,
    paymentType,
    changePaymentType,
    updateFilters,
    updateDetailSearch,
    setDetailSearch,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const collapseRef = useRef()
    return (
        <InsCollapse
            ref={ref => (collapseRef.current = ref)}
            header={
                <>
                    <InsSelect
                        value={paymentType}
                        onChange={value => {
                            changePaymentType(value)
                        }}
                        style={{ width: '120px', marginRight: '4px' }}
                    >
                        <Select.Option key="回収" value="1">
                            回収
                        </Select.Option>
                        <Select.Option key="支払" value="2">
                            支払
                        </Select.Option>
                    </InsSelect>
                    {paymentType === PaymentType.COLLECT.value && (
                        <InsSelect
                            value={state.filters['delay_status']}
                            className="mr-4"
                            style={{ width: 170 }}
                            onSelect={value => {
                                updateFilters({ current: 1 }, { delay_status: value }, {})
                            }}
                        >
                            <Select.Option value={0}>全てのステータス</Select.Option>
                            <Select.Option value={1}>回収予定</Select.Option>
                            <Select.Option value={2} className="color-pantone-213-c">
                                回収遅滞
                            </Select.Option>
                        </InsSelect>
                    )}
                    {paymentType === PaymentType.TRANSFER.value && (
                        <InsSelect
                            value={state.filters['delay_status']}
                            className="mr-4"
                            style={{ width: 170 }}
                            onSelect={value => {
                                updateFilters({ current: 1 }, { delay_status: value }, {})
                            }}
                        >
                            <Select.Option value={0}>全てのステータス</Select.Option>
                            <Select.Option value={1}>支払予定</Select.Option>
                            <Select.Option value={2} className="color-pantone-213-c">
                                支払遅滞
                            </Select.Option>
                        </InsSelect>
                    )}
                    <InsDateRangePicker
                        className="mr-4"
                        defaultValue={[
                            moment(state.filters['issued_date:gte']),
                            moment(state.filters['issued_date:lte']),
                        ]}
                        value={[moment(state.filters['issued_date:gte']), moment(state.filters['issued_date:lte'])]}
                        onChange={(mm, value) => {
                            updateFilters(
                                { current: 1 },
                                {
                                    'issued_date:gte': value[0],
                                    'issued_date:lte': value[1],
                                },
                                {}
                            )
                        }}
                    />
                    <InsInputSearch
                        style={{ width: '258px' }}
                        placeholder={
                            paymentType === PaymentType.COLLECT.value
                                ? t('請求先名、案件番号、件名など')
                                : t('支払先名、案件番号、件名など')
                        }
                        value={state.keywords}
                        onChange={values => {
                            dispatch(setKeywords(values))
                        }}
                        onSearch={values => updateFilters({ current: 1 }, { keyword: values }, {})}
                    />
                </>
            }
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Collection type')}</strong>
                </Col>
                <Col>
                    <ClassificationSelect
                        className="mr-16"
                        typeEnum={ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE}
                        showCreateButton={false}
                        style={{ width: 150 }}
                        value={state.detailSearch['collection_type']}
                        onChange={value => {
                            updateDetailSearch({ field: 'collection_type', value })
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Collection method')}</strong>
                </Col>
                <Col>
                    <ClassificationSelect
                        className="mr-16"
                        typeEnum={ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD}
                        showCreateButton={false}
                        style={{ width: 150 }}
                        value={state.detailSearch['collection_method']}
                        onChange={value => {
                            updateDetailSearch({ field: 'collection_method', value })
                        }}
                    />
                </Col>
            </Row>
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            updateFilters(
                                { current: 1 },
                                {
                                    collection_type: undefined,
                                    collection_method: undefined,
                                },
                                {}
                            )
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            // onSearch(detailSearch)
                            updateFilters(
                                { current: 1 },
                                {
                                    collection_type: state.detailSearch['collection_type'],
                                    collection_method: state.detailSearch['collection_method'],
                                },
                                {}
                            )
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}
const mapState = state => ({ state: state.payment })
const mapDispatch = { setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(PaymentListSearch)

PaymentListSearch.propTypes = {
    paymentType: PropTypes.string,
    changePaymentType: PropTypes.func,
    updateFilters: PropTypes.func,
    updateDetailSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
}
