import React from 'react'
import PropTypes from 'prop-types'
import InsDropdown from 'components/dropdown/InsDropdown'
import { ArrowDropdownIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'

const InsLinkDropdown = ({ icon, text, ...rest }) => {
    return (
        <InsDropdown {...rest}>
            <InsButton buttonStyle="plain" icon={icon} suffixIcon={<ArrowDropdownIcon size={24} />}>
                {text}
            </InsButton>
        </InsDropdown>
    )
}

InsLinkDropdown.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string.isRequired,
}

export default InsLinkDropdown
