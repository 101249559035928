import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import moment from 'moment'

const name = 'invoiceProjectModal'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props =>
        await baseGetData({
            url: APP_URLS.INVOICE_GET_SALES,
            ...props,
        })
)
const invoiceProjectSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: {
            'delivery_date:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:00'),
            'delivery_date:lte': moment().format('YYYY/MM/DD 23:59:59'),
            'customer_id': null,
            'exclude_project_ids': '',
        },
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setSorter,
    setSelection,
} = invoiceProjectSlice.actions

export default invoiceProjectSlice.reducer
