import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AccessTimeIcon, FolderOpenIcon, OpenInNewIcon, UnfoldLessIcon, UnfoldMoreIcon } from 'components/InsIcon'
import DestinationGroup from 'modules/business/invoice/invoiceProject/DestinationGroup'
import InsButton from 'components/InsButton'
import { dateToStr } from 'helpers/date.helper'
import DetailRow from 'modules/business/invoice/invoiceProject/DetailRow'
import Routes from 'routes'

const ProjectGroup = ({ sale, project, project_paid }) => {
    const { delivery_destinations, details } = sale
    const [collapsed, setCollapsed] = useState(false)

    return (
        <div className="project-group">
            <div className="project-group-header">
                <div className="project-group-info">
                    <FolderOpenIcon size={20} className="mdi-folder-open mr-8" />
                    <span className="spc mr-8">{'案件  :'}</span>
                    <span className="spc mr-16">
                        {sale.project_paid || project_paid ? (
                            `${project.project_code} (この案件)`
                        ) : (
                            <InsButton
                                buttonStyle="plain"
                                linkTo={
                                    Routes.private.business.PROJECT_DETAIL.path.replace(':id', sale.project.id) +
                                    (sale.countForCheckInvoice > 0 ? '/invoices' : '/sales')
                                }
                                buttonType="link"
                                target="_blank"
                            >
                                <span className="project-group-link mr-8">{project.project_code}</span>
                                <OpenInNewIcon size={16} className="mdi_open_in_new" />
                            </InsButton>
                        )}
                    </span>
                    <AccessTimeIcon size={20} className="mdi-access-time mr-8" />
                    <span className="mr-8">{'納品書発行日  :'}</span>
                    <span className="fw-500">{dateToStr(sale.delivery_date, 'YYYY年M月DD日')}</span>
                </div>
                <div onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? (
                        <UnfoldMoreIcon className="mdi_unfold_less" size={24} />
                    ) : (
                        <UnfoldLessIcon className="mdi_unfold_more" size={24} />
                    )}
                </div>
            </div>
            <div className="project-group-body" style={{ display: collapsed ? 'none' : 'block' }}>
                {delivery_destinations && delivery_destinations.length > 0 ? (
                    delivery_destinations.map(destination => (
                        <DestinationGroup destination={destination} key={destination.id} />
                    ))
                ) : (
                    <div className="destination-group-body" style={{ display: collapsed ? 'none' : 'block' }}>
                        {(details || []).map(detail => (
                            <DetailRow detail={detail} key={detail.id} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProjectGroup

ProjectGroup.propTypes = {
    sale: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    project_paid: PropTypes.bool,
}
