import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Checkbox } from 'antd'
import InsModal from 'components/InsModal'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import { dateToStr } from 'helpers/date.helper'
import { formatNumber, formatNumberComma } from 'helpers/number.helper'
import InsInputNumber from 'components/InsInputNumber'
import InsButton from 'components/InsButton'
import ReactToPrint from 'react-to-print'

const InvoicingModal = ({ title, children, visible, onOk, onCancel, invoice, setInvoice, componentRef }) => {
    const [visibleStep2, setVisibleStep2] = useState(false)

    // Deprecation warning
    const dateFormat = 'YYYY/MM/DD'

    const checkboxGroup = [
        {
            text: '金額非表示',
            name: 'amountHidden',
        },
        {
            text: '社印表示',
            name: 'companySealDisplay',
            isChecked: true,
        },
        {
            text: '作業指示内容表示',
            name: 'workInstructionContentDisplay',
        },
    ]

    const [printOptions, setPrintOptions] = useState({
        amountHidden: false,
        companySealDisplay: true,
        workInstructionContentDisplay: false,
    })
    const [issuedDate, setIssuedDate] = useState(moment.utc(moment().format(dateFormat)))
    const [adjustAmount, setAdjustAmount] = useState(0)

    const renderPrintOptions = () => {
        let printOptionsFilter = Object.keys(printOptions).filter(key => printOptions[key])
        return printOptionsFilter.map(
            (key, index) =>
                checkboxGroup.find(item => item.name === key).text +
                (index !== printOptionsFilter.length - 1 ? '、' : '')
        )
    }

    const onOkStep2 = () => {
        onOk({
            display_amount: !printOptions.amountHidden,
            display_company_seal: printOptions.companySealDisplay,
            display_instruction: printOptions.workInstructionContentDisplay,
            issue_date: moment.utc(issuedDate).format('YYYY-MM-DD'),
            adjust_amount: adjustAmount,
        })
        onCancel()
        setVisibleStep2(false)
    }

    return (
        <>
            <InsModal
                className="invoicing-modal"
                title={title}
                width={'90%'}
                visible={visible}
                onCancel={onCancel}
                onOk={() => setVisibleStep2(true)}
            >
                <div className="invoicing-modal-inner">
                    <div className="inner-row">
                        <label>{'印刷オプション'}</label>
                        {checkboxGroup.map((checkbox, index) => (
                            <Checkbox
                                onChange={value =>
                                    setPrintOptions({ ...printOptions, [checkbox.name]: value.target.checked })
                                }
                                defaultChecked={checkbox.isChecked}
                                key={index}
                            >
                                {checkbox.text}
                            </Checkbox>
                        ))}
                    </div>
                    <div className="inner-row">
                        <label>{'請求書発行日'}</label>
                        <InsDatePicker
                            defaultValue={issuedDate}
                            format={dateFormat}
                            style={{ width: 180 }}
                            onChange={mm => setIssuedDate(mm)}
                        />
                    </div>
                    <div className="inner-row">
                        <label>{'調整額'}</label>
                        <InsInputNumber
                            className="no-spinner"
                            defaultValue={adjustAmount}
                            min={0}
                            style={{ width: 180 }}
                            onChange={value => setAdjustAmount(value)}
                        />
                        <span className="ext">{'円'}</span>
                    </div>
                </div>
                <div className="total-amount">
                    <label>{'金額合計'}</label>
                    <span>{formatNumberComma(invoice.total)}</span>
                </div>
            </InsModal>
            {children}
            <InsModal
                className="invoicing-modal confirm-step-2"
                title="請求書発行"
                width={'90%'}
                visible={visibleStep2}
                // okButtonStyle="secondary"
                // okText="請求書発行"
                // onOk={() => {
                //     onOk({
                //         display_amount: !printOptions.amountHidden,
                //         display_company_seal: printOptions.companySealDisplay,
                //         display_instruction: printOptions.workInstructionContentDisplay,
                //         issue_date: moment.utc(issuedDate).format('YYYY-MM-DD'),
                //         adjust_amount: adjustAmount,
                //     })
                //     onCancel()
                //     setVisibleStep2(false)
                // }}
                onCancel={() => {
                    setVisibleStep2(false)
                }}
                footer={[
                    <InsButton centered key="cancel" onClick={() => setVisibleStep2(false)} buttonStyle="outline">
                        {'キャンセル'}
                    </InsButton>,
                    <ReactToPrint
                        key="print"
                        trigger={() => (
                            <InsButton centered key="ok" buttonStyle="secondary">
                                {'請求書発行'}
                            </InsButton>
                        )}
                        onBeforePrint={() => onOkStep2()}
                        content={() => componentRef.current}
                        // onBeforeGetContent={() => setInvoice({ ...invoice, adjust_amount: adjustAmount })}
                        onBeforeGetContent={() => {
                            return new Promise(resolve => {
                                setInvoice({
                                    ...invoice,
                                    adjust_amount: adjustAmount,
                                    issue_date: issuedDate,
                                    print_options: JSON.stringify({
                                        display_amount: !printOptions.amountHidden,
                                        display_company_seal: printOptions.companySealDisplay,
                                        display_instruction: printOptions.workInstructionContentDisplay,
                                    }),
                                })
                                setTimeout(function () {
                                    resolve()
                                }, 500)
                            })
                        }}
                    />,
                ]}
            >
                <div className="invoicing-modal-inner">
                    {Object.keys(printOptions).some(key => printOptions[key]) && (
                        <div className="inner-row">
                            <label>{'印刷オプション：'}</label>
                            <span>{renderPrintOptions()}</span>
                        </div>
                    )}
                    <div className="inner-row">
                        <label>{'発行日：'}</label>
                        <span>{dateToStr(issuedDate, dateFormat)}</span>
                    </div>
                    <div className="inner-row">
                        <label>{'調整額：'}</label>
                        <span>{`${formatNumber(adjustAmount)}円`}</span>
                    </div>
                </div>
                <div className="step-2-info">
                    <span>{'上記の内容でまとめて請求書を発行します。よろしいですか？'}</span>
                </div>
            </InsModal>
        </>
    )
}

export default InvoicingModal

InvoicingModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    onOk: PropTypes.bool,
    onCancel: PropTypes.func,
    invoice: PropTypes.object,
    setInvoice: PropTypes.func,
    componentRef: PropTypes.any,
}
