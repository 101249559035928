import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'
import PlaceOrderCreate from 'modules/business/placeOrder/PlaceOrderCreate'
import PlaceOrderPreview from 'modules/business/placeOrder/PlaceOrderPreview'
import PlaceOrderUpdate from 'modules/business/placeOrder/PlaceOrderUpdate'

const PlaceOrderTab = ({ project, response, createDocumentFrom }) => {
    const placeOrder = project.place_order
    const isExisted = placeOrder && placeOrder.id
    // current Route
    let { path, url } = useRouteMatch()
    const location = useLocation()

    const renderPlaceOrderRoute = () => {
        return (
            <Switch>
                <Route exact path={`${path}`}>
                    {isExisted ? (
                        <PlaceOrderPreview
                            project={project}
                            placeOrder={placeOrder}
                            response={() =>
                                response(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
                            }
                            updateLink={() =>
                                response(
                                    Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                        APP_URLS.PLACE_ORDER +
                                        '/update'
                                )
                            }
                        />
                    ) : (
                        <Redirect to={`${url}/create` + location.search} />
                    )}
                </Route>
                <Route exact={false} path={`${path}/create`}>
                    {isExisted ? (
                        <Redirect to={`${url}/preview`} />
                    ) : (
                        <PlaceOrderCreate
                            project={project}
                            createDocumentFrom={createDocumentFrom}
                            updateLink={redirectTo => {
                                if (!redirectTo) {
                                    redirectTo =
                                        Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                        APP_URLS.PLACE_ORDER
                                }
                                return response(redirectTo)
                            }}
                        />
                    )}
                </Route>
                <Route exact path={`${path}/update`}>
                    {isExisted ? (
                        <PlaceOrderUpdate
                            project={project}
                            placeOrder={placeOrder}
                            updateLink={redirectTo => {
                                if (!redirectTo) {
                                    redirectTo =
                                        Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                        APP_URLS.PLACE_ORDER
                                }
                                return response(redirectTo)
                            }}
                        />
                    ) : (
                        <Redirect to={`${url}/create` + location.search} />
                    )}
                </Route>
                <Route exact path={`${path}/preview`}>
                    <PlaceOrderPreview
                        project={project}
                        placeOrder={placeOrder}
                        response={() =>
                            response(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
                        }
                        updateLink={() =>
                            response(
                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                    APP_URLS.PLACE_ORDER +
                                    '/update'
                            )
                        }
                    />
                </Route>
            </Switch>
        )
    }

    return renderPlaceOrderRoute()
}

export default PlaceOrderTab

PlaceOrderTab.propTypes = {
    project: PropTypes.object.isRequired,
    createDocumentFrom: PropTypes.object,
    response: PropTypes.func,
}
