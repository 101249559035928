import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const NoPermission = () => {
    const { t } = useTranslation()

    useEffect(() => {}, [])

    return (
        <div id="ins-flex flex-center m-40">
            <h3>
                一度ログアウトし、再ログインをお試しください。再度エラーが発生する場合は，システム管理者に連絡してください。
            </h3>
        </div>
    )
}

export default NoPermission

NoPermission.propTypes = {}
