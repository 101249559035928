import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'

const name = 'stock'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.STOCKS, ...props })
)

const stockSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, { filters: {} }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setSorter,
    setSelection,
} = stockSlice.actions

export default stockSlice.reducer
