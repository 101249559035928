import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'
import UsageStatusEnum from 'enums/usage_status.enum'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import api from 'helpers/api.helper'
import { getEnumText } from 'helpers/enum.helper'
import { getClassificationItemName } from 'helpers/classification.helper'
import { InsBlockLoader } from 'components/InsLoader'
import InsFloatBar from 'components/InsFloatBar'
import InsButton from 'components/InsButton'
import { BackIcon, DeleteIcon, EditIcon, LaunchIcon } from 'components/InsIcon'
import InsHelpText from 'components/InsHelpText'
import { Link } from 'react-router-dom'
import InsModal from 'components/InsModal'

const OutsourceDetailComponent = ({ inModal = false, id, onDeleteOutsource }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [outsource, setOutsource] = useState(null)
    const [deleteOutsource, setDeleteOutsource] = useState({
        visible: false,
    })

    useEffect(() => {
        const getOutsource = async id => {
            const data = await api.get(APP_URLS.OUTSOURCES_DETAIL.replace(':id', id))
            if (data) {
                setOutsource(data)
                setLoading(false)
            }
        }

        getOutsource(id)
    }, [id])

    return (
        <>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <React.Fragment>
                    {!inModal && (
                        <InsFloatBar visible={true}>
                            <Row justify="space-between" align="middle">
                                <Col>
                                    <InsModal
                                        title={t('common:Delete')}
                                        visible={deleteOutsource.visible}
                                        onCancel={() =>
                                            setDeleteOutsource({
                                                visible: false,
                                            })
                                        }
                                        onOk={onDeleteOutsource}
                                        okText="はい"
                                        cancelText="いいえ"
                                    >
                                        <h2>{t('common:Are you sure')}</h2>
                                    </InsModal>

                                    <InsButton
                                        width="76px"
                                        disabled={!outsource.can_delete}
                                        icon={<DeleteIcon size={16} />}
                                        onClick={() =>
                                            setDeleteOutsource({
                                                visible: true,
                                            })
                                        }
                                    >
                                        {t('common:Delete')}
                                    </InsButton>
                                    <InsHelpText className="ml-8">
                                        {t('Can not delete in-used {{item}}', { item: t('entity:Outsource') })}
                                    </InsHelpText>
                                </Col>

                                <Col className="ins-flex flex-middle">
                                    <InsButton
                                        className="fw-500 mr-8"
                                        buttonType="link"
                                        linkTo={Routes.private.master.OUTSOURCE_LIST.path}
                                        buttonStyle="outline"
                                        buttonSize="big"
                                        width="110px"
                                        icon={<BackIcon />}
                                    >
                                        {t('common:Back to list')}
                                    </InsButton>
                                    <InsButton
                                        className="fw-bold"
                                        buttonType="link"
                                        linkTo={Routes.private.master.OUTSOURCE_UPDATE.path.replace(':id', id)}
                                        buttonStyle="primary"
                                        buttonSize="big"
                                        icon={<EditIcon size={20} />}
                                    >
                                        {t('navigation:{{entity}} information edit', { entity: t('entity:Outsource') })}
                                    </InsButton>
                                </Col>
                            </Row>
                        </InsFloatBar>
                    )}
                    <div className={`${inModal ? '' : 'p-24'}`}>
                        {inModal ? (
                            <>
                                <h1>{t('外注先情報')}</h1> <hr className="title-modal" />
                            </>
                        ) : null}
                        <h1 className="page-title-big mb-24 ins-flex flex-middle">
                            {outsource.outsource_name}
                            {!inModal && (
                                <InsButton
                                    buttonType="link"
                                    buttonStyle="plain"
                                    className="ml-8 color-blue-gray-200"
                                    icon={<EditIcon size={24} />}
                                    linkTo={Routes.private.master.OUTSOURCE_UPDATE.path.replace(':id', id)}
                                />
                            )}
                        </h1>
                        <section>
                            <table className="ins-info-table">
                                <tbody>
                                    <tr>
                                        <th>{t('column:Outsource code')}</th>
                                        <td className="fw-bold color-blue-gray-900 fw-bold">
                                            {outsource.outsource_code}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Outsource name')}</th>
                                        <td className="fw-bold color-blue-gray-900 fw-bold">
                                            {outsource.outsource_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Outsource name kana')}</th>
                                        <td>{outsource.outsource_name_kana}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Outsource short name')}</th>
                                        <td>{outsource.outsource_short_name}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Post code')}</th>
                                        <td>{outsource.post_code}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Address')}</th>
                                        <td>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.PREFECTURE.value,
                                                outsource.prefecture_code
                                            )}
                                            &nbsp;
                                            {outsource.address1}&nbsp;
                                            {outsource.address2}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Phone number')}</th>
                                        <td>{outsource.phone_number}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Fax number')}</th>
                                        <td>{outsource.fax_number}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Pic name')}</th>
                                        <td>{outsource.pic ? outsource.pic.pic_name : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Usage condition')}</th>
                                        <td>{t(`enum:${getEnumText(UsageStatusEnum, outsource.is_enabled)}`)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>

                    {inModal && (
                        <Row justify="end">
                            <Link
                                to={Routes.private.master.OUTSOURCE_DETAIL.path.replace(':id', outsource.id)}
                                target="_blank"
                            >
                                <InsButton
                                    icon={<LaunchIcon size={12} className="color-pantone-2738-c" />}
                                    buttonStyle="default"
                                >
                                    {t('Open new tab')}
                                </InsButton>
                            </Link>
                        </Row>
                    )}
                </React.Fragment>
            )}
        </>
    )
}

export default OutsourceDetailComponent

OutsourceDetailComponent.propTypes = {
    id: PropTypes.any.isRequired,
    inModal: PropTypes.bool,
    onDeleteOutsource: PropTypes.func,
}
