import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsModal from 'components/InsModal'
import { Button, Col, Form, Input, Row } from 'antd'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'

const PasswordChangeModal = ({ state, onCancel, onSelect, visible }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const updatePassword = async values => {
        const data = await api.post(APP_URLS.CHANGE_PASSWORD, values)
        if (data) {
            history.goBack()
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
    }, [])
    return (
        <InsModal
            destroyOnClose={true}
            className="ins-list-modal"
            // title={listModal.title}
            // width={listModal.width ? listModal.width : 760}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form className="ins-form" layout="vertical" form={form} onFinish={values => updatePassword(values)}>
                <h2 className="section-title mb-24">{t('パスワード変更')}</h2>
                <Row gutter={[8, 16]} align="middle">
                    <Form.Item
                        label={'旧パスワード'}
                        name="old_password"
                        validateTrigger={['onBlur']}
                        rules={[{ required: true, message: t('validation:Please enter password') }]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Form.Item
                        label={'新パスワード'}
                        name="password"
                        validateTrigger={['onBlur']}
                        rules={[{ required: true, message: t('validation:Please enter password') }]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Form.Item
                        label={t('common:Confirmation password')}
                        name="password_confirmation"
                        validateTrigger={['onBlur']}
                        rules={[{ required: true, message: t('validation:Please enter password') }]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item>
                        <Button className="w-100p mt-8" type="primary" htmlType="submit">
                            {t('変更')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </InsModal>
    )
}

const mapState = state => ({ state: state.teamUser })
export default PasswordChangeModal

PasswordChangeModal.propTypes = {
    state: BaseStatePropShape,
    onCancel: PropTypes.func,
    onSelect: PropTypes.func,
    visible: PropTypes.bool,
}
