import React, { useState } from 'react'
import InsInputNumber from 'components/InsInputNumber'
import { EditIcon } from 'components/InsIcon'

const InsInputWithEditBehavior = ({ ...rest }) => {
    const [editable, setEditable] = useState(false)

    return editable ? (
        <InsInputNumber {...rest} min={0} />
    ) : (
        <>
            <InsInputNumber {...rest} className="border-transparent input-with-edit-behavior" disabled min={0} />
            <EditIcon size={16} className="input-edit-behavior" onClick={() => setEditable(true)} />
        </>
    )
}

export default InsInputWithEditBehavior
