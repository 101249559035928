import PropTypes from 'prop-types'
import React from 'react'
import { Tabs } from 'antd'

const InsTab = ({ className, metaComponent, children, ...rest }) => {
    let wrapperClassName = 'ins-tab-wrapper '
    if (className) {
        wrapperClassName += className
    }
    return (
        <div className={wrapperClassName}>
            <Tabs {...rest}>{children}</Tabs>
            {metaComponent && <div className="ins-tab-meta">{metaComponent}</div>}
        </div>
    )
}

export default InsTab

InsTab.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    metaComponent: PropTypes.any,
}
