import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Checkbox, Col, Form, Row, Select, Tabs } from 'antd'
import InsButton from 'components/InsButton'
import { DeleteIcon, EditIcon } from 'components/InsIcon'
import Routes from 'routes'
import InsFloatBar from 'components/InsFloatBar'
import { useParams } from 'react-router-dom'
import InsInput from 'components/InsInput'
import { APP_URLS } from 'constants/url.constant'
import InsTextArea from 'components/InsTextArea'
import api from 'helpers/api.helper'
import { InsBlockLoader } from 'components/InsLoader'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { getClassificationsByType } from 'helpers/classification.helper'
import CustomerSelect from 'modules/master/customer/CustomerSelect'
import UserSelect from 'modules/master/user/UserSelect'
import InsSelect from 'components/select/InsSelect'
import { useDispatch, useStore } from 'react-redux'
import BusinessStatusEnum from 'enums/business_status.enum'
import history from 'helpers/history.helper'
import InsTab from 'components/InsTab'
import PlaceOrderForm from 'modules/business/placeOrder/form/PlaceOrderForm'
import EstimationForm from 'modules/business/estimation/form/EstimationForm'
import InstructionForm from 'modules/business/instruction/form/InstructionForm'
import SaleForm from 'modules/business/sale/form/SaleForm'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import RouterPrompt from 'modules/common/ui/RouterPrompt'
import InsModal from 'components/InsModal'
import { setDefaultState } from 'modules/business/placeOrder/place_order_detail.slice'

const ProjectForm = ({
    isEdit,
    initialValues = {},
    onSubmit,
    estimationId,
    instructionInternalId,
    instructionOutsourceId,
    placeOrderId,
    saleId,
    isPurchaseOrder,
}) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [code, setCode] = useState('')
    const status = getClassificationsByType(ClassificationTypeEnum.BUSINESS_STATUS.value)
    const [isDeleted, setIsDeleted] = useState(initialValues.status === BusinessStatusEnum.GARBAGE_CAN.value)
    const [modalDeleteProject, setModalDeleteProject] = useState(false)
    const [placeOrder, setPlaceOrder] = useState(null)
    const [estimation, setEstimation] = useState(null)
    const [instructionInternal, setInstructionInternal] = useState(null)
    const [instructionOutsource, setInstructionOutsource] = useState(null)
    const [sale, setSale] = useState(null)
    const [valueChange, setValueChange] = useState(false)
    const store = useStore()

    const dispatch = useDispatch()

    const getCode = async () => {
        const data = await api.get(APP_URLS.PROJECT_RANDOM_CODE)
        if (data) {
            return data
        }
        return null
    }

    const deleteProject = async () => {
        const data = await api.post(APP_URLS.PROJECT_UPDATE_STATUS, {
            status: BusinessStatusEnum.GARBAGE_CAN.value,
            ids: [initialValues.id],
        })
        if (data?.isSuccess) {
            setIsDeleted(true)
            setModalDeleteProject(false)
            form.setFieldsValue({ status: BusinessStatusEnum.GARBAGE_CAN.value })
            history.push(Routes.private.business.PROJECT.path)
        }
    }

    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            if (!isEdit) {
                const projectCode = await getCode()
                if (projectCode) {
                    setCode(projectCode)
                    if (placeOrderId) {
                        const placeOrder = await api.get(APP_URLS.PLACE_ORDER_DETAIL.replace(':id', placeOrderId))
                        placeOrder['place_order_note'] = placeOrder['note']
                        placeOrder['place_order_pic'] = placeOrder['pic']
                        placeOrder['place_order_pic_id'] = placeOrder['pic_id']
                        placeOrder['place_order_code'] = projectCode.code + '-OD-' + 1
                        delete placeOrder['note']
                        delete placeOrder['pic']
                        delete placeOrder['pic_id']
                        delete placeOrder['customer_id']
                        setPlaceOrder(placeOrder)
                    } else if (estimationId) {
                        const estimation = await api.get(APP_URLS.ESTIMATIONS_DETAIL.replace(':id', estimationId))
                        estimation['estimation_note'] = estimation['note']
                        estimation['estimation_customer'] = estimation['customer']
                        estimation['estimation_customer_id'] = estimation['customer_id']
                        estimation['estimation_pic'] = estimation['pic']
                        estimation['estimation_pic_id'] = estimation['pic_id']
                        estimation['estimation_code'] = projectCode.code + '-MK-' + 1
                        delete estimation['note']
                        delete estimation['customer']
                        delete estimation['pic']
                        delete estimation['customer_id']
                        delete estimation['pic_id']
                        setEstimation(estimation)
                    } else if (instructionInternalId || instructionOutsourceId) {
                        let instruction
                        let instructionCode
                        if (instructionInternalId) {
                            instruction = await api.get(
                                APP_URLS.INSTRUCTION_DETAIL.replace(':id', instructionInternalId)
                            )
                            instructionCode = projectCode.code + '-SN-' + 1
                        } else {
                            instruction = await api.get(
                                APP_URLS.INSTRUCTION_DETAIL.replace(':id', instructionOutsourceId)
                            )
                            instructionCode = projectCode.code + '-GC-' + 1
                        }
                        instruction['instruction_note'] = instruction['note']
                        instruction['instruction_customer'] = instruction['customer']
                        instruction['instruction_customer_id'] = instruction['customer_id']
                        instruction['instruction_pic'] = instruction['pic']
                        instruction['instruction_pic_id'] = instruction['pic_id']
                        instruction['instruction_code'] = instructionCode
                        delete instruction['note']
                        delete instruction['customer']
                        delete instruction['pic']
                        delete instruction['customer_id']
                        delete instruction['pic_id']
                        if (instructionInternalId) {
                            setInstructionInternal(instruction)
                        } else {
                            setInstructionOutsource(instruction)
                        }
                    } else if (isPurchaseOrder) {
                        setPlaceOrder({ place_order_classification: 3 })
                    } else if (saleId) {
                        const sale = await api.get(APP_URLS.SALES_DETAIL.replace(':id', saleId))
                        sale['sale_note'] = sale['note']
                        sale['sale_pic'] = sale['pic']
                        sale['sale_customer'] = sale['customer']
                        sale['sale_customer_id'] = sale['customer_id']
                        sale['sale_pic_id'] = sale['pic_id']
                        sale['sale_code'] = projectCode.code + '-SD-' + 1
                        delete sale['note']
                        delete sale['pic']
                        delete sale['pic_id']
                        delete sale['customer_id']
                        setSale(sale)
                    }
                }
            }
        }
        fetchData().then(r => setLoading(false))
    }, [])

    initialValues = Object.assign(
        {
            project_name: '',
            pic_id: store.getState().auth.user.id,
            pic: store.getState().auth.user,
            auth: store.getState().auth.user,
            is_purchase_order: isPurchaseOrder,
        },
        initialValues
    )
    initialValues.status = initialValues.status ? initialValues.status : parseInt(status[0].value)
    const [isPurchaseOrderLocal, setIsPurchaseOrderLocal] = useState(isPurchaseOrder)
    const hasDocument = estimationId || placeOrderId || instructionInternalId || instructionOutsourceId || saleId

    const cantDelete = initialValues.status >= BusinessStatusEnum.SALE_SHIPPED.value

    const [selectedPic, setSelectedPic] = useState(initialValues.pic)

    return can('PROJECT_EDITABLE') && !initialValues?.is_locked ? (
        <div className="form-project">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <React.Fragment>
                    {!hasDocument && (
                        <RouterPrompt
                            handleSave={path => {
                                let values = form.getFieldsValue()
                                values.project_code = initialValues.project_code
                                    ? initialValues.project_code
                                    : code.code
                                onSubmit(values)
                                return true
                            }}
                            isValueChange={isEdit && valueChange}
                            setDefaultState={() => {}}
                        />
                    )}
                    <Form
                        className="ins-form pt-0 pl-0"
                        form={form}
                        onFinish={values => {
                            values.project_code = initialValues.project_code ? initialValues.project_code : code.code
                            setValueChange(false)
                            onSubmit(values)
                        }}
                        initialValues={initialValues}
                        onValuesChange={() => {
                            if (!valueChange) {
                                setValueChange(true)
                            }
                        }}
                    >
                        <Row>
                            <Col>
                                <h1 className="page-title-big mt-24 mb-16">
                                    {t('Project details')}
                                    {initialValues.project_code ? initialValues.project_code : code.code}
                                </h1>
                            </Col>
                        </Row>
                        <Row className="mb-16">
                            <Col span={12}>
                                <Form.Item name="status" label={t('column:Status')}>
                                    <InsSelect
                                        onSelect={value => {}}
                                        style={{ width: '220px' }}
                                        disabled
                                        suffixIcon={<></>}
                                    >
                                        {status.map(s => (
                                            <Select.Option key={s.value} value={parseInt(s.value)}>
                                                {s.text}
                                            </Select.Option>
                                        ))}
                                    </InsSelect>
                                </Form.Item>
                                <Form.Item name="project_name" label={t('column:Project name')}>
                                    <InsInput
                                        placeholder={t('Placeholder project name')}
                                        showMaxLength
                                        maxLength={40}
                                        style={{ width: '320px' }}
                                    />
                                </Form.Item>
                                <Form.Item label={t('column:Customer name')} required className="project-place-order">
                                    {isPurchaseOrderLocal ? (
                                        <Form.Item noStyle>
                                            <InsInput disabled value="顧客なし" style={{ width: 230 }} />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            name="customer_id"
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter {{item}}', {
                                                        item: t('column:Customer name'),
                                                    }),
                                                },
                                            ]}
                                        >
                                            <CustomerSelect
                                                record={initialValues.customer}
                                                placeholder={t('Search for customer')}
                                                style={{ width: 230 }}
                                                onChange={(value, option) => {
                                                    let curPic = (option.item || option)?.pic ?? initialValues.auth
                                                    let curPicId = option.pic_id

                                                    form.setFieldsValue({
                                                        pic_id: curPicId ?? curPic?.id,
                                                        pic: curPic,
                                                    })
                                                    setSelectedPic(curPic)
                                                    // setCustomer(option)
                                                }}
                                            />
                                        </Form.Item>
                                    )}

                                    <Form.Item name="is_purchase_order" noStyle valuePropName="checked">
                                        <Checkbox
                                            className="checkbox-purchase-order"
                                            disabled={isEdit || isPurchaseOrder || hasDocument}
                                            onChange={evt => {
                                                if (evt.target.checked) {
                                                    setPlaceOrder({
                                                        place_order_classification: 3,
                                                    })
                                                } else {
                                                    form.setFieldsValue({ customer_id: undefined })
                                                    form.setFieldsValue({ supplier_id: undefined })
                                                    dispatch(setDefaultState())
                                                }
                                                setIsPurchaseOrderLocal(evt.target.checked)
                                            }}
                                        >
                                            {t('Purchase order')}
                                        </Checkbox>
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="pic_id" label={t('column:Pic name')}>
                                    <UserSelect
                                        record={selectedPic}
                                        placeholder={t('Search for pic')}
                                        style={{ width: 280 }}
                                        showListLink={true}
                                    />
                                </Form.Item>
                                <Form.Item name="note" label={t('column:Item memo')}>
                                    <InsTextArea
                                        style={{ width: '340px' }}
                                        allowClear={false}
                                        placeholder={t('Placeholder note')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {(!!hasDocument || !!isPurchaseOrderLocal) && !isEdit && (
                            <React.Fragment>
                                <div className={'add-cld-project p-8 mb-20 project-detail-tab'}>
                                    <InsTab type="card">
                                        {estimationId && (
                                            <Tabs.TabPane
                                                tab={
                                                    <span className={'ins-flex'}>
                                                        見積書
                                                        <EditIcon size={16} className={'edit-icon ml-12'} />
                                                    </span>
                                                }
                                                key={'estimations'}
                                            />
                                        )}
                                        {(placeOrderId || isPurchaseOrderLocal) && (
                                            <Tabs.TabPane
                                                tab={
                                                    <span className={'ins-flex'}>
                                                        発注書
                                                        <EditIcon size={16} className={'edit-icon ml-12'} />
                                                    </span>
                                                }
                                                key={'place-orders'}
                                            />
                                        )}
                                        {instructionInternalId && (
                                            <Tabs.TabPane
                                                tab={
                                                    <span className={'ins-flex'}>
                                                        作業指示書(社内)
                                                        <EditIcon size={16} className={'edit-icon ml-12'} />
                                                    </span>
                                                }
                                                key={'instruction-internal'}
                                            />
                                        )}
                                        {instructionOutsourceId && (
                                            <Tabs.TabPane
                                                tab={
                                                    <span className={'ins-flex'}>
                                                        作業指示書(外注)
                                                        <EditIcon size={16} className={'edit-icon ml-12'} />
                                                    </span>
                                                }
                                                key={'instruction-outsource'}
                                            />
                                        )}
                                        {saleId && (
                                            <Tabs.TabPane
                                                tab={
                                                    <span className={'ins-flex'}>
                                                        納品書
                                                        <EditIcon size={16} className={'edit-icon ml-12'} />
                                                    </span>
                                                }
                                                key={'sale'}
                                            />
                                        )}
                                    </InsTab>
                                    {estimation && (
                                        <div className="ins-tab-content">
                                            <div className="p-24">
                                                <EstimationForm
                                                    projectForm={form}
                                                    project={{
                                                        ...initialValues,
                                                        ...{
                                                            project_code: initialValues.project_code
                                                                ? initialValues.project_code
                                                                : code.code,
                                                        },
                                                    }}
                                                    onSubmit={values => onSubmit(values)}
                                                    isEdit={true}
                                                    initialValues={estimation}
                                                    projectCreateDoc={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {(placeOrder || isPurchaseOrderLocal) && (
                                        <div className="ins-tab-content">
                                            <div className="p-24">
                                                <PlaceOrderForm
                                                    isEdit={!isPurchaseOrderLocal}
                                                    projectForm={form}
                                                    project={{
                                                        ...initialValues,
                                                        ...{
                                                            project_code: initialValues.project_code
                                                                ? initialValues.project_code
                                                                : code.code,
                                                            is_purchase_order: isPurchaseOrderLocal,
                                                        },
                                                    }}
                                                    initialValues={{
                                                        ...placeOrder,
                                                        ...{
                                                            place_order_pic: initialValues.pic,
                                                            place_order_pic_id: initialValues.pic_id,
                                                        },
                                                    }}
                                                    onSubmit={values => onSubmit(values)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {instructionInternal && (
                                        <div className="ins-tab-content">
                                            <div className="p-24">
                                                <InstructionForm
                                                    isEdit={true}
                                                    projectForm={form}
                                                    project={{
                                                        ...initialValues,
                                                        ...{
                                                            project_code: initialValues.project_code
                                                                ? initialValues.project_code
                                                                : code.code,
                                                        },
                                                    }}
                                                    instruction={instructionInternal}
                                                    instructionType={1}
                                                    initialValues={instructionInternal}
                                                    onSubmit={values => onSubmit(values)}
                                                    projectCreateDoc={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {instructionOutsource && (
                                        <div className="ins-tab-content">
                                            <div className="p-24">
                                                <InstructionForm
                                                    isEdit={true}
                                                    projectForm={form}
                                                    project={{
                                                        ...initialValues,
                                                        ...{
                                                            project_code: initialValues.project_code
                                                                ? initialValues.project_code
                                                                : code.code,
                                                        },
                                                    }}
                                                    instruction={instructionOutsource}
                                                    instructionType={2}
                                                    initialValues={instructionOutsource}
                                                    onSubmit={values => onSubmit(values)}
                                                    projectCreateDoc={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {sale && (
                                        <div className="ins-tab-content">
                                            <div className="p-24">
                                                <SaleForm
                                                    isEdit={true}
                                                    projectForm={form}
                                                    project={{
                                                        ...initialValues,
                                                        ...{
                                                            project_code: initialValues.project_code
                                                                ? initialValues.project_code
                                                                : code.code,
                                                        },
                                                    }}
                                                    initialValues={sale}
                                                    onSubmit={values => onSubmit(values)}
                                                    projectCreateDoc={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                        {!hasDocument && (
                            <InsFloatBar visible={true} className="with-sidebar">
                                <InsModal
                                    title="削除を確認"
                                    visible={modalDeleteProject}
                                    onCancel={() => setModalDeleteProject(false)}
                                    onOk={deleteProject}
                                >
                                    <p>{'このアイテムを削除しますか？'}</p>
                                </InsModal>
                                <Row gutter={8} justify="space-between" align="middle">
                                    <Col>
                                        {isEdit && (
                                            <InsButton
                                                disabled={isDeleted || cantDelete}
                                                icon={
                                                    <DeleteIcon
                                                        size={16}
                                                        className={
                                                            !isDeleted && !cantDelete ? 'color-pantone-213-c' : ''
                                                        }
                                                    />
                                                }
                                                buttonStyle="default"
                                                style={{ width: 94, marginLeft: 10 }}
                                                onClick={() => setModalDeleteProject(true)}
                                            >
                                                {t('Trash')}
                                            </InsButton>
                                        )}
                                    </Col>
                                    <Col className="ins-flex flex-middle btn-update-project-bottom">
                                        <InsButton
                                            buttonStyle="outline"
                                            buttonSize="big"
                                            centered
                                            width="120px"
                                            onClick={() => {
                                                history.push(
                                                    isEdit
                                                        ? Routes.private.business.PROJECT_DETAIL.path.replace(':id', id)
                                                        : Routes.private.business.PROJECT.path
                                                )
                                            }}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {t('common:Cancel')}
                                        </InsButton>
                                        <InsButton
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            centered
                                            width={140}
                                            onClick={() => {
                                                setValueChange(false)
                                            }}
                                            isSubmitButton={true}
                                        >
                                            {isEdit ? t('Project update new') : t('Project create new')}
                                        </InsButton>
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        )}
                    </Form>
                </React.Fragment>
            )}
        </div>
    ) : (
        <NoPermission />
    )
}

export default ProjectForm

ProjectForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    estimationId: PropTypes.number,
    instructionInternalId: PropTypes.number,
    instructionOutsourceId: PropTypes.number,
    placeOrderId: PropTypes.number,
    saleId: PropTypes.number,
    isPurchaseOrder: PropTypes.bool,
    isDocument: PropTypes.bool,
}
