import React from 'react'
import PropTypes from 'prop-types'
import ProjectGroup from 'modules/business/redSlip/redSlipProject/ProjectGroup'

const RedSlipProjectComponent = React.memo(({ sale }) => {
    return (
        <div className="ins-drag-drop">
            <div className="ins-drag-drop-header">
                <div style={{ flex: '0 0 20px' }} />
                <div style={{ flex: 184 }}>タイトル</div>
                <div style={{ flex: 182 }}>仕様</div>
                <div style={{ flex: 134 }}>サイズ</div>
                <div style={{ flex: '0 0 55px' }}>数量</div>
                <div style={{ flex: '0 0 63px' }}>単位</div>
                <div style={{ flex: '0 0 81px' }}>原価</div>
                <div style={{ flex: '0 0 61px' }}>単価</div>
                <div style={{ flex: '0 0 77px' }}>税区分</div>
                <div style={{ flex: '0 0 65px' }}>金額</div>
                <div style={{ flex: '0 0 24px' }} />
            </div>
            <div className="ins-draggable-body">
                <ProjectGroup sale={sale} project={sale.project} />
            </div>
        </div>
    )
})

const RedSlipProject = ({ sale }) => {
    return <RedSlipProjectComponent sale={sale} />
}

export default RedSlipProject

RedSlipProject.propTypes = {
    sale: PropTypes.object.isRequired,
}

RedSlipProjectComponent.propTypes = {
    sale: PropTypes.object.isRequired,
}
