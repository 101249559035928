import React, { useEffect, useState } from 'react'
import { Row, Tabs } from 'antd'
import InsDataTable from 'components/datatable/InsDataTable'
import PropTypes from 'prop-types'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InstructionTypeEnum from 'enums/instruction_type.enum'
import { formatNumber } from 'helpers/number.helper'
import { DescriptionIcon, SearchIcon } from 'components/InsIcon'
import { useTranslation } from 'react-i18next'
import { checkNoDocument } from 'helpers/business.helper'
import { getClassificationItemName, getClassificationItemNameFromValue } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsLoader, { InsBlockLoader } from 'components/InsLoader'
import { isHeadline } from 'helpers/detail.helper'

const { TabPane } = Tabs

const RowDetail = ({ record, selected }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [estimationDetails, setEstimationDetails] = useState([])
    const [placeOrderDetails, setPlaceOrderDetails] = useState([])
    const [instructionInternalDetails, setInstructionInternalDetails] = useState([])
    const [instructionOutsourceDetails, setInstructionOutsourceDetails] = useState([])
    const [saleDetails, setSaleDetails] = useState([])
    const columnProducts = [
        {
            title: 'タイトル',
            dataIndex: 'title',
            render: (t, r) => t || r.product_name,
        },
        {
            title: '仕様',
            dataIndex: 'spec',
        },
        {
            title: 'サイズ',
            dataIndex: 'size',
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            render: q => formatNumber(q),
        },
        {
            title: '単位',
            dataIndex: 'unit',
            render: q => getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, q),
        },
        {
            title: '原価',
            dataIndex: 'unit_cost',
            render: r => formatNumber(r),
        },
        {
            title: '単価',
            dataIndex: 'unit_price',
            render: unitPrice => formatNumber(unitPrice),
        },
        {
            title: '税区分',
            dataIndex: 'tax_rate',
            render: q => getClassificationItemNameFromValue(ClassificationTypeEnum.TAX_RATE.value, q),
        },
        {
            title: '金額',
            dataIndex: 'amount',
            render: amount => formatNumber(amount),
        },
    ]

    const getDetails = data => {
        let details = []
        data.details.map(detail => {
            if (!isHeadline(detail)) {
                details.push(detail)
            }
        })
        return details
    }

    const getEstimationsByProject = async () => {
        const data = await api.get(APP_URLS.ESTIMATIONS_BY_PROJECT.replace(':projectId', record.id))
        if (data) {
            let details = []
            data.detail.map(detail => {
                if (!isHeadline(detail)) {
                    details.push(detail)
                }
            })
            setEstimationDetails(details)
        }
    }

    const getInstructionInternalByProject = async () => {
        const data = await api.get(
            APP_URLS.INSTRUCTION_BY_TYPE.replace(':projectId', record.id).replace(
                ':instructionType',
                InstructionTypeEnum.INTERNAL.value
            )
        )
        if (data) {
            let details = getDetails(data)
            setInstructionInternalDetails(details)
        }
    }

    const getInstructionOutsourceByProject = async () => {
        const data = await api.get(
            APP_URLS.INSTRUCTION_BY_TYPE.replace(':projectId', record.id).replace(
                ':instructionType',
                InstructionTypeEnum.OUTSOURCE.value
            )
        )
        if (data) {
            let details = getDetails(data)
            setInstructionOutsourceDetails(details)
        }
    }

    const getPlaceOrderByProject = async () => {
        const data = await api.get(APP_URLS.PLACE_ORDER_BY_PROJECT.replace(':projectId', record.id))
        if (data) {
            let details = getDetails(data)
            let detailsDelivery = []
            data.delivery_destinations.map(deliveryDestination => {
                deliveryDestination.details.map(detail => {
                    if (!isHeadline(detail)) {
                        detailsDelivery.push(detail)
                    }
                })
            })
            setPlaceOrderDetails(detailsDelivery.concat(details))
        }
    }

    const getSaleByProject = async () => {
        const data = await api.get(APP_URLS.SALE_BY_PROJECT.replace(':projectId', record.id))
        if (data) {
            let details = getDetails(data)
            setSaleDetails(details)
        }
    }

    useEffect(() => {
        setLoading(true)
        if (record.estimation) {
            getEstimationsByProject().then()
        }
        if (record.place_order) {
            getPlaceOrderByProject().then()
        }
        if (record.instruction_internal) {
            getInstructionInternalByProject().then()
        }
        if (record.instruction_outsource) {
            getInstructionOutsourceByProject().then()
        }
        if (record.sale) {
            getSaleByProject().then()
        }
        setLoading(false)
    }, [])

    const table = (data, columns) => {
        return (
            <InsDataTable
                loading={loading ? { indicator: <InsLoader /> } : false}
                bordered={true}
                hasSelection={false}
                data={data}
                columns={columns}
                onSelectionChanged={keys => console.log(keys)}
            />
        )
    }

    return (
        <div className="page-container" style={{ backgroundColor: selected ? '#e0f2f1' : '' }}>
            {checkNoDocument(record) ? (
                <Row justify="center" className="ins-empty-text" align="middle" style={{ height: 200 }}>
                    <div className="circle">
                        <Row justify="center" align="middle" className="icon">
                            <div className="icon">
                                <DescriptionIcon size={54} className="description-icon" />
                                <SearchIcon size={30} className="search-icon" />
                            </div>
                        </Row>
                        <Row justify="center" align="middle" className="text">
                            <h4>{t('No document')}</h4>
                        </Row>
                    </div>
                </Row>
            ) : (
                <Tabs onChange={key => console.log(key)} type="card">
                    {record.estimation && (
                        <TabPane tab="見積書" key="1">
                            <div className={'table-estimation'}>{table(estimationDetails, columnProducts)}</div>
                        </TabPane>
                    )}
                    {record.instruction_internal && (
                        <TabPane tab="作業指示書(社内)" key="2">
                            <div className={'table-estimation'}>
                                {table(instructionInternalDetails, columnProducts)}
                            </div>
                        </TabPane>
                    )}
                    {record.instruction_outsource && (
                        <TabPane tab="作業指示書(外注)" key="3">
                            <div className={'table-estimation'}>
                                {table(instructionOutsourceDetails, columnProducts)}
                            </div>
                        </TabPane>
                    )}
                    {record.place_order && (
                        <TabPane tab="発注書" key="4">
                            <div className={'table-estimation'}>{table(placeOrderDetails, columnProducts)}</div>
                        </TabPane>
                    )}
                    {record.sale && (
                        <TabPane tab="納品書" key="5">
                            <div className={'table-estimation'}>{table(saleDetails, columnProducts)}</div>
                        </TabPane>
                    )}
                </Tabs>
            )}
        </div>
    )
}

export default RowDetail

RowDetail.propTypes = {
    record: PropTypes.any,
    selected: PropTypes.bool,
}
