export const ProjectPermissionEnum = Object.freeze({
    PROJECT_READ_ONLY: { value: 'PROJECT_READ_ONLY', text: '閲覧のみ' },
    PROJECT_EDITABLE: { value: 'PROJECT_EDITABLE', text: '編集可能' },
})

export const SalePermissionEnum = Object.freeze({
    SALE_READ_ONLY: { value: 'SALE_READ_ONLY', text: '閲覧のみ' },
    SALE_EDITABLE: { value: 'SALE_EDITABLE', text: '編集可能' },
})

export const EstimationPermissionEnum = Object.freeze({
    ESTIMATION_READ_ONLY: { value: 'ESTIMATION_READ_ONLY', text: '閲覧のみ' },
    ESTIMATION_EDITABLE: { value: 'ESTIMATION_EDITABLE', text: '編集可能' },
})

// export const TrackingPermissionEnum = Object.freeze({
//     TRACKING_READ_ONLY: { value: 'TRACKING_READ_ONLY', text: '閲覧のみ' },
//     TRACKING_EDITABLE: { value: 'TRACKING_EDITABLE', text: '編集可能' },
// })

export const InstructionPermissionEnum = Object.freeze({
    INSTRUCTION_READ_ONLY: { value: 'INSTRUCTION_READ_ONLY', text: '閲覧のみ' },
    INSTRUCTION_EDITABLE: { value: 'INSTRUCTION_EDITABLE', text: '編集可能' },
})

export const InvoicePermissionEnum = Object.freeze({
    INVOICE_READ_ONLY: { value: 'INVOICE_READ_ONLY', text: '閲覧のみ' },
    INVOICE_EDITABLE: { value: 'INVOICE_EDITABLE', text: '編集可能' },
})

export const PlaceOrderPermissionEnum = Object.freeze({
    PLACE_ORDER_READ_ONLY: { value: 'PLACE_ORDER_READ_ONLY', text: '閲覧のみ' },
    PLACE_ORDER_EDITABLE: { value: 'PLACE_ORDER_EDITABLE', text: '編集可能' },
})

export const ReportPermissionEnum = Object.freeze({
    REPORT_READ_ONLY: { value: 'REPORT_READ_ONLY', text: '閲覧のみ' },
    REPORT_EDITABLE: { value: 'REPORT_EDITABLE', text: '編集可能' },
    REPORT_HIDDEN: { value: 'REPORT_HIDDEN', text: '非表示' },
})

export const RedSlipPermissionEnum = Object.freeze({
    RED_SLIP_READ_ONLY: { value: 'RED_SLIP_READ_ONLY', text: '閲覧のみ' },
    RED_SLIP_EDITABLE: { value: 'RED_SLIP_EDITABLE', text: '編集可能' },
})

export const SummaryInvoicePermissionEnum = Object.freeze({
    SUMMARY_INVOICE_READ_ONLY: { value: 'SUMMARY_INVOICE_READ_ONLY', text: '閲覧のみ' },
    SUMMARY_INVOICE_EDITABLE: { value: 'SUMMARY_INVOICE_EDITABLE', text: '編集可能' },
})

export const PaymentPermissionEnum = Object.freeze({
    PAYMENT_READ_ONLY: { value: 'PAYMENT_READ_ONLY', text: '閲覧のみ' },
    PAYMENT_EDITABLE: { value: 'PAYMENT_EDITABLE', text: '編集可能' },
    PAYMENT_HIDDEN: { value: 'PAYMENT_HIDDEN', text: '非表示' },
})

export const ShipmentPermissionEnum = Object.freeze({
    SHIPMENT_READ_ONLY: { value: 'SHIPMENT_READ_ONLY', text: '閲覧のみ' },
    SHIPMENT_EDITABLE: { value: 'SHIPMENT_EDITABLE', text: '編集可能' },
})
