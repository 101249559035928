import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import InstructionForm from 'modules/business/instruction/form/InstructionForm'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import Routes from 'routes'
import { setUser } from 'modules/common/auth/auth.slice'
import { InsBlockLoader } from 'components/InsLoader'
import { setDefaultState } from 'modules/business/placeOrder/place_order_detail.slice'

const InstructionUpdate = ({ project, instruction, instructionType, response, setUser, currentUser }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const updateInstruction = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.put(APP_URLS.INSTRUCTION_DETAIL.replace(':id', instruction.id), values)
        if (data) {
            setUser({
                ...currentUser,
                processing_product: data.processing_product,
                processing_spec: data.processing_spec,
                processing_size: data.processing_size,
            })
            if (!redirectTo) {
                redirectTo =
                    Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                    (instructionType === 1 ? '/instruction-internal' : '/instruction-outsource')
            }
            response(redirectTo).then(() => {
                setLoading(false)
            })
            dispatch(setDefaultState())
        }
    }

    return (
        <div className="p-24">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <InstructionForm
                    isEdit={true}
                    project={project}
                    instruction={instruction}
                    instructionType={instructionType}
                    initialValues={instruction}
                    onSubmit={(values, redirectTo) => updateInstruction(values, redirectTo)}
                />
            )}
        </div>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUser,
}

export default connect(mapState, mapDispatch)(InstructionUpdate)

InstructionUpdate.propTypes = {
    project: PropTypes.object.isRequired,
    instruction: PropTypes.object,
    instructionType: PropTypes.number.isRequired,
    response: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
}
