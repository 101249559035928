import React from 'react'
import PropTypes from 'prop-types'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import RedSlipForm from 'modules/business/redSlip/form/RedSlipForm'
import Routes from 'routes'

const RedSlipCreate = ({ project, sale, redSlip, invoice, updateLink }) => {
    const createRedSlip = async (values, redirectTo) => {
        const data = await api.post(APP_URLS.RED_SLIP, values)
        if (data) {
            if (!redirectTo) {
                redirectTo = Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id)
            }
            updateLink(redirectTo)
        }
    }

    return (
        <div className="p-24">
            <RedSlipForm
                project={project}
                onSubmit={(values, redirectTo) => createRedSlip(values, redirectTo)}
                invoice={invoice}
                sale={sale}
                redSlip={redSlip}
            />
        </div>
    )
}

export default RedSlipCreate

RedSlipCreate.propTypes = {
    project: PropTypes.object.isRequired,
    sale: PropTypes.object,
    redSlip: PropTypes.object,
    invoice: PropTypes.object,
    updateLink: PropTypes.func,
}
