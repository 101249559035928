import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InsFloatBar from 'components/InsFloatBar'
import { Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import { DeleteIcon, EditIcon } from 'components/InsIcon'
import BusinessStatusEnum from 'enums/business_status.enum'
import ReactToPrint from 'react-to-print'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'
import { can } from 'helpers/permission.helper'
import RedSlipDOMPrint from 'modules/business/redSlip/RedSlipDOMPrint'
import { InsBlockLoader } from 'components/InsLoader'
import InsModal from 'components/InsModal'
import { useLocation } from 'react-router-dom'

const RedSlipPreview = ({ sale, project, inModal, updateLink, response, redSlip }) => {
    const { t } = useTranslation()
    const componentRef = useRef()
    const location = useLocation()

    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const deleteRedSlip = async () => {
        setLoading(true)
        await api.delete(APP_URLS.RED_SLIP_DETAIL.replace(':id', redSlip.id))
        response()
        history.push(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
        setModalDeleteVisible(false)
        setLoading(false)
    }

    const updateStatusRedSlip = async status => {
        const data = await api.post(APP_URLS.RED_SLIP_UPDATE_STATUS, {
            status: status,
            red_slip_id: redSlip.id,
        })
        if (data) {
            response()
        }
    }

    const canEditRedSlip = can('RED_SLIP_EDITABLE') && !project.is_locked
    const isRedSlipIssued = redSlip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value

    if (loading) {
        return <InsBlockLoader />
    }

    return (
        <div className="page-container">
            <RedSlipDOMPrint
                project={project}
                salePrint={sale}
                redSlip={redSlip}
                componentRef={componentRef}
                showPreview={true}
            />

            <InsFloatBar visible={!inModal}>
                <Row justify="space-between" align="middle">
                    {canEditRedSlip && (
                        <Col>
                            <InsButton
                                disabled={isRedSlipIssued}
                                icon={<DeleteIcon size={20} className={isRedSlipIssued ? '' : 'color-pantone-213-c'} />}
                                buttonStyle="default"
                                onClick={() => setModalDeleteVisible(true)}
                                width={isRedSlipIssued ? '130px' : '102px'}
                            >
                                {isRedSlipIssued ? t('赤伝削除不可') : t('赤伝削除')}
                            </InsButton>
                            <InsModal
                                className="ins-modal-w-550"
                                title="削除"
                                visible={modalDeleteVisible}
                                onCancel={() => setModalDeleteVisible(false)}
                                onOk={deleteRedSlip}
                                okText="はい"
                                cancelText="いいえ"
                            >
                                <h3>{'赤伝を削除してもよろしいですか？'}</h3>
                            </InsModal>
                            <InsButton
                                disabled={isRedSlipIssued}
                                icon={<EditIcon size={20} className={isRedSlipIssued ? '' : 'color-pantone-2738-c'} />}
                                buttonStyle="default"
                                onClick={updateLink}
                                width={isRedSlipIssued ? '130px' : '102px'}
                                className="ml-8"
                            >
                                {isRedSlipIssued ? t('赤伝編集不可') : t('赤伝編集')}
                            </InsButton>
                        </Col>
                    )}

                    <Col className="ins-flex flex-middle btn-update-project-bottom">
                        {canEditRedSlip && (
                            <ReactToPrint
                                trigger={() => (
                                    <InsButton
                                        className={!isRedSlipIssued ? 'fw-bold' : 'fw-500'}
                                        buttonStyle={!isRedSlipIssued ? 'secondary' : 'outline'}
                                        buttonSize="big"
                                        style={{ width: 140 }}
                                        onClick={() => {}}
                                    >
                                        {!isRedSlipIssued ? t('赤伝 発行') : t('赤伝 再発行')}
                                    </InsButton>
                                )}
                                content={() => componentRef.current}
                                onBeforePrint={() => {
                                    !isRedSlipIssued && updateStatusRedSlip(BusinessStatusEnum.RED_SLIP_ISSUED.value)
                                }}
                            />
                        )}
                        {canEditRedSlip && isRedSlipIssued && (
                            <InsButton
                                className="fw-500"
                                buttonStyle={'outline-secondary'}
                                buttonSize="big"
                                style={{ width: 140 }}
                                onClick={() => {
                                    isRedSlipIssued && updateStatusRedSlip(BusinessStatusEnum.RED_SLIP_CREATING.value)
                                }}
                            >
                                {t('赤伝 発行 取消')}
                            </InsButton>
                        )}
                    </Col>
                </Row>
            </InsFloatBar>
        </div>
    )
}
export default RedSlipPreview

RedSlipPreview.propTypes = {
    sale: PropTypes.object.isRequired,
    redSlip: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    inModal: PropTypes.bool,
    updateLink: PropTypes.func,
    response: PropTypes.func,
}
