import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import InsCollapse from 'components/InsCollapse'
import InsInputSearch from 'components/InsInputSearch'
import { useTranslation } from 'react-i18next'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import InsSelect from 'components/select/InsSelect'
import { Col, Row, Select } from 'antd'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import UserSelect from 'modules/master/user/UserSelect'
import { setDetailSearch, setFilters, setKeywords, updateDetailSearch } from 'modules/business/redSlip/redSlip.slice'
import BusinessSelect from 'components/select/BusinessSelect'
import BusinessPageEnum from 'enums/business_page.enum'
import { getClassificationsByType } from 'helpers/classification.helper'
import CustomerSelect from 'modules/master/customer/CustomerSelect'

const RedSlipListSearch = ({
    keywords,
    setKeywords,
    detailSearch,
    setDetailSearch,
    updateDetailSearch,
    onSearch,
    filters,
    getRedSlips,
    pagination,
    onReset,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const collapseRef = useRef()

    const redSlipStatus = getClassificationsByType(ClassificationTypeEnum.BUSINESS_STATUS.value).filter(status =>
        status.value.startsWith(BusinessPageEnum.RED_SLIPS.value)
    )

    const createdAtChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            'created_at:gte': value[0] + '\t00:00:00',
            'created_at:lte': value[1] + '\t23:59:59',
        })
        dispatch(setFilters(newFilters))
        getRedSlips(pagination, newFilters)
    }

    const statusChangeHandler = value => {
        const newFilters = Object.assign({}, filters, { status: value })
        dispatch(setFilters(newFilters))
        getRedSlips(pagination, newFilters)
    }

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <>
                    <BusinessSelect pageId={BusinessPageEnum.RED_SLIPS.value} />

                    <InsSelect
                        className="mr-4"
                        value={filters.status}
                        onSelect={value => statusChangeHandler(value)}
                        dropdownInnerStyle={{ minWidth: 150 }}
                    >
                        <Select.Option value={'0'}>全てのステータス</Select.Option>
                        {redSlipStatus.map(s => (
                            <Select.Option key={s.value} value={s.value} title={s.text}>
                                {s.text}
                            </Select.Option>
                        ))}
                    </InsSelect>

                    <InsDateRangePicker
                        className="mr-8"
                        value={[moment.utc(filters['created_at:gte']), moment.utc(filters['created_at:lte'])]}
                        onChange={(mm, value) => createdAtChangeHandler(value)}
                    />
                    <InsInputSearch
                        className="free-text-search"
                        placeholder={t('RedSlip code, project code, project name, etc')}
                        value={keywords}
                        onChange={values => {
                            setKeywords(values)
                        }}
                        onSearch={values => {
                            // setDetailSearch({})
                            onSearch({
                                'keyword': values,
                                'created_at:gte': filters['created_at:gte'],
                                'created_at:lte': filters['created_at:lte'],
                            })
                        }}
                    />
                </>
            }
            className="mb-16"
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('entity:Customer')}</strong>
                </Col>
                <Col>
                    <CustomerSelect
                        style={{ width: 180 }}
                        showCreateButton={false}
                        showListLink={false}
                        onChange={(value, record) => {
                            updateDetailSearch({ field: 'project|customer_id', value })
                            updateDetailSearch({
                                field: 'project|cache_customer_name',
                                value: (record?.item || record).customer_name,
                            })
                        }}
                        value={detailSearch['project|customer_id']}
                        record={
                            detailSearch['project|customer_id']
                                ? {
                                      id: detailSearch['project|customer_id'],
                                      customer_name: detailSearch['project|cache_customer_name'],
                                  }
                                : undefined
                        }
                    />
                </Col>
                <Col className="ml-32" flex={'80px'}>
                    <strong>{t('column:Pic name')}</strong>
                </Col>
                <Col>
                    <UserSelect
                        style={{ width: 180 }}
                        dropdownMatchSelectWidth={false}
                        onChange={(value, record) => {
                            updateDetailSearch({ field: 'project|pic_id', value })
                            updateDetailSearch({
                                field: 'project|cache_pic_name',
                                value: (record?.item || record).pic_name,
                            })
                        }}
                        value={detailSearch['project|pic_id']}
                        record={
                            detailSearch['project|pic_id']
                                ? {
                                      id: detailSearch['project|pic_id'],
                                      pic_name: detailSearch['project|cache_pic_name'],
                                  }
                                : undefined
                        }
                        showCreateButton={false}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Issued date')}</strong>
                </Col>
                <Col>
                    <InsDateRangePicker
                        className="mr-8"
                        value={[
                            detailSearch['red_slip_issued_date:gte']
                                ? moment(detailSearch['red_slip_issued_date:gte'])
                                : '',
                            detailSearch['red_slip_issued_date:lte']
                                ? moment(detailSearch['red_slip_issued_date:lte'])
                                : '',
                        ]}
                        onChange={(mm, value) => {
                            updateDetailSearch({ field: 'red_slip_issued_date:gte', value: value[0] })
                            updateDetailSearch({ field: 'red_slip_issued_date:lte', value: value[1] })
                        }}
                    />
                </Col>
            </Row>
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    keywords: state.redSlip.keywords,
    detailSearch: state.redSlip.detailSearch,
    filters: state.redSlip.filters,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(RedSlipListSearch)

RedSlipListSearch.propTypes = {
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    getRedSlips: PropTypes.func.isRequired,
}
