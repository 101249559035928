import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getData, setFilters, setSorter, setKeywords, setPagination } from 'modules/master/supplier/supplier.slice'
import SupplierListComponent from 'modules/master/supplier/SupplierListComponent'

const SupplierList = ({ state }) => {
    return (
        <div className="page-container with-sidebar">
            <SupplierListComponent
                state={state}
                getData={getData}
                setFilters={setFilters}
                setSorter={setSorter}
                setKeywords={setKeywords}
                setPagination={setPagination}
            />
        </div>
    )
}

const mapState = state => ({ state: state.supplier })
export default connect(mapState)(SupplierList)

SupplierList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
}
