import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { APP_URLS } from 'constants/url.constant'
import Routes from 'routes'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import ClassificationForm from 'modules/master/classification/form/ClassificationForm'
import { addClassification, getClassificationsByType } from 'helpers/classification.helper'
import InsBackLink from 'components/InsBackLink'

const ClassificationCreate = () => {
    const { t } = useTranslation()
    let code = new URLSearchParams(useLocation().search).get('code')
    if (getClassificationsByType(code).length === 0) {
        code = null
    }

    const createClassification = async values => {
        const classification = await api.post(APP_URLS.CLASSIFICATIONS, values)
        addClassification(classification)
        history.push(Routes.private.master.CLASSIFICATION_LIST.path)
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} create', { entity: t('entity:Classification') })}
            </h1>
            <ClassificationForm
                modalConfirmSave={modalConfirmSave}
                setModalConfirmSave={setModalConfirmSave}
                initialValues={{ classification_type_code: code }}
                onSubmit={(values, redirectTo, confirmSave = true) => {
                    if (confirmSave) setModalConfirmSave(true)
                    else {
                        createClassification(values, redirectTo, confirmSave)
                        setModalConfirmSave(false)
                    }
                }}
            />
        </div>
    )
}

export default ClassificationCreate

ClassificationCreate.propTypes = {
    match: PropTypes.object,
}
