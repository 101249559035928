import React from 'react'
import PropTypes from 'prop-types'
import { APP_URLS } from 'constants/url.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import { getData, setFilters, setSorter } from 'modules/master/user/user_modal.slice'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import UserListComponent from 'modules/master/user/UserListComponent'
import UserDetailComponent from 'modules/master/user/UserDetailComponent'
import UserCreateComponent from 'modules/master/user/UserCreateComponent'
import { checkAdmin } from 'helpers/permission.helper'
import { Select } from 'antd'

const UserSelect = ({
    state,
    record,
    showListLink = true,
    showCreateButton = true,
    showDetailLink = true,
    ...rest
}) => {
    const { t } = useTranslation()
    let initialOptions = []
    let updatedOptions = []
    if (record) {
        initialOptions.push({
            id: record.id,
            pic_code: record.pic_code,
            pic_name: record.pic_name,
        })
        updatedOptions.push({
            id: record.id,
            pic_name: record.pic_name,
        })
    }

    let listModal, createModal, detailModal
    if (showListLink) {
        listModal = {
            title: t('navigation:User management'),
            component: UserListComponent,
            props: {
                state,
                getData,
                setFilters,
                setSorter,
            },
        }
    }
    if (showCreateButton && checkAdmin()) {
        createModal = {
            title: t('navigation:{{entity}} create', { entity: t('entity:User') }),
            component: UserCreateComponent,
        }
    }
    if (showDetailLink) {
        detailModal = {
            title: t('navigation:{{entity}} information', { entity: t('entity:User') }),
            linkText: t('navigation:{{entity}} information', { entity: t('entity:User') }),
            component: UserDetailComponent,
        }
    }

    return (
        <InsRemoteSelect
            {...rest}
            url={`${APP_URLS.USERS}?page_size=100&is_enabled=1&keyword[]=`}
            initialOptions={initialOptions}
            updatedOptions={updatedOptions}
            optionIndexKey="id"
            optionValueKey="id"
            optionTextKey="pic_name"
            optionLabelProp="label"
            optionRenderer={item => (
                <Select.Option key={item.id} value={item.id} item={item} label={item.pic_name}>
                    <span style={{ fontWeight: 'bold' }}>{item.pic_code}</span>
                    {'　'}
                    {item.pic_name}
                </Select.Option>
            )}
            listModal={listModal}
            createModal={createModal}
            detailModal={detailModal}
        />
    )
}

const mapState = state => ({
    state: state.userModal,
})
export default connect(mapState)(UserSelect)

UserSelect.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.any,
        pic_code: PropTypes.string,
        pic_name: PropTypes.string,
    }),
    showListLink: PropTypes.bool,
    showCreateButton: PropTypes.bool,
    showDetailLink: PropTypes.bool,
    state: PropTypes.shape(BaseStatePropShape),
}
