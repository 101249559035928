import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Select } from 'antd'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import { EditIcon, CloseIcon, AddIcon } from 'components/InsIcon'
import Routes from 'routes'
import InsSelect from 'components/select/InsSelect'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsInput from 'components/InsInput'
import { getEnumText } from 'helpers/enum.helper'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import InsModal from 'components/InsModal'

const ClassificationForm = ({
    isEdit,
    initialValues = {},
    onSubmit,
    onCancel,
    modalConfirmSave,
    setModalConfirmSave,
}) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    initialValues = Object.assign(
        {
            classification_type_code: ClassificationTypeEnum.BILL_DATE.value,
            classification_type_name: ClassificationTypeEnum.BILL_DATE.text,
            classification_item_code: '',
            classification_item_value: '',
        },
        initialValues
    )

    // const formSubmitHandler = () => {
    //     let formData = form.getFieldsValue()
    //     const name = getEnumText(ClassificationTypeEnum, formData['classification_type_code'])
    //     if (!name) {
    //         return
    //     }
    //     formData['classification_type_name'] = name
    //     onSubmit(formData)
    // }

    const buttonControl = (
        <Row gutter={8} justify="end">
            <Col>
                <InsButton
                    buttonStyle="outline"
                    buttonSize="big"
                    centered
                    width="120px"
                    icon={<CloseIcon />}
                    onClick={onCancel}
                    isSubmitButton={false}
                    linkTo={Routes.private.master.CLASSIFICATION_LIST.path}
                    buttonType="link"
                >
                    {t('common:Cancel')}
                </InsButton>
            </Col>
            <Col>
                <InsButton
                    buttonStyle="primary"
                    buttonSize="big"
                    centered
                    width="136px"
                    icon={isEdit ? <EditIcon size={20} /> : <AddIcon size={20} />}
                    isSubmitButton={true}
                >
                    {t(`navigation:{{entity}} ${isEdit ? 'update new' : 'create new'}`, {
                        entity: t('entity:Classification'),
                    })}
                </InsButton>
            </Col>
        </Row>
    )

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => {
                        let formData = form.getFieldsValue()
                        const name = getEnumText(ClassificationTypeEnum, formData['classification_type_code'])
                        if (!name) {
                            return
                        }
                        formData['classification_type_name'] = name
                        onSubmit(formData, null, false)
                    }}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className="ins-form pt-0"
                layout="vertical"
                form={form}
                size={'middle'}
                onFinish={values => onSubmit(values)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <Row>
                    <Col>
                        <Form.Item
                            name="classification_type_code"
                            label={t('column:Classification type code')}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please choose {{item}}', {
                                        item: t('column:Classification type code'),
                                    }),
                                },
                            ]}
                        >
                            <InsSelect onSelect={value => console.log(value)} disabled={isEdit} style={{ width: 300 }}>
                                {Object.values(ClassificationTypeEnum).map(type => (
                                    <Select.Option
                                        key={type.value}
                                        value={type.value}
                                        disabled={
                                            !isEdit && type.value === ClassificationTypeEnum.BUSINESS_STATUS.value
                                        }
                                    >
                                        {t(`enum:${type.text}`)}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </Col>
                </Row>
                {isEdit && (
                    <Row>
                        <Col>
                            <Form.Item
                                name="classification_item_code"
                                label={t('column:Classification item code')}
                                style={{ width: 300 }}
                            >
                                <InsInput disabled maxLength={15} />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <Form.Item
                            name="classification_item_name"
                            label={t('column:Classification item name')}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter {{item}}', {
                                        item: t('column:Classification item name'),
                                    }),
                                },
                            ]}
                        >
                            <InsInput style={{ width: 300 }} maxLength={300} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item
                            name="classification_item_value"
                            label={t('column:Classification item value')}
                            rules={[]}
                        >
                            <InsInput style={{ width: 300 }} maxLength={300} />
                        </Form.Item>
                    </Col>
                </Row>
                <InsFloatBar visible={true}>{buttonControl}</InsFloatBar>
            </Form>
        </>
    )
}

export default ClassificationForm

ClassificationForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
}

ClassificationForm.defaultProps = {
    isEdit: false,
}
