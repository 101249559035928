import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { APP_URLS } from 'constants/url.constant'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import ProductListComponent from 'modules/master/product/ProductListComponent'
import ProductCreateComponent from 'modules/master/product/ProductCreateComponent'
import ProductDetailComponent from 'modules/master/product/ProductDetailComponent'
import { getData, setFilters, setSorter } from 'modules/master/product/product_modal.slice'

const ProductSelect = ({
    state,
    record,
    showListLink = true,
    showCreateButton = true,
    showDetailLink = true,
    inStock = false,
    ...rest
}) => {
    const { t } = useTranslation()
    let initialOptions = []
    if (record) {
        initialOptions.push({
            id: record.id,
            product_name: record.product_name,
            product_code: record.product_code,
        })
    }

    let listModal, createModal, detailModal
    if (showListLink) {
        listModal = {
            title: t('navigation:{{entity}} list', { entity: t('entity:Product') }),
            component: ProductListComponent,
            props: {
                inStock: inStock,
                state,
                getData,
                setFilters,
                setSorter,
            },
        }
    }
    if (showCreateButton) {
        createModal = {
            title: t('navigation:{{entity}} create', { entity: t('entity:Product') }),
            component: ProductCreateComponent,
        }
    }
    if (showDetailLink) {
        detailModal = {
            title: t('navigation:{{entity}} information', { entity: t('entity:Product') }),
            linkText: t('navigation:{{entity}} information', { entity: t('entity:Product') }),
            component: ProductDetailComponent,
        }
    }

    return (
        <InsRemoteSelect
            {...rest}
            url={`${APP_URLS.PRODUCTS_SEARCH}?page_size=100&is_enabled=1&keyword=`}
            initialOptions={initialOptions}
            optionIndexKey="id"
            optionValueKey="id"
            optionTextKey="product_name"
            optionLabelProp="label"
            optionRenderer={item => (
                <Select.Option key={item.id} value={item.id} item={item} label={item.product_name}>
                    <span style={{ fontWeight: 'bold' }}>{item.product_code}</span>
                    {'　'}
                    {item.product_name}
                </Select.Option>
            )}
            listModal={listModal}
            createModal={createModal}
            detailModal={detailModal}
        />
    )
}

const mapState = state => ({
    state: state.productModal,
})
export default connect(mapState)(ProductSelect)

ProductSelect.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.any,
        product_name: PropTypes.string,
        product_code: PropTypes.string,
    }),
    showListLink: PropTypes.bool,
    showCreateButton: PropTypes.bool,
    showDetailLink: PropTypes.bool,
    state: PropTypes.shape(BaseStatePropShape),
    inStock: PropTypes.bool,
}
