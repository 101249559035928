const DayOfWeekEnum = Object.freeze({
    MONDAY: { value: 1, text: '月' },
    TUESDAY: { value: 2, text: '火' },
    WEDNESDAY: { value: 3, text: '水' },
    THURSDAY: { value: 4, text: '木' },
    FRIDAY: { value: 5, text: '金' },
    SATURDAY: { value: 6, text: '土' },
    SUNDAY: { value: 0, text: '日' },
})

export default DayOfWeekEnum
