import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form, Row } from 'antd'
import { updateDetail } from 'modules/business/shipment/shipment_process.slice'
import ProductSelect from 'modules/master/product/ProductSelect'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import InsInputNumber2 from 'components/InsInputNumber2'

const OutgoingProcessRow = React.memo(({ id, value, updateDetail }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const handlerProductSelect = async productId => {
        const product = await api.get(APP_URLS.PRODUCTS_DETAIL.replace(':id', productId))
        if (product) {
            form.setFieldsValue({
                product: product,
                product_id: product.id,
                product_name: product.product_name,
                unit: product.unit,
            })
        }
    }
    let timeout = null
    const updateDetailShipment = values => {
        if (timeout !== null) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            updateDetail({
                id,
                value: form.getFieldsValue(),
            })
        }, 300)
    }

    return (
        <Form form={form} onValuesChange={updateDetailShipment} initialValues={value}>
            <Row align="middle">
                <Form.Item
                    name="product_id"
                    style={{ flex: 384, paddingRight: 6 }}
                    rules={[
                        {
                            required: true,
                            message: t('validation:Please enter customer name'),
                        },
                    ]}
                >
                    <ProductSelect
                        record={value.product}
                        style={{ width: '100%', display: 'inline-flex' }}
                        placeholder="タイトル"
                        showCreateButton={false}
                        showDetailLink={false}
                        onSelect={productId => handlerProductSelect(productId)}
                        onChange={productId => handlerProductSelect(productId)}
                    />
                </Form.Item>
                <Form.Item name="quantity" style={{ flex: `0 0 106px`, paddingRight: 6 }}>
                    <InsInputNumber2
                        min={1}
                        onChange={value => {
                            form.setFieldsValue({
                                quantity: value,
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item name="unit" className="form-item-select-hide-search" style={{ flex: `0 0 56px` }}>
                    <ClassificationSelect typeEnum={ClassificationTypeEnum.PRODUCT_UNIT} showCreateButton={false} />
                </Form.Item>
            </Row>
        </Form>
    )
})

const mapState = (state, props) => ({
    value: state.shipmentProcess.details[props.id],
    currentUser: state.auth.user,
})

const mapDispatch = { updateDetail }
export default connect(mapState, mapDispatch)(OutgoingProcessRow)

OutgoingProcessRow.propTypes = {
    id: PropTypes.number,
    value: PropTypes.object,
    updateDetail: PropTypes.func,
}
