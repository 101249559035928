import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InsBackLink from 'components/InsBackLink'
import UserDetailComponent from 'modules/master/user/UserDetailComponent'
import { setUser } from 'modules/common/auth/auth.slice'
import { connect } from 'react-redux'
import UserRoleEnum from 'enums/user_role.enum'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'

const UserDetail = ({}) => {
    const { id } = useParams()
    const deleteCustomer = async () => {
        await api.delete(APP_URLS.USERS_DETAIL.replace(':id', id))
        history.push(Routes.private.master.USER_LIST.path)
    }
    if (!checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return (
        <div className="page-container with-sidebar mb-64">
            <InsBackLink />
            <UserDetailComponent id={id} onDelete={() => deleteCustomer()} />
        </div>
    )
}

export default UserDetail

UserDetail.propTypes = {
    match: PropTypes.object.isRequired,
}
