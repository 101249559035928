import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import Routes from 'routes'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import { AddIcon } from 'components/InsIcon'
import InsLoader from 'components/InsLoader'
import { getData, setFilters, setSorter } from 'modules/master/stock/stock.slice'
import InsPagination from 'components/datatable/InsPagination'
import InsTab from 'components/InsTab'
import StockListSearch from 'modules/master/stock/StockListSearch'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import InsModal from 'components/InsModal'

const StockList = ({ state }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [deleteStockModal, setDeleteStockModal] = useState({
        visible: false,
        id: null,
    })

    const deleteStock = async id => {
        await api.delete(APP_URLS.STOCKS_DETAIL.replace(':id', deleteStockModal.id))
        setDeleteStockModal({
            visible: false,
            id: null,
        })
        getStocks()
    }

    const stockColumns = [
        {
            title: t('column:Product code'),
            dataIndex: 'product|product_code',
            render: (text, record) => record.product.product_code,
            sorter: true,
        },
        {
            title: t('column:Product name'),
            dataIndex: 'product|product_name',
            render: (text, record) => record.product.product_name,
            sorter: true,
        },
        {
            title: t('column:Remain quantity'),
            dataIndex: 'remain_quantity',
            sorter: true,
        },
        { title: t('column:Note'), dataIndex: 'note', sorter: true },
        {
            title: '',
            dataIndex: '',
            render: (text, r) => {
                return (
                    <>
                        <InsButton
                            buttonType="link"
                            buttonStyle="plain"
                            className="mr-20"
                            linkTo={Routes.private.master.STOCK_UPDATE.path.replace(':id', r.id)}
                        >
                            {t('common:Edit')}
                        </InsButton>
                        <InsButton
                            // buttonType="link"
                            // linkTo={Routes.private.master.STOCK_LIST.path}
                            buttonStyle="plain"
                            onClick={() =>
                                setDeleteStockModal({
                                    visible: true,
                                    id: r.id,
                                })
                            }
                        >
                            {t('common:Delete')}
                        </InsButton>
                    </>
                )
            },
        },
    ]

    const getStocks = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        if (!state.fetched) {
            getStocks()
        }
        // eslint-disable-next-line
    }, [])

    const updateFilters = obj => {
        const newFilters = Object.assign({}, obj)
        dispatch(setFilters(newFilters))
        getStocks(state.pagination, newFilters)
    }
    if (!checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }

    return (
        <div className="page-container with-sidebar">
            <InsModal
                title={t('common:Delete')}
                visible={deleteStockModal.visible}
                onCancel={() =>
                    setDeleteStockModal({
                        visible: false,
                        id: null,
                    })
                }
                onOk={deleteStock}
                okText="はい"
                cancelText="いいえ"
            >
                <h2>{t('common:Are you sure')}</h2>
            </InsModal>
            <Row className="mt-8 mb-16" justify="space-between">
                <Col>
                    <h1 className="page-title mb-0">
                        {t('navigation:{{entity}} management', { entity: t('entity:Stock') })}
                    </h1>
                </Col>
                {/*<Col>*/}
                {/*    <InsButton*/}
                {/*        width={120}*/}
                {/*        buttonType="link"*/}
                {/*        linkTo={Routes.private.master.STOCK_CREATE.path}*/}
                {/*        icon={<AddIcon size={24} />}*/}
                {/*        buttonStyle="secondary"*/}
                {/*    >*/}
                {/*        {t('navigation:{{entity}} create', { entity: t('entity:Stock') })}*/}
                {/*    </InsButton>*/}
                {/*</Col>*/}
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <StockListSearch
                        onSearch={condition => updateFilters(condition)}
                        onReset={() => updateFilters({})}
                    />
                </Col>
            </Row>

            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsTab
                        type="card"
                        metaComponent={
                            <InsPagination {...state.pagination} onChange={pagination => getStocks(pagination)} />
                        }
                        className="hide-tabs"
                    >
                        <Tabs.TabPane tab={t('common:Enabled')} key="1" />
                    </InsTab>
                    <InsDataTable
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered
                        columns={stockColumns}
                        data={state.data}
                        onChange={(pagination, filter, sorter) => {
                            getStocks(state.pagination, state.filters, sorter)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                    />
                </Col>
            </Row>
        </div>
    )
}

const mapState = state => ({ state: state.stock })
export default connect(mapState)(StockList)

StockList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
}
