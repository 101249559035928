import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import CustomerListComponent from 'modules/master/customer/CustomerListComponent'
import CustomerCreateComponent from 'modules/master/customer/CustomerCreateComponent'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import { getData, setPagination, setFilters, setSorter } from 'modules/master/customer/customer_modal.slice'
import { Select } from 'antd'

const CustomerSelect = ({
    entity,
    state,
    record,
    showListLink = true,
    showCreateButton = true,
    showDetailLink = true,
    ...rest
}) => {
    const { t } = useTranslation()
    if (!entity) {
        entity = t('entity:Customer')
    }
    let initialOptions = []
    if (record) {
        initialOptions.push({
            id: record.id,
            customer_code: record.customer_code,
            customer_name: record.customer_name,
        })
    }

    let listModal, createModal, detailModal
    if (showListLink) {
        listModal = {
            title: t('navigation:{{entity}} list', { entity: entity }),
            component: CustomerListComponent,
            props: {
                state,
                getData,
                setPagination,
                setFilters,
                setSorter,
            },
        }
    }
    if (showCreateButton) {
        createModal = {
            title: t('navigation:{{entity}} create', { entity: entity }),
            component: CustomerCreateComponent,
        }
    }
    if (showDetailLink) {
        detailModal = {
            // title: t('navigation:{{entity}} information', { entity: entity }),
            linkText: t('navigation:{{entity}} information', { entity: entity }),
            component: CustomerDetailComponent,
        }
    }

    return (
        <InsRemoteSelect
            {...rest}
            url={`${APP_URLS.CUSTOMERS}?page_size=100&is_enabled=1&customer_info:like=`}
            initialOptions={initialOptions}
            optionIndexKey="id"
            optionValueKey="id"
            optionTextKey="customer_name"
            optionLabelProp="label"
            optionRenderer={item => (
                <Select.Option key={item.id} value={item.id} item={item} label={item.customer_name}>
                    <span style={{ fontWeight: 'bold' }}>{item.customer_code}</span>
                    {'　'}
                    {item.customer_name}
                </Select.Option>
            )}
            listModal={listModal}
            createModal={createModal}
            detailModal={detailModal}
        />
    )
}

const mapState = state => ({
    state: state.customerModal,
})
export default connect(mapState)(CustomerSelect)

CustomerSelect.propTypes = {
    entity: PropTypes.string,
    record: PropTypes.shape({
        id: PropTypes.any,
        customer_code: PropTypes.string,
        customer_name: PropTypes.string,
    }),
    showListLink: PropTypes.bool,
    showCreateButton: PropTypes.bool,
    showDetailLink: PropTypes.bool,
    state: PropTypes.shape(BaseStatePropShape),
}
