import React from 'react'
import * as PropTypes from 'prop-types'
import { ReactComponent as ClearInputSvg } from 'assets/images/icons/mdi_clear_input.svg'
import { ReactComponent as SortDefaultSvg } from 'assets/images/icons/mdi_sort_default.svg'
import { ReactComponent as ArrowDropdownSvg } from 'assets/images/icons/mdi_arrow_drop_down.svg'
import { ReactComponent as AccountCircleSvg } from 'assets/images/icons/mdi_account_circle.svg'
import { ReactComponent as SettingSvg } from 'assets/images/icons/mdi_setting.svg'
import { ReactComponent as UnfoldLessSvg } from 'assets/images/icons/mdi_unfold_less.svg'
import { ReactComponent as UnfoldMoreSvg } from 'assets/images/icons/mdi_unfold_more.svg'
import { ReactComponent as FirstPageSvg } from 'assets/images/icons/mdi_first_page.svg'
import { ReactComponent as KeyboardArrowLeftSvg } from 'assets/images/icons/mdi_keyboard_arrow_left.svg'
import { ReactComponent as KeyboardArrowRightSvg } from 'assets/images/icons/mdi_keyboard_arrow_right.svg'
import { ReactComponent as LastPageSvg } from 'assets/images/icons/mdi_last_page.svg'
import { ReactComponent as BulSvg } from 'assets/images/icons/mdi_bul.svg'
import { ReactComponent as FolderOpenSvg } from 'assets/images/icons/mdi_folder_open.svg'
import { ReactComponent as AddSvg } from 'assets/images/icons/mdi_add.svg'
import { ReactComponent as SearchSvg } from 'assets/images/icons/mdi_search.svg'
import { ReactComponent as CloseSvg } from 'assets/images/icons/mdi_close.svg'
import { ReactComponent as CalendarTodaySvg } from 'assets/images/icons/mdi_calendar_today.svg'
import { ReactComponent as BackSvg } from 'assets/images/icons/mdi_back.svg'
import { ReactComponent as EditSvg } from 'assets/images/icons/mdi_edit.svg'
import { ReactComponent as DeleteSvg } from 'assets/images/icons/mdi_delete.svg'
import { ReactComponent as HelpOutlineSvg } from 'assets/images/icons/mdi_help_outline.svg'
import { ReactComponent as MenuIconSvg } from 'assets/images/icons/mdi_menu.svg'
import { ReactComponent as CommentIconSvg } from 'assets/images/icons/mdi_comment.svg'
import { ReactComponent as SubdirectoryArrowRightSvg } from 'assets/images/icons/mdi_subdirectory_arrow_right.svg'
import { ReactComponent as TruckSvg } from 'assets/images/icons/mdi_truck.svg'
import { ReactComponent as ReloadSvg } from 'assets/images/icons/mdi_reload.svg'
import { ReactComponent as CheckSvg } from 'assets/images/icons/mdi_check.svg'
import { ReactComponent as FileCopySvg } from 'assets/images/icons/mdi_file_copy.svg'
import { ReactComponent as PrintSvg } from 'assets/images/icons/mdi_print.svg'
import { ReactComponent as DescriptionSvg } from 'assets/images/icons/mdi_description.svg'
import { ReactComponent as ReportProblemSvg } from 'assets/images/icons/mdi_report_problem.svg'
import { ReactComponent as AssignmentSvg } from 'assets/images/icons/mdi_assignment.svg'
import { ReactComponent as PreviewSvg } from 'assets/images/icons/mdi_find_in_page.svg'
import { ReactComponent as LaunchSvg } from 'assets/images/icons/mdi_launch.svg'
import { ReactComponent as AccessTimeSvg } from 'assets/images/icons/mdi_access_time.svg'
import { ReactComponent as OpenInNewSvg } from 'assets/images/icons/mdi_open_in_new.svg'
import { ReactComponent as CompanySealSvg } from 'assets/images/icons/company_seal.svg'
import { ReactComponent as LockSvg } from 'assets/images/icons/mdi_lock.svg'
import { ReactComponent as LockOpenSvg } from 'assets/images/icons/mdi_lock_open.svg'

const InsIcon = ({ svg: SvgComponent, size, className, onClick }) => (
    <span role="img" aria-label="home" className={`anticon ins-icon ${className ? className : ''}`} onClick={onClick}>
        <SvgComponent width={`${size}px`} height={`${size}px`} />
    </span>
)

InsIcon.propTypes = {
    className: PropTypes.any,
    size: PropTypes.number,
    svg: PropTypes.any.isRequired,
    onClick: PropTypes.func,
}

InsIcon.defaultProps = { size: 24 }

export default InsIcon

export const ClearInputIcon = props => <InsIcon svg={ClearInputSvg} {...props} />
export const SortDefaultIcon = props => <InsIcon svg={SortDefaultSvg} {...props} />
export const ArrowDropdownIcon = props => <InsIcon svg={ArrowDropdownSvg} {...props} />
export const AccountCircleIcon = props => <InsIcon svg={AccountCircleSvg} {...props} />
export const SettingIcon = props => <InsIcon svg={SettingSvg} {...props} />
export const UnfoldLessIcon = props => <InsIcon svg={UnfoldLessSvg} {...props} />
export const UnfoldMoreIcon = props => <InsIcon svg={UnfoldMoreSvg} {...props} />
export const FirstPageIcon = props => <InsIcon svg={FirstPageSvg} {...props} />
export const KeyboardArrowLeftIcon = props => <InsIcon svg={KeyboardArrowLeftSvg} {...props} />
export const KeyboardArrowRightIcon = props => <InsIcon svg={KeyboardArrowRightSvg} {...props} />
export const LastPageIcon = props => <InsIcon svg={LastPageSvg} {...props} />
export const BulIcon = props => <InsIcon svg={BulSvg} {...props} />
export const FolderOpenIcon = props => <InsIcon svg={FolderOpenSvg} {...props} />
export const AddIcon = props => <InsIcon svg={AddSvg} {...props} />
export const SearchIcon = props => <InsIcon svg={SearchSvg} {...props} />
export const CloseIcon = props => <InsIcon svg={CloseSvg} {...props} />
export const CalendarTodayIcon = props => <InsIcon svg={CalendarTodaySvg} {...props} />
export const BackIcon = props => <InsIcon svg={BackSvg} {...props} />
export const EditIcon = props => <InsIcon svg={EditSvg} {...props} />
export const DeleteIcon = props => <InsIcon svg={DeleteSvg} {...props} />
export const HelpOutlineIcon = props => <InsIcon svg={HelpOutlineSvg} {...props} />
export const MenuIcon = props => <InsIcon svg={MenuIconSvg} {...props} />
export const CommentIcon = props => <InsIcon svg={CommentIconSvg} {...props} />
export const SubdirectoryArrowRightIcon = props => <InsIcon svg={SubdirectoryArrowRightSvg} {...props} />
export const TruckIcon = props => <InsIcon svg={TruckSvg} {...props} />
export const ReloadIcon = props => <InsIcon svg={ReloadSvg} {...props} />
export const CheckIcon = props => <InsIcon svg={CheckSvg} {...props} />
export const FileCopyIcon = props => <InsIcon svg={FileCopySvg} {...props} />
export const PrintIcon = props => <InsIcon svg={PrintSvg} {...props} />
export const DescriptionIcon = props => <InsIcon svg={DescriptionSvg} {...props} />
export const ReportProblemIcon = props => <InsIcon svg={ReportProblemSvg} {...props} />
export const AssignmentIcon = props => <InsIcon svg={AssignmentSvg} {...props} />
export const PreviewIcon = props => <InsIcon svg={PreviewSvg} {...props} />
export const LaunchIcon = props => <InsIcon svg={LaunchSvg} {...props} />
export const AccessTimeIcon = props => <InsIcon svg={AccessTimeSvg} {...props} />
export const OpenInNewIcon = props => <InsIcon svg={OpenInNewSvg} {...props} />
export const CompanySealIcon = props => <InsIcon svg={CompanySealSvg} {...props} />
export const LockIcon = props => <InsIcon svg={LockSvg} {...props} />
export const LockOpenIcon = props => <InsIcon svg={LockOpenSvg} {...props} />
