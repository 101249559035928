import React from 'react'
import { useParams } from 'react-router-dom'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InsBackLink from 'components/InsBackLink'
import SupplierDetailComponent from 'modules/master/supplier/SupplierDetailComponent'

const SupplierDetail = () => {
    const { id } = useParams()

    const deleteSupplier = async () => {
        await api.delete(APP_URLS.SUPPLIERS_DETAIL.replace(':id', id))
        history.push(Routes.private.master.SUPPLIER_LIST.path)
    }

    return (
        <div className="page-container with-sidebar mb-64">
            <InsBackLink />
            <SupplierDetailComponent id={id} onDelete={() => deleteSupplier()} />
        </div>
    )
}

export default SupplierDetail
