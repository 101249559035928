import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import InsInputSearch from 'components/InsInputSearch'
import InsCollapse from 'components/InsCollapse'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import moment from 'moment'
import { setKeywords, setFilters } from 'modules/business/summaryInvoice/summary_invoice.slice'

const SummaryInvoiceListSearch = ({
    keywords,
    setKeywords,
    onSearch,
    filters,
    getSummaryInvoices,
    pagination,
    isIssuedSearch,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const collapseRef = useRef()

    const createdAtChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            'created_at:gte': value[0] + ' 00:00:01',
            'created_at:lte': value[1] + ' 23:59:59',
        })
        dispatch(setFilters(newFilters))
        getSummaryInvoices(pagination, newFilters)
    }

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <div>
                    <InsDateRangePicker
                        className="mr-4"
                        value={[moment(filters['created_at:gte']), moment(filters['created_at:lte'])]}
                        onChange={(mm, value) => createdAtChangeHandler(value)}
                    />
                    <InsInputSearch
                        className="free-text-search"
                        placeholder={t(
                            isIssuedSearch
                                ? 'Customer name, matter number, subject, etc'
                                : 'Customer name, matter number, subject, bill date classification, etc'
                        )}
                        value={keywords}
                        onChange={values => setKeywords(values)}
                        onSearch={values => {
                            onSearch({
                                'keyword': values,
                                'created_at:gte': filters['created_at:gte'],
                                'created_at:lte': filters['created_at:lte'],
                            })
                        }}
                    />
                </div>
            }
            className="hide-expand"
        />
    )
}

const mapState = state => ({
    keywords: state.summaryInvoice.keywords,
    filters: state.summaryInvoice.filters,
})
const mapDispatch = { setKeywords }
export default connect(mapState, mapDispatch)(SummaryInvoiceListSearch)

SummaryInvoiceListSearch.propTypes = {
    keywords: PropTypes.array.isRequired,
    onSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    getSummaryInvoices: PropTypes.func.isRequired,
    isIssuedSearch: PropTypes.bool,
}
