import LoginPage from 'modules/common/auth/LoginPage'
import UserList from 'modules/master/user/UserList'
import AccountSetting from 'modules/master/account/AccountSetting'
import DisplaySetting from 'modules/master/account/DisplaySetting'
import CustomerList from 'modules/master/customer/CustomerList'
import CustomerCreate from 'modules/master/customer/CustomerCreate'
import CustomerUpdate from 'modules/master/customer/CustomerUpdate'
import CustomerCategory from 'modules/master/customer/CustomerCategory'
import OutsourceList from 'modules/master/outsource/OutsourceList'
import UserAuthority from 'modules/master/user/UserAuthority'
import Team from 'modules/master/user/Team'
import TeamMember from 'modules/master/user/TeamMember'
import UserCreate from 'modules/master/user/UserCreate'
import ButtonDemo from 'modules/demo/ButtonDemo'
import CheckboxDemo from 'modules/demo/CheckboxDemo'
import InputDemo from 'modules/demo/InputDemo'
import SelectDemo from 'modules/demo/SelectDemo'
import ModalDemo from 'modules/demo/ModalDemo'
import DropdownDemo from 'modules/demo/DropdownDemo'
import DatePickerDemo from 'modules/demo/DatePickerDemo'
import FloatBarDemo from 'modules/demo/FloatBarDemo'
import CollapseDemo from 'modules/demo/CollapseDemo'
import ProductCreate from 'modules/master/product/ProductCreate'
import ProductUpdate from 'modules/master/product/ProductUpdate'
import ProductDetail from 'modules/master/product/ProductDetail'
import TabDemo from 'modules/demo/TabDemo'
import CustomerDetail from 'modules/master/customer/CustomerDetail'
import OutsourceCreate from 'modules/master/outsource/OutsourceCreate'
import OutsourceDetail from 'modules/master/outsource/OutsourceDetail'
import OutsourceUpdate from 'modules/master/outsource/OutsourceUpdate'
import ClassificationList from 'modules/master/classification/ClassificationList'
import ClassificationCreate from 'modules/master/classification/ClassificationCreate'
import ProductList from 'modules/master/product/ProductList'
import ClassificationUpdate from 'modules/master/classification/ClassificationUpdate'
import ProductCostList from 'modules/master/productCost/ProductCostList'
import ProductCostCreate from 'modules/master/productCost/ProductCostCreate'
import ProductCostUpdate from 'modules/master/productCost/ProductCostUpdate'
import SupplierList from 'modules/master/supplier/SupplierList'
import SupplierCreate from 'modules/master/supplier/SupplierCreate'
import SupplierUpdate from 'modules/master/supplier/SupplierUpdate'
import SupplierDetail from 'modules/master/supplier/SupplierDetail'
import ShippingAddressList from 'modules/master/shippingAddress/ShippingAddressList'
import ShippingAddressDetail from 'modules/master/shippingAddress/ShippingAddressDetail'
import ShippingAddressCreate from 'modules/master/shippingAddress/ShippingAddressCreate'
import ShippingAddressUpdate from 'modules/master/shippingAddress/ShippingAddressUpdate'
// import ClientList from 'modules/master/client/ClientList'
// import ClientCreate from 'modules/master/client/ClientCreate'
// import ClientUpdate from 'modules/master/client/ClientUpdate'
// import ClientDetail from 'modules/master/client/ClientDetail'
import StockList from 'modules/master/stock/StockList'
import StockCreate from 'modules/master/stock/StockCreate'
import StockUpdate from 'modules/master/stock/StockUpdate'
import InstructionList from 'modules/business/instruction/InstructionList'
import InstructionCreate from 'modules/business/instruction/InstructionCreate'
import EstimationList from 'modules/business/estimation/EstimationList'
import EstimationPreview from 'modules/business/estimation/EstimationPreview'
import ProjectCreate from 'modules/business/project/ProjectCreate'
import ProjectUpdate from 'modules/business/project/ProjectUpdate'
import ProjectDetail from 'modules/business/project/ProjectDetail'
import ProjectList from 'modules/business/project/ProjectList'
import EstimationCreate from 'modules/business/estimation/EstimationCreate'
import EstimationUpdate from 'modules/business/estimation/EstimationUpdate'
import DraggableDemo from './modules/demo/DraggableDemo'
import InstructionUpdate from 'modules/business/instruction/InstructionUpdate'
import UserDetail from './modules/master/user/UserDetail'
import UserUpdate from './modules/master/user/UserUpdate'
import PlaceOrderList from 'modules/business/placeOrder/PlaceOrderList'
import InvoiceList from 'modules/business/invoice/InvoiceList'
import SummaryInvoiceList from 'modules/business/summaryInvoice/SummaryInvoiceList'
import SaleList from 'modules/business/sale/SaleList'
import SaleTab from './modules/business/sale/SaleTab'
import PlaceOrderTab from './modules/business/placeOrder/PlaceOrderTab'
import PaymentList from 'modules/business/payment/PaymentList'
import ShipmentList from 'modules/business/shipment/ShipmentList'
import RedSlipList from 'modules/business/redSlip/RedSlipList'
import ReportList from 'modules/business/report/ReportList'
import TeamList from 'modules/master/team/TeamList'
import TeamCreate from 'modules/master/team/TeamCreate'
import TeamUpdate from 'modules/master/team/TeamUpdate'
import CompanyList from 'modules/master/company/CompanyList'
import CompanyCreate from 'modules/master/company/CompanyCreate'
import CompanyUpdate from 'modules/master/company/CompanyUpdate'
import AccountSettingUpdate from 'modules/master/account/AccountSettingUpdate'
import ActionLogList from 'modules/business/actionLog/ActionLogList'

const Routes = {
    public: {
        LOGIN: {
            path: '/login',
            component: LoginPage,
        },
    },
    private: {
        master: {
            USER_LIST: {
                path: '/master/users',
                component: UserList,
            },
            USER_CREATE: {
                path: '/master/users/create',
                component: UserCreate,
            },
            USER_DETAIL: {
                path: '/master/users/:id',
                component: UserDetail,
            },
            USER_AUTHORITY: {
                path: '/master/users/authority',
                component: UserAuthority,
            },
            USER_TEAM: {
                path: '/master/users/teams',
                component: Team,
            },
            USER_TEAM_MEMBER: {
                path: '/master/users/teams/members',
                component: TeamMember,
            },
            USER_UPDATE: {
                path: '/master/users/update/:id',
                component: UserUpdate,
            },
            ACCOUNT_SETTING: {
                path: '/account/settings',
                component: AccountSetting,
            },
            ACCOUNT_SETTING_EDIT: {
                path: '/account/settings/edit',
                component: AccountSettingUpdate,
            },
            DISPLAY_SETTING: {
                path: '/master/account/displays',
                component: DisplaySetting,
            },
            CUSTOMER_LIST: {
                path: '/master/customers',
                component: CustomerList,
            },
            CUSTOMER_CREATE: {
                path: '/master/customers/create',
                component: CustomerCreate,
            },
            CUSTOMER_COPY: {
                path: '/master/customers/create/:id?',
                component: CustomerCreate,
            },
            CUSTOMER_UPDATE: {
                path: '/master/customers/update/:id',
                component: CustomerUpdate,
            },
            CUSTOMER_CATEGORY: {
                path: '/master/customers/categories',
                component: CustomerCategory,
            },
            CUSTOMER_DETAIL: {
                path: '/master/customers/:id',
                component: CustomerDetail,
            },
            OUTSOURCE_LIST: {
                path: '/master/outsources',
                component: OutsourceList,
            },
            OUTSOURCE_CREATE: {
                path: '/master/outsources/create',
                component: OutsourceCreate,
            },
            OUTSOURCE_UPDATE: {
                path: '/master/outsources/:id/update',
                component: OutsourceUpdate,
            },
            OUTSOURCE_DETAIL: {
                path: '/master/outsources/:id',
                component: OutsourceDetail,
            },
            CLASSIFICATION_LIST: {
                path: '/master/classifications',
                component: ClassificationList,
            },
            CLASSIFICATION_CREATE: {
                path: '/master/classifications/create',
                component: ClassificationCreate,
            },
            CLASSIFICATION_UPDATE: {
                path: '/master/classifications/:id/update',
                component: ClassificationUpdate,
            },
            TEAM_LIST: {
                path: '/master/teams',
                component: TeamList,
            },
            TEAM_CREATE: {
                path: '/master/teams/create',
                component: TeamCreate,
            },
            TEAM_UPDATE: {
                path: '/master/teams/update/:id',
                component: TeamUpdate,
            },
            COMPANY_LIST: {
                path: '/master/companies',
                component: CompanyList,
            },
            COMPANY_CREATE: {
                path: '/master/companies/create',
                component: CompanyCreate,
            },
            COMPANY_UPDATE: {
                path: '/master/companies/:id/update',
                component: CompanyUpdate,
            },
            PRODUCT_LIST: {
                path: '/master/products',
                component: ProductList,
            },
            PRODUCT_CREATE: {
                path: '/master/products/create',
                component: ProductCreate,
            },
            PRODUCT_UPDATE: {
                path: '/master/products/edit/:id',
                component: ProductUpdate,
            },
            PRODUCT_DETAIL: {
                path: '/master/products/:id',
                component: ProductDetail,
            },
            // PRODUCT_COST_LIST: {
            //     path: '/master/product-costs',
            //     component: ProductCostList,
            // },
            // PRODUCT_COST_CREATE: {
            //     path: '/master/product-costs/create',
            //     component: ProductCostCreate,
            // },
            // PRODUCT_COST_UPDATE: {
            //     path: '/master/product-costs/:id/update',
            //     component: ProductCostUpdate,
            // },
            SUPPLIER_LIST: {
                path: '/master/suppliers',
                component: SupplierList,
            },
            SUPPLIER_CREATE: {
                path: '/master/suppliers/create',
                component: SupplierCreate,
            },
            SUPPLIER_UPDATE: {
                path: '/master/suppliers/update/:id',
                component: SupplierUpdate,
            },
            SUPPLIER_DETAIL: {
                path: '/master/suppliers/:id',
                component: SupplierDetail,
            },
            SHIPPING_ADDRESS_LIST: {
                path: '/master/shipping_addresses',
                component: ShippingAddressList,
            },
            SHIPPING_ADDRESS_CREATE: {
                path: '/master/shipping_addresses/create',
                component: ShippingAddressCreate,
            },
            SHIPPING_ADDRESS_UPDATE: {
                path: '/master/shipping_addresses/edit/:id',
                component: ShippingAddressUpdate,
            },
            SHIPPING_ADDRESS_DETAIL: {
                path: '/master/shipping_addresses/:id',
                component: ShippingAddressDetail,
            },
            // CLIENT_LIST: {
            //     path: '/master/clients',
            //     component: ClientList,
            // },
            // CLIENT_CREATE: {
            //     path: '/master/clients/create',
            //     component: ClientCreate,
            // },
            // CLIENT_UPDATE: {
            //     path: '/master/clients/:id/update',
            //     component: ClientUpdate,
            // },
            // CLIENT_DETAIL: {
            //     path: '/master/clients/:id',
            //     component: ClientDetail,
            // },
            STOCK_LIST: {
                path: '/master/stocks',
                component: StockList,
            },
            STOCK_CREATE: {
                path: '/master/stocks/create',
                component: StockCreate,
            },
            STOCK_UPDATE: {
                path: '/master/stocks/edit/:id',
                component: StockUpdate,
            },
            ACTION_LOG: {
                path: '/master/action-logs',
                component: ActionLogList,
            },
        },
        business: {
            COMPONENT_BUTTON: {
                demo: true,
                path: '/demo/button',
                component: ButtonDemo,
            },
            COMPONENT_CHECKBOX: {
                demo: true,
                path: '/demo/checkbox',
                component: CheckboxDemo,
            },
            COMPONENT_INPUT: {
                demo: true,
                path: '/demo/input',
                component: InputDemo,
            },
            COMPONENT_SELECT: {
                demo: true,
                path: '/demo/select',
                component: SelectDemo,
            },
            COMPONENT_DROPDOWN: {
                demo: true,
                path: '/demo/dropdown',
                component: DropdownDemo,
            },
            COMPONENT_MODAL: {
                demo: true,
                path: '/demo/modal',
                component: ModalDemo,
            },
            COMPONENT_DATEPICKER: {
                demo: true,
                path: '/demo/datepicker',
                component: DatePickerDemo,
            },
            COMPONENT_FLOAT_BAR: {
                demo: true,
                path: '/demo/float-bar',
                component: FloatBarDemo,
            },
            COMPONENT_COLLAPSE: {
                demo: true,
                path: '/demo/collapse',
                component: CollapseDemo,
            },
            COMPONENT_TAB: {
                demo: true,
                path: '/demo/tab',
                component: TabDemo,
            },
            COMPONENT_DRAGGABLE: {
                demo: true,
                path: '/demo/draggable',
                component: DraggableDemo,
            },
            PROJECT_CREATE: {
                path: '/projects/create',
                component: ProjectCreate,
            },
            PROJECT_UPDATE: {
                path: '/projects/:id/update',
                component: ProjectUpdate,
            },
            PROJECT: {
                path: '/projects',
                component: ProjectList,
            },
            PROJECT_DETAIL: {
                path: '/projects/:id',
                component: ProjectDetail,
                exact: false,
            },
            INSTRUCTION: {
                path: '/instructions',
                component: InstructionList,
            },
            INSTRUCTION_UPDATE: {
                path: '/instructions/:id/update',
                component: InstructionUpdate,
            },
            INSTRUCTION_CREATE: {
                path: '/instructions/create',
                component: InstructionCreate,
            },
            ESTIMATION_PREVIEW: {
                path: '/estimations/:id/preview',
                component: EstimationPreview,
            },
            ESTIMATION_CREATE: {
                path: '/estimations/create',
                component: EstimationCreate,
            },
            ESTIMATION_UPDATE: {
                path: '/estimations/:id/update',
                component: EstimationUpdate,
            },
            ESTIMATION_DETAIL: {
                path: '/estimations/:id',
                component: EstimationPreview,
            },
            ESTIMATION: {
                path: '/estimations',
                component: EstimationList,
            },
            PLACE_ORDER: {
                path: '/place-orders',
                component: PlaceOrderList,
            },
            PLACE_ORDER_CREATE: {
                path: '/place-orders/create',
                component: PlaceOrderTab,
            },
            SALE: {
                path: '/sales',
                component: SaleList,
            },
            SALE_CREATE: {
                path: '/sales/create',
                component: SaleTab,
            },
            INVOICE: {
                path: '/invoices',
                component: InvoiceList,
            },
            SUMMARY_INVOICE: {
                path: '/summary-invoices',
                component: SummaryInvoiceList,
            },
            PAYMENT: {
                path: '/payments',
                component: PaymentList,
                exact: false,
            },
            SHIPMENT: {
                path: '/shipments',
                component: ShipmentList,
                exact: false,
            },
            RED_SLIP: {
                path: '/red-slips',
                component: RedSlipList,
            },
            TRACKING_CREATE: {
                path: '/trackings/create',
                component: SaleTab,
            },
            REPORT: {
                path: '/reports',
                component: ReportList,
            },
        },
    },
}

export default Routes
