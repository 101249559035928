import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import moment from 'moment'
import { PaymentType } from 'enums/payment.enum'

const name = 'payments'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.PAYMENTS, ...props })
)
const paymentSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: {
            'issued_date:gte': moment().subtract(1, 'months').format('YYYY/MM/DD'),
            'issued_date:lte': moment().add(2, 'months').format('YYYY/MM/DD'),
            'delay_status': 0,
            'is_purchase_order': false,
            'keyword': [],
            'money_received_status': false,
            'paid_status': false,
        },
        sorter: {
            order: 'descend',
            field: 'invoices.id',
        },
        paymentType: PaymentType.COLLECT.value,
    }),
    reducers: {
        ...baseReducers,
        setPaymentType(state, action) {
            state.paymentType = action.payload
        },
    },
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setSorter,
    setSelection,
    setPaymentType,
} = paymentSlice.actions

export default paymentSlice.reducer
