import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import InsFloatBar from 'components/InsFloatBar'
import FloatBarButtonPrint from 'modules/business/common/FloatBarButtonPrint'
import FloatBarButtonDelete from 'modules/business/common/FloatBarButtonDelete'
import FloatBarButtonEdit from 'modules/business/common/FloatBarButtonEdit'
import FloatBarButtonCopy from 'modules/business/common/FloatBarButtonCopy'
import BusinessPageEnum from 'enums/business_page.enum'

const FloatBarBehavior = ({ visible, data, selectedIds, setSelectedIds, documentType, fetchData }) => {
    const isSingleSelect = selectedIds.length === 1

    return (
        <>
            <InsFloatBar
                visible={visible && selectedIds.length > 0}
                className="project-list-float-bar justify-left"
                floatBarStyle="float"
            >
                <div className="pl-16 pr-16">
                    <Row>
                        <span>{'選択した見積書を'}</span>
                    </Row>
                    <Row className="mt-4">
                        <FloatBarButtonPrint
                            data={data}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            documentType={documentType}
                        />
                        <FloatBarButtonDelete
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            documentType={documentType}
                            fetchData={fetchData}
                        />
                        {isSingleSelect && (
                            <>
                                <FloatBarButtonEdit data={data} selectedIds={selectedIds} documentType={documentType} />
                                {documentType !== BusinessPageEnum.INVOICES.value &&
                                    documentType !== BusinessPageEnum.RED_SLIPS.value && (
                                        <FloatBarButtonCopy
                                            data={data}
                                            selectedIds={selectedIds}
                                            setSelectedIds={setSelectedIds}
                                            documentType={documentType}
                                            fetchData={fetchData}
                                        />
                                    )}
                            </>
                        )}
                    </Row>
                </div>
            </InsFloatBar>
        </>
    )
}

export default FloatBarBehavior

FloatBarBehavior.propTypes = {
    visible: PropTypes.bool,
    data: PropTypes.any,
    selectedIds: PropTypes.array,
    setSelectedIds: PropTypes.func,
    documentType: PropTypes.any,
    fetchData: PropTypes.func,
}
