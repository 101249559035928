import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import InsFloatBar from 'components/InsFloatBar'
import InsButton from 'components/InsButton'
import { DeleteIcon, EditIcon, ReportProblemIcon } from 'components/InsIcon'
import InsModal from 'components/InsModal'
import { useTranslation } from 'react-i18next'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'
import Routes from 'routes'
import InvoicingModal from 'modules/business/invoice/InvoicingModal'
import ReactToPrint from 'react-to-print'
import BusinessStatusEnum from 'enums/business_status.enum'
import { can } from 'helpers/permission.helper'
import InvoiceDOMPrint from 'modules/business/invoice/InvoiceDOMPrint'
import { dateToStr } from 'helpers/date.helper'

const InvoicePreview = ({ project, invoice, setInvoice, response, updateLink }) => {
    const { t } = useTranslation()
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
    const [modalInvoicingVisible, setModalInvoicingVisible] = useState(false)
    const canEditInvoice = can('INVOICE_EDITABLE') && !project.is_locked

    const componentRef = useRef()

    const deleteInvoice = async () => {
        await api.delete(APP_URLS.INVOICE_DETAIL.replace(':id', invoice.id))
        response()
        setModalDeleteVisible(false)
    }

    const updateStatusInvoice = async (status, updateFollowData) => {
        const data = await api.post(APP_URLS.INVOICE_UPDATE_STATUS, {
            status: status,
            invoice_id: invoice.id,
            ...updateFollowData,
        })
        response()
        // response(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) + '/invoices/preview')
    }

    const disabledByRedSlip = project.red_slip && project.red_slip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value
    const redSlipUrl = projectId => APP_URLS.PROJECT_DETAIL.replace(':id', projectId) + APP_URLS.RED_SLIP
    const projectsWithRedSlip = [...invoice.summary_invoice_projects, invoice.project].filter(
        p => p && p.red_slip && p.red_slip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value
    )
    const renderRedSlipCreatedMessage = () => {
        return (
            <>
                {projectsWithRedSlip.length > 0 && (
                    <div className="red-slip-created-message">
                        {projectsWithRedSlip.map((p, index) => (
                            <div key={p.id || index} className="red-slip-created-message-row">
                                <div className="red-slip-created-message-container">
                                    <ReportProblemIcon size={24} className="color-pantone-213-c mr-8" />
                                    <span>{`この伝票は「${p.red_slip.red_slip_code}」により${dateToStr(
                                        p.red_slip.red_slip_issued_date
                                    )}にキャンセルされました。`}</span>
                                    <InsButton
                                        buttonStyle="plain"
                                        buttonType="link"
                                        linkTo={redSlipUrl(p.id)}
                                        target="_blank"
                                    >
                                        {'赤伝を確認する'}
                                    </InsButton>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </>
        )
    }

    const isInvoiceBillIssued = invoice.status === BusinessStatusEnum.BILL_ISSUED.value
    const isInvoiceBillCreating = invoice.status === BusinessStatusEnum.BILL_CREATING.value

    return (
        <>
            <div className="page-container">
                {renderRedSlipCreatedMessage()}
                <InvoiceDOMPrint
                    project={project}
                    invoicePrint={invoice}
                    componentRef={componentRef}
                    showPreview={true}
                />

                {canEditInvoice && (
                    <InsFloatBar visible={true} className="with-sidebar">
                        <Row justify="space-between" align="middle">
                            <Col>
                                <InsButton
                                    disabled={isInvoiceBillIssued || disabledByRedSlip}
                                    icon={
                                        <DeleteIcon
                                            size={20}
                                            className={
                                                isInvoiceBillIssued || disabledByRedSlip ? '' : 'color-pantone-213-c'
                                            }
                                        />
                                    }
                                    buttonStyle="default"
                                    onClick={() => setModalDeleteVisible(true)}
                                    width={isInvoiceBillIssued || disabledByRedSlip ? '140px' : '112px'}
                                >
                                    {isInvoiceBillIssued || disabledByRedSlip ? t('請求書削除不可') : t('請求書削除')}
                                </InsButton>
                                <InsModal
                                    className="ins-modal-w-550"
                                    title="削除"
                                    visible={modalDeleteVisible}
                                    onCancel={() => setModalDeleteVisible(false)}
                                    onOk={deleteInvoice}
                                    okText="はい"
                                    cancelText="いいえ"
                                >
                                    <h3>{'請求書を削除してもよろしいですか？'}</h3>
                                </InsModal>
                                <InsButton
                                    disabled={isInvoiceBillIssued || disabledByRedSlip}
                                    className="ml-8"
                                    icon={
                                        <EditIcon
                                            size={20}
                                            className={
                                                isInvoiceBillIssued || disabledByRedSlip ? '' : 'color-pantone-2738-c'
                                            }
                                        />
                                    }
                                    buttonStyle="default"
                                    linkTo={updateLink}
                                    buttonType="link"
                                    width={isInvoiceBillIssued || disabledByRedSlip ? '140px' : '112px'}
                                >
                                    {isInvoiceBillIssued || disabledByRedSlip ? t('請求書編集不可') : t('請求書編集')}
                                </InsButton>
                            </Col>
                            <Col className="ins-flex flex-middle btn-update-project-bottom">
                                {isInvoiceBillCreating && (
                                    <InvoicingModal
                                        title="請求書発行"
                                        visible={modalInvoicingVisible}
                                        onCancel={() => setModalInvoicingVisible(false)}
                                        onOk={updateFollowData => {
                                            updateStatusInvoice(BusinessStatusEnum.BILL_ISSUED.value, updateFollowData)
                                        }}
                                        invoice={invoice}
                                        setInvoice={setInvoice}
                                        componentRef={componentRef}
                                    >
                                        <InsButton
                                            className="fw-bold"
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            style={{ width: 140 }}
                                            onClick={() => setModalInvoicingVisible(true)}
                                            disabled={disabledByRedSlip}
                                        >
                                            {t('請求書 発行')}
                                        </InsButton>
                                    </InvoicingModal>
                                )}
                                {isInvoiceBillIssued && (
                                    <ReactToPrint
                                        trigger={() => (
                                            <InsButton
                                                className="fw-bold"
                                                buttonStyle="outline"
                                                buttonSize="big"
                                                style={{ width: 140 }}
                                                disabled={disabledByRedSlip}
                                            >
                                                {t('請求書 再発行')}
                                            </InsButton>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                )}

                                {isInvoiceBillIssued && (
                                    <InsButton
                                        className="fw-500"
                                        buttonStyle={'outline-secondary'}
                                        buttonSize="big"
                                        style={{ width: 140 }}
                                        onClick={() => {
                                            !disabledByRedSlip &&
                                                updateStatusInvoice(BusinessStatusEnum.BILL_CREATING.value, {})
                                        }}
                                    >
                                        {t('請求書 取消')}
                                    </InsButton>
                                )}

                                <InsButton
                                    className="fw-bold"
                                    buttonStyle="secondary"
                                    buttonSize="big"
                                    style={{ width: 140 }}
                                    onClick={() => {
                                        history.push(
                                            Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) +
                                                '/red-slips'
                                        )
                                    }}
                                    disabled={disabledByRedSlip}
                                >
                                    {t('赤伝作成')}
                                </InsButton>
                            </Col>
                        </Row>
                    </InsFloatBar>
                )}
            </div>
        </>
    )
}

export default InvoicePreview

InvoicePreview.propTypes = {
    project: PropTypes.object.isRequired,
    invoice: PropTypes.object,
    setInvoice: PropTypes.func,
    updateLink: PropTypes.string,
    response: PropTypes.func,
}
