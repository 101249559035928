import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Tabs } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import history from 'helpers/history.helper'
import Routes from 'routes'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon } from 'components/InsIcon'
import PropTypes from 'prop-types'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'

const TeamListComponent = ({ inModal = false, onSelect, state, getData, setFilters, setSorter }) => {
    const { t } = useTranslation()
    const ref = useRef()
    const dispatch = useDispatch()

    const teamColumns = [
        {
            title: t('column:Team name'),
            dataIndex: 'team_name',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        if (inModal) {
                            onSelect(record)
                        } else {
                            history.push(Routes.private.master.TEAM_UPDATE.path.replace(':id', record.id))
                        }
                    }}
                >
                    {text}
                </InsButton>
            ),
        },
        { title: t('column:Team member amount'), dataIndex: 'users_count', sorter: true },
    ]

    const getTeams = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        getTeams()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            {!inModal && (
                <>
                    <Row justify="space-between">
                        <Col>
                            <h1 className="page-title mb-32">{t('navigation:Team management')}</h1>
                        </Col>
                        <Col>
                            <InsButton
                                width={120}
                                buttonType="link"
                                linkTo={Routes.private.master.TEAM_CREATE.path}
                                icon={<AddIcon size={24} />}
                                buttonStyle="secondary"
                            >
                                {t('navigation:Team create')}
                            </InsButton>
                        </Col>
                    </Row>
                </>
            )}

            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsTab
                        type="card"
                        metaComponent={
                            <InsPagination
                                {...state.pagination}
                                onChange={pagination => getTeams(pagination)}
                                pageSizeOptions={[100, 200, 300]}
                            />
                        }
                        className="hide-tabs"
                    >
                        <Tabs.TabPane tab={'placeholder'} key="0" />
                    </InsTab>
                    <InsDataTable
                        ref={r => (ref.current = r)}
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered={true}
                        data={state.data}
                        columns={teamColumns}
                        onChange={(pagination, filter, sorter) => {
                            getTeams(state.pagination, state.filters, sorter)
                        }}
                        hasSelection={true}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default TeamListComponent

TeamListComponent.propTypes = {
    inModal: PropTypes.bool,
    onSelect: PropTypes.func,
    state: PropTypes.shape(BaseStatePropShape),
    getData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
}

TeamListComponent.propTypes = {
    inModal: false,
}
