import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import SupplierListComponent from 'modules/master/supplier/SupplierListComponent'
import SupplierCreateComponent from 'modules/master/supplier/SupplierCreateComponent'
import SupplierDetailComponent from 'modules/master/supplier/SupplierDetailComponent'
import { getData, setFilters, setSorter } from 'modules/master/supplier/supplier_modal.slice'
import { Select } from 'antd'

const SupplierSelect = ({
    state,
    record,
    showListLink = true,
    showCreateButton = true,
    showDetailLink = true,
    ...rest
}) => {
    const { t } = useTranslation()
    let initialOptions = []
    if (record) {
        initialOptions.push({
            id: record.id,
            supplier_code: record.supplier_code,
            supplier_name: record.supplier_name,
        })
    }

    let listModal, createModal, detailModal
    if (showListLink) {
        listModal = {
            title: t('navigation:{{entity}} list', { entity: t('entity:Supplier') }),
            component: SupplierListComponent,
            props: {
                state,
                getData,
                setFilters,
                setSorter,
            },
        }
    }
    if (showCreateButton) {
        createModal = {
            title: t('navigation:{{entity}} create', { entity: t('entity:Supplier') }),
            component: SupplierCreateComponent,
        }
    }
    if (showDetailLink) {
        detailModal = {
            title: t('navigation:{{entity}} information', { entity: t('entity:Supplier') }),
            linkText: t('支払先情報'),
            component: SupplierDetailComponent,
        }
    }

    return (
        <InsRemoteSelect
            {...rest}
            url={`${APP_URLS.SUPPLIER}?page_size=100&is_enabled=1&supplier_info:like=`}
            initialOptions={initialOptions}
            optionIndexKey="id"
            optionValueKey="id"
            optionTextKey="supplier_name"
            optionLabelProp="label"
            optionRenderer={item => (
                <Select.Option key={item.id} value={item.id} item={item} label={item.supplier_name}>
                    <span style={{ fontWeight: 'bold' }}>{item.supplier_code}</span>
                    {'　'}
                    {item.supplier_name}
                </Select.Option>
            )}
            listModal={listModal}
            createModal={createModal}
            detailModal={detailModal}
        />
    )
}

const mapState = state => ({
    state: state.supplierModal,
})
export default connect(mapState)(SupplierSelect)

SupplierSelect.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.any,
        supplier_code: PropTypes.string,
        supplier_name: PropTypes.string,
    }),
    showListLink: PropTypes.bool,
    showCreateButton: PropTypes.bool,
    showDetailLink: PropTypes.bool,
    state: PropTypes.shape(BaseStatePropShape),
}
