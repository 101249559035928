import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import Routes from 'routes'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import { BackIcon, DeleteIcon, EditIcon, LaunchIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsHelpText from 'components/InsHelpText'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { InsBlockLoader } from 'components/InsLoader'
import InsModal from 'components/InsModal'
// import ClientDetailComponent from 'modules/master/client/ClientDetailComponent'
import { Link } from 'react-router-dom'

const ShippingAddressDetailComponent = ({ inModal = false, id, onDelete }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [checkDelete, setCheckDelete] = useState(false)
    const [shippingAddress, setShippingAddress] = useState(null)
    // const [modalClient, setModalClient] = useState(false)

    useEffect(() => {
        setLoading(true)
        const getShippingAddress = async id => {
            const data = await api.get(APP_URLS.SHIPPING_ADDRESS_DETAIL.replace(':id', id))
            if (data) {
                setShippingAddress(data)
                setLoading(false)
            }
        }

        getShippingAddress(id)
    }, [id])

    if (loading) {
        return <InsBlockLoader />
    }

    return (
        <>
            {!inModal && (
                <InsFloatBar visible={true}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <InsButton
                                width="76px"
                                icon={<DeleteIcon size={16} />}
                                onClick={() => setCheckDelete(true)}
                            >
                                {t('common:Delete')}
                            </InsButton>
                            <InsHelpText className="ml-8">
                                {t('Can not delete in-used {{item}}', { item: t('entity:ShippingAddress') })}
                            </InsHelpText>
                        </Col>

                        <Col className="ins-flex flex-middle">
                            <InsButton
                                className="fw-500 mr-8"
                                buttonType="link"
                                linkTo={Routes.private.master.SHIPPING_ADDRESS_LIST.path}
                                buttonStyle="outline"
                                buttonSize="big"
                                width="110px"
                                icon={<BackIcon />}
                            >
                                {t('common:Back to list')}
                            </InsButton>
                            <InsButton
                                className="fw-bold"
                                buttonType="link"
                                linkTo={Routes.private.master.SHIPPING_ADDRESS_UPDATE.path.replace(':id', id)}
                                buttonStyle="primary"
                                buttonSize="big"
                                icon={<EditIcon size={20} />}
                            >
                                {t('navigation:{{entity}} information edit', {
                                    entity: t('entity:ShippingAddress'),
                                })}
                            </InsButton>
                        </Col>
                    </Row>
                </InsFloatBar>
            )}

            {checkDelete && (
                <InsModal
                    title={t('common:Delete')}
                    visible={checkDelete}
                    onCancel={() => setCheckDelete(false)}
                    onOk={onDelete}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <h2>{t('common:Are you sure')}</h2>
                </InsModal>
            )}
            <div className="p-24">
                {!inModal && (
                    <h1 className="page-title-big">
                        {shippingAddress.shipping_address_name1}
                        <InsButton
                            buttonType="link"
                            buttonStyle="plain"
                            className="ml-8 color-blue-gray-200"
                            icon={<EditIcon size={24} />}
                            linkTo={Routes.private.master.SHIPPING_ADDRESS_UPDATE.path.replace(':id', id)}
                        />
                    </h1>
                )}

                <section>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('Shipping address code')}</th>
                                <td className="fw-bold color-blue-gray-900">{shippingAddress.shipping_address_code}</td>
                            </tr>
                            <tr>
                                <th>{t('Shipping address name1')}</th>
                                <td className="fw-bold color-blue-gray-900">
                                    {shippingAddress.shipping_address_name1}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('Shipping address name department1')}</th>
                                <td className="fw-bold color-blue-gray-900">
                                    {shippingAddress.shipping_address_name_department1}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('Shipping address name department2')}</th>
                                <td className="fw-bold color-blue-gray-900">
                                    {shippingAddress.shipping_address_name_department2}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('Shipping address title')}</th>
                                <td className="fw-bold color-blue-gray-900">
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.CUSTOMER_TITLE.value,
                                        shippingAddress.shipping_address_title
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('Post code')}</th>
                                <td className="fw-bold color-blue-gray-900">{shippingAddress.post_code}</td>
                            </tr>
                            <tr>
                                <th>{t('Address1')}</th>
                                <td className="fw-bold color-blue-gray-900">
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.PREFECTURE.value,
                                        shippingAddress.prefecture_code
                                    )}
                                    &nbsp;
                                    {shippingAddress.address1}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('Address2')}</th>
                                <td className="fw-bold color-blue-gray-900">{shippingAddress.address2}</td>
                            </tr>
                            <tr>
                                <th>{t('Phone number')}</th>
                                <td className="fw-bold color-blue-gray-900">{shippingAddress.phone_number}</td>
                            </tr>
                            <tr>
                                <th>{t('Fax number')}</th>
                                <td className="fw-bold color-blue-gray-900">{shippingAddress.fax_number}</td>
                            </tr>
                            {/*  <tr>
                                <th>{t('Client code')}</th>
                                <td>
                                    {
                                        <>
                                            <InsButton
                                                onClick={() => {
                                                    setModalClient(true)
                                                }}
                                                buttonStyle="plain"
                                                className={'fw-bold'}
                                            >
                                                {shippingAddress.client ? shippingAddress.client.client_name : null}
                                            </InsButton>
                                            <InsModal
                                                className="ins-detail-modal"
                                                width={760}
                                                visible={modalClient}
                                                onCancel={() => setModalClient(false)}
                                                footer={null}
                                            >
                                                <ClientDetailComponent
                                                    inModal={true}
                                                    id={shippingAddress.client ? shippingAddress.client.id : null}
                                                />
                                            </InsModal>
                                        </>
                                    }
                                </td>
                            </tr>*/}
                            <tr>
                                <th>{t('Direct delivery pic name')}</th>
                                <td className="fw-bold color-blue-gray-900">
                                    {shippingAddress.direct_delivery_pic_name}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('Usage status')}</th>
                                <td className="fw-bold color-blue-gray-900">
                                    {shippingAddress.is_enabled === 1 ? '有効' : '無効'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
            {inModal && (
                <Row justify="end">
                    <Link
                        to={Routes.private.master.SHIPPING_ADDRESS_DETAIL.path.replace(':id', shippingAddress.id)}
                        target="_blank"
                    >
                        <InsButton
                            icon={<LaunchIcon size={12} className="color-pantone-2738-c" />}
                            buttonStyle="default"
                        >
                            {t('Open new tab')}
                        </InsButton>
                    </Link>
                </Row>
            )}
        </>
    )
}

export default ShippingAddressDetailComponent

ShippingAddressDetailComponent.propTypes = {
    id: PropTypes.any.isRequired,
    inModal: PropTypes.bool,
    onDelete: PropTypes.func,
}

ShippingAddressDetailComponent.defaultProps = {
    inModal: false,
}
