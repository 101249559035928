import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import Routes from 'routes'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon } from 'components/InsIcon'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import {
    getData,
    setFilters,
    setPagination,
    setKeywords,
    setSorter,
} from 'modules/master/shippingAddress/shippingAddress.slice'
import ShippingAddressSearch from 'modules/master/shippingAddress/ShippingAddressListSearch'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'

const ShippingAddressList = ({ state }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const ShippingAddressColumns = [
        {
            title: t('column:Shipping address code'),
            dataIndex: 'shipping_address_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonType="link"
                    buttonStyle="plain"
                    linkTo={Routes.private.master.SHIPPING_ADDRESS_DETAIL.path.replace(':id', record.id)}
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: t('column:Shipping address name'),
            dataIndex: 'shipping_address_name1',
            sorter: true,
            render: (text, record) => (
                <div>
                    <span>{record.shipping_address_name1}</span>
                </div>
            ),
        },
        {
            title: t('column:Shipping address 1-2'),
            dataIndex: 'address1',
            sorter: true,
            render: (text, record) => (
                <span>
                    {getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, record.prefecture_code)}
                    &nbsp; {text}&nbsp;<span>{record.address2}</span>
                </span>
            ),
        },
        { title: t('column:Phone number'), dataIndex: 'phone_number', sorter: true },
        { title: t('column:Fax number'), dataIndex: 'fax_number', sorter: true },
        { title: t('column:Direct delivery pic name'), dataIndex: 'direct_delivery_pic_name', sorter: true },
    ]

    const updateFilters = obj => {
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getShippingAddress(newPagination, newFilters)
    }

    const tabChangeHandler = key => {
        dispatch(setKeywords([]))
        const newFilters = Object.assign({}, state.filters, {
            keyword: [],
            is_enabled: key,
        })
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setFilters(newFilters))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getShippingAddress(newPagination, newFilters, newSorter)
    }

    const getShippingAddress = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        getShippingAddress()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="page-container with-sidebar">
            <Row className="mt-8 mb-16" justify="space-between">
                <Col>
                    <h1 className="page-title mb-0">
                        {t('navigation:{{entity}} management', { entity: t('entity:ShippingAddress') })}
                    </h1>
                </Col>
                <Col>
                    <InsButton
                        width={120}
                        buttonType="link"
                        linkTo={Routes.private.master.SHIPPING_ADDRESS_CREATE.path}
                        icon={<AddIcon size={24} />}
                        buttonStyle="secondary"
                    >
                        {t('navigation:{{entity}} create', { entity: t('entity:ShippingAddress') })}
                    </InsButton>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <ShippingAddressSearch
                        onSearch={condition => updateFilters(condition)}
                        onReset={() => updateFilters({})}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsTab
                        type="card"
                        metaComponent={
                            <InsPagination
                                {...state.pagination}
                                onChange={pagination => getShippingAddress(pagination)}
                            />
                        }
                        onChange={key => tabChangeHandler(key)}
                        activeKey={state.filters.is_enabled.toString()}
                        defaultActiveKey="1"
                    >
                        <Tabs.TabPane tab={t('common:Enabled')} key="1" />
                        <Tabs.TabPane tab={t('common:Disabled')} key="0" />
                    </InsTab>
                    <InsDataTable
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered
                        data={state.data}
                        columns={ShippingAddressColumns}
                        onChange={(pagination, filter, sorter) => {
                            getShippingAddress(state.pagination, state.filters, sorter)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        ctlSortOrder={true}
                    />
                </Col>
            </Row>
        </div>
    )
}

const mapState = state => ({ state: state.shippingAddress })
export default connect(mapState)(ShippingAddressList)

ShippingAddressList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
}
