import React from 'react'
import { Col, Row } from 'antd'
import InsInput from 'components/InsInput'
import InsInputSearch from 'components/InsInputSearch'
import InsTextArea from 'components/InsTextArea'

const InputDemo = () => {
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">InsInput</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsInput placeholder="Normal input" />
                </Col>
                <Col>
                    <InsInput placeholder="Show maxlength" showMaxLength maxLength={40} />
                </Col>
                <Col>
                    <InsInput value="Disabled" disabled />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsInput type="number" placeholder="Number input" />
                </Col>
                <Col>
                    <InsInput showMaxLength maxLength={40} />
                </Col>
                <Col>
                    <InsInput defaultValue="Allow clear" allowClear={true} />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsInputSearch
                        style={{ width: 300 }}
                        onChange={values => console.log('Input search changed', values)}
                        onSearch={values => console.log('Search', values)}
                    />
                </Col>
                <Col>
                    <InsInputSearch
                        style={{ width: 300 }}
                        inputSize="big"
                        onChange={values => console.log('Input search changed', values)}
                        onSearch={values => console.log('Search', values)}
                    />
                </Col>
            </Row>
            <h1 className="page-title mt-32 mb-32">Textarea</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsTextArea />
                </Col>
            </Row>
        </div>
    )
}

export default InputDemo
