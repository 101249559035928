import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Checkbox } from 'antd'
import InsModal from 'components/InsModal'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import { dateToStr } from 'helpers/date.helper'
import { formatNumberComma } from 'helpers/number.helper'
import InsInputNumber from 'components/InsInputNumber'
import InsButton from 'components/InsButton'
import ReactToPrint from 'react-to-print'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import SummaryInvoiceDOMPrint from 'modules/business/summaryInvoice/SummaryInvoiceDOMPrint'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'

const SummaryInvoicingModal = ({
    title,
    children,
    visible,
    onCancel,
    componentRef,
    stat,
    selectedKeys,
    setSelectedKeys,
    getSummaryInvoices,
    reissueMode,
    companyInfo,
}) => {
    const [visibleStep2, setVisibleStep2] = useState(false)

    // Deprecation warning
    const dateFormat = 'YYYY/MM/DD'

    const checkboxGroup = [
        {
            text: '金額非表示',
            name: 'amountHidden',
        },
        {
            text: '社印表示',
            name: 'companySealDisplay',
            isChecked: true,
        },
        {
            text: '作業指示内容表示',
            name: 'workInstructionContentDisplay',
        },
    ]

    const [printOptions, setPrintOptions] = useState({
        amountHidden: false,
        companySealDisplay: true,
        workInstructionContentDisplay: false,
    })
    const [issuedDate, setIssuedDate] = useState(moment.utc(moment().format(dateFormat)))
    const [adjustAmount, setAdjustAmount] = useState(0)

    const renderPrintOptions = () => {
        let printOptionsFilter = Object.keys(printOptions).filter(key => printOptions[key])
        return printOptionsFilter.map(
            (key, index) =>
                checkboxGroup.find(item => item.name === key).text +
                (index !== printOptionsFilter.length - 1 ? '、' : '')
        )
    }

    const makeCompanyInfo = company => {
        let prefecture = getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, company.prefecture_code)
        return `〒${company.post_code}${prefecture}${company.address1}${company.address2 || ''}\nTEL：${
            company.phone_number
            // eslint-disable-next-line no-irregular-whitespace
        }　　FAX：${company.fax_number ? company.fax_number : ''}`
    }

    const [printInvoices, setPrintInvoices] = useState([])
    const createSummaryInvoice = async values => {
        // make sender
        values.sender_id = companyInfo.company_code
        values.sender_name = companyInfo.company_name
        values.sender_address = companyInfo.post_code ? makeCompanyInfo(companyInfo) : companyInfo.address1
        const data = await api.post(APP_URLS.SUMMARY_INVOICE, values)
        if (data) {
            setPrintInvoices(data.invoices || [])
        }
    }

    const reissueInvoices = async values => {
        const data = await api.post(APP_URLS.SUMMARY_INVOICE_REISSUE, values)
        if (data) {
            setPrintInvoices(data.invoices || [])
        }
    }

    return (
        <>
            <SummaryInvoiceDOMPrint
                printInvoices={printInvoices}
                componentRef={componentRef}
                companyInfo={companyInfo}
            />
            <InsModal
                className="invoicing-modal"
                title={title}
                width={'90%'}
                visible={visible}
                onCancel={onCancel}
                onOk={() => setVisibleStep2(true)}
            >
                <div className="invoicing-modal-inner">
                    <div className="inner-row">
                        <label>{'印刷オプション'}</label>
                        {checkboxGroup.map((checkbox, index) => (
                            <Checkbox
                                onChange={value =>
                                    setPrintOptions({ ...printOptions, [checkbox.name]: value.target.checked })
                                }
                                defaultChecked={checkbox.isChecked}
                                key={index}
                            >
                                {checkbox.text}
                            </Checkbox>
                        ))}
                    </div>
                    <div className="inner-row">
                        <label>{'請求書発行日'}</label>
                        <InsDatePicker
                            defaultValue={issuedDate}
                            format={dateFormat}
                            style={{ width: 180 }}
                            onChange={mm => setIssuedDate(mm)}
                        />
                    </div>
                    <div className="inner-row">
                        <label>{'調整額'}</label>
                        <InsInputNumber
                            className="no-spinner"
                            defaultValue={adjustAmount}
                            min={0}
                            style={{ width: 180 }}
                            onChange={value => setAdjustAmount(value)}
                        />
                        <span className="ext">{'円'}</span>
                    </div>
                </div>
                <div className="total-amount">
                    <div className="invoicing-selected-projects">
                        {'選択した案件　'}
                        <span className="color-pantone-213-c">{stat.countProjectSelected}</span>
                        {'件'}
                    </div>
                    <label>{'選択された金額合計'}</label>
                    <span>{formatNumberComma(stat.totalAmountSelected)}</span>
                </div>
            </InsModal>
            {children}
            <InsModal
                className="invoicing-modal confirm-step-2"
                title="請求書発行"
                width={'90%'}
                visible={visibleStep2}
                onCancel={() => {
                    setVisibleStep2(false)
                }}
                footer={[
                    <InsButton centered key="cancel" onClick={() => setVisibleStep2(false)} buttonStyle="outline">
                        {'キャンセル'}
                    </InsButton>,
                    <ReactToPrint
                        key="print"
                        trigger={() => (
                            <InsButton centered key="ok" buttonStyle="secondary">
                                {'請求書発行'}
                            </InsButton>
                        )}
                        onBeforePrint={() => {}}
                        content={() => componentRef.current}
                        onBeforeGetContent={() => {
                            let values = {
                                display_amount: !printOptions.amountHidden,
                                display_company_seal: printOptions.companySealDisplay,
                                display_instruction: printOptions.workInstructionContentDisplay,
                                issue_date: moment.utc(issuedDate).format('YYYY-MM-DD'),
                                adjust_amount: adjustAmount,
                            }
                            let summary_invoices = []
                            Object.keys(selectedKeys).map(k => {
                                summary_invoices.push({ customer_id: Number(k), project_ids: selectedKeys[Number(k)] })
                            })
                            values.summary_invoices = summary_invoices

                            onCancel()
                            setVisibleStep2(false)
                            return new Promise(resolve => {
                                createSummaryInvoice(values).then(() => {
                                    setTimeout(function () {
                                        resolve()
                                    }, 1000)
                                })
                            })
                        }}
                        onAfterPrint={() => {
                            setSelectedKeys({})
                            getSummaryInvoices()
                        }}
                    />,
                ]}
            >
                <div className="invoicing-modal-inner">
                    {Object.keys(printOptions).some(key => printOptions[key]) && (
                        <div className="inner-row">
                            <label>{'印刷オプション：'}</label>
                            <span>{renderPrintOptions()}</span>
                        </div>
                    )}
                    <div className="inner-row">
                        <label>{'発行日：'}</label>
                        <span>{dateToStr(issuedDate, dateFormat)}</span>
                    </div>
                    <div className="inner-row">
                        <label>{'調整額：'}</label>
                        <span>{`${formatNumberComma(adjustAmount)}円`}</span>
                    </div>
                </div>
                <div className="step-2-info summary-step-2-info">
                    <div>
                        {'選択した'}
                        <span className="color-pantone-213-c fw-bold">{stat.countProjectSelected}</span>
                        {'件の案件を上記の内容でまとめて請求書を発行します。'}
                    </div>
                    <div>{'よろしいですか？'}</div>
                </div>
            </InsModal>
            {reissueMode && (
                <ReactToPrint
                    key="print"
                    trigger={() => (
                        <InsButton
                            className="fw-bold pl-16 pr-16"
                            buttonStyle="primary"
                            buttonSize="big"
                            disabled2={!Object.keys(selectedKeys).length}
                        >
                            {'請求書再発行'}
                        </InsButton>
                    )}
                    onBeforePrint={() => {}}
                    content={() => componentRef.current}
                    onBeforeGetContent={() => {
                        let summary_invoices = []
                        Object.keys(selectedKeys).map(k => {
                            summary_invoices.push(Number(k))
                        })
                        return new Promise(resolve => {
                            reissueInvoices({ summary_invoices }).then(() => {
                                setTimeout(function () {
                                    resolve()
                                }, 1000)
                            })
                        })
                    }}
                    onAfterPrint={() => {
                        setSelectedKeys({})
                        getSummaryInvoices()
                    }}
                />
            )}
        </>
    )
}

export default SummaryInvoicingModal

SummaryInvoicingModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    componentRef: PropTypes.any,
    stat: PropTypes.object,
    selectedKeys: PropTypes.object,
    setSelectedKeys: PropTypes.func,
    getSummaryInvoices: PropTypes.func,
    reissueMode: PropTypes.bool,
    companyInfo: PropTypes.any,
}
