import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Routes from 'routes'
import Sidebar from 'modules/common/ui/Sidebar'

const MasterLayout = () => {
    return (
        <React.Fragment>
            <Sidebar />
            <Switch>
                {Object.keys(Routes.private.master).map(key => {
                    const r = Routes.private.master[key]
                    return <Route key={`master-${key}`} path={r.path} exact component={r.component} />
                })}

                <Route path="*">
                    <h1>Not found</h1>
                </Route>
            </Switch>
        </React.Fragment>
    )
}

export default MasterLayout
