import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Select } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsInput from 'components/InsInput'
import InsButton from 'components/InsButton'
import InsCollapse from 'components/InsCollapse'
import {
    setDetailSearch,
    setKeywords,
    updateDetailSearch,
    setFilters,
} from 'modules/business/instruction/instruction.slice'
import InsSelect from 'components/select/InsSelect'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import BusinessSelect from 'components/select/BusinessSelect'
import BusinessPageEnum from 'enums/business_page.enum'
import { getClassificationsByType } from 'helpers/classification.helper'
// import ClientSelect from 'modules/master/client/ClientSelect'

const InstructionListSearch = ({
    keywords,
    detailSearch,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    onSearch,
    onReset,
    filters,
    getInstruction,
    pagination,
}) => {
    const { t } = useTranslation()
    const collapseRef = useRef()
    const dispatch = useDispatch()

    const instructionStatus = getClassificationsByType(ClassificationTypeEnum.BUSINESS_STATUS.value).filter(status =>
        status.value.startsWith(BusinessPageEnum.INSTRUCTIONS.value)
    )

    const createdAtChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            'created_at:gte': value[0] + ' 00:00:00',
            'created_at:lte': value[1] + ' 23:59:59',
        })
        dispatch(setFilters(newFilters))
        getInstruction(pagination, newFilters)
    }

    const statusChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            status: value,
        })
        dispatch(setFilters(newFilters))
        getInstruction(pagination, newFilters)
    }

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <>
                    <BusinessSelect pageId={BusinessPageEnum.INSTRUCTIONS.value} />

                    <InsSelect
                        className="mr-4"
                        value={filters.status}
                        onSelect={value => statusChangeHandler(value)}
                        dropdownInnerStyle={{ minWidth: 150 }}
                    >
                        <Select.Option value={'0'}>全てのステータス</Select.Option>
                        {instructionStatus.map(s => (
                            <Select.Option key={s.value} value={s.value} title={s.text}>
                                {s.text}
                            </Select.Option>
                        ))}
                    </InsSelect>

                    <InsDateRangePicker
                        className="mr-8"
                        value={[moment.utc(filters['created_at:gte']), moment.utc(filters['created_at:lte'])]}
                        onChange={(mm, value) => createdAtChangeHandler(value)}
                    />

                    <InsInputSearch
                        className="free-text-search"
                        placeholder={t('Number instruction, customer name etc')}
                        value={keywords}
                        onChange={values => setKeywords(values)}
                        onSearch={values => {
                            // setDetailSearch({})
                            onSearch({
                                'keyword': values,
                                'created_at:gte': filters['created_at:gte'],
                                'created_at:lte': filters['created_at:lte'],
                            })
                        }}
                    />
                </>
            }
            className="mb-16"
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Instruction classification')}</strong>
                </Col>
                <Col>
                    <ClassificationSelect
                        className="mr-16"
                        typeEnum={ClassificationTypeEnum.INSTRUCTION}
                        showCreateButton={false}
                        style={{ width: 150 }}
                        onChange={value => updateDetailSearch({ field: 'instruction_classification', value })}
                        value={detailSearch['instruction_classification']}
                    />
                </Col>
                <Col flex={'100px'}>
                    <strong>{t('Customer pic name')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: 122 }}
                        onChange={value => updateDetailSearch({ field: 'customer_pic_name:like', value })}
                        value={detailSearch['customer_pic_name:like']}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Arrival date')}</strong>
                </Col>
                <Col>
                    <InsDateRangePicker
                        className="mr-16"
                        value={[
                            detailSearch['arrival_date:gte'] ? moment(detailSearch['arrival_date:gte']) : '',
                            detailSearch['arrival_date:lte'] ? moment(detailSearch['arrival_date:lte']) : '',
                        ]}
                        onChange={(mm, value) => {
                            updateDetailSearch({ field: 'arrival_date:gte', value: value[0] })
                            updateDetailSearch({ field: 'arrival_date:lte', value: value[1] })
                        }}
                    />
                </Col>
                <Col flex={'80px'}>
                    <strong>{t('column:Processing arrival date')}</strong>
                </Col>
                <Col>
                    <InsDateRangePicker
                        className="mr-8"
                        value={[
                            detailSearch['processing_arrival_date:gte']
                                ? moment(detailSearch['processing_arrival_date:gte'])
                                : '',
                            detailSearch['processing_arrival_date:lte']
                                ? moment(detailSearch['processing_arrival_date:lte'])
                                : '',
                        ]}
                        onChange={(mm, value) => {
                            updateDetailSearch({ field: 'processing_arrival_date:gte', value: value[0] })
                            updateDetailSearch({ field: 'processing_arrival_date:lte', value: value[1] })
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Delivery date')}</strong>
                </Col>
                <Col>
                    <InsDateRangePicker
                        className="mr-16"
                        value={[
                            detailSearch['delivery_date:gte'] ? moment(detailSearch['delivery_date:gte']) : '',
                            detailSearch['delivery_date:lte'] ? moment(detailSearch['delivery_date:lte']) : '',
                        ]}
                        onChange={(mm, value) => {
                            updateDetailSearch({ field: 'delivery_date:gte', value: value[0] })
                            updateDetailSearch({ field: 'delivery_date:lte', value: value[1] })
                        }}
                    />
                </Col>
                <Col flex={'80px'}>
                    <strong>{t('column:Scheduled arrival date')}</strong>
                </Col>
                <Col>
                    <InsDateRangePicker
                        className="mr-8"
                        value={[
                            detailSearch['scheduled_arrival_date:gte']
                                ? moment(detailSearch['scheduled_arrival_date:gte'])
                                : '',
                            detailSearch['scheduled_arrival_date:lte']
                                ? moment(detailSearch['scheduled_arrival_date:lte'])
                                : '',
                        ]}
                        onChange={(mm, value) => {
                            updateDetailSearch({ field: 'scheduled_arrival_date:gte', value: value[0] })
                            updateDetailSearch({ field: 'scheduled_arrival_date:lte', value: value[1] })
                        }}
                    />
                </Col>
            </Row>
            {/*    <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Shipper')}</strong>
                </Col>
                <Col>
                    <ClientSelect
                        placeholder={t('Shipper search')}
                        style={{ width: 180 }}
                        onChange={value => updateDetailSearch({ field: 'client_id', value })}
                        showListLink={false}
                        showCreateButton={false}
                        showDetailLink={false}
                        value={detailSearch['client_id']}
                    />
                </Col>
            </Row>*/}
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    keywords: state.instruction.keywords,
    detailSearch: state.instruction.detailSearch,
    filters: state.instruction.filters,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(InstructionListSearch)

InstructionListSearch.propTypes = {
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    getInstruction: PropTypes.func.isRequired,
}
