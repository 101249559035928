import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InsButton from 'components/InsButton'
import { BackIcon } from 'components/InsIcon'
import history from '../helpers/history.helper'

const InsBackLink = ({ className, ...rest }) => {
    const { t } = useTranslation()
    let backLinkClassName = 'ins-back-link '
    if (className) {
        backLinkClassName += className
    }
    return (
        <InsButton
            {...rest}
            className={backLinkClassName}
            buttonStyle="plain"
            icon={<BackIcon size={24} />}
            onClick={() => history.goBack()}
        >
            {t('common:Back')}
        </InsButton>
    )
}

export default InsBackLink

InsBackLink.propTypes = {
    className: PropTypes.string,
}
