export const isEmpty = str => {
    return typeof str === 'undefined' || str === null || str === ''
}

// year_on_year: 2019-08 => 2020-08; 2019 => 2020
export const dataIndexNextYear = (str, is_metric_period_month) => {
    if (is_metric_period_month) {
        let intYear = parseInt(str.substring(0, 4))
        let intMonth = parseInt(str.substring(5, 7))
        if (intMonth === 1) {
            return intYear - 1 + '/12'
        }
        return intYear + '/' + (intMonth - 1 < 10 ? '0' + (intMonth - 1) : intMonth - 1)
    } else {
        let substr = str.substring(0, 4)
        return str.replace(substr, Number(substr) - 1)
    }
}

export const rdStr = (len = 4) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < len; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}
