import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Radio, Row, Select } from 'antd'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import InsSelect from 'components/select/InsSelect'
import InsHelpText from 'components/InsHelpText'
import { AddIcon, CloseIcon, EditIcon } from 'components/InsIcon'
import UsageStatusEnum from 'enums/usage_status.enum'
import { getClassificationsByType } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsPostCodeInput from 'components/InsPostCodeInput'
import InsInput from 'components/InsInput'
import InsTextArea from 'components/InsTextArea'
import { CollectionScheduleDayEnum, CollectionScheduleMonthEnum } from 'enums/collection_schedule.enum'
import { PAYMENT_SCHEDULE } from 'constants/classification.constant'
import history from 'helpers/history.helper'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import InsInputNumber from 'components/InsInputNumber'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import { SupplierBillAddressEnum } from 'enums/bill_address.enum'
import SupplierSelect from 'modules/master/supplier/SupplierSelect'
import InsModal from 'components/InsModal'

const SupplierForm = ({
    isEdit,
    initialValues = {},
    onCancel,
    onSubmit,
    inModal = false,
    modalConfirmSave,
    setModalConfirmSave,
}) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const clfCollectionScheduleType = getClassificationsByType(ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value)
    const clfCollectionScheduleMethod = getClassificationsByType(
        ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value
    )

    const clfPaymentDate = getClassificationsByType(ClassificationTypeEnum.PAYMENT_DATE.value)

    const convertToKana = async value => {
        const data = await api.get(APP_URLS.UTIL_CONVERT_KANA.replace(':value', value.target.value))
        if (data) {
            form.setFieldsValue({ supplier_name_kana: data })
        }
    }

    initialValues = Object.assign(
        {
            payment_schedule_payment_type: PAYMENT_SCHEDULE.TYPE_DEFAULT,
            payment_schedule_payment_method: PAYMENT_SCHEDULE.METHOD_DEFAULT,
            payment_schedule_month: CollectionScheduleMonthEnum.NEXT_MONTH.value,
            payment_schedule_day: '25',
            phone_number: '',
            supplier_bill_address_type: SupplierBillAddressEnum.SAME_AS_SUPPLIER_NAME.value,
        },
        initialValues
    )

    if (initialValues.id && initialValues.payee_id !== initialValues.id) {
        initialValues.supplier_bill_address_type = SupplierBillAddressEnum.SET_SEPARATELY.value
    }

    const [supplierBillAddressType, setSupplierBillAddressType] = useState(initialValues.supplier_bill_address_type)

    initialValues.payment_schedule_month_custom =
        [
            CollectionScheduleMonthEnum.CURRENT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_NEXT_MONTH.value,
        ].indexOf(initialValues.payment_schedule_month) > -1
            ? initialValues.payment_schedule_month
            : CollectionScheduleMonthEnum.DIRECT_SETTING.value
    const [paymentScheduleMonthCustom, setPaymentScheduleMonthCustom] = useState(
        initialValues.payment_schedule_month_custom
    )

    initialValues.payment_schedule_day_custom =
        initialValues.payment_schedule_day === CollectionScheduleDayEnum.END_OF_THE_MONTH.value
            ? initialValues.payment_schedule_day
            : CollectionScheduleDayEnum.DATE_SELECTION.value
    const [paymentScheduleDayCustom, setPaymentScheduleDayCustom] = useState(initialValues.payment_schedule_day_custom)
    const processOnSubmit = (values, redirectTo) => {
        onSubmit(values, redirectTo)
    }

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => {
                        onSubmit(form.getFieldsValue(), null, false)
                        !isEdit && form.resetFields()
                    }}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className="ins-form pt-0"
                layout="vertical"
                form={form}
                size={'middle'}
                onFinish={values => processOnSubmit(values, null, false)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <div className="ins-form-items">
                    <h2 className="section-title mb-24">{t('common:Basic information')}</h2>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="supplier_code"
                                label={t('Supplier management code')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter supplier management code'),
                                    },
                                    {
                                        validator: async (rule, value) => {
                                            if (isEdit && initialValues.supplier_code.trim() == value.trim()) {
                                                return Promise.resolve()
                                            }

                                            if (value) {
                                                const data = await api.post(APP_URLS.SUPPLIERS_CHECK_EXISTS_CODE, {
                                                    supplier_code: value,
                                                })
                                                if (data && data.is_existed) {
                                                    return Promise.reject(t('validation:Supplier code existed'))
                                                }
                                            }
                                        },
                                    },
                                ]}
                                validateTrigger={['onBlur']}
                            >
                                <InsInput style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="supplier_name"
                                label={t('column:Supplier name')}
                                onBlur={convertToKana}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter supplier name'),
                                    },
                                ]}
                            >
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="supplier_name_kana" label={t('column:Supplier name kana')}>
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="supplier_pic_name" label={t('column:Supplier pic name')}>
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="supplier_title" label={t('column:Supplier title')}>
                                <ClassificationSelect
                                    showCreateButton={false}
                                    typeEnum={ClassificationTypeEnum.CUSTOMER_TITLE}
                                    style={{ width: 140 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="is_enabled" label={t('Usage status')}>
                                <Radio.Group>
                                    {Object.values(UsageStatusEnum).map(status => (
                                        <Radio key={status.value} value={status.value}>
                                            {t(`enum:${status.text}`)}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="post_code"
                                label={t('column:Post code')}
                                extra={
                                    <InsHelpText>
                                        {t('Please enter half size number in this format 000-0000')}
                                    </InsHelpText>
                                }
                            >
                                <InsPostCodeInput
                                    onSearchSuccess={data => {
                                        if (data.prefecture_code) {
                                            form.setFieldsValue({ prefecture_code: data.prefecture_code })
                                        }
                                        if (data.address) {
                                            form.setFieldsValue({ address1: data.address })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="prefecture_code" label={t('column:Address')} className="mb-4">
                                <InsSelect onSelect={value => console.log(value)} style={{ width: 140 }}>
                                    {getClassificationsByType(ClassificationTypeEnum.PREFECTURE.value).map(c => (
                                        <Select.Option key={c.value} value={c.value}>
                                            {c.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                            <Form.Item name="address1" className="mb-8">
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                            <Form.Item name="address2">
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item
                                name="phone_number"
                                label={t('column:Phone number')}
                                extra={<InsHelpText>{t('Help Phone number')}</InsHelpText>}
                                rules={[
                                    {
                                        pattern: /^[0-9]?([-]?[\s]?[0-9])+$/,
                                        message: t('validation:Please enter half-width numbers'),
                                    },
                                ]}
                                validateTrigger={['onBlur']}
                            >
                                <InsInput placeholder={'0000-0000-0000'} style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="fax_number" label={t('column:Fax number')}>
                                <InsInput style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="ml-16">
                        <Col>
                            <Form.Item name="note" label={t('column:Note')} className="mb-24">
                                <InsTextArea style={{ width: 280 }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <>
                        <h2 className="section-title mb-24">{t('common:Payment information')}</h2>
                        {/*<Row className="ml-16">*/}
                        {/*    <Form.Item name="payee_id" label={t('Payment destination')}>*/}
                        {/*        <UserSelect*/}
                        {/*            placeholder="仕入名で検索"*/}
                        {/*            record={initialValues.payee}*/}
                        {/*            style={{ width: 280 }}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Row>*/}
                        <Row className="ml-16 mb-28">
                            <Col>
                                <Form.Item name="supplier_bill_address_type" label={t('支払先情報')} className="mb-4">
                                    <InsSelect
                                        onSelect={value => {
                                            setSupplierBillAddressType(value)
                                            !isEdit && form.setFieldsValue({ payee_id: undefined })
                                        }}
                                        style={{ width: 140 }}
                                    >
                                        {Object.values(SupplierBillAddressEnum).map(m => (
                                            <Select.Option key={m.value} value={m.value}>
                                                {m.text}
                                            </Select.Option>
                                        ))}
                                    </InsSelect>
                                </Form.Item>
                                {supplierBillAddressType === SupplierBillAddressEnum.SET_SEPARATELY.value && (
                                    <Form.Item name="payee_id" className="mb-4">
                                        <SupplierSelect
                                            record={initialValues.payee}
                                            style={{ width: 280 }}
                                            placeholder={t('Supplier search')}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>

                        {supplierBillAddressType !== SupplierBillAddressEnum.SET_SEPARATELY.value && (
                            <>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="payment_date_classification"
                                            label={t('column:Payment date classification')}
                                        >
                                            <InsSelect style={{ width: 140 }}>
                                                {clfPaymentDate.map(clf => (
                                                    <Select.Option key={clf.item_value} value={clf.item_value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="payment_schedule_month_custom"
                                            label={t('column:Collection schedule')}
                                            className="mr-4"
                                        >
                                            <InsSelect
                                                onSelect={value => {
                                                    form.setFieldsValue({
                                                        payment_schedule_month:
                                                            value === CollectionScheduleMonthEnum.DIRECT_SETTING.value
                                                                ? 3
                                                                : value,
                                                    })
                                                    setPaymentScheduleMonthCustom(value)
                                                }}
                                                style={{ width: 140 }}
                                            >
                                                {Object.values(CollectionScheduleMonthEnum).map(m => (
                                                    <Select.Option key={m.value} value={m.value}>
                                                        {m.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        style={{
                                            display:
                                                paymentScheduleMonthCustom ===
                                                CollectionScheduleMonthEnum.DIRECT_SETTING.value
                                                    ? 'unset'
                                                    : 'none',
                                        }}
                                    >
                                        <Form.Item
                                            name="payment_schedule_month"
                                            label={' '}
                                            className="mr-4 hide-require fix-schedule-style"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'validation:Please enter customer collection schedule month'
                                                    ),
                                                },
                                            ]}
                                            validateTrigger={['onBlur']}
                                        >
                                            <InsInputNumber
                                                className="no-spinner"
                                                min={0}
                                                max={127}
                                                style={{ width: 70 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="payment_schedule_day_custom" label={' '} className="mr-4">
                                            <InsSelect
                                                onSelect={value => {
                                                    form.setFieldsValue({
                                                        payment_schedule_day:
                                                            value === CollectionScheduleDayEnum.DATE_SELECTION.value
                                                                ? 1
                                                                : value,
                                                    })
                                                    setPaymentScheduleDayCustom(value)
                                                }}
                                                style={{ width: 140 }}
                                            >
                                                {Object.values(CollectionScheduleDayEnum).map(d => (
                                                    <Select.Option key={d.value} value={d.value}>
                                                        {d.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        style={{
                                            display:
                                                paymentScheduleDayCustom ===
                                                CollectionScheduleDayEnum.DATE_SELECTION.value
                                                    ? 'unset'
                                                    : 'none',
                                        }}
                                    >
                                        <Form.Item
                                            name="payment_schedule_day"
                                            label={' '}
                                            className="hide-require"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'validation:Please enter customer collection schedule day'
                                                    ),
                                                },
                                            ]}
                                        >
                                            <InsInputNumber
                                                className="no-spinner"
                                                min={1}
                                                max={31}
                                                style={{ width: 70 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="payment_schedule_payment_type"
                                            label={t('column:Collection type')}
                                        >
                                            <InsSelect style={{ width: 140 }}>
                                                {clfCollectionScheduleType.map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="ml-16">
                                    <Col>
                                        <Form.Item
                                            name="payment_schedule_payment_method"
                                            label={t('column:Collection method')}
                                        >
                                            <InsSelect style={{ width: 140 }}>
                                                {clfCollectionScheduleMethod.map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Row className="ml-16">
                            <Form.Item name="bank_transfer1_name" label={t('column:Bank transfer name')}>
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Row>
                        <Row className="ml-16" style={{ marginBottom: 60 }}>
                            <Form.Item name="bank_transfer1_name_kana" label={t('column:Bank transfer name kana')}>
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Row>
                    </>
                </div>
                <InsFloatBar visible={true}>
                    <Row gutter={8} justify={inModal ? 'center' : 'end'}>
                        <Col>
                            <InsButton
                                buttonStyle="outline"
                                buttonSize="big"
                                centered
                                width="120px"
                                icon={<CloseIcon />}
                                onClick={() => {
                                    if (!inModal) {
                                        history.goBack()
                                    } else {
                                        onCancel && onCancel()
                                    }
                                }}
                            >
                                {t('common:Cancel')}
                            </InsButton>
                        </Col>
                        <Col>
                            <InsButton
                                buttonStyle="primary"
                                buttonSize="big"
                                centered
                                icon={isEdit ? <EditIcon size={20} /> : <AddIcon />}
                                isSubmitButton={true}
                            >
                                {isEdit
                                    ? t('navigation:{{entity}} update new', { entity: t('entity:Supplier') })
                                    : t('navigation:{{entity}} create new', { entity: t('entity:Supplier') })}
                            </InsButton>
                        </Col>
                    </Row>
                </InsFloatBar>
            </Form>
        </>
    )
}

export default SupplierForm

SupplierForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    inModal: PropTypes.bool,
    onCancel: PropTypes.func,
}
