import React from 'react'
import { useTranslation } from 'react-i18next'
import InsBackLink from 'components/InsBackLink'
import SupplierCreateComponent from 'modules/master/supplier/SupplierCreateComponent'

const SupplierCreate = () => {
    const { t } = useTranslation()

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} create', { entity: t('entity:Supplier') })}
            </h1>
            <SupplierCreateComponent />
        </div>
    )
}

export default SupplierCreate
