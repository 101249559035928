import React, { useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Table } from 'antd'
import InsExpandIcon from 'components/datatable/InsExpandIcon'
import { SorterPropTypes } from 'constants/proptypes.constant'
import { DescriptionIcon, SearchIcon } from 'components/InsIcon'

const { Column } = Table

const TableStyles = {
    NORMAL: 'normal',
    COMPACT: 'compact',
}

const InsDataTable = (
    {
        tableStyle = TableStyles.NORMAL,
        striped = false,
        bordered = false,
        columns,
        data,
        sorter,
        onChange,
        hasSelection,
        onSelectionChanged,
        expandable,
        selectionType,
        locale,
        subSelectedKeys,
        rowKey,
        customClassName = '',
        checkboxProps = () => {},
        ctlSortOrder = false,
        ...rest
    },
    ref
) => {
    const { t } = useTranslation()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    let rowSelection = null

    useImperativeHandle(ref, () => ({
        resetSelectedRowKeys() {
            setSelectedRowKeys([])
        },
    }))

    if (hasSelection && onSelectionChanged) {
        rowSelection = {
            type: selectionType,
            selectedRowKeys,
            onChange: keys => {
                setSelectedRowKeys(keys)
                onSelectionChanged(keys)
            },
            getCheckboxProps: checkboxProps,
        }
    }

    useEffect(() => {
        setSelectedRowKeys(subSelectedKeys)
    }, [subSelectedKeys])

    let className = customClassName + ' ins-datatable '
    if (striped) {
        className += 'ins-datatable-striped '
    }
    if (tableStyle === TableStyles.COMPACT) {
        className += 'ins-datatable-compact '
    }

    const getColumnSorter = field => {
        if (!sorter || sorter.field !== field) return null
        return sorter.order
    }

    let children = []
    for (const c of columns) {
        children.push(
            <Column
                className={c.className}
                width={c.width}
                key={c.dataIndex}
                title={t(c.title)}
                dataIndex={c.dataIndex}
                filters={c.filters}
                sorter={c.sorter}
                render={c.render}
                defaultSortOrder={getColumnSorter(c.dataIndex)}
                {...(ctlSortOrder && { sortOrder: getColumnSorter(c.dataIndex) })}
            />
        )
    }

    return (
        <Table
            {...rest}
            className={className}
            locale={
                locale ?? {
                    emptyText: (
                        <Row justify="center" className="ins-empty-text" align="middle" style={{ height: 300 }}>
                            <div className="circle">
                                <Row justify="center" align="middle" className="icon">
                                    <div className="icon">
                                        <DescriptionIcon size={54} className="description-icon" />
                                        <SearchIcon size={30} className="search-icon" />
                                    </div>
                                </Row>
                                <Row justify="center" align="middle" className="text">
                                    <h4>{'データがありません'}</h4>
                                </Row>
                            </div>
                        </Row>
                    ),
                }
            }
            bordered={bordered}
            size="middle"
            rowSelection={rowSelection}
            rowKey={record => (rowKey ? rowKey(record) : record.id)}
            dataSource={data}
            pagination={false}
            onChange={(pagination, filters, sorter) => {
                const { field, order } = sorter
                onChange(pagination, filters, { field, order })
            }}
            expandable={
                expandable
                    ? {
                          expandedRowRender: expandable.expandedRowRender,
                          rowExpandable: expandable.rowExpandable,
                          expandIconColumnIndex: expandable.expandIconColumnIndex,
                          expandIcon: InsExpandIcon,
                      }
                    : null
            }
        >
            {children}
        </Table>
    )
}
export default React.forwardRef(InsDataTable)

InsDataTable.propTypes = {
    tableStyle: PropTypes.oneOf(Object.values(TableStyles)),
    striped: PropTypes.bool,
    bordered: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            dataIndex: PropTypes.string.isRequired,
            sorter: PropTypes.any,
        })
    ),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    sorter: SorterPropTypes,
    onChange: PropTypes.func,
    hasSelection: PropTypes.bool,
    onSelectionChanged: PropTypes.func,
    expandable: PropTypes.shape({
        expandedRowRender: PropTypes.func.isRequired,
        rowExpandable: PropTypes.func,
        expandIconColumnIndex: PropTypes.number,
    }),
    selectionType: PropTypes.string,
    locale: PropTypes.object,
    subSelectedKeys: PropTypes.array,
    rowKey: PropTypes.func,
    customClassName: PropTypes.string,
    checkboxProps: PropTypes.func,
    ctlSortOrder: PropTypes.bool,
}

InsDataTable.defaultTypes = {
    selectionType: 'checkbox',
}
