import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import Routes from 'routes'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon } from 'components/InsIcon'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import OutsourceListSearch from 'modules/master/outsource/OutsourceListSearch'
import history from 'helpers/history.helper'

const OutsourceListComponent = ({
    inModal = false,
    onSelect,
    state,
    getData,
    setPagination,
    setFilters,
    setSorter,
    setKeywords,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const outsourceColumns = [
        {
            title: t('column:Outsource code'),
            dataIndex: 'outsource_code',
            sorter: true,
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            if (inModal) {
                                onSelect(r)
                            } else {
                                history.push(Routes.private.master.OUTSOURCE_DETAIL.path.replace(':id', r.id))
                            }
                        }}
                    >
                        {text}
                    </InsButton>
                )
            },
        },
        {
            title: t('column:Outsource name'),
            dataIndex: 'outsource_name',
            sorter: true,
        },
        {
            title: t('column:Address'),
            dataIndex: 'address1',
            sorter: true,
            render: (text, record) => (
                <span>
                    {text}&nbsp;<span>{record.address2}</span>
                </span>
            ),
            // code cu~
            // render: (text, r) =>
            //     `${getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, r.prefecture_code)}${r.address1}${
            //         r.address2
            //     }`,
        },
        { title: t('column:Phone number'), dataIndex: 'phone_number', sorter: true },
        { title: t('column:Fax number'), dataIndex: 'fax_number', sorter: true },
        {
            title: t('column:Pic name'),
            dataIndex: 'pic_name',
            sorter: true,
            render: (text, r) => {
                if (!r.pic) {
                    return null
                }
                return r.pic.pic_name
            },
        },
    ]

    const getOutsources = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        if (!state.fetched) {
            getOutsources()
        }
        // eslint-disable-next-line
    }, [])

    const updateFilters = obj => {
        const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getOutsources(newPagination, newFilters)
    }

    const tabChangeHandler = key => {
        dispatch(setKeywords([]))
        const newFilters = Object.assign({}, state.filters, {
            keyword: [],
            is_enabled: key,
        })
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setFilters(newFilters))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getOutsources(newPagination, newFilters, newSorter)
    }
    return (
        <React.Fragment>
            {!inModal && (
                <Row className="mt-8 mb-16" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-0">
                            {t('navigation:{{entity}} management', { entity: t('entity:Outsource') })}
                        </h1>
                    </Col>
                    <Col>
                        <InsButton
                            width={120}
                            buttonType="link"
                            linkTo={Routes.private.master.OUTSOURCE_CREATE.path}
                            icon={<AddIcon size={24} />}
                            buttonStyle="secondary"
                        >
                            {t('navigation:{{entity}} create', { entity: t('entity:Outsource') })}
                        </InsButton>
                    </Col>
                </Row>
            )}
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <OutsourceListSearch
                        onSearch={condition => updateFilters(condition)}
                        onReset={() => updateFilters({})}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsTab
                        type="card"
                        onChange={key => tabChangeHandler(key)}
                        activeKey={state.filters.is_enabled.toString()}
                        defaultActiveKey="1"
                        metaComponent={
                            <InsPagination {...state.pagination} onChange={pagination => getOutsources(pagination)} />
                        }
                    >
                        <Tabs.TabPane tab={t('common:Enabled')} key="1" />
                        <Tabs.TabPane tab={t('common:Disabled')} key="0" />
                    </InsTab>
                    <InsDataTable
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered
                        columns={outsourceColumns}
                        data={state.data}
                        onChange={(pagination, filter, sorter) => {
                            getOutsources(state.pagination, state.filters, sorter)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        ctlSortOrder={true}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default OutsourceListComponent

OutsourceListComponent.propTypes = {
    inModal: PropTypes.bool,
    onSelect: PropTypes.func,
    state: PropTypes.shape(BaseStatePropShape),
    setPagination: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
}

OutsourceListComponent.defaultProps = {
    inModal: false,
}
