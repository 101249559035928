import translation from 'locales/ja/translation.json'
import column from 'locales/ja/column.json'
import common from 'locales/ja/common.json'
import enumTrans from 'locales/ja/enum.json'
import entity from 'locales/ja/entity.json'
import navigation from 'locales/ja/navigation.json'
import validation from 'locales/ja/validation.json'

export default {
    translation,
    column,
    common,
    entity,
    enum: enumTrans,
    navigation,
    validation,
}
