import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import moment from 'moment'
import { ShipmentType } from 'enums/shipment.enum'

const name = 'shipments'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.SHIPMENTS, ...props })
)
const shipmentSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: {
            'shipment_type': ShipmentType.INCOMING.value,
            'display_shipment_type': 1, //1:display by product, 2:display by item
            'time:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:01'),
            'time:lte': moment().format('YYYY/MM/DD 23:59:59'),
        },
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setSorter,
    setSelection,
} = shipmentSlice.actions

export default shipmentSlice.reducer
