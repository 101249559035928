import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Select, Tabs } from 'antd'
import Routes from 'routes'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon, CheckIcon, DeleteIcon, FileCopyIcon, LockIcon, LockOpenIcon, PrintIcon } from 'components/InsIcon'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import {
    getData,
    setFilters,
    setPagination,
    setLoading,
    setDetailSearch,
    setKeywords,
    setSorter,
} from 'modules/business/project/project.slice'
import ProjectListSearch from 'modules/business/project/ProjectListSearch'
import RowDetail from 'modules/business/project/RowDetail'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { getClassificationItemName } from 'helpers/classification.helper'
import InsFloatBar from 'components/InsFloatBar'
import InsSelect from 'components/select/InsSelect'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import BusinessStatusEnum from 'enums/business_status.enum'
import InsDropdown from 'components/dropdown/InsDropdown'
import { dateFormat } from 'helpers/date.helper'
import ProjectCloneModal from 'modules/business/project/ProjectCloneModal'
import CloneDocumentEnum from 'enums/clone_document.enum'
import { userSettingColumns } from 'helpers/user_setting.helper'
import { formatNumberComma } from 'helpers/number.helper'
import { can } from 'helpers/permission.helper'
import ReactToPrint from 'react-to-print'
import { useReactToPrint } from 'react-to-print'
import BusinessToPrint from 'modules/common/ui/BusinessToPrint'
import InsModal from 'components/InsModal'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import moment from 'moment'
import { showWarning } from 'helpers/notification.helper'
import { getEnumText } from 'helpers/enum.helper'

const ProjectList = ({ state, currentUser }) => {
    const { t } = useTranslation()
    const [floatBarVisible, setFloatBarVisible] = useState(false)
    const [projectIds, setProjectIds] = useState([]) //select a lot
    const [selectedProject, setSelectedProject] = useState() //select one
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [dropdownVisible, setDropDownVisible] = useState({
        visible: false,
        x: 0,
        y: 0,
    })
    const ref = useRef()
    const dispatch = useDispatch()
    const [modalCloneVisible, setModalCloneVisible] = useState(false)
    const [projectCloneModal, setProjectCloneModal] = useState({
        title: '',
        cloneDocument: 0,
    })
    const [enabledDocument, setEnabledDocument] = useState([])
    const [activeKey, setActiveKey] = useState('0')
    const [confirmDeleteProject, setConfirmDeleteProject] = useState({
        visible: false,
        ids: [],
    })
    const projectsColumns = [
        {
            title: t('column:ID'),
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: t('column:Project name'),
            dataIndex: 'project_name',
            sorter: true,
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        linkTo={Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id)}
                        buttonType="link"
                    >
                        {text}
                    </InsButton>
                )
            },
        },
        {
            title: t('column:Project code'),
            dataIndex: 'project_code',
            sorter: true,
            render: (text, r) => {
                return (
                    <>
                        {r.is_locked ? <LockIcon className="color-blue-gray-600 mr-4" size={14} /> : null}
                        <InsButton
                            buttonStyle="plain"
                            linkTo={Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id)}
                            buttonType="link"
                        >
                            {text}
                        </InsButton>
                    </>
                )
            },
        },
        {
            title: t('顧客ID'),
            dataIndex: 'customer_id',
            sorter: true,
        },
        {
            title: t('顧客コード'),
            dataIndex: 'customer_code',
            sorter: false,
            render: (text, r) => r.customer?.customer_code,
        },
        {
            title: t('column:Customer name'),
            dataIndex: 'customer',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        setModalCustomerDetail({
                            customerId: record.customer?.id,
                            visible: true,
                        })
                    }}
                >
                    {text ? text.customer_name : ''}
                </InsButton>
            ),
        },
        {
            title: t('担当者ID'),
            dataIndex: 'pic_id',
            sorter: true,
        },
        { title: t('column:Pic name'), dataIndex: 'pic', sorter: true, render: text => (text ? text.pic_name : '') },
        {
            title: t('メモ'),
            dataIndex: 'note',
            sorter: true,
        },
        {
            title: t('仕入発注かどうか'),
            dataIndex: 'is_purchase_order',
            sorter: true,
        },
        {
            title: t('column:Status'),
            dataIndex: 'status',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.BUSINESS_STATUS.value, text.toString()),
        },
        {
            title: t('column:Created at'),
            dataIndex: 'created_at',
            sorter: true,
            render: date => dateFormat(date),
        },
        {
            title: t('作成者'),
            dataIndex: 'created_by',
            sorter: false,
            render: (text, record) => record.created_user?.pic_name,
        },
        {
            title: t('更新日'),
            dataIndex: 'updated_at',
            sorter: true,
            render: date => dateFormat(date),
        },
        {
            title: t('更新者'),
            dataIndex: 'updated_by',
            sorter: true,
        },
        {
            title: t('column:Bill'),
            dataIndex: 'billing_amount',
            sorter: true,
            render: (text, project) =>
                project.sale && project.money_received_status === 1 ? formatNumberComma(project.sale.total) : '未請求',
        },
        {
            title: t('column:Sale amount'),
            dataIndex: 'sale_amount',
            sorter: true,
            render: (text, project) => project.sale && formatNumberComma(project.sale.total),
        },
    ]
    const defaultColumns = ['project_code', 'project_name', 'customer', 'status', 'created_at', 'pic', 'billing_amount']
    const userProjectColumns = userSettingColumns(currentUser.user_setting, 'projects', projectsColumns, defaultColumns)
    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        copyStyles: true,
    })
    const updateFilters = obj => {
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        const newFilters = Object.assign(
            {
                'status': state.filters.status,
                'created_at:gte': state.filters['created_at:gte'],
                'created_at:lte': state.filters['created_at:lte'],
                'keyword': state.filters['keyword'],
            },
            obj
        )
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getProject(newPagination, newFilters)
    }

    const tabChangeHandler = key => {
        setActiveKey(key)
        dispatch(setKeywords([]))
        const newFilters = Object.assign(
            {},
            {
                'status': key,
                'created_at:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:01'),
                'created_at:lte': moment().format('YYYY/MM/DD 23:59:59'),
                'keyword': [],
            }
        )
        dispatch(setFilters(newFilters))
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setDetailSearch({}))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        ref.current.resetSelectedRowKeys()
        setFloatBarVisible(false)
        getProject(newPagination, newFilters, newSorter)
    }

    const getProject = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        setProjectIds([])
        setDropDownVisible({
            visible: false,
            x: dropdownVisible.x,
            y: dropdownVisible.y,
        })
        if (
            parseInt(filters.status) !== BusinessStatusEnum.GARBAGE_CAN.value &&
            parseInt(filters.status) !== BusinessStatusEnum.PROCESSED.value
        ) {
            setActiveKey('0')
        } else {
            setActiveKey(filters.status)
        }
        dispatch(getData({ pagination, filters, sorter }))
    }

    const updateProjectStatus = async (status, ids) => {
        dispatch(setLoading(true))
        setFloatBarVisible(false)
        if (status === BusinessStatusEnum.GARBAGE_CAN.value) {
            setConfirmDeleteProject({
                visible: false,
                ids: [],
            })
        }
        await api.post(APP_URLS.PROJECT_UPDATE_STATUS, {
            status: status,
            ids: ids,
        })
        ref.current.resetSelectedRowKeys()
        getProject()
    }
    const [checkDeleteProjects, setCheckDeleteProjects] = useState({
        projectIds: null,
        canDelete: false,
        data: null,
    })
    const checkCanDelete = async ids => {
        const data = await api.post(APP_URLS.PROJECT_CHECK_CAN_DELETE, {
            project_ids: ids,
        })
        if (data) {
            setCheckDeleteProjects({
                projectIds: ids,
                canDelete: data.can_delete,
                data: data.data,
            })
        }
    }

    const rollbackProjectStatus = async () => {
        await api.post(APP_URLS.PROJECT_ROLLBACK_STATUS, {
            ids: projectIds,
        })
        ref.current.resetSelectedRowKeys()
        getProject()
    }

    const updateLockProject = async (isLocked, ids) => {
        dispatch(setLoading(true))
        setFloatBarVisible(false)
        await api.post(APP_URLS.PROJECT_UPDATE_LOCK, {
            is_locked: isLocked,
            ids: ids,
        })
        ref.current.resetSelectedRowKeys()
        getProject()
    }
    const checkCanUpdateLockProjects = ids => {
        let disabled = false
        if (ids.length > 1) {
            let isLocked = state.data.find(element => element.id === ids[0])?.is_locked
            ids.forEach(id => {
                let project = state.data.find(element => element.id === id)
                if (project?.is_locked !== isLocked) {
                    disabled = true
                }
            })
        }
        if (selectedProject?.status >= BusinessStatusEnum.PROCESSED.value) disabled = true
        return disabled
    }

    const changeSelectedProject = async project => {
        let isPurchaseOrder = project.is_purchase_order
        getDetailProject(project.id)
        let documentValues = []
        if (project.estimation && !isPurchaseOrder) {
            documentValues.push(CloneDocumentEnum.ESTIMATION.value)
        }
        if (project.instruction_internal && !isPurchaseOrder) {
            documentValues.push(CloneDocumentEnum.INSTRUCTION_INTERNAL.value)
        }
        if (project.instruction_outsource && !isPurchaseOrder) {
            documentValues.push(CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value)
        }
        if (project.place_order) {
            documentValues.push(CloneDocumentEnum.PLACE_ORDER.value)
        }
        if (project.sale && !isPurchaseOrder) {
            documentValues.push(CloneDocumentEnum.SALES.value)
        }
        setEnabledDocument(documentValues)
        setSelectedDocument(null)
    }

    const selectedDocumentClone = value => {
        setSelectedDocument(value)
        setForProjectClone(value)
    }

    const setForProjectClone = value => {
        switch (value) {
            case CloneDocumentEnum.ESTIMATION.value:
                setProjectCloneModal({
                    title: CloneDocumentEnum.ESTIMATION.text,
                    cloneDocument: CloneDocumentEnum.ESTIMATION.value,
                })
                break
            case CloneDocumentEnum.INSTRUCTION_INTERNAL.value:
                setProjectCloneModal({
                    title: CloneDocumentEnum.INSTRUCTION_INTERNAL.text,
                    cloneDocument: CloneDocumentEnum.INSTRUCTION_INTERNAL.value,
                })
                break
            case CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value:
                setProjectCloneModal({
                    title: CloneDocumentEnum.INSTRUCTION_OUTSOURCE.text,
                    cloneDocument: CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value,
                })
                break
            case CloneDocumentEnum.PLACE_ORDER.value:
                setProjectCloneModal({
                    title: CloneDocumentEnum.PLACE_ORDER.text,
                    cloneDocument: CloneDocumentEnum.PLACE_ORDER.value,
                })
                break
            case CloneDocumentEnum.SALES.value:
                setProjectCloneModal({
                    title: CloneDocumentEnum.SALES.text,
                    cloneDocument: CloneDocumentEnum.SALES.value,
                })
                break
            default:
                break
        }
    }

    const getDetailProject = projectId => {
        api.get(APP_URLS.PROJECT_DETAIL.replace(':id', projectId)).then(project => {
            setSelectedProject(project)
            let indexOfDocument = null
            if (project.sale !== null) {
                indexOfDocument = CloneDocumentEnum.SALES.value
            } else if (project.place_order !== null) {
                indexOfDocument = CloneDocumentEnum.PLACE_ORDER.value
            } else if (project.instruction_outsource !== null) {
                indexOfDocument = CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value
            } else if (project.instruction_internal !== null) {
                indexOfDocument = CloneDocumentEnum.INSTRUCTION_INTERNAL.value
            } else if (project.estimation !== null) {
                indexOfDocument = CloneDocumentEnum.ESTIMATION.value
            }
            setSelectedDocument(indexOfDocument)
            setForProjectClone(indexOfDocument)
        })
    }

    useEffect(() => {
        dispatch(setDetailSearch({}))
        updateFilters({})
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (projectIds.length > 0) {
            setFloatBarVisible(true)
        } else setFloatBarVisible(false)
    }, [projectIds])

    useEffect(() => {
        if (!checkDeleteProjects.canDelete && checkDeleteProjects.data) {
            let text = ''
            let arr = checkDeleteProjects.data
            arr.forEach(project => {
                text += `${project.project_code} : ${getEnumText(BusinessStatusEnum, project.status)}\n`
            })
            showWarning('', <pre>{text}</pre>)
        }
        // eslint-disable-next-line
    }, [checkDeleteProjects])

    const [modalCustomerDetail, setModalCustomerDetail] = useState({
        customerId: null,
        visible: false,
    })

    return (
        <>
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalCustomerDetail.visible}
                onCancel={() =>
                    setModalCustomerDetail({
                        customerId: modalCustomerDetail.customerId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <CustomerDetailComponent inModal={true} id={modalCustomerDetail.customerId} />
            </InsModal>

            {projectIds.length < 2 && (
                <BusinessToPrint project={selectedProject} document={selectedDocument} printRef={printRef} />
            )}

            {confirmDeleteProject.visible && (
                <InsModal
                    title={t('common:Delete')}
                    visible={confirmDeleteProject.visible}
                    onCancel={() =>
                        setConfirmDeleteProject({
                            visible: false,
                            ids: [],
                        })
                    }
                    onOk={async () => {
                        if (confirmDeleteProject.ids.length > 0) {
                            await updateProjectStatus(BusinessStatusEnum.GARBAGE_CAN.value, confirmDeleteProject.ids)
                        }
                    }}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <h2>{t('common:Are you sure')}</h2>
                    <p>※削除された案件は「ゴミ箱」タブで確認できます。</p>
                </InsModal>
            )}
            <InsModal
                title={t('common:Delete')}
                visible={checkDeleteProjects.canDelete}
                onCancel={() =>
                    setCheckDeleteProjects({
                        projectIds: null,
                        canDelete: false,
                        data: null,
                    })
                }
                onOk={async () => {
                    if (checkDeleteProjects.projectIds.length > 0) {
                        await updateProjectStatus(BusinessStatusEnum.GARBAGE_CAN.value, checkDeleteProjects.projectIds)
                        setCheckDeleteProjects({
                            projectIds: null,
                            canDelete: false,
                            data: null,
                        })
                    }
                }}
                okText="はい"
                cancelText="いいえ"
            >
                <h2>{t('common:Are you sure')}</h2>
                <p>※削除された案件は「ゴミ箱」タブで確認できます。</p>
            </InsModal>

            {can('PROJECT_EDITABLE') && (
                <div style={{ position: 'absolute', top: dropdownVisible.y, left: dropdownVisible.x, width: 170 }}>
                    <InsDropdown
                        visible={dropdownVisible.visible}
                        onSelect={async key => {
                            if (key.key === '900' || key.key === '901') {
                                setDropDownVisible({
                                    visible: false,
                                    x: dropdownVisible.x,
                                    y: dropdownVisible.y,
                                })
                                if (key.key === '901') {
                                    setConfirmDeleteProject({
                                        visible: true,
                                        ids: [selectedProject.id],
                                    })
                                } else {
                                    await updateProjectStatus(parseInt(key.key), projectIds)
                                }
                            } else if (key.keyPath.includes('clone')) {
                                await selectedDocumentClone(parseInt(key.key))
                                setModalCloneVisible(true)
                            } else if (key.keyPath.includes('copy')) {
                                setSelectedDocument(parseInt(key.key))
                                // handlePrint()
                            } else if (key.key === 'lock') {
                                if (selectedProject) {
                                    await updateLockProject(!selectedProject.is_locked, projectIds)
                                }
                            }
                        }}
                        options={[
                            {
                                key: 'clone',
                                text: t('Create and copy'),
                                disabled: projectIds.length > 1,
                                children:
                                    projectIds.length === 1
                                        ? Object.values(CloneDocumentEnum).map(option => ({
                                              key: option.value,
                                              text: option.text,
                                              disabled: !enabledDocument.includes(option.value),
                                          }))
                                        : null,
                            },
                            {
                                key: 'copy',
                                text: '印刷',
                                disabled: projectIds.length > 1,
                                children:
                                    projectIds.length === 1
                                        ? Object.values(CloneDocumentEnum).map(option => ({
                                              key: option.value,
                                              text: option.text,
                                              disabled: !enabledDocument.includes(option.value),
                                          }))
                                        : null,
                            },
                            {
                                key: BusinessStatusEnum.PROCESSED.value,
                                text: t('Processed'),
                                disabled: selectedProject?.status === BusinessStatusEnum.PROCESSED.value,
                            },
                            {
                                key: BusinessStatusEnum.GARBAGE_CAN.value,
                                text: t('Trash'),
                                className: 'color-pantone-213-c',
                                disabled: selectedProject?.status === BusinessStatusEnum.GARBAGE_CAN.value,
                            },
                            {
                                key: 'lock',
                                text: selectedProject?.is_locked ? t('Unlock') : t('Lock'),
                                className: 'color-pantone-2738-c',
                                disabled: selectedProject?.status === BusinessStatusEnum.GARBAGE_CAN.value,
                            },
                        ]}
                        onVisibleChange={() => {
                            setDropDownVisible({
                                visible: false,
                                x: dropdownVisible.x,
                                y: dropdownVisible.y,
                            })
                            if (!modalCloneVisible) handlePrint()
                        }}
                        placement="bottomRight"
                        getPopupContainer={triggerNode => {
                            return triggerNode.parentNode
                        }}
                    />
                </div>
            )}
            <div className="page-container">
                <Row className="mt-8 mb-16" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-0">
                            {t('navigation:{{entity}}', { entity: t('entity:Project') })}
                        </h1>
                    </Col>
                    <Col>
                        {can('PROJECT_EDITABLE') && (
                            <InsButton
                                width={120}
                                buttonType="link"
                                linkTo={Routes.private.business.PROJECT_CREATE.path}
                                icon={<AddIcon size={24} />}
                                buttonStyle="secondary"
                            >
                                {t('entity:Item create')}
                            </InsButton>
                        )}
                    </Col>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Col span={24}>
                        <ProjectListSearch
                            onSearch={condition => updateFilters(condition)}
                            onReset={() => updateFilters({ keyword: [] })}
                            getProject={getProject}
                            pagination={state.pagination}
                        />
                    </Col>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Col span={24} className={'project-datatable'}>
                        <InsTab
                            type="card"
                            metaComponent={
                                <InsPagination {...state.pagination} onChange={pagination => getProject(pagination)} />
                            }
                            onChange={key => tabChangeHandler(key)}
                            activeKey={activeKey}
                        >
                            <Tabs.TabPane tab={t('common:Project Enabled')} key="0" />
                            <Tabs.TabPane tab={t('common:Project Disabled')} key="900" />
                            <Tabs.TabPane tab={t('common:Project Delete')} key="901" />
                        </InsTab>
                        <InsDataTable
                            ref={r => (ref.current = r)}
                            loading={state.loading ? { indicator: <InsLoader /> } : false}
                            tableStyle="compact"
                            striped
                            bordered={false}
                            data={state.data}
                            columns={userProjectColumns}
                            onChange={(pagination, filter, sorter) => {
                                getProject(state.pagination, state.filters, sorter)
                            }}
                            hasSelection={can('PROJECT_EDITABLE')}
                            onSelectionChanged={async keys => {
                                if (keys.length === 1) {
                                    let project = state.data.find(element => element.id === keys[0])
                                    await changeSelectedProject(project)
                                }
                                setProjectIds(keys)
                            }}
                            subSelectedKeys={projectIds}
                            pagination={state.pagination}
                            filters={state.filters}
                            sorter={state.sorter}
                            expandable={{
                                expandedRowRender: r => <RowDetail record={r} selected={projectIds.includes(r.id)} />,
                                rowExpandable: r => r.name !== null,
                                expandIconColumnIndex: 8,
                            }}
                            onRow={(record, recordIndex) => {
                                return {
                                    onContextMenu: async event => {
                                        event.preventDefault()
                                        if (projectIds.indexOf(record.id) === -1) {
                                            setProjectIds([...projectIds, record.id])
                                        }
                                        setDropDownVisible({
                                            visible: true,
                                            x: event.pageX - 10,
                                            y: event.pageY - 10,
                                        })
                                        await changeSelectedProject(record)
                                    },
                                }
                            }}
                            ctlSortOrder={true}
                        />
                    </Col>
                </Row>
                {can('PROJECT_EDITABLE') && (
                    <InsFloatBar visible={floatBarVisible} className="project-list-float-bar" floatBarStyle="float">
                        <React.Fragment>
                            <Col style={{ paddingLeft: 16 }}>
                                <Row>
                                    <span>{t('Select button')}</span>
                                </Row>
                                <Row className="mt-4">
                                    {selectedProject?.status < BusinessStatusEnum.PROCESSED.value && (
                                        <InsButton
                                            style={{ width: 94 }}
                                            icon={<CheckIcon className="color-pantone-2738-c" size={14} />}
                                            buttonStyle="default"
                                            disabled={selectedProject?.status === BusinessStatusEnum.PROCESSED.value}
                                            onClick={() =>
                                                updateProjectStatus(BusinessStatusEnum.PROCESSED.value, projectIds)
                                            }
                                        >
                                            {t('Processed')}
                                        </InsButton>
                                    )}
                                    {selectedProject?.status >= BusinessStatusEnum.PROCESSED.value && (
                                        <InsButton
                                            icon={<CheckIcon className="color-pantone-2738-c" size={14} />}
                                            buttonStyle="default"
                                            onClick={() =>
                                                // updateProjectStatus(BusinessStatusEnum.PROCESSED.value, projectIds)
                                                rollbackProjectStatus()
                                            }
                                        >
                                            {selectedProject?.status === BusinessStatusEnum.PROCESSED.value
                                                ? t('未処理案件へ')
                                                : t('未処理案件に復元')}
                                        </InsButton>
                                    )}
                                    <InsButton
                                        icon={
                                            <div
                                                className={
                                                    selectedProject?.status !== BusinessStatusEnum.GARBAGE_CAN.value &&
                                                    'color-pantone-213-c'
                                                }
                                            >
                                                <DeleteIcon size={16} />
                                            </div>
                                        }
                                        disabled={selectedProject?.status === BusinessStatusEnum.GARBAGE_CAN.value}
                                        icon={
                                            <DeleteIcon
                                                size={16}
                                                className={
                                                    selectedProject?.status === BusinessStatusEnum.GARBAGE_CAN.value
                                                        ? ''
                                                        : 'color-pantone-213-c'
                                                }
                                            />
                                        }
                                        buttonStyle="default"
                                        style={{ width: 94, marginLeft: 10 }}
                                        onClick={() => {
                                            checkCanDelete(projectIds)
                                        }}
                                    >
                                        {t('Trash')}
                                    </InsButton>
                                    <InsButton
                                        style={{ marginLeft: 10 }}
                                        icon={
                                            selectedProject?.is_locked ? (
                                                <LockOpenIcon
                                                    className={
                                                        !checkCanUpdateLockProjects(projectIds) &&
                                                        'color-pantone-2738-c'
                                                    }
                                                    size={16}
                                                />
                                            ) : (
                                                <LockIcon
                                                    className={
                                                        !checkCanUpdateLockProjects(projectIds) &&
                                                        'color-pantone-2738-c'
                                                    }
                                                    size={16}
                                                />
                                            )
                                        }
                                        buttonStyle="default"
                                        disabled={checkCanUpdateLockProjects(projectIds)}
                                        onClick={() => updateLockProject(!selectedProject.is_locked, projectIds)}
                                    >
                                        {selectedProject?.is_locked ? t('Unlock') : t('Lock')}
                                    </InsButton>
                                </Row>
                            </Col>
                            <div className="divider" />
                            <Col style={{ paddingLeft: 16 }}>
                                <Row>
                                    <span>{t('Select item')} </span>
                                </Row>
                                <Row className="mt-4">
                                    <InsSelect
                                        style={{ width: 180 }}
                                        onSelect={value => {
                                            selectedDocumentClone(value)
                                        }}
                                        value={selectedDocument}
                                        disabled={projectIds.length > 1}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {Object.values(CloneDocumentEnum).map(option => (
                                            <Select.Option
                                                key={option.value}
                                                value={option.value}
                                                disabled={!enabledDocument.includes(option.value)}
                                            >
                                                {option.text}
                                            </Select.Option>
                                        ))}
                                    </InsSelect>
                                    <ReactToPrint
                                        trigger={() => (
                                            <InsButton
                                                icon={
                                                    <div
                                                        style={{
                                                            color:
                                                                projectIds.length > 1 || !selectedDocument
                                                                    ? ''
                                                                    : '#06038D',
                                                        }}
                                                    >
                                                        <PrintIcon size={16} />
                                                    </div>
                                                }
                                                disabled={projectIds.length > 1 || !selectedDocument}
                                                buttonStyle="default"
                                                style={{ marginLeft: 10, width: 69 }}
                                            >
                                                {t('Print')}
                                            </InsButton>
                                        )}
                                        content={() => printRef.current}
                                    />
                                    <ProjectCloneModal
                                        title={projectCloneModal.title}
                                        visible={modalCloneVisible}
                                        project={selectedProject}
                                        cloneDocument={projectCloneModal.cloneDocument}
                                        onCancel={() => {
                                            setModalCloneVisible(false)
                                            getProject()
                                        }}
                                    >
                                        <InsButton
                                            icon={
                                                <div
                                                    style={{
                                                        color:
                                                            projectIds.length > 1 || !selectedDocument ? '' : '#06038D',
                                                    }}
                                                >
                                                    <FileCopyIcon size={16} />
                                                </div>
                                            }
                                            disabled={projectIds.length > 1 || !selectedDocument}
                                            buttonStyle="default"
                                            style={{ marginLeft: 10 }}
                                            onClick={() => {
                                                setModalCloneVisible(true)
                                            }}
                                        >
                                            {t('Create and copy')}
                                        </InsButton>
                                    </ProjectCloneModal>
                                </Row>
                            </Col>
                        </React.Fragment>
                    </InsFloatBar>
                )}
            </div>
        </>
    )
}

const mapState = state => ({
    state: state.project,
    currentUser: state.auth.user,
})
export default connect(mapState)(ProjectList)

ProjectList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
}
