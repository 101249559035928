import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { CheckIcon } from 'components/InsIcon'
import InsTab from 'components/InsTab'
import InsFloatBar from 'components/InsFloatBar'
import { formatNumberComma } from 'helpers/number.helper'
import { setData, setKeywords, setLoading } from 'modules/business/payment/payment.slice'
import { connect, useDispatch } from 'react-redux'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import moment from 'moment'
import InsModal from 'components/InsModal'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import InsLoader from 'components/InsLoader'
import { Link } from 'react-router-dom'
import Routes from 'routes'
import { dateFormat } from 'helpers/date.helper'
import SupplierDetailComponent from 'modules/master/supplier/SupplierDetailComponent'
import NavigationEnum from 'enums/navigation.enum'

const SCHEDULE = '1'
const PAID = '2'

const TransferDataTable = ({ state, updateFilters, setDefaultFilters }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [selectedKeys, setSelectedKeys] = useState({})
    const [confirmPaidStatus, setConfirmPaidStatus] = useState(false)

    const handleSelectedKeysChanged = (keys, rootKey) => {
        if (rootKey) {
            if (keys.length > 0) {
                setSelectedKeys({ ...selectedKeys, [rootKey]: keys })
            } else {
                let tmpSelectedKeys = Object.assign({}, selectedKeys)
                delete tmpSelectedKeys[rootKey]
                setSelectedKeys(tmpSelectedKeys)
            }
        } else {
            let currentRootKeys = Object.keys(selectedKeys)
            let diffAddKeys = keys.filter(key => !currentRootKeys.includes(key))
            let diffRemoveKeys = currentRootKeys.filter(key => !keys.includes(key))
            let tmpSelectedKeys = Object.assign({}, selectedKeys)
            diffRemoveKeys.map(k => {
                delete tmpSelectedKeys[k]
            })
            diffAddKeys.map(k => {
                tmpSelectedKeys[k] = [state.data.find(d => d.id === k).project.id]
            })
            setSelectedKeys(tmpSelectedKeys)
        }
    }

    const selectAll = () => {
        let tmpSelectedKeys = {}
        state.data.map(placeOrder => {
            tmpSelectedKeys[placeOrder.id] = [placeOrder.project.id]
        })
        setSelectedKeys(tmpSelectedKeys)
    }

    const calculateTotalAmountSelected = () => {
        let totalAmountSelected = 0
        state.data.map(placeOrder => {
            let tmpGroup = selectedKeys[placeOrder.id] || []
            if (tmpGroup.includes(placeOrder?.project?.id)) {
                totalAmountSelected += placeOrder.total
            }
        })
        return totalAmountSelected
    }

    const calculateTotalAmountExtracted = () => {
        return state.data.map(item => item.total).reduce((prev, curr) => prev + curr, 0)
    }

    const updatePaidStatus = () => {
        let ids = Object.values(selectedKeys).flat()
        setConfirmPaidStatus(false)
        dispatch(setData([]))
        dispatch(setLoading(true))
        api.post(APP_URLS.PAYMENTS_TRANSFER, { project_ids: ids, status: !state.filters['paid_status'] }).then(r => {
            updateFilters()
            setSelectedKeys({})
        })
    }

    const renderDelayedPaymentDate = record => {
        if (record.payment_schedule_month === 99) {
            return '支払遅滞'
        }
        let paymentScheduleDate = moment(record.issued_date, 'YYYY-MM-DD')
        let futureMonthEnd = moment(paymentScheduleDate).add(record.payment_schedule_month, 'M').endOf('month')
        if (futureMonthEnd.daysInMonth() <= record.payment_schedule_day || record.payment_schedule_day === 99) {
            paymentScheduleDate = futureMonthEnd
        } else {
            if (record.payment_schedule_month) paymentScheduleDate.add(record.payment_schedule_month, 'M')
            if (record.payment_schedule_day)
                paymentScheduleDate = paymentScheduleDate.set('date', record.payment_schedule_day)
        }
        return dateFormat(paymentScheduleDate)
    }

    const isLater = str => {
        if (str === '9999') return true
        let currDate = new Date(moment(new Date()).format('YYYY/MM/DD'))
        return new Date(str) < currDate
    }

    const columns = [
        {
            title: '支払先名',
            dataIndex: 'supplier_name',
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            setModalSupplierDetail({
                                supplierId: r.supplier.payee.id,
                                visible: true,
                            })
                        }}
                    >
                        {r.supplier.payee.supplier_name}
                    </InsButton>
                )
            },
            sorter: true,
        },
        {
            title: '支払種別',
            dataIndex: 'payment_schedule_payment_type',
            render: (text, record) => {
                return getClassificationItemName(
                    ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value,
                    record.supplier.payee.payment_schedule_payment_type
                )
            },
        },
        {
            title: '支払方法',
            dataIndex: 'payment_schedule_payment_method',
            render: (text, record) => {
                return getClassificationItemName(
                    ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value,
                    record.supplier.payee.payment_schedule_payment_method
                )
            },
        },
        {
            title: '発注日',
            dataIndex: 'issued_date',
            sorter: true,
            render: date => dateFormat(date),
        },
        {
            title: '支払予定日',
            dataIndex: 'collection_schedule_date',
            sorter: true,
            render: (text, r) => {
                if (r.collection_schedule_date === '9999') return '支払遅滞'
                return dateFormat(r.collection_schedule_date)
            },
        },
        {
            title: '小計',
            dataIndex: 'total',
            render: text => {
                return <span style={{ fontWeight: 'bold' }}>{formatNumberComma(text)}</span>
            },
            sorter: true,
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            // sorter: true,
            render: (text, record) => {
                let isDelayed = isLater(record.collection_schedule_date)
                if (isDelayed) {
                    return (
                        <span style={{ fontWeight: 'bold' }} className="text-row-pantone-213 ">
                            支払遅滞
                        </span>
                    )
                } else if (!state.filters['paid_status']) {
                    return <span>支払予定</span>
                } else {
                    return <span>支払済み</span>
                }
            },
        },
    ]
    const [modalSupplierDetail, setModalSupplierDetail] = useState({
        supplierId: null,
        visible: false,
    })

    return (
        <>
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalSupplierDetail.visible}
                onCancel={() =>
                    setModalSupplierDetail({
                        supplierId: modalSupplierDetail.supplierId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <SupplierDetailComponent inModal={true} id={modalSupplierDetail.supplierId} />
            </InsModal>
            <Row gutter={[8, 16]} align="middle">
                <InsModal
                    width={'572px'}
                    title="まとめ請求"
                    visible={confirmPaidStatus}
                    onCancel={() => setConfirmPaidStatus(false)}
                    onOk={() => updatePaidStatus()}
                    okText="確認"
                    okButtonStyle="secondary"
                >
                    <Row justify="space-between" align="middle">
                        <span>選択した 件の案件を上記の内容でまとめて請求書を発行します。 よろしいですか？</span>
                    </Row>
                </InsModal>
                <Col span={24} className={'payment-datatable'}>
                    <InsTab
                        type="card"
                        metaComponent={
                            <InsPagination
                                {...state.pagination}
                                onChange={pagination => {
                                    updateFilters(pagination, state.filters, state.sorter)
                                }}
                            />
                        }
                        onChange={key => {
                            dispatch(setLoading(true))
                            dispatch(setData([]))
                            dispatch(setKeywords([]))
                            setSelectedKeys({})
                            if (key === SCHEDULE) {
                                setDefaultFilters(false)
                            } else if (key === PAID) {
                                setDefaultFilters(true)
                            }
                            // dispatch(setSorter({}))
                        }}
                        activeKey={state.filters['paid_status'] ? PAID : SCHEDULE}
                    >
                        <Tabs.TabPane tab={t('支払予定')} key={SCHEDULE} />
                        <Tabs.TabPane tab={t('支払済み')} key={PAID} />
                    </InsTab>
                    <InsDataTable
                        data={state.data}
                        rowClassName={(record, index) => {
                            let isDelayedCollection = isLater(record.collection_schedule_date)
                            if (isDelayedCollection) {
                                return 'table-row-pantone-213-opacity'
                            } else {
                                return ''
                            }
                        }}
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        onChange={(pagination, filter, sorter) => {
                            let newSorter = Object.assign({}, sorter)
                            if (sorter.order === undefined)
                                newSorter = {
                                    order: 'descend',
                                    field: 'project_id',
                                }
                            updateFilters(pagination, filter, newSorter)
                        }}
                        columns={columns}
                        hasSelection={true}
                        rowKey={record => record.id}
                        onSelectionChanged={keys => {
                            handleSelectedKeysChanged(keys)
                        }}
                        subSelectedKeys={Object.keys(selectedKeys).map(Number)}
                        expandable={{
                            expandedRowRender: record => {
                                const columns = [
                                    {
                                        title: '案件番号',
                                        dataIndex: 'project_code',
                                        sorter: (a, b) => a.id - b.id,
                                        render: (text, r) => {
                                            return (
                                                <Link
                                                    to={
                                                        Routes.private.business.PROJECT_DETAIL.path.replace(
                                                            ':id',
                                                            r?.id
                                                        ) +
                                                        '?from=' +
                                                        NavigationEnum.PAYMENTS
                                                    }
                                                    target="_blank"
                                                >
                                                    <InsButton buttonStyle="plain" onClick={() => {}}>
                                                        {r?.project_code}
                                                    </InsButton>
                                                </Link>
                                            )
                                        },
                                    },
                                    {
                                        title: '顧客名',
                                        dataIndex: 'supplier_name',
                                        sorter: (a, b) => a.id - b.id,
                                        render: () => {
                                            return <span>{record.supplier.supplier_name}</span>
                                        },
                                    },
                                    {
                                        title: '件名',
                                        dataIndex: 'project_name',
                                        sorter: (a, b) => a.id - b.id,
                                        render: (text, r) => {
                                            return <span>{r?.project_name}</span>
                                        },
                                    },
                                    {
                                        title: '納品日',
                                        dataIndex: 'issued_date',
                                        sorter: (a, b) => a.id - b.id,
                                        render: date => dateFormat(record.issued_date),
                                    },
                                    {
                                        title: '担当者名',
                                        dataIndex: 'pic_name',
                                        render: (text, r) => {
                                            return <span>{r?.pic?.pic_name}</span>
                                        },
                                    },
                                    {
                                        title: '請求額',
                                        dataIndex: 'total',
                                        sorter: (a, b) => a.total - b.total,
                                        render: text => {
                                            return <span>{formatNumberComma(record.total)}</span>
                                        },
                                    },
                                ]
                                return (
                                    <div style={{ padding: 4, paddingBottom: 16 }}>
                                        <InsDataTable
                                            columns={columns}
                                            data={[record.project]}
                                            rowKey={record => record.id}
                                            pagination={false}
                                            hasSelection={true}
                                            bordered={true}
                                            onChange={(pagination, filter, sorter) => {}}
                                            onSelectionChanged={keys => {
                                                handleSelectedKeysChanged(keys, record.id)
                                                // handleSelectedKeysChanged(
                                                //     keys,
                                                //     record.issued_date.concat(',', record.id)
                                                // )
                                            }}
                                            subSelectedKeys={selectedKeys[record.id] || []}
                                        />
                                    </div>
                                )
                            },
                            rowExpandable: r => r.name !== null,
                            expandIconColumnIndex: 8,
                        }}
                        sorter={state.sorter}
                        ctlSortOrder={true}
                    />
                </Col>
            </Row>
            <InsFloatBar visible={true}>
                <Row justify="space-between" align="middle">
                    <Col style={{ paddingLeft: 16 }}>
                        <Row className="mt-4">
                            <InsButton
                                style={{ width: 94 }}
                                icon={<CheckIcon size={16} />}
                                buttonStyle="default"
                                onClick={() => selectAll()}
                            >
                                {t('全て選択')}
                            </InsButton>
                        </Row>
                    </Col>
                    <Col style={{ paddingLeft: 16 }}>
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">抽出された金額合計</label>
                            <span className="project-status-label summary-invoice-label ">
                                {formatNumberComma(calculateTotalAmountExtracted())}
                            </span>
                        </span>
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">選択された金額合計</label>
                            <span className="project-status-label summary-invoice-label ">
                                {formatNumberComma(calculateTotalAmountSelected())}
                            </span>
                        </span>
                        <InsButton
                            width={136}
                            centered
                            className="pl-16 pr-16"
                            disabled2={!Object.keys(selectedKeys).length}
                            buttonStyle={!state.filters['paid_status'] ? 'primary' : 'secondary'}
                            buttonSize={'big'}
                            onClick={() => {
                                setConfirmPaidStatus(true)
                            }}
                        >
                            {!state.filters['paid_status'] ? '支払済み' : '支払済み 取消'}
                        </InsButton>
                    </Col>
                </Row>
            </InsFloatBar>
        </>
    )
}

const mapState = state => ({ state: state.payment })
export default connect(mapState)(TransferDataTable)

TransferDataTable.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    updateFilters: PropTypes.func,
    setDefaultFilters: PropTypes.func,
}
