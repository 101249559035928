import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'

const InsLoader = ({ className, ...rest }) => {
    let loaderClassName = 'ins-loader '
    if (className) {
        loaderClassName += className
    }
    return (
        <div {...rest} className={loaderClassName}>
            <div />
            <div />
            <div />
        </div>
    )
}

export default InsLoader

export const InsBlockLoader = () => (
    <div className="ins-flex flex-center m-40">
        <Spin indicator={<InsLoader />} />
    </div>
)

InsLoader.propTypes = {
    className: PropTypes.string,
}
