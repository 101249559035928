import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Checkbox, Col, Form, Row, Select } from 'antd'
import InsInput from 'components/InsInput'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import CustomerSelect from 'modules/master/customer/CustomerSelect'
import InsSelect from 'components/select/InsSelect'
import { PreviewIcon } from 'components/InsIcon'
import { getClassificationsByType } from 'helpers/classification.helper'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import UserSelect from 'modules/master/user/UserSelect'
import InsFloatBar from 'components/InsFloatBar'
import InsButton from 'components/InsButton'
import InsTextArea from 'components/InsTextArea'
import moment from 'moment'
import InsInputNumber from 'components/InsInputNumber'
import InstructionDetailAddButton from 'modules/business/instruction/form/InstructionDetailAddButton'
import InstructionDragAndDrop from 'modules/business/instruction/form/InstructionDragAndDrop'
import {
    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,
    setDefaultState,
    setInstructionType,
    setStateWithoutDelivery,
    setStateWithOneDelivery,
    setStateWithMultiDelivery,
    parseData,
} from 'modules/business/instruction/instruction_detail.slice'
import { showWarning } from 'helpers/notification.helper'
import history from 'helpers/history.helper'
import InstructionPreviewModal from 'modules/business/instruction/InstructionPreviewModal'
import { calculateTotal } from 'helpers/calculate.helper'
import InsAutoComplete from 'components/InsAutoComplete'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import RouterPrompt from 'modules/common/ui/RouterPrompt'
import { useLocation } from 'react-router-dom'
import Routes from 'routes'
import { isHeadline } from 'helpers/detail.helper'
import OutsourceSelect from 'modules/master/outsource/OutsourceSelect'
import InsModal from 'components/InsModal'
import ShipperSelect from 'modules/master/shipper/ShipperSelect'

const InstructionForm = ({
    isEdit,
    initialValues = {},
    onSubmit,
    project,
    instructionType,
    destinations,
    destinationsOrder,
    details,
    detailsOrder,
    setDefaultState,
    setInstructionType,
    setStateWithoutDelivery,
    setStateWithOneDelivery,
    setStateWithMultiDelivery,
    parseData,
    changedData,
    projectForm,
    projectCreateDoc,
}) => {
    const { t } = useTranslation()
    const [form] = projectForm ? [projectForm] : Form.useForm()
    const [modalPreviewVisible, setModalPreviewVisible] = useState(false)
    const [isValueChange, setIsValueChange] = useState(!isEdit)
    const [modalConfirmSave, setModalConfirmSave] = useState(false)
    const dateFormat = 'YYYY年M月DD日'

    // parse data for update
    const parseDataForUpdate = () => {
        setDefaultState()
        setInstructionType(instructionType)
        if (initialValues.id || isEdit) {
            const orgDestinations = initialValues.delivery_destinations
            parseData({
                destinations: orgDestinations,
                details: initialValues.details,
            })
            if (orgDestinations.length === 0) {
                setOptDeliveryDestination(`${deliveryDestinationType}${directDeliveryType}`)
            }
        }
    }
    useEffect(() => {
        if (!projectCreateDoc) {
            parseDataForUpdate()
        }
    }, [project])

    useEffect(() => {
        parseDataForUpdate()
    }, [])

    const calculateSum = details => {
        const result = calculateTotal(details)
        form.setFieldsValue(result)
    }

    useEffect(() => {
        calculateSum(details)
    }, [details])

    useEffect(() => {
        if (isEdit && changedData && !isValueChange) {
            setIsValueChange(true)
        }
    }, [changedData])

    const DIRECT_DELIVERY = '1' // 1: 直送 - classification 391
    const [deliveryDestinationType, setDeliveryDestinationType] = useState(initialValues.delivery_destination || '1')
    const handleDeliveryDestinationChange = value => {
        if (value === DIRECT_DELIVERY) {
            setOptDeliveryDestination(`${value}${directDeliveryType}`)
            if (directDeliveryType === '1') {
                setStateWithMultiDelivery()
            } else {
                setStateWithOneDelivery()
            }
        } else {
            setOptDeliveryDestination(value)
            // no effect if switch 2 <-> 1
            if (deliveryDestinationType === '1') {
                setStateWithoutDelivery()
            }
        }

        setDeliveryDestinationType(value)
    }
    const [optDeliveryDestination, setOptDeliveryDestination] = useState(
        initialValues.delivery_destinations && initialValues.delivery_destinations.length === 1 ? '12' : '11'
    )
    const [directDeliveryType, setDirectDeliveryType] = useState(
        initialValues.delivery_destinations && initialValues.delivery_destinations.length === 1 ? '2' : '1'
    )
    const handleDirectDeliveryTypeChange = value => {
        // to many
        if (value === '1') {
            setOptDeliveryDestination(`${deliveryDestinationType}${value}`)
            setStateWithMultiDelivery()
        } else {
            setOptDeliveryDestination(`${deliveryDestinationType}${value}`)
            setStateWithOneDelivery()
        }
        setDirectDeliveryType(value)
    }

    const Delivery = {
        DEFAULT: '1',
        DELIVERY: '2',
        VISIT: '3',
    }
    const [deliveryType, setDeliveryType] = useState(initialValues.delivery_type || Delivery.DEFAULT)
    initialValues = Object.assign(
        {
            instruction_code:
                project.project_code +
                (instructionType === 1
                    ? `-SN-${project.count_instruction_internal || 0}`
                    : `-GC-${project.count_instruction_outsource || 0}`),
            delivery_destination: DIRECT_DELIVERY, // 1: 直送 - classification 391
            delivery_type: '1', // 392
            transport_type: '1', // 393
            arrival_time: '1', // 390
            processing_arrival_time: '1', // 390
            scheduled_arrival_time: '1', // 394
            is_included: 0,
            is_hold_sales: false,
            is_specify_sales_date: false,
            is_serial_number_sales: false,
            is_additional_included: 0,
            is_replacement: 0,
            is_time_service: 0,
            is_invoice_no_notice: false,
            invoice_no_notice: '2', //395
            instruction_classification: instructionType, // 300 // 1:社内 2:外注
            gross_profit: 0,
            subtotal: 0,
            consumption_tax: 0,
            total: 0,
            // auto input
            customer_id: project?.customer?.id,
            customer: project?.customer,
            customer_pic_title: project?.customer?.customer_title,
            // case: outsource
            shipping_time: '-1', // 指定なし
            delivery_time: '1',
            received_order_date: moment().format('YYYY年M月DD日'),
            place_order_code: project.project_code + `-GC-${project.count_instruction_outsource || 0}`,
            outsource_timing: '1',
        },
        initialValues
    )

    // clone - generate project_code -> estimation_code
    useEffect(() => {
        if (!initialValues.instruction_code.startsWith(project.project_code)) {
            form.setFieldsValue({
                instruction_code: project.project_code + (instructionType === 1 ? '-SN-' : '-GC-') + 1,
            })
        }
    }, [project.project_code])

    if (!initialValues.client_id) {
        delete initialValues['client_id']
    }
    if (!initialValues.pic_id) {
        delete initialValues['pic_id']
    }
    if (!initialValues.customer_pic_name) {
        delete initialValues['customer_pic_name']
    }

    initialValues.project_id = project.id

    const [customer, setCustomer] = useState(projectForm ? initialValues.instruction_customer : initialValues.customer)

    if (!initialValues.arrival_date) {
        delete initialValues['arrival_date']
    }
    if (!initialValues.processing_arrival_date) {
        delete initialValues['processing_arrival_date']
    }
    if (!initialValues.delivery_date) {
        delete initialValues['delivery_date']
    }
    if (!initialValues.scheduled_arrival_date) {
        delete initialValues['scheduled_arrival_date']
    }
    if (!initialValues.specified_sales_date) {
        delete initialValues['specified_sales_date']
    }
    if (!initialValues.shipping_date) {
        delete initialValues['shipping_date']
    }
    if (!initialValues.received_order_date) {
        delete initialValues['received_order_date']
    }
    initialValues.arrival_date && (initialValues.arrival_date = moment.utc(initialValues.arrival_date, dateFormat))
    initialValues.processing_arrival_date &&
        (initialValues.processing_arrival_date = moment.utc(initialValues.processing_arrival_date, dateFormat))
    initialValues.delivery_date && (initialValues.delivery_date = moment.utc(initialValues.delivery_date, dateFormat))
    initialValues.scheduled_arrival_date &&
        (initialValues.scheduled_arrival_date = moment.utc(initialValues.scheduled_arrival_date, dateFormat))
    initialValues.specified_sales_date &&
        (initialValues.specified_sales_date = moment.utc(initialValues.specified_sales_date, dateFormat))
    initialValues.shipping_date && (initialValues.shipping_date = moment.utc(initialValues.shipping_date, dateFormat))
    initialValues.received_order_date &&
        (initialValues.received_order_date = moment.utc(initialValues.received_order_date, dateFormat))

    const handlerCustomerChange = id => {
        const getCustomer = async id => {
            const data = await api.get(APP_URLS.CUSTOMERS_DETAIL.replace(':id', id))
            if (data) {
                setCustomer(data)
                setResult(data.customer_pic ? data.customer_pic.split(',') : [])
                // set title
                form.setFieldsValue({
                    customer_pic_name: '',
                    customer_pic_title: data.customer_title,
                })
            }
        }
        getCustomer(id)
    }

    const [checkboxExt, setCheckboxExt] = useState({
        is_specify_sales_date: initialValues.is_specify_sales_date,
        is_serial_number_sales: initialValues.is_serial_number_sales,
        is_invoice_no_notice: initialValues.is_invoice_no_notice,
    })
    const checkboxGroup = [
        {
            text: t('column:Is included'),
            name: 'is_included',
        },
        {
            text: t('column:Is hold sales'),
            name: 'is_hold_sales',
        },
        {
            text: t('column:Specified sales date'),
            name: 'is_specify_sales_date',
            onChange: checked => {
                checkboxExt.is_specify_sales_date = checked
                setCheckboxExt({ ...checkboxExt })
            },
        },
        {
            text: t('column:Serial number sales'),
            name: 'is_serial_number_sales',
            onChange: checked => {
                checkboxExt.is_serial_number_sales = checked
                setCheckboxExt({ ...checkboxExt })
            },
        },
        {
            text: t('column:Is additional included'),
            name: 'is_additional_included',
        },
        {
            text: t('column:Is replacement'),
            name: 'is_replacement',
        },
        {
            text: t('column:Is time service'),
            name: 'is_time_service',
        },
        {
            text: t('column:Invoice no notice'),
            name: 'is_invoice_no_notice',
            onChange: checked => {
                checkboxExt.is_invoice_no_notice = checked
                setCheckboxExt({ ...checkboxExt })
            },
        },
    ]
    const checkboxOutsource = ['is_included', 'is_hold_sales', 'is_specify_sales_date', 'is_serial_number_sales']

    // special for preview
    const processData = () => {
        let tmpDeliveryDestinations = []
        let tmpDetails = []

        if (destinationsOrder.length && destinationsOrder[0] !== 'destinationUnknown') {
            destinationsOrder.map(destinationOrder => {
                if (destinationOrder === 'destinationUnknown') return null

                let destination = { ...destinations[destinationOrder] }
                destination.details = [...detailsOrder[destinationOrder].map(detailOrder => details[detailOrder])]
                // for preview group
                tmpDetails = [...tmpDetails, ...destination.details]
                tmpDeliveryDestinations.push(destination)
                return null
            })
        } else {
            detailsOrder['destinationUnknown'].map(detailOrder => tmpDetails.push(details[detailOrder]))
        }
        return { delivery_destinations: tmpDeliveryDestinations, details: tmpDetails }
    }

    const processOnSubmit = (values, redirectTo, confirmSave = true) => {
        if (isValueChange && isCreateProject) {
            setIsValueChange(false)
        }
        values.delivery_destinations = []

        // with destinations
        if (destinationsOrder.length && destinationsOrder[0] !== 'destinationUnknown') {
            destinationsOrder.map(destinationOrder => {
                if (destinationOrder === 'destinationUnknown') return null

                let destination = { ...destinations[destinationOrder] }
                destination.details = []

                detailsOrder[destinationOrder].map(detailOrder => destination.details.push(details[detailOrder]))

                values.delivery_destinations.push(destination)

                return null
            })
        } else {
            values.details = []
            detailsOrder['destinationUnknown'].map(detailOrder => values.details.push(details[detailOrder]))
        }

        if (values.delivery_destinations.length === 0) {
            // let filterDetails = values.details.filter(detail => !!detail.processed_product_classification)
            let filterDetails = values.details.filter(detail => !isHeadline(detail))
            if (filterDetails.length < 1 || filterDetails.filter(detail => !detail.title).length > 0) {
                showWarning('', '品名を入力してください。')
                return
            }
        } else {
            let filterDeliveryDestinations = values.delivery_destinations.filter(deliveryDestination => {
                let filterDetails = deliveryDestination.details.filter(detail => !isHeadline(detail))
                return filterDetails.length > 0
            })
            if (
                filterDeliveryDestinations.length < 1 ||
                filterDeliveryDestinations.filter(des => des.details.filter(d => !isHeadline(d) && !d.title).length > 0)
                    .length > 0
            ) {
                showWarning('', '品名を入力してください。')
                return
            }
        }

        if (
            deliveryDestinationType === DIRECT_DELIVERY &&
            detailsOrder['destinationUnknown'] &&
            detailsOrder['destinationUnknown'].length > 0
        ) {
            showWarning('', '納入先を入力してください。')
            return
        }

        if (confirmSave) setModalConfirmSave(true)
        else {
            if (projectForm) {
                const projectValues = {
                    id: project.id,
                    status: values.status,
                    project_name: values.project_name,
                    project_code: project.project_code,
                    customer_id: values.customer_id,
                    is_purchase_order: values.is_purchase_order,
                    pic_id: values.pic_id,
                    note: values.note,
                    instruction: {
                        ...values,
                        ...{
                            note: values.instruction_note,
                            pic_id: values.instruction_pic_id,
                            customer_id: values.instruction_customer_id,
                        },
                    },
                }
                onSubmit(projectValues, redirectTo)
            } else {
                onSubmit(values, redirectTo)
            }
            setDefaultState()
            return true
        }
    }

    const suggestionList = customer.customer_pic ? customer.customer_pic.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const location = useLocation()
    const isCreateProject = location.pathname.includes(Routes.private.business.PROJECT_CREATE.path)
    const canEditInstruction = can('INSTRUCTION_EDITABLE') && !project.is_locked

    const isOutsource = instructionType !== 1

    return (
        <>
            {canEditInstruction ? (
                <>
                    {modalConfirmSave && (
                        <InsModal
                            title="確認"
                            visible={modalConfirmSave}
                            onCancel={() => setModalConfirmSave(false)}
                            onOk={() => processOnSubmit(form.getFieldsValue(), null, false)}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <p>保存しますか？</p>
                        </InsModal>
                    )}
                    <RouterPrompt
                        handleSave={path => {
                            let values = form.getFieldsValue()
                            return processOnSubmit(values, path, false)
                        }}
                        isValueChange={isValueChange}
                        setDefaultState={() => setDefaultState()}
                    />
                    <div className="bl-form">
                        <Form
                            form={form}
                            initialValues={initialValues}
                            onFinish={values => setModalConfirmSave(true)}
                            onValuesChange={() => {
                                if (isEdit && !isValueChange) {
                                    setIsValueChange(true)
                                }
                            }}
                        >
                            <Row>
                                <Col>
                                    <h1 className="bl-form-title">{t('entity:Instruction')}作成</h1>
                                </Col>
                            </Row>

                            <Form.Item name="project_id" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>

                            <Form.Item name="instruction_classification" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>

                            <Row className="mb-16">
                                <Col className="bl-form-col" span={12}>
                                    <Form.Item label={t('column:Customer name')}>
                                        <Form.Item
                                            name={projectForm ? 'instruction_customer_id' : 'customer_id'}
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter customer name'),
                                                },
                                            ]}
                                        >
                                            <CustomerSelect
                                                placeholder={t('Customer search')}
                                                record={
                                                    projectForm
                                                        ? initialValues.instruction_customer
                                                        : initialValues.customer
                                                }
                                                style={{ width: 274 }}
                                                onChange={(id, customer) => {
                                                    handlerCustomerChange(id, customer)
                                                }}
                                                // showListLink={false}
                                                showCreateButton={false}
                                                // showDetailLink={false}
                                            />
                                        </Form.Item>
                                    </Form.Item>

                                    <Form.Item label={t('column:Customer pic name')}>
                                        <Form.Item name="customer_pic_name" noStyle>
                                            <InsAutoComplete
                                                options={result}
                                                onSearch={handleSuggestion}
                                                placeholder="担当者検索"
                                                className="inline"
                                                style={{ width: 180 }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="customer_pic_title" noStyle>
                                            <InsSelect style={{ width: 90, marginLeft: 4 }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.CUSTOMER_TITLE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Form.Item>

                                    <Form.Item label={t('column:Arrival date')}>
                                        <Form.Item name="arrival_date" noStyle>
                                            <InsDatePicker
                                                format={dateFormat}
                                                placeholder={t('common:Enter {{item}}', {
                                                    item: t('column:Arrival date'),
                                                })}
                                                style={{ width: 180 }}
                                                allowClear={false}
                                            />
                                        </Form.Item>
                                        <Form.Item name="arrival_time" noStyle>
                                            <InsSelect style={{ width: isOutsource ? 108 : 90, marginLeft: 4 }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Form.Item>

                                    {!isOutsource ? (
                                        <Form.Item label={t('column:Processing arrival date')}>
                                            <Form.Item name="processing_arrival_date" noStyle>
                                                <InsDatePicker
                                                    format={dateFormat}
                                                    placeholder={t('Enter processing arrival date')}
                                                    style={{ width: 180 }}
                                                    allowClear={false}
                                                />
                                            </Form.Item>
                                            <Form.Item name="processing_arrival_time" noStyle>
                                                <InsSelect style={{ width: 90, marginLeft: 4 }}>
                                                    {getClassificationsByType(
                                                        ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value
                                                    ).map(clf => (
                                                        <Select.Option key={clf.value} value={clf.value}>
                                                            {clf.text}
                                                        </Select.Option>
                                                    ))}
                                                </InsSelect>
                                            </Form.Item>
                                        </Form.Item>
                                    ) : (
                                        <>
                                            <Form.Item label={'出荷日'}>
                                                <Form.Item name="shipping_date" noStyle>
                                                    <InsDatePicker
                                                        format={dateFormat}
                                                        placeholder={t('出荷日入力')}
                                                        style={{ width: 180 }}
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item label={'納品日'}>
                                                <Form.Item name="delivery_date" noStyle>
                                                    <InsDatePicker
                                                        format={dateFormat}
                                                        placeholder={t('納品日入力')}
                                                        style={{ width: 180 }}
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="delivery_time" noStyle>
                                                    <InsSelect style={{ width: 108, marginLeft: 4 }}>
                                                        {getClassificationsByType(
                                                            ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value
                                                        ).map(clf => (
                                                            <Select.Option key={clf.value} value={clf.value}>
                                                                {clf.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Form.Item>
                                        </>
                                    )}

                                    <Form.Item label={t('column:Delivery destination')}>
                                        <Form.Item name="delivery_destination" noStyle>
                                            <InsSelect
                                                style={{ width: 92, marginRight: 4 }}
                                                onChange={value => handleDeliveryDestinationChange(value)}
                                            >
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.DELIVERY_TYPE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                        {deliveryDestinationType === DIRECT_DELIVERY && (
                                            <Form.Item noStyle>
                                                <InsSelect
                                                    defaultValue={directDeliveryType}
                                                    style={{ width: 180 }}
                                                    onChange={value => handleDirectDeliveryTypeChange(value)}
                                                >
                                                    <Select.Option value={'1'}>{'商品ごと設定'}</Select.Option>
                                                    <Select.Option value={'2'}>{'全ての直送先に送る'}</Select.Option>
                                                </InsSelect>
                                            </Form.Item>
                                        )}
                                    </Form.Item>

                                    <Form.Item name="shipper_id" label={t('column:Shipper')}>
                                        <ShipperSelect
                                            placeholder={t('Shipper search')}
                                            record={initialValues.shipper}
                                            style={{ width: 220 }}
                                            onChange={(id, option) => {
                                                form.setFieldsValue({
                                                    shipper: option.item || option,
                                                    shipper_type: option.item?.shipper_type || option.shipper_type,
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="shipper" style={{ display: 'none' }}>
                                        <InsInput />
                                    </Form.Item>
                                    <Form.Item name="shipper_type" style={{ display: 'none' }}>
                                        <InsInput />
                                    </Form.Item>
                                </Col>
                                <Col className="bl-form-col" span={12}>
                                    <Form.Item
                                        name="instruction_code"
                                        label={t('column:Instruction code')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter Instruction code'),
                                                // check unique
                                            },
                                            {
                                                // trigger: 'onBlur',
                                                validator: async (rule, value) => {
                                                    if (
                                                        isEdit &&
                                                        initialValues.instruction_code.trim() === value.trim()
                                                    )
                                                        return Promise.resolve()

                                                    if (value.trim()) {
                                                        const data = await api.post(
                                                            APP_URLS.INSTRUCTION_CHECK_EXISTS_CODE,
                                                            {
                                                                instruction_code: value,
                                                            }
                                                        )
                                                        if (data && data.is_existed) {
                                                            return Promise.reject(
                                                                t('validation:Instruction code existed')
                                                            )
                                                        }
                                                    }

                                                    return Promise.resolve()
                                                },
                                            },
                                            {
                                                max: 20,
                                                message: t('validation:{{item}} is invalid', {
                                                    item: t('column:Instruction code'),
                                                }),
                                            },
                                        ]}
                                        validateTrigger={['onBlur']}
                                        className="hide-require"
                                    >
                                        <InsInput style={{ width: 180 }} />
                                    </Form.Item>

                                    {!isOutsource ? (
                                        <>
                                            <Form.Item label={t('column:Delivery type')}>
                                                <Form.Item name="delivery_type" noStyle>
                                                    <InsSelect
                                                        style={{ width: 92 }}
                                                        onChange={value => setDeliveryType(value)}
                                                    >
                                                        {getClassificationsByType(
                                                            ClassificationTypeEnum.DELIVERY_CATEGORY_1.value
                                                        ).map(clf => (
                                                            <Select.Option key={clf.value} value={clf.value}>
                                                                {clf.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                                {deliveryType === Delivery.DEFAULT && (
                                                    <Form.Item name="transport_type" noStyle>
                                                        <InsSelect
                                                            style={{ width: 180, marginLeft: 4 }}
                                                            optionLabelProp="label"
                                                        >
                                                            {getClassificationsByType(
                                                                ClassificationTypeEnum.DELIVERY_CATEGORY_2.value
                                                            ).map(clf => (
                                                                <Select.Option
                                                                    key={clf.value}
                                                                    value={clf.value}
                                                                    label={`${clf.text}宅急便`}
                                                                >
                                                                    {clf.text}
                                                                </Select.Option>
                                                            ))}
                                                        </InsSelect>
                                                    </Form.Item>
                                                )}
                                            </Form.Item>

                                            <Form.Item label={t('column:Delivery date')}>
                                                <Form.Item name="delivery_date" noStyle>
                                                    <InsDatePicker
                                                        format={dateFormat}
                                                        placeholder={t('common:Enter {{item}}', {
                                                            item: t('column:Delivery date'),
                                                        })}
                                                        style={{ width: 150 }}
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                                <span className="delivery-type-label">
                                                    {deliveryType === Delivery.DELIVERY
                                                        ? '←持ち出し'
                                                        : deliveryType === Delivery.VISIT
                                                        ? 'ご来社'
                                                        : '出荷'}
                                                </span>
                                            </Form.Item>

                                            <Form.Item label={t('column:Scheduled arrival date')}>
                                                <Form.Item name="scheduled_arrival_date" noStyle>
                                                    <InsDatePicker
                                                        format={dateFormat}
                                                        placeholder={t('common:Enter {{item}}', {
                                                            item: t('column:Scheduled arrival date'),
                                                        })}
                                                        style={{ width: 150 }}
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="scheduled_arrival_time" noStyle>
                                                    <InsSelect style={{ width: 140, marginLeft: 4 }}>
                                                        {getClassificationsByType(
                                                            ClassificationTypeEnum.INSTRUCTION_ARRIVAL_SCHEDULED.value
                                                        ).map(clf => (
                                                            <Select.Option key={clf.value} value={clf.value}>
                                                                {clf.text}
                                                            </Select.Option>
                                                        ))}
                                                    </InsSelect>
                                                </Form.Item>
                                            </Form.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Form.Item name="received_order_date" label={t('受注日')}>
                                                <InsDatePicker
                                                    format={dateFormat}
                                                    placeholder={t('common:Enter {{item}}', {
                                                        item: '受注日',
                                                    })}
                                                    style={{ width: 180 }}
                                                    allowClear={false}
                                                />
                                            </Form.Item>
                                            <Form.Item name="place_order_code" label={t('発注書番号')}>
                                                <InsInput
                                                    style={{ width: 180 }}
                                                    placeholder={t('common:Enter {{item}}', {
                                                        item: '発注書番号',
                                                    })}
                                                />
                                            </Form.Item>
                                            <Form.Item name="outsource_id" label={t('発注先')}>
                                                <OutsourceSelect
                                                    placeholder={t('発注先検索')}
                                                    record={initialValues.outsource}
                                                    style={{ width: 180 }}
                                                    onChange={(id, option) => {
                                                        form.setFieldsValue({ outsource: option.item || option })
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item name="outsource" style={{ display: 'none' }}>
                                                <InsInput />
                                            </Form.Item>
                                        </>
                                    )}

                                    <Form.Item
                                        name={projectForm ? 'instruction_pic_id' : 'pic_id'}
                                        label={t('Inasa representative')}
                                    >
                                        <UserSelect
                                            record={projectForm ? initialValues.instruction_pic : initialValues.pic}
                                            style={{ width: 180 }}
                                            placeholder={t('Inasa person search')}
                                            dropdownMatchSelectWidth={false}
                                        />
                                    </Form.Item>

                                    <Form.Item className="mt-16 mb-0 bl-form-checkbox-group">
                                        {checkboxGroup
                                            .filter(c => !isOutsource || checkboxOutsource.includes(c.name))
                                            .map((checkbox, index) => (
                                                <Form.Item
                                                    name={checkbox.name}
                                                    valuePropName="checked"
                                                    noStyle
                                                    key={index}
                                                >
                                                    <Checkbox
                                                        onChange={value =>
                                                            checkbox.onChange && checkbox.onChange(value.target.checked)
                                                        }
                                                    >
                                                        {checkbox.text}
                                                    </Checkbox>
                                                </Form.Item>
                                            ))}
                                    </Form.Item>

                                    {isOutsource && (
                                        <Form.Item
                                            name="outsource_timing"
                                            label={t('発注タイミング')}
                                            className="bl-form-checkbox-group-mg"
                                        >
                                            <InsSelect style={{ width: 180 }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.OUTSOURCE_TIMING.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    )}

                                    {checkboxExt.is_specify_sales_date && (
                                        <Form.Item
                                            name="specified_sales_date"
                                            label={t('column:Specified sales date')}
                                            className="bl-form-checkbox-group-mg"
                                        >
                                            <InsDatePicker
                                                format={dateFormat}
                                                placeholder={t('common:Enter {{item}}', {
                                                    item: t('column:Specified sales date'),
                                                })}
                                                style={{ width: 150 }}
                                                allowClear={false}
                                            />
                                        </Form.Item>
                                    )}

                                    {checkboxExt.is_serial_number_sales && (
                                        <Form.Item
                                            name="serial_number_sales"
                                            label={t('column:Serial number sales')}
                                            className="bl-form-checkbox-group-mg"
                                        >
                                            <InsInput
                                                style={{ width: 150 }}
                                                placeholder={t('common:Enter {{item}}', {
                                                    item: t('column:Serial number sales'),
                                                })}
                                            />
                                        </Form.Item>
                                    )}
                                    {checkboxExt.is_invoice_no_notice && (
                                        <Form.Item
                                            name="invoice_no_notice"
                                            label={t('column:Invoice no notice')}
                                            className="bl-form-checkbox-group-mg"
                                        >
                                            <InsSelect style={{ width: 180 }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.INSTRUCTION_NO_INVOICE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>

                            <InstructionDragAndDrop optDeliveryDestination={optDeliveryDestination} />

                            <Row className="bl-form-detail-control" justify="space-between">
                                <InstructionDetailAddButton />
                                <Row className="total">
                                    <Form.Item name="gross_profit" label="粗利">
                                        <InsInputNumber disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="subtotal" label="小計">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="consumption_tax" label="消費税">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="total" label="合計	：:">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                </Row>
                            </Row>

                            <h3>備考</h3>
                            <Form.Item name={projectForm ? 'instruction_note' : 'note'}>
                                <InsTextArea allowClear={false} />
                            </Form.Item>
                            <InsFloatBar visible={true} className="with-sidebar z-9999">
                                <Row gutter={8}>
                                    <Col>
                                        <InstructionPreviewModal
                                            project={project}
                                            instructionType={instructionType}
                                            instruction={Object.assign(
                                                {},
                                                initialValues,
                                                form.getFieldsValue(),
                                                processData()
                                            )}
                                            visible={modalPreviewVisible}
                                            onCancel={() => {
                                                setModalPreviewVisible(false)
                                            }}
                                        >
                                            <InsButton
                                                buttonStyle="default"
                                                buttonSize="medium"
                                                centered
                                                icon={<PreviewIcon size={16} className="color-pantone-2738-c" />}
                                                onClick={() => {
                                                    setModalPreviewVisible(true)
                                                }}
                                            >
                                                {t('common:Preview')}
                                            </InsButton>
                                        </InstructionPreviewModal>
                                    </Col>
                                </Row>
                                <Row gutter={8} justify="end">
                                    <Col>
                                        <InsButton
                                            buttonStyle="outline"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            onClick={() => {
                                                if (isCreateProject) {
                                                    history.push(`${Routes.private.business.PROJECT.path}`)
                                                } else {
                                                    history.goBack()
                                                }
                                            }}
                                        >
                                            {t('common:Cancel')}
                                        </InsButton>
                                    </Col>
                                    <Col>
                                        <InsButton
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            isSubmitButton={true}
                                        >
                                            {isEdit ? t('common:Save') : t('common:Save')}
                                        </InsButton>
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        </Form>
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    )
}

const mapState = state => ({
    destinations: state.instructionDetail.destinations,
    destinationsOrder: state.instructionDetail.destinationsOrder,
    details: state.instructionDetail.details,
    detailsOrder: state.instructionDetail.detailsOrder,
    changedData: state.instructionDetail.changedData,
})

const mapDispatch = {
    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,
    setDefaultState,
    setInstructionType,
    setStateWithoutDelivery,
    setStateWithOneDelivery,
    setStateWithMultiDelivery,
    parseData,
}

export default connect(mapState, mapDispatch)(InstructionForm)

InstructionForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    project: PropTypes.object.isRequired,
    instructionType: PropTypes.number.isRequired,
    destinations: PropTypes.object,
    destinationsOrder: PropTypes.array,
    details: PropTypes.object,
    detailsOrder: PropTypes.object,
    setDestinations: PropTypes.func.isRequired,
    setDestinationsOrder: PropTypes.func.isRequired,
    setDetails: PropTypes.func.isRequired,
    setDetailsOrder: PropTypes.func.isRequired,
    setDefaultState: PropTypes.func.isRequired,
    setInstructionType: PropTypes.func.isRequired,
    setStateWithoutDelivery: PropTypes.func.isRequired,
    setStateWithOneDelivery: PropTypes.func.isRequired,
    setStateWithMultiDelivery: PropTypes.func.isRequired,
    parseData: PropTypes.func.isRequired,
    changedData: PropTypes.bool.isRequired,
    projectForm: PropTypes.any,
    projectCreateDoc: PropTypes.bool,
}
