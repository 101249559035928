import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import PlaceOrderForm from 'modules/business/placeOrder/form/PlaceOrderForm'
import { setUser } from 'modules/common/auth/auth.slice'
import { InsBlockLoader } from 'components/InsLoader'
import { setDefaultState } from 'modules/business/placeOrder/place_order_detail.slice'

const PlaceOrderCreate = ({ project, updateLink, setUser, currentUser, createDocumentFrom, setDefaultState }) => {
    const [loading, setLoading] = useState(false)
    const createPlaceOrder = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.post(APP_URLS.PLACE_ORDER, values)
        if (data) {
            setUser({
                ...currentUser,
                processing_product: data.processing_product,
                processing_spec: data.processing_spec,
                processing_size: data.processing_size,
            })
            setLoading(false)
            updateLink(redirectTo)
            setDefaultState()
        }
    }

    const getInitialValues = () => {
        let placeOrder = {}
        if (createDocumentFrom.estimation) {
            let details = project.estimation.detail
            let placeOrderDetails = []
            details.forEach(detail => {
                if (!detail.headline) placeOrderDetails.push({ ...detail, ...{ title: detail.product_name } })
                else placeOrderDetails.push({ ...detail })
            })
            placeOrder = {
                ...project.estimation,
                ...{ details: placeOrderDetails, delivery_destinations: [] },
            }
        } else if (createDocumentFrom.instruction_internal) {
            placeOrder = project.instruction_internal
            delete placeOrder['place_order_code']
        } else if (createDocumentFrom.instruction_outsource) {
            placeOrder = project.instruction_outsource
            delete placeOrder['place_order_code']
        } else if (createDocumentFrom.invoice) {
            placeOrder = project.invoice
        } else if (createDocumentFrom.sale) {
            placeOrder = project.sale
        }
        return placeOrder
    }

    return (
        <div className="p-24">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <PlaceOrderForm
                    project={project}
                    initialValues={getInitialValues()}
                    onSubmit={(values, redirectTo) => createPlaceOrder(values, redirectTo)}
                />
            )}
        </div>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUser,
    setDefaultState,
}

export default connect(mapState, mapDispatch)(PlaceOrderCreate)

PlaceOrderCreate.propTypes = {
    project: PropTypes.object.isRequired,
    isCreateProject: PropTypes.bool,
    updateLink: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
    createDocumentFrom: PropTypes.object,
    setDefaultState: PropTypes.func,
}
