import React from 'react'
import PropTypes from 'prop-types'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import TrackingForm from 'modules/business/tracking/form/TrackingForm'

const TrackingCreate = ({ project, response }) => {
    const createTracking = async values => {
        const data = await api.post(APP_URLS.TRACKING, values)
        if (data) {
            response()
        }
    }

    return <TrackingForm project={project} onSubmit={values => createTracking(values)} />
}

export default TrackingCreate

TrackingCreate.propTypes = {
    project: PropTypes.object.isRequired,
    response: PropTypes.func,
}
