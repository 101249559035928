import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print'
import { PrintIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import { useTranslation } from 'react-i18next'
import FloatBarBusinessToPrint from 'modules/business/common/FloatBarBusinessToPrint'

const FloatBarButtonPrint = ({ data, selectedIds, setSelectedIds, documentType }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)

    const printRef = useRef([])
    printRef.current = [...selectedIds].map((ref, index) => (printRef.current[index] = React.createRef()))

    let taskNumber = -1

    const handlePrint = useReactToPrint({
        content: () => printRef.current[taskNumber].current,
        onBeforeGetContent: () => {
            taskNumber++
        },
        onBeforePrint: () => {},
        onAfterPrint: async () => {
            if (taskNumber === selectedIds.length - 1) {
                taskNumber = -1
                setSelectedIds([])
                // end process
                setIsProcessing(false)
            } else {
                handlePrint()
            }
        },
        copyStyles: true,
    })

    // handle Print
    const handlePrintButton = async () => {
        if (isProcessing) return
        // start process
        setIsProcessing(true)
        handlePrint()
    }

    return (
        <>
            {selectedIds.map((id, index) => {
                const d = data.find(dt => dt.id === id)
                return (
                    <FloatBarBusinessToPrint
                        key={id || index}
                        data={d}
                        document={documentType}
                        printRef={printRef.current[index]}
                    />
                )
            })}
            <InsButton
                icon={<PrintIcon size={16} className="color-pantone-2738-c" />}
                buttonStyle="default"
                className="mr-8"
                style={{ minWidth: 69 }}
                onClick={handlePrintButton}
            >
                {t('Print')}
            </InsButton>
        </>
    )
}

export default FloatBarButtonPrint

FloatBarButtonPrint.propTypes = {
    data: PropTypes.any,
    selectedIds: PropTypes.array,
    setSelectedIds: PropTypes.func,
    documentType: PropTypes.any,
}
