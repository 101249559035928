import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import ShippingAddressForm from 'modules/master/shippingAddress/ShippingAddressForm'
import InsBackLink from 'components/InsBackLink'

const ShippingAddressCreate = () => {
    const { t } = useTranslation()

    const createShippingAddress = async values => {
        const data = await api.post(APP_URLS.SHIPPING_ADDRESS, values)
        if (data) {
            history.goBack()
        }
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} create', { entity: t('entity:ShippingAddress') })}
            </h1>
            <ShippingAddressForm
                modalConfirmSave={modalConfirmSave}
                setModalConfirmSave={setModalConfirmSave}
                onSubmit={(values, redirectTo, confirmSave = true) => {
                    if (confirmSave) setModalConfirmSave(true)
                    else {
                        createShippingAddress(values, redirectTo, confirmSave)
                        setModalConfirmSave(false)
                    }
                }}
            />
        </div>
    )
}

export default ShippingAddressCreate
