import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getData, setPagination, setFilters, setSorter, setKeywords } from 'modules/master/customer/customer.slice'
import CustomerListComponent from './CustomerListComponent'
import { BaseStatePropShape } from 'constants/proptypes.constant'

const CustomerList = ({ state }) => {
    return (
        <div className="page-container with-sidebar">
            <CustomerListComponent
                state={state}
                getData={getData}
                setPagination={setPagination}
                setFilters={setFilters}
                setSorter={setSorter}
                setKeywords={setKeywords}
            />
        </div>
    )
}

const mapState = state => ({ state: state.customer })
export default connect(mapState)(CustomerList)

CustomerList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
}
