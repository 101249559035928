import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Select } from 'antd'
import InsInput from 'components/InsInput'
import { getClassificationsByType } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsSelect from 'components/select/InsSelect'
import InsInputNumber from 'components/InsInputNumber'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import { CommentIcon, DescriptionIcon, SubdirectoryArrowRightIcon } from 'components/InsIcon'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateDetail } from 'modules/business/instruction/instruction_detail.slice'
import InsAutoComplete from 'components/InsAutoComplete'
import { isEmpty, rdStr } from 'helpers/string.helper'
import InsInputNumber2 from 'components/InsInputNumber2'
import { isHeadline } from 'helpers/detail.helper'
import { formCalculateDetailAmount } from 'helpers/calculate.helper'
import InsTextArea from 'components/InsTextArea'

const InstructionDetailRow = React.memo(({ id, value, updateDetail, instructionType, currentUser }) => {
    const { t } = useTranslation()
    const [noteVisible, setNoteVisible] = useState(!isEmpty(value.note))
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(value)
    }, [form, instructionType])

    let timeout = null
    const changeHandle = () => {
        if (timeout !== null) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            syncFormData()
        }, 300)
    }
    const syncFormData = () => {
        let data = form.getFieldsValue([
            'lam_thickness',
            'mimi',
            'rounded_corners',
            'suji_oshi',
            'cutting',
            'media',
            'printing',
            'printing_paper',
            'printing_form',
            'cold',
            'oberami',
            'panel_thickness',
            'product_name',
            'material',
            'chichi',
            'heat_cut',
            'mimaki',
            'bias',
            'back',
            'pocket',
            'bag_stopper',
            'cap',
            'pole_material',
            'pole_length',
        ])
        updateDetail({ id, value: Object.assign({}, form.getFieldsValue(), data) })
    }

    const calculateDetailAmount = () => formCalculateDetailAmount(form)

    const isInternal = instructionType === 1

    const [processedProductType, setProcessedProductType] = useState(value.processed_product_classification || '1')

    useEffect(() => {
        // set init value
        if (!value.id) {
            form.setFieldsValue(nullableExtFields)
            form.setFieldsValue(isInternal ? InternalProductTypes['1'] : OutsourceProductTypes['1'])
            form.setFieldsValue({
                processed_product_classification: '1',
                quantity: 1,
                unit: '2',
                unit_cost: '0.00',
                unit_price: 0,
                tax_rate: '10',
                amount: 0,
            })
            syncFormData()
        }
    }, [isInternal])

    const DASH_X_O = {
        DASH: '-',
        X: '×',
        O: '〇',
    }

    const InternalProductTypes = {
        // ラミネート
        '1': {
            lam_thickness: '7', // 304	7	350μ
            mimi: '1', // 305	1	あり
            rounded_corners: DASH_X_O.DASH,
            suji_oshi: DASH_X_O.O,
            cutting: DASH_X_O.X,
        },
        // 札
        '2': {
            lam_thickness: '7', // 304	7	350μ
            mimi: '3', // 305	3	4
            rounded_corners: DASH_X_O.O,
            printing: '1', // 309	1	片面
            printing_paper: '1', // 310	1	マットカード18㎏
        },
        // 大判
        '3': {
            lam_thickness: '4', // 304	4	150μ
            mimi: '5', // 305	5	10
            rounded_corners: DASH_X_O.X,
            media: '4', // 306	4	合成紙糊付き
        },
        // 溶剤
        '4': {
            media: '7', // 307	7	1/4モザイクシルバー
            printing_form: '1', // 311	1	プロッタ
            cold: '6', // 312	6	リピール
            oberami: '7', // 313	7	30
        },
        // パネル
        '5': {
            lam_thickness: '1', // 304	1	38μ
            media: '3', // 308	3	コート135K
            printing: '2', // 309	2	両面
            panel_thickness: '5', // 314	2	7mm黒
        },
    }

    const OutsourceProductTypes = {
        // のぼり
        '1': {
            product_name: '1', // 315
            material: '8', // 318 8 遮光メッシュターポリン
            chichi: '3', // 319 3 袋チチ(上･左)
            heat_cut: '0', // 320 ○
            mimaki: '1', // 321 右･下
        },
        // イスカバー
        '2': {
            product_name: '2', // 316	2	パイプ椅子用
            material: '20', // 318 20 防炎ポリエステルクロス
            bias: '', // Free text input
            back: '', // Free text input
            pocket: '0', // 322 ○
        },
        // 三角フラッグ
        '3': {
            printing: '1', // 309	1	片面
            product_name: '1', // 317	1	三角ﾌﾗｯｸﾞ
            material: '8', // 318 8 遮光メッシュターポリン
            bag_stopper: '3', // 323 3 先端
            cap: '0', // 324 0 あり
            pole_material: '2', // 325 2 アルミ
            pole_length: '3', // 326 3 1200mm
        },
        // その他
        '4': {
            product_name: '', // Free text input
            material: '10', // 318 10 防炎メッシュターポリン
        },
    }
    const getFieldsByProductType = productType => {
        return isInternal ? InternalProductTypes[productType] : OutsourceProductTypes[productType]
    }

    const renderProductExtends = () => {
        const fields = getFieldsByProductType(processedProductType)

        return (
            <>
                {fields?.lam_thickness && (
                    <div className="wrap-field">
                        <label>ラミ厚</label>
                        <Form.Item name="lam_thickness">
                            <InsSelect style={{ width: 85 }}>
                                {getClassificationsByType(ClassificationTypeEnum.LAMINATED_PRODUCT_THICKNESS.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.mimi && (
                    <div className="wrap-field">
                        <label>ﾐﾐ幅</label>
                        <Form.Item name="mimi">
                            <InsSelect style={{ width: 64 }}>
                                {getClassificationsByType(ClassificationTypeEnum.PROCESSED_PRODUCT_MIMI.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.rounded_corners && (
                    <div className="wrap-field">
                        <label>角丸</label>
                        <Form.Item name="rounded_corners">
                            <InsSelect style={{ width: 56 }}>
                                {Object.keys(DASH_X_O).map(k => (
                                    <Select.Option key={k} value={DASH_X_O[k]}>
                                        {DASH_X_O[k]}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.suji_oshi && (
                    <div className="wrap-field">
                        <label>ｽｼﾞ押し</label>
                        <Form.Item name="suji_oshi">
                            <InsSelect style={{ width: 56 }}>
                                {Object.keys(DASH_X_O).map(k => (
                                    <Select.Option key={k} value={DASH_X_O[k]}>
                                        {DASH_X_O[k]}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.cutting && (
                    <div className="wrap-field">
                        <label>断裁</label>
                        <Form.Item name="cutting">
                            <InsSelect style={{ width: 56 }}>
                                {Object.keys(DASH_X_O).map(k => (
                                    <Select.Option key={k} value={DASH_X_O[k]}>
                                        {DASH_X_O[k]}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.media && (
                    <div className="wrap-field">
                        <label>メディア</label>
                        <Form.Item name="media">
                            <InsSelect
                                style={{
                                    width:
                                        processedProductType === '3' ? 135 : processedProductType === '4' ? 180 : 120,
                                }}
                            >
                                {getClassificationsByType(
                                    // special for 3 (306), 4 (307), 5 (308)
                                    processedProductType === '3'
                                        ? ClassificationTypeEnum.LARGE_FORMAT_PROCESSED_MEDIA.value
                                        : processedProductType === '4'
                                        ? ClassificationTypeEnum.SOLVENT_PROCESSED_MEDIA.value
                                        : processedProductType === '5'
                                        ? ClassificationTypeEnum.PANEL_PROCESSED_MEDIA.value
                                        : []
                                ).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.printing && (
                    <div className="wrap-field">
                        <label>印刷</label>
                        <Form.Item name="printing">
                            <InsSelect style={{ width: 64 }}>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_PRINTING.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.printing_paper && (
                    <div className="wrap-field">
                        <label>用紙</label>
                        <Form.Item name="printing_paper">
                            <InsSelect style={{ width: 160 }}>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_PAPER.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.printing_form && (
                    <div className="wrap-field">
                        <label>形</label>
                        <Form.Item name="printing_form">
                            <InsSelect style={{ width: 100 }}>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_SHAPE.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.cold && (
                    <div className="wrap-field">
                        <label>ゴールド</label>
                        <Form.Item name="cold">
                            <InsSelect style={{ width: 100 }}>
                                {getClassificationsByType(ClassificationTypeEnum.PROCESSED_GOLD.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.oberami && (
                    <div className="wrap-field">
                        <label>オーパーラミ</label>
                        <Form.Item name="oberami">
                            <InsSelect style={{ width: 64 }}>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_OVER_LAMINATION.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.panel_thickness && (
                    <div className="wrap-field">
                        <label>パネル厚</label>
                        <Form.Item name="panel_thickness">
                            <InsSelect style={{ width: 86 }}>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_PANEL_THICKNESS.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.product_name && (
                    <div className="wrap-field">
                        <label>商品名</label>
                        <Form.Item name="product_name">
                            {processedProductType === '4' ? (
                                // special for 4
                                <InsInput style={{ width: 200 }} />
                            ) : (
                                <InsSelect
                                    style={{
                                        width: processedProductType === '3' ? 104 : 125,
                                    }}
                                >
                                    {getClassificationsByType(
                                        // special for 1 (315), 2 (316), 3 (317)
                                        processedProductType === '1'
                                            ? ClassificationTypeEnum.CLIMBING_PRODUCT_NAME_CATEGORY.value
                                            : processedProductType === '2'
                                            ? ClassificationTypeEnum.CHAIR_COVER_PRODUCT_NAME_CATEGORY.value
                                            : processedProductType === '3'
                                            ? ClassificationTypeEnum.TRIANGLE_FLAG_PRODUCT_NAME_CATEGORY.value
                                            : []
                                    ).map(clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            )}
                        </Form.Item>
                    </div>
                )}
                {fields?.material && (
                    <div className="wrap-field">
                        <label>素材</label>
                        <Form.Item name="material">
                            <InsSelect style={{ width: 200 }}>
                                {getClassificationsByType(ClassificationTypeEnum.PROCESSED_MATERIAL.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.chichi && (
                    <div className="wrap-field">
                        <label>チチ</label>
                        <Form.Item name="chichi">
                            <InsSelect style={{ width: 130 }}>
                                {getClassificationsByType(ClassificationTypeEnum.PROCESSED_PRODUCT_CLASS.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.heat_cut && (
                    <div className="wrap-field">
                        <label>ヒートカット</label>
                        <Form.Item name="heat_cut">
                            <InsSelect style={{ width: 64 }}>
                                {getClassificationsByType(ClassificationTypeEnum.PROCESSED_PRODUCT_HEAT_CUT.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.mimaki && (
                    <div className="wrap-field">
                        <label>三巻</label>
                        <Form.Item name="mimaki">
                            <InsSelect style={{ width: 80 }}>
                                {getClassificationsByType(ClassificationTypeEnum.THREE_PIECE_WORKPIECE.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.bias && (
                    <div className="wrap-field">
                        <label>バイアス</label>
                        <Form.Item name="bias">
                            <InsInput style={{ width: 200 }} />
                        </Form.Item>
                    </div>
                )}
                {fields?.back && (
                    <div className="wrap-field">
                        <label>背面</label>
                        <Form.Item name="back">
                            <InsInput style={{ width: 200 }} />
                        </Form.Item>
                    </div>
                )}
                {fields?.pocket && (
                    <div className="wrap-field">
                        <label>ポケット</label>
                        <Form.Item name="pocket" style={{ width: 64 }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_POCKET.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.bag_stopper && (
                    <div className="wrap-field">
                        <label>袋止め</label>
                        <Form.Item name="bag_stopper" style={{ width: 64 }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_BAG_STOP.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.cap && (
                    <div className="wrap-field">
                        <label>キャップ</label>
                        <Form.Item name="cap" style={{ width: 64 }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_CAP.value).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.pole_material && (
                    <div className="wrap-field">
                        <label>ポール素材</label>
                        <Form.Item name="pole_material" style={{ width: 80 }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_POLE_MATERIAL.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
                {fields?.pole_length && (
                    <div className="wrap-field">
                        <label>ポール長さ</label>
                        <Form.Item name="pole_length" style={{ width: 100 }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.WORKPIECE_POLE_LENGTH.value).map(
                                    clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    )
                                )}
                            </InsSelect>
                        </Form.Item>
                    </div>
                )}
            </>
        )
    }

    const nullableExtFields = {
        lam_thickness: null,
        mimi: null,
        rounded_corners: null,
        suji_oshi: null,
        cutting: null,
        media: null,
        printing: null,
        printing_paper: null,
        printing_form: null,
        cold: null,
        oberami: null,
        panel_thickness: null,
        product_name: null,
        material: null,
        chichi: null,
        heat_cut: null,
        mimaki: null,
        bias: null,
        back: null,
        pocket: null,
        bag_stopper: null,
        cap: null,
        pole_material: null,
        pole_length: null,
    }

    const handleProductTypeChange = value => {
        const fields = getFieldsByProductType(value)
        form.setFieldsValue(Object.assign({}, nullableExtFields, fields))
        setProcessedProductType(value)
    }

    const suggestionList = currentUser.processing_product ? currentUser.processing_product.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const suggestionListSpec = currentUser.processing_spec ? currentUser.processing_spec.split(',') : []
    const [resultSpec, setResultSpec] = useState(suggestionListSpec)
    const handleSuggestionSpec = value => {
        setResultSpec(suggestionListSpec.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const suggestionListSize = currentUser.processing_size ? currentUser.processing_size.split(',') : []
    const [resultSize, setResultSize] = useState(suggestionListSize)
    const handleSuggestionSize = value => {
        setResultSize(suggestionListSize.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    return (
        <Form form={form} onValuesChange={changeHandle} name={rdStr()}>
            {isHeadline(value) ? (
                <Form.Item name="headline">
                    <InsInput placeholder={t('column:Headline')} />
                </Form.Item>
            ) : (
                <>
                    <Row align="middle">
                        <Form.Item name="title" style={{ flex: 180 }}>
                            <InsAutoComplete options={result} onSearch={handleSuggestion} placeholder="タイトル" />
                        </Form.Item>

                        <Form.Item name="spec" style={{ flex: 174 }}>
                            <InsAutoComplete
                                options={resultSpec}
                                onSearch={handleSuggestionSpec}
                                placeholder={t('column:Spec')}
                            />
                        </Form.Item>
                        <Form.Item name="size" style={{ flex: 116 }}>
                            <InsAutoComplete
                                options={resultSize}
                                onSearch={handleSuggestionSize}
                                placeholder={t('column:Size')}
                            />
                        </Form.Item>
                        <Form.Item name="quantity" style={{ flex: '0 0 74px' }}>
                            <InsInputNumber2
                                min={1}
                                onChange={value => {
                                    form.setFieldsValue({
                                        quantity: value,
                                    })
                                    calculateDetailAmount()
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="unit" className="form-item-select-hide-search" style={{ flex: '0 0 38px' }}>
                            <ClassificationSelect
                                typeEnum={ClassificationTypeEnum.PRODUCT_UNIT}
                                showCreateButton={false}
                            />
                        </Form.Item>
                        <Form.Item name="unit_cost" style={{ flex: '0 0 90px' }}>
                            <InsInputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="unit_price"
                            style={{ flex: '0 0 84px' }}
                            onChange={() => calculateDetailAmount()}
                        >
                            <InsInputNumber min={0} />
                        </Form.Item>
                        <Form.Item name="tax_rate" style={{ flex: '0 0 66px' }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.TAX_RATE.value).map(c => (
                                    <Select.Option key={c.item_value} value={c.item_value}>
                                        {`${c.text}%`}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                        <Form.Item name="amount" style={{ flex: '0 0 84px' }}>
                            <InsInputNumber min={0} disabled />
                        </Form.Item>
                        <CommentIcon
                            className={`mr-4 pointer ${noteVisible ? 'color-pantone-2738-c' : 'color-blue-gray-200'}`}
                            onClick={() => setNoteVisible(!noteVisible)}
                        />
                    </Row>
                    <Row align="middle" className="row-wrapper-wrap">
                        <SubdirectoryArrowRightIcon size={16} className="mdi_subdirectory_arrow_right mr-4 mt-4" />
                        <Form.Item name="processed_product_classification" className="mt-8" style={{ width: 128 }}>
                            <InsSelect onChange={value => handleProductTypeChange(value)}>
                                {getClassificationsByType(
                                    isInternal
                                        ? ClassificationTypeEnum.IN_HOUSE_PROCESSED.value
                                        : ClassificationTypeEnum.OUTSIDE_PROCESSED.value
                                ).map(clf => (
                                    <Select.Option key={clf.value} value={clf.value}>
                                        {clf.text}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>

                        {renderProductExtends()}
                    </Row>
                    <Row align="middle" className="mt-4" style={{ display: 'flex' }}>
                        <DescriptionIcon size={20} className="color-deep-orange-400" style={{ marginRight: 2 }} />
                        <div className="fw-bold mr-4">メモ</div>
                        <Form.Item name="memo" style={{ flex: 1 }}>
                            <InsInput placeholder="作業メモ" />
                        </Form.Item>
                    </Row>
                    <Row align="middle" className="mt-4" style={{ display: noteVisible ? 'flex' : 'none' }}>
                        <SubdirectoryArrowRightIcon size={20} style={{ marginRight: 2 }} />
                        <div className="fw-bold mr-4">コメント</div>
                        <Form.Item name="note" style={{ flex: 1 }}>
                            <InsTextArea
                                className="textarea-auto-size"
                                placeholder="コメント入力　※コメントは印刷されません"
                                autoSize={true}
                            />
                        </Form.Item>
                    </Row>
                </>
            )}
        </Form>
    )
})

const mapState = (state, props) => ({
    value: state.instructionDetail.details[props.id],
    instructionType: state.instructionDetail.instructionType,
    currentUser: state.auth.user,
})
const mapDispatch = { updateDetail }
export default connect(mapState, mapDispatch)(InstructionDetailRow)

InstructionDetailRow.propTypes = {
    id: PropTypes.number,
    value: PropTypes.object,
    updateDetail: PropTypes.func,
    instructionType: PropTypes.number,
    currentUser: PropTypes.object.isRequired,
}
