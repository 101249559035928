import React from 'react'
import PropTypes from 'prop-types'
import InsModal from 'components/InsModal'
import EstimationDOMPrint from 'modules/business/estimation/EstimationDOMPrint'

const EstimationPreviewModal = ({ project, estimation, children, visible, onCancel }) => {
    return (
        <>
            <InsModal width={'90%'} visible={visible} onCancel={onCancel} footer={null}>
                <EstimationDOMPrint
                    project={project}
                    estimationPrint={estimation}
                    showPreview={true}
                    processPrint={false}
                />
            </InsModal>
            {children}
        </>
    )
}

export default EstimationPreviewModal

EstimationPreviewModal.propTypes = {
    project: PropTypes.object,
    estimation: PropTypes.object,
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
}
