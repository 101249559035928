import React from 'react'
import PropTypes from 'prop-types'
import CloneDocumentEnum from 'enums/clone_document.enum'
import EstimationDOMPrint from 'modules/business/estimation/EstimationDOMPrint'
import PlaceOrderDOMPrint from 'modules/business/placeOrder/PlaceOrderDOMPrint'
import InstructionDOMPrint from 'modules/business/instruction/InstructionDOMPrint'
import SaleDOMPrint from 'modules/business/sale/SaleDOMPrint'

const BusinessToPrint = ({ project, document, printRef }) => {
    return (
        <React.Fragment>
            {document === CloneDocumentEnum.ESTIMATION.value && (
                <EstimationDOMPrint project={project} estimationPrint={project.estimation} componentRef={printRef} />
            )}
            {document === CloneDocumentEnum.PLACE_ORDER.value && (
                <PlaceOrderDOMPrint project={project} placeOrderPrint={project.placeOrder} componentRef={printRef} />
            )}
            {document === CloneDocumentEnum.INSTRUCTION_INTERNAL.value && (
                <InstructionDOMPrint
                    project={project}
                    instruction={project.instruction_internal}
                    componentRef={printRef}
                    hideColumns={[]}
                />
            )}
            {document === CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value && (
                <InstructionDOMPrint
                    project={project}
                    instruction={project.instruction_outsource}
                    componentRef={printRef}
                    hideColumns={[]}
                />
            )}
            {document === CloneDocumentEnum.SALES.value && (
                <SaleDOMPrint project={project} salePrint={project.sale} componentRef={printRef} hideColumns={[]} />
            )}
        </React.Fragment>
    )
}

export default BusinessToPrint

BusinessToPrint.propTypes = {
    project: PropTypes.object,
    document: PropTypes.number,
    printRef: PropTypes.any,
}
