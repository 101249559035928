import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import { Spin } from 'antd'
import history from 'helpers/history.helper'
import InsLoader from 'components/InsLoader'
import LoginPage from 'modules/common/auth/LoginPage'
import AuthLayout from 'modules/common/ui/AuthLayout'
import { checkAuth } from 'modules/common/auth/auth.slice'
import ja from 'locales/ja'

i18n.use(initReactI18next).init({
    resources: { ja },
    ns: ['translation', 'entity', 'enum', 'common', 'column', 'validation', 'navigation'],
    defaultNS: 'translation',
    lng: 'ja',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
})

const App = ({ loading, checkAuth }) => {
    const { t } = useTranslation()
    useEffect(() => {
        checkAuth()
    }, [checkAuth])

    if (loading) {
        return (
            <div className="ins-full-page-loader">
                <Spin indicator={<InsLoader />} tip={t('common:Loading, please wait')} />
            </div>
        )
    }

    return (
        <Router history={history}>
            <Route path="/login">
                <LoginPage />
            </Route>
            <Route path="/">
                <AuthLayout />
            </Route>
        </Router>
    )
}

export default connect(
    state => ({
        loading: state.ui.loading,
    }),
    { checkAuth }
)(App)

App.propTypes = {
    loading: PropTypes.bool.isRequired,
    checkAuth: PropTypes.func.isRequired,
}
