import React from 'react'
import { connect } from 'react-redux'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getData, setFilters, setSorter, setKeywords, setPagination } from 'modules/master/user/user.slice'
import UserListComponent from 'modules/master/user/UserListComponent'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'

const UserList = ({ state }) => {
    if (!checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return (
        <div className="page-container with-sidebar">
            <UserListComponent
                state={state}
                getData={getData}
                setFilters={setFilters}
                setSorter={setSorter}
                setKeywords={setKeywords}
                setPagination={setPagination}
            />
        </div>
    )
}

const mapState = state => ({ state: state.user })
export default connect(mapState)(UserList)

UserList.propTypes = {
    state: BaseStatePropShape,
}
