import React from 'react'
import PropTypes from 'prop-types'
import EstimationDOMPrint from 'modules/business/estimation/EstimationDOMPrint'
import PlaceOrderDOMPrint from 'modules/business/placeOrder/PlaceOrderDOMPrint'
import InstructionDOMPrint from 'modules/business/instruction/InstructionDOMPrint'
import SaleDOMPrint from 'modules/business/sale/SaleDOMPrint'
import BusinessPageEnum from 'enums/business_page.enum'
import InvoiceDOMPrint from 'modules/business/invoice/InvoiceDOMPrint'
import RedSlipDOMPrint from 'modules/business/redSlip/RedSlipDOMPrint'

const FloatBarBusinessToPrint = ({ data, document, printRef }) => {
    if (!data) return <></>
    return (
        <>
            {document === BusinessPageEnum.ESTIMATIONS.value && (
                <EstimationDOMPrint project={data.project} estimationPrint={data} componentRef={printRef} />
            )}
            {document === BusinessPageEnum.PURCHASE_ORDER.value && (
                <PlaceOrderDOMPrint
                    project={data.project || data.project_for_list}
                    placeOrderPrint={data}
                    componentRef={printRef}
                />
            )}
            {document === BusinessPageEnum.INSTRUCTIONS.value && (
                <InstructionDOMPrint
                    project={data.project}
                    instruction={data}
                    componentRef={printRef}
                    hideColumns={[]}
                />
            )}
            {document === BusinessPageEnum.SALES.value && (
                <SaleDOMPrint project={data.project} salePrint={data} componentRef={printRef} hideColumns={[]} />
            )}
            {document === BusinessPageEnum.INVOICES.value && (
                <div style={{ position: 'absolute', zIndex: '-9999999', top: 0, left: 0, opacity: 0 }}>
                    <InvoiceDOMPrint
                        project={data.project || data.summary_invoice_projects[0]}
                        //invoicePrint={data}
                        componentRef={printRef}
                        showPreview={false}
                    />
                </div>
            )}
            {document === BusinessPageEnum.RED_SLIPS.value && (
                <div style={{ position: 'absolute', zIndex: '-9999999', top: 0, left: 0, opacity: 0 }}>
                    <RedSlipDOMPrint
                        project={data.project}
                        //salePrint={sale} // for fetch
                        redSlip={data}
                        componentRef={printRef}
                        showPreview={false}
                    />
                </div>
            )}
        </>
    )
}

export default FloatBarBusinessToPrint

FloatBarBusinessToPrint.propTypes = {
    data: PropTypes.any,
    document: PropTypes.number,
    printRef: PropTypes.any,
}
