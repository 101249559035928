let userPermissions = []
let admin = false

export const getPermissions = () => userPermissions

export const setIsAdmin = isAdmin => {
    admin = isAdmin
}

export const checkAdmin = () => admin
export const setPermissions = ps => {
    userPermissions = ps
}

export const can = permission => {
    // if (!permission) return true
    let permissionNames = []
    for (const p of userPermissions) {
        permissionNames.push(p.authority_name)
    }
    if (permissionNames.indexOf(permission) > -1) return true
    return false
}

export const canOne = permissions => {
    if (!permissions || permissions.length === 0) return true
    for (const p of permissions) {
        if (userPermissions.indexOf(p) > -1) return true
    }
    return false
}

export const canAll = permissions => {
    // if (!permissions || permissions.length === 0) return true
    let permissionNames = []
    for (const permission of userPermissions) {
        permissionNames.push(permission.authority_name)
    }
    for (const p of permissions) {
        if (permissionNames.indexOf(p) === -1) return false
    }
    return true
}

export const getPermissionName = keyword => {
    switch (keyword) {
        case 'projects':
            return 'PROJECT_EDITABLE'
        case 'estimations':
            return 'ESTIMATION_EDITABLE'
        case 'instruction-internal':
            return 'INSTRUCTION_EDITABLE'
        case 'instruction-outsource':
            return 'INSTRUCTION_EDITABLE'
        case 'place-orders':
            return 'PLACE_ORDER_EDITABLE'
        case 'trackings':
            return 'TRACKING_EDITABLE'
        case 'invoices':
            return 'INVOICE_EDITABLE'
        case 'sales':
            return 'SALE_EDITABLE'
        case 'reports':
            return 'REPORT_EDITABLE'
        case 'red-slips':
            return 'RED_SLIP_EDITABLE'
        case 'summary_invoice':
            return 'SUMMARY_INVOICE_EDITABLE'
        default:
            return null
    }
}
