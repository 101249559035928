import React from 'react'
import PropTypes from 'prop-types'
import { CalendarTodayIcon } from 'components/InsIcon'
import { DatePicker } from 'antd'

const { RangePicker } = DatePicker

const InsDateRangePicker = ({
    className = '',
    dateFormat = 'YYYY/MM/DD',
    separator = '~',
    allowClear = false,
    ...rest
}) => {
    let pickerClassName = 'ins-date-range-picker '
    if (className) {
        pickerClassName += className
    }
    const customSeparator = (
        <span className="ant-picker-suffix">
            <CalendarTodayIcon size={16} />
            <span className="separator">{separator}</span>
        </span>
    )

    return (
        <RangePicker
            {...rest}
            className={pickerClassName}
            format={dateFormat}
            separator={customSeparator}
            allowClear={allowClear}
            suffixIcon={<CalendarTodayIcon size={16} />}
        />
    )
}

InsDateRangePicker.propTypes = {
    dateFormat: PropTypes.string,
    className: PropTypes.string,
    separator: PropTypes.string,
    allowClear: PropTypes.bool,
}

export default InsDateRangePicker
