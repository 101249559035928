import React, { useState } from 'react'
import { Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import { CloseIcon } from 'components/InsIcon'

const FloatBarDemo = () => {
    const [stickVisible, setStickVisible] = useState(false)
    const [floatVisible, setFloatVisible] = useState(false)
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">Float bar Demo</h1>
            <Row gutter={[8, 16]} align="bottom" style={{ width: '100%' }}>
                <Col>
                    <InsButton
                        buttonStyle={stickVisible ? 'outline' : 'primary'}
                        onClick={() => setStickVisible(!stickVisible)}
                    >
                        {stickVisible ? 'Hide' : 'Show'} stick bottom bar
                    </InsButton>
                    <InsFloatBar visible={stickVisible}>
                        <Row gutter={8} justify="end">
                            <Col>
                                <InsButton
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    centered
                                    width="120px"
                                    icon={<CloseIcon />}
                                >
                                    キャンセル
                                </InsButton>
                            </Col>
                            <Col>
                                <InsButton buttonStyle="primary" buttonSize="big" centered width="120px">
                                    保存
                                </InsButton>
                            </Col>
                        </Row>
                    </InsFloatBar>
                </Col>
                <Col>
                    <InsButton
                        buttonStyle={floatVisible ? 'outline' : 'primary'}
                        onClick={() => setFloatVisible(!floatVisible)}
                    >
                        {floatVisible ? 'Hide' : 'Show'} float bar
                    </InsButton>
                    <InsFloatBar visible={floatVisible} floatBarStyle="float">
                        <Row gutter={8} justify="end">
                            <Col>
                                <InsButton
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    centered
                                    width="120px"
                                    icon={<CloseIcon />}
                                >
                                    キャンセル
                                </InsButton>
                            </Col>
                            <Col>
                                <InsButton buttonStyle="primary" buttonSize="big" centered width="120px">
                                    保存
                                </InsButton>
                            </Col>
                        </Row>
                    </InsFloatBar>
                </Col>
            </Row>
        </div>
    )
}

export default FloatBarDemo
