import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import PropTypes from 'prop-types'
import InsBackLink from 'components/InsBackLink'
import TeamForm from 'modules/master/team/form/TeamForm'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'

const TeamCreate = () => {
    const { t } = useTranslation()

    const createTeam = async values => {
        const data = await api.post(APP_URLS.TEAMS, values)
        if (data) {
            history.goBack()
        }
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    if (!checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">{t('navigation:{{entity}} create', { entity: t('entity:Team') })}</h1>
            <TeamForm
                modalConfirmSave={modalConfirmSave}
                setModalConfirmSave={setModalConfirmSave}
                onSubmit={(values, redirectTo, confirmSave = true) => {
                    if (confirmSave) setModalConfirmSave(true)
                    else {
                        createTeam(values, redirectTo, confirmSave)
                        setModalConfirmSave(false)
                    }
                }}
            />
        </div>
    )
}

export default TeamCreate

TeamCreate.propTypes = {
    isEdit: PropTypes.bool,
}
