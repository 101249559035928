import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import moment from 'moment'

const name = 'placeOrder'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.PLACE_ORDER, ...props })
)

const dateFormatStart = 'YYYY/MM/DD 00:00:01'
const dateFormatEnd = 'YYYY/MM/DD 23:59:59'

const placeOrderSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: {
            'status': '0',
            'created_at:gte': moment().subtract(2, 'months').format(dateFormatStart),
            'created_at:lte': moment().format(dateFormatEnd),
            'is_deleted': 0,
        },
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setSorter,
    setSelection,
} = placeOrderSlice.actions

export default placeOrderSlice.reducer
