import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import { splittingContent } from 'components/print/InsSplittingContent'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { dateToStr } from 'helpers/date.helper'
import { formatNumber, formatNumberComma } from 'helpers/number.helper'
import { AccessTimeIcon, CompanySealIcon, FolderOpenIcon, TruckIcon } from 'components/InsIcon'
import { isHeadline } from 'helpers/detail.helper'
import { renderInstructionExtendRow } from 'modules/business/instruction/InstructionHelper'
import BusinessStatusEnum from 'enums/business_status.enum'

const InvoiceDOMPrint = ({ project, invoicePrint, componentRef, showPreview = false, processPrint = true }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [invoice, setInvoice] = useState(invoicePrint)
    const curCustomer = invoice?.customer || {}

    const printOptions = invoice && invoice.print_options ? JSON.parse(invoice.print_options) : null
    // const printOptions = { display_amount: true, display_company_seal: true, display_instruction: true }
    const displayAmount = printOptions ? printOptions.display_amount : false
    const displayCompanySeal = printOptions ? printOptions.display_company_seal : false
    const displayInstruction = printOptions ? printOptions.display_instruction : false

    let refs = {}
    let refIndex = 1
    let nextRef = {}

    const setRefs = (el, isTHead = false, withoutTHead = false, similarTHead = false) => {
        refs[refIndex] = {
            elem: el,
            isTHead: isTHead,
            withoutTHead: withoutTHead,
            similarTHead: similarTHead, // project row
        }
        refIndex++
    }

    const setNextRef = el => {
        nextRef = {
            elem: el,
        }
    }

    const [companyInfo, setCompanyInfo] = useState()
    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            let senderId = invoice && invoice.sender_id
            if (!invoice) {
                const data = await api.get(APP_URLS.INVOICE_BY_PROJECT.replace(':projectId', project.id))
                if (data) {
                    setInvoice(data)
                    senderId = data.sender_id
                }
            }

            // keep: summary invoice print == invoice print
            senderId = '0000000001'
            const companyInfoResp = await api.get(APP_URLS.COMPANIES_DETAIL_BY_CODE.replace(':code', senderId))
            if (companyInfoResp) {
                setCompanyInfo(companyInfoResp)
            }
        }
        fetchData().then(() => setLoading(false))
    }, [project.id])

    // ref
    const renderHeadFirstPage = skipRef => {
        return <div ref={el => !skipRef && setRefs(el)}>{renderHeader()}</div>
    }

    // ref
    const renderTableRow1 = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el)} className="page-dynamic-padding pb-24">
                <div className="invoice-table-row1 as-thead">
                    <span style={{ flex: 181 }}>{'前回御請求額'}</span>
                    <span style={{ flex: 181 }}>{'調整額'}</span>
                    <span style={{ flex: 181 }}>{'繰越金額'}</span>
                    <span style={{ flex: 181 }}>{'今回御請求金額'}</span>
                    <span style={{ flex: 184 }}>{'合計残高'}</span>
                </div>
                <div className="invoice-table-row1 as-tbody">
                    <span style={{ flex: 181 }}>{'0'}</span>
                    <span style={{ flex: 181 }}>{formatNumber(invoice.adjust_amount) || 0}</span>
                    <span style={{ flex: 181 }}>{'0'}</span>
                    <span className="bigger" style={{ flex: 181 }}>
                        {'0'}
                    </span>
                    <span style={{ flex: 184 }}>{formatNumberComma(invoice.total) || 0}</span>
                </div>
            </div>
        )
    }

    const renderInvoiceProject = (skipRef, isPreview) => {
        const projects = [invoice.project, ...invoice.summary_invoice_projects].filter(
            p => p && (!p.red_slip || (p.red_slip && p.red_slip.status !== BusinessStatusEnum.RED_SLIP_ISSUED.value))
        )
        return (
            <>
                {projects.map((project, index) => (
                    <Fragment key={project.id || index}>
                        {renderCodeDelivery(project, skipRef)}
                        {renderTHead(project.sale, skipRef, isPreview)}
                        {renderDestinationsOrDetails(project, skipRef, isPreview)}
                        {renderProjectTotal(project, skipRef)}
                    </Fragment>
                ))}
            </>
        )
    }

    const renderCodeDelivery = (project, skipRef) => {
        return (
            <div ref={el => !skipRef && setRefs(el, false, true)} className="invoice-table-2">
                <div className="project-group-info">
                    <FolderOpenIcon size={20} className="mdi-folder-open mr-8" />
                    <span className="mr-8">{'案件番号  :'}</span>
                    <span className="fw-500 mr-16">{project.project_code}</span>
                    <AccessTimeIcon size={20} className="mdi-access-time mr-8" />
                    <span className="spc mr-8">{'納品日  :'}</span>
                    <span className="spc fw-500">{dateToStr(project.sale.delivery_date)}</span>
                </div>
            </div>
        )
    }

    const renderTHead = (sale, skipRef, isPreview) => {
        return (
            <div ref={el => !skipRef && setRefs(el, true)} className="invoice-project-table-1">
                <div className="as-thead">
                    <span style={{ flex: '0 0 86px' }}>{'納品日'}</span>
                    {sale && parseInt(sale.sale_classification) !== 1 ? (
                        <>
                            <span style={{ flex: 1 }}>{t('品番・品名')}</span>
                        </>
                    ) : (
                        <>
                            <span style={{ flex: 215 }}>{'タイトル'}</span>
                            <span style={{ flex: 215 }}>{'仕様'}</span>
                            <span style={{ flex: 116 }}>{'サイズ'}</span>
                        </>
                    )}
                    <span style={{ flex: '0 0 92px' }}>{'数量'}</span>
                    <span style={{ flex: '0 0 56px' }}>{'単位'}</span>
                    {(displayAmount || isPreview) && <span style={{ flex: '0 0 106px' }}>{'単価'}</span>}
                    {(displayAmount || isPreview) && <span style={{ flex: '0 0 106px' }}>{'金額'}</span>}
                </div>
            </div>
        )
    }

    const renderDestinationsOrDetails = (project, skipRef, isPreview) => {
        const sale = project.sale
        return (
            <>
                {sale.delivery_destinations.length
                    ? renderDestinations(sale, sale.delivery_destinations, skipRef, isPreview)
                    : renderDetails(sale, sale.details, skipRef, isPreview)}
            </>
        )
    }

    const renderDestinations = (sale, destinations, skipRef, isPreview) => {
        return <>{destinations.map(des => renderDestination(sale, des, skipRef, isPreview))}</>
    }

    const renderDestination = (sale, des, skipRef, isPreview) => {
        return (
            <>
                <div ref={el => !skipRef && setRefs(el)}>
                    <div className="invoice-project-delivery-des-info">
                        <TruckIcon
                            size={20}
                            className="invoice-project-delivery-des-info-truck"
                            style={{ flex: '0 0 20px' }}
                        />
                        <div className="fw-bold include-first">{'納入先： '}</div>
                        <div>{des.delivery_destination_name}</div>
                        <div className="ml-24">{des.delivery_destination_address}</div>
                        <div className="ml-24">{des.delivery_destination_pic_name}</div>
                        <div className="ml-24">{des.delivery_destination_pic_title}</div>
                    </div>
                </div>
                {renderDetails(sale, des.details, skipRef, isPreview)}
            </>
        )
    }

    const renderDetails = (sale, details, skipRef, isPreview) => {
        return <>{details.map(detail => renderDetail(sale, detail, skipRef, isPreview))}</>
    }

    const showExtend = detail => {
        return detail.instruction_detail_id && displayInstruction
    }

    const getInstructionsDetails = () => {
        let tmpDetails = []
        if (!invoice) return tmpDetails
        // eslint-disable-next-line no-unused-vars
        let m = [...invoice.summary_invoice_projects, invoice.project]
            .filter(p => p)
            .map(prj => {
                if (prj.instruction_internal && prj.instruction_internal.details.length) {
                    tmpDetails = [...tmpDetails, ...prj.instruction_internal.details]
                }
                if (prj.instruction_outsource && prj.instruction_outsource.details.length) {
                    tmpDetails = [...tmpDetails, ...prj.instruction_outsource.details]
                }
            })

        return tmpDetails
    }
    const instructionDetails = getInstructionsDetails() || []
    const renderDetail = (sale, detail, skipRef, isPreview) => {
        return (
            <div ref={el => !skipRef && setRefs(el)} className="invoice-project-delivery" key={detail.id}>
                <div className="invoice-project-detail" style={{ flexWrap: showExtend(detail) ? 'wrap' : null }}>
                    <span style={{ flex: '0 0 86px' }}>{dateToStr(sale.delivery_date, 'YYYY/MM/DD')}</span>
                    {!isHeadline(detail) ? (
                        <>
                            {parseInt(sale.sale_classification) !== 1 ? (
                                <span style={{ flex: 1 }}>{detail.title}</span>
                            ) : (
                                <>
                                    <span style={{ flex: 215 }}>{detail.title}</span>
                                    <span style={{ flex: 215 }}>{detail.spec}</span>
                                    <span style={{ flex: 116 }}>{detail.size}</span>
                                </>
                            )}

                            <span style={{ flex: '0 0 92px' }}>{formatNumber(detail.quantity)}</span>
                            <span style={{ flex: '0 0 56px' }}>
                                {getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, detail.unit)}
                            </span>
                            {(displayAmount || isPreview) && (
                                <span className="text-right" style={{ flex: '0 0 106px' }}>
                                    {formatNumber(detail.unit_price)}
                                </span>
                            )}

                            {(displayAmount || isPreview) && (
                                <span
                                    className="text-right"
                                    style={{
                                        flex: '0 0 106px',
                                        borderRightWidth: showExtend(detail) ? '0' : null,
                                    }}
                                >
                                    {formatNumberComma(detail.amount)}
                                </span>
                            )}

                            {showExtend(detail) &&
                                renderInstructionExtendRow(detail.instruction_detail_id, instructionDetails)}
                        </>
                    ) : (
                        <>
                            <span style={{ flex: 546 }}>{detail.headline}</span>
                            <span className="bg25" style={{ flex: '0 0 92px' }} />
                            <span className="bg25" style={{ flex: '0 0 56px' }} />
                            {(displayAmount || isPreview) && <span className="bg25" style={{ flex: '0 0 106px' }} />}
                            {(displayAmount || isPreview) && <span className="bg25" style={{ flex: '0 0 106px' }} />}
                        </>
                    )}
                </div>
            </div>
        )
    }

    // ref
    const renderTableDetail = (skipRef, isPreview) => {
        return <>{renderInvoiceProject(skipRef, isPreview)}</>
    }

    const renderProjectTotal = (project, skipRef) => {
        return (
            <div ref={el => !skipRef && setRefs(el)} className="invoice-project-table-1 mb-24">
                <div className="as-tfoot text-right">
                    <span className="spc bg25" style={{ flex: 146 }}>
                        {'小計'}
                    </span>
                    <span style={{ flex: 146 }}>{formatNumberComma(project.sale.subtotal)}</span>
                    <span className="spc bg25" style={{ flex: 146 }}>
                        {'消費税'}
                    </span>
                    <span style={{ flex: 146 }}>{formatNumberComma(project.sale.consumption_tax)}</span>
                    <span className="f-bold spc bg25" style={{ flex: 140 }}>
                        {'伝票合計'}
                    </span>
                    <span className="f-bold" style={{ flex: 184 }}>
                        {formatNumberComma(project.sale.total)}
                    </span>
                </div>
            </div>
        )
    }

    // ref
    const renderPreviewSumTotal = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, false, true)} className="page-dynamic-padding">
                <div className="preview-sum-total">
                    <label className="mr-8">{'小計'}</label>
                    <span className="mr-16">{formatNumberComma(invoice.subtotal) || 0}</span>
                    <label className="mr-8">{'消費税'}</label>
                    <span className="mr-16">{formatNumberComma(invoice.consumption_tax) || 0}</span>
                    <label className="sum-bigger mr-8">{'合計\t：'}</label>
                    <span className="sum-bigger">{formatNumberComma(invoice.total) || 0}</span>
                </div>
            </div>
        )
    }

    // ref
    const renderNote = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, false, true)} className="c-preview-note">
                <label>{'備考'}</label>
                <div className="fake-area invoice-project-note1">{invoice.note}</div>
            </div>
        )
    }

    // ref: repeatable
    const renderHeadNextPage = () => {
        return <div ref={el => setNextRef(el)}>{renderHeader(99)}</div>
    }

    const renderHiddenLeftContent = () => {
        return (
            <>
                <div className="about-info">
                    <div className="about-info-phone-fax">
                        <span className="about-info-tel">
                            {curCustomer.phone_number && `TEL：${curCustomer.phone_number || ''}`}
                        </span>
                        <span className="about-info-fax">
                            {curCustomer.fax_number && 'FAX：' + curCustomer.fax_number}
                        </span>
                    </div>
                </div>
                <div className="total-amount pt-12">
                    <span>{'今回御請求金額'}</span>
                    <span className="amount-number">
                        <span>{'￥'}</span>
                        {formatNumberComma(invoice.total)}
                    </span>
                </div>
                <div className="as-hr" />
                <div className="invoice-tax-note mb-24">
                    <span>{'※「今回御請求金額」は、当月御買上額です。明細欄は税別金額です。'}</span>
                </div>
            </>
        )
    }

    const renderHeader = (pageNum = 1) => {
        let pageNumString = pageNum < 10 ? '0' + pageNum : pageNum

        return (
            <div className="c-preview-header c-preview">
                <Row justify="space-between" align="bottom">
                    <Col span={8} offset={8} className={pageNum > 1 && 'hidden-title'}>
                        <strong className="c-preview-title">{'請求書'}</strong>
                    </Col>
                    <Col span={8} className={`text-right page-number ${pageNum > 1 && 'page-number-continue'}`}>
                        {'Page'} - <span>{pageNumString}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="c-preview-header-left">
                        <div className="children-left">
                            <div className="obj-name">{curCustomer.customer_name}</div>
                            {!!(invoice.customer_pic_name || invoice.customer_pic_title) && (
                                <div className="obj-person">
                                    {invoice.customer_pic_name}
                                    <span className="obj-person-extra">
                                        {getClassificationItemName(
                                            ClassificationTypeEnum.CUSTOMER_TITLE.value,
                                            invoice.customer_pic_title
                                        )}
                                    </span>
                                </div>
                            )}
                            <div className="as-hr" />
                            {pageNum === 1 && renderHiddenLeftContent()}
                            {pageNum > 1 && <div className="pb-24" />}
                        </div>
                    </Col>
                    <Col className="c-preview-header-right">
                        {displayCompanySeal && pageNum === 1 && (
                            <div className="ins-company-seal">
                                <CompanySealIcon size={90} />
                            </div>
                        )}
                        <div className="children-right">
                            {(invoice.invoice_code || invoice.delivery_date) && (
                                <table>
                                    <tbody>
                                        {!!invoice.delivery_date && (
                                            <tr>
                                                <th>
                                                    {'発行日'}
                                                    <span>:</span>
                                                </th>
                                                <td>{dateToStr(invoice.delivery_date)}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>
                                                {'請求管理番号'}
                                                <span>:</span>
                                            </th>
                                            <td>{invoice.invoice_code}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {pageNum === 1 && renderHiddenRightContent()}
                            {pageNum > 1 && <div className="pb-16" />}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const renderHiddenRightContent = () => {
        return (
            <>
                <div className="obj-name">{invoice.sender_name}</div>
                <div className="about-info">{invoice.sender_address}</div>
                {renderTransferInfo()}
            </>
        )
    }

    const renderTransferInfo = () => {
        return (
            <div className="transfer-info">
                <div className="transfer-info-description">{'お振込みの場合は、下記の口座へ送金願います。'}</div>
                <div className="company-info-bank">{(companyInfo && companyInfo.bank_info) || ''}</div>
            </div>
        )
    }

    const renderPreview = () => {
        return (
            <>
                <div className="dom-preview-visibility">
                    <div className="DOM-single-page dom-page-style dom-mod-style-header invoice-preview">
                        {renderHeadFirstPage()}
                        {renderTableRow1()}
                        {renderTableDetail()}
                        {renderPreviewSumTotal()}
                        {renderNote()}
                        {renderHeadNextPage()}
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        setSplitContents(splittingContent(refs, nextRef))
    }, [refs, nextRef])

    const renderPages = () => {
        return (
            <div style={{ display: 'none' }}>
                <div
                    ref={componentRef}
                    className="DOM-multi-pages dom-page-style dom-mod-style-header invoice-preview"
                    dangerouslySetInnerHTML={{ __html: splitContents }}
                />
            </div>
        )
    }

    const [splitContents, setSplitContents] = useState('')
    // render
    const render = () => (
        <>
            {processPrint && renderPreview()}
            {processPrint && renderPages()}
            {showPreview && renderUI()}
        </>
    )

    // effect re-render preview
    useEffect(() => {
        showPreview && setInvoice(invoicePrint)
    }, [invoicePrint])

    // render - show Preview
    const renderUI = () => (
        <>
            <div className="dom-preview-show dom-mod-style-header invoice-preview">
                {renderHeadFirstPage(true)}
                {renderTableRow1(true)}
                {renderTableDetail(true, true)}
                {renderPreviewSumTotal(true)}
                {renderNote(true)}
            </div>
        </>
    )

    return loading ? <InsBlockLoader /> : render()
}

export default InvoiceDOMPrint

InvoiceDOMPrint.propTypes = {
    project: PropTypes.object.isRequired,
    invoicePrint: PropTypes.object,
    componentRef: PropTypes.any,
    showPreview: PropTypes.bool,
    processPrint: PropTypes.bool,
}
