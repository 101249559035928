import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import Routes from 'routes'
import { Link } from 'react-router-dom'
import { SENDER_CODE } from 'constants/classification.constant'
import {
    CollectionScheduleFlagEnum,
    CollectionScheduleMonthEnum,
    CollectionScheduleDayEnum,
} from 'enums/collection_schedule.enum'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import UsageStatusEnum from 'enums/usage_status.enum'
import { BillAddressEnum } from 'enums/bill_address.enum'
import { convertLineBreak } from 'helpers/note.helper'
import { getClassificationItemName, getClassificationItemNameFromValue } from 'helpers/classification.helper'
import { getEnumText } from 'helpers/enum.helper'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import InsModal from 'components/InsModal'
import InsHelpText from 'components/InsHelpText'
import { InsBlockLoader } from 'components/InsLoader'
import { BackIcon, DeleteIcon, EditIcon, LaunchIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import UserDetailComponent from 'modules/master/user/UserDetailComponent'
import { formatNumber } from 'helpers/number.helper'

const CustomerDetailComponent = ({ inModal = false, id, onDelete }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [customer, setCustomer] = useState(null)
    const [deleteCustomer, setDeleteCustomer] = useState({
        visible: false,
    })
    const [modalPicDetail, setModalPicDetail] = useState(false)
    const [modalCustomerDetail, setModalCustomerDetail] = useState(false)
    useEffect(() => {
        setLoading(true)
        const getOutsource = async id => {
            const data = await api.get(APP_URLS.CUSTOMERS_DETAIL.replace(':id', id))
            if (data) {
                setCustomer(data)
                setLoading(false)
            }
        }

        getOutsource(id)
    }, [id])

    const showScheduleMonth = scheduleMonth => {
        // if (!scheduleMonth) return ''
        return [
            CollectionScheduleMonthEnum.CURRENT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_NEXT_MONTH.value,
        ].indexOf(scheduleMonth) > -1
            ? getEnumText(CollectionScheduleMonthEnum, scheduleMonth)
            : `${scheduleMonth}ヶ月後`
    }

    const showScheduleDay = scheduleDay => {
        if (!scheduleDay) return ''
        return scheduleDay === CollectionScheduleDayEnum.END_OF_THE_MONTH.value
            ? getEnumText(CollectionScheduleDayEnum, scheduleDay)
            : `${scheduleDay}日`
    }

    if (loading) {
        return <InsBlockLoader />
    }

    return (
        <>
            {!inModal && (
                <InsFloatBar visible={true}>
                    <Row justify="space-between">
                        <Row align="middle">
                            <Col>
                                <InsModal
                                    title={t('common:Delete')}
                                    visible={deleteCustomer.visible}
                                    onCancel={() =>
                                        setDeleteCustomer({
                                            visible: false,
                                        })
                                    }
                                    onOk={onDelete}
                                    okText="はい"
                                    cancelText="いいえ"
                                >
                                    <h2>{t('common:Are you sure')}</h2>
                                </InsModal>

                                <InsButton
                                    width="76px"
                                    disabled={!customer.can_delete}
                                    icon={<DeleteIcon size={16} />}
                                    onClick={() =>
                                        setDeleteCustomer({
                                            visible: true,
                                        })
                                    }
                                >
                                    {t('common:Delete')}
                                </InsButton>
                            </Col>
                            <Col>
                                <InsHelpText className="ml-8">
                                    {t('Can not delete in-used {{item}}', { item: t('entity:Customer') })}
                                </InsHelpText>
                            </Col>
                        </Row>

                        <Col>
                            <Row gutter={8} justify="end">
                                <Col>
                                    <InsButton
                                        className="fw-500"
                                        buttonType="link"
                                        linkTo={Routes.private.master.CUSTOMER_LIST.path}
                                        buttonStyle="outline"
                                        buttonSize="big"
                                        width="110px"
                                        icon={<BackIcon />}
                                    >
                                        {t('common:Back to list')}
                                    </InsButton>
                                </Col>
                                <Col>
                                    <InsButton
                                        className="fw-bold"
                                        buttonType="link"
                                        linkTo={Routes.private.master.CUSTOMER_UPDATE.path.replace(':id', id)}
                                        buttonStyle="primary"
                                        buttonSize="big"
                                        width="136px"
                                        icon={<EditIcon size={20} />}
                                    >
                                        {t('navigation:{{entity}} information edit', {
                                            entity: t('entity:Customer'),
                                        })}
                                    </InsButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </InsFloatBar>
            )}
            <div className={`${inModal ? '' : 'p-24'}`}>
                {/*{inModal ? (*/}
                {/*    <>*/}
                {/*        <h1>{t('column:Customer information')}</h1> <hr className="title-modal" />*/}
                {/*    </>*/}
                {/*) : null}*/}
                {!inModal && (
                    <h1 className="page-title-big mb-24 ins-flex flex-middle">
                        {customer.customer_name}
                        {!inModal && (
                            <InsButton
                                buttonType="link"
                                buttonStyle="plain"
                                className="ml-8 color-blue-gray-200"
                                icon={<EditIcon size={24} />}
                                linkTo={Routes.private.master.CUSTOMER_UPDATE.path.replace(':id', id)}
                            />
                        )}
                    </h1>
                )}
                <section>
                    <h2 className="section-title">{t('common:Basic information')}</h2>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('Customer code')}</th>
                                <td className="fw-bold color-blue-gray-900">{customer.customer_code}</td>
                            </tr>
                            <tr>
                                <th>{t('Customer name')}</th>
                                <td className="fw-bold color-blue-gray-900">{customer.customer_name}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Furigana')}</th>
                                <td>{customer.customer_name_furigana}</td>
                            </tr>
                            <tr>
                                <th>{t('Customer short name')}</th>
                                <td>{customer.customer_short_name}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Usage condition')}</th>
                                <td>{t(`enum:${getEnumText(UsageStatusEnum, customer.is_enabled)}`)}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Post code')}</th>
                                <td>{customer.post_code}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Address')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.PREFECTURE.value,
                                        customer.prefecture_code
                                    )}
                                    &nbsp;{customer.address1}&nbsp;{customer.address2}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('Customer title')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.CUSTOMER_TITLE.value,
                                        customer.customer_title
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('common:Phone number')}</th>
                                <td>{customer.phone_number}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Fax number')}</th>
                                <td>{customer.fax_number}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <h2 className="section-title">{t('common:Additional Information')}</h2>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('column:Customer classification')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.CUSTOMER.value,
                                        customer.customer_classification
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('column:Customer industry classification')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.CUSTOMER_INDUSTRY.value,
                                        customer.customer_industry_classification
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('column:Inashin pic name')}</th>
                                <td>
                                    {
                                        <>
                                            <InsButton
                                                onClick={() => {
                                                    setModalPicDetail(true)
                                                }}
                                                buttonStyle="plain"
                                                className={'fw-bold'}
                                            >
                                                {customer.pic ? customer.pic.pic_name : null}
                                            </InsButton>
                                            <InsModal
                                                className="ins-detail-modal"
                                                width={760}
                                                visible={modalPicDetail}
                                                onCancel={() => setModalPicDetail(false)}
                                                footer={null}
                                            >
                                                <UserDetailComponent
                                                    inModal={true}
                                                    id={customer.pic ? customer.pic.id : null}
                                                />
                                            </InsModal>
                                        </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>{t('column:Team')}</th>
                                <td>{customer.pic_code}</td>
                            </tr>
                            <tr>
                                <th>{t('column:Note')}</th>
                                <td>{convertLineBreak(customer.note)}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <h2 className="section-title">{t('common:Billing information')}</h2>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('column:Bill address code')}</th>
                                <td>
                                    {customer.bill_customer &&
                                        (customer.id === customer.bill_address_id ? (
                                            getEnumText(BillAddressEnum, BillAddressEnum.SAME_AS_CUSTOMER_NAME.value)
                                        ) : (
                                            <>
                                                {`${getEnumText(
                                                    BillAddressEnum,
                                                    BillAddressEnum.SET_SEPARATELY.value
                                                    // eslint-disable-next-line no-irregular-whitespace
                                                )}：　`}
                                                <InsButton
                                                    buttonStyle="plain"
                                                    className={'fw-bold'}
                                                    onClick={() => {
                                                        setModalCustomerDetail(true)
                                                    }}
                                                >
                                                    {customer.bill_customer?.customer_name}
                                                </InsButton>
                                                <InsModal
                                                    className="ins-detail-modal"
                                                    width={760}
                                                    visible={modalCustomerDetail}
                                                    onCancel={() => setModalCustomerDetail(false)}
                                                    footer={null}
                                                >
                                                    <CustomerDetailComponent
                                                        inModal={true}
                                                        id={customer.bill_customer ? customer.bill_customer.id : null}
                                                    />
                                                </InsModal>
                                            </>
                                        ))}
                                </td>
                            </tr>

                            {customer.bill_address_id === customer.id && (
                                <>
                                    <tr>
                                        <th>{t('column:Credit amount')}</th>
                                        <td>{formatNumber(customer.credit_amount)}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Billing deadline classification')}</th>
                                        <td>
                                            {getClassificationItemNameFromValue(
                                                ClassificationTypeEnum.BILL_DATE.value,
                                                customer.bill_date_classification
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Collection schedule')}</th>
                                        <td>
                                            {customer.collection_schedule1_month &&
                                            customer.collection_schedule1_month === 99
                                                ? '設定なし'
                                                : customer.collection_schedule1_month ||
                                                  customer.collection_schedule1_day
                                                ? `${showScheduleMonth(
                                                      customer.collection_schedule1_month
                                                      // eslint-disable-next-line no-irregular-whitespace
                                                  )} 　${showScheduleDay(customer.collection_schedule1_day)}`
                                                : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Collection type')}</th>
                                        <td>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value,
                                                customer.collection_schedule1_collection_type
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Collection method')}</th>
                                        <td>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value,
                                                customer.collection_schedule1_collection_method
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Collection schedule 2 flag')}</th>
                                        <td>
                                            {getEnumText(
                                                CollectionScheduleFlagEnum,
                                                customer.collection_schedule2_flag
                                            )}
                                        </td>
                                    </tr>
                                    {customer.collection_schedule2_flag === 1 && (
                                        <>
                                            <tr>
                                                <th>{t('column:Collection schedule (Collection schedule 2)')}</th>
                                                <td>
                                                    {customer.collection_schedule2_month &&
                                                    customer.collection_schedule2_month === 99
                                                        ? '設定なし'
                                                        : customer.collection_schedule2_month ||
                                                          customer.collection_schedule2_day
                                                        ? `${showScheduleMonth(
                                                              customer.collection_schedule2_month
                                                              // eslint-disable-next-line no-irregular-whitespace
                                                          )} 　${showScheduleDay(customer.collection_schedule2_day)}`
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('column:Collection type (Collection schedule 2)')}</th>
                                                <td>
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value,
                                                        customer.collection_schedule2_collection_type
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('column:Collection method (Collection schedule 2)')}</th>
                                                <td>
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value,
                                                        customer.collection_schedule2_collection_method
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                </section>
                <section>
                    <h2 className="section-title">{t('common:Display information')}</h2>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('column:Estimation sender code')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.ESTIMATION_SENDER.value,
                                        customer.estimation_sender_code
                                    )}
                                    {customer.estimation_sender_code === SENDER_CODE.ESTIMATION_OTHER && (
                                        <>
                                            <br />
                                            {customer.estimation_sender_name}
                                            <br />
                                            {customer.estimation_sender_address}
                                        </>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('column:Delivery note sender code')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.DELIVERY_NOTE_SENDER.value,
                                        customer.delivery_note_sender_code
                                    )}
                                    {customer.delivery_note_sender_code === SENDER_CODE.DELIVERY_NOTE_OTHER && (
                                        <>
                                            <br />
                                            {customer.delivery_note_sender_name}
                                            <br />
                                            {customer.delivery_note_sender_address}
                                        </>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('column:Bill sender code')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.BILL_SENDER.value,
                                        customer.bill_sender_code
                                    )}
                                    {customer.bill_sender_code === SENDER_CODE.BILL_OTHER && (
                                        <>
                                            <br />
                                            {customer.bill_sender_name}
                                            <br />
                                            {customer.bill_sender_address}
                                        </>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>

            {inModal && (
                <Row justify="end">
                    <Link to={Routes.private.master.CUSTOMER_DETAIL.path.replace(':id', customer.id)} target="_blank">
                        <InsButton
                            icon={<LaunchIcon size={12} className="color-pantone-2738-c" />}
                            buttonStyle="default"
                        >
                            {t('Open new tab')}
                        </InsButton>
                    </Link>
                </Row>
            )}
        </>
    )
}

export default CustomerDetailComponent

CustomerDetailComponent.propTypes = {
    id: PropTypes.any.isRequired,
    inModal: PropTypes.bool,
    onDelete: PropTypes.func,
}

CustomerDetailComponent.defaultProps = {
    inModal: false,
}
