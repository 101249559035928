import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InsBackLink from 'components/InsBackLink'
import { InsBlockLoader } from 'components/InsLoader'
import TeamForm from 'modules/master/team/form/TeamForm'
import InsButton from 'components/InsButton'
import Routes from 'routes'
import { EditIcon } from 'components/InsIcon'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'

const TeamUpdate = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [team, setTeam] = useState(null)

    const [editTeamName, setEditTeamName] = useState(false)

    useEffect(() => {
        api.get(APP_URLS.TEAMS_DETAIL.replace(':id', id)).then(response => {
            if (response) {
                setTeam(response)
                // setLoading(false)
            }
        })
    }, [id])
    const updateTeam = async values => {
        const data = await api.put(APP_URLS.TEAMS_DETAIL.replace(':id', id), values)
        if (data) {
            api.get(APP_URLS.TEAMS_DETAIL.replace(':id', id)).then(response => {
                if (response) {
                    setTeam(response)
                    // setLoading(false)
                }
            })
            // history.goBack()
            setEditTeamName(false)
        }
    }

    if (!checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {team && team.team_name}
                {!editTeamName && (
                    <InsButton
                        // buttonType="link"
                        buttonStyle="plain"
                        className="ml-8"
                        icon={<EditIcon size={20} className="color-blue-gray-200" />}
                        onClick={() => setEditTeamName(true)}
                    />
                )}
            </h1>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <TeamForm
                    isEdit={true}
                    initialValues={team}
                    onSubmit={values => updateTeam(values)}
                    editTeamName={editTeamName}
                    setEditTeamName={setEditTeamName}
                />
            )}
        </div>
    )
}

export default TeamUpdate

TeamUpdate.propTypes = {
    match: PropTypes.object.isRequired,
}
