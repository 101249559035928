import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Select } from 'antd'
import InsInput from 'components/InsInput'
import InsSelect from 'components/select/InsSelect'
import { getClassificationItemName, getClassificationsByType } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsAutoComplete from 'components/InsAutoComplete'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import InsTextArea from 'components/InsTextArea'
import InvoiceProject from 'modules/business/invoice/invoiceProject/InvoiceProject'
import UserSelect from 'modules/master/user/UserSelect'
import {
    AccessTimeIcon,
    AddIcon,
    CloseIcon,
    FolderOpenIcon,
    OpenInNewIcon,
    PreviewIcon,
    ReportProblemIcon,
} from 'components/InsIcon'
import InsButton from 'components/InsButton'
import moment from 'moment'
import { SENDER_CODE } from 'constants/classification.constant'
import InvoiceProjectModal from 'modules/business/invoice/InvoiceProjectModal'
import InsInputNumber from 'components/InsInputNumber'
import InsFloatBar from 'components/InsFloatBar'
import history from 'helpers/history.helper'
import { dateToStr } from 'helpers/date.helper'
import { calculateTotal } from 'helpers/calculate.helper'
import InvoicePreviewModal from 'modules/business/invoice/InvoicePreviewModal'
import Routes from 'routes'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import RouterPrompt from 'modules/common/ui/RouterPrompt'
import InsModal from 'components/InsModal'

const InvoiceForm = ({ isEdit, project, sale, initialValues = {}, onSubmit, countProject }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const [modalSelectProjectVisible, setModalSelectProjectVisible] = useState(false)
    const [modalPreviewVisible, setModalPreviewVisible] = useState(false)
    const [isValueChange, setIsValueChange] = useState(!isEdit)

    const customer = project.customer
    const suggestionList = customer.customer_pic ? customer.customer_pic.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    initialValues = Object.assign(
        {
            project_id: project.id,
            customer_id: customer.id,
            customer_pic_name: suggestionList[0],
            customer_pic_title: customer.customer_title,
            invoice_code: project.project_code + '-' + (project.count_invoice || 0),
            invoice_name: project.project_name,
            pic: project.pic,
            pic_id: project.pic_id,
            sender_id: customer.bill_sender_code || SENDER_CODE.BILL_DEFAULT,
            sender_name: (customer.bill_sender_code === SENDER_CODE.BILL_OTHER && customer.bill_sender_name) || '',
            sender_address:
                (customer.bill_sender_code === SENDER_CODE.BILL_OTHER && customer.bill_sender_address) || '',
            is_summary_invoice: 0,
        },
        initialValues
    )

    const [otherSales, setOtherSales] = useState(
        initialValues.summary_invoice_projects
            ? initialValues.summary_invoice_projects.map(prj => ({ ...prj.sale, project: prj }))
            : []
    )

    initialValues.project_id = project.id
    initialValues.delivery_date = initialValues.delivery_date
        ? moment.utc(initialValues.delivery_date, 'YYYY-MM-DD')
        : moment.utc(moment().format('YYYY-MM-DD'))

    const [companies, setCompanies] = useState([])
    const getCompanies = async () => {
        const data = await api.get(APP_URLS.COMPANIES)
        if (data && data.list) {
            setCompanies(data.list)
            // special
            if (!isEdit && initialValues.sender_id !== SENDER_CODE.BILL_OTHER) {
                // set title
                const company = getCompanyByCode(initialValues.sender_id, data.list)
                form.setFieldsValue({
                    sender_name: company.company_name,
                    sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
                })
            }
        }
    }
    useEffect(() => {
        getCompanies().then(() => {})
    }, [])

    const getCompanyByCode = (company_code, tmpCompanies) => {
        const filterCompanies = (tmpCompanies || companies).filter(c => c.company_code === company_code)
        return filterCompanies.length > 0 ? filterCompanies[0] : {}
    }

    const makeCompanyInfo = company => {
        let prefecture = getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, company.prefecture_code)
        return `〒${company.post_code}${prefecture}${company.address1}${company.address2 || ''}\nTEL：${
            company.phone_number
            // eslint-disable-next-line no-irregular-whitespace
        }　　FAX：${company.fax_number ? company.fax_number : ''}`
    }

    const handlerSenderIdChange = senderId => {
        if (senderId === SENDER_CODE.BILL_OTHER) {
            form.setFieldsValue({
                sender_name: customer.bill_sender_name,
                sender_address: customer.bill_sender_address,
            })
        } else {
            const company = getCompanyByCode(senderId)
            form.setFieldsValue({
                sender_name: company.company_name,
                sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
            })
        }
    }

    const [isBulk, setIsBulk] = useState(initialValues.is_summary_invoice === 1)

    const processOnSubmit = (values, redirectTo) => {
        // process invoice_projects
        // values.project_ids = [sale.project.id, ...otherSales.map(oSale => oSale.project.id)]
        if (isValueChange) {
            setIsValueChange(false)
        }
        values.project_ids = otherSales.map(oSale => oSale.project.id)
        onSubmit(
            values,
            redirectTo
                ? redirectTo
                : values.project_ids.includes(project.id) || values.project_id === project.id
                ? null
                : Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id)
        )
    }

    const dateFormat = 'YYYY年M月DD日'

    const otherSaleClose = oSaleId => {
        setOtherSales(otherSales.filter(oSale => oSale.id !== oSaleId))
    }

    const calculateSum = details => {
        const result = calculateTotal(details)
        form.setFieldsValue(result)
    }
    const canEditInvoice = can('INVOICE_EDITABLE') && !project.is_locked
    useEffect(() => {
        let otherSalesDetails = []
        otherSales.map(oSale => (otherSalesDetails = [...otherSalesDetails, ...oSale.details]))
        if (sale.details) {
            calculateSum([...sale.details, ...otherSalesDetails])
        } else {
            calculateSum([...otherSalesDetails])
        }
    }, [sale, otherSales])

    return (
        <>
            {canEditInvoice ? (
                <>
                    {modalConfirmSave && (
                        <InsModal
                            title="確認"
                            visible={modalConfirmSave}
                            onCancel={() => setModalConfirmSave(false)}
                            onOk={() => processOnSubmit(form.getFieldsValue())}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <p>保存しますか？</p>
                        </InsModal>
                    )}
                    <RouterPrompt
                        handleSave={path => {
                            let values = form.getFieldsValue()
                            return processOnSubmit(values, path)
                            // return true
                        }}
                        isValueChange={isValueChange}
                    />
                    <div className="form-invoice bl-form">
                        <Form
                            form={form}
                            initialValues={initialValues}
                            onFinish={values => setModalConfirmSave(true)}
                            onValuesChange={() => {
                                if (isEdit && !isValueChange) {
                                    setIsValueChange(true)
                                }
                            }}
                        >
                            <Row>
                                <Col>
                                    <h1 className="page-title-big mt-24 mb-16">{'請求書作成'}</h1>
                                </Col>
                            </Row>

                            <Form.Item name="project_id" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>
                            <Form.Item name="customer_id" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>

                            <Row className="mb-16">
                                <Col className="bl-form-col" span={12}>
                                    <Form.Item label={t('column:Customer name')}>
                                        <InsInput
                                            value={customer.customer_name}
                                            disabled
                                            style={{ width: 274 }}
                                            className="customer-name-disabled"
                                        />
                                    </Form.Item>
                                    <Form.Item label={t('column:Customer pic name')}>
                                        <Form.Item name="customer_pic_name" noStyle>
                                            <InsAutoComplete
                                                options={result}
                                                onSearch={handleSuggestion}
                                                placeholder="担当者検索"
                                                className="inline"
                                                style={{ width: 180 }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="customer_pic_title" noStyle>
                                            <InsSelect style={{ width: 90, marginLeft: 4, verticalAlign: 'top' }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.CUSTOMER_TITLE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item name="invoice_name" label={t('件名')}>
                                        <InsInput
                                            style={{ width: 340 }}
                                            placeholder="件名入力"
                                            showMaxLength
                                            maxLength={40}
                                        />
                                    </Form.Item>
                                    <Form.Item name="is_summary_invoice" label="請求対象">
                                        <InsSelect style={{ width: 180 }} onChange={value => setIsBulk(value === 1)}>
                                            <Select.Option value={0}>{'現在の案件のみ'}</Select.Option>
                                            <Select.Option value={1}>{'まとめて請求'}</Select.Option>
                                        </InsSelect>
                                    </Form.Item>
                                    <Form.Item label=" " className="no-label-style">
                                        <div className="invoice-billable">
                                            {!isBulk && countProject > 0 && (
                                                <div className="invoice-billable-warning">
                                                    <ReportProblemIcon size={24} className="color-pantone-213-c mr-8" />
                                                    <span>
                                                        {'他に未請求案件が '}
                                                        <span className="color-pantone-213-c">{`${countProject}件`}</span>
                                                        {' あります'}
                                                    </span>
                                                </div>
                                            )}
                                            {isBulk && (
                                                <div className="invoice-billable-list">
                                                    {sale.project && (
                                                        <div className="invoice-billable-item">
                                                            <FolderOpenIcon
                                                                size={20}
                                                                className="mdi-folder-open mr-8"
                                                            />
                                                            <span className="mr-8">{'案件  :'}</span>
                                                            <span className="mr-16">{`${sale.project.project_code} (現案件)`}</span>
                                                            <AccessTimeIcon
                                                                size={20}
                                                                className="mdi-access-time mr-8"
                                                            />
                                                            <span className="fw-500 spc flex-1">
                                                                {dateToStr(sale.delivery_date, 'YYYY/MM/DD')}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {otherSales.map(oSale => (
                                                        <div className="invoice-billable-item" key={oSale.id}>
                                                            <FolderOpenIcon
                                                                size={20}
                                                                className="mdi-folder-open mr-8"
                                                            />
                                                            <span className="mr-8">{'案件  :'}</span>
                                                            <span className="mr-16">
                                                                <InsButton
                                                                    buttonStyle="plain"
                                                                    linkTo={Routes.private.business.PROJECT_DETAIL.path.replace(
                                                                        ':id',
                                                                        oSale.project.id
                                                                    )}
                                                                    buttonType="link"
                                                                    target="_blank"
                                                                >
                                                                    <span className="project-group-link mr-8">
                                                                        {oSale.project.project_code}
                                                                    </span>
                                                                    <OpenInNewIcon
                                                                        size={16}
                                                                        className="mdi_open_in_new"
                                                                    />
                                                                </InsButton>
                                                            </span>
                                                            <AccessTimeIcon
                                                                size={20}
                                                                className="mdi-access-time mr-8"
                                                            />
                                                            <span className="fw-500 spc flex-1">
                                                                {dateToStr(oSale.delivery_date, 'YYYY/MM/DD')}
                                                            </span>
                                                            <CloseIcon
                                                                size={20}
                                                                className="pointer color-pantone-2738-c"
                                                                onClick={() => otherSaleClose(oSale.id)}
                                                            />
                                                        </div>
                                                    ))}
                                                    <InvoiceProjectModal
                                                        title="まとめて請求する案件選択"
                                                        visible={modalSelectProjectVisible}
                                                        onCancel={() => {
                                                            setModalSelectProjectVisible(false)
                                                        }}
                                                        onOk={selectedSales => {
                                                            if (selectedSales && selectedSales.length > 0) {
                                                                let diffSales = selectedSales.filter(
                                                                    sSale =>
                                                                        sSale.id !== sale.id &&
                                                                        !otherSales.find(oSale => oSale.id === sSale.id)
                                                                )

                                                                setOtherSales([...otherSales, ...diffSales])
                                                            }
                                                            setModalSelectProjectVisible(false)
                                                        }}
                                                        customer={customer}
                                                        exclude_project_ids={
                                                            sale.project
                                                                ? [
                                                                      sale.project.id,
                                                                      ...otherSales.map(oSale => oSale.project.id),
                                                                  ]
                                                                : [...otherSales.map(oSale => oSale.project.id)]
                                                        }
                                                    >
                                                        <InsButton
                                                            style={{ width: 100 }}
                                                            icon={
                                                                <AddIcon size={16} className="color-pantone-2738-c" />
                                                            }
                                                            buttonStyle="default"
                                                            onClick={() => setModalSelectProjectVisible(true)}
                                                        >
                                                            {'案件追加'}
                                                        </InsButton>
                                                    </InvoiceProjectModal>
                                                </div>
                                            )}
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col className="bl-form-col" span={12}>
                                    <Form.Item
                                        name="invoice_code"
                                        label={t('請求番号')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter Invoice code'),
                                                // check unique
                                            },
                                            {
                                                // trigger: 'onBlur',
                                                validator: async (rule, value) => {
                                                    if (isEdit && initialValues.invoice_code.trim() === value.trim())
                                                        return Promise.resolve()

                                                    if (value.trim()) {
                                                        const data = await api.post(
                                                            APP_URLS.INVOICE_CHECK_EXISTS_CODE,
                                                            {
                                                                invoice_code: value,
                                                            }
                                                        )
                                                        if (data && data.is_existed) {
                                                            return Promise.reject(t('validation:Invoice code existed'))
                                                        }
                                                    }

                                                    return Promise.resolve()
                                                },
                                            },
                                            {
                                                max: 20,
                                                message: t('validation:{{item}} is invalid', {
                                                    item: t('column:Invoice number'),
                                                }),
                                            },
                                        ]}
                                        validateTrigger={['onBlur']}
                                        className="hide-require"
                                    >
                                        <InsInput style={{ width: 180 }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="delivery_date"
                                        label={t('納品日')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter issued date'),
                                            },
                                        ]}
                                        className="hide-require"
                                    >
                                        <InsDatePicker
                                            format={dateFormat}
                                            placeholder="発行日入力"
                                            style={{ width: 180 }}
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="sender_id"
                                        label="差出名"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter sender name'),
                                            },
                                        ]}
                                        className="hide-require"
                                    >
                                        <InsSelect
                                            style={{ width: 180 }}
                                            onChange={value => handlerSenderIdChange(value)}
                                        >
                                            {getClassificationsByType(ClassificationTypeEnum.BILL_SENDER.value).map(
                                                clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                )
                                            )}
                                        </InsSelect>
                                    </Form.Item>
                                    <Form.Item name="sender_name" label=" ">
                                        <InsInput style={{ width: 180 }} value={'株式会社稲進'} allowClear />
                                    </Form.Item>
                                    <Form.Item name="sender_address" label=" " style={{ width: 420, marginBottom: 8 }}>
                                        <InsTextArea defaultValue={initialValues.sender_address} />
                                    </Form.Item>
                                    <Form.Item
                                        name="pic_id"
                                        label={t('column:Pic name')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter pic name'),
                                            },
                                        ]}
                                        className="hide-require"
                                    >
                                        <UserSelect
                                            record={initialValues.pic}
                                            style={{ width: 180 }}
                                            placeholder="稲進担当者検索"
                                            dropdownMatchSelectWidth={false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <InvoiceProject sale={sale} otherSales={otherSales} />

                            <Row className="bl-form-detail-control" justify="space-between">
                                <label />
                                <Row className="total">
                                    <Form.Item name="gross_profit" label="粗利">
                                        <InsInputNumber disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="subtotal" label="小計">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="consumption_tax" label="消費税">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="total" label="合計	：:">
                                        <InsInputNumber min={0} disabled style={{ width: 110 }} />
                                    </Form.Item>
                                </Row>
                            </Row>

                            <h3>{'備考'}</h3>
                            <Form.Item name="note">
                                <InsTextArea allowClear={false} />
                            </Form.Item>

                            <InsFloatBar visible={true} className="with-sidebar">
                                <Row gutter={8}>
                                    <Col>
                                        <InvoicePreviewModal
                                            project={project}
                                            invoice={Object.assign(
                                                {},
                                                { customer: customer },
                                                { project: project },
                                                initialValues,
                                                {
                                                    summary_invoice_projects: [
                                                        // { ...sale.project, sale: sale },
                                                        ...otherSales.map(oSale => ({ ...oSale.project, sale: oSale })),
                                                    ],
                                                },
                                                form.getFieldsValue()
                                            )}
                                            visible={modalPreviewVisible}
                                            onCancel={() => {
                                                setModalPreviewVisible(false)
                                            }}
                                        >
                                            <InsButton
                                                buttonStyle="default"
                                                buttonSize="medium"
                                                centered
                                                icon={<PreviewIcon size={16} className="color-pantone-2738-c" />}
                                                onClick={() => {
                                                    setModalPreviewVisible(true)
                                                }}
                                            >
                                                {t('common:Preview')}
                                            </InsButton>
                                        </InvoicePreviewModal>
                                    </Col>
                                </Row>
                                <Row gutter={8} justify="end">
                                    <Col>
                                        <InsButton
                                            buttonStyle="outline"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            onClick={() => {
                                                history.goBack()
                                            }}
                                        >
                                            {t('common:Cancel')}
                                        </InsButton>
                                    </Col>
                                    <Col>
                                        <InsButton
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            isSubmitButton={true}
                                        >
                                            {isEdit ? t('common:Save') : t('common:Save')}
                                        </InsButton>
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        </Form>
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    )
}

export default InvoiceForm

InvoiceForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    project: PropTypes.object.isRequired,
    sale: PropTypes.object.isRequired,
    countProject: PropTypes.number,
}
