const BusinessPageEnum = Object.freeze({
    PROJECTS: { value: 1, text: '案件管理' },
    ESTIMATIONS: { value: 2, text: '見積書' },
    INSTRUCTIONS: { value: 3, text: '作業指示書' },
    PURCHASE_ORDER: { value: 4, text: '発注書' },
    SALES: { value: 5, text: '納品書' },
    // TRACKING: { value: 6, text: '送り状 ' },
    INVOICES: { value: 7, text: '請求書' },
    RED_SLIPS: { value: 8, text: '赤伝' },
})

export default BusinessPageEnum
