import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import InstructionPreview from 'modules/business/instruction/InstructionPreview'
import InstructionCreate from 'modules/business/instruction/InstructionCreate'
import InstructionUpdate from 'modules/business/instruction/InstructionUpdate'
import { setDefaultState } from 'modules/business/instruction/instruction_detail.slice'

const InstructionTab = ({ project, instructionType, setDefaultState, response, createDocumentFrom }) => {
    // useEffect(() => {
    //     setDefaultState()
    // }, [project.id, instructionType])

    const isExisted =
        instructionType === 1
            ? project.instruction_internal && project.instruction_internal.id
            : project.instruction_outsource && project.instruction_outsource.id

    // current Route
    let { path, url } = useRouteMatch()
    const location = useLocation()

    const renderInstructionRoute = () => {
        return (
            <Switch>
                <Route exact path={`${path}`}>
                    {isExisted ? (
                        <InstructionPreview
                            project={project}
                            instructionType={instructionType}
                            instruction={
                                instructionType === 1 ? project.instruction_internal : project.instruction_outsource
                            }
                            updateLink={`${url}/update` + location.search}
                            response={() => response()}
                        />
                    ) : (
                        <Redirect to={`${url}/create` + location.search} />
                    )}
                </Route>
                <Route exact={false} path={`${path}/create`}>
                    {isExisted ? (
                        <Redirect to={`${url}/preview`} />
                    ) : (
                        <InstructionCreate
                            project={project}
                            instructionType={instructionType}
                            createDocumentFrom={createDocumentFrom}
                            response={redirectTo => response(redirectTo)}
                        />
                    )}
                </Route>
                <Route exact path={`${path}/update`}>
                    {isExisted ? (
                        <InstructionUpdate
                            project={project}
                            instruction={
                                instructionType === 1 ? project.instruction_internal : project.instruction_outsource
                            }
                            instructionType={instructionType}
                            response={redirectTo => response(redirectTo)}
                        />
                    ) : (
                        <Redirect to={`${url}/create` + location.search} />
                    )}
                </Route>
                <Route exact path={`${path}/preview`}>
                    <InstructionPreview
                        project={project}
                        instruction={
                            instructionType === 1 ? project.instruction_internal : project.instruction_outsource
                        }
                        instructionType={instructionType}
                        updateLink={`${url}/update` + location.search}
                        response={() => response()}
                    />
                </Route>
            </Switch>
        )
    }

    return <>{renderInstructionRoute()}</>
}

const mapDispatch = {
    setDefaultState,
}

export default connect(() => {}, mapDispatch)(InstructionTab)

InstructionTab.propTypes = {
    project: PropTypes.object.isRequired,
    instructionType: PropTypes.number.isRequired,
    setDefaultState: PropTypes.func.isRequired,
    response: PropTypes.func,
    createDocumentFrom: PropTypes.object,
}
