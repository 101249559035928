import React from 'react'
import { connect } from 'react-redux'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getData, setFilters, setSorter } from 'modules/business/actionLog/action_log.slice'
import { checkAdmin } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import ActionLogListDetailComponent from 'modules/business/actionLog/ActionLogListDetailComponet'

const ActionLogList = ({ state }) => {
    if (!checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return (
        <div className="page-container with-sidebar">
            <ActionLogListDetailComponent
                state={state}
                getData={getData}
                setFilters={setFilters}
                setSorter={setSorter}
            />
        </div>
    )
}

const mapState = state => ({ state: state.actionLog })
export default connect(mapState)(ActionLogList)

ActionLogList.propTypes = {
    state: BaseStatePropShape,
}
