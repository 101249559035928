import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import SaleForm from 'modules/business/sale/form/SaleForm'
import { setUser } from 'modules/common/auth/auth.slice'
import { InsBlockLoader } from 'components/InsLoader'
import { setDefaultState } from 'modules/business/sale/sale_detail.slice'

const SaleCreate = ({ project, updateLink, setUser, currentUser, createDocumentFrom }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const createSale = async (values, redirectTo) => {
        setLoading(true)
        const data = await api.post(APP_URLS.SALES, values)
        if (data) {
            setUser({
                ...currentUser,
                processing_product: data.processing_product,
                processing_spec: data.processing_spec,
                processing_size: data.processing_size,
            })
            updateLink(redirectTo).then(() => {
                setLoading(false)
            })
            dispatch(setDefaultState())
        }
    }

    const getInitialValues = () => {
        let sale = {}
        if (createDocumentFrom.place_order) {
            sale = project.place_order
        } else if (createDocumentFrom.estimation) {
            let details = project.estimation.detail
            let saleDetails = []
            details.forEach(detail => {
                if (!detail.headline) saleDetails.push({ ...detail, ...{ title: detail.product_name } })
                else saleDetails.push({ ...detail })
            })
            sale = {
                ...project.estimation,
                ...{ details: saleDetails, delivery_destinations: [] },
            }
        } else if (createDocumentFrom.instruction_internal) {
            sale = project.instruction_internal
            sale.is_created_from_instruction = true
        } else if (createDocumentFrom.instruction_outsource) {
            sale = project.instruction_outsource
            sale.is_created_from_instruction = true
        } else if (createDocumentFrom.invoice) {
            sale = project.invoice
        } else if (createDocumentFrom.sale) {
            sale = project.sale
        }
        return sale
    }

    return (
        <div className="p-24">
            {loading ? (
                <InsBlockLoader />
            ) : (
                <SaleForm
                    project={project}
                    initialValues={getInitialValues()}
                    onSubmit={(values, redirectTo) => createSale(values, redirectTo)}
                />
            )}
        </div>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUser,
}

export default connect(mapState, mapDispatch)(SaleCreate)

SaleCreate.propTypes = {
    project: PropTypes.object.isRequired,
    isCreateProject: PropTypes.bool,
    updateLink: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
    createDocumentFrom: PropTypes.object,
}
