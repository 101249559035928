import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InsBackLink from 'components/InsBackLink'
import { useParams } from 'react-router-dom'
import ProjectForm from 'modules/business/project/ProjectForm'
import { InsBlockLoader } from 'components/InsLoader'

const ProjectUpdate = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [project, setProject] = useState({})

    const getProject = async id => {
        const data = await api.get(APP_URLS.PROJECT_DETAIL.replace(':id', id))
        if (data) {
            setProject(data)
            setLoading(false)
        }
    }
    useEffect(() => {
        getProject(id)
    }, [id])

    const updateProject = async values => {
        const data = await api.put(APP_URLS.PROJECT_DETAIL.replace(':id', id), values)
        if (data) {
            history.push(APP_URLS.PROJECT_DETAIL.replace(':id', id))
        }
    }

    return (
        <div className="page-container">
            <InsBackLink />
            {loading ? (
                <InsBlockLoader />
            ) : (
                <ProjectForm
                    isEdit={true}
                    initialValues={project}
                    onSubmit={values => updateProject(values)}
                    isPurchaseOrder={project.is_purchase_order}
                />
            )}
        </div>
    )
}

export default ProjectUpdate

ProjectUpdate.propTypes = {
    match: PropTypes.object.isRequired,
}
