import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const InsDraggable = ({ headlineColumns, columns, margin = 8, data, setData, columnMode = 1, columns2 }) => {
    let widthStatic = 0
    let widthDynamic = 0
    const calcWidth = columns => {
        columns.map(col => {
            if (col.staticWidth) widthStatic += col.width
            else widthDynamic += col.width
            return null
        })
        widthStatic += (columns.length + 1) * margin
    }
    calcWidth(columns)

    // weird
    let widthStaticHeadline = 0
    let widthDynamicHeadline = 0
    const calcWidthHeadline = headlineColumns => {
        headlineColumns.map(col => {
            if (col.staticWidth) widthStaticHeadline += col.width
            else widthDynamicHeadline += col.width
            return null
        })
        widthStaticHeadline += (headlineColumns.length + 1) * margin
    }
    calcWidthHeadline(headlineColumns)

    let widthStatic2 = 0
    let widthDynamic2 = 0
    const calcWidth2 = columns => {
        if (columns) {
            columns.map(col => {
                if (col.staticWidth) widthStatic2 += col.width
                else widthDynamic2 += col.width
                return null
            })
            widthStatic2 += (columns.length + 1) * margin
        }
    }
    calcWidth2(columns2)

    const makeStyle = (col, isFirst, widthStatic, widthDynamic) => {
        if (col.staticWidth) {
            return { width: col.width, marginRight: margin, marginLeft: isFirst && margin }
        } else {
            let per = col.width / widthDynamic
            return {
                width: `calc((100% - ${widthStatic}px) * ${per})`,
                marginRight: margin,
                marginLeft: isFirst && margin,
            }
        }
    }

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const newItems = reorder(data, result.source.index, result.destination.index)
        setData(newItems)
    }

    return (
        <div className="ins-draggable">
            <div className="ins-draggable-table">
                <div className="ins-draggable-heading">
                    {(columnMode === 2 ? columns2 : columns).map((col, index) => (
                        <span
                            className="ins-draggable-heading-cell"
                            key={index}
                            style={makeStyle(
                                col,
                                index === 0,
                                columnMode === 2 ? widthStatic2 : widthStatic,
                                columnMode === 2 ? widthDynamic2 : widthDynamic
                            )}
                        >
                            {col.Header}
                        </span>
                    ))}
                </div>
                <div className="ins-draggable-body">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}>
                                    {data.map((item, index) => (
                                        <Draggable key={index} draggableId={'draggable-item__' + index} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className="ins-draggable-tr"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <div className="ins-draggable-tr-wrap">
                                                        {(item.headline || item.isHeadline
                                                            ? headlineColumns
                                                            : columnMode === 2 && columns2
                                                            ? columns2
                                                            : columns
                                                        ).map((col, _index) => (
                                                            <div
                                                                key={'ins-draggable-tr-cell-normal' + _index}
                                                                className={'ins-draggable-tr-cell'}
                                                                style={makeStyle(
                                                                    col,
                                                                    _index === 0,
                                                                    item.headline || item.isHeadline
                                                                        ? widthStaticHeadline
                                                                        : columnMode === 2
                                                                        ? widthStatic2
                                                                        : widthStatic,
                                                                    item.headline || item.isHeadline
                                                                        ? widthDynamicHeadline
                                                                        : columnMode === 2
                                                                        ? widthDynamic2
                                                                        : widthDynamic
                                                                )}
                                                                onClick={() =>
                                                                    col.handleClick && col.handleClick(item, index)
                                                                }
                                                                {...(col.dragHandle && provided.dragHandleProps)}
                                                            >
                                                                {col.cell ? (
                                                                    col.cell
                                                                ) : (
                                                                    <div className={'ins-draggable-input-data'}>
                                                                        {(col.render &&
                                                                            col.render(
                                                                                item,
                                                                                index
                                                                                // item.display_order
                                                                            )) ||
                                                                            item[col.accessor]}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    )
}

InsDraggable.propTypes = {
    headlineColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    margin: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    setData: PropTypes.func,
    columnMode: PropTypes.number,
    columns2: PropTypes.arrayOf(PropTypes.object),
}

export default InsDraggable
