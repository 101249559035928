import React from 'react'
import { AddIcon, EditIcon } from 'components/InsIcon'
import PropTypes from 'prop-types'
import InsDropdown from 'components/dropdown/InsDropdown'
import { useTranslation } from 'react-i18next'
import { can, getPermissionName } from 'helpers/permission.helper'

const ProjectBusinessTab = ({ url, tabs = [], activeKey, onTabChange, isNoDocument, isLocked }) => {
    const { t } = useTranslation()
    const handleOnTabChange = (tab, key) => {
        onTabChange(tab.id, key)
    }
    return (
        <div className="add-cld-project project-detail-tab">
            <div className="ins-tab-wrapper">
                <div className="ant-tabs ant-tabs-top ant-tabs-card ant-tabs-no-animation">
                    <div className="ant-tabs-bar ant-tabs-top-bar ant-tabs-card-bar">
                        <div className="ant-tabs-nav-container">
                            <div className="ant-tabs-nav-wrap">
                                <div className="ant-tabs-nav-scroll">
                                    <div className="ant-tabs-nav ant-tabs-nav-animated">
                                        <div>
                                            {tabs.map(tab => {
                                                let isActive = can(getPermissionName(tab.id)) && !isLocked
                                                let isShowTab = tab.isShowTab == null ? true : tab.isShowTab
                                                let isShowMenuDocument =
                                                    tab.isShowMenuDocument == null ? true : tab.isShowMenuDocument
                                                return (
                                                    isShowTab &&
                                                    tab.id && (
                                                        <div
                                                            className={`dis-inline-bl ${
                                                                tab.addIcon && !isActive ? 'cur-no-drop' : ''
                                                            }`}
                                                        >
                                                            <div
                                                                onClick={() => {
                                                                    if (!tab.addIcon || !isShowMenuDocument) {
                                                                        handleOnTabChange(tab, tab.id)
                                                                    }
                                                                }}
                                                                key={tab.id}
                                                                className={`ant-tabs-tab ${
                                                                    activeKey === tab.id ? 'ant-tabs-tab-active' : ''
                                                                } ${!tab.addIcon ? 'tab-has-info' : ''} ${
                                                                    tab.addIcon && !isActive ? 'locked' : ''
                                                                }`}
                                                                id={tab.id}
                                                            >
                                                                {activeKey === tab.id ? (
                                                                    <span className={'ins-flex'}>
                                                                        {tab.addIcon && (
                                                                            <AddIcon
                                                                                size={24}
                                                                                className={'add-icon mr-4'}
                                                                            />
                                                                        )}
                                                                        {tab.text}
                                                                        <EditIcon
                                                                            size={16}
                                                                            className={'edit-icon ml-12'}
                                                                        />
                                                                    </span>
                                                                ) : isShowMenuDocument && tab.addIcon ? (
                                                                    <InsDropdown
                                                                        disabled={!isActive}
                                                                        onSelect={key => {
                                                                            handleOnTabChange(tab, key)
                                                                        }}
                                                                        options={tabs.map(tabItem => {
                                                                            let pTabShow =
                                                                                tabItem.isShowMenuDocument == null
                                                                                    ? true
                                                                                    : tabItem.isShowMenuDocument
                                                                            if (
                                                                                pTabShow &&
                                                                                tabItem.id &&
                                                                                !tabItem.addIcon &&
                                                                                tabItem.id !== tab.id
                                                                            ) {
                                                                                return {
                                                                                    key: tabItem.id,
                                                                                    text:
                                                                                        tabItem.text + t('Create from'),
                                                                                }
                                                                            }

                                                                            if (!tabItem.id) {
                                                                                return {
                                                                                    key: null,
                                                                                    text: t('Create new'),
                                                                                }
                                                                            }
                                                                        })}
                                                                        trigger={['click']}
                                                                    >
                                                                        <span
                                                                            className={
                                                                                tab.text === '赤伝'
                                                                                    ? 'font-tab-rs'
                                                                                    : 'ins-flex'
                                                                            }
                                                                        >
                                                                            {tab.addIcon && (
                                                                                <AddIcon
                                                                                    size={24}
                                                                                    className={'add-icon mr-4'}
                                                                                />
                                                                            )}
                                                                            {tab.text}
                                                                            <EditIcon
                                                                                size={16}
                                                                                className={'edit-icon ml-12'}
                                                                            />
                                                                        </span>
                                                                    </InsDropdown>
                                                                ) : (
                                                                    <span
                                                                        className={
                                                                            tab.text === '赤伝'
                                                                                ? 'font-tab-rs'
                                                                                : 'ins-flex'
                                                                        }
                                                                    >
                                                                        {tab.addIcon && (
                                                                            <AddIcon
                                                                                size={24}
                                                                                className={'add-icon mr-4'}
                                                                            />
                                                                        )}
                                                                        {tab.text}
                                                                        <EditIcon
                                                                            size={16}
                                                                            className={'edit-icon ml-12'}
                                                                        />
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectBusinessTab

ProjectBusinessTab.propTypes = {
    url: PropTypes.string,
    tabs: PropTypes.array,
    activeKey: PropTypes.number,
    onTabChange: PropTypes.func,
    isNoDocument: PropTypes.bool,
    isLocked: PropTypes.number,
}
