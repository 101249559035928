import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import moment from 'moment'

const name = 'projectModal'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.PROJECT, ...props })
)
const projectSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: {
            'status': 0,
            'is_purchase_order': false,
            'created_at:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:00'),
            'created_at:lte': moment().format('YYYY/MM/DD 23:59:59'),
        },
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setSorter,
    setSelection,
} = projectSlice.actions

export default projectSlice.reducer
