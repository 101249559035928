import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Checkbox, Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import InsModal from 'components/InsModal'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    addChecked,
    addSettings,
    moveSetting,
    removeChecked,
    removeSettings,
    setDefaultState,
} from 'modules/master/user/user_setting.slice'
import { MenuIcon } from 'components/InsIcon'
import InsHelpText from 'components/InsHelpText'

const UserSettingDragList = React.memo(({ data, order, addChecked, removeChecked }) => {
    return order.map((id, index) => {
        return (
            <Draggable key={id} draggableId={id} index={index}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div className="ins-drag-row-wrapper" style={{ borderBottom: 0, padding: 4 }}>
                            <Row justify="space-between" align="middle" className="row-setting-label">
                                <Checkbox
                                    onChange={value => {
                                        if (value.target.checked) {
                                            addChecked(data[id])
                                        } else {
                                            removeChecked(data[id])
                                        }
                                    }}
                                >
                                    {data[id].text}
                                </Checkbox>
                                <div className="ins-drag-row-handle" {...provided.dragHandleProps}>
                                    <MenuIcon size={16} />
                                </div>
                            </Row>
                        </div>
                    </div>
                )}
            </Draggable>
        )
    })
})

const UserSettingDragAndDrop = React.memo(({ settings, settingsOrder, moveSetting, addChecked, removeChecked }) => {
    const { t } = useTranslation()
    const onDragEnd = result => {
        if (!result.destination) return
        const srcIndex = result.source.index
        const desIndex = result.destination.index
        moveSetting({ srcIndex, desIndex })
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {provided => (
                    <div ref={provided.innerRef}>
                        <UserSettingDragList
                            data={settings}
                            order={settingsOrder}
                            addChecked={addChecked}
                            removeChecked={removeChecked}
                        />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
})

UserSettingDragAndDrop.propTypes = {
    settings: PropTypes.object.isRequired,
    settingsOrder: PropTypes.array.isRequired,
    moveSetting: PropTypes.func.isRequired,
    addChecked: PropTypes.func.isRequired,
}

const UserSettingModal = ({
    userSettingEnum,
    userSettings,
    visible,
    onCancel,
    onOk,
    settings,
    settingsOrder,
    moveSetting,
    removeSettings,
    addSettings,
    addChecked,
    checked,
    removeChecked,
    notSettings,
    setDefaultState,
}) => {
    const { t } = useTranslation()
    const [highlightWarning, setHighlightWarning] = useState(false)
    useEffect(() => {
        setDefaultState()
        const arrayNotSettings = []
        const arraySettings = []
        userSettings.forEach(setting => {
            Object.values(userSettingEnum).forEach(column => {
                if (setting === column.value) {
                    arraySettings.push({ ...column, is_setting: true })
                }
            })
        })
        Object.values(userSettingEnum).forEach(setting => {
            if (!userSettings.includes(setting.value)) {
                arrayNotSettings.push({ ...setting, is_setting: false })
            }
        })
        addSettings(arraySettings)
        addSettings(arrayNotSettings)
    }, [])

    const activateHighlightWarning = () => {
        setHighlightWarning(true)
        setTimeout(function () {
            setHighlightWarning(false)
        }, 3000)
    }

    return (
        <InsModal
            className="ins-detail-modal"
            title={t('Item display settings')}
            width={875}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Row justify="space-between" align="center">
                <Col>
                    <h3>{t('Selectable items')}</h3>
                    {Object.values(notSettings).map(notSetting => {
                        return (
                            <div style={{ padding: 4 }}>
                                <div className="row-setting-label" style={{ padding: 8 }}>
                                    <Checkbox
                                        checked={checked.indexOf(notSetting) !== -1}
                                        onChange={value => {
                                            if (value.target.checked) {
                                                addChecked(notSetting)
                                            } else {
                                                removeChecked(notSetting)
                                            }
                                        }}
                                    >
                                        {notSetting.text}
                                    </Checkbox>
                                </div>
                            </div>
                        )
                    })}
                </Col>
                <Col>
                    <div style={{ marginTop: 100 }}>
                        <div>
                            <InsButton
                                buttonStyle="outline"
                                centered
                                onClick={() => {
                                    let numberItem = Object.keys(settings).length + checked.length
                                    if (checked && checked.length > 0 && numberItem < 8) {
                                        let newValues = checked.filter(c => c.is_setting === false)
                                        if (newValues.length > 0) removeSettings(newValues)
                                    }
                                    if (numberItem > 7) {
                                        activateHighlightWarning()
                                    }
                                }}
                                style={{ width: 96 }}
                            >
                                {t('→')}
                            </InsButton>
                        </div>
                        <div style={{ height: 8 }} />
                        <div>
                            <InsButton
                                buttonStyle="outline"
                                centered
                                onClick={() => {
                                    if (checked && checked.length > 0) {
                                        let newValues = checked.filter(c => c.is_setting === true)
                                        if (newValues.length > 0) removeSettings(newValues)
                                    }
                                }}
                                style={{ width: 96 }}
                            >
                                {t('←')}
                            </InsButton>
                        </div>
                    </div>
                </Col>
                <Col>
                    <h3>{t('Display items')}</h3>
                    <UserSettingDragAndDrop
                        settings={settings}
                        settingsOrder={settingsOrder}
                        moveSetting={moveSetting}
                        addChecked={addChecked}
                        removeChecked={removeChecked}
                    />
                </Col>
            </Row>
            <InsHelpText className={highlightWarning ? 'ml-8 color-pure-red' : 'ml-8'} hasIcon={false}>
                {'※ドラッグ＆ドロップで表示項目の選択が出来ます。表示項目は７つまで設定出来ます。'}
            </InsHelpText>
        </InsModal>
    )
}

const mapState = state => ({
    settings: state.userSetting.settings,
    settingsOrder: state.userSetting.settingsOrder,
    checked: state.userSetting.checked,
    notSettings: state.userSetting.notSettings,
})

const mapDispatch = {
    moveSetting,
    removeSettings,
    addSettings,
    addChecked,
    removeChecked,
    setDefaultState,
}

export default connect(mapState, mapDispatch)(UserSettingModal)

UserSettingModal.propTypes = {
    userSettingEnum: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    moveSetting: PropTypes.func.isRequired,
    removeSettings: PropTypes.func.isRequired,
    addSettings: PropTypes.func.isRequired,
    addChecked: PropTypes.func.isRequired,
    removeChecked: PropTypes.func.isRequired,
    setDefaultState: PropTypes.func.isRequired,
}
UserSettingModal.defaultProps = {
    visible: false,
}
