import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Tabs, Checkbox } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import UserRoleEnum from 'enums/user_role.enum'
import { getEnumText } from 'helpers/enum.helper'
import history from 'helpers/history.helper'
import Routes from 'routes'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon } from 'components/InsIcon'
import PropTypes from 'prop-types'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import UserListSearch from 'modules/master/user/UserListSearch'
import { setPagination } from './user.slice'

const UserListComponent = ({
    inModal = false,
    onSelect,
    state,
    getData,
    setFilters,
    setSorter,
    setKeywords,
    setPagination,
    hasSelection = false,
    onSelectionChange,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const userColumns = [
        {
            title: t('column:Person code'),
            dataIndex: 'pic_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        if (inModal) {
                            onSelect(record)
                        } else {
                            history.push(Routes.private.master.USER_DETAIL.path.replace(':id', record.id))
                        }
                    }}
                >
                    {text}
                </InsButton>
            ),
        },
        { title: t('column:Contact name'), dataIndex: 'pic_name', sorter: true },
        {
            title: t('column:Team'),
            dataIndex: 'team|team_name',
            sorter: true,
            render: (text, record) => record.team && record.team.team_name,
        },
        {
            title: t('column:User role'),
            dataIndex: 'user_role',
            render: text => t(`enum:${getEnumText(UserRoleEnum, text)}`),
        },
    ]

    const updateFilters = obj => {
        const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        dispatch(setFilters(newFilters))
        getUsers(newPagination, newFilters)
    }
    const tabChangeHandler = key => {
        dispatch(setKeywords([]))
        const newFilters = Object.assign({}, state.filters, {
            keyword: [],
            is_enabled: key,
        })
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setFilters(newFilters))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getUsers(newPagination, newFilters, newSorter)
    }

    const getUsers = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            {!inModal && (
                <Row className="mt-8 mb-16" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-32">{t('navigation:User management')}</h1>
                    </Col>
                    <Col>
                        <InsButton
                            className="btn-create-user"
                            buttonType="link"
                            linkTo={Routes.private.master.USER_CREATE.path}
                            icon={<AddIcon size={24} />}
                            buttonStyle="secondary"
                        >
                            {t('navigation:User create')}
                        </InsButton>
                    </Col>
                </Row>
            )}
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <UserListSearch
                        onSearch={condition => updateFilters(condition)}
                        onReset={() => updateFilters({})}
                        // pagination={state.pagination}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsTab
                        type="card"
                        metaComponent={
                            <InsPagination
                                {...state.pagination}
                                onChange={pagination => getUsers(pagination)}
                                pageSizeOptions={inModal ? [15, 25, 50] : [100, 200, 300]}
                            />
                        }
                        onChange={key => {
                            tabChangeHandler(key)
                        }}
                        activeKey={state.filters.is_enabled.toString()}
                        defaultActiveKey="1"
                    >
                        <Tabs.TabPane tab={t('common:Enabled')} key="1" />
                        <Tabs.TabPane tab={t('common:Disabled')} key="0" />
                    </InsTab>
                    <InsDataTable
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered={true}
                        data={state.data}
                        columns={userColumns}
                        onChange={(pagination, filter, sorter) => {
                            getUsers(state.pagination, state.filters, sorter)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        ctlSortOrder={true}
                        scroll={inModal ? { y: 400 } : null}
                        hasSelection={hasSelection}
                        onSelectionChanged={onSelectionChange}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default UserListComponent

UserListComponent.propTypes = {
    inModal: PropTypes.bool,
    onSelect: PropTypes.func,
    state: PropTypes.shape(BaseStatePropShape),
    getData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
}

UserListComponent.propTypes = {
    inModal: false,
}
