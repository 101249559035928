import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Select } from 'antd'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { getClassificationsByType } from 'helpers/classification.helper'
import InsInputSearch from 'components/InsInputSearch'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsInput from 'components/InsInput'
import InsButton from 'components/InsButton'
import InsCollapse from 'components/InsCollapse'
import { setDetailSearch, setKeywords, updateDetailSearch } from 'modules/master/supplier/supplier.slice'
import { setKeywords as setModalKeywords } from 'modules/master/supplier/supplier_modal.slice'
import InsSelect from 'components/select/InsSelect'

const SupplierListSearch = ({
    inModal = false,
    keywords,
    modalKeywords,
    detailSearch,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setModalKeywords,
    onSearch,
    onReset,
}) => {
    const { t } = useTranslation()
    const collapseRef = useRef()

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <InsInputSearch
                    style={{ width: '380px' }}
                    placeholder={t('Supplier code, supplier name, note, etc')}
                    value={inModal ? modalKeywords : keywords}
                    onChange={values => {
                        if (inModal) {
                            setModalKeywords(values)
                        } else {
                            setKeywords(values)
                        }
                    }}
                    onSearch={values => {
                        setDetailSearch({})
                        onSearch({ keyword: values })
                    }}
                />
            }
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'140px'}>
                    <strong>{t('column:Supplier code')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['supplier_code:like']}
                        onChange={value => updateDetailSearch({ field: 'supplier_code:like', value })}
                    />
                </Col>
                <Col className="ml-32" flex={'140px'}>
                    <strong>{t('column:Supplier name')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['supplier_name:like']}
                        onChange={value => updateDetailSearch({ field: 'supplier_name:like', value })}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'140px'}>
                    <strong>{t('column:Prefecture code')}</strong>
                </Col>
                <Col>
                    <InsSelect
                        style={{ width: '180px' }}
                        onSelect={value => updateDetailSearch({ field: 'prefecture_code', value })}
                        value={detailSearch['prefecture_code']}
                    >
                        {getClassificationsByType(ClassificationTypeEnum.PREFECTURE.value).map(c => (
                            <Select.Option key={c.value} value={c.value}>
                                {c.text}
                            </Select.Option>
                        ))}
                    </InsSelect>
                </Col>
                <Col className="ml-32" flex={'140px'}>
                    <strong>{t('column:Address')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['address:like']}
                        onChange={value => updateDetailSearch({ field: 'address:like', value })}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'140px'}>
                    <strong>{t('column:Phone number')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['phone_number:like']}
                        onChange={value => updateDetailSearch({ field: 'phone_number:like', value })}
                    />
                </Col>
                <Col className="ml-32" flex={'140px'}>
                    <strong>{t('column:Fax number')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['fax_number:like']}
                        onChange={value => updateDetailSearch({ field: 'fax_number:like', value })}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'140px'}>
                    <strong>{t('column:Note')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['note:like']}
                        onChange={value => updateDetailSearch({ field: 'note:like', value })}
                    />
                </Col>
            </Row>
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    keywords: state.supplier.keywords,
    detailSearch: state.supplier.detailSearch,
    modalKeywords: state.supplierModal.keywords,
})
const mapDispatch = { setKeywords, setModalKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(SupplierListSearch)

SupplierListSearch.propTypes = {
    inModal: PropTypes.bool,
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    modalKeywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
    setModalKeywords: PropTypes.func.isRequired,
}
