import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'

const name = 'outsourceModal'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.OUTSOURCES, ...props })
)

const outsourceSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: { is_enabled: 1 },
        pagination: { current: 1, pageSize: 15, total: 1 },
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setSorter,
    setSelection,
} = outsourceSlice.actions

export default outsourceSlice.reducer
