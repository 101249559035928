const viewportHeight = window.innerHeight || document.documentElement.clientHeight
export const handleScrollOnFocus = e => {
    e.target.autocomplete = 'new-password'
    if (e.target.tagName === 'BUTTON') return

    const bounding = e.target.getBoundingClientRect()
    const toBottomPx = viewportHeight - bounding.bottom
    const requireAtLeast = 64 + 24 // float bar + margin
    const count = bounding.height + window.scrollY + (requireAtLeast - toBottomPx)
    if (toBottomPx < requireAtLeast) {
        // e.target.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' })
        window.scrollTo({
            top: count,
            left: 0,
            behavior: 'smooth',
        })
    }
}
