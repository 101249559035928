import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Input, Select, Radio, Tabs } from 'antd'
import { connect, useDispatch, useStore } from 'react-redux'
import InsInput from 'components/InsInput'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsButton from 'components/InsButton'
import { AddIcon, CloseIcon, DeleteIcon } from 'components/InsIcon'
import Routes from 'routes'
import InsFloatBar from 'components/InsFloatBar'
import { getEnumText } from 'helpers/enum.helper'
import UserRoleEnum from 'enums/user_role.enum'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsLoader from 'components/InsLoader'
import InsDataTable from 'components/datatable/InsDataTable'
import { getData, setPagination, setFilters, setSorter, setLoading } from 'modules/master/user/user_modal.slice'
import { useParams } from 'react-router-dom'
import InsHelpText from 'components/InsHelpText'
import history from 'helpers/history.helper'
import InsModal from 'components/InsModal'
import UserListComponent from 'modules/master/user/UserListComponent'
import { showWarning } from 'helpers/notification.helper'
import { handleScrollOnFocus } from 'helpers/form_event.helper'

const TeamForm = ({
    isEdit,
    initialValues,
    onSubmit,
    state,
    userState,
    editTeamName,
    setEditTeamName,
    modalConfirmSave,
    setModalConfirmSave,
}) => {
    const { id } = useParams()
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const ref = useRef()
    const dispatch = useDispatch()
    const [userIds, setUserIds] = useState([]) //select a lot
    const [selectedUser, setSelectedUser] = useState() //select one
    const [userListModalVisible, setUserListModalVisible] = useState(false)
    const [deleteTeamModal, setDeleteTeamModal] = useState({
        visible: false,
    })
    const [userList, setUserList] = useState([])
    const [noTeamUsers, setNoTeamUsers] = useState([])

    const userColumns = [
        {
            title: t('column:Person code'),
            dataIndex: 'pic_code',
            // sorter: true,
        },
        { title: t('column:Contact name'), dataIndex: 'pic_name' /*sorter: true*/ },
        {
            title: t('column:User role'),
            dataIndex: 'user_role',
            // sorter: true,
            render: text => t(`enum:${getEnumText(UserRoleEnum, text)}`),
        },
    ]

    const deleteTeamUser = async userIds => {
        let userListTotal = userList
        let noTeamUsersRemain = noTeamUsers
        let deletedUsers = []
        let unSelectedUserId = []

        userListTotal.forEach(user => {
            if (userIds.includes(user.id)) {
                deletedUsers.push(user)
            } else {
                unSelectedUserId.push(user.id)
            }
        })
        deletedUsers.forEach(user => {
            userListTotal.splice(userListTotal.indexOf(user), 1)
            noTeamUsersRemain.push(user)
            ref.current.resetSelectedRowKeys()
        })
        setNoTeamUsers([...noTeamUsersRemain])
        setUserList([...userListTotal])

        if (isEdit) {
            api.put(APP_URLS.TEAMS_DETAIL.replace(':id', initialValues.id), {
                users: unSelectedUserId,
            }).then(r => {
                dispatch(setLoading(true))
                getUser()
            })
        } else {
            form.setFieldsValue({ users: unSelectedUserId })
        }
    }

    const addTeamUser = addedUsers => {
        let userListTotal = userList
        let noTeamUsersRemain = noTeamUsers
        addedUsers.forEach(user => {
            if (userListTotal.findIndex(value => value.id === user.id) === -1) {
                userListTotal.push(user)
                setUserListModalVisible(false)
            } else {
                showWarning('', t('選択されたユーザーは既にそのチームに登録されています。'))
            }
            noTeamUsersRemain.splice(
                noTeamUsersRemain.findIndex(value => value.id === user.id),
                1
            )
            ref.current.resetSelectedRowKeys()
        })

        setUserList([...userListTotal])
        setNoTeamUsers([...noTeamUsersRemain])

        let inputUserIds = []
        userList.forEach(value => {
            inputUserIds.push(value.id)
        })

        if (isEdit) {
            api.put(APP_URLS.TEAMS_DETAIL.replace(':id', initialValues.id), {
                users: inputUserIds,
            }).then(r => {
                dispatch(setLoading(true))
                getUser()
            })
        } else {
            form.setFieldsValue({ users: inputUserIds })
        }
    }

    const deleteTeam = async values => {
        const data = await api.delete(APP_URLS.TEAMS_DETAIL.replace(':id', id), values)
        if (data) {
            history.goBack()
        }
    }

    const getUser = () => {
        if (isEdit) {
            api.get(APP_URLS.TEAMS_DETAIL.replace(':id', id)).then(data => {
                if (data) {
                    setUserList(data.users)
                    dispatch(setLoading(false))
                }
            })
        } else {
            setUserList(userList)
            dispatch(setLoading(false))
        }
    }

    useEffect(() => {
        if (isEdit) {
            dispatch(setLoading(true))
            getUser()
        }
    }, [])

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => onSubmit(form.getFieldsValue(), null, false)}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <InsModal
                destroyOnClose={true}
                className="ins-list-modal"
                title={t('navigation:{{entity}} list', { entity: t('entity:User') })}
                width={760}
                visible={userListModalVisible}
                onCancel={() => setUserListModalVisible(false)}
                footer={null}
            >
                <UserListComponent
                    inModal={true}
                    state={userState}
                    getData={getData}
                    setSorter={setSorter}
                    setFilters={setFilters}
                    onSelect={user => {
                        if (!user.team_id) {
                            addTeamUser([user])
                        } else showWarning('', t('選択されたユーザーは既に他のチームに登録されています。'))
                    }}
                />
            </InsModal>
            <Form
                className="ins-form pt-0 mb-20"
                layout="vertical"
                form={form}
                size={'middle'}
                onFinish={values => onSubmit(values)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <div className="ins-form-items">
                    {(!isEdit || editTeamName) && (
                        <>
                            <h2 className="section-title mb-24">{t('common:Basic information')}</h2>
                            <Row className="ml-16">
                                <Col>
                                    <Form.Item
                                        name="team_name"
                                        label={t('column:Team name')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter team name'),
                                            },
                                            {
                                                // trigger: 'onBlur',
                                            },
                                        ]}
                                    >
                                        <InsInput style={{ width: 483 }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="ml-16">
                                <Col>
                                    <Form.Item
                                        name="team_code"
                                        label={t('column:Team code')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter team code'),
                                            },
                                            {
                                                // trigger: 'onBlur',
                                            },
                                        ]}
                                    >
                                        <InsInput style={{ width: 483 }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <h2 className="section-title mb-24">{t('navigation:User add')}</h2>
                        </>
                    )}
                    <Form.Item name="users">
                        {/*<InsTab*/}
                        {/*    type="card"*/}
                        {/*    metaComponent={*/}
                        {/*        <InsPagination*/}
                        {/*            {...state.pagination}*/}
                        {/*            onChange={pagination => getUser(pagination)}*/}
                        {/*            pageSizeOptions={[15, 25, 50]}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    className="hide-tabs"*/}
                        {/*>*/}
                        {/*    <Tabs.TabPane tab={'placeholder'} key="0" />*/}
                        {/*</InsTab>*/}
                        <InsDataTable
                            ref={r => (ref.current = r)}
                            loading={userState.loading ? { indicator: <InsLoader /> } : false}
                            tableStyle="compact"
                            striped
                            bordered={true}
                            data={userList}
                            columns={userColumns}
                            // onChange={(pagination, filter, sorter) => {
                            //     getUser(userState.pagination, userState.filters, sorter)
                            // }}
                            hasSelection={true}
                            onSelectionChanged={keys => {
                                if (keys.length === 1) {
                                    let user = userList.find(element => element.id === keys[0])
                                    setSelectedUser(user)
                                }
                                setUserIds(keys)
                            }}
                            pagination={userState.pagination}
                            filters={userState.filters}
                            sorter={userState.sorter}
                        />
                        {!userState.loading && (
                            <div className="mt-8">
                                <InsButton
                                    icon={
                                        <DeleteIcon size={20} isSubmitButton={true} className="color-pantone-213-c" />
                                    }
                                    buttonStyle="default"
                                    onClick={() => {
                                        if (userIds.length) {
                                            deleteTeamUser(userIds)
                                        }
                                    }}
                                >
                                    {t('Delete user of team')}
                                </InsButton>
                                <InsButton
                                    className="ml-8"
                                    icon={<AddIcon size={20} className="color-pantone-2738-c" />}
                                    buttonStyle="default"
                                    onClick={() => setUserListModalVisible(true)}
                                >
                                    {t('navigation:User add')}
                                </InsButton>
                            </div>
                        )}
                    </Form.Item>
                </div>

                {(!isEdit || editTeamName) && (
                    <>
                        <div style={{ marginTop: '100px' }}>
                            <InsFloatBar visible={true} className="with-sidebar">
                                <Row gutter={8} justify="end">
                                    {editTeamName ? (
                                        <>
                                            <Col>
                                                <InsButton
                                                    buttonStyle="outline"
                                                    buttonSize="big"
                                                    centered
                                                    width="120px"
                                                    icon={<CloseIcon />}
                                                    onClick={() => {
                                                        form.setFieldsValue({ team_name: initialValues.team_name })
                                                        form.setFieldsValue({ team_code: initialValues.team_code })
                                                        setEditTeamName(false)
                                                    }}
                                                >
                                                    {t('common:Cancel')}
                                                </InsButton>
                                            </Col>
                                            <Col>
                                                <InsButton
                                                    buttonStyle="primary"
                                                    buttonSize="big"
                                                    centered
                                                    isSubmitButton={true}
                                                    className="pl-16 pr-16"
                                                >
                                                    {t('navigation:{{entity}} edit', {
                                                        entity: t('entity:Team'),
                                                    })}
                                                </InsButton>
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col>
                                                <InsButton
                                                    buttonStyle="outline"
                                                    buttonSize="big"
                                                    centered
                                                    width="120px"
                                                    icon={<CloseIcon />}
                                                    linkTo={Routes.private.master.TEAM_LIST.path}
                                                    buttonType="link"
                                                >
                                                    {t('common:Cancel')}
                                                </InsButton>
                                            </Col>
                                            <Col>
                                                <InsButton
                                                    buttonStyle="primary"
                                                    buttonSize="big"
                                                    centered
                                                    icon={<AddIcon />}
                                                    isSubmitButton={true}
                                                >
                                                    {t('navigation:{{entity}} create new', {
                                                        entity: t('entity:Team'),
                                                    })}
                                                </InsButton>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </InsFloatBar>
                        </div>
                    </>
                )}
                {isEdit && !editTeamName && (
                    <>
                        <InsModal
                            title={t('common:Delete')}
                            visible={deleteTeamModal.visible}
                            onCancel={() =>
                                setDeleteTeamModal({
                                    visible: false,
                                })
                            }
                            onOk={() => deleteTeam()}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <h2>{t('common:Are you sure')}</h2>
                        </InsModal>
                        {!userState.loading && (
                            <InsFloatBar visible={true}>
                                <Row justify="space-between" align="middle">
                                    <Col>
                                        <InsButton
                                            width="76px"
                                            disabled={userList?.length !== 0}
                                            icon={<DeleteIcon size={16} />}
                                            onClick={() =>
                                                setDeleteTeamModal({
                                                    visible: true,
                                                })
                                            }
                                        >
                                            {t('common:Delete')}
                                        </InsButton>
                                        <InsHelpText className="ml-8">
                                            {t('Can not delete in-used {{item}}', { item: t('entity:Team') })}
                                        </InsHelpText>
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        )}
                    </>
                )}
            </Form>
        </>
    )
}

const mapState = state => ({ state: state.teamUser, userState: state.userModal })
export default connect(mapState)(TeamForm)

TeamForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    state: BaseStatePropShape,
    userState: BaseStatePropShape,
}
