import React, { useState } from 'react'
import InsDraggable from '../../components/draggable/InsDraggable'
import { CloseIcon, MenuIcon, TruckIcon } from '../../components/InsIcon'

const DraggableDemo = () => {
    const headlineColumns = []
    const columns = [
        {
            Header: '',
            cell: <MenuIcon className="mdi_menu" size={16} />,
            width: 16,
            staticWidth: true,
            dragHandle: true,
        },
        {
            Header: '',
            cell: <TruckIcon className="mdi_local_shipping" size={20} />,
            width: 20,
            staticWidth: true,
        },
        {
            Header: '納入先',
            accessor: 'text1',
            width: 254,
        },
        {
            Header: '住所',
            accessor: 'text2',
            width: 440,
        },
        {
            Header: '直送先担当者',
            accessor: 'text3',
            width: 110,
        },
        {
            Header: '敬称',
            accessor: 'text4',
            width: 60,
        },
        {
            Header: '',
            cell: <CloseIcon className="mdi_close" />,
            width: 24,
            staticWidth: true,
            handleClick: () => {},
        },
    ]

    const [data, setData] = useState([
        {
            text1: '㈱日本ﾃﾞﾘｶﾌﾚｯｼｭ 名古屋工場',
            text2: '490-1114 愛知県海部郡甚目寺町下萱津新替998',
            text3: '田中',
            text4: '様',
        },
        {
            text1: '㈱日本ﾃﾞﾘｶﾌﾚｯｼｭ　豊川工場',
            text2: '442-0061 愛知県豊川市穂ノ原3-2-7',
            text3: '高橋',
            text4: '様',
        },
    ])

    return (
        <div className="page-container">
            <InsDraggable
                margin={16}
                columns={columns}
                data={data}
                setData={setData}
                headlineColumns={headlineColumns}
            />
        </div>
    )
}

export default DraggableDemo
