export const ActionLogObject = Object.freeze({
    CUSTOMER: { value: '001', text: '顧客' },
    PRODUCT: { value: '002', text: '商品' },
    SUPPLIER: { value: '003', text: '仕入先' },
    OUTSOURCE: { value: '004', text: '外注先' },
    SHIPPING_ADDRESS: { value: '005', text: '配送先' },
    STOCK: { value: '006', text: '在庫' },
    PROJECT: { value: '100', text: '案件' },
    ESTIMATION: { value: '101', text: '見積書' },
    INSTRUCTION: { value: '102', text: '作業指示書' },
    INSTRUCTION_INTERNAL: { value: '102a', text: '作業指示書(社内)' },
    INSTRUCTION_OUTSOURCE: { value: '102b', text: '作業指示書(外注)' },
    PLACE_ORDER: { value: '103', text: '発注書' },
    SALE: { value: '104', text: '納品書' },
    INVOICE: { value: '108', text: '請求書' },
    RED_SLIP: { value: '109', text: '赤伝票' },
    OUTGOING: { value: '105', text: '仕入情報' },
    INCOMING: { value: '106', text: '入荷情報' },
    SHIPMENT: { value: '107', text: '入荷･出荷' },
    USER: { value: '200', text: 'ユーザー' },
    DEPARTMENT: { value: '201', text: '部門' },
    TEAM: { value: '202', text: 'チーム' },
    AUTHORITY: { value: '203', text: '権限' },
    COMPANY: { value: '204', text: '会社' },
})
