import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import { isEmpty } from 'helpers/string.helper'
import { addClassification, getClassificationsByType } from 'helpers/classification.helper'
import { AddIcon, SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import InsSelect from 'components/select/InsSelect'

const ClassificationSelect = ({ typeEnum, suffixIcon, showCreateButton = true, onChange, ...rest }) => {
    const { t } = useTranslation()
    const [keyword, setKeyword] = useState('')
    const [options, setOptions] = useState(getClassificationsByType(typeEnum.value))
    const [open, setOpen] = useState(false)

    const createClassification = async name => {
        if (isEmpty(name)) {
            return
        }
        const classification = await api.post(APP_URLS.CLASSIFICATIONS, {
            classification_type_code: typeEnum.value,
            classification_type_name: typeEnum.text,
            classification_item_name: name,
        })

        addClassification(classification)
        setOptions(getClassificationsByType(typeEnum.value))
        onChange && onChange(classification.classification_item_code)
        setOpen(false)
    }

    let dropdownSuffix = null
    if (showCreateButton) {
        dropdownSuffix = (
            <InsButton
                buttonStyle="default"
                className="color-pantone-2738-c w-100p"
                style={{ borderRadius: 8 }}
                buttonSize="big"
                icon={<AddIcon size={24} />}
                onClick={() => createClassification(keyword)}
            >
                {t('common:Create new')}
            </InsButton>
        )
    }

    return (
        <InsSelect
            {...rest}
            onChange={onChange}
            dropdownMatchSelectWidth={false}
            showSearch={true}
            filterOption={false}
            suffixIcon={suffixIcon && <SearchIcon size={16} />}
            onSearch={keyword => {
                setKeyword(keyword)
                let options = getClassificationsByType(typeEnum.value)
                if (isEmpty(keyword)) {
                    setOptions(options)
                }
                setOptions(options.filter(opt => opt.text.indexOf(keyword) > -1))
            }}
            dropdownSuffix={dropdownSuffix}
            open={open}
            onDropdownVisibleChange={open => setOpen(open)}
        >
            {options.map(option => (
                <Select.Option key={option.value} value={option.value}>
                    {option.text}
                </Select.Option>
            ))}
        </InsSelect>
    )
}

export default ClassificationSelect

ClassificationSelect.propTypes = {
    onChange: PropTypes.func,
    showCreateButton: PropTypes.bool,
    typeEnum: PropTypes.object.isRequired,
    suffixIcon: PropTypes.bool,
}

ClassificationSelect.defaultProps = {
    showCreateButton: true,
    suffixIcon: true,
}
