import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import InsInput from 'components/InsInput'
import { SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import { showError } from 'helpers/notification.helper'

const InsPostCodeInput = ({ value, onChange, onSearchSuccess }) => {
    const { t } = useTranslation()
    const searchPostCode = async value => {
        await api
            .get(APP_URLS.UTIL_POST_CODE, { value }, {}, true)
            .then(data => {
                if (data) {
                    onSearchSuccess(data)
                }
            })
            .catch(e => showError(api.message.error, 'エラーが発生しました。システム管理者に連絡してください。'))
    }
    return (
        <React.Fragment>
            <InsInput
                value={value}
                onChange={value => onChange(value)}
                placeholder="000-0000"
                suffix={<SearchIcon size={16} />}
                style={{ width: 140 }}
                className="mr-8"
            />
            <InsButton buttonStyle="default" style={{ width: 138 }} type="button" onClick={() => searchPostCode(value)}>
                {t('common:Search by Zip code')}
            </InsButton>
        </React.Fragment>
    )
}

export default InsPostCodeInput

InsPostCodeInput.propTypes = {
    onChange: PropTypes.func,
    onSearchSuccess: PropTypes.func,
    value: PropTypes.string,
}
