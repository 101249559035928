import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Input } from 'antd'
import { ClearInputIcon } from 'components/InsIcon'

const InsTextArea = ({ className, allowClear = true, value = null, defaultValue = '', onChange, ...rest }) => {
    const [inputValue, setInputValue] = useState(value === null ? defaultValue : value)
    let textAreaClassName = 'ins-text-area '
    if (className) {
        textAreaClassName += className
    }

    const clearHandler = () => {
        if (value === null) {
            setInputValue('')
        }
        onChange && onChange('')
    }

    const handleChange = e => {
        if (value === null) {
            setInputValue(e.target.value)
        }
        onChange && onChange(e.target.value)
    }

    return (
        <div className="ins-text-area-wrapper">
            <Input.TextArea
                {...rest}
                className={textAreaClassName}
                value={value === null ? inputValue : value}
                onChange={e => handleChange(e)}
                autoComplete={allowClear ? 'off' : 'new-password'}
            />
            {allowClear && (
                <div className="ins-text-area-clear">
                    <ClearInputIcon className="ins-input-clear-suffix" size={16} onClick={() => clearHandler()} />
                </div>
            )}
        </div>
    )
}

export default InsTextArea

InsTextArea.propTypes = {
    allowClear: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
}

InsTextArea.defaultProps = {
    allowClear: true,
    defaultValue: '',
    value: null,
}
