import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row } from 'antd'
import InsButton from 'components/InsButton'
import InsDragAndDrop from 'components/draggable/InsDragAndDrop'
import { AddIcon, ReportProblemIcon, TruckIcon, UnfoldLessIcon, UnfoldMoreIcon } from 'components/InsIcon'
import InsDragAndDropGroup from 'components/draggable/InsDragAndDropGroup'
import {
    addDestinations,
    moveDestination,
    moveDetail,
    removeDestination,
    removeDetail,
    setCollapse,
} from 'modules/business/placeOrder/place_order_detail.slice'
import PlaceOrderDestinationRow from 'modules/business/placeOrder/form/draggable/PlaceOrderDestinationRow'
import PlaceOrderDetailRowDelivery from 'modules/business/placeOrder/form/draggable/PlaceOrderDetailRowDelivery'
import PlaceOrderDetailRow from 'modules/business/placeOrder/form/draggable/PlaceOrderDetailRow'

const PlaceOrderDestinationDragAndDrop = React.memo(
    ({ destinations, destinationsOrder, moveDestination, removeDestination }) => {
        return (
            <div className="ins-drag-drop">
                <div className="ins-drag-drop-header">
                    <div className="ml-32" style={{ flex: '0 0 20px' }} />
                    <div style={{ flex: 254 }}>納入先</div>
                    <div style={{ flex: 440 }}>住所</div>
                    <div style={{ flex: 110 }}>直送先担当者</div>
                    <div style={{ flex: 60 }}>敬称</div>
                    <div style={{ flex: '0 0 24px' }} />
                </div>
                <div className="ins-draggable-body">
                    <InsDragAndDrop
                        data={destinations}
                        order={destinationsOrder}
                        onOrderChange={(srcIndex, desIndex) => moveDestination({ srcIndex, desIndex })}
                        onRemove={id => removeDestination({ id })}
                        rowComponent={PlaceOrderDestinationRow}
                    />
                </div>
            </div>
        )
    }
)

const PlaceOrderDetailDragAndDrop = React.memo(
    ({
        details,
        detailsOrder,
        destinations,
        destinationsOrder,
        moveDetail,
        removeDetail,
        placeOrderClassification,
        isPurchaseOrder,
        deliveryDestinationType,
        setCollapse,
    }) => {
        return (
            <div className="ins-drag-drop">
                <div className="ins-drag-drop-header">
                    <div style={{ flex: '0 0 20px' }} />
                    {placeOrderClassification === 1 ? (
                        <>
                            <div style={{ flex: 180 }}>タイトル</div>
                            <div style={{ flex: 174 }}>仕様</div>
                            <div style={{ flex: 116 }}>サイズ</div>
                        </>
                    ) : (
                        <>
                            <div style={{ flex: 180 }}>品番・品名</div>
                        </>
                    )}

                    <div className="text-center" style={{ flex: '0 0 74px' }}>
                        数量
                    </div>
                    <div className="text-center" style={{ flex: '0 0 48px' }}>
                        単位
                    </div>
                    <div className="text-center" style={{ flex: '0 0 90px' }}>
                        原価
                    </div>
                    <div className="text-center" style={{ flex: '0 0 84px' }}>
                        単価
                    </div>
                    <div className="text-center" style={{ flex: '0 0 66px' }}>
                        税区分
                    </div>
                    <div className="text-center" style={{ flex: '0 0 84px' }}>
                        金額
                    </div>
                    <div style={{ flex: '0 0 24px' }} />
                    <div style={{ flex: '0 0 24px' }} />
                </div>
                <div className="ins-draggable-body">
                    <InsDragAndDropGroup
                        data={details}
                        order={detailsOrder}
                        groupOrder={destinationsOrder}
                        onOrderChange={(srcIndex, desIndex, srcId, desId) =>
                            moveDetail({ srcIndex, desIndex, srcId, desId })
                        }
                        onRemove={(detailId, destinationId) => removeDetail({ destinationId, detailId })}
                        rowComponent={
                            placeOrderClassification === 1 ? PlaceOrderDetailRowDelivery : PlaceOrderDetailRow
                        }
                        headerRenderer={destinationId => {
                            const destination = destinations[destinationId]

                            if (!isPurchaseOrder && deliveryDestinationType === '1') {
                                if (destination.isUnknown) {
                                    if (destinationsOrder.length <= 1) {
                                        return (
                                            <div className="ins-drop-header">
                                                <ReportProblemIcon
                                                    size={20}
                                                    className="color-pantone-213-c ins-flex flex-middle ml-16 mr-8"
                                                    style={{ flex: '0 0 20px' }}
                                                />
                                                <div className="fw-bold">納入先： </div>
                                                <div>未指定</div>
                                            </div>
                                        )
                                    }
                                    return <></>
                                } else {
                                    return (
                                        <div className="destination-group">
                                            <div className="destination-group-header">
                                                <div className="destination-group-info">
                                                    <div className="ins-drop-group">
                                                        <div className="ins-drop-header">
                                                            <TruckIcon
                                                                size={20}
                                                                className="color-pantone-2422-c ins-flex flex-middle ml-16 mr-8"
                                                                style={{ flex: '0 0 20px' }}
                                                            />
                                                            <div className="fw-bold">納入先： </div>
                                                            {destination.delivery_destination_name && (
                                                                <div>{destination.delivery_destination_name}</div>
                                                            )}
                                                            {destination.delivery_destination_address && (
                                                                <div className="ml-24">
                                                                    {destination.delivery_destination_address}
                                                                </div>
                                                            )}
                                                            {destination.delivery_destination_pic_name && (
                                                                <div className="ml-24">
                                                                    {destination.delivery_destination_pic_name}
                                                                </div>
                                                            )}
                                                            {destination.delivery_destination_pic_title && (
                                                                <div className="ml-24">
                                                                    {destination.delivery_destination_pic_title}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="des-group-collapse"
                                                    onClick={() => setCollapse(destinationId)}
                                                >
                                                    {destination.isCollapse ? (
                                                        <UnfoldMoreIcon className="mdi_unfold_less" size={24} />
                                                    ) : (
                                                        <UnfoldLessIcon className="mdi_unfold_more" size={24} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                            return <></>
                        }}
                        collapse={destinationId =>
                            parseInt(deliveryDestinationType) === 1 && destinations[destinationId].isCollapse
                        }
                    />
                </div>
            </div>
        )
    }
)

const PlaceOrderDragAndDrop = ({
    destinations,
    destinationsOrder,
    details,
    detailsOrder,

    addDestinations,
    moveDestination,
    removeDestination,

    moveDetail,
    removeDetail,

    placeOrderClassification,
    directDeliveryType,
    isPurchaseOrder,
    deliveryDestinationType,
    setCollapse,
}) => {
    if (directDeliveryType !== 1) {
        for (var i = 1; i < destinationsOrder.length; i++) {
            let destinationOrder = destinationsOrder[i]
            if (destinationOrder !== 'destinationUnknown') {
                removeDestination({ id: destinationOrder })
            }
        }
    }
    return (
        <React.Fragment>
            {!isPurchaseOrder && deliveryDestinationType === '1' && (
                <>
                    <PlaceOrderDestinationDragAndDrop
                        destinations={destinations}
                        destinationsOrder={destinationsOrder}
                        moveDestination={moveDestination}
                        removeDestination={removeDestination}
                    />
                    {directDeliveryType === 1 && (
                        <Row className="bl-form-detail-control bl-form-detail-control-trans-bg" justify="space-between">
                            <InsButton
                                icon={<AddIcon className="color-pantone-2738-c" />}
                                buttonStyle="default"
                                onClick={() => addDestinations()}
                            >
                                納入先追加
                            </InsButton>
                        </Row>
                    )}
                </>
            )}

            <PlaceOrderDetailDragAndDrop
                destinations={destinations}
                destinationsOrder={destinationsOrder}
                details={details}
                detailsOrder={detailsOrder}
                moveDetail={moveDetail}
                removeDetail={removeDetail}
                placeOrderClassification={placeOrderClassification}
                isPurchaseOrder={isPurchaseOrder}
                deliveryDestinationType={deliveryDestinationType}
                setCollapse={setCollapse}
            />
        </React.Fragment>
    )
}

const mapState = state => ({
    destinations: state.placeOrderDetail.destinations,
    destinationsOrder: state.placeOrderDetail.destinationsOrder,
    details: state.placeOrderDetail.details,
    detailsOrder: state.placeOrderDetail.detailsOrder,
    placeOrderClassification: state.placeOrderDetail.placeOrderClassification,
    deliveryDestinationType: state.placeOrderDetail.deliveryDestinationType,
    directDeliveryType: state.placeOrderDetail.directDeliveryType,
})
const mapDispatch = {
    addDestinations,
    moveDestination,
    removeDestination,
    moveDetail,
    removeDetail,
    setCollapse,
}
export default connect(mapState, mapDispatch)(PlaceOrderDragAndDrop)

PlaceOrderDragAndDrop.propTypes = {
    addDestinations: PropTypes.func.isRequired,
    destinations: PropTypes.object.isRequired,
    destinationsOrder: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.object.isRequired,
    moveDestination: PropTypes.func.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDestination: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    directDeliveryType: PropTypes.number,
    isPurchaseOrder: PropTypes.bool,
    deliveryDestinationType: PropTypes.string,
    placeOrderClassification: PropTypes.number,
    setCollapse: PropTypes.func.isRequired,
}

PlaceOrderDestinationDragAndDrop.propTypes = {
    destinations: PropTypes.object.isRequired,
    destinationsOrder: PropTypes.array.isRequired,
    moveDestination: PropTypes.func.isRequired,
    removeDestination: PropTypes.func.isRequired,
}

PlaceOrderDetailDragAndDrop.propTypes = {
    destinations: PropTypes.object.isRequired,
    destinationsOrder: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.object.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    placeOrderClassification: PropTypes.number.isRequired,
    isPurchaseOrder: PropTypes.bool,
    deliveryDestinationType: PropTypes.string,
    setCollapse: PropTypes.func.isRequired,
}
