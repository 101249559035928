import api, { createQuery } from 'helpers/api.helper'
import { isEmpty } from 'helpers/string.helper'

export const baseInitialState = {
    loading: false,
    fetched: false,
    data: [],
    filters: {},
    keywords: [],
    detailSearch: {},
    sorter: {
        order: 'descend',
        field: 'id',
    },
    pagination: {
        current: 1,
        pageSize: 100,
        total: 1,
    },
    selection: [],
}

export const baseReducers = {
    setLoading(state, action) {
        state.loading = action.payload
    },
    setData(state, action) {
        state.data = action.payload
        state.fetched = true
    },
    setPagination(state, action) {
        state.pagination = action.payload
    },
    setFilters(state, action) {
        state.filters = action.payload
    },
    setKeywords(state, action) {
        state.keywords = action.payload
    },
    setDetailSearch(state, action) {
        state.detailSearch = action.payload
    },
    updateDetailSearch(state, action) {
        let tmp = Object.assign({}, state.detailSearch)
        if (isEmpty(action.payload.value)) {
            delete tmp[action.payload.field]
        } else {
            tmp[action.payload.field] = action.payload.value
        }
        state.detailSearch = tmp
    },
    setSorter(state, action) {
        state.sorter = action.payload
    },
    setSelection(state, action) {
        state.selection = action.payload
    },
}

export const baseGetData = async ({ url, pagination, filters, sorter }) => {
    if (sorter.order === undefined) {
        sorter = {
            order: 'descend',
            field: 'id',
        }
    }
    const data = await api.get(url, createQuery(pagination, filters, sorter), null, true)
    const { list: respData, page: current, page_size: pageSize, total } = data
    return { data: respData, pagination: { current, pageSize, total } }
}

export const baseExtraReducers = asyncThunk => ({
    [asyncThunk.pending]: state => {
        state.loading = true
    },
    [asyncThunk.fulfilled]: (state, action) => {
        state.data = action.payload.data
        state.pagination = action.payload.pagination
        state.loading = false
    },
    [asyncThunk.rejected]: state => {
        state.loading = false
    },
})
