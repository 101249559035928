import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Prompt, useLocation } from 'react-router-dom'
import InsButton from 'components/InsButton'
import InsModal from 'components/InsModal'
import history from 'helpers/history.helper'

const RouterPrompt = ({ handleSave, isValueChange, setDefaultState }) => {
    const [modalConfirm, setModalConfirm] = useState(false)
    const [lastLocation, setLastLocation] = useState()
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(isValueChange)
    const [navigated, setNavigated] = useState(false)
    const location = useLocation()

    const handleBlockedNavigation = nextLocation => {
        setLastLocation(nextLocation)
        setModalConfirm(true)
        return !isValueChange
    }

    const handleDoNotSave = () => {
        setModalConfirm(false)
        setShouldBlockNavigation(false)
        setNavigated(true)
        setDefaultState && setDefaultState()
    }

    useEffect(() => {
        setShouldBlockNavigation(isValueChange)
    }, [isValueChange])

    useEffect(() => {
        if (navigated) {
            history.push(lastLocation.pathname + location.search)
        }
    }, [navigated])

    return (
        <>
            <Prompt when={shouldBlockNavigation} message={location => handleBlockedNavigation(location)} />
            <InsModal
                title="確認"
                visible={modalConfirm}
                onCancel={() => setModalConfirm(false)}
                className="router-prompt"
                footer={[
                    <>
                        <InsButton
                            centered
                            key="do_not_save"
                            buttonStyle="outline"
                            onClick={() => setModalConfirm(false)}
                        >
                            キャンセル
                        </InsButton>
                        <InsButton
                            centered
                            key="save"
                            buttonStyle="primary"
                            onClick={() => {
                                // setShouldBlockNavigation(false)
                                // setNavigated(true)
                                // handleSave(lastLocation.pathname)

                                // save fail => ko cho redirect
                                // if (handleSave(lastLocation.pathname)) {
                                //     setShouldBlockNavigation(false)
                                //     setNavigated(true)
                                // } else {
                                //     setModalConfirm(false)
                                // }
                                handleDoNotSave()
                            }}
                        >
                            破棄する
                        </InsButton>
                    </>,
                ]}
            >
                <p>変更された内容があります。</p>
                <p>保存されていない変更を破棄しますか？</p>
            </InsModal>
        </>
    )
}

export default RouterPrompt

RouterPrompt.propTypes = {
    handleSave: PropTypes.func,
    isValueChange: PropTypes.bool,
    setDefaultState: PropTypes.func,
}
