import React from 'react'
import PropTypes from 'prop-types'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import RedSlipForm from 'modules/business/redSlip/form/RedSlipForm'

const RedSlipUpdate = ({ project, sale, redSlip, updateLink }) => {
    const updateRedSlip = async (values, redirectTo) => {
        const data = await api.put(APP_URLS.RED_SLIP_DETAIL.replace(':id', redSlip.id), values)
        if (data) {
            updateLink(redirectTo)
        }
    }

    return (
        <div className="p-24">
            <RedSlipForm
                isEdit={true}
                project={project}
                redSlip={redSlip}
                sale={sale}
                initialValues={redSlip}
                onSubmit={(values, redirectTo) => updateRedSlip(values, redirectTo)}
            />
        </div>
    )
}

export default RedSlipUpdate

RedSlipUpdate.propTypes = {
    project: PropTypes.object.isRequired,
    sale: PropTypes.object.isRequired,
    redSlip: PropTypes.object,
    updateLink: PropTypes.func,
}
