import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import history from 'helpers/history.helper'
import Routes from 'routes'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import { AddIcon, DeleteIcon } from 'components/InsIcon'
import PropTypes from 'prop-types'
import InsLoader from 'components/InsLoader'
import { setLoading } from 'modules/master/user/user.slice'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { Col, Row } from 'antd'

const CompanyListComponent = ({ inModal = false, onSelect, state, getData, setFilters, setSorter }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const ref = useRef()
    const [companyIds, setCompanyIds] = useState([]) //select a lot
    const [selectedCompany, setSelectedCompany] = useState() //select one
    const deleteCompany = async companyIds => {
        dispatch(setLoading(true))
        await api.delete(APP_URLS.COMPANIES, {
            ids: companyIds,
        })
        ref.current.resetSelectedRowKeys()
        getCompanies()
    }

    const companyColumns = [
        {
            title: t('column:Company code'),
            dataIndex: 'company_code',
            sorter: true,
        },
        { title: t('column:Company name'), dataIndex: 'company_name', sorter: true },
        {
            title: t('column:Company address'),
            dataIndex: 'address1',
            sorter: true,
            render: text => <pre>{text}</pre>,
        },
        { title: t('column:Note'), dataIndex: 'note', sorter: true },
        { title: t('column:Bank info'), dataIndex: 'bank_info', sorter: true, className: 'bank-info-pre-wrap' },
        {
            title: '',
            dataIndex: 'edit',
            className: 'white-space-nowrap',
            render: (text, record) => (
                <InsButton
                    buttonType="link"
                    buttonStyle="plain"
                    linkTo={Routes.private.master.COMPANY_UPDATE.path.replace(':id', record.id)}
                >
                    {t('common:Edit')}
                </InsButton>
            ),
        },
    ]

    const getCompanies = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        getCompanies()
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            {!inModal && (
                <>
                    <Row justify="space-between">
                        <Col>
                            <h1 className="page-title mb-32">{t('navigation:Company information')}</h1>
                        </Col>
                        <Col>
                            <InsButton
                                width={136}
                                buttonType="link"
                                linkTo={Routes.private.master.COMPANY_CREATE.path}
                                icon={<AddIcon size={24} />}
                                buttonStyle="secondary"
                            >
                                {t('navigation:Company create')}
                            </InsButton>
                        </Col>
                    </Row>
                </>
            )}

            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsDataTable
                        ref={r => (ref.current = r)}
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        // tableStyle="compact"
                        striped
                        bordered={true}
                        data={state.data}
                        columns={companyColumns}
                        onChange={(pagination, filter, sorter) => {
                            getCompanies(state.pagination, state.filters, sorter)
                        }}
                        hasSelection={true}
                        onSelectionChanged={keys => {
                            if (keys.length === 1) {
                                let company = state.data.find(element => element.id === keys[0])
                                setSelectedCompany(company)
                            }
                            setCompanyIds(keys)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                    />
                </Col>
            </Row>
            {!state.loading && (
                <InsButton
                    icon={<DeleteIcon className="color-pantone-213-c" size={20} isSubmitButton={true} />}
                    buttonStyle="default"
                    onClick={() => {
                        if (companyIds.length) {
                            deleteCompany(companyIds)
                        }
                    }}
                >
                    {t('Delete selected companies')}
                </InsButton>
            )}
        </React.Fragment>
    )
}

export default CompanyListComponent

CompanyListComponent.propTypes = {
    inModal: PropTypes.bool,
    onSelect: PropTypes.func,
    state: PropTypes.shape(BaseStatePropShape),
    getData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
}

CompanyListComponent.propTypes = {
    inModal: false,
}
