export const ActionLogType = Object.freeze({
    CREATE: { value: '1', text: '作成' },
    UPDATE: { value: '2', text: '編集' },
    DELETE: { value: '3', text: '削除' },
    CLONE: { value: '4', text: '複写' },
    CREATE_CLONE: { value: '5', text: '案件を作成して複写' },
    MOVE_TO_TRASH: { value: '6', text: 'ゴミ箱へ' },
    ISSUE: { value: '7', text: '発行' },
    REISSUE: { value: '8', text: '再発行' },
    ORDER_PROCESSING: { value: '9', text: '受注処理' },
    CANCEL_ORDER_PROCESSING: { value: '10', text: '受注処理取消' },
    ORDER_FAILED: { value: '11', text: '失注' },
    PROCESS_SHIPPING: { value: '12', text: '出荷処理' },
    CANCEL_PROCESS_SHIPPING: { value: '13', text: '出荷 取消' },
    PROCESS_SALE: { value: '14', text: '売上処理' },
    CANCEL_PROCESS_SALE: { value: '15', text: '売上処理 取消' },
    CONFIRM_PAYMENT: { value: '16', text: '入金確認' },
    CANCEL_PAYMENT_CONFIRMATION: { value: '17', text: '入金確認取消' },
    PAID: { value: '18', text: '支払済み' },
    CANCEL_PAID: { value: '19', text: '支払済み 取消' },
    PROCESS_SHIPMENT: { value: '20', text: '出荷処理' },
    LOCK: { value: '21', text: 'ロック' },
    UNLOCK: { value: '22', text: 'ロック解除' },
    PROJECT_PROCESS: { value: '23', text: '処理済み' },
})
