import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import InstructionPreviewComponent from 'modules/business/instruction/InstructionPreviewComponent'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import { useParams, useLocation } from 'react-router-dom'
import { InsBlockLoader } from 'components/InsLoader'
import { Checkbox, Col, Row } from 'antd'
import InsFloatBar from 'components/InsFloatBar'
import InsButton from 'components/InsButton'
import { EditIcon, DeleteIcon, FileCopyIcon } from 'components/InsIcon'
import ReactToPrint from 'react-to-print'
import CloneDocumentEnum from 'enums/clone_document.enum'
import ProjectCloneModal from 'modules/business/project/ProjectCloneModal'
import { useTranslation } from 'react-i18next'
import InstructionTypeEnum from 'enums/instruction_type.enum'
import BusinessStatusEnum from 'enums/business_status.enum'
import Routes from 'routes'
import history from 'helpers/history.helper'
import InsModal from 'components/InsModal'
import { can } from 'helpers/permission.helper'
import InstructionDOMPrint from 'modules/business/instruction/InstructionDOMPrint'

const InstructionPreview = ({ project, instructionType, updateLink, response, instruction }) => {
    const canEditInstruction = can('INSTRUCTION_EDITABLE') && !project.is_locked
    const { projectId } = useParams()
    const location = useLocation()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [modalCloneVisible, setModalCloneVisible] = useState(false)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
    const componentRef = useRef()

    const deleteInstruction = async () => {
        await api.delete(APP_URLS.INSTRUCTION_DETAIL.replace(':id', instruction.id))
        response()
        if (instructionType === InstructionTypeEnum.INTERNAL.value) project.instruction_internal = null
        if (instructionType === InstructionTypeEnum.OUTSOURCE.value) project.instruction_outsource = null
        history.push(
            Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) + '?from=' + location.search
        )
    }

    const [status, setStatus] = useState(instruction && instruction.status)
    const updateStatusInstruction = async status => {
        setStatus(status)
        await api.post(APP_URLS.INSTRUCTION_UPDATE_STATUS, {
            status: status,
            instruction_id: instruction.id,
        })
        response()
    }

    useEffect(() => {
        setLoading(false)
        setStatus(instruction && instruction.status)
    }, [projectId, instruction.instruction_classification])

    const [hideColumns, setHideColumns] = useState([])
    const renderCheckboxGroup = () => {
        const checkboxGroup = [
            {
                text: t('原価非表示'),
                name: 'unit_cost',
            },
            {
                text: t('単価非表示'),
                name: 'unit_price',
            },
        ]
        const handleChange = (column, checked) => {
            setHideColumns(checked ? [...hideColumns, column] : hideColumns.filter(hideColumn => hideColumn !== column))
        }
        return (
            <>
                {checkboxGroup.map((checkbox, index) => (
                    <Checkbox
                        key={index}
                        onChange={value => handleChange(checkbox.name, value.target.checked)}
                        defaultChecked={checkbox.init}
                    >
                        {checkbox.text}
                    </Checkbox>
                ))}
            </>
        )
    }

    return (
        <>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <>
                    <InstructionPreviewComponent
                        project={project}
                        instructionType={instructionType}
                        instruction={instruction}
                        hideColumns={[]}
                    />
                    <InstructionDOMPrint
                        project={project}
                        instruction={instruction}
                        componentRef={componentRef}
                        hideColumns={hideColumns}
                    />
                    {/*<div style={{ display: 'none' }}>*/}
                    {/*    <div className="prv-instruction p-48" ref={componentRef}>*/}
                    {/*        <InstructionPrintTemplate*/}
                    {/*            project={project}*/}
                    {/*            instructionType={instructionType}*/}
                    {/*            instruction={instruction}*/}
                    {/*            hasPagination={true}*/}
                    {/*            filterDetailType={detail => !isHeadline(detail)}*/}
                    {/*            splitByType={true}*/}
                    {/*            hideColumns={hideColumns}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {canEditInstruction && (
                        <>
                            <InsFloatBar visible={true} className="with-sidebar">
                                <Row justify="space-between" align="middle">
                                    <Col>
                                        <InsButton
                                            icon={<DeleteIcon size={20} className="color-pantone-213-c" />}
                                            buttonStyle="default"
                                            onClick={() => setModalDeleteVisible(true)}
                                            width="142px"
                                        >
                                            {t('Instruction internal delete document')}
                                        </InsButton>
                                        {modalDeleteVisible && (
                                            <InsModal
                                                className="ins-modal-w-550"
                                                title="削除"
                                                visible={modalDeleteVisible}
                                                onCancel={() => setModalDeleteVisible(false)}
                                                onOk={deleteInstruction}
                                                okText="はい"
                                                cancelText="いいえ"
                                            >
                                                <h3>{'作業指示書を削除してもよろしいですか？'}</h3>
                                                <p>
                                                    {
                                                        '※削除されたドキュメントは各ドキュメントの「削除」タブで確認できます。'
                                                    }
                                                </p>
                                            </InsModal>
                                        )}
                                        <ProjectCloneModal
                                            title={
                                                instructionType === InstructionTypeEnum.INTERNAL.value
                                                    ? CloneDocumentEnum.INSTRUCTION_INTERNAL.text
                                                    : CloneDocumentEnum.INSTRUCTION_OUTSOURCE.text
                                            }
                                            cloneDocument={
                                                instructionType === InstructionTypeEnum.INTERNAL.value
                                                    ? CloneDocumentEnum.INSTRUCTION_INTERNAL.value
                                                    : CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value
                                            }
                                            visible={modalCloneVisible}
                                            project={project}
                                            onCancel={() => {
                                                setModalCloneVisible(false)
                                            }}
                                        >
                                            <InsButton
                                                icon={<FileCopyIcon size={20} className="color-pantone-2738-c" />}
                                                buttonStyle="default"
                                                style={{ marginLeft: 8 }}
                                                onClick={() => {
                                                    setModalCloneVisible(true)
                                                }}
                                            >
                                                {t('Instruction internal clone document')}
                                            </InsButton>
                                        </ProjectCloneModal>
                                        <InsButton
                                            className="ml-8"
                                            icon={<EditIcon size={20} className="color-pantone-2738-c" />}
                                            buttonStyle="default"
                                            linkTo={updateLink}
                                            buttonType="link"
                                        >
                                            {t('Instruction internal edit document')}
                                        </InsButton>
                                    </Col>
                                    <Col className="ins-flex flex-middle btn-update-project-bottom">
                                        {renderCheckboxGroup()}
                                        <ReactToPrint
                                            trigger={() => (
                                                <InsButton
                                                    className="fw-bold"
                                                    buttonStyle={
                                                        status !== BusinessStatusEnum.WORK_REQUESTED.value
                                                            ? 'primary'
                                                            : 'outline'
                                                    }
                                                    buttonSize="big"
                                                    style={{ width: 140 }}
                                                    onClick={() => {}}
                                                >
                                                    {status !== BusinessStatusEnum.WORK_REQUESTED.value
                                                        ? t('作業指示書 発行')
                                                        : t('作業指示書 再発行')}
                                                </InsButton>
                                            )}
                                            content={() => componentRef.current}
                                            onBeforePrint={() => {
                                                status !== BusinessStatusEnum.WORK_REQUESTED.value &&
                                                    updateStatusInstruction(BusinessStatusEnum.WORK_REQUESTED.value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default InstructionPreview

InstructionPreview.propTypes = {
    project: PropTypes.object.isRequired,
    instructionType: PropTypes.number.isRequired,
    updateLink: PropTypes.string,
    response: PropTypes.func,
    instruction: PropTypes.object,
}
