import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import { InsBlockLoader } from 'components/InsLoader'
import UserForm from 'modules/master/user/form/UserForm'
import { setUser } from 'modules/common/auth/auth.slice'
import { connect } from 'react-redux'
import { checkAdmin, setPermissions } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'

const UserUpdate = ({ setUserState, currentUser, isAccountSetting }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [modalConfirmSave, setModalConfirmSave] = useState(false)
    const params = useParams()

    let id = null
    if (isAccountSetting) {
        id = currentUser.id
    } else {
        id = parseInt(params.id)
    }

    useEffect(() => {
        api.get(APP_URLS.USERS_DETAIL.replace(':id', id)).then(response => {
            if (response) {
                setUser(response)
                setLoading(false)
            }
        })
    }, [id])
    const updateUser = async (values, redirectTo) => {
        const data = await api.put(APP_URLS.USERS_DETAIL.replace(':id', id), values)
        if (data) {
            if (!redirectTo) {
                if (currentUser.id === id) {
                    setUserState({ ...currentUser, user_setting: data.user_setting, authorities: data.authorities })
                    setPermissions(data.authorities)
                }
                history.goBack()
            } else history.push(redirectTo)
        }
    }
    if (!isAccountSetting && !checkAdmin()) {
        return (
            <div className="page-container with-sidebar">
                <NoPermission />
            </div>
        )
    }
    return loading ? (
        <InsBlockLoader />
    ) : (
        <UserForm
            id={id}
            isEdit={true}
            initialValues={user}
            inModal={false}
            modalConfirmSave={modalConfirmSave}
            setModalConfirmSave={setModalConfirmSave}
            onSubmit={(values, redirectTo, confirmSave = true) => {
                if (confirmSave) setModalConfirmSave(true)
                else updateUser(values, redirectTo, confirmSave)
            }}
            isAccountSetting={isAccountSetting}
        />
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
})

const mapDispatch = {
    setUserState: setUser,
}

export default connect(mapState, mapDispatch)(UserUpdate)

UserUpdate.propTypes = {
    match: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    setUserState: PropTypes.func.isRequired,
    isAccountSetting: PropTypes.bool,
}
