import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Col, Form, Radio, Row, Select } from 'antd'
import Routes from 'routes'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { getClassificationsByType } from 'helpers/classification.helper'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import InsSelect from 'components/select/InsSelect'
import { AddIcon, CloseIcon, EditIcon } from 'components/InsIcon'
import InsInput from 'components/InsInput'
import InsHelpText from 'components/InsHelpText'
import InsPostCodeInput from 'components/InsPostCodeInput'
import UserSelect from 'modules/master/user/UserSelect'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import history from 'helpers/history.helper'
import InsModal from 'components/InsModal'

const OutsourceForm = ({
    isEdit,
    initialValues = {},
    onCancel,
    onSubmit,
    inModal = false,
    modalConfirmSave,
    setModalConfirmSave,
}) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const { id } = useParams()

    const convertToKana = async value => {
        const data = await api.get(APP_URLS.UTIL_CONVERT_KANA.replace(':value', value.target.value))
        if (data) {
            form.setFieldsValue({ outsource_name_kana: data })
        }
    }

    const processOnSubmit = (values, redirectTo) => {
        onSubmit(values, redirectTo)
    }

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => {
                        onSubmit(form.getFieldsValue(), null, false)
                        !isEdit && form.resetFields()
                    }}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className="ins-form pt-0"
                layout="vertical"
                form={form}
                size={'middle'}
                onFinish={values => processOnSubmit(values, null, false)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <div className="ins-form-items">
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="outsource_code"
                                label={t('column:Outsource code')}
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter outsource management code'),
                                    },
                                    {
                                        validator: async (rule, value) => {
                                            if (value) {
                                                if (isEdit && initialValues.outsource_code.trim() === value.trim())
                                                    return Promise.resolve()
                                                const data = await api.post(APP_URLS.OUTSOURCES_CHECK_EXISTS_CODE, {
                                                    outsource_code: value,
                                                })
                                                if (data && data.is_existed) {
                                                    return Promise.reject(t('validation:Outsource code existed'))
                                                }
                                            }
                                            return Promise.resolve()
                                        },
                                    },
                                ]}
                            >
                                <InsInput style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="outsource_name"
                                label={t('column:Outsource name')}
                                onBlur={convertToKana}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validation:Please enter {{item}}', {
                                            item: t('column:Outsource name'),
                                        }),
                                    },
                                ]}
                            >
                                <InsInput style={{ width: 280 }} maxLength={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="outsource_name_kana" label={t('column:Outsource name kana')}>
                                <InsInput style={{ width: 280 }} maxLength={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="outsource_short_name" label={t('column:Outsource short name')}>
                                <InsInput style={{ width: 140 }} maxLength={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="post_code"
                                label={t('column:Post code')}
                                extra={
                                    <InsHelpText>
                                        {t('Please enter half size number in this format 000-0000')}
                                    </InsHelpText>
                                }
                                rules={[
                                    {
                                        pattern: /^\d{3}-?\d{4}$/,
                                        message: t('validation:{{item}} is invalid', {
                                            item: t('column:Post code'),
                                        }),
                                    },
                                ]}
                            >
                                <InsPostCodeInput
                                    onSearchSuccess={data => {
                                        if (data.prefecture_code) {
                                            form.setFieldsValue({ prefecture_code: data.prefecture_code })
                                        }
                                        if (data.address) {
                                            form.setFieldsValue({ address1: data.address })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="prefecture_code" label={t('column:Address')} className="mb-4">
                                <InsSelect onSelect={value => console.log(value)} style={{ width: 140 }}>
                                    {getClassificationsByType(ClassificationTypeEnum.PREFECTURE.value).map(c => (
                                        <Select.Option key={c.value} value={c.value}>
                                            {c.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                            <Form.Item name="address1" className="mb-4">
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                            <Form.Item name="address2">
                                <InsInput style={{ width: 280 }} maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="phone_number"
                                label={t('column:Phone number')}
                                extra={<InsHelpText>{t('Please enter full size number')}</InsHelpText>}
                                rules={[
                                    {
                                        pattern: /^[0-9]?([-]?[\s]?[0-9])+$/,
                                        message: t('validation:Please enter half-width numbers'),
                                    },
                                ]}
                                validateTrigger={['onBlur']}
                            >
                                <InsInput placeholder="0000-0000-0000" style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="fax_number" label={t('column:Fax number')}>
                                <InsInput style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="pic_id" label={t('column:Pic name')}>
                                <UserSelect record={initialValues.pic} style={{ width: 280 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={inModal ? '' : 'mb-100'}>
                        <Col span={24}>
                            <Form.Item name="is_enabled" label={t('Usage status')}>
                                <Radio.Group>
                                    <Radio value={1}>{t(`common:Enabled`)}</Radio>
                                    <Radio value={0}>{t(`common:Disabled`)}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <InsFloatBar visible={true}>
                    <Row gutter={8} justify={inModal ? 'center' : 'end'}>
                        <Col>
                            <InsButton
                                buttonStyle="outline"
                                buttonSize="big"
                                centered
                                width="120px"
                                icon={<CloseIcon />}
                                onClick={() => {
                                    if (!inModal) {
                                        history.push(
                                            isEdit
                                                ? Routes.private.master.OUTSOURCE_DETAIL.path.replace(':id', id)
                                                : Routes.private.master.OUTSOURCE_LIST.path
                                        )
                                    } else {
                                        onCancel && onCancel()
                                    }
                                }}
                            >
                                {t('common:Cancel')}
                            </InsButton>
                        </Col>
                        {isEdit ? (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<EditIcon size={20} />}
                                    isSubmitButton={true}
                                >
                                    {isEdit
                                        ? t('navigation:{{entity}} update new', { entity: t('entity:Outsource') })
                                        : t('navigation:{{entity}} create new', { entity: t('entity:Outsource') })}
                                </InsButton>
                            </Col>
                        ) : (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<AddIcon />}
                                    isSubmitButton={true}
                                >
                                    {isEdit
                                        ? t('navigation:{{entity}} update new', { entity: t('entity:Outsource') })
                                        : t('navigation:{{entity}} create new', { entity: t('entity:Outsource') })}
                                </InsButton>
                            </Col>
                        )}
                    </Row>
                </InsFloatBar>
            </Form>
        </>
    )
}

export default OutsourceForm

OutsourceForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    inModal: PropTypes.bool,
}
