import React from 'react'
import InsButton from 'components/InsButton'
import { AddIcon } from 'components/InsIcon'
import InsDropdown from 'components/dropdown/InsDropdown'
import { addDetail } from 'modules/business/placeOrder/place_order_detail.slice'
import { connect } from 'react-redux'

const PlaceOrderDetailAddButton = ({ addDetail, placeOrderClassification }) => {
    let options = []
    if (placeOrderClassification === 3) {
        options = [{ key: 'product', text: '商品入力' }]
    } else {
        options = [
            { key: 'product', text: '商品入力' },
            { key: 'headline', text: '見出し' },
        ]
    }
    return (
        <InsDropdown
            onSelect={key => {
                let isHeadline = true
                if (key.key === 'product') {
                    isHeadline = false
                }
                addDetail({ isHeadline })
            }}
            options={options}
            trigger={['click']}
        >
            <InsButton icon={<AddIcon className="color-pantone-2738-c" />} buttonStyle="default">
                行追加
            </InsButton>
        </InsDropdown>
    )
}

const mapState = state => ({
    placeOrderClassification: state.placeOrderDetail.placeOrderClassification,
})

const mapDispatch = { addDetail }
export default connect(mapState, mapDispatch)(PlaceOrderDetailAddButton)
