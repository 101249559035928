import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Col, Row, Select } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsInput from 'components/InsInput'
import InsButton from 'components/InsButton'
import InsCollapse from 'components/InsCollapse'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import InsSelect from 'components/select/InsSelect'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import { APP_URLS } from 'constants/url.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { getClassificationsByType } from 'helpers/classification.helper'
import moment from 'moment'
import { setDetailSearch, setKeywords, updateDetailSearch, setFilters } from 'modules/business/project/project.slice'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import BusinessSelect from 'components/select/BusinessSelect'
import BusinessPageEnum from 'enums/business_page.enum'
import InsInputNumber from 'components/InsInputNumber'
import BusinessStatusEnum from 'enums/business_status.enum'

const ProjectListSearch = ({
    keywords,
    detailSearch,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    onSearch,
    onReset,
    filters,
    getProject,
    pagination,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const status = getClassificationsByType(ClassificationTypeEnum.BUSINESS_STATUS.value).filter(s => {
        return !(
            s.value === BusinessStatusEnum.TRACKING_CREATING.value.toString() ||
            s.value === BusinessStatusEnum.TRACKING_ISSUED.value.toString()
        )
    })
    const collapseRef = useRef()
    const createdAtChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            'created_at:gte': value[0] + ' 00:00:01',
            'created_at:lte': value[1] + ' 23:59:59',
        })
        dispatch(setFilters(newFilters))
        getProject(pagination, newFilters)
    }

    const statusChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            status: value,
        })
        dispatch(setFilters(newFilters))
        getProject(pagination, newFilters)
    }

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <div>
                    <BusinessSelect pageId={BusinessPageEnum.PROJECTS.value} />
                    <InsSelect
                        value={filters.status}
                        onSelect={value => statusChangeHandler(value)}
                        style={{ width: '170px', marginRight: '4px' }}
                    >
                        <Select.Option value={'0'}>全てのステータス</Select.Option>
                        <Select.Option value={'-1'}>ロックされた案件</Select.Option>
                        {status.map(s => (
                            <Select.Option key={s.value} value={s.value} title={s.text}>
                                {s.text}
                            </Select.Option>
                        ))}
                    </InsSelect>
                    <InsDateRangePicker
                        className="mr-4"
                        value={[moment(filters['created_at:gte']), moment(filters['created_at:lte'])]}
                        onChange={(mm, value) => createdAtChangeHandler(value)}
                    />

                    <InsInputSearch
                        className="free-text-search"
                        placeholder={t('Customer name, matter number, subject, etc')}
                        value={keywords}
                        onChange={values => setKeywords(values)}
                        onSearch={values => {
                            setDetailSearch({})
                            onSearch({ keyword: values })
                        }}
                    />
                </div>
            }
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'90px'}>
                    <strong>{t('column:Customer segmentation')}</strong>
                </Col>
                <Col>
                    <ClassificationSelect
                        typeEnum={ClassificationTypeEnum.CUSTOMER}
                        showCreateButton={false}
                        style={{ width: 140 }}
                        value={detailSearch['customer|customer_classification']}
                        onChange={value => updateDetailSearch({ field: 'customer|customer_classification', value })}
                    />
                </Col>
                <Col>
                    <strong>{t('column:Contact name')}</strong>
                </Col>
                <Col>
                    <InsRemoteSelect
                        url={`${APP_URLS.USERS}?page_size=100&is_enabled=1&pic_name:like=`}
                        optionIndexKey="id"
                        optionValueKey="id"
                        optionTextKey="pic_name"
                        name="pic_id"
                        style={{ width: '180px', marginRight: '37px' }}
                        onChange={value => updateDetailSearch({ field: 'pic_id', value })}
                        value={detailSearch['pic_id']}
                        autoSearch={true}
                    />
                </Col>
                <Col>
                    <strong>{t('column:Item memo')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '294px' }}
                        value={detailSearch['note:like']}
                        onChange={value => updateDetailSearch({ field: 'note:like', value })}
                        // useKeyUpTrigger={true}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'90px'}>
                    <strong>{t('column:Delivery date')}</strong>
                </Col>
                <Col>
                    <InsDateRangePicker
                        className="mr-8"
                        value={[
                            detailSearch['estimation|delivery_date:gte']
                                ? moment(detailSearch['estimation|delivery_date:gte'])
                                : '',
                            detailSearch['estimation|delivery_date:lte']
                                ? moment(detailSearch['estimation|delivery_date:lte'])
                                : '',
                        ]}
                        onChange={(mm, value) => {
                            updateDetailSearch({ field: 'estimation|delivery_date:gte', value: value[0] })
                            updateDetailSearch({ field: 'estimation|delivery_date:lte', value: value[1] })
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'90px'}>
                    <strong>{t('column:Billing amount')}</strong>
                </Col>
                <Col>
                    <InsInputNumber
                        className="no-spinner"
                        style={{ width: 124 }}
                        min={0}
                        onChange={value => updateDetailSearch({ field: 'sale|total:gte', value })}
                        value={detailSearch['sale|total:gte']}
                    />
                    <span style={{ margin: '0px 5px' }} className={'project_billing_between'}>
                        ~
                    </span>
                    <InsInputNumber
                        className="no-spinner"
                        style={{ width: 124 }}
                        min={0}
                        onChange={value => updateDetailSearch({ field: 'sale|total:lte', value })}
                        value={detailSearch['sale|total:lte']}
                    />
                </Col>
            </Row>
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    keywords: state.project.keywords,
    detailSearch: state.project.detailSearch,
    filters: state.project.filters,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(ProjectListSearch)

ProjectListSearch.propTypes = {
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    getActionLog: PropTypes.func,
}
