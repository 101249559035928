import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Form, Tabs } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import { CheckIcon } from 'components/InsIcon'
import InsModal from 'components/InsModal'
import InsFloatBar from 'components/InsFloatBar'
import { formatNumberComma } from 'helpers/number.helper'
import { setData, setLoading, setKeywords } from 'modules/business/payment/payment.slice'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import moment from 'moment'
import InsPagination from 'components/datatable/InsPagination'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import { Link } from 'react-router-dom'
import Routes from 'routes'
import InsTextArea from 'components/InsTextArea'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import { dateFormat } from 'helpers/date.helper'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import NavigationEnum from 'enums/navigation.enum'

const SCHEDULE = '1'
const COLLECT = '2'

const CollectDataTable = ({ state, updateFilters, setDefaultFilters }) => {
    const { t } = useTranslation()
    const [confirmMoneyReceived, setConfirmMoneyReceived] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedKeys, setSelectedKeys] = useState({})
    const [editNote, setEditNote] = useState({
        visible: false,
        note: '',
        project_ids: [],
    })

    const handleSelectedKeysChanged = (keys, rootKey) => {
        if (rootKey) {
            if (keys.length > 0) {
                setSelectedKeys({ ...selectedKeys, [rootKey]: keys })
            } else {
                let tmpSelectedKeys = Object.assign({}, selectedKeys)
                delete tmpSelectedKeys[rootKey]
                setSelectedKeys(tmpSelectedKeys)
            }
        } else {
            let currentRootKeys = Object.keys(selectedKeys)
            let diffAddKeys = keys.filter(key => !currentRootKeys.includes(key))
            let diffRemoveKeys = currentRootKeys.filter(key => !keys.includes(key))
            let tmpSelectedKeys = Object.assign({}, selectedKeys)
            diffRemoveKeys.map(k => {
                delete tmpSelectedKeys[k]
            })
            diffAddKeys.map(k => {
                tmpSelectedKeys[k] = state.data.find(d => d.id === k).projects.map(project => project.id)
            })
            setSelectedKeys(tmpSelectedKeys)
        }
    }
    const selectAll = () => {
        let tmpSelectedKeys = {}
        state.data.map(invoice => {
            tmpSelectedKeys[invoice.id] = invoice.projects.map(p => p.id)
        })
        setSelectedKeys(tmpSelectedKeys)
    }

    const calculateTotalAmountSelected = () => {
        let totalAmountSelected = 0
        state.data.map(invoice => {
            let tmpGroup = selectedKeys[invoice.id] || []
            invoice.projects.map(project => {
                if (tmpGroup.includes(project.id) && !project.red_slip) {
                    totalAmountSelected += project?.sale?.total ?? 0
                }
            })
        })
        return totalAmountSelected
    }

    const calculateTotalAmountExtracted = () => {
        return state.data.map(item => item.total_sales).reduce((prev, curr) => prev + curr, 0)
    }

    const columns = [
        {
            title: '請求書 ID',
            dataIndex: 'invoice_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    linkTo={
                        Routes.private.business.PROJECT_DETAIL.path.replace(
                            ':id',
                            record.project_id || record.projects[0]?.id
                        ) +
                        '/invoices?from=' +
                        NavigationEnum.PAYMENTS
                    }
                    buttonType="link"
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: '請求先名',
            dataIndex: 'customer_name',
            render: (text, r) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        setModalCustomerDetail({
                            customerId: r.customer.id,
                            visible: true,
                        })
                    }}
                >
                    {r.customer.bill_customer.customer_name}
                </InsButton>
            ),
            sorter: true,
        },
        {
            title: '回収種別',
            dataIndex: 'collection_type',
            // sorter: true,
            render: (text, record) => {
                if (
                    record.customer.collection_schedule2_amount > record.total_sales &&
                    record.customer.collection_schedule2_flag === 1
                ) {
                    return getClassificationItemName(
                        ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value,
                        record.customer.bill_customer.collection_schedule2_collection_type
                    )
                } else {
                    return getClassificationItemName(
                        ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value,
                        record.customer.bill_customer.collection_schedule1_collection_type
                    )
                }
            },
        },
        {
            title: '回収方法',
            dataIndex: 'collection_method',
            // sorter: true,
            render: (text, record) => {
                if (
                    record.customer.collection_schedule2_amount > record.total_sales &&
                    record.customer.collection_schedule2_flag === 1
                ) {
                    return getClassificationItemName(
                        ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value,
                        record.customer.bill_customer.collection_schedule2_collection_method
                    )
                } else {
                    return getClassificationItemName(
                        ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value,
                        record.customer.bill_customer.collection_schedule1_collection_method
                    )
                }
            },
        },
        {
            title: '請求日',
            dataIndex: 'invoices.issue_date',
            sorter: true,
            render: (text, r) => dateFormat(r.issue_date),
        },
        {
            title: '回収予定日',
            dataIndex: 'collection_schedule_date',
            sorter: true,
            render: (text, r) => {
                if (r.collection_schedule_date === '9999') return '設定なし'
                return dateFormat(r.collection_schedule_date)
            },
        },
        {
            title: '小計',
            dataIndex: 'total_sales',
            render: text => {
                return <span style={{ fontWeight: 'bold' }}>{formatNumberComma(text)}</span>
            },
            sorter: true,
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            // sorter: true,
            render: (text, record) => {
                let isDelayed = isLater(record.collection_schedule_date)
                if (isDelayed) {
                    return (
                        <span style={{ fontWeight: 'bold' }} className="text-row-pantone-213 ">
                            回収遅滞
                        </span>
                    )
                } else if (!state.filters['money_received_status']) {
                    return <span>回収予定</span>
                } else {
                    return <span>回収済み</span>
                }
            },
        },
        {
            title: 'メモ',
            dataIndex: 'note',
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            setEditNote({
                                visible: true,
                                note: getLastNote(r, false),
                                project_ids: getProjectIds(r.projects),
                            })
                        }}
                    >
                        {getLastNote(r, true)}
                    </InsButton>
                )
            },
        },
    ]

    const [modalCustomerDetail, setModalCustomerDetail] = useState({
        customerId: null,
        visible: false,
    })

    const renderDelayedCollectionDate = r => {
        let collectionScheduleDate
        let collectionSchedule1Month = r.customer.collection_schedule1_month
        let collectionSchedule1Day = r.customer.collection_schedule1_day
        let collectionSchedule2Month = r.customer.collection_schedule2_month
        let collectionSchedule2Day = r.customer.collection_schedule2_day
        if (r.customer.collection_schedule2_amount > r.total_sales && r.customer.collection_schedule2_flag === 1) {
            if (collectionSchedule2Month === 99) {
                return '設定なし'
            } else {
                let futureMonthEnd = moment(r.issue_date, 'YYYY-MM-DD')
                    .add(collectionSchedule2Month, 'M')
                    .endOf('month')
                if (collectionSchedule2Day > futureMonthEnd.daysInMonth()) {
                    collectionScheduleDate = futureMonthEnd
                } else {
                    collectionScheduleDate = moment(r.issue_date, 'YYYY-MM-DD').add(collectionSchedule2Month, 'M')
                    collectionScheduleDate = collectionScheduleDate.set('date', collectionSchedule2Day)
                }
            }
        } else {
            if (collectionSchedule1Month === 99) {
                return '設定なし'
            } else {
                let futureMonthEnd = moment(r.issue_date, 'YYYY-MM-DD')
                    .add(collectionSchedule1Month, 'M')
                    .endOf('month')
                if (collectionSchedule1Day > futureMonthEnd.daysInMonth()) {
                    collectionScheduleDate = futureMonthEnd
                } else {
                    collectionScheduleDate = moment(r.issue_date, 'YYYY-MM-DD').add(collectionSchedule1Month, 'M')
                    collectionScheduleDate = collectionScheduleDate.set('date', collectionSchedule1Day)
                }
            }
        }
        return dateFormat(collectionScheduleDate)
    }

    const isLater = str => {
        if (str === '9999') return true
        let currDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
        return new Date(str) < currDate
    }

    const updateMoneyReceived = async (isEditNote = false) => {
        let ids = Object.values(selectedKeys).flat(2)
        if (isEditNote) {
            ids = [editNote.project_ids]
        }
        if (ids.length > 0) {
            dispatch(setData([]))
            dispatch(setLoading(true))
            let data = {
                project_ids: ids,
            }
            if (isEditNote) {
                setEditNote({
                    visible: false,
                    note: '',
                })
                data['note'] = form.getFieldValue('edit_note')
            } else {
                setConfirmMoneyReceived(false)
                data['note'] = form.getFieldValue('note')
                data['status'] = !state.filters['money_received_status']
            }
            await api.post(APP_URLS.PAYMENTS_COLLECT, data).then(r => {
                updateFilters()
                setSelectedKeys({})
            })
        }
    }

    const getLastNote = (record, ellipsis) => {
        let projects = record.projects
        let project = projects.reduce((a, b) => {
            return new Date(a.money_received_date) > new Date(b.money_received_date) ? a : b
        })
        let note = project.money_received_note ?? ''
        if (note.length > 11 && ellipsis) {
            note = note.substring(0, 10) + '......'
        }
        return note
    }

    const getProjectIds = projects => {
        return projects.map(project => project.id)
    }

    return (
        <>
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalCustomerDetail.visible}
                onCancel={() =>
                    setModalCustomerDetail({
                        customerId: modalCustomerDetail.customerId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <CustomerDetailComponent inModal={true} id={modalCustomerDetail.customerId} />
            </InsModal>
            {confirmMoneyReceived && (
                <InsModal
                    width={'572px'}
                    title={t('Payment confirm')}
                    className="collect-confirm"
                    visible={confirmMoneyReceived}
                    onCancel={() => setConfirmMoneyReceived(false)}
                    onOk={() => updateMoneyReceived()}
                    okText={t('Confirm')}
                    okButtonStyle="primary"
                >
                    <Row justify="space-between" align="middle">
                        <span className="color-blue-gray-900">
                            選択した案件を<span className="color-pantone-213-c">入金済み処理</span>
                            します。よろしいですか？
                        </span>
                    </Row>
                    <Form className="bl-form" form={form}>
                        <Form.Item name="note" label={t('メモ')}>
                            <InsTextArea allowClear={false} placeholder={t('Enter note')} />
                        </Form.Item>
                    </Form>
                </InsModal>
            )}
            {editNote.visible && (
                <InsModal
                    width={'572px'}
                    title="メモ"
                    className="collect-confirm"
                    visible={editNote.visible}
                    onCancel={() =>
                        setEditNote({
                            visible: false,
                            note: '',
                        })
                    }
                    onOk={() => updateMoneyReceived(true)}
                    okText={t('Confirm')}
                    cancelText={t('Close')}
                    okButtonStyle="primary"
                >
                    <Form className="bl-form" form={form}>
                        <Form.Item name="edit_note">
                            <InsTextArea
                                defaultValue={editNote.note}
                                allowClear={false}
                                placeholder={t('Enter note')}
                                style={{ width: 492 }}
                            />
                        </Form.Item>
                    </Form>
                </InsModal>
            )}
            <div className="payment-datatable">
                <InsTab
                    type="card"
                    metaComponent={
                        <InsPagination
                            {...state.pagination}
                            onChange={pagination => {
                                updateFilters(pagination, state.filters, state.sorter)
                            }}
                        />
                    }
                    onChange={key => {
                        dispatch(setLoading(true))
                        dispatch(setData([]))
                        dispatch(setKeywords([]))
                        setSelectedKeys({})
                        if (key === SCHEDULE) {
                            setDefaultFilters(false)
                        } else if (key === COLLECT) {
                            setDefaultFilters(true)
                        }
                    }}
                    activeKey={!state.filters['money_received_status'] ? SCHEDULE : COLLECT}
                >
                    <Tabs.TabPane tab={t('回収予定')} key={SCHEDULE} />
                    <Tabs.TabPane tab={t('回収済み')} key={COLLECT} />
                </InsTab>
                <InsDataTable
                    data={state.data}
                    columns={columns}
                    rowClassName={(record, index) => {
                        let isDelayedCollection = isLater(record.collection_schedule_date)
                        if (isDelayedCollection) {
                            return 'table-row-pantone-213-opacity'
                        } else {
                            return ''
                        }
                    }}
                    loading={state.loading ? { indicator: <InsLoader /> } : false}
                    onChange={(pagination, filter, sorter) => {
                        let newSorter = Object.assign({}, sorter)
                        if (sorter.order === undefined)
                            newSorter = {
                                order: 'descend',
                                field: 'invoices.id',
                            }
                        updateFilters(pagination, filter, newSorter)
                    }}
                    hasSelection={true}
                    rowKey={record => record.id}
                    onSelectionChanged={keys => {
                        handleSelectedKeysChanged(keys)
                    }}
                    subSelectedKeys={Object.keys(selectedKeys).map(Number)}
                    expandable={{
                        expandedRowRender: record => {
                            const columns = [
                                {
                                    title: '案件番号',
                                    dataIndex: 'project_code',
                                    sorter: (a, b) => a.id - b.id,
                                    render: (text, r) => {
                                        return (
                                            <Link
                                                to={
                                                    Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id) +
                                                    '?from=' +
                                                    NavigationEnum.PAYMENTS
                                                }
                                            >
                                                <InsButton buttonStyle="plain" onClick={() => {}}>
                                                    {text}
                                                </InsButton>
                                            </Link>
                                        )
                                    },
                                },
                                {
                                    title: '顧客名',
                                    dataIndex: 'customer_name',
                                    sorter: (a, b) => a.id - b.id,
                                    render: (text, r) => {
                                        return <span>{record.customer.customer_name}</span>
                                    },
                                },
                                { title: '件名', dataIndex: 'project_name', sorter: true },
                                {
                                    title: '納品日',
                                    dataIndex: 'delivery_date',
                                    sorter: (a, b) => a.id - b.id,
                                    render: (text, r) => {
                                        return <span>{record.delivery_date}</span>
                                    },
                                },
                                {
                                    title: '担当者名',
                                    dataIndex: 'pic_name',
                                    sorter: (a, b) => a.id - b.id,
                                    render: (text, r) => r.pic.pic_name,
                                },
                                {
                                    title: '赤伝',
                                    render: (text, r) => {
                                        if (r.red_slip) {
                                            return <span className="color-pantone-213-c">赤伝</span>
                                        }
                                        return ''
                                    },
                                },
                                {
                                    title: '請求額',
                                    dataIndex: 'total',
                                    sorter: (a, b) => a.sale.total - b.sale.total,
                                    render: (text, r) => formatNumberComma(r?.sale?.total),
                                },
                            ]
                            return (
                                <div style={{ padding: 4 }}>
                                    <InsDataTable
                                        columns={columns}
                                        data={record.projects}
                                        rowKey={record => record.id}
                                        pagination={false}
                                        hasSelection={true}
                                        bordered={true}
                                        onChange={(pagination, filter, sorter) => {}}
                                        onSelectionChanged={keys => handleSelectedKeysChanged(keys, record.id)}
                                        subSelectedKeys={selectedKeys[record.id] || []}
                                    />
                                </div>
                            )
                        },
                        rowExpandable: r => r.name !== null,
                        expandIconColumnIndex: 10,
                    }}
                    sorter={state.sorter}
                    ctlSortOrder={true}
                />
            </div>
            <InsFloatBar visible={true}>
                <Row justify="space-between" align="middle">
                    <Col style={{ paddingLeft: 16 }}>
                        <Row className="mt-4">
                            <InsButton
                                style={{ width: 94 }}
                                icon={<CheckIcon size={16} />}
                                buttonStyle="default"
                                onClick={() => selectAll()}
                            >
                                {t('全て選択')}
                            </InsButton>
                        </Row>
                    </Col>
                    <Col style={{ paddingLeft: 16 }}>
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">抽出された金額合計</label>
                            <span className="project-status-label summary-invoice-label ">
                                {formatNumberComma(calculateTotalAmountExtracted())}
                            </span>
                        </span>
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">選択された金額合計</label>
                            <span className="project-status-label summary-invoice-label ">
                                {formatNumberComma(calculateTotalAmountSelected())}
                            </span>
                        </span>
                        <InsButton
                            className="pl-16 pr-16"
                            width={136}
                            centered
                            onClick={() => {
                                if (Object.values(selectedKeys).length > 0) {
                                    form.resetFields(['note'])
                                    setConfirmMoneyReceived(true)
                                }
                            }}
                            disabled2={!Object.keys(selectedKeys).length}
                            buttonStyle={!state.filters['money_received_status'] ? 'primary' : 'secondary'}
                            buttonSize="big"
                        >
                            {!state.filters['money_received_status'] ? '入金確認' : '入金確認 取消'}
                        </InsButton>
                    </Col>
                </Row>
            </InsFloatBar>
        </>
    )
}

const mapState = state => ({ state: state.payment })
export default connect(mapState)(CollectDataTable)

CollectDataTable.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    updateFilters: PropTypes.func,
    setDefaultFilters: PropTypes.func,
}
