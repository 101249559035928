import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import { splittingContent } from 'components/print/InsSplittingContent'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { dateToStr } from 'helpers/date.helper'
import { formatNumber, formatNumberComma } from 'helpers/number.helper'
import { DeliveryTimeEnum } from 'enums/delivery_time.enum'
import { TruckIcon } from 'components/InsIcon'
import { isHeadline } from 'helpers/detail.helper'

const RedSlipDOMPrint = ({ project, salePrint, redSlip, componentRef, showPreview = false, processPrint = true }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [sale, setSale] = useState(salePrint)
    const curCustomer = sale?.customer || {}

    let refs = {}
    let refIndex = 1
    let nextRef = {}

    const setRefs = (el, isTHead = false, withoutTHead = false, similarTHead = false) => {
        refs[refIndex] = {
            elem: el,
            isTHead: isTHead,
            withoutTHead: withoutTHead,
            similarTHead: similarTHead, // truck, or project row
        }
        refIndex++
    }

    const setNextRef = el => {
        nextRef = {
            elem: el,
        }
    }

    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            if (!sale) {
                const data = await api.get(APP_URLS.SALE_BY_PROJECT.replace(':projectId', project.id))
                if (data) {
                    setSale(data)
                }
            }
        }
        fetchData().then(() => setLoading(false))
    }, [project.id])

    // ref
    const renderHeadFirstPage = skipRef => {
        return <div ref={el => !skipRef && setRefs(el)}>{renderHeader()}</div>
    }

    // ref
    const renderTHead = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, true)} className="dom-row-thead">
                {parseInt(sale.sale_classification) !== 1 ? (
                    <>
                        <span style={{ flex: 220 }}>{t('品番・品名')}</span>
                    </>
                ) : (
                    <>
                        <span style={{ flex: 220 }}>{t('common:Title')}</span>
                        <span style={{ flex: 220 }}>{t('column:Spec')}</span>
                        <span style={{ flex: 144 }}>{t('column:Size')}</span>
                    </>
                )}
                <span style={{ flex: '0 0 92px' }}>{t('column:Quantity')}</span>
                <span style={{ flex: '0 0 48px' }}>{t('column:Unit')}</span>
                <span style={{ flex: '0 0 92px' }}>{t('column:Unit price')}</span>
                <span style={{ flex: '0 0 92px' }}>{t('column:Amount')}</span>
            </div>
        )
    }

    const renderSaleDetails = (details, skipRef) => {
        return (
            <>
                {details?.map((d, index) => {
                    if (isHeadline(d)) {
                        return (
                            <div key={index} ref={el => !skipRef && setRefs(el)} className="dom-row-detail">
                                <span style={{ flex: 1 }}>{d.headline}</span>
                                <span className="bg-color" style={{ flex: '0 0 92px' }}>
                                    &nbsp;
                                </span>
                                <span className="bg-color" style={{ flex: '0 0 48px' }}>
                                    &nbsp;
                                </span>
                                <span className="bg-color" style={{ flex: '0 0 92px' }}>
                                    &nbsp;
                                </span>
                                <span className="bg-color" style={{ flex: '0 0 92px' }}>
                                    &nbsp;
                                </span>
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} ref={el => !skipRef && setRefs(el)} className="dom-row-detail">
                                {parseInt(sale.sale_classification) !== 1 ? (
                                    <span style={{ flex: 1 }}>{d.title}</span>
                                ) : (
                                    <>
                                        <span style={{ flex: 220 }}>{d.title}</span>
                                        <span style={{ flex: 220 }}>{d.spec}</span>
                                        <span style={{ flex: 144 }}>{d.size}</span>
                                    </>
                                )}

                                <span style={{ flex: '0 0 92px' }}>{formatNumber(d.quantity)}</span>
                                <span style={{ flex: '0 0 48px' }}>
                                    {getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, d.unit)}
                                </span>
                                <span style={{ flex: '0 0 92px' }}>{formatNumber(d.unit_price)}</span>
                                <span style={{ flex: '0 0 92px' }}>{formatNumberComma(d.amount)}</span>
                            </div>
                        )
                    }
                })}
            </>
        )
    }

    const renderSaleDeliveryDestinations = (deliveryDestinations, skipRef) => {
        return (
            <>
                {(deliveryDestinations || []).map(des => {
                    return (
                        <>
                            <div
                                ref={el => !skipRef && setRefs(el, false, false, true)}
                                className="i-table-detail-shipping pt-28 mb-0 pb-8"
                            >
                                <div className="i-table-shipping-type">
                                    <span className="p-truck mr-8">
                                        <TruckIcon size={24} />
                                    </span>
                                    <span className="p-shipping-text">{`${t('column:Delivery destination')} :`}</span>
                                </div>
                                <div className="i-table-shipping-detail">
                                    <div className="i-table-shipping-detail-line1">
                                        <span className="p-bold mr-16">{des.delivery_destination_name}</span>
                                        <span className="p-bold mr-16">{des.delivery_destination_address}</span>
                                        <span className="p-bold mr-16">{des.delivery_destination_pic_name}</span>
                                        <span className="p-bold mr-16">{des.delivery_destination_pic_title}</span>
                                    </div>
                                </div>
                            </div>
                            {renderTHead(skipRef)}
                            {renderSaleDetails(des.details, skipRef)}
                        </>
                    )
                })}
            </>
        )
    }

    // ref
    const renderTableDetail = skipRef => {
        return (
            <>
                {sale.delivery_destinations.length > 0
                    ? renderSaleDeliveryDestinations(sale.delivery_destinations, skipRef)
                    : renderSaleDetails(sale.details, skipRef)}
            </>
        )
    }

    // ref
    const renderTotal = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, false, true)} className="dom-total-price">
                <span className="td bg-color " style={{ flex: 146 }}>
                    小計
                </span>
                <span className="td bold" style={{ flex: 146 }}>
                    {'△'}
                    {formatNumberComma(sale.subtotal)}
                </span>
                <span className="td bg-color" style={{ flex: 146 }}>
                    消費税
                </span>
                <span className="td bold" style={{ flex: 146 }}>
                    {'△'}
                    {formatNumberComma(sale.consumption_tax)}
                </span>
                <span className="td bg-color" style={{ flex: '0 0 140px' }}>
                    合計
                </span>
                <span className="td total total-value" style={{ flex: '0 0 184px' }}>
                    {'△'}
                    {formatNumberComma(sale.total)}
                </span>
            </div>
        )
    }

    // ref
    const renderNote = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, false, true)} className="c-preview-note">
                <label>{'備考'}</label>
                <div className="fake-area">{sale.note}</div>
            </div>
        )
    }

    // ref: repeatable
    const renderHeadNextPage = () => {
        return <div ref={el => setNextRef(el)}>{renderHeader(99)}</div>
    }

    const getDeliveryTimeName = key => {
        const deliveriesTime = Object.values(DeliveryTimeEnum)
        let deliveryTime = deliveriesTime.find(element => element.value === key)
        return deliveryTime?.text ?? ''
    }

    const renderHiddenLeftContent = () => {
        return (
            <>
                <div className="obj-person">
                    {sale.customer_pic_name}
                    <span className="obj-person-extra">
                        {getClassificationItemName(
                            ClassificationTypeEnum.CUSTOMER_TITLE.value,
                            sale.customer_pic_title
                        )}
                    </span>
                </div>
                <div className="as-hr" />
                <div className="about-info">
                    <div className="about-info-phone-fax">
                        {curCustomer?.phone_number && (
                            <span className="about-info-tel">TEL： {curCustomer.phone_number}</span>
                        )}
                        {curCustomer?.fax_number && (
                            <span className="about-info-fax">FAX： {curCustomer.fax_number}</span>
                        )}
                    </div>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>
                                {t('column:Sale delivery date')}
                                <span>:</span>
                            </th>
                            <td>
                                {dateToStr(redSlip.red_slip_issued_date)} &nbsp;&nbsp;&nbsp;
                                {getDeliveryTimeName(sale.delivery_time)}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="total-amount">
                    <span>{'合計'}</span>
                    <span className="amount-number">
                        <span>{'￥'}</span>
                        {formatNumberComma(sale.total)}
                    </span>
                </div>
            </>
        )
    }

    const renderHiddenRightContent = () => {
        return (
            <>
                <div className="obj-name">{sale.sender_name}</div>
                <div className="about-info">{sale.sender_address}</div>
                <div className="person">
                    {sale.pic && (
                        <>
                            <label>{'担当 :'}</label>
                            <span>{sale.pic && sale.pic.pic_name}</span>
                        </>
                    )}
                </div>
            </>
        )
    }

    const renderHeader = (pageNum = 1) => {
        let pageNumString = pageNum < 10 ? '0' + pageNum : pageNum

        return (
            <div className="c-preview-header c-preview">
                <Row justify="space-between" align="bottom">
                    <Col span={8} offset={8} className={pageNum > 1 && 'hidden-title'}>
                        <strong className="c-preview-title">{'訂正伝票'}</strong>
                    </Col>
                    <Col span={8} className={`text-right page-number ${pageNum > 1 && 'page-number-continue'}`}>
                        Page - <span>{pageNumString}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="c-preview-header-left">
                        <div className="children-left">
                            <div className="obj-name">{sale?.customer?.customer_name ?? ''}</div>
                            {pageNum === 1 && renderHiddenLeftContent(sale)}
                            <div className="as-hr" />
                            {sale.sale_name ? (
                                <div className="person">
                                    <label>{'件名 :'}</label>
                                    <span>{sale.sale_name}</span>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </Col>
                    <Col className="c-preview-header-right">
                        <div className="children-right">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            {t('column:Issued date')}
                                            <span>:</span>
                                        </th>
                                        <td>{dateToStr(redSlip.red_slip_issued_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            {t('column:Project code')}
                                            <span>:</span>
                                        </th>
                                        <td>{project.project_code}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            {t('column:RedSlip management code')}
                                            <span>:</span>
                                        </th>
                                        <td>{redSlip.red_slip_code}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {pageNum === 1 && renderHiddenRightContent(sale)}
                            {pageNum > 1 && <div className="pb-24" />}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const renderPreview = () => {
        return (
            <>
                <div className="dom-preview-visibility">
                    <div className="DOM-single-page dom-page-style dom-mod-style-header">
                        {renderHeadFirstPage()}
                        {renderTableDetail()}
                        {renderTotal()}
                        {renderNote()}
                        {renderHeadNextPage()}
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        setSplitContents(splittingContent(refs, nextRef))
    }, [refs, nextRef])

    const renderPages = () => {
        return (
            <div style={{ display: 'none' }}>
                <div
                    ref={componentRef}
                    className="DOM-multi-pages dom-page-style dom-mod-style-header"
                    dangerouslySetInnerHTML={{ __html: splitContents }}
                />
            </div>
        )
    }

    const [splitContents, setSplitContents] = useState('')
    // render
    const render = () => (
        <>
            {processPrint && renderPreview()}
            {processPrint && renderPages()}
            {showPreview && renderUI()}
        </>
    )

    // effect re-render preview
    useEffect(() => {
        showPreview && setSale(salePrint)
    }, [salePrint])

    // render - show Preview
    const renderUI = () => (
        <>
            <div className="dom-preview-show dom-mod-style-header dom-mod-style-header">
                {renderHeadFirstPage(true)}
                {renderTableDetail(true)}
                {renderTotal(true)}
                {renderNote(true)}
            </div>
        </>
    )

    return loading ? <InsBlockLoader /> : render()
}

export default RedSlipDOMPrint

RedSlipDOMPrint.propTypes = {
    project: PropTypes.object.isRequired,
    salePrint: PropTypes.object,
    redSlip: PropTypes.object,
    componentRef: PropTypes.any,
    showPreview: PropTypes.bool,
    processPrint: PropTypes.bool,
}
