import React from 'react'
import PropTypes from 'prop-types'
import ProjectGroup from 'modules/business/invoice/invoiceProject/ProjectGroup'

const InvoiceProjectComponent = React.memo(({ sale, otherSales }) => {
    return (
        <div className="ins-drag-drop">
            <div className="ins-drag-drop-header">
                <div style={{ flex: '0 0 20px' }} />
                <div style={{ flex: 184 }}>タイトル</div>
                <div style={{ flex: 182 }}>仕様</div>
                <div style={{ flex: 134 }}>サイズ</div>
                <div style={{ flex: '0 0 55px' }}>数量</div>
                <div style={{ flex: '0 0 63px' }}>単位</div>
                <div style={{ flex: '0 0 81px' }}>原価</div>
                <div style={{ flex: '0 0 61px' }}>単価</div>
                <div style={{ flex: '0 0 77px' }}>税区分</div>
                <div style={{ flex: '0 0 65px' }}>金額</div>
                <div style={{ flex: '0 0 24px' }} />
            </div>
            <div className="ins-draggable-body">
                {sale && sale.project && <ProjectGroup sale={sale} project={sale.project} project_paid={true} />}
                {otherSales.map(oSale => (
                    <ProjectGroup sale={oSale} project={oSale.project} key={oSale.id} />
                ))}
            </div>
        </div>
    )
})

const InvoiceProject = ({ sale, otherSales = [] }) => {
    return (
        <>
            <InvoiceProjectComponent sale={sale} otherSales={otherSales} />
        </>
    )
}

export default InvoiceProject

InvoiceProject.propTypes = {
    sale: PropTypes.object.isRequired,
    otherSales: PropTypes.array.isRequired,
}

InvoiceProjectComponent.propTypes = {
    sale: PropTypes.object.isRequired,
    otherSales: PropTypes.array.isRequired,
}
