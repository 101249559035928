import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { Col, Row, Select, Checkbox } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { ReportProblemIcon, SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import { useTranslation } from 'react-i18next'
import InsSelect from 'components/select/InsSelect'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import { setFilters } from 'modules/business/report/report.slice'

const ReportListSearch = ({
    onSearch,
    keywordsFilter,
    setKeywordsFilter,
    filters,
    getReports,
    isIncludingYearOnYearChange,
    setIsIncludingYearOnYearChange,
    groupItem1,
    groupItem2,
    metricType,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const dateFormatStart = 'YYYY/MM/01'
    const dateFormatEnd = 'YYYY/MM/01'
    const defaultFilters = {
        start_time: moment().subtract(2, 'months').format(dateFormatStart),
        end_time: moment().format(dateFormatEnd),
        group_item_1_type: 'area',
        group_item_1_limit: 10,
        group_item_2_type: 'person_in_charge',
        group_item_2_limit: 5,
        metric_type: 'sales_amount',
        metric_period: 1,
    }

    const { path } = useRouteMatch()
    useEffect(() => {
        dispatch(setFilters(defaultFilters))
        getReports(defaultFilters)
    }, [path])

    const [curGroupItem1, setCurGroupItem1] = useState('area')
    const [curGroupItem2, setCurGroupItem2] = useState('person_in_charge')

    const groupItemLimit = [
        {
            label: '5件',
            name: 5,
        },
        {
            label: '10件',
            name: 10,
        },
        {
            label: '20件',
            name: 20,
        },
        {
            label: '30件',
            name: 30,
        },
        {
            label: 'すべて',
            name: 0,
        },
    ]

    const getPairList = name => {
        return groupItem1.find(g => g.name === name).pair_list
    }
    const curPairList = getPairList(curGroupItem1)

    const handleGroupItem1Change = value => {
        const tmpPairList = getPairList(value)
        setCurGroupItem1(value)
        if (tmpPairList.indexOf(curGroupItem2) < 0) {
            setCurGroupItem2(tmpPairList[0])
        }
        // add to filter
        const newFilters = Object.assign({}, filters, {
            group_item_1_type: value,
            group_item_2_type: tmpPairList.indexOf(curGroupItem2) < 0 ? tmpPairList[0] : filters.group_item_2_type,
        })
        dispatch(setFilters(newFilters))
    }

    const handleGroupItem1LimitChange = value => {
        const newFilters = Object.assign({}, filters, {
            group_item_1_limit: value,
        })
        dispatch(setFilters(newFilters))
    }

    const handleGroupItem2Change = value => {
        setCurGroupItem2(value)
        // add to filter
        const newFilters = Object.assign({}, filters, {
            group_item_2_type: value,
        })
        dispatch(setFilters(newFilters))
    }

    const handleGroupItem2LimitChange = value => {
        const newFilters = Object.assign({}, filters, {
            group_item_2_limit: value,
        })
        dispatch(setFilters(newFilters))
    }

    const handleMetricTypeChange = value => {
        const newFilters = Object.assign({}, filters, {
            metric_type: value,
        })
        dispatch(setFilters(newFilters))
    }

    const handleMetricPeriodChange = value => {
        const newFilters = Object.assign({}, filters, {
            metric_period: value,
            // // fresh
            // group_1_sorter: { field: 'group_1_text', order: undefined },
            // group_2_sorter: { field: 'group_2_text', order: undefined },
        })
        dispatch(setFilters(newFilters))
    }

    const handleStartEndChange = (mm, value) => {
        const newFilters = Object.assign({}, filters, {
            start_time: value[0] + '/01',
            end_time: value[1] + '/01',
            // // fresh
            // group_1_sorter: { field: 'group_1_text', order: undefined },
            // group_2_sorter: { field: 'group_2_text', order: undefined },
        })
        dispatch(setFilters(newFilters))
    }

    const handleIncludingYearOnYearChange = value => {
        const newFilters = Object.assign({}, filters, {
            including_year_on_year: value ? 1 : 0,
        })
        dispatch(setFilters(newFilters))
        setIsIncludingYearOnYearChange(value)
        getReports(newFilters)
    }

    const handleInputSearch = values => {
        // const newFilters = Object.assign({}, filters, {
        //     keyword: values,
        // })
        // dispatch(setFilters(newFilters))
        // getReports(newFilters)
        onSearch(values)
    }

    return (
        <>
            <Row className="report-select-search">
                <Col>
                    <span className="label">{'区分１'}</span>
                    <InsSelect
                        value={curGroupItem1}
                        onChange={value => handleGroupItem1Change(value)}
                        className="mr-4"
                        style={{ width: 86, minWidth: 115 }}
                    >
                        {groupItem1.map(g => (
                            <Select.Option key={g.name} value={g.name}>
                                {g.label}
                            </Select.Option>
                        ))}
                    </InsSelect>
                    <InsSelect
                        defaultValue={10}
                        value={filters.group_item_1_limit}
                        onChange={value => handleGroupItem1LimitChange(value)}
                        className="mr-4"
                        style={{ minWidth: 68 }}
                        dropdownInnerStyle={{ minWidth: 50 }}
                    >
                        {groupItemLimit.map(g => (
                            <Select.Option key={g.name} value={g.name}>
                                {g.label}
                            </Select.Option>
                        ))}
                    </InsSelect>
                </Col>
                <Col>
                    <span className="label">{'区分２'}</span>
                    <InsSelect
                        value={curGroupItem2}
                        onChange={value => handleGroupItem2Change(value)}
                        className="mr-4"
                        style={{ width: 86, minWidth: 105 }}
                    >
                        {groupItem2
                            .filter(g => curPairList.indexOf(g.name) > -1)
                            .map(g => (
                                <Select.Option key={g.name} value={g.name}>
                                    {g.label}
                                </Select.Option>
                            ))}
                    </InsSelect>
                    <InsSelect
                        defaultValue={5}
                        value={filters.group_item_2_limit}
                        onChange={value => handleGroupItem2LimitChange(value)}
                        className="mr-4"
                        style={{ minWidth: 68 }}
                        dropdownInnerStyle={{ minWidth: 50 }}
                    >
                        {groupItemLimit.map(g => (
                            <Select.Option key={g.name} value={g.name}>
                                {g.label}
                            </Select.Option>
                        ))}
                    </InsSelect>
                </Col>
                <Col>
                    <span className="label">{'測定基準'}</span>
                    <InsSelect
                        defaultValue="sales_amount"
                        value={filters.metric_type}
                        onChange={value => handleMetricTypeChange(value)}
                        className="mr-4"
                        style={{ minWidth: 90 }}
                    >
                        {metricType.map(m => (
                            <Select.Option key={m.name} value={m.name}>
                                {m.label}
                            </Select.Option>
                        ))}
                    </InsSelect>
                    <InsSelect
                        defaultValue={1}
                        value={filters.metric_period}
                        className="mr-4"
                        onChange={value => handleMetricPeriodChange(value)}
                        style={{ width: 68 }}
                    >
                        <Select.Option value={1}>{'月別'}</Select.Option>
                        <Select.Option value={2}>{'年別'}</Select.Option>
                    </InsSelect>
                </Col>
                <Col>
                    <span className="label">{'期間'}</span>
                    <InsDateRangePicker
                        picker="month"
                        className="mr-4 range-picker-month-select"
                        dateFormat="YYYY/MM"
                        value={[moment(filters['start_time'], 'YYYY/MM/01'), moment(filters['end_time'], 'YYYY/MM/01')]}
                        defaultValue={[
                            moment(filters['start_time'], 'YYYY/MM/01'),
                            moment(filters['end_time'], 'YYYY/MM/01'),
                        ]}
                        onChange={(mm, value) => handleStartEndChange(mm, value)}
                    />
                </Col>
                <InsButton
                    width={80}
                    buttonStyle="default"
                    icon={<SearchIcon className="color-pantone-2738-c" size={16} />}
                    onClick={() => getReports(filters)}
                >
                    {t('common:Search')}
                </InsButton>
            </Row>
            <Row className="report-input-search" justify="space-between" align="middle">
                <Col>
                    {filters.group_item_1_limit === 0 && (
                        <>
                            <ReportProblemIcon size={20} className="color-pantone-213-c mr-8" />
                            <span>{'件数が多いと読み込みに時間がかかる場合があります'}。</span>
                        </>
                    )}
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox className="mr-20" onChange={evt => handleIncludingYearOnYearChange(evt.target.checked)}>
                        {'前年月比を含む'}
                    </Checkbox>
                    <span className="label">{'絞り込み'}</span>
                    <InsInputSearch
                        style={{ width: 258 }}
                        placeholder={t('区分に設定した内容で絞り込み')}
                        value={keywordsFilter}
                        onChange={values => setKeywordsFilter(values)}
                        onSearch={values => handleInputSearch(values)}
                    />
                </Col>
            </Row>
        </>
    )
}

const mapState = state => ({
    filters: state.report.filters,
})
const mapDispatch = { setFilters }

export default connect(mapState, mapDispatch)(ReportListSearch)

ReportListSearch.propTypes = {
    onSearch: PropTypes.func,
    keywordsFilter: PropTypes.array,
    setKeywordsFilter: PropTypes.func,
    filters: PropTypes.object.isRequired,
    getReports: PropTypes.func.isRequired,
    isIncludingYearOnYearChange: PropTypes.bool,
    setIsIncludingYearOnYearChange: PropTypes.func,
    groupItem1: PropTypes.array,
    groupItem2: PropTypes.array,
    metricType: PropTypes.array,
}
