import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import InsSelect from 'components/select/InsSelect'
import InsModal from 'components/InsModal'
import api from 'helpers/api.helper'
import { AddIcon, SearchIcon, UnfoldMoreIcon } from 'components/InsIcon'
import { Form, Select } from 'antd'
import InsButton from 'components/InsButton'
import { useTranslation } from 'react-i18next'
import { getEnumText } from 'helpers/enum.helper'
import ShipperTypeEnum from 'enums/shipper_type.enum'

const InsRemoteSelect = ({
    url,
    listModal,
    createModal,
    detailModal,
    initialOptions = [],
    updatedOptions = [],
    optionIndexKey = 'id',
    optionValueKey = 'id',
    optionTextKey = 'text',
    optionTypeKey = 'text',
    optionRenderer,
    initialValue,
    value,
    onChange,
    suffixIcon,
    autoSearch,
    createText,
    ...rest
}) => {
    // Remove dispatch prop injected by redux to avoid warning
    delete rest['dispatch']
    const { t } = useTranslation()
    const [listModalVisible, setListModalVisible] = useState(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [detailModalVisible, setDetailModalVisible] = useState(false)

    // cache last selected record
    const [selectedRecord, setSelectedRecord] = useState({
        [optionValueKey]: initialOptions.length ? initialOptions[0][optionValueKey] : null,
        [optionTextKey]: initialOptions.length ? initialOptions[0][optionTextKey] : null,
        [optionTypeKey]: initialOptions.length ? initialOptions[0][optionTypeKey] : null,
    })
    const storeSelectedRecord = (value, option) => {
        setSelectedRecord({
            [optionValueKey]: value,
            [optionTextKey]: option['item'] ? option['item'][optionTextKey] : option[optionTextKey],
            [optionTypeKey]: option['item'] ? option['item'][optionTypeKey] : option[optionTypeKey],
        })
    }
    initialOptions.pop()

    const [options, setOptions] = useState(initialOptions)
    const [selectValue, setSelectValue] = useState(initialValue)
    const [form] = Form.useForm()

    useEffect(() => {
        if (updatedOptions.length > 0) setOptions(updatedOptions)
    }, [updatedOptions])

    useEffect(() => {
        setSelectValue(value)
    }, [value])

    let timeout = null
    const searchHandler = keyword => {
        if (!autoSearch && (!keyword || keyword.length < 1)) return
        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        const search = async () => {
            try {
                const response = await api.get(`${url}${keyword}`, {}, {}, true)
                if (response) {
                    if (Array.isArray(response)) {
                        setOptions(response)
                    } else if (response.list && Array.isArray(response.list)) {
                        setOptions(response.list)
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }
        timeout = setTimeout(() => search(), 300)
    }

    const itemRenderer = (item, index) => {
        if (optionRenderer) {
            return optionRenderer(item, index)
        }
        const key = optionIndexKey ? item[optionIndexKey] : index
        const value = optionValueKey ? item[optionValueKey] : index
        const text = optionTextKey ? item[optionTextKey] : item.text
        return (
            <Select.Option key={key} value={value} item={item}>
                {optionTypeKey && item[optionTypeKey] && (
                    <span
                        className={`type-label ${
                            item[optionTypeKey] === ShipperTypeEnum.CUSTOMER.value ? 'customer' : 'shipping-address'
                        } `}
                    >
                        {getEnumText(ShipperTypeEnum, item[optionTypeKey])}
                    </span>
                )}
                <span>{text}</span>
            </Select.Option>
        )
    }

    return (
        <div className="ins-remote-select-wrapper">
            <InsSelect
                {...rest}
                dropdownClassName="ins-remote-select-dropdown"
                dropdownMatchSelectWidth={false}
                dropdownAutoWidth={false}
                value={selectValue}
                onChange={(value, option) => {
                    storeSelectedRecord(value, option)
                    onChange(value, option)
                }}
                onFocus={event => {
                    event.target.autocomplete = 'new-password'
                    if (autoSearch && !selectValue) {
                        searchHandler(event.target.value)
                    }
                }}
                showSearch={true}
                suffixIcon={suffixIcon ? suffixIcon : <SearchIcon size={16} />}
                filterOption={false}
                onSearch={keyword => searchHandler(keyword)}
                dropdownSuffix={
                    <div>
                        {createModal && (
                            <InsButton
                                buttonStyle="default"
                                className="color-pantone-2738-c w-100p"
                                style={{ borderRadius: 8 }}
                                buttonSize="big"
                                icon={<AddIcon size={24} />}
                                onClick={() => setCreateModalVisible(true)}
                            >
                                {createText ? createText : t('common:Create new')}
                            </InsButton>
                        )}
                        {listModal && (
                            <div className="ins-flex flex-middle flex-center">
                                <InsButton buttonStyle="plain" onClick={() => setListModalVisible(true)}>
                                    {t('common:Choose from list')}
                                </InsButton>
                            </div>
                        )}
                    </div>
                }
            >
                {options.map((option, index) => itemRenderer(option, index))}
                {!!selectedRecord[optionValueKey] &&
                    !options.find(o => o[optionValueKey] === selectedRecord[optionValueKey]) && (
                        <Select.Option
                            value={selectedRecord[optionValueKey]}
                            // item={selectedRecord}
                            label={selectedRecord[optionTextKey]}
                            style={{ display: 'none' }}
                        >
                            {selectedRecord[optionTextKey]}
                        </Select.Option>
                    )}
            </InsSelect>
            {detailModal && selectValue && (
                <InsButton className="ml-4" buttonStyle="plain" onClick={() => setDetailModalVisible(true)}>
                    <UnfoldMoreIcon size={16} className="mr-4" />
                    {detailModal.linkText}
                </InsButton>
            )}
            {detailModal && selectValue && (
                <InsModal
                    className="ins-detail-modal"
                    title={detailModal.title}
                    width={detailModal.width ? detailModal.width : 760}
                    visible={detailModalVisible}
                    onCancel={() => setDetailModalVisible(false)}
                    footer={null}
                >
                    {optionTypeKey && selectedRecord[optionTypeKey] === ShipperTypeEnum.SHIPPING_ADDRESS.value ? (
                        <detailModal.component2 inModal={true} id={selectValue} {...detailModal.props} />
                    ) : (
                        <detailModal.component inModal={true} id={selectValue} {...detailModal.props} />
                    )}
                </InsModal>
            )}
            {listModal && (
                <InsModal
                    className="ins-list-modal"
                    title={listModal.title}
                    width={listModal.width ? listModal.width : 930}
                    visible={listModalVisible}
                    onCancel={() => setListModalVisible(false)}
                    footer={null}
                >
                    <div>
                        <listModal.component
                            inModal={true}
                            onSelect={item => {
                                setListModalVisible(false)
                                storeSelectedRecord(item.id, item)
                                onChange(item.id, item)
                                setOptions([item])
                            }}
                            {...listModal.props}
                        />
                    </div>
                </InsModal>
            )}
            {createModal && (
                <InsModal
                    className="ins-create-modal"
                    title={createModal.title}
                    width={createModal.width ? createModal.width : 760}
                    visible={createModalVisible}
                    onCancel={() => setCreateModalVisible(false)}
                    footer={null}
                >
                    <div>
                        <createModal.component
                            inModal={true}
                            onCreated={item => {
                                setCreateModalVisible(false)
                                storeSelectedRecord(item.id, item)
                                onChange(item.id, item)
                                setOptions([item])
                            }}
                            onCancel={() => setCreateModalVisible(false)}
                            {...createModal.props}
                            form={form}
                        />
                    </div>
                </InsModal>
            )}
        </div>
    )
}

export default InsRemoteSelect

InsRemoteSelect.propTypes = {
    initialOptions: PropTypes.array,
    updatedOptions: PropTypes.array,
    initialValue: PropTypes.string,
    listModal: PropTypes.shape({
        title: PropTypes.string,
        width: PropTypes.number,
        component: PropTypes.any.isRequired,
        props: PropTypes.object,
    }),
    createModal: PropTypes.shape({
        title: PropTypes.string,
        width: PropTypes.number,
        component: PropTypes.any.isRequired,
        props: PropTypes.object,
    }),
    detailModal: PropTypes.shape({
        title: PropTypes.string,
        width: PropTypes.number,
        component: PropTypes.any.isRequired,
        component2: PropTypes.any,
        props: PropTypes.object,
        linkText: PropTypes.string,
    }),
    onChange: PropTypes.func,
    optionIndexKey: PropTypes.string,
    optionRenderer: PropTypes.any,
    optionTextKey: PropTypes.string,
    optionValueKey: PropTypes.string,
    optionTypeKey: PropTypes.string,
    suffixIcon: PropTypes.any,
    url: PropTypes.string,
    value: PropTypes.any,
    autoSearch: PropTypes.bool,
    createText: PropTypes.string,
}

InsRemoteSelect.defaultProps = {
    initialOptions: [],
    updatedOptions: [],
    optionIndexKey: 'id',
    optionTextKey: 'text',
    optionValueKey: 'id',
    optionTypeKey: 'text',
    autoSearch: true,
}
