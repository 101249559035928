import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InsFloatBar from 'components/InsFloatBar'
import { Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import { DeleteIcon, EditIcon, FileCopyIcon } from 'components/InsIcon'
import ProjectCloneModal from 'modules/business/project/ProjectCloneModal'
import { APP_URLS } from 'constants/url.constant'
import BusinessStatusEnum from 'enums/business_status.enum'
import api from 'helpers/api.helper'
import { useParams, useRouteMatch, useLocation } from 'react-router-dom'
import history from 'helpers/history.helper'
import CloneDocumentEnum from 'enums/clone_document.enum'
import ReactToPrint from 'react-to-print'
import { InsBlockLoader } from 'components/InsLoader'
import InsModal from 'components/InsModal'
import { can } from 'helpers/permission.helper'
import Routes from 'routes'
import EstimationDOMPrint from 'modules/business/estimation/EstimationDOMPrint'

const EstimationPreview = ({ estimation, project, setEditMode, updateEstimation, response }) => {
    const { projectId } = useParams()
    const location = useLocation()
    const [modalCloneVisible, setModalCloneVisible] = useState(false)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
    const { t } = useTranslation()
    const componentRef = useRef()
    const [loading, setLoading] = useState(true)
    const canEditEstimation = can('ESTIMATION_EDITABLE') && !project.is_locked
    const deleteEstimation = async () => {
        setModalDeleteVisible(false)
        setLoading(true)
        api.delete(APP_URLS.ESTIMATIONS_DETAIL.replace(':id', project.estimation.id)).then(r => {
            setLoading(false)
            project.estimation = null
            response(
                Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id) + '?from=' + location.search
            )
        })
    }

    const updateStatusEstimation = async (status, cancelOrderFailed) => {
        const data = await api.post(APP_URLS.ESTIMATIONS_UPDATE_STATUS, {
            status: status,
            estimation_id: project.estimation.id,
            cancel_order_failed: cancelOrderFailed,
        })
        updateEstimation(false)
    }

    const getEstimation = () => {
        setLoading(false)
    }

    const { path, url } = useRouteMatch()

    useEffect(() => {
        getEstimation()
    }, [projectId])

    const isEstimationOrderReceived = project?.estimation?.status === BusinessStatusEnum.ORDER_RECEIVED.value
    const isEstimationIssued = project?.estimation?.status === BusinessStatusEnum.ESTIMATION_ISSUED.value
    const isEstimationCreate = project?.estimation?.status === BusinessStatusEnum.ESTIMATION_CREATE.value
    const isProjectOrderFailed = project.status === BusinessStatusEnum.ORDER_FAILED.value

    return (
        <>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <div className="page-container">
                    <EstimationDOMPrint
                        project={project}
                        estimationPrint={estimation}
                        componentRef={componentRef}
                        showPreview={true}
                    />

                    {canEditEstimation && (
                        <InsFloatBar visible={true}>
                            <Row justify="space-between" align="middle">
                                <Col>
                                    <InsButton
                                        disabled={isEstimationOrderReceived || isProjectOrderFailed}
                                        icon={
                                            <DeleteIcon
                                                size={20}
                                                className={
                                                    isEstimationOrderReceived || isProjectOrderFailed
                                                        ? ''
                                                        : 'color-pantone-213-c'
                                                }
                                            />
                                        }
                                        buttonStyle="default"
                                        onClick={() => setModalDeleteVisible(true)}
                                        width={isEstimationOrderReceived || isProjectOrderFailed ? '140px' : '112px'}
                                    >
                                        {isEstimationOrderReceived || isProjectOrderFailed
                                            ? t('見積書削除不可')
                                            : t('見積書削除')}
                                    </InsButton>
                                    <InsModal
                                        className="ins-modal-w-550"
                                        title="削除"
                                        visible={modalDeleteVisible}
                                        onCancel={() => setModalDeleteVisible(false)}
                                        onOk={() => deleteEstimation()}
                                        okText="はい"
                                        cancelText="いいえ"
                                    >
                                        <h3>{'見積書を削除してもよろしいですか？'}</h3>
                                        <p>{'※削除されたドキュメントは各ドキュメントの「削除」タブで確認できます。'}</p>
                                    </InsModal>
                                    <ProjectCloneModal
                                        title={CloneDocumentEnum.ESTIMATION.text}
                                        cloneDocument={CloneDocumentEnum.ESTIMATION.value}
                                        visible={modalCloneVisible}
                                        project={project}
                                        onCancel={() => {
                                            setModalCloneVisible(false)
                                        }}
                                    >
                                        <InsButton
                                            icon={<FileCopyIcon size={20} className="color-pantone-2738-c" />}
                                            disabled={project?.estimation == null}
                                            buttonStyle="default"
                                            style={{ marginLeft: 8 }}
                                            onClick={() => {
                                                setModalCloneVisible(true)
                                            }}
                                        >
                                            {t('Estimation clone document')}
                                        </InsButton>
                                    </ProjectCloneModal>
                                    <InsButton
                                        disabled={isEstimationOrderReceived || isProjectOrderFailed}
                                        icon={
                                            <EditIcon
                                                size={20}
                                                className={
                                                    isEstimationOrderReceived || isProjectOrderFailed
                                                        ? ''
                                                        : 'color-pantone-2738-c'
                                                }
                                            />
                                        }
                                        buttonStyle="default"
                                        onClick={() => {
                                            history.push(`${url}/update` + location.search)
                                        }}
                                        width={isEstimationOrderReceived || isProjectOrderFailed ? '140px' : '112px'}
                                        className="ml-8"
                                    >
                                        {isEstimationOrderReceived || isProjectOrderFailed
                                            ? t('見積書編集不可')
                                            : t('見積書編集')}
                                    </InsButton>
                                </Col>
                                <Col className="ins-flex flex-middle btn-update-project-bottom">
                                    {isEstimationIssued && (
                                        <InsButton
                                            className="fw-bold"
                                            buttonStyle={!isProjectOrderFailed ? 'primary' : 'outline'}
                                            buttonSize="big"
                                            style={{ width: 140 }}
                                            onClick={() =>
                                                updateStatusEstimation(
                                                    BusinessStatusEnum.ORDER_FAILED.value,
                                                    isProjectOrderFailed
                                                )
                                            }
                                        >
                                            {!isProjectOrderFailed ? t('失注') : t('失注 取消')}
                                        </InsButton>
                                    )}
                                    <ReactToPrint
                                        trigger={() => (
                                            <InsButton
                                                disabled={isProjectOrderFailed}
                                                className="fw-bold"
                                                buttonStyle={isEstimationCreate ? 'primary' : 'outline'}
                                                buttonSize="big"
                                                style={{ width: 140 }}
                                                onClick={() => {}}
                                            >
                                                {isEstimationCreate ? t('見積書 発行') : t('見積書 再発行')}
                                            </InsButton>
                                        )}
                                        content={() => componentRef.current}
                                        onBeforePrint={() => {
                                            isEstimationCreate &&
                                                updateStatusEstimation(BusinessStatusEnum.ESTIMATION_ISSUED.value)
                                        }}
                                    />
                                    <InsButton
                                        disabled={isProjectOrderFailed}
                                        className="fw-bold"
                                        buttonStyle={!isEstimationOrderReceived ? 'secondary' : 'outline-secondary'}
                                        buttonSize="big"
                                        style={{ width: 140 }}
                                        onClick={() => {
                                            if (!isEstimationOrderReceived) {
                                                updateStatusEstimation(BusinessStatusEnum.ORDER_RECEIVED.value)
                                            } else {
                                                updateStatusEstimation(BusinessStatusEnum.ESTIMATION_ISSUED.value)
                                            }
                                        }}
                                    >
                                        {!isEstimationOrderReceived
                                            ? t('Order processing')
                                            : t('Cancel order processing')}
                                    </InsButton>
                                </Col>
                            </Row>
                        </InsFloatBar>
                    )}
                </div>
            )}
        </>
    )
}

export default EstimationPreview
EstimationPreview.propTypes = {
    estimation: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    setEditMode: PropTypes.func,
    updateEstimation: PropTypes.func,
    response: PropTypes.func,
}
