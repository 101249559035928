import React, { useEffect, useState } from 'react'
import InsDataTable from 'components/datatable/InsDataTable'
import { Col, Row, Tabs } from 'antd'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getData, setFilters, setSorter } from 'modules/business/invoice/invoice.slice'
import { useTranslation } from 'react-i18next'
import InsTab from 'components/InsTab'
import InsPagination from 'components/datatable/InsPagination'
import InsLoader from 'components/InsLoader'
import { dateFormat } from 'helpers/date.helper'
import { formatNumber } from 'helpers/number.helper'
import { userSettingColumns } from 'helpers/user_setting.helper'
import InsButton from 'components/InsButton'
import Routes from 'routes'
import { AddIcon } from 'components/InsIcon'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InvoiceListSearch from 'modules/business/invoice/InvoiceListSearch'
import InvoiceListExpand from 'modules/business/invoice/InvoiceListExpand'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import InsModal from 'components/InsModal'
import BusinessPageEnum from 'enums/business_page.enum'
import FloatBarBehavior from 'modules/business/common/FloatBarBehavior'
import NavigationEnum from 'enums/navigation.enum'

const InvoiceList = ({ state, currentUser }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const invoiceColumns = [
        {
            title: t('column:ID'),
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: t('案件ID'),
            dataIndex: 'project_id',
            sorter: true,
        },
        {
            title: t('column:Invoice code'),
            dataIndex: 'invoice_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    linkTo={
                        Routes.private.business.PROJECT_DETAIL.path.replace(
                            ':id',
                            (record.project && record.project.id) || record.summary_invoice_projects[0]?.id
                        ) +
                        '/invoices?from=' +
                        NavigationEnum.LIST_INVOICES
                    }
                    buttonType="link"
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: '件名',
            dataIndex: 'invoice_name',
            sorter: true,
        },
        {
            title: t('column:Customer name'),
            dataIndex: 'customer_name',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        setModalCustomerDetail({
                            customerId: record.customer?.id,
                            visible: true,
                        })
                    }}
                >
                    {record.customer && record.customer.customer_name}
                </InsButton>
            ),
        },
        {
            title: t('column:Status'),
            dataIndex: 'status',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.BUSINESS_STATUS.value, text.toString()),
        },
        {
            title: t('column:Total'),
            dataIndex: 'total',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: '納品日',
            dataIndex: 'delivery_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: '請求書発行日',
            dataIndex: 'issue_date',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: '案件番号',
            dataIndex: 'project_code',
            sorter: true,
        },
        {
            title: '請求対象',
            dataIndex: 'is_summary_invoice',
            sorter: true,
        },
        {
            title: '顧客',
            dataIndex: 'customer_id',
            sorter: true,
        },
        {
            title: '仕入先',
            dataIndex: 'supplier_id',
            sorter: true,
        },
        {
            title: '顧客担当者',
            dataIndex: 'customer_pic_name',
            sorter: true,
        },
        {
            title: '顧客担当者敬称',
            dataIndex: 'customer_pic_title',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.CUSTOMER_TITLE.value, text),
        },
        {
            title: '納入先',
            dataIndex: 'delivery_destination',
            sorter: true,
        },
        {
            title: '納品先区分',
            dataIndex: 'delivery_type',
            sorter: true,
        },
        {
            title: '担当者',
            dataIndex: 'pic_id',
            sorter: true,
        },
        {
            title: '差出名',
            dataIndex: 'sender_name',
            sorter: true,
        },
        {
            title: '差出名ID',
            dataIndex: 'sender_id',
            sorter: true,
        },
        {
            title: '差出住所',
            dataIndex: 'sender_address',
            sorter: true,
        },
        {
            title: '粗利',
            dataIndex: 'gross_profit',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: '小計',
            dataIndex: 'subtotal',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: '消費税',
            dataIndex: 'consumption_tax',
            sorter: true,
            render: text => formatNumber(text),
        },
        {
            title: '備考',
            dataIndex: 'note',
            sorter: true,
        },
        {
            title: '削除フラグ',
            dataIndex: 'is_deleted',
            sorter: true,
        },
        {
            title: '作成者',
            dataIndex: 'created_by',
            sorter: true,
        },
        {
            title: '作成日',
            dataIndex: 'created_at',
            sorter: true,
        },
        {
            title: '更新者',
            dataIndex: 'updated_by',
            sorter: true,
        },
        {
            title: '更新日',
            dataIndex: 'updated_at',
            sorter: true,
        },
        {
            title: '受注済日',
            dataIndex: 'ordered_date',
            sorter: true,
        },
        {
            title: '発行日',
            dataIndex: 'issued_date',
            sorter: true,
        },
        {
            title: '納期の時間帯',
            dataIndex: 'delivery_time',
            sorter: true,
        },
    ]
    const defaultColumns = [
        'invoice_code',
        'invoice_name',
        'customer_name',
        'status',
        'total',
        'delivery_date',
        'issue_date',
    ]

    const userInvoiceColumns = userSettingColumns(currentUser.user_setting, 'invoices', invoiceColumns, defaultColumns)

    const invoiceDetailColumns = [
        {
            title: t('column:Project code'),
            dataIndex: 'project_code',
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    linkTo={
                        Routes.private.business.PROJECT_DETAIL.path.replace(':id', record.id) +
                        '/invoices?from=' +
                        NavigationEnum.LIST_INVOICES
                    }
                    buttonType="link"
                >
                    {text}
                </InsButton>
            ),
        },
        {
            title: t('column:Project name'),
            dataIndex: 'project_name',
        },
        {
            title: t('column:Status'),
            dataIndex: 'status',
            sorter: true,
            render: text => getClassificationItemName(ClassificationTypeEnum.BUSINESS_STATUS.value, text.toString()),
        },
    ]

    const updateFilters = obj => {
        // const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        const newFilters = Object.assign(
            {},
            {
                'status': state.filters.status,
                'created_at:gte': state.filters['created_at:gte'],
                'created_at:lte': state.filters['created_at:lte'],
                'keyword': state.filters['keyword'],
                'is_deleted': state.filters['is_deleted'],
            },
            obj
        )
        dispatch(setFilters(newFilters))
        getInvoices(state.pagination, newFilters)
    }

    const getInvoices = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSelectedKeys([])
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        getInvoices()
        // eslint-disable-next-line
    }, [])

    const [modalCustomerDetail, setModalCustomerDetail] = useState({
        customerId: null,
        visible: false,
    })

    const [selectedKeys, setSelectedKeys] = useState([])

    return (
        <div className="page-container invoice-list-page">
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalCustomerDetail.visible}
                onCancel={() =>
                    setModalCustomerDetail({
                        customerId: modalCustomerDetail.customerId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <CustomerDetailComponent inModal={true} id={modalCustomerDetail.customerId} />
            </InsModal>
            <Row className="mt-8 mb-16" justify="space-between">
                <Col>
                    <h1 className="page-title mb-0">{'請求書一覧'}</h1>
                </Col>
                <Col>
                    <InsButton
                        width={120}
                        buttonType="link"
                        linkTo={Routes.private.business.PROJECT_CREATE.path}
                        icon={<AddIcon size={24} />}
                        buttonStyle="secondary"
                    >
                        {'案件作成'}
                    </InsButton>
                </Col>
            </Row>
            <InvoiceListSearch
                onSearch={condition => updateFilters(condition)}
                onReset={() => updateFilters({ keyword: [] })}
                getInvoices={getInvoices}
                pagination={state.pagination}
            />
            <InsTab
                type="card"
                metaComponent={
                    <InsPagination
                        {...state.pagination}
                        onChange={pagination => getInvoices(pagination)}
                        pageSizeOptions={[100, 200, 300]}
                    />
                }
                className="hide-tabs"
            >
                <Tabs.TabPane tab={'placeholder'} key="0" />
            </InsTab>
            <InsDataTable
                loading={state.loading ? { indicator: <InsLoader /> } : false}
                hasSelection={true}
                onSelectionChanged={keys => setSelectedKeys(keys)}
                subSelectedKeys={selectedKeys}
                data={state.data}
                columns={userInvoiceColumns}
                expandable={{
                    expandedRowRender: record => <InvoiceListExpand record={record} columns={invoiceDetailColumns} />,
                    rowExpandable: r => [r.project, ...r.summary_invoice_projects].filter(p => p).length > 0,
                    expandIconColumnIndex: 8,
                }}
                onChange={(pagination, filter, sorter) => {
                    getInvoices(state.pagination, state.filters, sorter)
                }}
                pagination={state.pagination}
                filters={state.filters}
                sorter={state.sorter}
            />
            <FloatBarBehavior
                visible={selectedKeys.length > 0}
                data={state.data}
                selectedIds={selectedKeys}
                setSelectedIds={setSelectedKeys}
                documentType={BusinessPageEnum.INVOICES.value}
                fetchData={getInvoices}
            />
        </div>
    )
}

const mapState = state => ({ state: state.invoice, currentUser: state.auth.user })
export default connect(mapState)(InvoiceList)

InvoiceList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    currentUser: PropTypes.object,
}
