import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FileCopyIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import { useTranslation } from 'react-i18next'
import CloneDocumentEnum from 'enums/clone_document.enum'
import ProjectCloneModal from 'modules/business/project/ProjectCloneModal'
import BusinessPageEnum from 'enums/business_page.enum'
import InstructionTypeEnum from 'enums/instruction_type.enum'

const FloatBarButtonCopy = ({ data, selectedIds, documentType, fetchData }) => {
    const { t } = useTranslation()
    const [modalCloneVisible, setModalCloneVisible] = useState(false)

    const assignToProject = () => {
        const doc = data.find(d => d.id === selectedIds[0])

        if (documentType === BusinessPageEnum.ESTIMATIONS.value) {
            return { ...doc.project, estimation: doc }
        } else if (documentType === BusinessPageEnum.INSTRUCTIONS.value) {
            return doc.instruction_classification === InstructionTypeEnum.INTERNAL.value
                ? { ...doc.project, instruction_internal: doc }
                : { ...doc.project, instruction_outsource: doc }
        } else if (documentType === BusinessPageEnum.PURCHASE_ORDER.value) {
            return { ...doc.project_for_list, place_order: doc }
        } else if (documentType === BusinessPageEnum.SALES.value) {
            return { ...doc.project, sale: doc }
        }
    }

    const titleLabel = () => {
        if (documentType === BusinessPageEnum.ESTIMATIONS.value) {
            return CloneDocumentEnum.ESTIMATION.text
        } else if (documentType === BusinessPageEnum.INSTRUCTIONS.value) {
            const doc = data.find(d => d.id === selectedIds[0])
            return doc.instruction_classification === InstructionTypeEnum.INTERNAL.value
                ? CloneDocumentEnum.INSTRUCTION_INTERNAL.text
                : CloneDocumentEnum.INSTRUCTION_OUTSOURCE.text
        } else if (documentType === BusinessPageEnum.PURCHASE_ORDER.value) {
            return CloneDocumentEnum.PLACE_ORDER.text
        } else if (documentType === BusinessPageEnum.SALES.value) {
            return CloneDocumentEnum.SALES.text
        }
    }

    const cloneDocumentType = () => {
        if (documentType === BusinessPageEnum.ESTIMATIONS.value) {
            return CloneDocumentEnum.ESTIMATION.value
        } else if (documentType === BusinessPageEnum.INSTRUCTIONS.value) {
            const doc = data.find(d => d.id === selectedIds[0])
            return doc.instruction_classification === InstructionTypeEnum.INTERNAL.value
                ? CloneDocumentEnum.INSTRUCTION_INTERNAL.value
                : CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value
        } else if (documentType === BusinessPageEnum.PURCHASE_ORDER.value) {
            return CloneDocumentEnum.PLACE_ORDER.value
        } else if (documentType === BusinessPageEnum.SALES.value) {
            return CloneDocumentEnum.SALES.value
        }
        return documentType
    }

    return (
        <>
            <ProjectCloneModal
                title={titleLabel()}
                cloneDocument={cloneDocumentType()}
                visible={modalCloneVisible}
                project={assignToProject()}
                onCancel={() => {
                    setModalCloneVisible(false)
                    fetchData()
                }}
            >
                <InsButton
                    icon={<FileCopyIcon size={16} className="color-pantone-2738-c" />}
                    buttonStyle="default"
                    className="mr-8"
                    style={{ minWidth: 162 }}
                    onClick={() => {
                        setModalCloneVisible(true)
                    }}
                >
                    {t('Create and copy')}
                </InsButton>
            </ProjectCloneModal>
        </>
    )
}

export default FloatBarButtonCopy

FloatBarButtonCopy.propTypes = {
    data: PropTypes.any,
    selectedIds: PropTypes.array,
    documentType: PropTypes.any,
    fetchData: PropTypes.func,
}
