import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, message } from 'antd'
import Routes from 'routes'
import moment from 'moment'
import history from 'helpers/history.helper'
import PropTypes from 'prop-types'
import InsDataTable from 'components/datatable/InsDataTable'
import { AddIcon, DescriptionIcon, LockIcon, SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import { dateFormat } from 'helpers/date.helper'
import InsModal from 'components/InsModal'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import InsInputSearch from 'components/InsInputSearch'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import {
    setLoading,
    setPagination,
    setFilters,
    setKeywords,
    getData,
} from 'modules/business/project/project_modal.slice'
import InsLoader from 'components/InsLoader'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import BusinessStatusEnum from 'enums/business_status.enum'
import CloneDocumentEnum from 'enums/clone_document.enum'

const ProjectCloneModal = ({ title, cloneDocument, state, children, visible, project, onCancel }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [selectedProject, setSelectedProject] = useState()
    const projectColumns = [
        {
            title: t('column:Project code'),
            dataIndex: 'project_code',
            sorter: true,
            render: (text, r) => {
                return (
                    <>
                        {r.is_locked ? <LockIcon className="color-blue-gray-600 mr-4" size={14} /> : null}
                        <InsButton
                            buttonStyle="plain"
                            linkTo={Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id)}
                            buttonType="link"
                            target="_blank"
                        >
                            {text}
                        </InsButton>
                    </>
                )
            },
        },
        {
            title: t('column:Customer name'),
            dataIndex: 'customer',
            sorter: true,
            render: text => (text ? text.customer_name : ''),
        },
        {
            title: t('column:Project name'),
            dataIndex: 'project_name',
            sorter: true,
        },
        {
            title: t('column:Created at'),
            dataIndex: 'created_at',
            sorter: true,
            render: text => dateFormat(text),
        },
        {
            title: t('column:Status'),
            dataIndex: 'status',
            sorter: true,
            render: (text, record) => {
                switch (cloneDocument) {
                    case CloneDocumentEnum.ESTIMATION.value:
                        return (
                            <span
                                className={
                                    record?.estimation?.status === BusinessStatusEnum.ORDER_RECEIVED.value
                                        ? 'color-pantone-213-c'
                                        : ''
                                }
                            >
                                {getClassificationItemName(
                                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                                    record?.estimation?.status?.toString()
                                )}
                            </span>
                        )
                    case CloneDocumentEnum.SALES.value:
                        return (
                            <span
                                className={
                                    record?.sale?.status === BusinessStatusEnum.SALE_PROCESSED.value
                                        ? 'color-pantone-213-c'
                                        : ''
                                }
                            >
                                {getClassificationItemName(
                                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                                    record?.sale?.status?.toString()
                                )}
                            </span>
                        )
                    case CloneDocumentEnum.INSTRUCTION_INTERNAL.value:
                        return (
                            <span>
                                {getClassificationItemName(
                                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                                    record?.instruction_internal?.status?.toString()
                                )}
                            </span>
                        )
                    case CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value:
                        return (
                            <span>
                                {getClassificationItemName(
                                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                                    record?.instruction_outsource?.status?.toString()
                                )}
                            </span>
                        )
                    case CloneDocumentEnum.PLACE_ORDER.value:
                        return (
                            <span>
                                {getClassificationItemName(
                                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                                    record?.place_order?.status?.toString()
                                )}
                            </span>
                        )
                    default:
                        return <span />
                }
            },
        },
    ]
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false)

    const getProjectClones = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(getData({ pagination, filters, sorter }))
    }

    const updateFilters = obj => {
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        const newFilters = Object.assign(
            {},
            {
                'status': state.filters.status,
                'created_at:gte': state.filters['created_at:gte'],
                'created_at:lte': state.filters['created_at:lte'],
                'is_purchase_order': state.filters['is_purchase_order'],
            },
            obj
        )
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getProjectClones(newPagination, newFilters)
    }

    const createdAtChangeHandler = value => {
        const newFilters = Object.assign({}, state.filters, {
            'created_at:gte': value[0] + '\t00:00:00',
            'created_at:lte': value[1] + '\t23:59:59',
        })
        dispatch(setFilters(newFilters))
        getProjectClones(state.pagination, newFilters)
    }

    useEffect(() => {
        if (visible) {
            updateFilters({ is_purchase_order: project.is_purchase_order })
        }
    }, [visible])

    const locale = {
        emptyText: (
            <Row justify="center" className="ins-empty-text" align="middle">
                <div className="circle">
                    <Row justify="center" align="middle" className="icon">
                        <div className="icon">
                            <DescriptionIcon size={54} className="description-icon" />
                            <SearchIcon size={30} className="search-icon" />
                        </div>
                    </Row>
                    <Row justify="center" align="middle" className="text">
                        <h4>{t('Results not found')}</h4>
                        <InsButton buttonStyle="plain" onClick={() => redirectProjectCreate(cloneDocument)}>
                            {t('Create and copy')}
                        </InsButton>
                    </Row>
                </div>
            </Row>
        ),
    }

    const clone = async () => {
        dispatch(setLoading(true))
        if (modalConfirmVisible) setModalConfirmVisible(false)
        let data = {}
        switch (cloneDocument) {
            case CloneDocumentEnum.ESTIMATION.value:
                data = await api.post(APP_URLS.PROJECT_CLONE_ESTIMATION, {
                    project_id: selectedProject.id,
                    estimation_id: project.estimation.id,
                })
                break
            case CloneDocumentEnum.INSTRUCTION_INTERNAL.value:
                data = await api.post(APP_URLS.PROJECT_CLONE_INSTRUCTION_INTERNAL, {
                    project_id: selectedProject.id,
                    instruction_internal_id: project?.instruction_internal?.id,
                })
                break
            case CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value:
                data = await api.post(APP_URLS.PROJECT_CLONE_INSTRUCTION_OUTSOURCE, {
                    project_id: selectedProject.id,
                    instruction_outsource_id: project?.instruction_outsource?.id,
                })
                break
            case CloneDocumentEnum.PLACE_ORDER.value:
                data = await api.post(APP_URLS.PROJECT_CLONE_PLACE_ORDER, {
                    project_id: selectedProject.id,
                    place_order_id: project?.place_order?.id,
                })
                break
            case CloneDocumentEnum.SALES.value:
                data = await api.post(APP_URLS.PROJECT_CLONE_SALE, {
                    project_id: selectedProject.id,
                    sale_id: project?.sale?.id,
                })
                break
            default:
                break
        }
        dispatch(setLoading(false))
        if (data && data?.isSuccess) {
            message.success(t('Clone successful'))
            onCancel()
        }
    }

    const isDisabledCopy = (cloneDocument, selectedProject) => {
        if (!selectedProject || selectedProject?.id === project?.id) return true
        if (selectedProject?.is_locked) return true
        switch (cloneDocument) {
            case CloneDocumentEnum.ESTIMATION.value:
                if (selectedProject?.estimation?.status === BusinessStatusEnum.ORDER_RECEIVED.value) {
                    return true
                }
                break
            case CloneDocumentEnum.SALES.value:
                if (selectedProject?.sale?.status === BusinessStatusEnum.SALE_PROCESSED.value) return true
                break
        }

        return false
    }

    const redirectProjectCreate = cloneDocument => {
        switch (cloneDocument) {
            case CloneDocumentEnum.ESTIMATION.value:
                history.push(Routes.private.business.PROJECT_CREATE.path, {
                    estimation_id: project?.estimation.id,
                })
                break
            case CloneDocumentEnum.INSTRUCTION_INTERNAL.value:
                history.push(Routes.private.business.PROJECT_CREATE.path, {
                    instruction_internal_id: project?.instruction_internal.id,
                })
                break
            case CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value:
                history.push(Routes.private.business.PROJECT_CREATE.path, {
                    instruction_outsource_id: project?.instruction_outsource.id,
                })
                break
            case CloneDocumentEnum.PLACE_ORDER.value:
                history.push(Routes.private.business.PROJECT_CREATE.path, {
                    place_order_id: project?.place_order.id,
                    is_purchase_order: project?.is_purchase_order,
                })
                break
            case CloneDocumentEnum.SALES.value:
                history.push(Routes.private.business.PROJECT_CREATE.path, {
                    sale_id: project?.sale.id,
                })
                break
            default:
                break
        }
    }

    return (
        <React.Fragment>
            {/*Modal confirm*/}
            <InsModal
                title="確認"
                visible={modalConfirmVisible}
                onCancel={() => setModalConfirmVisible(false)}
                onOk={clone}
                okText="はい"
                cancelText="いいえ"
            >
                <p>複写先の案件に既に同じドキュメントが存在しています。</p>
                <p>上書き保存してもよろしいですか？</p>
            </InsModal>
            <InsModal
                className="project-clone-modal"
                title={`${title}複写`}
                width={866}
                visible={visible}
                onCancel={onCancel}
                footer={[
                    <InsButton
                        key="button-1"
                        icon={<AddIcon size={16} className="color-pantone-2738-c" />}
                        buttonStyle="default"
                        onClick={() => redirectProjectCreate(cloneDocument)}
                    >
                        {t('Create and copy')}
                    </InsButton>,
                    <Row key="button-2">
                        <InsButton buttonStyle="outline" onClick={onCancel} style={{ width: 130 }}>
                            {t('Cancel')}
                        </InsButton>
                        <InsButton
                            buttonStyle="primary"
                            disabled2={isDisabledCopy(cloneDocument, selectedProject)}
                            onClick={() => {
                                let visible = false
                                switch (cloneDocument) {
                                    case CloneDocumentEnum.ESTIMATION.value:
                                        if (selectedProject?.estimation?.status) visible = true
                                        break
                                    case CloneDocumentEnum.INSTRUCTION_INTERNAL.value:
                                        if (selectedProject?.instruction_internal?.status) visible = true
                                        break
                                    case CloneDocumentEnum.INSTRUCTION_OUTSOURCE.value:
                                        if (selectedProject?.instruction_outsource?.status) visible = true
                                        break
                                    case CloneDocumentEnum.PLACE_ORDER.value:
                                        if (selectedProject?.place_order?.status) visible = true
                                        break
                                    case CloneDocumentEnum.SALES.value:
                                        if (selectedProject?.sale?.status) visible = true
                                        break
                                    default:
                                        visible = false
                                }
                                if (visible) setModalConfirmVisible(true)
                                else clone()
                            }}
                            style={{ width: 130 }}
                        >
                            {t('Copy')}
                        </InsButton>
                    </Row>,
                ]}
            >
                <Row justify="space-between">
                    <h4>
                        {`選択した${title}を複写する案件を１つ選択してください。受注済、売上処理済案件は取り消し後複写処理を行ってください`}{' '}
                    </h4>
                </Row>
                <Row
                    gutter={[8, 16]}
                    align="middle"
                    style={{
                        background: '#DAE2E8',
                        height: 48,
                        marginTop: 8,
                        marginBottom: 8,
                    }}
                >
                    <Col span={24}>
                        <InsDateRangePicker
                            className="mr-4"
                            value={[
                                moment.utc(state.filters['created_at:gte']),
                                moment.utc(state.filters['created_at:lte']),
                            ]}
                            onChange={(mm, value) => createdAtChangeHandler(value)}
                        />

                        <InsInputSearch
                            style={{ width: '258px' }}
                            placeholder={t('Customer name, matter number, subject, etc')}
                            value={state.keywords}
                            onChange={values => dispatch(setKeywords(values))}
                            onSearch={values => {
                                updateFilters({ keyword: values, is_purchase_order: project.is_purchase_order })
                            }}
                        />
                    </Col>
                </Row>
                <InsDataTable
                    tableStyle="compact"
                    locale={locale}
                    loading={state.loading ? { indicator: <InsLoader /> } : false}
                    striped
                    columns={projectColumns}
                    data={state.data}
                    onChange={(pagination, filter, sorter) => {
                        getProjectClones(pagination, filter, sorter)
                    }}
                    pagination={state.pagination}
                    filters={state.filters}
                    sorter={state.sorter}
                    hasSelection={true}
                    onSelectionChanged={keys => {
                        setSelectedProject(state.data.find(element => element.id === keys[0]))
                    }}
                    scroll={{ y: 'calc(100vh - 400px) ' }}
                    selectionType="radio"
                />
            </InsModal>
            {children}
        </React.Fragment>
    )
}

const mapState = state => ({ state: state.projectModal })
export default connect(mapState)(ProjectCloneModal)

ProjectCloneModal.propTypes = {
    title: PropTypes.string,
    cloneDocument: PropTypes.number,
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    project: PropTypes.object,
    onCancel: PropTypes.func,
}
