import { createSlice } from '@reduxjs/toolkit'

const currentTime = new Date().getTime().toString()
const DESTINATION_UNKNOWN = 'destinationUnknown'

const defaultDestinations = {
    [`${currentTime}-destination`]: { isCollapse: false },
    destinationUnknown: { isUnknown: true },
}
const defaultDestinationsOrder = [`${currentTime}-destination`, DESTINATION_UNKNOWN]
const defaultDetails = {
    [`${currentTime}-headline`]: { isHeadline: true },
    [`${currentTime}-product`]: {
        processed_product_classification: '1',
        quantity: 1,
        unit: '2',
        unit_cost: '0.00',
        unit_price: 0,
        tax_rate: '10',
        amount: 0,
    },
}
const defaultDetailsOrder = {
    [`${currentTime}-destination`]: [`${currentTime}-headline`, `${currentTime}-product`],
    [DESTINATION_UNKNOWN]: [],
}

const instructionDetail = createSlice({
    name: 'instructionDetail',
    initialState: {
        destinations: defaultDestinations,
        destinationsOrder: defaultDestinationsOrder,
        details: defaultDetails,
        detailsOrder: defaultDetailsOrder,
        instructionType: 1,
        changedData: false,
    },
    reducers: {
        addDestinations(state) {
            const currentTime = new Date().getTime().toString()
            const destinationId = `${currentTime}-destination`
            state.destinations[destinationId] = { isCollapse: false }
            state.destinationsOrder = [
                ...state.destinationsOrder.slice(0, state.destinationsOrder.length - 1),
                destinationId,
                DESTINATION_UNKNOWN,
            ]

            if (state.detailsOrder[DESTINATION_UNKNOWN] && state.detailsOrder[DESTINATION_UNKNOWN].length > 0) {
                state.detailsOrder[destinationId] = state.detailsOrder[DESTINATION_UNKNOWN]
                state.detailsOrder[DESTINATION_UNKNOWN] = []
            } else {
                state.detailsOrder = {
                    ...state.detailsOrder,
                    [destinationId]: [],
                }
            }
            state.changedData = true
        },
        moveDestination(state, action) {
            const { srcIndex, desIndex } = action.payload
            if (srcIndex === desIndex) {
                return
            }
            let newOrder = [...state.destinationsOrder]
            reOrder(newOrder, srcIndex, desIndex)
            state.destinationsOrder = newOrder
            state.changedData = true
        },
        updateDestination(state, action) {
            state.destinations = {
                ...state.destinations,
                [action.payload.id]: {
                    ...state.destinations[action.payload.id],
                    ...action.payload.value,
                },
            }
            state.changedData = true
        },
        removeDestination(state, action) {
            let newDestinations = { ...state.destinations }
            delete newDestinations[action.payload.id]
            state.destinations = newDestinations
            state.destinationsOrder = state.destinationsOrder.filter(d => d !== action.payload.id)
            const { [action.payload.id]: value, ...newDetailsOrder } = state.detailsOrder

            const desTarget =
                state.destinationsOrder[state.destinationsOrder.length - 1] === DESTINATION_UNKNOWN &&
                state.destinationsOrder[state.destinationsOrder.length - 2]
                    ? state.destinationsOrder[state.destinationsOrder.length - 2]
                    : state.destinationsOrder[state.destinationsOrder.length - 1]

            state.detailsOrder = {
                ...newDetailsOrder,
                [desTarget]: [...state.detailsOrder[desTarget], ...value],
            }
            state.changedData = true
        },

        addDetail(state, action) {
            const currentTime = new Date().getTime().toString()
            const detailId = action.payload.isHeadline ? `${currentTime}-headline` : `${currentTime}-product`
            state.details[detailId] = {
                isHeadline: action.payload.isHeadline,
                processed_product_classification: action.payload.isHeadline ? null : '1',
            }
            state.detailsOrder = {
                ...state.detailsOrder,
                [DESTINATION_UNKNOWN]: [...state.detailsOrder[DESTINATION_UNKNOWN], detailId],
            }
            state.changedData = true
        },
        moveDetail(state, action) {
            const { srcId, desId, srcIndex, desIndex } = action.payload
            if (srcId === desId && srcIndex === desIndex) {
                return
            }
            if (srcId === desId) {
                if (srcIndex === desIndex) return
                let tmpOrder = [...state.detailsOrder[srcId]]
                reOrder(tmpOrder, srcIndex, desIndex)
                state.detailsOrder = {
                    ...state.detailsOrder,
                    [srcId]: tmpOrder,
                }
            } else {
                let srcOrder = [...state.detailsOrder[srcId]]
                let desOrder = [...state.detailsOrder[desId]]
                const [detailId] = srcOrder.splice(srcIndex, 1)
                desOrder.splice(desIndex, 0, detailId)
                state.detailsOrder = {
                    ...state.detailsOrder,
                    [srcId]: srcOrder,
                    [desId]: desOrder,
                }
            }
            state.changedData = true
        },
        updateDetail(state, action) {
            const { id, value } = action.payload
            state.details = {
                ...state.details,
                [id]: {
                    ...state.details[id],
                    ...value,
                },
            }
            state.changedData = true
        },
        removeDetail(state, action) {
            const { destinationId, detailId } = action.payload
            let newDetails = { ...state.details }
            delete newDetails[detailId]
            state.details = newDetails

            let newOrder = [...state.detailsOrder[destinationId]].filter(id => id !== detailId)
            state.detailsOrder = {
                ...state.detailsOrder,
                [destinationId]: newOrder,
            }
            state.changedData = true
        },

        setDestinations(state, action) {
            state.destinations = action.payload
        },

        setDestinationsOrder(state, action) {
            state.destinationsOrder = action.payload
        },

        setDetails(state, action) {
            state.details = action.payload
        },

        setDetailsOrder(state, action) {
            state.detailsOrder = action.payload
        },

        setState(state, action) {
            state.destinations = action.payload.destinations
            state.destinationsOrder = action.payload.destinationsOrder
            state.details = action.payload.details
            state.detailsOrder = action.payload.detailsOrder
            state.changedData = false
        },

        setDefaultState(state) {
            state.destinations = defaultDestinations
            state.destinationsOrder = defaultDestinationsOrder
            state.details = defaultDetails
            state.detailsOrder = defaultDetailsOrder
            state.changedData = false
        },

        setInstructionType(state, action) {
            state.instructionType = action.payload
        },

        setStateWithoutDelivery(state) {
            let newDetailOrder = []
            state.destinationsOrder.map(
                destinationOrder => (newDetailOrder = [...newDetailOrder, ...state.detailsOrder[destinationOrder]])
            )
            state.destinations = { [DESTINATION_UNKNOWN]: { isUnknown: true } }
            state.destinationsOrder = [DESTINATION_UNKNOWN]
            // state.detailsOrder = { [DESTINATION_UNKNOWN]: [...Object.keys(state.details).map(k => k)] }
            state.detailsOrder = { [DESTINATION_UNKNOWN]: newDetailOrder }
        },

        setStateWithOneDelivery(state) {
            // from no delivery -> to one
            if (state.destinationsOrder[0] === DESTINATION_UNKNOWN) {
                const currentTime = new Date().getTime().toString()
                const destinationId = `${currentTime}-destination`

                state.destinations = { [destinationId]: {} }
                state.destinationsOrder = [destinationId]
                state.detailsOrder = { [destinationId]: state.detailsOrder[DESTINATION_UNKNOWN] }
            } else {
                // from multi -> to one
                const destinationId = state.destinationsOrder[0]

                let newDetailOrder = []
                state.destinationsOrder.map(
                    destinationOrder => (newDetailOrder = [...newDetailOrder, ...state.detailsOrder[destinationOrder]])
                )
                state.detailsOrder = { [destinationId]: newDetailOrder }
                state.destinations = { [destinationId]: state.destinations[destinationId] }
                state.destinationsOrder = [destinationId]
            }
        },

        setStateWithMultiDelivery(state) {
            // from no delivery -> to multi
            if (state.destinationsOrder[0] === DESTINATION_UNKNOWN) {
                const currentTime = new Date().getTime().toString()
                const destinationId = `${currentTime}-destination`

                state.destinations[destinationId] = {}
                state.destinationsOrder.unshift(destinationId)
                state.detailsOrder[destinationId] = state.detailsOrder[DESTINATION_UNKNOWN]
                state.detailsOrder[DESTINATION_UNKNOWN] = []
            } else {
                // from one -> to multi
                state.destinations[DESTINATION_UNKNOWN] = { isUnknown: true }
                state.destinationsOrder.push(DESTINATION_UNKNOWN)
                state.detailsOrder[DESTINATION_UNKNOWN] = []
            }
        },
        setCollapse(state, action) {
            const destinationId = action.payload
            const destination = state.destinations[destinationId]
            state.destinations = {
                ...state.destinations,
                [destinationId]: { ...destination, isCollapse: !destination.isCollapse },
            }
        },
        parseData(state, action) {
            const { destinations, details } = action.payload

            let parseDestinations = {}
            let parseDestinationsOrder = []
            let parseDetails = {}
            let parseDetailsOrder = {}

            // parse no destination
            if (destinations.length === 0) {
                parseDestinations[DESTINATION_UNKNOWN] = { isUnknown: true }
                parseDestinationsOrder = [DESTINATION_UNKNOWN]
                parseDetailsOrder[DESTINATION_UNKNOWN] = []
                details.map(d => {
                    const detailName = `${d.id}-${d.headline ? 'headline' : 'product'}`
                    parseDetails[detailName] = Object.assign({}, d, { isHeadline: d.headline })
                    parseDetailsOrder[DESTINATION_UNKNOWN].push(detailName)
                    return null
                })
            } else {
                // with destination
                destinations.map(des => {
                    const destinationName = `${des.id}-destination`
                    parseDestinations[destinationName] = { ...des, isCollapse: false }
                    parseDestinationsOrder.push(destinationName)
                    parseDetailsOrder[destinationName] = []

                    des.details.map(d => {
                        const detailName = `${d.id}-${d.headline ? 'headline' : 'product'}`
                        parseDetails[detailName] = Object.assign({}, d, { isHeadline: d.headline })
                        parseDetailsOrder[destinationName].push(detailName)

                        return null
                    })

                    return null
                })
                if (destinations.length > 1) {
                    parseDestinations[DESTINATION_UNKNOWN] = { isUnknown: true }
                    parseDestinationsOrder.push(DESTINATION_UNKNOWN)
                    parseDetailsOrder[DESTINATION_UNKNOWN] = []
                }
            }

            state.destinations = parseDestinations
            state.destinationsOrder = parseDestinationsOrder
            state.details = parseDetails
            state.detailsOrder = parseDetailsOrder
        },
    },
})

export default instructionDetail.reducer
export const {
    addDestinations,
    moveDestination,
    updateDestination,
    removeDestination,

    addDetail,
    moveDetail,
    updateDetail,
    removeDetail,

    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,

    setState,

    setDefaultState,

    setInstructionType,

    setStateWithoutDelivery,
    setStateWithOneDelivery,
    setStateWithMultiDelivery,
    parseData,
    setCollapse,
} = instructionDetail.actions

const reOrder = (list, srcIndex, desIndex) => {
    const [removed] = list.splice(srcIndex, 1)
    list.splice(desIndex, 0, removed)
}
