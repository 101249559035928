import React, { useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'antd'
import InsButton from 'components/InsButton'
import { UnfoldMoreIcon } from 'components/InsIcon'

const InsCollapse = (
    { hasAdvancedSearch = true, header, buttonHeader, align, children, className, onClose, ...rest },
    ref
) => {
    const [contentExpanded, setContentExpanded] = useState(false)
    let collapseClassName = 'ins-collapse '
    if (align === 'right') {
        collapseClassName += 'ins-collapse-right '
    }
    if (className) {
        collapseClassName += className
    }

    useImperativeHandle(ref, () => ({
        setContentExpanded() {
            setContentExpanded(!contentExpanded)
        },
    }))

    return (
        <Collapse {...rest} className={collapseClassName} activeKey={contentExpanded ? [1] : []}>
            <Collapse.Panel
                header={
                    <React.Fragment>
                        <div className="ins-collapse-header-wrapper">{header}</div>
                        {hasAdvancedSearch && (
                            <>
                                <InsButton
                                    onClick={() => setContentExpanded(!contentExpanded)}
                                    className={`ml-8 ins-collapse-icon ${contentExpanded ? 'expanded' : ''}`}
                                    icon={<UnfoldMoreIcon size={16} />}
                                    buttonStyle="plain"
                                >
                                    詳細検索
                                </InsButton>
                                {buttonHeader}
                            </>
                        )}
                    </React.Fragment>
                }
                key={1}
            >
                {children}
            </Collapse.Panel>
        </Collapse>
    )
}

export default React.forwardRef(InsCollapse)

InsCollapse.propTypes = {
    align: PropTypes.oneOf(['auto', 'right']),
    children: PropTypes.any,
    className: PropTypes.string,
    header: PropTypes.any,
    buttonHeader: PropTypes.any,
    onClose: PropTypes.func,
    hasAdvancedSearch: PropTypes.bool,
}
