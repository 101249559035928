export const ShippingType = {
    PAYMENT: { value: 1, text: '発払い' },
    DM_FLIGHT: { value: 2, text: 'ＤＭ便' },
    TIME: { value: 3, text: 'タイム' },
    FREIGHT_COLLECT: { value: 4, text: '着払い' },
    CAT_POS: { value: 5, text: 'ネコポス' },
    COURIER_COMPACT: { value: 6, text: '宅急便コンパクト' },
}

export const DeliveryTime = {
    UNSPECIFIED: { value: 1, text: '指定なし' },
    IN_THE_MORNING: { value: 2, text: '午前中' },
    FROM_14_TO_16: { value: 3, text: '14時～16時' },
    FROM_16_TO_18: { value: 4, text: '16時～18時' },
    FROM_18_TO_20: { value: 5, text: '18時～20時' },
    FROM_19_TO_21: { value: 6, text: '19時～21時' },
}

export const DeliveryTime_DM_FLIGHT = {
    UNTIL_10AM: { value: 11, text: '午前10時まで' },
    UNTIL_5PM: { value: 12, text: '午後5時まで' },
}

export const ProductType = {
    WORKPIECE: { value: 1, text: '加工物' },
    MATERIAL: { value: 2, text: '資材' },
    MACHINE_PRESENT: { value: 3, text: '機械在中' },
    SAMPLE: { value: 4, text: '見本在中' },
    SAMPLE_IN_EXISTENCE: { value: 5, text: 'サンプル在中' },
    OTHER: { value: 6, text: 'その他' },
    FREE_INPUT: { value: 7, text: 'フリー入力' },
}

export const PrintType = {
    DO_NOT: { value: 1, text: '個数口枠を印字しない' },
    ONLY: { value: 2, text: '個数口枠のみ印字する' },
    ALL: { value: 3, text: '個数口枠と口数を印字する' },
}
