import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import Routes from 'routes'
import { setSidebarOpenKeys, setSidebarSelectedKeys } from 'modules/common/ui/ui.slice'
import { connect } from 'react-redux'
import { AccountCircleIcon, BulIcon, FolderOpenIcon, KeyboardArrowRightIcon, SettingIcon } from 'components/InsIcon'
import { checkAdmin } from 'helpers/permission.helper'

const { SubMenu } = Menu

const Sidebar = ({ openKeys, selectedKeys, setSidebarOpenKeys, setSidebarSelectedKeys }) => {
    const { t } = useTranslation()
    const location = useLocation()

    const menu = [
        {
            icon: AccountCircleIcon,
            text: t('navigation:Account'),
            children: [
                { text: t('navigation:Account setting'), path: Routes.private.master.ACCOUNT_SETTING.path },
                {
                    text: t('navigation:Display setting'),
                    path: Routes.private.master.ACCOUNT_SETTING_EDIT.path + '?user_setting=true',
                },
            ],
        },
        {
            icon: FolderOpenIcon,
            text: t('navigation:Data management'),
            children: checkAdmin()
                ? [
                      {
                          key: 'customer',
                          text: t('navigation:Customer management'),
                          children: [
                              { text: t('navigation:Customer list'), path: Routes.private.master.CUSTOMER_LIST.path },
                          ],
                      },
                      {
                          key: 'stock',
                          text: t('navigation:Stock management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:Stock') }),
                                  path: Routes.private.master.STOCK_LIST.path,
                              },
                          ],
                      },
                      {
                          key: 'product',
                          text: t('navigation:Product (material) management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:Product') }),
                                  path: Routes.private.master.PRODUCT_LIST.path,
                              },
                              // {
                              //     text: t('navigation:{{entity}} list', { entity: t('entity:ProductCost') }),
                              //     path: Routes.private.master.PRODUCT_COST_LIST.path,
                              // },
                          ],
                      },
                      {
                          key: 'outsource-supplier',
                          text: t('navigation:Outsource - Supplier management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:Outsource') }),
                                  path: Routes.private.master.OUTSOURCE_LIST.path,
                              },
                              { text: t('navigation:Supplier list'), path: Routes.private.master.SUPPLIER_LIST.path },
                          ],
                      },
                      {
                          key: 'shipping-address',
                          text: t('navigation:Shipping address management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:ShippingAddress') }),
                                  path: Routes.private.master.SHIPPING_ADDRESS_LIST.path,
                              },
                              // {
                              //     text: t('navigation:{{entity}} list', { entity: t('entity:Client') }),
                              //     path: Routes.private.master.CLIENT_LIST.path,
                              // },
                          ],
                      },
                      {
                          key: 'classification',
                          text: t('navigation:Classification management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:Classification') }),
                                  path: Routes.private.master.CLASSIFICATION_LIST.path,
                              },
                          ],
                      },
                  ]
                : [
                      {
                          key: 'customer',
                          text: t('navigation:Customer management'),
                          children: [
                              { text: t('navigation:Customer list'), path: Routes.private.master.CUSTOMER_LIST.path },
                          ],
                      },
                      {
                          key: 'product',
                          text: t('navigation:Product (material) management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:Product') }),
                                  path: Routes.private.master.PRODUCT_LIST.path,
                              },
                              // {
                              //     text: t('navigation:{{entity}} list', { entity: t('entity:ProductCost') }),
                              //     path: Routes.private.master.PRODUCT_COST_LIST.path,
                              // },
                          ],
                      },
                      {
                          key: 'outsource-supplier',
                          text: t('navigation:Outsource - Supplier management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:Outsource') }),
                                  path: Routes.private.master.OUTSOURCE_LIST.path,
                              },
                              { text: t('navigation:Supplier list'), path: Routes.private.master.SUPPLIER_LIST.path },
                          ],
                      },
                      {
                          key: 'shipping-address',
                          text: t('navigation:Shipping address management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:ShippingAddress') }),
                                  path: Routes.private.master.SHIPPING_ADDRESS_LIST.path,
                              },
                              // {
                              //     text: t('navigation:{{entity}} list', { entity: t('entity:Client') }),
                              //     path: Routes.private.master.CLIENT_LIST.path,
                              // },
                          ],
                      },
                      {
                          key: 'classification',
                          text: t('navigation:Classification management'),
                          children: [
                              {
                                  text: t('navigation:{{entity}} list', { entity: t('entity:Classification') }),
                                  path: Routes.private.master.CLASSIFICATION_LIST.path,
                              },
                          ],
                      },
                  ],
        },
        {
            icon: SettingIcon,
            text: t('navigation:System management'),
            children: checkAdmin()
                ? [
                      {
                          key: 'user',
                          text: t('navigation:User management'),
                          children: [
                              { text: t('navigation:User list'), path: Routes.private.master.USER_LIST.path },
                              { text: t('navigation:Team list'), path: Routes.private.master.TEAM_LIST.path },
                              { text: t('entity:ActionLog'), path: Routes.private.master.ACTION_LOG.path },
                          ],
                      },
                      {
                          key: 'system',
                          text: t('navigation:System management'),
                          children: [
                              {
                                  text: t('navigation:Company information'),
                                  path: Routes.private.master.COMPANY_LIST.path,
                              },
                          ],
                      },
                  ]
                : [
                      {
                          key: 'system',
                          text: t('navigation:System management'),
                          children: [
                              {
                                  text: t('navigation:Company information'),
                                  path: Routes.private.master.COMPANY_LIST.path,
                              },
                          ],
                      },
                  ],
        },
    ]

    useEffect(() => {
        const getOpenKey = (pathname, items, parentKey) => {
            for (const item of items) {
                if (!item.children) {
                    if (item.path === pathname) {
                        return parentKey
                    }
                } else {
                    const childResult = getOpenKey(pathname, item.children, item.key)
                    if (childResult) {
                        return childResult
                    }
                }
            }
            return null
        }

        const openKey = getOpenKey(location.pathname, menu)
        if (openKey) {
            setSidebarOpenKeys([openKey])
        }
        setSidebarSelectedKeys([location.pathname])

        // eslint-disable-next-line
    }, [location])

    const buildMenuItem = (item, isFirstLevel) => {
        return (
            <Menu.Item key={item.path} className={isFirstLevel ? 'ins-menu-level-one' : 'ins-menu-level-two'}>
                <Link className="ins-menu-link" to={item.path || '/'}>
                    <BulIcon size={16} className="ml-4 mr-8" />
                    {item.text}
                </Link>
            </Menu.Item>
        )
    }

    const buildSubMenu = sub => {
        if (!sub.key) {
            throw new Error('Menu key missing')
        }
        return (
            <SubMenu
                key={sub.key}
                className="ins-menu-level-one"
                title={
                    <React.Fragment>
                        <KeyboardArrowRightIcon size={16} className="ml-4 mr-8" />
                        {sub.text}
                    </React.Fragment>
                }
            >
                {sub.children.map(item => buildMenuItem(item, false))}
            </SubMenu>
        )
    }

    const buildMenuGroup = (group, index) => {
        if (!group.children || group.children === 0) return null
        const key = group.key || index
        return (
            <Menu.ItemGroup
                key={key}
                className="ins-menu-group"
                title={
                    <div className="ins-menu-group-title">
                        {group.icon && <group.icon size={24} className="mr-4" />}
                        {group.text}
                    </div>
                }
            >
                {group.children.map(item => {
                    if (item.children) {
                        return buildSubMenu(item)
                    }
                    return buildMenuItem(item, true)
                })}
            </Menu.ItemGroup>
        )
    }

    return (
        <div id="sidebar">
            <Menu
                className="w-100p"
                mode="inline"
                selectedKeys={selectedKeys}
                onSelect={e => setSidebarSelectedKeys([e.key])}
                openKeys={openKeys}
                onOpenChange={keys => setSidebarOpenKeys(keys)}
            >
                {menu.map((group, index) => buildMenuGroup(group, index))}
            </Menu>
        </div>
    )
}

const mapState = state => ({
    openKeys: state.ui.sidebar.openKeys,
    selectedKeys: state.ui.sidebar.selectedKeys,
})
const mapDispatch = { setSidebarOpenKeys, setSidebarSelectedKeys }
export default connect(mapState, mapDispatch)(Sidebar)

Sidebar.propTypes = {
    openKeys: PropTypes.array.isRequired,
    selectedKeys: PropTypes.array.isRequired,
    setSidebarOpenKeys: PropTypes.func.isRequired,
    setSidebarSelectedKeys: PropTypes.func.isRequired,
}
