export const calculateTotal = details => {
    let subtotal = 0
    let consumption_tax = 0
    let gross_profit = 0
    Object.keys(details).map(k => {
        const m = details[k]
        if (m.isHeadline || m.headline) return null
        if (m.amount) {
            subtotal += m.amount
        }
        if (m.amount && m.tax_rate) {
            consumption_tax += (m.amount * m.tax_rate) / 100
        }
        if (m.unit_price && m.unit_cost && m.quantity) {
            gross_profit += (m.unit_price - m.unit_cost) * m.quantity
        }
    })
    return {
        subtotal: roundNumber(subtotal),
        consumption_tax: roundNumber(consumption_tax),
        total: roundNumber(subtotal + consumption_tax),
        gross_profit: roundNumber(gross_profit || 0),
    }
}

export const formCalculateDetailAmount = form => {
    let amount = form.getFieldValue('quantity') * form.getFieldValue('unit_price')
    if (amount < 0 || isNaN(amount)) {
        amount = 0
    }
    form.setFieldsValue({
        amount: roundNumber(amount),
    })
}

export const calculateDetailAmount = (quantity, unitPrice) => {
    return quantity * unitPrice || 0
}

export const roundNumber = (number, fix = 2) => {
    return Number((Math.round(number * 100) / 100).toFixed(fix))
}

export const calculateInStock = details => {
    let products = []
    Object.values(details).map(m => {
        if (m.product_id && m.quantity && m?.product?.is_stock_control === 1) {
            if (products.findIndex(product => product.product_id === m.product_id) === -1) {
                products.push({
                    product_id: m.product_id,
                    // product_name: m.product_name,
                    quantity: m.quantity,
                    is_stock_control: m.product.is_stock_control,
                })
            } else {
                products.find(product => product.product_id === m.product_id).quantity += m.quantity
            }
        }
    })
    return products
}
