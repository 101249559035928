import { combineReducers } from 'redux'
import uiReducer from 'modules/common/ui/ui.slice'
import authReducer from 'modules/common/auth/auth.slice'
import userReducer from 'modules/master/user/user.slice'
import outsourceReducer from 'modules/master/outsource/outsource.slice'
import outsourceModalReducer from 'modules/master/outsource/outsource_modal.slice'
import classificationReducer from 'modules/master/classification/classification.slice'
import customerReducer from 'modules/master/customer/customer.slice'
import customerModalReducer from 'modules/master/customer/customer_modal.slice'
import productReducer from 'modules/master/product/product.slice'
import productModalReducer from 'modules/master/product/product_modal.slice'
import productCostReducer from 'modules/master/productCost/productCost.slice'
import supplierReducer from 'modules/master/supplier/supplier.slice'
import supplierModalReducer from 'modules/master/supplier/supplier_modal.slice'
import shippingAddressReducer from 'modules/master/shippingAddress/shippingAddress.slice'
// import clientReducer from 'modules/master/client/client.slice'
// import clientModalReducer from 'modules/master/client/client_modal.slice'
import stockReducer from 'modules/master/stock/stock.slice'
import projectReducer from 'modules/business/project/project.slice'
import projectModalReducer from 'modules/business/project/project_modal.slice'
import estimationReducer from 'modules/business/estimation/estimation.slice'
import estimationDetailReducer from 'modules/business/estimation/estimation_detail.slice'
import instructionReducer from 'modules/business/instruction/instruction.slice'
import instructionDetailReducer from 'modules/business/instruction/instruction_detail.slice'
import userModalReducer from 'modules/master/user/user_modal.slice'
import placeOrderReducer from 'modules/business/placeOrder/place_order.slice'
import placeOrderDetailReducer from 'modules/business/placeOrder/place_order_detail.slice'
import saleReducer from 'modules/business/sale/sale.slice'
import saleDetailReducer from 'modules/business/sale/sale_detail.slice'
import invoiceReducer from 'modules/business/invoice/invoice.slice'
import invoiceProjectReducer from 'modules/business/invoice/invoiceProjectModal.slice'
import summaryInvoiceReducer from 'modules/business/summaryInvoice/summary_invoice.slice'
import shipmentProcessReducer from 'modules/business/shipment/shipment_process.slice'
import shipmentReducer from 'modules/business/shipment/shipment.slice'
import redSlipReducer from 'modules/business/redSlip/redSlip.slice'
import userSettingReducer from 'modules/master/user/user_setting.slice'
import teamReducer from 'modules/master/team/team.slice'
import teamUserReducer from 'modules/master/team/team_user.slice'
import companyReducer from 'modules/master/company/company.slice'
import paymentReducer from 'modules/business/payment/payment.slice'
import reportReducer from 'modules/business/report/report.slice'
import actionLogReducer from 'modules/business/actionLog/action_log.slice'
import shipperModalReducer from 'modules/master/shipper/shipper_modal.slice'

const appReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,

    // Master
    user: userReducer,
    userModal: userModalReducer,
    userSetting: userSettingReducer,
    team: teamReducer,
    teamUser: teamUserReducer,
    company: companyReducer,
    outsource: outsourceReducer,
    outsourceModal: outsourceModalReducer,
    classification: classificationReducer,
    customer: customerReducer,
    customerModal: customerModalReducer,
    product: productReducer,
    shippingAddress: shippingAddressReducer,
    productModal: productModalReducer,
    productCost: productCostReducer,
    // client: clientReducer,
    // clientModal: clientModalReducer,
    supplier: supplierReducer,
    supplierModal: supplierModalReducer,
    stock: stockReducer,
    project: projectReducer,
    projectModal: projectModalReducer,
    shipperModal: shipperModalReducer,

    // Business
    estimation: estimationReducer,
    estimationDetail: estimationDetailReducer,
    instruction: instructionReducer,
    instructionDetail: instructionDetailReducer,
    placeOrder: placeOrderReducer,
    placeOrderDetail: placeOrderDetailReducer,
    sale: saleReducer,
    saleDetail: saleDetailReducer,
    invoice: invoiceReducer,
    invoiceProjectModal: invoiceProjectReducer,
    summaryInvoice: summaryInvoiceReducer,

    shipment: shipmentReducer,
    shipmentProcess: shipmentProcessReducer,
    redSlip: redSlipReducer,
    payment: paymentReducer,
    report: reportReducer,
    actionLog: actionLogReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_ALL') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer
