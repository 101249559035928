import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import InsDragList from 'components/draggable/InsDragList'

const InsDragAndDropGroup = ({
    data,
    order,
    groupOrder,
    headerRenderer,
    onOrderChange,
    onRemove,
    rowComponent,
    collapse,
}) => {
    const onDragEnd = result => {
        if (!result.destination) {
            return
        }
        onOrderChange(
            result.source.index,
            result.destination.index,
            result.source.droppableId,
            result.destination.droppableId
        )
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {groupOrder.map(groupId => {
                let newData = {}
                let isCollapse = false
                if (collapse) {
                    isCollapse = collapse(groupId)
                }
                if (!isCollapse) {
                    for (const id of order[groupId]) {
                        newData[id] = data[id]
                    }
                }
                if (groupId === 'destinationUnknown' && order[groupId] && order[groupId].length < 1) return null
                return (
                    <div key={groupId} className="ins-drop-group">
                        {headerRenderer(groupId)}
                        {!isCollapse && (
                            <Droppable droppableId={groupId}>
                                {provided => (
                                    <div ref={provided.innerRef}>
                                        <InsDragList
                                            groupId={groupId}
                                            data={newData}
                                            order={order[groupId]}
                                            onRemove={onRemove}
                                            rowComponent={rowComponent}
                                        />
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        )}
                    </div>
                )
            })}
        </DragDropContext>
    )
}

export default InsDragAndDropGroup
