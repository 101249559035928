import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import InsDragList from 'components/draggable/InsDragList'

const InsDragAndDrop = ({ data, order, onOrderChange, onRemove, rowComponent }) => {
    const onDragEnd = result => {
        if (!result.destination) return
        onOrderChange(result.source.index, result.destination.index)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {provided => (
                    <div ref={provided.innerRef}>
                        <InsDragList data={data} order={order} onRemove={onRemove} rowComponent={rowComponent} />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default InsDragAndDrop
