import React from 'react'
import { useTranslation } from 'react-i18next'
import InsBackLink from 'components/InsBackLink'
import UserDetailComponent from 'modules/master/user/UserDetailComponent'
import { setUser } from 'modules/common/auth/auth.slice'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const AccountSetting = ({ currentUser }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className="page-container with-sidebar mb-64">
                <InsBackLink />
                <UserDetailComponent id={currentUser.id} isAccountSetting={true} />
            </div>
        </>
    )
}
const mapState = state => ({
    currentUser: state.auth.user,
})
const mapDispatch = {
    setUser,
}

export default connect(mapState, mapDispatch)(AccountSetting)

AccountSetting.propTypes = {
    match: PropTypes.object.isRequired,
}
