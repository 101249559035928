import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper'
import { APP_URLS } from 'constants/url.constant'
import moment from 'moment'

const name = 'summaryInvoice'

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.SUMMARY_INVOICE, ...props })
)

const dateFormatStart = 'YYYY/MM/DD 00:00:01'
const dateFormatEnd = 'YYYY/MM/DD 23:59:59'

const summaryInvoiceSlice = createSlice({
    name,
    initialState: Object.assign({}, baseInitialState, {
        filters: {
            'created_at:gte': moment().subtract(2, 'months').format(dateFormatStart),
            'created_at:lte': moment().format(dateFormatEnd),
            'is_issued': '0', // as false
        },
    }),
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
})

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    setSorter,
    setSelection,
} = summaryInvoiceSlice.actions

export default summaryInvoiceSlice.reducer
