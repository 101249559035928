import React from 'react'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { DescriptionIcon, SubdirectoryArrowRightIcon } from 'components/InsIcon'

const renderExtItem = (itemValue, label, clfValue) => {
    return (
        <>
            {itemValue && (
                <div className="p-ext-item">
                    <span>{`${label}：`}</span>
                    <span className="p-bold">
                        {clfValue !== undefined ? getClassificationItemName(clfValue, itemValue) : itemValue}
                    </span>
                </div>
            )}
        </>
    )
}

export const renderInstructionExtendRow = (id, instructionDetails) => {
    const d = instructionDetails.find(d => d.id === id)
    if (!d || !d.id) {
        return null
    }
    return (
        <div className="p-table-2 print-opt-invoice" style={{ width: '100%' }}>
            <div className="with-dashed">
                <SubdirectoryArrowRightIcon size={16} className="p-sub-dir" />
                <div className="p-extends">
                    {renderExtItem(d.lam_thickness, 'ラミ厚', ClassificationTypeEnum.LAMINATED_PRODUCT_THICKNESS.value)}
                    {renderExtItem(d.mimi, 'ﾐﾐ幅', ClassificationTypeEnum.PROCESSED_PRODUCT_MIMI.value)}
                    {renderExtItem(d.rounded_corners, '角丸')}
                    {renderExtItem(d.suji_oshi, 'ｽｼﾞ押し')}
                    {renderExtItem(d.cutting, '断裁')}
                    {renderExtItem(
                        d.media,
                        'メディア',
                        d.processed_product_classification === '3'
                            ? ClassificationTypeEnum.LARGE_FORMAT_PROCESSED_MEDIA.value
                            : d.processed_product_classification === '4'
                            ? ClassificationTypeEnum.SOLVENT_PROCESSED_MEDIA.value
                            : d.processed_product_classification === '5'
                            ? ClassificationTypeEnum.PANEL_PROCESSED_MEDIA.value
                            : undefined
                    )}
                    {renderExtItem(d.printing, '印刷', ClassificationTypeEnum.WORKPIECE_PRINTING.value)}
                    {renderExtItem(d.printing_paper, '用紙', ClassificationTypeEnum.WORKPIECE_PAPER.value)}
                    {renderExtItem(d.printing_form, '形', ClassificationTypeEnum.WORKPIECE_SHAPE.value)}
                    {renderExtItem(d.cold, 'ゴールド', ClassificationTypeEnum.PROCESSED_GOLD.value)}
                    {renderExtItem(d.oberami, 'オーパーラミ', ClassificationTypeEnum.WORKPIECE_OVER_LAMINATION.value)}
                    {renderExtItem(
                        d.panel_thickness,
                        'パネル厚',
                        ClassificationTypeEnum.WORKPIECE_PANEL_THICKNESS.value
                    )}
                    {renderExtItem(
                        d.product_name,
                        '商品名',
                        d.processed_product_classification === '1'
                            ? ClassificationTypeEnum.CLIMBING_PRODUCT_NAME_CATEGORY.value
                            : d.processed_product_classification === '2'
                            ? ClassificationTypeEnum.CHAIR_COVER_PRODUCT_NAME_CATEGORY.value
                            : d.processed_product_classification === '3'
                            ? ClassificationTypeEnum.TRIANGLE_FLAG_PRODUCT_NAME_CATEGORY.value
                            : undefined
                    )}
                    {renderExtItem(d.material, '素材', ClassificationTypeEnum.PROCESSED_MATERIAL.value)}
                    {renderExtItem(d.chichi, 'チチ', ClassificationTypeEnum.PROCESSED_PRODUCT_CLASS.value)}
                    {renderExtItem(d.heat_cut, 'ヒートカット', ClassificationTypeEnum.PROCESSED_PRODUCT_HEAT_CUT.value)}
                    {renderExtItem(d.mimaki, '三巻', ClassificationTypeEnum.THREE_PIECE_WORKPIECE.value)}
                    {renderExtItem(d.bias, 'バイアス')}
                    {renderExtItem(d.back, '背面')}
                    {renderExtItem(d.pocket, 'ポケット', ClassificationTypeEnum.WORKPIECE_POCKET.value)}
                    {renderExtItem(d.bag_stopper, '袋止め', ClassificationTypeEnum.WORKPIECE_BAG_STOP.value)}
                    {renderExtItem(d.cap, 'キャップ', ClassificationTypeEnum.WORKPIECE_CAP.value)}
                    {renderExtItem(d.pole_material, 'ポール素材', ClassificationTypeEnum.WORKPIECE_POLE_MATERIAL.value)}
                    {renderExtItem(d.pole_length, 'ポール長さ', ClassificationTypeEnum.WORKPIECE_POLE_LENGTH.value)}
                </div>
            </div>
            <div className="p-note-wrap">
                <DescriptionIcon size={20} className="p-description-icon mr-8" />
                <span className="p-bold">{`メモ：`}</span>
                <span>{d.memo}</span>
            </div>
        </div>
    )
}
