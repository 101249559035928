import { createSlice } from '@reduxjs/toolkit'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'
import { setIsAdmin, setPermissions } from 'helpers/permission.helper'
import { startLoading, stopLoading } from 'modules/common/ui/ui.slice'
import Routes from 'routes'
import { setClassifications } from 'helpers/classification.helper'
import UserRoleEnum from 'enums/user_role.enum'

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
    },
    reducers: {
        setUser(state, action) {
            state.user = action.payload
        },
    },
})

export const { setUser } = authSlice.actions

export default authSlice.reducer

// Selectors
export const selectUsername = state => state.auth.user['username']
export const selectUserPicName = state => state.auth.user['pic_name']
export const selectUserPicNameKana = state => state.auth.user['pic_name_kana']

// Thunk action creators
export const login = (username, password) => async dispatch => {
    const data = await api.post(APP_URLS.LOGIN, { username, password })
    if (data) {
        localStorage.setItem('access_token', data.token)
        api.accessToken = data.token
        const { user, permissions, classifications } = data
        setIsAdmin(user.user_role === UserRoleEnum.ADMIN.value)
        setPermissions(permissions)
        setClassifications(classifications)
        dispatch(setUser(user))
        history.push(Routes.private.business.PROJECT.path)
    }
}

export const logout = () => async dispatch => {
    const data = await api.delete(APP_URLS.LOGOUT)
    if (data.isSuccess) {
        localStorage.removeItem('access_token')
        api.accessToken = null
        setPermissions([])
        dispatch({ type: 'CLEAR_ALL' })
        history.push(Routes.public.LOGIN.path)
        dispatch(stopLoading())
    }
}

export const checkAuth = () => async dispatch => {
    if (!api.accessToken) {
        dispatch(stopLoading())
        return
    }
    dispatch(startLoading())
    try {
        const data = await api.get(APP_URLS.AUTH, null, {}, true)
        if (data) {
            const { user, permissions, classifications } = data
            setIsAdmin(user.user_role === UserRoleEnum.ADMIN.value)
            setPermissions(permissions)
            setClassifications(classifications)
            dispatch(setUser(user))
        }
    } catch (e) {
        console.log('Unauthenticated')
    }
    dispatch(stopLoading())
}
