import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InsBackLink from 'components/InsBackLink'
import CustomerCreateComponent from 'modules/master/customer/CustomerCreateComponent'

const CustomerCreate = () => {
    const { t } = useTranslation()

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} create', { entity: t('entity:Customer') })}
            </h1>
            <CustomerCreateComponent />
        </div>
    )
}

export default CustomerCreate

CustomerCreate.propTypes = {
    match: PropTypes.object,
}
