export const UserSettingProjectEnum = Object.freeze({
    //default
    PROJECT_CODE: { value: 'project_code', text: '案件番号' },
    STATUS: { value: 'status', text: 'ステータス' },
    PROJECT_NAME: { value: 'project_name', text: '件名' },
    CREATED_AT: { value: 'created_at', text: '作成日' },
    CREATED_BY: { value: 'created_by', text: '作成者' },
    BILLING_AMOUNT: { value: 'billing_amount', text: '請求額' },
    //end default
    // CUSTOMER_ID: { value: 'customer_id', text: '顧客名ID' },
    CUSTOMER_CODE: { value: 'customer_code', text: '顧客コード' },
    // PROJECT_ID: { value: 'id', text: 'ID' },
    NOTE: { value: 'note', text: 'メモ' },
    IS_PURCHASE_ORDER: { value: 'is_purchase_order', text: '仕入発注かどうか' },
    UPDATED_AT: { value: 'updated_at', text: '更新日' },
    UPDATED_BY: { value: 'updated_by', text: '更新者' },
    CUSTOMER: { value: 'customer', text: '顧客名' },
    PIC_NAME: { value: 'pic', text: '担当者名' },
    SALE_AMOUNT: { value: 'sale_amount', text: '売上額' },
})

export const UserSettingEstimationEnum = Object.freeze({
    ID: { value: 'id', text: 'ID' },
    ESTIMATION_CODE: { value: 'estimation_code', text: '見積番号' },
    ESTIMATION_NAME: { value: 'estimation_name', text: '件名' },
    CUSTOMER_PIC_NAME: { value: 'customer_pic_name', text: '顧客名' },
    STATUS: { value: 'status', text: 'ステータス' },
    ESTIMATION_STATUS: { value: 'estimation_status', text: '見積のステータス' },
    ISSUED_DATE: { value: 'issued_date', text: '発行日' },
    DELIVERY_DATE: { value: 'delivery_date_text', text: '納期' },
    TOTAL: { value: 'total', text: '請求額' }, //Amount of money 金額

    //extends
    PROJECT_ID: { value: 'project_id', text: '案件ID' },
    ESTIMATION_CLASSIFICATION: { value: 'estimation_classification', text: '見積区分' },
    PROJECT_CODE: { value: 'project_code', text: '案件番号' },
})

export const UserSettingInstructionEnum = Object.freeze({
    INSTRUCTION_CODE: { value: 'instruction_code', text: '作業指示書番号' },
    INSTRUCTION_CLASSIFICATION: { value: 'instruction_classification', text: '区分' },
    CUSTOMER_PIC_NAME: { value: 'customer_pic_name', text: '顧客担当者' },
    DELIVERY_DATE: { value: 'delivery_date', text: '納期' },
    CREATED_BY: { value: 'created_by', text: '作成者' },
    TOTAL: { value: 'total', text: '請求額' },
    STATUS: { value: 'status', text: 'ステータス' },
    INSTRUCTION_STATUS: { value: 'instruction_status', text: '作業指示書のステータス' },
})

export const UserSettingPlaceOrderEnum = Object.freeze({
    PLACE_ORDER_CODE: { value: 'place_order_code', text: '発注番号' },
    PLACE_ORDER_CLASSIFICATION: { value: 'place_order_classification', text: '区分' },
    // CUSTOMER_NAME: { value: 'customer_name', text: '顧客名' },
    PLACE_ORDER_NAME: { value: 'place_order_name', text: '件名' },
    SUPPLIER_PIC_NAME: { value: 'supplier_pic_name', text: '発注先名' },
    DELIVERY_DATE: { value: 'delivery_date', text: '納期' },
    TOTAL: { value: 'total', text: '請求額' },
    STATUS: { value: 'status', text: 'ステータス' },
    PLACE_ORDER_STATUS: { value: 'place_order_status', text: '発注のステータス' },
})

export const UserSettingSaleEnum = Object.freeze({
    SALE_CODE: { value: 'sale_code', text: '納品番号' },
    CUSTOMER_NAME: { value: 'customer_name', text: '顧客名' }, //extend
    SALE_NAME: { value: 'sale_name', text: '販売名' },
    DELIVERY_DATE: { value: 'delivery_date', text: '納品日' },
    TOTAL: { value: 'total', text: '請求額' },
    STATUS: { value: 'status', text: 'ステータス' },
    SALE_STATUS: { value: 'sale_status', text: '納品のステータス' },
})

// export const UserSettingDeliveryEnum = Object.freeze({
//     PROJECT_CODE: { value: 'project_code', text: '案件番号' },
//     STATUS: { value: 'status', text: 'ステータス' },
//     DELIVERY_NAME: { value: 'delivery_name', text: '件名' },
//     DATE_OF_ISSUE: { value: 'date_of_issue', text: '発行日' },
//     CREATED_BY: { value: 'created_by', text: '作成者' },
//     TOTAL: { value: 'total', text: '請求額' },
// })

export const UserSettingRedSlipEnum = Object.freeze({
    ID: { value: 'id', text: 'ID' },
    PROJECT_ID: { value: 'project_id', text: '案件ID' },
    RED_SLIP_CODE: { value: 'red_slip_code', text: '赤伝番号' },
    RED_SLIP_ISSUED_DATE: { value: 'red_slip_issued_date', text: '赤発行日' },
    STATUS: { value: 'status', text: 'ステータス' },
    RED_SLIP_STATUS: { value: 'red_slip_status', text: '赤伝のステータス' },
    CREATED_AT: { value: 'created_at', text: '作成日' },
    // CREATED_BY: { value: 'created_by', text: '作成者' },
    UPDATED_BY: { value: 'updated_by', text: '更新者' },
    UPDATED_AT: { value: 'updated_at', text: '更新日' },
    CUSTOMER_NAME: { value: 'customer_name', text: '顧客名' }, // ext
    PIC_NAME: { value: 'pic_name', text: '担当者名' }, // ext
})
export const UserSettingInvoiceEnum = Object.freeze({
    INVOICE_CODE: { value: 'invoice_code', text: '請求コード' },
    CUSTOMER_NAME: { value: 'customer_name', text: '顧客名' }, //extend
    INVOICE_NAME: { value: 'invoice_name', text: '件名' },
    DELIVERY_DATE: { value: 'delivery_date', text: '納品日' },
    TOTAL: { value: 'total', text: '請求額' },
    STATUS: { value: 'status', text: 'ステータス' },
})

export const UserSettingSummaryInvoiceEnum = Object.freeze({
    CUSTOMER_NAME: { value: 'customer_name', text: '顧客名' },
    BILL_DATE_CLASSIFICATION: { value: 'bill_date_classification', text: '締め区分' },
    TOTAL: { value: 'total', text: '小計' },
})

export const UserSettingSummaryInvoiceHistoryEnum = Object.freeze({
    INVOICE_CODE: { value: 'invoice_code', text: '請求書 ID' },
    CUSTOMER_NAME: { value: 'customer_name', text: '請求先名' },
    ISSUE_DATE: { value: 'issue_date', text: '発行日' },
    PIC_NAME: { value: 'pic_name', text: '発行者' },
    ADJUST_AMOUNT: { value: 'adjust_amount', text: '調整額' },
    TOTAL: { value: 'total', text: '小計' },
})
