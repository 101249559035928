import PropTypes from 'prop-types'
import React from 'react'
import { DatePicker } from 'antd'

const InsCustomDatePicker = ({ className, children, format = 'YYYY/MM/DD', ...rest }) => {
    let pickerClassName = 'ins-custom-date-picker '
    if (className) {
        pickerClassName += className
    }
    return <DatePicker {...rest} className={pickerClassName} format={format} suffixIcon={children} allowClear={false} />
}

export default InsCustomDatePicker

InsCustomDatePicker.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    format: PropTypes.string,
}

InsCustomDatePicker.defaultProps = {
    format: 'YYYY/MM/DD',
}
