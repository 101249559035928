import React from 'react'
import { Col, Row } from 'antd'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import InsCustomDatePicker from 'components/datepicker/InsCustomDatePicker'
import InsButton from 'components/InsButton'
import { ArrowDropdownIcon, CalendarTodayIcon } from 'components/InsIcon'

const DatePickerDemo = () => {
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">Datepicker Demo</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsDateRangePicker />
                </Col>
                <Col>
                    <InsDatePicker />
                </Col>
                <Col>
                    <InsCustomDatePicker onChange={(d, s) => console.log(d, s)}>
                        <InsButton buttonStyle="default" icon={<CalendarTodayIcon size={16} />}>
                            Button date picker
                        </InsButton>
                    </InsCustomDatePicker>
                </Col>
                <Col>
                    <InsCustomDatePicker>
                        <InsButton buttonStyle="plain" icon={<ArrowDropdownIcon size={24} />}>
                            Link date picker
                        </InsButton>
                    </InsCustomDatePicker>
                </Col>
            </Row>
        </div>
    )
}

export default DatePickerDemo
