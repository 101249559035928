import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown } from 'antd'
import { CommentIcon } from 'components/InsIcon'
import { isEmpty } from 'helpers/string.helper'

const InsNoteAffix = ({ className, onChange, children, note, showCorner, ...rest }) => {
    const isShowCorner = showCorner && !isEmpty(note)
    return (
        <Dropdown
            overlayClassName="custom-tooltip-overlay"
            overlay={
                isShowCorner ? (
                    <div className="tooltip-comment">
                        <CommentIcon className="color-blue-gray-200" />
                        <span className="comment">{note}</span>
                    </div>
                ) : (
                    <></>
                )
            }
            placement="bottomRight"
            trigger={['click']}
            getPopupContainer={triggerNode => {
                return triggerNode.parentNode
            }}
        >
            <div className={`${isShowCorner ? 'comment-corner' : ''}`}>
                <span>{children}</span>
            </div>
        </Dropdown>
    )
}

export default InsNoteAffix

InsNoteAffix.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    note: PropTypes.any,
}

InsNoteAffix.defaultProps = { showCorner: true }
