import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import Routes from 'routes'
import { getEnumManyText, getEnumText } from 'helpers/enum.helper'
import {
    SalePermissionEnum,
    EstimationPermissionEnum,
    InstructionPermissionEnum,
    // TrackingPermissionEnum,
    PlaceOrderPermissionEnum,
    ProjectPermissionEnum,
    ReportPermissionEnum,
    InvoicePermissionEnum,
    RedSlipPermissionEnum,
    SummaryInvoicePermissionEnum,
    ShipmentPermissionEnum,
    PaymentPermissionEnum,
} from 'enums/permission.enum'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import InsHelpText from 'components/InsHelpText'
import { InsBlockLoader } from 'components/InsLoader'
import { BackIcon, DeleteIcon, EditIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsDataTable from 'components/datatable/InsDataTable'
import { getUserSettingEnumObject } from 'helpers/user_setting.helper'
import UserRoleEnum from 'enums/user_role.enum'
import { checkAdmin } from 'helpers/permission.helper'
import history from 'helpers/history.helper'

const UserDetailComponent = ({ inModal = false, id, onDelete, isAccountSetting }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [userSettingDataTable, setUserSettingDataTable] = useState(null)
    const isAdmin = checkAdmin()
    useEffect(() => {
        api.get(APP_URLS.USERS_DETAIL.replace(':id', id)).then(data => {
            if (data) {
                setUser(data)
                let userSettings = {}
                if (data.user_setting) {
                    userSettings = Object.assign({}, JSON.parse(data.user_setting))[0]
                    delete userSettings['deliveries']
                } else
                    userSettings = {
                        projects: [],
                        estimations: [],
                        instructions: [],
                        place_orders: [],
                        sales: [],
                        // deliveries: [],
                        red_slips: [],
                        summary_invoices: [],
                        history_summary_invoices: [],
                    }
                let dataTable = Object.keys(userSettings).map(keyObject => ({
                    name: keyObject,
                    setting_text: getEnumManyText(getUserSettingEnumObject(keyObject), userSettings[keyObject]),
                    setting_value: userSettings[keyObject],
                }))
                setUserSettingDataTable(dataTable)
                setLoading(false)
            }
        })
    }, [id])

    if (loading) {
        return <InsBlockLoader />
    }

    const renderPermission = authorities => {
        const rows = [
            {
                label: t('column:Project'),
                permsEnum: [ProjectPermissionEnum.PROJECT_READ_ONLY, ProjectPermissionEnum.PROJECT_EDITABLE],
                isBold: true,
            },
            {
                label: t('column:Estimation'),
                permsEnum: [
                    EstimationPermissionEnum.ESTIMATION_READ_ONLY,
                    EstimationPermissionEnum.ESTIMATION_EDITABLE,
                ],
                isBold: true,
            },
            {
                label: t('column:Work instruction'),
                permsEnum: [
                    InstructionPermissionEnum.INSTRUCTION_READ_ONLY,
                    InstructionPermissionEnum.INSTRUCTION_EDITABLE,
                ],
                isBold: true,
            },
            {
                label: t('column:Place order'),
                permsEnum: [
                    PlaceOrderPermissionEnum.PLACE_ORDER_READ_ONLY,
                    PlaceOrderPermissionEnum.PLACE_ORDER_EDITABLE,
                ],
                isBold: true,
            },
            {
                label: t('column:Sale'),
                permsEnum: [SalePermissionEnum.SALE_READ_ONLY, SalePermissionEnum.SALE_EDITABLE],
                isBold: true,
            },
            // {
            //     label: t('column:Tracking'),
            //     permsEnum: [TrackingPermissionEnum.TRACKING_READ_ONLY, TrackingPermissionEnum.TRACKING_EDITABLE],
            //     isBold: true,
            // },
            {
                label: t('column:Invoice'),
                permsEnum: [InvoicePermissionEnum.INVOICE_READ_ONLY, InvoicePermissionEnum.INVOICE_EDITABLE],
                isBold: true,
            },
            {
                label: t('column:Report'),
                permsEnum: [
                    ReportPermissionEnum.REPORT_READ_ONLY,
                    ReportPermissionEnum.REPORT_EDITABLE,
                    ReportPermissionEnum.REPORT_HIDDEN,
                ],
                isBold: true,
            },
            {
                label: t('column:Red slip'),
                permsEnum: [RedSlipPermissionEnum.RED_SLIP_READ_ONLY, RedSlipPermissionEnum.RED_SLIP_EDITABLE],
                isBold: true,
            },
            {
                label: t('column:Summary invoice'),
                permsEnum: [
                    SummaryInvoicePermissionEnum.SUMMARY_INVOICE_READ_ONLY,
                    SummaryInvoicePermissionEnum.SUMMARY_INVOICE_EDITABLE,
                ],
                isBold: true,
            },
            {
                label: t('column:Shipment'),
                permsEnum: [ShipmentPermissionEnum.SHIPMENT_READ_ONLY, ShipmentPermissionEnum.SHIPMENT_EDITABLE],
                isBold: true,
            },
            {
                label: t('column:Payment'),
                permsEnum: [
                    PaymentPermissionEnum.PAYMENT_READ_ONLY,
                    PaymentPermissionEnum.PAYMENT_EDITABLE,
                    PaymentPermissionEnum.PAYMENT_HIDDEN,
                ],
                isBold: true,
            },
        ]

        return rows.map(row => (
            <tr>
                <th>{row.label}</th>
                <td className={row.isBold ? 'fw-bold color-blue-gray-900' : null}>
                    {renderEachPermission(row, authorities)}
                </td>
            </tr>
        ))
    }

    const renderEachPermission = (row, authorities) => {
        let isHidden = true
        return row.permsEnum.map(permEnum => (
            <>
                {authorities.map(authority => {
                    if (authority.authority_name === permEnum.value) {
                        isHidden = false
                        return <>{permEnum.text}</>
                    }

                    return null
                })}
            </>
        ))
    }

    const userSettingColumns = [
        {
            title: '',
            width: 140,
            dataIndex: 'name',
            render: text => {
                return <div>{t(text)}</div>
            },
        },
        {
            title: '',
            sorter: true,
            dataIndex: 'setting_text',
            render: (text, r) => {
                return (
                    <Row justify="space-between" align="middle">
                        <Col>
                            {r.setting_text.map(setting => {
                                return <span className="row-detail-item">{setting}</span>
                            })}
                        </Col>
                    </Row>
                )
            },
        },
    ]

    return (
        <div className="user-detail">
            {!inModal && (
                <InsFloatBar visible={true}>
                    <Row justify="space-between">
                        <Row align="middle">
                            {!isAccountSetting && (
                                <>
                                    <Col>
                                        <InsButton
                                            width="76px"
                                            disabled={!user.canDelete}
                                            icon={<DeleteIcon size={16} />}
                                            onClick={() => onDelete()}
                                        >
                                            {t('common:Delete')}
                                        </InsButton>
                                    </Col>
                                    <Col>
                                        <InsHelpText className="ml-8">
                                            {t('Users in use cannot be deleted')}
                                        </InsHelpText>
                                    </Col>
                                </>
                            )}
                        </Row>

                        <Col>
                            <Row gutter={8} justify="end">
                                <Col>
                                    <InsButton
                                        className="fw-500"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                        buttonStyle="outline"
                                        buttonSize="big"
                                        width="110px"
                                        icon={<BackIcon />}
                                    >
                                        {t('common:Back to list')}
                                    </InsButton>
                                </Col>
                                <Col>
                                    <InsButton
                                        className="edit-user-btn"
                                        buttonType="link"
                                        linkTo={
                                            isAdmin
                                                ? Routes.private.master.USER_UPDATE.path.replace(':id', id)
                                                : Routes.private.master.ACCOUNT_SETTING_EDIT.path
                                        }
                                        buttonStyle="primary"
                                        buttonSize="big"
                                        width="155px"
                                        icon={<EditIcon size="20" />}
                                    >
                                        {t('Edit user information')}
                                    </InsButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </InsFloatBar>
            )}
            <div className={`${inModal ? '' : 'p-24'}`}>
                {!inModal && (
                    <h1 className="page-title-big mb-24 ins-flex flex-middle">
                        {isAccountSetting ? t('navigation:Account setting') : t(`common:User information`)}
                    </h1>
                )}
                <section>
                    <h2 className={`section-title ${inModal ? 'mt-0' : ''}`}>{t('common:Basic information')}</h2>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('column:Person code')}</th>
                                <td className="fw-bold color-blue-gray-900">{user.pic_code}</td>
                            </tr>
                            <tr>
                                <th>{t('column:Pic name')}</th>
                                <td className="fw-bold color-blue-gray-900">{user.pic_name}</td>
                            </tr>
                            <tr>
                                <th>{t('column:Pic name kana')}</th>
                                <td className="fw-bold color-blue-gray-900">{user.pic_name_kana}</td>
                            </tr>
                            <tr>
                                <th>{t('column:User role')}</th>
                                <td>{getEnumText(UserRoleEnum, user.user_role)}</td>
                            </tr>
                            <tr>
                                <th>{t('column:Team')}</th>
                                <td>{user.team ? user.team.team_name : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                {!inModal && (
                    <section>
                        <h2 className="section-title">{t('common:Permission setting')}</h2>
                        <table className="ins-info-table">
                            <tbody>{renderPermission(user.authorities)}</tbody>
                        </table>
                    </section>
                )}
                {!inModal && (
                    <div>
                        <h2 className="section-title">{t('common:User display settings')}</h2>
                        <InsDataTable
                            tableStyle="compact"
                            // loading={state.loading ? { indicator: <InsLoader /> } : false}
                            striped
                            columns={userSettingColumns}
                            data={userSettingDataTable}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserDetailComponent

UserDetailComponent.propTypes = {
    id: PropTypes.any.isRequired,
    inModal: PropTypes.bool,
    onDelete: PropTypes.func,
    isAccountSetting: PropTypes.bool,
}

UserDetailComponent.defaultProps = {
    inModal: false,
}
