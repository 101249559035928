import { createSlice } from '@reduxjs/toolkit'

const currentTime = new Date().getTime().toString()
const DESTINATION_UNKNOWN = 'destinationUnknown'

const defaultDestinations = {
    [`${currentTime}-destination`]: { isCollapse: false },
    destinationUnknown: { isUnknown: true },
}
const defaultDestinationsOrder = [`${currentTime}-destination`, DESTINATION_UNKNOWN]
const objectHeadline = { isHeadline: true }
const objectProduct = {
    title: '',
    spec: undefined,
    size: undefined,
    quantity: 1,
    unit: '2',
    unit_cost: 0.0,
    unit_price: 0,
    tax_rate: '10',
    amount: 0,
    note: null,
}
const defaultDetails = {
    [`${currentTime}-headline`]: objectHeadline,
    [`${currentTime}-product`]: objectProduct,
}
const defaultDetailsOrder = {
    [`${currentTime}-destination`]: [`${currentTime}-headline`, `${currentTime}-product`],
    [DESTINATION_UNKNOWN]: [],
}

const saleDetail = createSlice({
    name: 'saleDetail',
    initialState: {
        destinations: defaultDestinations,
        destinationsOrder: defaultDestinationsOrder,
        details: defaultDetails,
        detailsOrder: defaultDetailsOrder,
        changedData: false,
    },
    reducers: {
        addDestinations(state) {
            const currentTime = new Date().getTime().toString()
            const destinationId = `${currentTime}-destination`
            state.destinations[destinationId] = { isCollapse: false }
            state.destinationsOrder = [
                ...state.destinationsOrder.slice(0, state.destinationsOrder.length - 1),
                destinationId,
                DESTINATION_UNKNOWN,
            ]
            state.detailsOrder = {
                ...state.detailsOrder,
                [destinationId]: [...state.detailsOrder[DESTINATION_UNKNOWN]],
            }
            state.detailsOrder[DESTINATION_UNKNOWN] = []
            state.changedData = true
        },
        moveDestination(state, action) {
            const { srcIndex, desIndex } = action.payload
            if (srcIndex === desIndex) {
                return
            }
            let newOrder = [...state.destinationsOrder]
            reOrder(newOrder, srcIndex, desIndex)
            state.destinationsOrder = newOrder
            state.changedData = true
        },
        updateDestination(state, action) {
            state.destinations = {
                ...state.destinations,
                [action.payload.id]: {
                    ...state.destinations[action.payload.id],
                    ...action.payload.value,
                },
            }
            state.changedData = true
        },
        removeDestination(state, action) {
            if (state.directDeliveryType === 2 && state.destinationsOrder.length <= 2) {
                return
            }
            let newDestinations = { ...state.destinations }
            delete newDestinations[action.payload.id]
            state.destinations = newDestinations
            state.destinationsOrder = state.destinationsOrder.filter(d => d !== action.payload.id)
            const { [action.payload.id]: value, ...newDetailsOrder } = state.detailsOrder
            if (state.destinationsOrder.length > 1) {
                const detailsOrderArrayKeys = Object.keys(newDetailsOrder)
                detailsOrderArrayKeys.splice(detailsOrderArrayKeys.indexOf(DESTINATION_UNKNOWN), 1)
                const lastKeyDetail = detailsOrderArrayKeys.pop()
                state.detailsOrder = {
                    ...newDetailsOrder,
                    [lastKeyDetail]: [...newDetailsOrder[lastKeyDetail], ...value],
                }
            } else {
                state.detailsOrder = {
                    ...newDetailsOrder,
                    [DESTINATION_UNKNOWN]: [...state.detailsOrder[DESTINATION_UNKNOWN], ...value],
                }
            }
            state.changedData = true
        },

        addDetail(state, action) {
            const isHeadline = action.payload.isHeadline
            const currentTime = new Date().getTime().toString()
            const detailId = isHeadline ? `${currentTime}-headline` : `${currentTime}-product`
            if (isHeadline) {
                state.details[detailId] = Object.assign({ isHeadline: isHeadline }, objectHeadline)
            } else {
                state.details[detailId] = Object.assign({ isHeadline: isHeadline }, objectProduct)
            }
            if (state.destinationsOrder.length > 1) {
                const detailsOrderArrayKeys = Object.keys(state.detailsOrder)
                detailsOrderArrayKeys.splice(detailsOrderArrayKeys.indexOf(DESTINATION_UNKNOWN), 1)
                const lastKeyDetail = detailsOrderArrayKeys.pop()
                state.detailsOrder = {
                    ...state.detailsOrder,
                    [lastKeyDetail]: [...state.detailsOrder[lastKeyDetail], detailId],
                }
            } else {
                state.detailsOrder = {
                    ...state.detailsOrder,
                    [DESTINATION_UNKNOWN]: [...state.detailsOrder[DESTINATION_UNKNOWN], detailId],
                }
            }
            state.changedData = true
        },
        moveDetail(state, action) {
            const { srcId, desId, srcIndex, desIndex } = action.payload
            if (srcId === desId && srcIndex === desIndex) {
                return
            }
            if (srcId === desId) {
                if (srcIndex === desIndex) return
                let tmpOrder = [...state.detailsOrder[srcId]]
                reOrder(tmpOrder, srcIndex, desIndex)
                state.detailsOrder = {
                    ...state.detailsOrder,
                    [srcId]: tmpOrder,
                }
            } else {
                let srcOrder = [...state.detailsOrder[srcId]]
                let desOrder = [...state.detailsOrder[desId]]
                const [detailId] = srcOrder.splice(srcIndex, 1)
                desOrder.splice(desIndex, 0, detailId)
                state.detailsOrder = {
                    ...state.detailsOrder,
                    [srcId]: srcOrder,
                    [desId]: desOrder,
                }
            }
            state.changedData = true
        },
        updateDetail(state, action) {
            const { id, value } = action.payload
            state.details = {
                ...state.details,
                [id]: {
                    ...state.details[id],
                    ...value,
                },
            }
            state.changedData = true
        },
        removeDetail(state, action) {
            const { destinationId, detailId } = action.payload
            let newDetails = { ...state.details }
            delete newDetails[detailId]
            state.details = newDetails

            let newOrder = [...state.detailsOrder[destinationId]].filter(id => id !== detailId)
            state.detailsOrder = {
                ...state.detailsOrder,
                [destinationId]: newOrder,
            }
            state.changedData = true
        },

        setDestinations(state, action) {
            state.destinations = action.payload
        },

        setDestinationsOrder(state, action) {
            state.destinationsOrder = action.payload
        },

        setDetails(state, action) {
            state.details = action.payload
        },

        setDetailsOrder(state, action) {
            state.detailsOrder = action.payload
        },

        setDefaultState(state) {
            state.destinations = defaultDestinations
            state.destinationsOrder = defaultDestinationsOrder
            state.details = defaultDetails
            state.detailsOrder = defaultDetailsOrder
            state.changedData = false
        },

        setSaleClassification(state, action) {
            state.saleClassification = action.payload
        },

        setDeliveryDestinationType(state, action) {
            state.deliveryDestinationType = action.payload.deliveryDestinationType
            state.directDeliveryType = action.payload.directDeliveryType
            if (state.destinationsOrder[0] === DESTINATION_UNKNOWN) {
                const destinationId = `${currentTime}-destination`
                state.destinations[destinationId] = {}
                state.destinationsOrder.unshift(destinationId)
                state.detailsOrder[destinationId] = state.detailsOrder[DESTINATION_UNKNOWN]
                state.detailsOrder[DESTINATION_UNKNOWN] = []
            }
        },
        setCollapse(state, action) {
            const destinationId = action.payload
            const destination = state.destinations[destinationId]
            state.destinations = {
                ...state.destinations,
                [destinationId]: { ...destination, isCollapse: !destination.isCollapse },
            }
        },
        setData(state, action) {
            const { destinations, details } = action.payload
            let parseDestinations = {}
            let parseDestinationsOrder = []
            let parseDetails = {}
            let parseDetailsOrder = {}

            // parse no destination
            if (destinations.length === 0) {
                parseDestinations[DESTINATION_UNKNOWN] = { isUnknown: true }
                parseDestinationsOrder = [DESTINATION_UNKNOWN]
                parseDetailsOrder[DESTINATION_UNKNOWN] = []
                details.map(d => {
                    const detailName = `${d.id}-${d.headline ? 'headline' : 'product'}`
                    parseDetails[detailName] = Object.assign({}, d, { isHeadline: d.headline })
                    parseDetailsOrder[DESTINATION_UNKNOWN].push(detailName)
                    return null
                })
            } else {
                // with destination
                destinations.map(des => {
                    const destinationName = `${des.id}-destination`
                    parseDestinations[destinationName] = { ...des, isCollapse: false }
                    parseDestinationsOrder.push(destinationName)
                    parseDetailsOrder[destinationName] = []
                    des.details.map(d => {
                        const detailName = `${d.id}-${d.headline ? 'headline' : 'product'}`
                        parseDetails[detailName] = Object.assign({}, d, { isHeadline: d.headline })
                        parseDetailsOrder[destinationName].push(detailName)
                        return null
                    })
                    return null
                })
                parseDestinations[DESTINATION_UNKNOWN] = { isUnknown: true }
                parseDestinationsOrder.push(DESTINATION_UNKNOWN)
                parseDetailsOrder[DESTINATION_UNKNOWN] = []
            }
            state.destinations = parseDestinations
            state.destinationsOrder = parseDestinationsOrder
            state.details = parseDetails
            state.detailsOrder = parseDetailsOrder
        },
    },
})

export default saleDetail.reducer
export const {
    addDestinations,
    moveDestination,
    updateDestination,
    removeDestination,

    addDetail,
    moveDetail,
    updateDetail,
    removeDetail,

    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,

    setDefaultState,

    setSaleClassification,
    setDeliveryDestinationType,
    setData,
    setCollapse,
} = saleDetail.actions

const reOrder = (list, srcIndex, desIndex) => {
    const [removed] = list.splice(srcIndex, 1)
    list.splice(desIndex, 0, removed)
}
