import {
    UserSettingProjectEnum,
    UserSettingEstimationEnum,
    UserSettingInstructionEnum,
    UserSettingPlaceOrderEnum,
    // UserSettingDeliveryEnum,
    UserSettingSaleEnum,
    UserSettingRedSlipEnum,
    UserSettingSummaryInvoiceEnum,
    UserSettingSummaryInvoiceHistoryEnum,
} from 'enums/user_setting.enum'

export const userSettingColumns = (userSettings, documentKey, implFullColumns, defaultColumns = []) => {
    const parseUserSettings = JSON.parse(userSettings)
    const hasUserSettings =
        parseUserSettings &&
        parseUserSettings[0] &&
        parseUserSettings[0][documentKey] &&
        parseUserSettings[0][documentKey].length
    const columns = hasUserSettings ? parseUserSettings[0][documentKey] : defaultColumns
    let implColumns = []
    columns.map(col => {
        const tmp = implFullColumns.find(impl => impl.dataIndex === col)
        if (tmp) {
            implColumns.push(tmp)
        }
    })
    return implColumns
}

export const getUserSettingEnumObject = keyword => {
    switch (keyword) {
        case 'projects':
            return UserSettingProjectEnum
        case 'estimations':
            return UserSettingEstimationEnum
        case 'instructions':
            return UserSettingInstructionEnum
        case 'place_orders':
            return UserSettingPlaceOrderEnum
        // case 'deliveries':
        //     return UserSettingDeliveryEnum
        case 'red_slips':
            return UserSettingRedSlipEnum
        case 'sales':
            return UserSettingSaleEnum
        case 'summary_invoices':
            return UserSettingSummaryInvoiceEnum
        case 'history_summary_invoices':
            return UserSettingSummaryInvoiceHistoryEnum

        default:
            return null
    }
}

export const getUserSettingEnumKeyObject = object => {
    switch (object) {
        case UserSettingProjectEnum:
            return 'projects'
        case UserSettingEstimationEnum:
            return 'estimations'
        case UserSettingInstructionEnum:
            return 'instructions'
        case UserSettingPlaceOrderEnum:
            return 'place_orders'
        // case UserSettingDeliveryEnum:
        //     return 'deliveries'
        case UserSettingRedSlipEnum:
            return 'red_slips'
        case UserSettingSaleEnum:
            return 'sales'
        case UserSettingSummaryInvoiceEnum:
            return 'summary_invoices'
        case UserSettingSummaryInvoiceHistoryEnum:
            return 'history_summary_invoices'

        default:
            return null
    }
}
