import React from 'react'
import PropTypes from 'prop-types'
import ExcelFile from 'react-data-export/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-data-export/dist/ExcelPlugin/elements/ExcelSheet'
import { formatNumberExport } from 'helpers/number.helper'

const ReportExport = ({
    data = {},
    element,
    fileName,
    items,
    info,
    expandedSorter,
    isYearOnYearInclude,
    renderYearOnYearCompareForExport,
}) => {
    const thin = () => {
        return { style: 'thin' }
    }

    const borderExclude = pos => {
        if (pos === 'left') {
            return { border: { top: thin(), bottom: thin(), right: thin() } }
        } else if (pos === 'right') {
            return { border: { top: thin(), bottom: thin(), left: thin() } }
        } else if (pos === 'top') {
            return { border: { bottom: thin(), left: thin(), right: thin() } }
        } else if (pos === 'top-bottom') {
            return { border: { left: thin(), right: thin() } }
        } else if (pos === 'left-right') {
            return { border: { top: thin(), bottom: thin() } }
        }
        return { border: { top: thin(), bottom: thin(), left: thin(), right: thin() } }
    }

    const bold = () => {
        return { font: { bold: true } }
    }

    const bgHeader = argb => {
        return { fill: { patternType: 'solid', fgColor: { rgb: argb || 'ECEFF1' } } }
    }

    let columns = [
        {
            title: info['group_1_label'],
            width: { wpx: 125 },
            style: { ...borderExclude(), ...bold(), ...bgHeader() },
        },
        { title: info['group_2_label'], width: { wpx: 200 }, style: { ...borderExclude(), ...bold(), ...bgHeader() } },
        ...(data.metric_range || []).map(r => ({
            title: r,
            width: { wpx: 100 },
            style: { ...borderExclude(), ...bold(), ...bgHeader() },
        })),
    ]

    const processGroup2Sorter = item => {
        const expSorter = expandedSorter && expandedSorter[item.group_1_id]
        if (expSorter && expSorter.order === 'ascend') {
            // non-deep copy
            return [...item.group_2_items].sort((a, b) => {
                const field = expSorter.field
                if (!a['metric_data'][field] && !b['metric_data'][field]) return 0
                return (a['metric_data'][field] || 0) > (b['metric_data'][field] || 0) ? 1 : -1
            })
        }
        return item.group_2_items
    }

    let unGroupItems = []
    items.map(item => {
        let line = [
            {
                value: item['group_1_text'],
                style: { ...borderExclude('right'), ...bgHeader('F6F7F8') },
            },
            { value: '', style: { ...borderExclude('left'), ...bgHeader('F6F7F8') } },
            ...(data.metric_range || []).map(r => ({
                value: isYearOnYearInclude
                    ? `${formatNumberExport(item['group_1_metric_data'][r]) || 0}${renderYearOnYearCompareForExport(
                          item,
                          r,
                          false
                      )}`
                    : formatNumberExport(item['group_1_metric_data'][r]) || 0,
                style: { ...borderExclude(), ...bgHeader('F6F7F8') },
            })),
        ]
        unGroupItems.push(line)

        processGroup2Sorter(item).map((g, index) => {
            let subLine = [
                { value: '', style: borderExclude(index === item.group_2_items.length - 1 ? 'top' : 'top-bottom') },
                { value: g['group_2_text'], style: borderExclude() },
                ...(data.metric_range || []).map(r => ({
                    value: isYearOnYearInclude
                        ? `${formatNumberExport(g['metric_data'][r]) || 0}${renderYearOnYearCompareForExport(
                              g,
                              r,
                              true
                          )}`
                        : formatNumberExport(g['metric_data'][r]) || 0,
                    style: borderExclude(),
                })),
            ]
            unGroupItems.push(subLine)
        })
    })
    const dataSet = [
        {
            columns: [{ title: 'レポート' }],
            data: [
                [
                    {
                        value: `区分１: ${info['group_1_label']}`,
                        style: { ...borderExclude('right'), ...bgHeader('F6F7F8') },
                    },
                    {
                        value: `区分２: ${info['group_2_label']}`,
                        style: { ...borderExclude('left-right'), ...bgHeader('F6F7F8') },
                    },
                    { value: `${info['metricLabel']}`, style: { ...borderExclude('left'), ...bgHeader('F6F7F8') } },
                ],
                [{ value: '' }],
            ],
        },
        {
            //xSteps: 1,
            columns: columns,
            data: unGroupItems,
        },
    ]

    return (
        <>
            <ExcelFile filename={fileName} element={element}>
                <ExcelSheet dataSet={dataSet} name="reports" />
            </ExcelFile>
        </>
    )
}

export default ReportExport

ReportExport.propTypes = {
    data: PropTypes.any,
    element: PropTypes.any,
    items: PropTypes.array,
    info: PropTypes.object.isRequired,
    fileName: PropTypes.string,
    expandedSorter: PropTypes.object,
    isYearOnYearInclude: PropTypes.bool,
    renderYearOnYearCompareForExport: PropTypes.func,
}
