import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Select } from 'antd'
import InsInput from 'components/InsInput'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsSelect from 'components/select/InsSelect'
import { PreviewIcon } from 'components/InsIcon'
import { getClassificationItemName, getClassificationsByType } from 'helpers/classification.helper'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import UserSelect from 'modules/master/user/UserSelect'
import InsFloatBar from 'components/InsFloatBar'
import InsButton from 'components/InsButton'
import InsTextArea from 'components/InsTextArea'
import moment from 'moment'
import InsInputNumber from 'components/InsInputNumber'
import {
    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,
    setDefaultState,
    setSaleClassification,
    setDeliveryDestinationType,
    setData,
} from 'modules/business/sale/sale_detail.slice'
import SaleDragAndDrop from 'modules/business/sale/form/SaleDragAndDrop'
import SaleDetailAddButton from 'modules/business/sale/form/SaleDetailAddButton'
import { SENDER_CODE } from 'constants/classification.constant'
import history from 'helpers/history.helper'
import InsModal from 'components/InsModal'
import Routes from 'routes'
import CustomerSelect from 'modules/master/customer/CustomerSelect'
import InsAutoComplete from 'components/InsAutoComplete'
import { calculateInStock, calculateTotal } from 'helpers/calculate.helper'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import RouterPrompt from 'modules/common/ui/RouterPrompt'
import { useLocation } from 'react-router-dom'
import { isHeadline } from 'helpers/detail.helper'
import { showWarning } from 'helpers/notification.helper'
import SaleDOMPrint from 'modules/business/sale/SaleDOMPrint'
import StockListInsufficient from 'modules/master/stock/StockListInsufficient'

const SaleForm = ({
    saleClassification,
    isEdit,
    initialValues = {},
    onSubmit,
    project,
    destinations,
    destinationsOrder,
    details,
    detailsOrder,
    deliveryDestinationType,
    directDeliveryType,
    setDefaultState,
    setData,
    changedData,
    projectForm,
    projectCreateDoc,
}) => {
    const { t } = useTranslation()
    const [form] = projectForm ? [projectForm] : Form.useForm()
    const dispatch = useDispatch()
    const [modalSalePreview, setModalSalePreview] = useState(false)
    const [salePreviewModal, setSalePreviewModal] = useState({})
    const [firstRender, setFirstRender] = useState(true)
    const [isValueChange, setIsValueChange] = useState(!isEdit)
    const location = useLocation()
    const isCreateProject = location.pathname.includes(Routes.private.business.PROJECT_CREATE.path)
    const [companies, setCompanies] = useState([])
    const [oosProducts, setOosProducts] = useState({
        isOutOfStock: false,
        data: {},
    })
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    // parse data for update
    const parseDataForUpdate = () => {
        setDefaultState()
        if (initialValues.id) {
            const orgDestinations = initialValues.delivery_destinations
            const orgDetails = initialValues.details
            setData({
                destinations: orgDestinations,
                details: orgDetails,
            })
        }
    }

    const checkOutOfStock = async (details, values) => {
        const products = calculateInStock(details)
        let data = null
        let isOutOfStock = false
        if (products.length > 0) {
            data = await api.post(APP_URLS.STOCKS_CHECK_OUT_OF_STOCK, products)
            isOutOfStock = data.isOutOfStock && saleClassification === 2
            if (data) {
                setOosProducts({
                    isOutOfStock: isOutOfStock,
                    data: data.oosProducts,
                })
            }
        }
        if (!data || !isOutOfStock) processOnSubmit(values)
    }

    useEffect(() => {
        if (!projectCreateDoc) {
            parseDataForUpdate()
        }
    }, [project])

    useEffect(() => {
        parseDataForUpdate()
    }, [])

    useEffect(() => {
        if (isEdit && changedData) {
            setIsValueChange(true)
        }
    }, [changedData])

    const calculateSum = details => {
        const result = calculateTotal(details)
        setSalePreviewModal({
            ...salePreviewModal,
            ...result,
        })
        form.setFieldsValue(result)
    }

    // copy sender
    let useSenderCopy = false
    if (!isEdit) {
        if (initialValues.sender_id && initialValues.sender_id === project?.customer?.delivery_note_sender_code) {
            useSenderCopy = true
        } else {
            delete initialValues['sender_id']
            delete initialValues['sender_name']
            delete initialValues['sender_address']
        }
    }

    const DIRECT_DELIVERY = 1 // 1: 直送 - classification 391
    initialValues = Object.assign(
        {
            project_id: project.id,
            // outsource_id: initialValues.outsource.id,
            outsource_pic_title: 1,
            sale_code: project.project_code + '-SD-' + (project?.count_sale ?? '1'),
            sale_classification: 1, //isPurchaseOrder ? 3 : 1, // 400
            sale_name: project.project_name,
            delivery_destination: DIRECT_DELIVERY, // 1: 直送 - classification 391
            delivery_type: '1', // 392
            transport_type: '1', // 393
            sender_id: project?.customer?.delivery_note_sender_code || SENDER_CODE.DELIVERY_NOTE_DEFAULT,
            sender_name: project?.customer?.delivery_note_sender_name || '',
            sender_address: project?.customer?.delivery_note_sender_address || '',
            // delivery_destinations: [{}],
            delivery_time: 0,
            pic_id: project.pic_id,
            pic: project.pic,
            details: [],
            gross_profit: 0,
            subtotal: 0,
            consumption_tax: 0,
            customer_id: project?.customer?.id,
            customer: project?.customer,
            customer_pic_title: project?.customer?.customer_title,
            customer_pic_name: (project?.customer?.customer_pic || '').split(',')[0],
            total: 0,
        },
        initialValues
    )

    // clone - generate project_code -> sale_code
    useEffect(() => {
        if (!initialValues.sale_code.startsWith(project.project_code)) {
            form.setFieldsValue({ sale_code: project.project_code + '-SD-' + 1 })
        }
    }, [project.project_code])

    // set sale_classification via estimation
    if (initialValues.estimation_classification) {
        initialValues.sale_classification = initialValues.estimation_classification
    }
    // set sale_classification via place_order
    if (initialValues.place_order_classification) {
        initialValues.sale_classification = initialValues.place_order_classification
    }

    initialValues.delivery_destination = parseInt(initialValues?.delivery_destination ?? 0)
    const dateFormat = 'YYYY年M月DD日'
    const [customer, setCustomer] = useState((projectForm ? initialValues.sale_customer : initialValues.customer) || {})

    initialValues.issued_date = initialValues.issued_date ? moment(initialValues.issued_date, 'YYYY-MM-DD') : moment()
    initialValues.delivery_date = initialValues.delivery_date
        ? moment(initialValues.delivery_date, 'YYYY-MM-DD')
        : moment()

    const getCompanies = async () => {
        const data = await api.get(APP_URLS.COMPANIES)
        if (data && data.list) {
            setCompanies(data.list)
            // special
            if (!isEdit && initialValues.sender_id !== SENDER_CODE.DELIVERY_NOTE_OTHER) {
                // set title
                const company = getCompanyByCode(initialValues.sender_id, data.list)
                form.setFieldsValue({
                    sender_name: company.company_name,
                    sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
                })
            }
        }
    }

    const getCompanyByCode = (company_code, tmpCompanies) => {
        const filterCompanies = (tmpCompanies || companies).filter(c => c.company_code === company_code)
        return filterCompanies.length > 0 ? filterCompanies[0] : {}
    }

    const makeCompanyInfo = company => {
        let prefecture = getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, company.prefecture_code)
        return `〒${company.post_code}${prefecture}${company.address1}${company.address2 || ''}\nTEL：${
            company.phone_number
            // eslint-disable-next-line no-irregular-whitespace
        }　　FAX：${company.fax_number ? company.fax_number : ''}`
    }

    const handlerSenderIdChange = senderId => {
        if (senderId === SENDER_CODE.DELIVERY_NOTE_OTHER) {
            if (form.getFieldValue('customer_id') === project?.customer_id) {
                form.setFieldsValue({
                    sender_name: project?.customer?.delivery_note_sender_name || '',
                    sender_address: project?.customer?.delivery_note_sender_address || '',
                })
            } else
                form.setFieldsValue({
                    sender_name: customer?.delivery_note_sender_name || '',
                    sender_address: customer?.delivery_note_sender_address || '',
                })
        } else {
            const company = getCompanyByCode(senderId)
            form.setFieldsValue({
                sender_name: company.company_name,
                sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
            })
        }
    }

    const parseDataForSubmit = (values, isPreview = false) => {
        values.delivery_destinations = []
        values.details = []
        values.delivery_date =
            values.delivery_date && moment(values.delivery_date, dateFormat, true).format('YYYY-MM-DD')
        values.issued_date = values.issued_date && moment(values.issued_date, dateFormat, true).format('YYYY-MM-DD')
        if (deliveryDestinationType !== 1) {
            Object.values(detailsOrder)
                .flat()
                .map(key => {
                    let detail = details[key]
                    values.details.push(detail)
                })
        } else {
            if (detailsOrder['destinationUnknown'] && detailsOrder['destinationUnknown'].length > 0 && !isPreview) {
                showWarning('', '納入先を入力してください。')
                setOosProducts({
                    isOutOfStock: false,
                    data: {},
                })
                return null
            } else {
                if (destinationsOrder.length > 0) {
                    destinationsOrder.map(destinationOrder => {
                        if (destinationOrder === 'destinationUnknown') return null
                        let destination = { ...destinations[destinationOrder] }
                        destination.details = []
                        detailsOrder[destinationOrder].map(detailOrder => {
                            let detail = details[detailOrder]
                            destination.details.push(detail)
                            return null
                        })
                        values.delivery_destinations.push(destination)
                        return null
                    })
                }
            }
        }

        return values
    }

    const processOnSubmit = (values, redirectTo, confirmSave = true) => {
        if (isValueChange && isCreateProject) {
            setIsValueChange(false)
        }
        values = parseDataForSubmit(values)
        if (values) {
            if (values.delivery_destinations.length === 0) {
                let filterDetails = values.details.filter(detail => !isHeadline(detail))
                if (filterDetails.length < 1 || filterDetails.filter(detail => !detail.title).length > 0) {
                    showWarning('', '品名を入力してください。')
                    setOosProducts({
                        isOutOfStock: false,
                        data: {},
                    })
                    return
                }
            } else {
                let filterDeliveryDestinations = values.delivery_destinations.filter(deliveryDestination => {
                    let filterDetails = deliveryDestination.details.filter(detail => !isHeadline(detail))
                    return filterDetails.length > 0
                })
                if (
                    filterDeliveryDestinations.length < 1 ||
                    filterDeliveryDestinations.filter(
                        des => des.details.filter(d => !isHeadline(d) && !d.title).length > 0
                    ).length > 0
                ) {
                    showWarning('', '品名を入力してください。')
                    setOosProducts({
                        isOutOfStock: false,
                        data: {},
                    })
                    return
                }
            }

            if (confirmSave) setModalConfirmSave(true)
            else {
                if (projectForm) {
                    const projectValues = {
                        id: project.id,
                        status: values.status,
                        project_name: values.project_name,
                        project_code: project.project_code,
                        customer_id: values.customer_id,
                        is_purchase_order: values.is_purchase_order,
                        pic_id: values.pic_id,
                        note: values.note,
                        sale: { ...values, ...{ note: values.sale_note, pic_id: values.sale_pic_id } },
                    }
                    onSubmit(projectValues, redirectTo)
                } else {
                    onSubmit(values, redirectTo)
                }
                return true
            }
        }
    }

    const tmpDirectDeliveryType =
        initialValues.delivery_destinations && initialValues.delivery_destinations.length === 1 ? 2 : 1
    useEffect(() => {
        calculateSum(details)
        if (firstRender) {
            getCompanies().then(r => {})
            dispatch(setSaleClassification(initialValues.sale_classification))
            dispatch(
                setDeliveryDestinationType({
                    deliveryDestinationType: initialValues.delivery_destination,
                    directDeliveryType: tmpDirectDeliveryType,
                })
            )
        }
        setFirstRender(false)
    }, [details])
    const suggestionList = customer.customer_pic ? customer.customer_pic.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }
    const handlerCustomerChange = id => {
        const getCustomer = async id => {
            const data = await api.get(APP_URLS.CUSTOMERS_DETAIL.replace(':id', id))
            if (data) {
                setCustomer(data)

                form.setFieldsValue({ sender_id: data.delivery_note_sender_code })
                if (data.delivery_note_sender_code === SENDER_CODE.DELIVERY_NOTE_OTHER) {
                    form.setFieldsValue({
                        sender_name: data.delivery_note_sender_name,
                        sender_address: data.delivery_note_sender_address,
                    })
                } else {
                    // smt here
                    const company = getCompanyByCode(data.delivery_note_sender_code)
                    form.setFieldsValue({
                        sender_name: company.company_name,
                        sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
                    })
                }

                setResult(data.customer_pic ? data.customer_pic.split(',') : [])
                // set title
                form.setFieldsValue({
                    customer_pic_name: '',
                    customer_pic_title: data.customer_title,
                })
            }
        }
        getCustomer(id)
    }
    const canEditSale = can('SALE_EDITABLE') && !project.is_locked
    return (
        <>
            {canEditSale ? (
                <>
                    {modalConfirmSave && (
                        <InsModal
                            title="確認"
                            visible={modalConfirmSave}
                            onCancel={() => setModalConfirmSave(false)}
                            onOk={() => processOnSubmit(form.getFieldsValue(), null, false)}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <p>保存しますか？</p>
                        </InsModal>
                    )}
                    <RouterPrompt
                        handleSave={path => {
                            let values = form.getFieldsValue()
                            return processOnSubmit(values, path, false)
                        }}
                        isValueChange={isValueChange}
                        setDefaultState={() => setDefaultState()}
                    />
                    <div className="form-place-order bl-form">
                        <InsModal
                            title={t('Sales preview')}
                            visible={modalSalePreview}
                            onCancel={() => setModalSalePreview(false)}
                            okText="確認"
                            footer={null}
                            className="preview-modal"
                        >
                            <SaleDOMPrint
                                project={project}
                                salePrint={Object.assign(initialValues, salePreviewModal)}
                                hideColumns={[]}
                                showPreview={true}
                                processPrint={false}
                            />
                        </InsModal>
                        <InsModal
                            title={t('navigation:Stock check')}
                            width={760}
                            okText={t('はい')}
                            cancelText={t('いいえ')}
                            visible={oosProducts.isOutOfStock}
                            onCancel={() =>
                                setOosProducts({
                                    isOutOfStock: false,
                                    data: oosProducts.data,
                                })
                            }
                            onOk={() => {
                                setOosProducts({
                                    isOutOfStock: false,
                                    data: oosProducts.data,
                                })
                                processOnSubmit(form.getFieldsValue())
                            }}
                        >
                            <StockListInsufficient data={oosProducts.data} />
                        </InsModal>
                        <Form
                            form={form}
                            initialValues={initialValues}
                            onFinish={values => {
                                if (form.getFieldValue('sale_classification') === 2) checkOutOfStock(details, values)
                                else processOnSubmit(values)
                            }}
                            onValuesChange={() => {
                                if (isEdit && !isValueChange) {
                                    setIsValueChange(true)
                                }
                            }}
                        >
                            <Row>
                                <Col>
                                    <h1 className="bl-form-title">{t('navigation:Create sale-title')}</h1>
                                </Col>
                            </Row>

                            <Form.Item name="project_id" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>
                            <Form.Item name="is_created_from_instruction" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>

                            <Row className="mb-16">
                                <Col span={12}>
                                    <Form.Item name="sale_classification" label="区分">
                                        <InsSelect
                                            style={{ width: 180 }}
                                            onChange={value => dispatch(setSaleClassification(value))}
                                        >
                                            <Select.Option value={1}>{'加工'}</Select.Option>
                                            <Select.Option value={2}>{'資材'}</Select.Option>
                                        </InsSelect>
                                    </Form.Item>
                                    <Form.Item label={t('column:Customer name')}>
                                        <Form.Item
                                            name={projectForm ? 'sale_customer_id' : 'customer_id'}
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter customer name'),
                                                },
                                            ]}
                                        >
                                            <CustomerSelect
                                                placeholder={t('Customer search')}
                                                record={initialValues.customer}
                                                style={{ width: 274 }}
                                                onChange={(id, customer) => {
                                                    handlerCustomerChange(id, customer)
                                                }}
                                                // showListLink={false}
                                                showCreateButton={false}
                                                // showDetailLink={false}
                                            />
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item label={t('column:Customer pic name')}>
                                        <Form.Item name="customer_pic_name" noStyle>
                                            <InsAutoComplete
                                                options={result}
                                                onSearch={handleSuggestion}
                                                placeholder="担当者検索"
                                                className="inline"
                                                style={{ width: 180 }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="customer_pic_title" noStyle>
                                            <InsSelect style={{ width: 90, marginLeft: 4, verticalAlign: 'top' }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.CUSTOMER_TITLE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item name="sale_name" label={t('column:件名')}>
                                        <InsInput
                                            style={{ width: 340 }}
                                            placeholder="件名入力"
                                            showMaxLength
                                            maxLength={40}
                                        />
                                    </Form.Item>

                                    <Form.Item label={t('column:Delivery destination')}>
                                        <Form.Item name="delivery_destination" noStyle>
                                            <InsSelect
                                                style={{ width: 92 }}
                                                onSelect={value => {
                                                    dispatch(
                                                        setDeliveryDestinationType({
                                                            deliveryDestinationType: value,
                                                            directDeliveryType: DIRECT_DELIVERY,
                                                        })
                                                    )
                                                }}
                                            >
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.DELIVERY_TYPE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={parseInt(clf.value)}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                        {deliveryDestinationType === DIRECT_DELIVERY && (
                                            <Form.Item noStyle>
                                                <InsSelect
                                                    defaultValue={tmpDirectDeliveryType}
                                                    style={{ width: 180, marginLeft: 5 }}
                                                    onSelect={value => {
                                                        dispatch(
                                                            setDeliveryDestinationType({
                                                                deliveryDestinationType: deliveryDestinationType,
                                                                directDeliveryType: value,
                                                            })
                                                        )
                                                    }}
                                                >
                                                    <Select.Option value={1}>{'商品ごと設定'}</Select.Option>
                                                    <Select.Option value={2}>{'全ての直送先に送る'}</Select.Option>
                                                </InsSelect>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="sale_code"
                                        label={t('column:Sale code')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter sale code'),
                                                // check unique
                                            },
                                            {
                                                // trigger: 'onBlur',
                                                validator: async (rule, value) => {
                                                    if (isEdit && initialValues.sale_code.trim() === value.trim())
                                                        return Promise.resolve()

                                                    if (value.trim()) {
                                                        const data = await api.post(APP_URLS.SALES_CHECK_EXISTS_CODE, {
                                                            sale_code: value,
                                                        })
                                                        if (data && data.is_existed) {
                                                            return Promise.reject(t('validation:Sale code existed'))
                                                        }
                                                    }

                                                    return Promise.resolve()
                                                },
                                            },
                                        ]}
                                        validateTrigger={['onBlur']}
                                        className="hide-require"
                                    >
                                        <InsInput style={{ width: 180 }} />
                                    </Form.Item>
                                    <Form.Item name="delivery_date" label={t('column:Sale delivery date')}>
                                        <InsDatePicker
                                            format={dateFormat}
                                            placeholder={t('common:Enter {{item}}', {
                                                item: t('column:Delivery date'),
                                            })}
                                            style={{ width: 180 }}
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                    <Form.Item name="sender_id" label="差出名">
                                        <InsSelect
                                            style={{ width: 180 }}
                                            onChange={value => handlerSenderIdChange(value)}
                                        >
                                            {getClassificationsByType(
                                                ClassificationTypeEnum.DELIVERY_NOTE_SENDER.value
                                            ).map(clf => (
                                                <Select.Option key={clf.value} value={clf.value}>
                                                    {clf.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                    <Form.Item name="sender_name" label=" ">
                                        <InsInput style={{ width: 180 }} allowClear value={'株式会社稲進'} />
                                    </Form.Item>
                                    <Form.Item name="sender_address" label=" " style={{ width: 420, marginBottom: 8 }}>
                                        <InsTextArea />
                                    </Form.Item>
                                    <Form.Item
                                        name={projectForm ? 'sale_pic_id' : 'pic_id'}
                                        label={t('column:Pic name')}
                                    >
                                        <UserSelect
                                            record={projectForm ? initialValues.sale_pic : initialValues.pic}
                                            style={{ width: 180 }}
                                            placeholder="稲進担当者検索"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <SaleDragAndDrop />

                            <Row className="bl-form-detail-control" justify="space-between">
                                <SaleDetailAddButton />
                                <Row className="total">
                                    <Form.Item name="gross_profit" label="粗利">
                                        <InsInputNumber disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="subtotal" label="小計">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="consumption_tax" label="消費税">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="total" label="合計	：:">
                                        <InsInputNumber min={0} disabled style={{ width: 110 }} />
                                    </Form.Item>
                                </Row>
                            </Row>

                            <h3>備考</h3>
                            <Form.Item name={projectForm ? 'sale_note' : 'note'}>
                                <InsTextArea allowClear={false} />
                            </Form.Item>
                            <InsFloatBar visible={!modalSalePreview} className="with-sidebar z-9999">
                                <Row gutter={8} justify="space-between" align="middle">
                                    <Col>
                                        <InsButton
                                            icon={<PreviewIcon size={16} className="color-pantone-2738-c" />}
                                            buttonStyle="default"
                                            buttonSize="big"
                                            onClick={() => {
                                                setSalePreviewModal(parseDataForSubmit(form.getFieldsValue(), true))
                                                setModalSalePreview(true)
                                            }}
                                        >
                                            {t('プレビュー')}
                                        </InsButton>
                                    </Col>
                                    <Col className={'btn-update-project-bottom'}>
                                        <InsButton
                                            buttonStyle="outline"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            onClick={() => {
                                                if (isCreateProject) {
                                                    history.push(`${Routes.private.business.PROJECT.path}`)
                                                } else {
                                                    history.push(
                                                        `${Routes.private.business.PROJECT_DETAIL.path.replace(
                                                            ':id',
                                                            project.id
                                                        )}`
                                                    )
                                                }
                                            }}
                                        >
                                            {t('common:Cancel')}
                                        </InsButton>

                                        <InsButton
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            isSubmitButton={true}
                                        >
                                            {isEdit ? t('common:Save') : t('common:Save')}
                                        </InsButton>
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        </Form>
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    )
}

const mapState = state => ({
    destinations: state.saleDetail.destinations,
    destinationsOrder: state.saleDetail.destinationsOrder,
    details: state.saleDetail.details,
    detailsOrder: state.saleDetail.detailsOrder,
    saleClassification: state.saleDetail.saleClassification,
    deliveryDestinationType: state.saleDetail.deliveryDestinationType,
    directDeliveryType: state.saleDetail.directDeliveryType,
    changedData: state.saleDetail.changedData,
})

const mapDispatch = {
    setDestinations,
    setDestinationsOrder,
    setDetails,
    setDetailsOrder,
    setDefaultState,
    setDeliveryDestinationType,
    setData,
}

export default connect(mapState, mapDispatch)(SaleForm)

SaleForm.propTypes = {
    saleClassification: PropTypes.number,
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    project: PropTypes.object.isRequired,
    destinations: PropTypes.object,
    destinationsOrder: PropTypes.array,
    details: PropTypes.object,
    detailsOrder: PropTypes.object,
    deliveryDestinationType: PropTypes.number,
    directDeliveryType: PropTypes.number,
    setDestinations: PropTypes.func.isRequired,
    setDestinationsOrder: PropTypes.func.isRequired,
    setDetails: PropTypes.func.isRequired,
    setDetailsOrder: PropTypes.func.isRequired,
    setDefaultState: PropTypes.func.isRequired,
    setData: PropTypes.func,
    changedData: PropTypes.bool.isRequired,
    projectForm: PropTypes.any,
    projectCreateDoc: PropTypes.bool,
}
