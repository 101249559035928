import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, List, Row } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getEnum, getEnumText } from 'helpers/enum.helper'
import { ActionLogType } from 'enums/action_log_type.enum'
import { ActionLogObject } from 'enums/action_log_object.enum'
import moment from 'moment'
import DayOfWeekEnum from 'enums/day_of_week.enum'

const ActionLogListComponent = ({ inModal = false, data }) => {
    const { t } = useTranslation()

    useEffect(() => {
        // eslint-disable-next-line
    }, [])

    const statusChangeLogTypes = Object.freeze({
        ORDER_FAILED: { value: ActionLogType.ORDER_FAILED.value, text: '失注' },
        ORDER_PROCESSING: { value: ActionLogType.ORDER_PROCESSING.value, text: '受注済' },
        PROCESS_SALE: { value: ActionLogType.PROCESS_SALE.value, text: '売上済' },
        PROCESS_SHIPPING: { value: ActionLogType.PROCESS_SHIPPING.value, text: '出荷済' },
        PROCESSED: { value: ActionLogType.PROJECT_PROCESS.value, text: '処理済み' },
    })

    return (
        <React.Fragment>
            {inModal && (
                <Row className="mt-8 mb-4" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-0">{t('entity:ActionLog')}</h1>
                    </Col>
                </Row>
            )}
            <List
                locale={{ emptyText: <></> }}
                className={inModal ? 'log-list-scroll' : 'log-list'}
                split={false}
                size="small"
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                    // 三輪さんが案件を作成しました。（2020.11.11(月) 11:11）
                    // 高橋 直子さんが見積書を編集しました。（2020.11.11(月) 11:11）
                    // あああさんが作業指示書を削除しました。（2020.11.11(月) 11:11）
                    //（メンバ名）さんがこの案件のステータスを（ステータス）に変更しました。（yyyy/MM/dd(曜日) H:mm ）
                    <List.Item>
                        <span>
                            <span className="fw-bold">{item.pic.pic_name}</span>
                            {getEnum(statusChangeLogTypes, item.action_type.toString()) ? (
                                <span className="fw-400">
                                    さんがこの案件のステータスを 「
                                    {getEnumText(statusChangeLogTypes, item.action_type.toString())}」
                                    に変更しました。（
                                    {moment(item.action_datetime).format('YYYY.M.D')}(
                                    {getEnumText(DayOfWeekEnum, moment(item.action_datetime).day())}）
                                    {moment(item.action_datetime).format('HH:mm')})
                                </span>
                            ) : (
                                <span className="fw-400">
                                    さんが
                                    {getEnumText(ActionLogObject, item.action_object.toString())}を
                                    {item.action_type.toString() === ActionLogType.CLONE.value && (
                                        <span>{item.copied_project?.project_code}から</span>
                                    )}
                                    {getEnumText(ActionLogType, item.action_type.toString())} しました。（
                                    {moment(item.action_datetime).format('YYYY.M.D')}(
                                    {getEnumText(DayOfWeekEnum, moment(item.action_datetime).day())}）
                                    {moment(item.action_datetime).format('HH:mm')})
                                </span>
                            )}
                        </span>
                    </List.Item>
                )}
            />
        </React.Fragment>
    )
}

export default ActionLogListComponent

ActionLogListComponent.propTypes = {
    inModal: PropTypes.bool,
    state: PropTypes.shape(BaseStatePropShape),
    data: PropTypes.array,
}

ActionLogListComponent.defaultProps = {
    inModal: false,
}
