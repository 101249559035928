import { createSlice } from '@reduxjs/toolkit'

const userSetting = createSlice({
    name: 'userSetting',
    initialState: {
        settings: {},
        settingsOrder: [],
        notSettings: {},
        checked: [],
    },
    reducers: {
        addSettings(state, action) {
            const settings = action.payload
            settings.forEach(setting => {
                const isSetting = setting.is_setting
                const settingId = setting.value
                if (isSetting) {
                    state.settings[settingId] = setting
                    state.settingsOrder = [...state.settingsOrder, settingId]
                } else {
                    state.notSettings[settingId] = setting
                }
            })
        },
        addChecked(state, action) {
            state.checked = [...state.checked, action.payload]
        },
        removeChecked(state, action) {
            const newChecked = [...state.checked]
            newChecked.splice(
                newChecked.findIndex(item => item.value === action.payload.value),
                1
            )
            state.checked = newChecked
        },
        moveSetting(state, action) {
            const { srcIndex, desIndex } = action.payload
            if (srcIndex === desIndex) {
                return
            }
            let newOrder = [...state.settingsOrder]
            reOrder(newOrder, srcIndex, desIndex)
            state.settingsOrder = newOrder
        },
        removeSettings(state, action) {
            const settings = action.payload
            const newSettings = { ...state.settings }
            const newSettingsOrder = [...state.settingsOrder]
            const newNotSettings = { ...state.notSettings }
            settings.forEach(setting => {
                let settingId = setting.value
                if (setting.is_setting) {
                    delete newSettings[settingId]
                    newSettingsOrder.splice(newSettingsOrder.indexOf(settingId), 1)
                    newNotSettings[settingId] = { ...setting, is_setting: false }
                } else {
                    delete newNotSettings[settingId]
                    newSettings[settingId] = { ...setting, is_setting: true }
                    newSettingsOrder.push(settingId)
                }
            })
            state.settings = newSettings
            state.settingsOrder = newSettingsOrder
            state.notSettings = newNotSettings
            state.checked = []
        },
        setDefaultState(state) {
            state.settings = {}
            state.settingsOrder = []
            state.notSettings = {}
            state.checked = []
        },
    },
})

export default userSetting.reducer
export const {
    addSettings,
    moveSetting,
    removeSettings,
    addChecked,
    removeChecked,
    setDefaultState,
} = userSetting.actions

const reOrder = (list, srcIndex, desIndex) => {
    const [removed] = list.splice(srcIndex, 1)
    list.splice(desIndex, 0, removed)
}
