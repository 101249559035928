import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { login } from 'modules/common/auth/auth.slice'
import Routes from 'routes'
import NavigationBar from 'modules/common/ui/NavigationBar'
import MasterLayout from 'modules/common/ui/MasterLayout'

const AuthLayout = ({ authenticated }) => {
    if (!authenticated) {
        return <Redirect to={Routes.public.LOGIN.path} />
    }
    return (
        <Layout id="site-layout">
            <NavigationBar />
            <Layout.Content>
                <Switch>
                    <Route key="/" exact path="/">
                        <Redirect to={Routes.private.business.PROJECT.path} />
                    </Route>
                    {Object.keys(Routes.private.business).map(key => {
                        const r = Routes.private.business[key]
                        return (
                            <Route
                                key={`business-${key}`}
                                exact={typeof r.exact === 'undefined' ? true : r.exact}
                                path={r.path}
                                component={r.component}
                            />
                        )
                    })}
                    <Route key="/master" path="/master">
                        <MasterLayout />
                    </Route>
                    <Route key="/account" path="/account">
                        <MasterLayout />
                    </Route>
                    <Route path="*">
                        <h1>Not found</h1>
                    </Route>
                </Switch>
            </Layout.Content>
        </Layout>
    )
}

export default connect(state => ({ authenticated: state.auth.user !== null }), { login })(AuthLayout)

AuthLayout.propTypes = {
    authenticated: PropTypes.bool.isRequired,
}
