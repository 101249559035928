import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import { formatNumberComma } from 'helpers/number.helper'
import { connect } from 'react-redux'
import InsLoader from 'components/InsLoader'
import { DisplayShipmentTypeEnum } from 'enums/shipment.enum'
import ShipmentProcessModal from 'modules/business/shipment/ShipmentProcessModal'
import InsFloatBar from 'components/InsFloatBar'
import { Col, Row } from 'antd'
import { AssignmentIcon } from 'components/InsIcon'
import { CSVLink } from 'react-csv'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import InsModal from 'components/InsModal'
import Routes from 'routes'
import { setDefaultState } from 'modules/business/shipment/shipment_process.slice'
import { dateFormat } from 'helpers/date.helper'
import ProductDetailComponent from 'modules/master/product/ProductDetailComponent'
import { APP_URLS } from 'constants/url.constant'
import NavigationEnum from 'enums/navigation.enum'

let sumTotal = 0
const OutgoingDataTable = ({ state, getShipments }) => {
    const { t } = useTranslation()
    const [shipmentProcessModal, setShipmentProcessModal] = useState({
        visible: false,
        outgoingProductId: null,
    })
    const [outgoingData, setOutgoingData] = useState(null)
    const [modalCustomerDetail, setModalCustomerDetail] = useState(false)
    const [customerId, setCustomerId] = useState(null)
    const [modalProductDetail, setModalProductDetail] = useState({
        productId: null,
        visible: false,
    })

    const outgoingProductColumns = [
        {
            title: '出荷日',
            dataIndex: 'delivery_date',
            sorter: true,
            render: (text, r) => {
                return dateFormat(text)
            },
        },
        {
            title: '案件番号',
            dataIndex: 'number_code',
            sorter: true,
            render: (text, r) => {
                if (r.project_id) {
                    return (
                        <InsButton
                            buttonStyle="plain"
                            linkTo={
                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.project_id) +
                                APP_URLS.SALES +
                                '?from=' +
                                NavigationEnum.SHIPMENTS
                            }
                            buttonType="link"
                        >
                            {text}
                        </InsButton>
                    )
                } else if (r.outgoing_product_id) {
                    return (
                        <InsButton
                            buttonStyle="plain"
                            onClick={() => {
                                setDefaultState()
                                setShipmentProcessModal({
                                    visible: true,
                                    outgoingProductId: r.outgoing_product_id,
                                })
                            }}
                        >
                            {text}
                        </InsButton>
                    )
                }
            },
        },
        {
            title: '顧客名',
            dataIndex: 'customer_name',
            sorter: true,
            render: (text, r) => {
                let customerName = 'ー'
                if (r.customer_id) {
                    customerName = r.customer_name
                    return (
                        <>
                            <InsButton
                                buttonStyle="plain"
                                onClick={() => {
                                    if (r.customer_id) {
                                        setCustomerId(r.customer_id)
                                        setModalCustomerDetail(true)
                                    }
                                }}
                            >
                                {customerName}
                            </InsButton>
                        </>
                    )
                }
                return customerName
            },
        },
        {
            title: '品名',
            dataIndex: 'product_name',
            render: (text, r) => {
                let isOutgoingProduct = false
                if (r.project_id) {
                    isOutgoingProduct = false
                }
                if (r.outgoing_product_id) {
                    isOutgoingProduct = true
                }
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            if (isOutgoingProduct) {
                                setDefaultState()
                                setModalProductDetail({
                                    visible: true,
                                    productId: r.product_id,
                                })
                            } else {
                                setModalProductDetail({
                                    productId: r.product_id,
                                    visible: true,
                                })
                            }
                        }}
                    >
                        {text}
                    </InsButton>
                )
            },
            sorter: true,
        },
        {
            title: '単価',
            dataIndex: 'price',
            sorter: true,
            render: (text, r) => {
                return formatNumberComma(text)
            },
        },
        {
            title: '出荷数',
            dataIndex: 'quantity',
            render: text => formatNumberComma(text),
            sorter: true,
        },
        {
            title: '小計',
            dataIndex: 'subtotal',
            render: (text, r) => {
                return <span style={{ fontWeight: 'bold' }}>{formatNumberComma(text)}</span>
            },
            sorter: true,
        },
    ]

    const outgoingProjectColumns = [
        {
            title: '案件番号',
            dataIndex: 'project_code:outgoing_product',
            render: (text, r) => {
                if (r.project_code) {
                    return (
                        <InsButton
                            buttonStyle="plain"
                            linkTo={
                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id) +
                                '?from=' +
                                NavigationEnum.SHIPMENTS
                            }
                            buttonType="link"
                        >
                            {r.project_code}
                        </InsButton>
                    )
                } else if (r.outgoing_product && typeof r.outgoing_product === 'string') {
                    return (
                        <InsButton
                            buttonStyle="plain"
                            onClick={() => {
                                setShipmentProcessModal({
                                    visible: true,
                                    outgoingProductId: r.id,
                                })
                            }}
                        >
                            {r.outgoing_product}
                        </InsButton>
                    )
                }
            },
            sorter: true,
        },
        {
            title: '件名',
            dataIndex: 'project_code',
            render: (text, r) => {
                let title = '手動 出荷処理'
                if (r.project_name) {
                    title = r.project_name
                }
                return (
                    <>
                        {r.project_code ? (
                            <InsButton
                                buttonStyle="plain"
                                linkTo={
                                    Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id) +
                                    '?from=' +
                                    NavigationEnum.SHIPMENTS
                                }
                                buttonType="link"
                            >
                                {title}
                            </InsButton>
                        ) : (
                            r.outgoing_product &&
                            typeof r.outgoing_product === 'string' && (
                                <InsButton
                                    buttonStyle="plain"
                                    onClick={() => {
                                        setShipmentProcessModal({
                                            visible: true,
                                            outgoingProductId: r.id,
                                        })
                                    }}
                                >
                                    {title}
                                </InsButton>
                            )
                        )}
                    </>
                )
            },
            sorter: true,
        },
        {
            title: '顧客名',
            dataIndex: 'customer|customer_name',
            sorter: true,
            render: (text, r) => {
                let customerName = 'ー'
                if (r.project_code) {
                    customerName = r.customer?.customer_name
                    return (
                        <InsButton
                            buttonStyle="plain"
                            onClick={() => {
                                if (r.customer?.id) {
                                    setCustomerId(r.customer.id)
                                    setModalCustomerDetail(true)
                                }
                            }}
                        >
                            {customerName}
                        </InsButton>
                    )
                }
                return customerName
            },
        },
        {
            title: '出荷日',
            dataIndex: 'sale|delivery_date:outgoing_product_date',
            sorter: true,
            render: (text, r) => {
                if (r.sale) {
                    return dateFormat(r.sale?.delivery_date)
                }
                return dateFormat(r.outgoing_product_date)
            },
        },
        {
            title: '小計',
            dataIndex: 'subtotal',
            render: (text, r) => {
                let total = 0
                if (r.project_code) {
                    total = r.sale.subtotal ?? 0
                } else if (r.outgoing_product) {
                    let outgoingProductDetails = r.details
                    if (outgoingProductDetails) {
                        total = outgoingProductDetails
                            .map(item => {
                                return (item?.product?.price ?? 0) * (item?.quantity ?? 0)
                            })
                            .reduce((prev, curr) => prev + curr, 0)
                    }
                }
                return <span style={{ fontWeight: 'bold' }}>{formatNumberComma(total)}</span>
            },
            sorter: true,
        },
    ]

    const convertDatToCSV = () => {
        let csvData = []
        if (outgoingData) {
            if (state.filters['display_shipment_type'] === DisplayShipmentTypeEnum.PRODUCT.value) {
                csvData.push(['出荷日', '案件番号', '顧客名', '品名', '単価', '出荷数', '小計'])
                outgoingData.forEach(data => {
                    csvData.push([
                        data.delivery_date,
                        data.number_code,
                        data.customer_name ?? 'ー',
                        data.title,
                        data.price,
                        data.quantity,
                        data.price * data.quantity,
                    ])
                })
            } else {
                csvData.push(['案件番号', '件名', '仕入先名', '出荷日', '小計'])
                outgoingData.forEach(data => {
                    if (data.project_code) {
                        csvData.push([
                            data.project_code,
                            data.product_name,
                            data.customer.customer_name,
                            data.sale.delivery_date,
                            data.sale.subtotal,
                        ])
                    } else {
                        let total = 0
                        let outgoingProductDetails = data.details
                        if (outgoingProductDetails) {
                            total = outgoingProductDetails
                                .map(item => {
                                    return (item?.product?.price ?? 0) * (item?.quantity ?? 0)
                                })
                                .reduce((prev, curr) => prev + curr, 0)
                        }
                        csvData.push([data.outgoing_product, '手動 出荷処理', 'ー', data.outgoing_product_date, total])
                    }
                })
            }
        }
        return csvData
    }

    useEffect(() => {
        if (!state.loading && state.data.length > 0) {
            let data = [...state.data]
            let firstElement = data.shift()
            if (typeof firstElement === 'number') {
                sumTotal = firstElement
                setOutgoingData(data)
            } else {
                setOutgoingData(state.data)
            }
        }
    }, [state.data])

    return (
        <>
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalProductDetail.visible}
                onCancel={() =>
                    setModalProductDetail({
                        productId: modalProductDetail.productId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <ProductDetailComponent inModal={true} id={modalProductDetail.productId} />
            </InsModal>
            {shipmentProcessModal.visible && (
                <ShipmentProcessModal
                    isEdit={true}
                    outgoingProductId={shipmentProcessModal.outgoingProductId}
                    visible={shipmentProcessModal.visible}
                    onCancel={() => {
                        if (shipmentProcessModal.visible) {
                            setShipmentProcessModal({
                                visible: false,
                                outgoingProductId: null,
                            })
                            getShipments()
                        }
                    }}
                />
            )}
            <InsDataTable
                loading={state.loading ? { indicator: <InsLoader /> } : false}
                data={outgoingData}
                columns={
                    state.filters['display_shipment_type'] === DisplayShipmentTypeEnum.PROJECT.value
                        ? outgoingProjectColumns
                        : outgoingProductColumns
                }
                rowKey={record => `${record.id}_${record.project_code}`}
                onChange={(pagination, filter, sorter) => {
                    getShipments(state.pagination, state.filters, sorter)
                }}
                expandable={
                    state.filters['display_shipment_type'] === DisplayShipmentTypeEnum.PROJECT.value
                        ? {
                              expandedRowRender: record => {
                                  const columns = [
                                      {
                                          title: '',
                                          width: 32,
                                      },
                                      {
                                          title: '品名',
                                          dataIndex: 'title',
                                          key: 'title',
                                          sorter: (a, b) => {
                                              if (a.title && b.title) {
                                                  return a.title.length - b.title.length
                                              } else {
                                                  return (
                                                      a.product?.product_name?.length ??
                                                      0 - b.product?.product_name?.length ??
                                                      0
                                                  )
                                              }
                                          },
                                          render: (text, record) => text || record.product?.product_name,
                                      },
                                      {
                                          title: '数量',
                                          dataIndex: 'quantity',
                                          key: 'quantity',
                                          sorter: (a, b) => a.quantity - b.quantity,
                                          width: 94,
                                          render: text => formatNumberComma(text),
                                      },
                                      {
                                          title: '単価',
                                          dataIndex: 'unit_price',
                                          key: 'unit_price',
                                          sorter: (a, b) => a.unit_price - b.unit_price,
                                          width: 94,
                                          render: (text, r) => {
                                              let price
                                              if (r.sale_id) {
                                                  price = r.unit_price
                                              } else {
                                                  price = r.product?.price
                                              }
                                              return formatNumberComma(price)
                                          },
                                      },
                                      {
                                          title: '金額',
                                          dataIndex: 'amount',
                                          key: 'amount',
                                          sorter: (a, b) => a.amount - b.amount,
                                          width: 94,
                                          render: (text, r) => {
                                              let amount
                                              if (r.sale_id) {
                                                  amount = r.unit_price * r.quantity
                                              } else {
                                                  amount = r.product?.price * r.quantity
                                              }
                                              return formatNumberComma(amount)
                                          },
                                      },
                                  ]
                                  return (
                                      <div style={{ padding: 4 }}>
                                          <InsDataTable
                                              columns={columns}
                                              data={(record?.sale?.details ?? record.details)?.filter(
                                                  d => d.title || d.product
                                              )}
                                              rowKey={e => e._id}
                                              pagination={false}
                                              onChange={(pagination, filter, sorter) => {}}
                                          />
                                      </div>
                                  )
                              },
                              rowExpandable: r => r.name !== null,
                              expandIconColumnIndex: 8,
                          }
                        : null
                }
            />
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalCustomerDetail}
                onCancel={() => setModalCustomerDetail(false)}
                footer={null}
            >
                <CustomerDetailComponent inModal={true} id={customerId} />
            </InsModal>
            <InsFloatBar visible={true}>
                <Row justify="space-between" align="middle">
                    <Col style={{ paddingLeft: 16 }}>
                        <Row className="mt-4">
                            <InsButton
                                style={{ width: 94 }}
                                icon={<AssignmentIcon size={16} className="color-pantone-2422-c" />}
                                buttonStyle="default"
                                onClick={() => {
                                    convertDatToCSV()
                                }}
                            >
                                <CSVLink data={convertDatToCSV()} filename={'inashin-shipment.csv'} target="_blank">
                                    {t('CSV作成')}
                                </CSVLink>
                            </InsButton>
                        </Row>
                    </Col>
                    <Col style={{ paddingLeft: 16 }}>
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">抽出された金額合計</label>
                            <span className="project-status-label summary-invoice-label">
                                {formatNumberComma(sumTotal)}
                            </span>
                        </span>
                    </Col>
                </Row>
            </InsFloatBar>
        </>
    )
}
const mapDispatch = { setDefaultState }
const mapState = state => ({ state: state.shipment })
export default connect(mapState, mapDispatch)(OutgoingDataTable)

OutgoingDataTable.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    getShipments: PropTypes.func,
}
