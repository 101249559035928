import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import InsBackLink from 'components/InsBackLink'
import StockForm from 'modules/master/stock/StockForm'

const StockUpdate = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [stock, setStock] = useState(null)

    useEffect(() => {
        const getStock = async id => {
            const data = await api.get(APP_URLS.STOCKS_DETAIL.replace(':id', id))
            if (data) {
                setStock(data)
                setLoading(false)
            }
        }

        getStock(id)
    }, [id])

    const updateStock = async values => {
        const data = await api.put(APP_URLS.STOCKS_DETAIL.replace(':id', id), values)
        if (data) {
            history.goBack()
        }
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)
    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">{t('navigation:{{entity}} edit', { entity: t('entity:Stock') })}</h1>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <StockForm
                    modalConfirmSave={modalConfirmSave}
                    setModalConfirmSave={setModalConfirmSave}
                    isEdit={true}
                    initialValues={stock}
                    onSubmit={(values, confirmSave = true) => {
                        if (confirmSave) setModalConfirmSave(true)
                        else updateStock(values, confirmSave)
                    }}
                />
            )}
        </div>
    )
}

export default StockUpdate
