import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import { splittingContent } from 'components/print/InsSplittingContent'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { dateToStr } from 'helpers/date.helper'
import { formatNumber, formatNumberComma } from 'helpers/number.helper'
import { isHeadline } from 'helpers/detail.helper'
import InsNoteAffix from 'components/InsNoteAffix'
import InsTextArea from 'components/InsTextArea'
import { isEmpty } from 'helpers/string.helper'

const EstimationDOMPrint = ({ project, estimationPrint, componentRef, showPreview = false, processPrint = true }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [estimation, setEstimation] = useState(estimationPrint)

    let refs = {}
    let refIndex = 1
    let nextRef = {}

    const setRefs = (el, isTHead = false, withoutTHead = false) => {
        refs[refIndex] = {
            elem: el,
            isTHead: isTHead,
            withoutTHead: withoutTHead,
        }
        refIndex++
    }

    const setNextRef = el => {
        nextRef = {
            elem: el,
        }
    }

    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            if (!estimation) {
                const data = await api.get(APP_URLS.ESTIMATIONS_BY_PROJECT.replace(':projectId', project.id))
                if (data) {
                    setEstimation(data)
                }
            }
        }
        fetchData().then(() => setLoading(false))
    }, [project.id])

    // ref
    const renderHeadFirstPage = skipRef => {
        return <div ref={el => !skipRef && setRefs(el)}>{renderHeader(estimation)}</div>
    }

    // ref
    const renderTHead = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, true)} className="dom-row-thead">
                {estimation.estimation_classification === 1 ? (
                    <>
                        <span style={{ flex: 220 }}>{t('column:Estimation title')}</span>
                        <span style={{ flex: 220 }}>{t('column:Spec')}</span>
                        <span style={{ flex: 144 }}>{t('column:Size')}</span>
                    </>
                ) : (
                    <>
                        <span style={{ flex: 1 }}>{t('column:Estimation model product name')}</span>
                    </>
                )}
                <span style={{ flex: '0 0 92px' }}>{t('column:Quantity')}</span>
                <span style={{ flex: '0 0 48px' }}>{t('column:Unit')}</span>
                <span style={{ flex: '0 0 180px' }}>{t('column:Unit price')}</span>
                <span style={{ flex: '0 0 180px' }}>{t('column:Amount')}</span>
            </div>
        )
    }

    // ref
    const renderDetails = skipRef => {
        return (
            <>
                {estimation.detail.map((d, index) => {
                    if (isHeadline(d)) {
                        return (
                            <div key={index} ref={el => !skipRef && setRefs(el)} className="dom-row-detail">
                                <span style={{ flex: 1 }}>{d.headline}</span>
                                <span className="bg-color" style={{ flex: '0 0 92px' }}>
                                    &nbsp;
                                </span>
                                <span className="bg-color" style={{ flex: '0 0 48px' }}>
                                    &nbsp;
                                </span>
                                <span className="bg-color" style={{ flex: '0 0 180px' }}>
                                    &nbsp;
                                </span>
                                <span className="bg-color" style={{ flex: '0 0 180px' }}>
                                    &nbsp;
                                </span>
                            </div>
                        )
                    } else {
                        if (estimation.estimation_classification === 1) {
                            return (
                                <div key={index} ref={el => !skipRef && setRefs(el)} className="dom-row-detail">
                                    <span style={{ flex: 220 }}>
                                        <InsNoteAffix
                                            note={
                                                <InsTextArea
                                                    className="color-gray-678 textarea-auto-size text-area-preview"
                                                    autoSize={true}
                                                    disabled={true}
                                                    allowClear={false}
                                                    defaultValue={d.note}
                                                />
                                            }
                                            showCorner={!isEmpty(d.note)}
                                        >
                                            <span>{d.product_name}</span>
                                        </InsNoteAffix>
                                    </span>
                                    <span style={{ flex: 220 }}>{d.spec}</span>
                                    <span style={{ flex: 144 }}>{d.size}</span>
                                    <span style={{ flex: '0 0 92px' }}>{formatNumber(d.quantity)}</span>
                                    <span style={{ flex: '0 0 48px' }}>
                                        {getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, d.unit)}
                                    </span>
                                    <span style={{ flex: '0 0 180px' }}>{formatNumber(d.unit_price)}</span>
                                    <span style={{ flex: '0 0 180px' }}>{formatNumberComma(d.amount)}</span>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index} ref={el => !skipRef && setRefs(el)} className="dom-row-detail">
                                    <span style={{ flex: 1 }}>
                                        <InsNoteAffix
                                            note={
                                                <InsTextArea
                                                    className="color-gray-678 textarea-auto-size text-area-preview"
                                                    autoSize={true}
                                                    disabled={true}
                                                    allowClear={false}
                                                    defaultValue={d.note}
                                                />
                                            }
                                            showCorner={!isEmpty(d.note)}
                                        >
                                            <span>{d.product_name}</span>
                                        </InsNoteAffix>
                                    </span>
                                    <span style={{ flex: '0 0 92px' }}>{formatNumber(d.quantity)}</span>
                                    <span style={{ flex: '0 0 48px' }}>
                                        {getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, d.unit)}
                                    </span>
                                    <span style={{ flex: '0 0 180px' }}>{formatNumber(d.unit_price)}</span>
                                    <span style={{ flex: '0 0 180px' }}>{formatNumberComma(d.amount)}</span>
                                </div>
                            )
                        }
                    }
                })}
            </>
        )
    }

    // ref
    const renderTotal = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, false, true)} className="dom-total-price">
                <span className="td bg-color " style={{ flex: 146 }}>
                    小計
                </span>
                <span className="td bold" style={{ flex: 146 }}>
                    {formatNumberComma(estimation.subtotal)}
                </span>
                <span className="td bg-color" style={{ flex: 146 }}>
                    消費税
                </span>
                <span className="td bold" style={{ flex: 146 }}>
                    {formatNumberComma(estimation.consumption_tax)}
                </span>
                <span className="td bg-color" style={{ flex: '0 0 140px' }}>
                    合計
                </span>
                <span className="td total total-value" style={{ flex: '0 0 184px' }}>
                    {formatNumberComma(estimation.total)}
                </span>
            </div>
        )
    }

    // ref
    const renderNote = skipRef => {
        return (
            <div ref={el => !skipRef && setRefs(el, false, true)} className="c-preview-note">
                <label>{'備考'}</label>
                <div className="fake-area">{estimation.note}</div>
            </div>
        )
    }

    // ref: repeatable
    const renderHeadNextPage = () => {
        return <div ref={el => setNextRef(el)}>{renderHeader(estimation, 99)}</div>
    }

    const renderHiddenLeftContent = estimation => {
        return (
            <>
                <div className="obj-person">
                    {estimation.customer_pic_name}
                    <span className="obj-person-extra">
                        {getClassificationItemName(
                            ClassificationTypeEnum.CUSTOMER_TITLE.value,
                            estimation.customer_company_title
                        )}
                    </span>
                </div>
                <div className="as-hr" />
                <div className="about-info">
                    {(estimation.customer.phone_number || estimation.customer.fax_number) && (
                        <div className="about-info-phone-fax">
                            <span className="about-info-tel">
                                {estimation.customer.phone_number ? `TEL：${estimation.customer.phone_number}` : ''}
                            </span>
                            <span className="about-info-fax">
                                {estimation.customer.fax_number ? `FAX：${estimation.customer.fax_number}` : ''}
                            </span>
                        </div>
                    )}
                </div>
                <div className="smt">{'ご照会の件、下記の通り御見積申し上げます。'}</div>
                <table>
                    <tbody>
                        {!!(estimation.delivery_date || estimation.delivery_date_text) && (
                            <tr>
                                <th>
                                    {t('column:Delivery date')}
                                    <span>:</span>
                                </th>
                                <td>
                                    {estimation.delivery_date
                                        ? dateToStr(estimation.delivery_date)
                                        : estimation.delivery_date_text}
                                </td>
                            </tr>
                        )}
                        {!!(estimation.expiration_date || estimation.expiration_date_text) && (
                            <tr>
                                <th>
                                    {t('column:Expiration date')}
                                    <span>:</span>
                                </th>
                                <td>
                                    {estimation.expiration_date
                                        ? dateToStr(estimation.expiration_date)
                                        : estimation.expiration_date_text}
                                </td>
                            </tr>
                        )}
                        {estimation.payment_condition && (
                            <tr>
                                <th>
                                    {t('column:Payment condition')}
                                    <span>:</span>
                                </th>
                                <td>{estimation.payment_condition}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="total-amount">
                    <span>{'御見積合計金額'}</span>
                    <span className="amount-number">
                        <span>{'￥'}</span>
                        {formatNumberComma(estimation.total)}
                    </span>
                </div>
            </>
        )
    }

    const renderHiddenRightContent = estimation => {
        return (
            <>
                <div className="obj-name">{estimation.sender_name}</div>
                <div className="about-info">{estimation.sender_address}</div>
                <div className="person">
                    <label>{'担当 :'}</label>
                    <span>{estimation.pic && estimation.pic.pic_name}</span>
                </div>
            </>
        )
    }

    const renderHeader = (estimation, pageNum = 1) => {
        let pageNumString = pageNum < 10 ? '0' + pageNum : pageNum
        if (!estimation.customer) {
            estimation.customer = {}
        }
        return (
            <div className="c-preview-header c-preview">
                <Row justify="space-between" align="bottom" className="mb-12">
                    <Col span={8} offset={8} className={pageNum > 1 && 'hidden-title'}>
                        <strong className="c-preview-title">{'御見積書'}</strong>
                    </Col>
                    <Col span={8} className={`text-right page-number ${pageNum > 1 && 'page-number-continue'}`}>
                        Page - <span>{pageNumString}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="c-preview-header-left">
                        <div className="children-left">
                            <div className="obj-name">
                                {estimation.customer.customer_name}
                                <span className="obj-name-extra">
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.CUSTOMER_TITLE.value,
                                        estimation.customer_pic_title
                                    )}
                                </span>
                            </div>
                            {pageNum === 1 && renderHiddenLeftContent(estimation)}
                            <div className="as-hr" />
                            {estimation.estimation_name ? (
                                <div className="person">
                                    <label>{'件名 :'}</label>
                                    <span>{estimation.estimation_name}</span>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </Col>
                    <Col className="c-preview-header-right">
                        <div className="children-right">
                            <table className="mb-24">
                                <tbody>
                                    <tr>
                                        <th>
                                            {t('column:Issued date')}
                                            <span>:</span>
                                        </th>
                                        <td>{dateToStr(estimation.issued_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            {t('column:Project code')}
                                            <span>:</span>
                                        </th>
                                        <td>{project.project_code}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            {t('Estimation management code')}
                                            <span>:</span>
                                        </th>
                                        <td>{estimation.estimation_code}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {pageNum === 1 && renderHiddenRightContent(estimation)}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const renderPreview = () => {
        return (
            <div className="dom-preview-visibility">
                <div className="DOM-single-page dom-page-style dom-mod-style-header">
                    {renderHeadFirstPage()}
                    {renderTHead()}
                    {renderDetails()}
                    {renderTotal()}
                    {renderNote()}
                    {renderHeadNextPage()}
                </div>
            </div>
        )
    }

    useEffect(() => {
        processPrint && setSplitContents(splittingContent(refs, nextRef))
    }, [refs, nextRef])

    const renderPages = () => {
        return (
            <div style={{ display: 'none' }}>
                <div
                    ref={el => (componentRef.current = el)}
                    className="DOM-multi-pages dom-page-style dom-mod-style-header"
                    dangerouslySetInnerHTML={{ __html: splitContents }}
                />
            </div>
        )
    }

    const [splitContents, setSplitContents] = useState('')
    // render
    const render = () => (
        <>
            {processPrint && renderPreview()}
            {processPrint && renderPages()}
            {showPreview && renderUI()}
        </>
    )

    // effect re-render preview
    useEffect(() => {
        showPreview && setEstimation(estimationPrint)
    }, [estimationPrint])

    // render - show Preview
    const renderUI = () => (
        <>
            <div className="dom-preview-show dom-mod-style-header">
                {renderHeadFirstPage(true)}
                {renderTHead(true)}
                {renderDetails(true)}
                {renderTotal(true)}
                {renderNote(true)}
            </div>
        </>
    )

    return loading ? <InsBlockLoader /> : render()
}

export default EstimationDOMPrint

EstimationDOMPrint.propTypes = {
    project: PropTypes.object.isRequired,
    estimationPrint: PropTypes.object,
    componentRef: PropTypes.any,
    showPreview: PropTypes.bool,
    processPrint: PropTypes.bool,
}
