import React from 'react'
import { useTranslation } from 'react-i18next'
import InsBackLink from 'components/InsBackLink'
import OutsourceCreateComponent from 'modules/master/outsource/OutsourceCreateComponent'

const OutsourceCreate = () => {
    const { t } = useTranslation()

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} create', { entity: t('entity:Outsource') })}
            </h1>
            <OutsourceCreateComponent />
        </div>
    )
}

export default OutsourceCreate
