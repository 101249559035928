import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InsBackLink from 'components/InsBackLink'
import { useParams } from 'react-router-dom'
import { InsBlockLoader } from 'components/InsLoader'
import ShippingAddressForm from 'modules/master/shippingAddress/ShippingAddressForm'

const ShippingAddressUpdate = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [shippingAddress, setShippingAddress] = useState(null)

    useEffect(() => {
        const getShippingAddress = async id => {
            const data = await api.get(APP_URLS.SHIPPING_ADDRESS_DETAIL.replace(':id', id))
            if (data) {
                setShippingAddress(data)
                setLoading(false)
            }
        }

        getShippingAddress(id)
    }, [id])

    const updateShippingAddress = async values => {
        const data = await api.put(APP_URLS.SHIPPING_ADDRESS_DETAIL.replace(':id', id), values)
        if (data) {
            history.goBack()
        }
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} edit', { entity: t('entity:ShippingAddress') })}
            </h1>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <ShippingAddressForm
                    modalConfirmSave={modalConfirmSave}
                    setModalConfirmSave={setModalConfirmSave}
                    isEdit={true}
                    initialValues={shippingAddress}
                    onSubmit={(values, redirectTo, confirmSave = true) => {
                        if (confirmSave) setModalConfirmSave(true)
                        else updateShippingAddress(values, redirectTo, confirmSave)
                    }}
                />
            )}
        </div>
    )
}

export default ShippingAddressUpdate

ShippingAddressUpdate.propTypes = {
    match: PropTypes.object.isRequired,
}
