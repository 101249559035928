import React, { useState } from 'react'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import Routes from 'routes'
import ProjectForm from 'modules/business/project/ProjectForm'
import InsBackLink from 'components/InsBackLink'
import InsModal from 'components/InsModal'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setDefaultState as setDefaultStatePlaceOrder } from 'modules/business/placeOrder/place_order_detail.slice'
import { setDefaultState as setDefaultStateInstruction } from 'modules/business/instruction/instruction_detail.slice'
import { setDefaultState as setDefaultStateEstimation } from 'modules/business/estimation/estimation_detail.slice'
import { setDefaultState as setDefaultStateSale } from 'modules/business/sale/sale_detail.slice'
import InsButton from 'components/InsButton'

const ProjectCreate = () => {
    const [duplicateCode, setDuplicateCode] = useState({
        modal: false,
        code: '',
    })
    const [projectCode, setProjectCode] = useState()

    const location = useLocation()

    const dispatch = useDispatch()

    const createProjectForm = async values => {
        const data = await api.post(APP_URLS.PROJECT, values)

        if (data) {
            if (data.duplicate_code) {
                setDuplicateCode({
                    modal: true,
                    code: data.new_project_code,
                })
                return
            } else history.push(Routes.private.business.PROJECT_DETAIL.path.replace(':id', data.id))
            dispatch(setDefaultStatePlaceOrder())
            dispatch(setDefaultStateInstruction())
            dispatch(setDefaultStateEstimation())
            dispatch(setDefaultStateSale())
        }
    }

    return (
        <div className="page-container">
            <InsModal
                title="注意"
                visible={duplicateCode.modal}
                onOk={() => {
                    setProjectCode(duplicateCode.code)
                    setDuplicateCode({
                        modal: false,
                        code: '',
                    })
                }}
                footer={[
                    <>
                        <InsButton
                            centered
                            key="save"
                            buttonStyle="primary"
                            onClick={() => {
                                setProjectCode(duplicateCode.code)
                                setDuplicateCode({
                                    modal: false,
                                    code: '',
                                })
                            }}
                        >
                            確認
                        </InsButton>
                    </>,
                ]}
            >
                <h3>案件番号【{duplicateCode.code}】はすでに使用されています。</h3>
                <p> 別の案件番号で登録してください。</p>
            </InsModal>
            <InsBackLink />
            <ProjectForm
                initialValues={{
                    project_code: projectCode,
                }}
                estimationId={location?.state?.estimation_id ?? null}
                instructionInternalId={location?.state?.instruction_internal_id ?? null}
                instructionOutsourceId={location?.state?.instruction_outsource_id ?? null}
                placeOrderId={location?.state?.place_order_id ?? null}
                saleId={location?.state?.sale_id ?? null}
                isPurchaseOrder={location?.state?.is_purchase_order ?? false}
                isDocument={location?.state?.isDocument ?? false}
                onSubmit={values => createProjectForm(values)}
            />
        </div>
    )
}

export default ProjectCreate
