import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import { ClearInputIcon, SortDefaultIcon } from 'components/InsIcon'

const InsInput = ({
    className,
    type,
    maxLength,
    showMaxLength = false,
    defaultValue = '',
    value = null,
    onChange,
    allowClear = false,
    ...rest
}) => {
    const [inputValue, setInputValue] = useState(defaultValue)
    useEffect(() => {
        setInputValue(value)
    }, [value])
    let inputClassName = 'ins-input '
    if (className) {
        inputClassName += className
    }
    let props = { type, maxLength, value: value === null ? inputValue : value }

    const clearHandler = () => {
        setInputValue('')
        onChange && onChange('')
    }

    const handleChange = e => {
        setInputValue(e.target.value)
        onChange && onChange(e.target.value)
    }

    // const timeoutRef = useRef(null)
    // useEffect(() => {
    //     if (timeoutRef.current !== null) {
    //         clearTimeout(timeoutRef.current)
    //     }
    //
    //     timeoutRef.current = setTimeout(() => {
    //         onChange && onChange(inputValue)
    //         timeoutRef.current = null
    //     }, 500)
    // }, [inputValue])

    if (!rest.suffix) {
        props.suffix = (
            <InsInputSuffix
                allowClear={allowClear}
                onClear={() => clearHandler()}
                isNumberInput={type === 'number'}
                lengthSetting={
                    showMaxLength && maxLength ? { current: inputValue ? inputValue.length : 0, maxLength } : null
                }
            />
        )
    }

    return (
        <Input
            {...rest}
            className={inputClassName}
            onChange={handleChange}
            autoComplete={allowClear || showMaxLength || maxLength ? 'off' : 'new-password'}
            {...props}
        />
    )
}

const InsInputSuffix = ({ isNumberInput, lengthSetting, allowClear, onClear }) => {
    if (!allowClear && !isNumberInput && !lengthSetting) return null
    return (
        <React.Fragment>
            {allowClear && <ClearInputIcon className="ins-input-clear-suffix" size={16} onClick={onClear} />}
            {isNumberInput && <SortDefaultIcon className="ins-input-number-suffix" size={16} />}
            {!isNumberInput && lengthSetting && `${lengthSetting.current}/${lengthSetting.maxLength}`}
        </React.Fragment>
    )
}

export default InsInput

InsInput.propTypes = {
    allowClear: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.any,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    showMaxLength: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
}

InsInputSuffix.propTypes = {
    isNumberInput: PropTypes.bool,
    lengthSetting: PropTypes.shape({
        current: PropTypes.number.isRequired,
        maxLength: PropTypes.number.isRequired,
    }),
    allowClear: PropTypes.bool,
    onClear: PropTypes.func,
}

InsInputSuffix.defaultProps = {
    isNumberInput: false,
}
