import React from 'react'
import PropTypes from 'prop-types'
import InsModal from 'components/InsModal'
import InvoiceDOMPrint from 'modules/business/invoice/InvoiceDOMPrint'

const InvoicePreviewModal = ({ project, invoice, children, visible, onCancel }) => {
    return (
        <>
            <InsModal width={'90%'} visible={visible} onCancel={onCancel} footer={null}>
                <InvoiceDOMPrint project={project} invoicePrint={invoice} showPreview={true} processPrint={false} />
            </InsModal>
            {children}
        </>
    )
}

export default InvoicePreviewModal

InvoicePreviewModal.propTypes = {
    project: PropTypes.object,
    invoice: PropTypes.object,
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
}
