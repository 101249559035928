import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Select } from 'antd'
import Routes from 'routes'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { deleteClassificationById, getAllClassifications } from 'helpers/classification.helper'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import { AddIcon } from 'components/InsIcon'
import InsSelect from 'components/select/InsSelect'
import { setData, setFilters, setSorter } from 'modules/master/classification/classification.slice'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsModal from 'components/InsModal'
import InsLoader from 'components/InsLoader'

const ClassificationList = ({ classificationState }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [deleteClf, setDeleteClf] = useState({
        visible: false,
        id: null,
    })
    const deleteClassification = async id => {
        await api.delete(APP_URLS.CLASSIFICATIONS_DETAIL.replace(':id', deleteClf.id))
        setDeleteClf({
            visible: false,
            id: null,
        })
        deleteClassificationById(deleteClf.id)
        getData()
    }

    const classificationColumns = [
        { title: t('column:Classification type code'), dataIndex: 'classification_type_code', sorter: true },
        { title: t('column:Classification type name'), dataIndex: 'classification_type_name', sorter: true },
        { title: t('column:Classification item code'), dataIndex: 'classification_item_code', sorter: true },
        { title: t('column:Classification item name'), dataIndex: 'classification_item_name', sorter: true },
        { title: t('column:Classification item value'), dataIndex: 'classification_item_value', sorter: true },
        {
            title: '',
            dataIndex: 'edit',
            render: (text, record) => (
                <InsButton
                    buttonType="link"
                    buttonStyle="plain"
                    linkTo={Routes.private.master.CLASSIFICATION_UPDATE.path.replace(':id', record.id)}
                >
                    {t('common:Edit')}
                </InsButton>
            ),
        },
        {
            title: '',
            dataIndex: 'delete',
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() =>
                        setDeleteClf({
                            visible: true,
                            id: record.id,
                        })
                    }
                >
                    {t('common:Delete')}
                </InsButton>
            ),
        },
    ]

    const classifications = getAllClassifications()
    const getData = (filters = classificationState.filters, sorter = classificationState.sorter) => {
        let data = [...classifications]
        if (filters.typeCode && filters.typeCode !== '0') {
            data = data.filter(record => record.classification_type_code === filters.typeCode)
        }
        if (sorter.field && sorter.order) {
            data = data.sort((a, b) => {
                if (sorter.order === 'descend') {
                    if (a[sorter.field] > b[sorter.field]) {
                        return 1
                    } else if (a[sorter.field] < b[sorter.field]) {
                        return -1
                    } else {
                        return 0
                    }
                } else {
                    if (a[sorter.field] > b[sorter.field]) {
                        return -1
                    } else if (a[sorter.field] < b[sorter.field]) {
                        return 1
                    } else {
                        return 0
                    }
                }
            })
        }
        dispatch(setData(data))
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [classifications])

    const handleSelect = value => {
        const filters = { typeCode: value }
        dispatch(setFilters(filters))
        getData(filters)
    }
    return (
        <div className="page-container with-sidebar">
            <InsModal
                title={t('common:Delete')}
                visible={deleteClf.visible}
                onCancel={() =>
                    setDeleteClf({
                        visible: false,
                        id: null,
                    })
                }
                onOk={deleteClassification}
                okText="はい"
                cancelText="いいえ"
            >
                <h2>{t('common:Are you sure')}</h2>
            </InsModal>
            <Row justify="space-between">
                <Col>
                    <h1 className="page-title mb-32">
                        {t('navigation:{{entity}} management', { entity: t('entity:Classification') })}
                    </h1>
                </Col>
                <Col>
                    {classificationState.filters.typeCode !== ClassificationTypeEnum.BUSINESS_STATUS.value && (
                        <InsButton
                            buttonType="link"
                            linkTo={`${Routes.private.master.CLASSIFICATION_CREATE.path}?code=${classificationState.filters.typeCode}`}
                            icon={<AddIcon size={24} />}
                            buttonStyle="secondary"
                            width={120}
                        >
                            {t('navigation:{{entity}} create', { entity: t('entity:Classification') })}
                        </InsButton>
                    )}
                </Col>
            </Row>
            <Row className="ins-search-classification">
                <Col>{t('Classification type')}</Col>
                <Col>
                    <InsSelect
                        value={classificationState.filters.typeCode}
                        onSelect={handleSelect}
                        style={{ width: '200px' }}
                    >
                        <Select.Option value="0">{t('common:All')}</Select.Option>
                        {Object.values(ClassificationTypeEnum).map(type => (
                            <Select.Option key={type.value} value={type.value}>
                                {t(`enum:${type.text}`)}
                            </Select.Option>
                        ))}
                    </InsSelect>
                </Col>
            </Row>

            <InsDataTable
                loading={classificationState.loading ? { indicator: <InsLoader /> } : false}
                tableStyle="compact"
                striped
                bordered
                columns={classificationColumns}
                data={classificationState.data}
                onChange={(p, f, sorter) => {
                    dispatch(setSorter(sorter))
                    getData(classificationState.filters, sorter)
                }}
                pagination={classificationState.pagination}
                filters={classificationState.filters}
                sorter={classificationState.sorter}
                hasSelection={false}
            />
        </div>
    )
}

const mapState = state => ({ classificationState: state.classification })
export default connect(mapState)(ClassificationList)

ClassificationList.propTypes = {
    // classificationState: PropTypes.shape(
    //     Object.assign({}, BaseStatePropShape, { filters: PropTypes.object.isRequired })
    // ).isRequired,
    classificationState: PropTypes.shape(BaseStatePropShape),
}
