import React from 'react'
import PropTypes from 'prop-types'
import InsDataTable from 'components/datatable/InsDataTable'
import { isHeadline } from 'helpers/detail.helper'

const RowEstimationDetail = ({ record, columns }) => {
    return (
        <InsDataTable
            data={record.detail.filter(detail => !isHeadline(detail))}
            columns={columns}
            bordered={true}
            tableStyle="compact"
            style={{ padding: 12 }}
        />
    )
}

export default RowEstimationDetail

RowEstimationDetail.propTypes = {
    record: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
}
