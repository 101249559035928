import BusinessPageEnum from 'enums/business_page.enum'
import BusinessStatusEnum from 'enums/business_status.enum'

export const checkBusinessStatus = (curStatus, businessStatus) => {
    if (checkStatusNonBusiness(curStatus)) {
        return true
    }
    return curStatus.toString().startsWith(businessStatus.toString())
}

export const checkStatusNonBusiness = curStatus => {
    return (
        curStatus === BusinessStatusEnum.PROCESSED.value ||
        curStatus === BusinessStatusEnum.GARBAGE_CAN.value ||
        curStatus === BusinessStatusEnum.ORDER_FAILED.value
    )
}

export const checkBusinessStatusForInstruction = (curStatus, fromInstruction, toInstruction) => {
    if (checkStatusNonBusiness(curStatus)) {
        return true
    }
    if (curStatus.toString().startsWith(BusinessPageEnum.INSTRUCTIONS.value.toString())) {
        if (!toInstruction || fromInstruction.status <= toInstruction.status) {
            return true
        }
    }
    return false
}

export const checkNoDocument = project => {
    return (
        !project.estimation &&
        !project.instruction_internal &&
        !project.instruction_outsource &&
        !project.place_order &&
        !project.sale
    )
}
