import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import { getData, setPagination, setFilters, setSorter, setKeywords } from 'modules/master/product/product.slice'
import ProductListComponent from 'modules/master/product/ProductListComponent'

const ProductList = ({ state }) => {
    return (
        <div className="page-container with-sidebar">
            <ProductListComponent
                state={state}
                getData={getData}
                setPagination={setPagination}
                setFilters={setFilters}
                setSorter={setSorter}
                setKeywords={setKeywords}
            />
        </div>
    )
}

const mapState = state => ({ state: state.product })
export default connect(mapState)(ProductList)

ProductList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
}
