import React from 'react'
import { useParams } from 'react-router-dom'
import InsBackLink from 'components/InsBackLink'
import OutsourceDetailComponent from 'modules/master/outsource/OutsourceDetailComponent'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'
import Routes from 'routes'

const OutsourceDetail = () => {
    const { id } = useParams()

    const deleteOutsource = async () => {
        await api.delete(APP_URLS.OUTSOURCES_DETAIL.replace(':id', id))
        history.push(Routes.private.master.OUTSOURCE_LIST.path)
    }

    return (
        <div className="page-container with-sidebar mb-64">
            <InsBackLink />
            <OutsourceDetailComponent id={id} onDeleteOutsource={() => deleteOutsource()} />
        </div>
    )
}

export default OutsourceDetail
