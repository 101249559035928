import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'antd'
import InsInput from 'components/InsInput'
import InsButton from 'components/InsButton'
import { AddIcon, CloseIcon, EditIcon } from 'components/InsIcon'
import Routes from 'routes'
import InsFloatBar from 'components/InsFloatBar'
import InsHelpText from 'components/InsHelpText'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import InsTextArea from 'components/InsTextArea'
import InsModal from 'components/InsModal'

const CompanyForm = ({ isEdit, initialValues, onSubmit, modalConfirmSave, setModalConfirmSave }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => onSubmit(form.getFieldsValue(), null, false)}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className="ins-form pt-0"
                layout="vertical"
                form={form}
                size={'middle'}
                onFinish={values => onSubmit(values)}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <div className="ins-form-items">
                    <>
                        <h2 className="section-title mb-24">{t('common:Basic information')}</h2>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item
                                    name="company_code"
                                    label={t('column:Company code')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:Please enter company code'),
                                        },
                                    ]}
                                    validateTrigger={['onBlur']}
                                >
                                    <InsInput style={{ width: 424 }} maxLength={200} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item
                                    name="company_name"
                                    label={t('column:Company name')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:Please enter company name'),
                                        },
                                    ]}
                                    validateTrigger={['onBlur']}
                                >
                                    <InsInput style={{ width: 424 }} maxLength={200} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="company_name_kana" label={t('column:Company name kana')}>
                                    <InsInput style={{ width: 424 }} maxLength={200} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="address1" label={t('column:Address')}>
                                    <InsTextArea style={{ width: 424, height: 93 }} maxLength={200} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item
                                    name="phone_number"
                                    label={t('column:Phone number')}
                                    extra={<InsHelpText>{t('Help Phone number')}</InsHelpText>}
                                    rules={[
                                        {
                                            pattern: /^[0-9]?([-]?[\s]?[0-9])+$/,
                                            message: t('validation:Please enter half-width numbers'),
                                        },
                                    ]}
                                    validateTrigger={['onBlur']}
                                >
                                    <InsInput style={{ width: 280 }} maxLength={20} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="fax_number" label={t('column:Fax number')}>
                                    <InsInput style={{ width: 280 }} maxLength={20} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="note" label={t('column:Note')}>
                                    <InsTextArea style={{ width: 424, height: 66 }} maxLength={1000} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="ml-16">
                            <Col>
                                <Form.Item name="bank_info" label={t('column:Bank info')}>
                                    <InsTextArea style={{ width: 424, height: 66 }} maxLength={1000} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                </div>
                <>
                    <div style={{ marginTop: '100px' }}>
                        <InsFloatBar visible={true} className="with-sidebar">
                            <Row gutter={8} justify="end">
                                <Col>
                                    <InsButton
                                        buttonStyle="outline"
                                        buttonSize="big"
                                        centered
                                        width="120px"
                                        icon={<CloseIcon />}
                                        linkTo={Routes.private.master.COMPANY_LIST.path}
                                        buttonType="link"
                                    >
                                        {t('common:Cancel')}
                                    </InsButton>
                                </Col>
                                <Col>
                                    <InsButton
                                        buttonStyle="primary"
                                        buttonSize="big"
                                        centered
                                        icon={isEdit ? <EditIcon size={20} /> : <AddIcon />}
                                        isSubmitButton={true}
                                    >
                                        {isEdit
                                            ? t('navigation:{{entity}} update new', { entity: t('entity:Company') })
                                            : t('Company information new registration')}
                                    </InsButton>
                                </Col>
                            </Row>
                        </InsFloatBar>
                    </div>
                </>
            </Form>
        </>
    )
}

export default CompanyForm

CompanyForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    inModal: PropTypes.bool,
}
