import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import InsDragAndDrop from 'components/draggable/InsDragAndDrop'
import { moveDetail, removeDetail } from 'modules/business/estimation/estimation_detail.slice'
import EstimationDetailRow from 'modules/business/estimation/form/draggable/EstimationDetailRow'

const EstimationDetailDragAndDrop = React.memo(({ columnMode, details, detailsOrder, moveDetail, removeDetail }) => {
    const { t } = useTranslation()
    const isLess = columnMode === 2

    return (
        <div className="ins-drag-drop">
            <div className="ins-drag-drop-header">
                <div style={{ flex: '0 0 20px' }} />
                {!isLess ? (
                    <div style={{ flex: isLess ? 384 : 180 }}>{t('common:Title')}</div>
                ) : (
                    <div style={{ flex: isLess ? 384 : 180 }}>{t('column:Estimation model product name')}</div>
                )}
                {!isLess && (
                    <>
                        <div style={{ flex: 174 }}>{t('column:Spec')}</div>
                        <div style={{ flex: 116 }}>{t('column:Size')}</div>
                    </>
                )}
                <div className="text-center" style={{ flex: `0 0 ${isLess ? 62 : 64}px` }}>
                    {t('column:Quantity')}
                </div>
                <div className="text-center" style={{ flex: `0 0 ${isLess ? 48 : 38}px` }}>
                    {t('column:Unit')}
                </div>
                <div className="text-center" style={{ flex: `0 0 ${isLess ? 98 : 80}px` }}>
                    {t('column:Cost')}
                </div>
                <div className="text-center" style={{ flex: `0 0 ${isLess ? 80 : 74}px` }}>
                    {t('column:Unit price')}
                </div>
                <div className="text-center" style={{ flex: `0 0 ${isLess ? 76 : 65}px` }}>
                    {t('column:Tax rate')}
                </div>
                <div className="text-center" style={{ flex: `0 0 ${isLess ? 88 : 74}px` }}>
                    {t('column:Amount')}
                </div>
                <div style={{ flex: '0 0 24px' }} />
                <div style={{ flex: '0 0 24px' }} />
            </div>
            <div className="ins-draggable-body">
                <InsDragAndDrop
                    data={details}
                    order={detailsOrder}
                    onOrderChange={(srcIndex, desIndex) => moveDetail({ srcIndex, desIndex })}
                    onRemove={id => detailsOrder.length > 1 && removeDetail({ id })}
                    rowComponent={EstimationDetailRow}
                />
            </div>
        </div>
    )
})

const EstimationDragAndDrop = ({ columnMode, details, detailsOrder, moveDetail, removeDetail }) => {
    return (
        <EstimationDetailDragAndDrop
            columnMode={columnMode}
            details={details}
            detailsOrder={detailsOrder}
            moveDetail={moveDetail}
            removeDetail={removeDetail}
        />
    )
}

const mapState = state => ({
    details: state.estimationDetail.details,
    detailsOrder: state.estimationDetail.detailsOrder,
    columnMode: state.estimationDetail.columnMode,
})

const mapDispatch = {
    moveDetail,
    removeDetail,
}

export default connect(mapState, mapDispatch)(EstimationDragAndDrop)

EstimationDragAndDrop.propTypes = {
    columnMode: PropTypes.number.isRequired,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.array.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
}

EstimationDetailDragAndDrop.propTypes = {
    columnMode: PropTypes.number.isRequired,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.array.isRequired,
    moveDetail: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
}
