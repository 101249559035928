import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import PropTypes from 'prop-types'
import InsBackLink from 'components/InsBackLink'
import CompanyForm from 'modules/master/company/form/CompanyForm'
import { InsBlockLoader } from 'components/InsLoader'

const CompanyUpdate = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [company, setCompany] = useState(null)

    const updateCompany = async values => {
        const data = await api.put(APP_URLS.COMPANIES_DETAIL.replace(':id', id), values)
        if (data) {
            history.goBack()
        }
    }

    const getCompany = async id => {
        const data = await api.get(APP_URLS.COMPANIES_DETAIL.replace(':id', id))
        if (data) {
            setCompany(data)
            setLoading(false)
        }
    }

    useEffect(() => {
        getCompany(id)
    }, [id])

    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">{t('navigation:{{entity}} edit', { entity: t('entity:Company') })}</h1>

            {loading ? (
                <InsBlockLoader />
            ) : (
                <CompanyForm
                    modalConfirmSave={modalConfirmSave}
                    setModalConfirmSave={setModalConfirmSave}
                    isEdit={true}
                    initialValues={company}
                    onSubmit={(values, redirectTo, confirmSave = true) => {
                        if (confirmSave) setModalConfirmSave(true)
                        else updateCompany(values, redirectTo, confirmSave)
                    }}
                />
            )}
        </div>
    )
}

export default CompanyUpdate

CompanyUpdate.propTypes = {
    isEdit: PropTypes.bool,
}
