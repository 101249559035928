import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import SupplierForm from 'modules/master/supplier/SupplierForm'
import Routes from 'routes'

const SupplierCreateComponent = ({ inModal = false, onCreated, onCancel }) => {
    const createSupplier = async values => {
        const data = await api.post(APP_URLS.SUPPLIERS, values)
        if (data) {
            if (inModal) {
                onCreated && onCreated(data)
            } else {
                history.push(Routes.private.master.SUPPLIER_DETAIL.path.replace(':id', data.id))
            }
        }
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <SupplierForm
            modalConfirmSave={modalConfirmSave}
            setModalConfirmSave={setModalConfirmSave}
            inModal={inModal}
            initialValues={{ is_enabled: 1 }}
            onSubmit={(values, redirectTo, confirmSave = true) => {
                if (confirmSave) setModalConfirmSave(true)
                else {
                    createSupplier(values, redirectTo, confirmSave)
                    setModalConfirmSave(false)
                }
            }}
            onCancel={() => onCancel()}
        />
    )
}

export default SupplierCreateComponent

SupplierCreateComponent.propTypes = {
    inModal: PropTypes.bool,
    onCreated: PropTypes.func,
    onCancel: PropTypes.func,
}

SupplierCreateComponent.defaultProps = {
    inModal: false,
}
