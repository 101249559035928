import { createSlice } from '@reduxjs/toolkit'

const currentTime = new Date().getTime().toString()

const defaultDetails = {
    [`${currentTime}-headline`]: { isHeadline: true },
    [`${currentTime}-product`]: {
        quantity: 1,
        unit: '2',
        unit_cost: '0.00',
        unit_price: 0,
        tax_rate: '10',
        amount: 0,
    },
}
const defaultDetailsOrder = [`${currentTime}-headline`, `${currentTime}-product`]

const estimationDetail = createSlice({
    name: 'estimationDetail',
    initialState: {
        details: defaultDetails,
        detailsOrder: defaultDetailsOrder,
        columnMode: 1,
        updatedDetail: false,
    },
    reducers: {
        addDetail(state, action) {
            const currentTime = new Date().getTime().toString()
            const detailId = action.payload.isHeadline ? `${currentTime}-headline` : `${currentTime}-product`
            state.details[detailId] = action.payload.isHeadline
                ? { isHeadline: true }
                : {
                      isHeadline: false,
                      quantity: 1,
                      unit: '2',
                      unit_cost: '0.00',
                      unit_price: 0,
                      tax_rate: '10',
                      amount: 0,
                  }
            state.detailsOrder = [...state.detailsOrder, detailId]
        },
        updateDetail(state, action) {
            const { id, value } = action.payload
            state.details = {
                ...state.details,
                [id]: {
                    ...state.details[id],
                    ...value,
                },
            }
            state.updatedDetail = true
        },
        moveDetail(state, action) {
            const { srcIndex, desIndex } = action.payload
            if (srcIndex === desIndex) {
                return
            }
            let newOrder = [...state.detailsOrder]
            reOrder(newOrder, srcIndex, desIndex)
            state.detailsOrder = newOrder
        },
        removeDetail(state, action) {
            const { id } = action.payload
            let newDetails = { ...state.details }
            delete newDetails[id]
            state.details = newDetails
            state.detailsOrder = [...state.detailsOrder].filter(d => d !== id)
        },

        setState(state, action) {
            state.details = action.payload.details
            state.detailsOrder = action.payload.detailsOrder
        },

        setDefaultState(state) {
            state.details = defaultDetails
            state.detailsOrder = defaultDetailsOrder
            state.columnMode = 1
            state.updatedDetail = false
        },

        parseData(state, action) {
            let parseDetails = {}
            let parseDetailsOrder = []

            action.payload.map(detail => {
                const detailName = `${detail.id}-${detail.headline || detail.isHeadline ? 'headline' : 'product'}`
                parseDetails[detailName] = Object.assign({}, detail, {
                    isHeadline: detail.headline || detail.isHeadline,
                })
                parseDetailsOrder.push(detailName)
                return null
            })

            state.details = parseDetails
            state.detailsOrder = parseDetailsOrder
        },

        setColumnMode(state, action) {
            state.columnMode = action.payload
        },
    },
})

export default estimationDetail.reducer
export const {
    addDetail,
    updateDetail,
    moveDetail,
    removeDetail,
    setState,
    setDefaultState,
    parseData,
    setColumnMode,
} = estimationDetail.actions

const reOrder = (list, srcIndex, desIndex) => {
    const [removed] = list.splice(srcIndex, 1)
    list.splice(desIndex, 0, removed)
}
