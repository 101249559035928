import { createSlice } from '@reduxjs/toolkit'

const currentTime = new Date().getTime().toString()

const defaultDetails = {
    [`${currentTime}-product`]: {
        product_id: null,
        quantity: 1,
        unit: '1',
    },
}
const defaultDetailsOrder = [`${currentTime}-product`]

const shipmentProcess = createSlice({
    name: 'shipmentProcess',
    initialState: {
        details: defaultDetails,
        detailsOrder: defaultDetailsOrder,
    },
    reducers: {
        addDetail(state, action) {
            const currentTime = new Date().getTime().toString()
            const detailId = `${currentTime}-product`
            state.details[detailId] = {
                product_id: null,
                quantity: 0,
                unit: '1',
            }
            state.detailsOrder = [...state.detailsOrder, detailId]
        },
        updateDetail(state, action) {
            const { id, value } = action.payload
            state.details = {
                ...state.details,
                [id]: {
                    ...state.details[id],
                    ...value,
                },
            }
        },
        moveDetail(state, action) {
            const { srcIndex, desIndex } = action.payload
            if (srcIndex === desIndex) {
                return
            }
            let newOrder = [...state.detailsOrder]
            reOrder(newOrder, srcIndex, desIndex)
            state.detailsOrder = newOrder
        },
        removeDetail(state, action) {
            const { id } = action.payload
            let newDetails = { ...state.details }
            delete newDetails[id]
            state.details = newDetails
            state.detailsOrder = [...state.detailsOrder].filter(d => d !== id)
        },

        parseData(state, action) {
            let details = action.payload
            let parseDetails = {}
            let parseDetailsOrder = []
            details.map(d => {
                const detailId = `${d.id}-product`
                parseDetails[detailId] = Object.assign({}, d)
                parseDetailsOrder.push(detailId)
            })
            state.details = parseDetails
            state.detailsOrder = parseDetailsOrder
        },

        setState(state, action) {
            state.details = action.payload.details
            state.detailsOrder = action.payload.detailsOrder
        },

        setDefaultState(state) {
            state.details = defaultDetails
            state.detailsOrder = defaultDetailsOrder
        },
    },
})

export default shipmentProcess.reducer
export const {
    addDetail,
    updateDetail,
    moveDetail,
    removeDetail,
    parseData,
    setState,
    setDefaultState,
} = shipmentProcess.actions

const reOrder = (list, srcIndex, desIndex) => {
    const [removed] = list.splice(srcIndex, 1)
    list.splice(desIndex, 0, removed)
}
