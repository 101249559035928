export const isHeadline = detail => {
    return (
        detail.isHeadline ||
        !(
            detail.title ||
            // fix instruction: extend row has product_name
            // detail.product_name ||
            detail.unit ||
            detail.tax_rate ||
            detail.processed_product_classification
        )
    )
}
