import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import Routes from 'routes'
import history from 'helpers/history.helper'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon } from 'components/InsIcon'
import InsTab from 'components/InsTab'
import InsLoader from 'components/InsLoader'
import SupplierListSearch from 'modules/master/supplier/SupplierListSearch'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'

const SupplierListComponent = ({
    inModal = false,
    onSelect,
    state,
    getData,
    setFilters,
    setSorter,
    setKeywords,
    setPagination,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const supplierColumns = [
        {
            title: t('column:Supplier code'),
            dataIndex: 'supplier_code',
            sorter: true,
            render: (text, record) => (
                <InsButton
                    buttonStyle="plain"
                    onClick={() => {
                        if (inModal) {
                            onSelect(record)
                        } else {
                            history.push(Routes.private.master.SUPPLIER_DETAIL.path.replace(':id', record.id))
                        }
                    }}
                >
                    {text}
                </InsButton>
            ),
        },
        { title: t('column:Supplier name'), dataIndex: 'supplier_name', sorter: true },
        {
            title: t('column:Address'),
            dataIndex: 'address1',
            sorter: true,
            render: (text, record) => (
                <>
                    {getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, record.prefecture_code)}
                    &nbsp;{record.address1}&nbsp;{record.address2}
                </>
            ),
        },
        { title: t('column:Phone number'), dataIndex: 'phone_number', sorter: true },
        { title: t('column:Fax number'), dataIndex: 'fax_number', sorter: true },
        { title: t('column:Note'), dataIndex: 'note', sorter: true },
    ]

    const updateFilters = obj => {
        const newFilters = Object.assign({ is_enabled: state.filters.is_enabled }, obj)
        dispatch(setFilters(newFilters))
        getSuppliers(state.pagination, newFilters)
    }

    const tabChangeHandler = key => {
        dispatch(setKeywords([]))
        const newFilters = Object.assign({}, state.filters, {
            keyword: [],
            is_enabled: key,
        })
        const newSorter = {}
        dispatch(setSorter(newSorter))
        dispatch(setFilters(newFilters))
        const newPagination = {
            current: 1,
            pageSize: 100,
            total: 1,
        }
        dispatch(setPagination(newPagination))
        getSuppliers(newPagination, newFilters, newSorter)
    }

    const getSuppliers = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setSorter(sorter))
        dispatch(getData({ pagination, filters, sorter }))
    }

    useEffect(() => {
        getSuppliers()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {!inModal && (
                <Row className="mt-8 mb-16" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-0">
                            {t('navigation:{{entity}} management', { entity: t('entity:Supplier') })}
                        </h1>
                    </Col>
                    <Col>
                        <InsButton
                            width={120}
                            buttonType="link"
                            linkTo={Routes.private.master.SUPPLIER_CREATE.path}
                            icon={<AddIcon size={24} />}
                            buttonStyle="secondary"
                        >
                            {t('navigation:{{entity}} create', { entity: t('entity:Supplier') })}
                        </InsButton>
                    </Col>
                </Row>
            )}
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <SupplierListSearch
                        inModal={inModal}
                        onSearch={condition => updateFilters(condition)}
                        onReset={() => updateFilters({})}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsTab
                        type="card"
                        metaComponent={
                            <InsPagination
                                {...state.pagination}
                                onChange={pagination => getSuppliers(pagination)}
                                pageSizeOptions={inModal ? [15, 25, 50] : [100, 200, 300]}
                            />
                        }
                        onChange={key => tabChangeHandler(key)}
                        activeKey={state.filters.is_enabled.toString()}
                        defaultActiveKey="1"
                    >
                        <Tabs.TabPane tab={t('common:Enabled')} key="1" />
                        <Tabs.TabPane tab={t('common:Disabled')} key="0" />
                    </InsTab>
                    <InsDataTable
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        tableStyle="compact"
                        striped
                        bordered
                        data={state.data}
                        columns={supplierColumns}
                        onChange={(pagination, filter, sorter) => {
                            getSuppliers(state.pagination, state.filters, sorter)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        ctlSortOrder={true}
                        scroll={inModal ? { y: 400 } : null}
                    />
                </Col>
            </Row>
        </>
    )
}

export default SupplierListComponent

SupplierListComponent.propTypes = {
    inModal: PropTypes.bool,
    onSelect: PropTypes.func,
    state: PropTypes.shape(BaseStatePropShape),
    getData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
}

SupplierListComponent.defaultProps = {
    inModal: false,
}
