import React from 'react'
import PropTypes from 'prop-types'
import DestinationGroup from 'modules/business/redSlip/redSlipProject/DestinationGroup'
import DetailRow from 'modules/business/redSlip/redSlipProject/DetailRow'

const ProjectGroup = ({ sale }) => {
    const renderSale = sale => {
        const { delivery_destinations, details } = sale
        return (
            <div className="project-group-body">
                {delivery_destinations && delivery_destinations.length > 0 ? (
                    delivery_destinations.map(destination => (
                        <DestinationGroup destination={destination} key={destination.id} />
                    ))
                ) : (
                    <div className="destination-group-body">
                        {(details || []).map(detail => (
                            <DetailRow detail={detail} key={detail.id} />
                        ))}
                    </div>
                )}
            </div>
        )
    }

    return <div className="project-group">{renderSale(sale)}</div>
}

export default ProjectGroup

ProjectGroup.propTypes = {
    sale: PropTypes.object.isRequired,
}
