import PropTypes from 'prop-types'

export const PaginationPropTypes = PropTypes.shape({
    current: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
})

export const FiltersPropTypes = PropTypes.object

export const SorterPropTypes = PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
})

export const BaseStatePropShape = {
    loading: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PaginationPropTypes,
    filters: FiltersPropTypes,
    sorter: SorterPropTypes,
    selection: PropTypes.array.isRequired,
}
