import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form, Row, Select } from 'antd'
import { updateDetail } from 'modules/business/estimation/estimation_detail.slice'
import ProductSelect from 'modules/master/product/ProductSelect'
import InsInput from 'components/InsInput'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsSelect from 'components/select/InsSelect'
import { getClassificationsByType } from 'helpers/classification.helper'
import { ClearInputIcon, CommentIcon, SubdirectoryArrowRightIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsAutoComplete from 'components/InsAutoComplete'
import { isEmpty, rdStr } from 'helpers/string.helper'
import InsInputNumber2 from 'components/InsInputNumber2'
import InsInputNumber from 'components/InsInputNumber'
import InsInputWithEditBehavior from 'components/InsInputWithEditBehavior'
import { isHeadline } from 'helpers/detail.helper'
import InsTextArea from 'components/InsTextArea'

const EstimationDetailRow = React.memo(({ id, value, updateDetail, columnMode, currentUser }) => {
    const { t } = useTranslation()
    const isLess = columnMode === 2
    const [noteVisible, setNoteVisible] = useState(!isEmpty(value.note))
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(value)
    }, [])

    let timeout = null
    const changeHandle = () => {
        if (timeout !== null) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            syncFormData(product)
        }, 300)
    }

    const syncFormData = product => {
        updateDetail({
            id,
            value: Object.assign(
                {},
                { product: product },
                form.getFieldsValue(),
                isLess ? { spec: null, size: null } : {}
            ),
        })
    }

    const calculateDetailAmount = () => {
        let amount = form.getFieldValue('quantity') * form.getFieldValue('unit_price')
        if (amount < 0 || isNaN(amount)) {
            amount = 0
        }
        form.setFieldsValue({
            amount: amount,
        })
    }

    const [product, setProduct] = useState({})

    const handlerProductSelect = async productId => {
        const product = await api.get(APP_URLS.PRODUCTS_DETAIL.replace(':id', productId))
        if (product) {
            setProduct(product)
            form.setFieldsValue({
                product: product,
                product_id: product.id,
                product_name: product.product_name,
                unit: product.unit,
                unit_cost: product.unit_cost,
                unit_price: product.price,
                amount: form.getFieldValue('quantity') * product.price || 0,
            })
            syncFormData(product)
            setProductNameEditable(true)
        }
    }

    const suggestionList = currentUser.processing_product ? currentUser.processing_product.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const suggestionListSpec = currentUser.processing_spec ? currentUser.processing_spec.split(',') : []
    const [resultSpec, setResultSpec] = useState(suggestionListSpec)
    const handleSuggestionSpec = value => {
        setResultSpec(suggestionListSpec.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const suggestionListSize = currentUser.processing_size ? currentUser.processing_size.split(',') : []
    const [resultSize, setResultSize] = useState(suggestionListSize)
    const handleSuggestionSize = value => {
        setResultSize(suggestionListSize.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const [productNameEditable, setProductNameEditable] = useState(!!value.product_name)

    const clearProduct = () => {
        setProductNameEditable(!productNameEditable)
        form.setFieldsValue({
            product: null,
            product_id: undefined,
            product_name: null,
            quantity: 0,
            unit: '2',
            unit_cost: '0.00',
            unit_price: 0,
            tax_rate: '10',
            amount: 0,
            note: null,
        })
        changeHandle()
    }

    return (
        <Form form={form} onValuesChange={changeHandle} name={rdStr()}>
            {isHeadline(value) ? (
                <Form.Item name="headline">
                    <InsInput placeholder={t('column:Headline')} />
                </Form.Item>
            ) : (
                <>
                    <Row align="middle">
                        {isLess ? (
                            <Form.Item style={{ flex: isLess ? 384 : 180 }}>
                                <Form.Item name="product_id" noStyle>
                                    <ProductSelect
                                        record={value.product}
                                        style={{ width: '100%', display: productNameEditable ? 'none' : 'inline-flex' }}
                                        placeholder="品番・品名"
                                        showCreateButton={false}
                                        showDetailLink={false}
                                        // showListLink={false}
                                        onSelect={productId => handlerProductSelect(productId)}
                                        onChange={productId => handlerProductSelect(productId)}
                                    />
                                </Form.Item>

                                <div className="product-name-editable">
                                    <Form.Item name="product_name" noStyle>
                                        <InsInput
                                            placeholder="タイトル"
                                            style={{ display: productNameEditable ? 'inline-flex' : 'none' }}
                                            onChange={value => value.length < 1 && clearProduct()}
                                        />
                                    </Form.Item>
                                    {productNameEditable && (
                                        <ClearInputIcon
                                            size={16}
                                            className="mdi-clear-input"
                                            onClick={() => clearProduct()}
                                        />
                                    )}
                                </div>
                            </Form.Item>
                        ) : (
                            <Form.Item name="product_name" style={{ flex: isLess ? 384 : 180 }}>
                                <InsAutoComplete options={result} onSearch={handleSuggestion} placeholder="タイトル" />
                            </Form.Item>
                        )}
                        {!isLess && (
                            <>
                                <Form.Item name="spec" style={{ flex: 174 }}>
                                    <InsAutoComplete
                                        options={resultSpec}
                                        onSearch={handleSuggestionSpec}
                                        placeholder={t('column:Spec')}
                                    />
                                </Form.Item>
                                <Form.Item name="size" style={{ flex: 116 }}>
                                    <InsAutoComplete
                                        options={resultSize}
                                        onSearch={handleSuggestionSize}
                                        placeholder={t('column:Size')}
                                    />
                                </Form.Item>
                            </>
                        )}
                        <Form.Item name="quantity" style={{ flex: `0 0 ${isLess ? 62 : 64}px` }}>
                            <InsInputNumber2
                                min={1}
                                onChange={value => {
                                    form.setFieldsValue({
                                        quantity: value,
                                    })
                                    calculateDetailAmount()
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="unit"
                            className="form-item-select-hide-search"
                            style={{ flex: `0 0 ${isLess ? 48 : 38}px` }}
                        >
                            <ClassificationSelect
                                typeEnum={ClassificationTypeEnum.PRODUCT_UNIT}
                                showCreateButton={false}
                            />
                        </Form.Item>
                        <Form.Item name="unit_cost" style={{ flex: `0 0 ${isLess ? 98 : 80}px` }}>
                            {isLess ? <InsInputWithEditBehavior /> : <InsInputNumber min={0} />}
                        </Form.Item>
                        <Form.Item
                            name="unit_price"
                            style={{ flex: `0 0 ${isLess ? 80 : 74}px` }}
                            onChange={() => calculateDetailAmount()}
                        >
                            <InsInputNumber min={0} />
                        </Form.Item>
                        <Form.Item name="tax_rate" style={{ flex: `0 0 ${isLess ? 76 : 65}px` }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.TAX_RATE.value).map(c => (
                                    <Select.Option key={c.item_value} value={c.item_value}>
                                        {`${c.text}%`}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            className={isLess ? 'border-transparent' : ''}
                            style={{ flex: `0 0 ${isLess ? 88 : 74}px` }}
                        >
                            <InsInputNumber disabled min={0} />
                        </Form.Item>
                        <CommentIcon
                            className={`mr-4 pointer ${noteVisible ? 'color-pantone-2738-c' : 'color-blue-gray-200'}`}
                            onClick={() => setNoteVisible(!noteVisible)}
                        />
                    </Row>
                    <Row align="middle" className="mt-4" style={{ display: noteVisible ? 'flex' : 'none' }}>
                        <SubdirectoryArrowRightIcon size={16} className="mdi_subdirectory_arrow_right mr-4" />
                        <div className="fw-bold mr-4">{'コメント'}</div>
                        <Form.Item name="note" style={{ flex: 1, marginBottom: 0 }}>
                            <InsTextArea
                                className="color-gray-678 textarea-auto-size"
                                placeholder="コメント入力　※コメントは印刷されません"
                                autoSize={true}
                            />
                        </Form.Item>
                    </Row>
                </>
            )}
        </Form>
    )
})

const mapState = (state, props) => ({
    value: state.estimationDetail.details[props.id],
    columnMode: state.estimationDetail.columnMode,
    currentUser: state.auth.user,
})

const mapDispatch = { updateDetail }
export default connect(mapState, mapDispatch)(EstimationDetailRow)

EstimationDetailRow.propTypes = {
    id: PropTypes.number,
    value: PropTypes.object,
    updateDetail: PropTypes.func,
    columnMode: PropTypes.number,
    currentUser: PropTypes.object.isRequired,
}
