import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import history from 'helpers/history.helper'
import Routes from 'routes'
import { Col, Form, Input, Radio, Row, Select } from 'antd'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsSelect from 'components/select/InsSelect'
import InsButton from 'components/InsButton'
import { AddIcon, CloseIcon, EditIcon } from 'components/InsIcon'
import InsFloatBar from 'components/InsFloatBar'
import { getClassificationsByType } from 'helpers/classification.helper'
import SupplierSelect from 'modules/master/supplier/SupplierSelect'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { handleScrollOnFocus } from 'helpers/form_event.helper'
import InsInputNumber from 'components/InsInputNumber'
import { checkAdmin } from 'helpers/permission.helper'
import InsModal from 'components/InsModal'
import InsInput from 'components/InsInput'

const ProductForm = ({
    isEdit,
    initialValues = {},
    onSubmit,
    onCancel,
    inModal = false,
    modalConfirmSave,
    setModalConfirmSave,
}) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const { id } = useParams()
    const [dirtyFields, setDirtyFields] = useState([])

    const productClassification1 = getClassificationsByType(ClassificationTypeEnum.PRODUCT_1.value)
    const productClassification2 = getClassificationsByType(ClassificationTypeEnum.PRODUCT_2.value)
    const productUnit = getClassificationsByType(ClassificationTypeEnum.PRODUCT_UNIT.value)

    initialValues = Object.assign(
        {
            product_code: '',
            product_name: '',
            product_classification1: productClassification1.length > 0 && productClassification1[0].value,
            product_classification2: productClassification2.length > 0 && productClassification2[0].value,
            unit: productUnit.length > 0 && productUnit[0].value,
            price: '',
            unit_cost: '',
            purchase_cost: '',
            main_supplier: '',
            note: '',
            is_stock_control: 1,
            is_enabled: 1,
            remain_quantity: 0,
        },
        initialValues
    )
    const [stockControl, setStockControl] = useState(initialValues.is_stock_control)

    const addDirtyField = field => {
        if (dirtyFields.indexOf(field) > -1) return
        setDirtyFields([...dirtyFields, field])
    }
    const checkExistsCode = async (rule, value) => {
        if (isEdit && initialValues.product_code.trim() === value.trim()) {
            return Promise.resolve()
        }

        if (value) {
            const data = await api.post(APP_URLS.PRODUCT_CHECK_CODE, { product_code: value })
            if (data && data.is_existed) {
                return Promise.reject(t('validation:Product code is exist'))
            }
        }
        return Promise.resolve()
    }

    return (
        <>
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => onSubmit(form.getFieldsValue(), null, false)}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <Form
                className={`${inModal ? 'ins-form pt-32 ml-32' : 'ins-form pt-0'}`}
                layout="vertical"
                form={form}
                onFinish={values => onSubmit(values)}
                onValuesChange={(changedValues, allValues) => {
                    if (changedValues.hasOwnProperty('is_stock_control'))
                        setStockControl(changedValues.is_stock_control)
                }}
                initialValues={initialValues}
                onFocus={handleScrollOnFocus}
            >
                <div className="ins-form-items">
                    <Row gutter={16}>
                        <Col>
                            <Form.Item
                                name="product_code"
                                label={t('column:Product code')}
                                rules={[
                                    { required: true, message: t('validation:Please enter product code') },
                                    {
                                        validator: checkExistsCode,
                                    },
                                ]}
                                validateTrigger={['onBlur']}
                            >
                                <InsInput style={{ width: 140 }} maxLength={20} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item
                                label={t('common:Product name')}
                                name="product_name"
                                onBlur={() => addDirtyField('product_name')}
                                validateTrigger={false}
                                rules={[{ required: true, message: t('validation:Please enter product name') }]}
                            >
                                <Input style={{ width: 280 }} maxLength={255} autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item label={t('common:Product classification1')} name="product_classification1">
                                <InsSelect onSelect={value => console.log(value)} style={{ width: 140 }}>
                                    {productClassification1.map(clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item label={t('common:Product classification2')} name="product_classification2">
                                <InsSelect
                                    onSelect={value => console.log(value)}
                                    style={{ minWidth: 140 }}
                                    dropdownInnerStyle={{ minWidth: 200 }}
                                >
                                    {productClassification2.map(clf => (
                                        <Select.Option key={clf.value} value={clf.value} title={clf.text}>
                                            {clf.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item label={t('common:Unit')} name="unit">
                                <InsSelect onSelect={value => console.log(value)} style={{ width: 112 }}>
                                    {productUnit.map(clf => (
                                        <Select.Option key={clf.value} value={clf.value}>
                                            {clf.text}
                                        </Select.Option>
                                    ))}
                                </InsSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item label={t('common:Price')} name="price">
                                <Input type="number" style={{ width: 104 }} autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item label={t('common:Unit cost')} name="unit_cost" style={{ width: 104 }}>
                                <Input type="number" autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item label={t('common:Purchase cost')} name="purchase_cost" style={{ width: 104 }}>
                                <Input type="number" autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="main_supplier_id" label={t('Main supplier')}>
                                <SupplierSelect record={initialValues.main_supplier} style={{ width: 280 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={20} md={16} lg={12}>
                            <Form.Item label={t('column:Note')} name="note">
                                <Input.TextArea rows={5} style={{ width: 368 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item label={t('Stock management')} name="is_stock_control">
                                <Radio.Group>
                                    <Radio value={1}>{t('common:Yes')}</Radio>
                                    <Radio value={0}>{t('common:No')}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    {stockControl === 1 && (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="remain_quantity"
                                    label={t('column:Stock quantity')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:Please enter {{item}}', {
                                                item: t('column:Stock quantity'),
                                            }),
                                        },
                                        {
                                            type: 'number',
                                            message: t('validation:Please enter remain quantity in number'),
                                        },
                                    ]}
                                >
                                    <InsInputNumber
                                        disabled={!checkAdmin()}
                                        className="no-spinner"
                                        style={{ width: 104 }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={16} className="mb-36">
                        <Col>
                            <Form.Item label={t('Usage status')} name="is_enabled">
                                <Radio.Group>
                                    <Radio value={1}>{t('common:Enabled')}</Radio>
                                    <Radio value={0}>{t('common:Disabled')}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <InsFloatBar visible={true} className="with-sidebar">
                    <Row gutter={8} justify={inModal ? 'center' : 'end'}>
                        <Col>
                            <InsButton
                                buttonStyle="outline"
                                buttonSize="big"
                                centered
                                width="120px"
                                icon={<CloseIcon />}
                                onClick={() => {
                                    if (!inModal) {
                                        isEdit
                                            ? history.push(Routes.private.master.PRODUCT_DETAIL.path.replace(':id', id))
                                            : history.push(Routes.private.master.CUSTOMER_LIST.path)
                                    } else {
                                        onCancel && onCancel()
                                    }
                                }}
                            >
                                {t('common:Cancel')}
                            </InsButton>
                        </Col>
                        {isEdit ? (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<EditIcon size={20} />}
                                    isSubmitButton={true}
                                >
                                    {isEdit
                                        ? t('navigation:{{entity}} update new', { entity: t('entity:Product') })
                                        : t('navigation:{{entity}} create new', { entity: t('entity:Product') })}
                                </InsButton>
                            </Col>
                        ) : (
                            <Col>
                                <InsButton
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    centered
                                    icon={<AddIcon />}
                                    isSubmitButton={true}
                                >
                                    {isEdit
                                        ? t('navigation:{{entity}} update new', { entity: t('entity:Product') })
                                        : t('navigation:{{entity}} create new', { entity: t('entity:Product') })}
                                </InsButton>
                            </Col>
                        )}
                    </Row>
                </InsFloatBar>
            </Form>
        </>
    )
}

export default ProductForm

ProductForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    inModal: PropTypes.bool,
    onCancel: PropTypes.func,
}

ProductForm.defaultTypes = {
    inModal: false,
}
