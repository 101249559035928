import React from 'react'
import moment from 'moment'
import { Col, Row, Select } from 'antd'
import InsCollapse from 'components/InsCollapse'
import InsInputSearch from 'components/InsInputSearch'
import InsSelect from 'components/select/InsSelect'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import InsDatePicker from 'components/datepicker/InsDatePicker'

const CollapseDemo = () => {
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">Collapse Demo</h1>
            <Row gutter={[8, 16]} align="middle">
                <Col span={24}>
                    <InsCollapse
                        header={<InsInputSearch style={{ width: '340px' }} placeholder="顧客名、案件番号、件名など" />}
                    >
                        <h1>Content</h1>
                    </InsCollapse>
                </Col>
                <Col span={24}>
                    <InsCollapse
                        align="right"
                        header={
                            <Row align="middle">
                                <Col flex="auto">
                                    <InsSelect defaultValue="0" style={{ width: '140px' }} className="mr-4">
                                        <Select.Option value="0">案件管理</Select.Option>
                                        <Select.Option value="1">見積書管理</Select.Option>
                                        <Select.Option value="2">作業指示書管理</Select.Option>
                                        <Select.Option value="3">発注書管理</Select.Option>
                                        <Select.Option value="4">納品書管理</Select.Option>
                                        <Select.Option value="5">送り状管理</Select.Option>
                                        <Select.Option value="6">請求書管理</Select.Option>
                                    </InsSelect>
                                    <InsSelect defaultValue="0" style={{ width: '170px' }}>
                                        <Select.Option value="0">全てのステータス</Select.Option>
                                        <Select.Option value="1">ステータス1</Select.Option>
                                        <Select.Option value="2">ステータス2</Select.Option>
                                    </InsSelect>
                                </Col>
                                <Col>
                                    <InsDateRangePicker
                                        className="mr-4"
                                        defaultValue={[
                                            moment('2020/01/17', 'YYYY/MM/DD'),
                                            moment('2020/03/17', 'YYYY/MM/DD'),
                                        ]}
                                    />
                                </Col>
                                <Col>
                                    <InsInputSearch
                                        style={{ width: '260px' }}
                                        placeholder="顧客名、案件番号、件名など"
                                    />
                                </Col>
                            </Row>
                        }
                    >
                        <Row>
                            <Col>
                                <InsDatePicker />
                            </Col>
                        </Row>
                        <Row className="mt-16">
                            <Col>
                                <InsSelect
                                    placeholder="顧客区分"
                                    onSelect={value => console.log(value)}
                                    style={{ width: '200px' }}
                                    searchOptions={true}
                                >
                                    <Select.Option value="Option 0">Option 0</Select.Option>
                                    <Select.Option value="Option 1">Option 1</Select.Option>
                                    <Select.Option value="Option 2">Option 2</Select.Option>
                                </InsSelect>
                            </Col>
                        </Row>
                    </InsCollapse>
                </Col>
            </Row>
        </div>
    )
}

export default CollapseDemo
