import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { EditIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import BusinessPageEnum from 'enums/business_page.enum'
import Routes from 'routes'
import BusinessStatusEnum from 'enums/business_status.enum'

const FloatBarButtonEdit = ({ data, selectedIds, documentType }) => {
    const { t } = useTranslation()

    const isEstimationOrderReceived = estimation => estimation.status === BusinessStatusEnum.ORDER_RECEIVED.value
    const isProjectOrderFailed = project => project.status === BusinessStatusEnum.ORDER_FAILED.value

    const isSaleProcessed = sale => sale.status === BusinessStatusEnum.SALE_PROCESSED.value
    const isSaleShipped = sale => sale.status === BusinessStatusEnum.SALE_SHIPPED.value

    const isInvoiceCreated = invoice => invoice.status === BusinessStatusEnum.BILL_CREATING.value

    const isRedSlipCreated = redSlip => redSlip.status === BusinessStatusEnum.RED_SLIP_CREATING.value

    // handle Edit
    const editLinkByDoc = () => {
        const doc = data.find(d => d.id === selectedIds[0])
        if (documentType === BusinessPageEnum.ESTIMATIONS.value) {
            return (
                Routes.private.business.PROJECT_DETAIL.path.replace(':id', doc.project && doc.project.id) +
                '/estimations' +
                (isEstimationOrderReceived(doc) || isProjectOrderFailed(doc.project) ? '' : '/update')
            )
        } else if (documentType === BusinessPageEnum.INSTRUCTIONS.value) {
            return (
                Routes.private.business.PROJECT_DETAIL.path.replace(':id', doc.project && doc.project.id) +
                `/${doc.instruction_classification === 2 ? 'instruction-outsource' : 'instruction-internal'}` +
                '/update'
            )
        } else if (documentType === BusinessPageEnum.PURCHASE_ORDER.value) {
            return (
                Routes.private.business.PROJECT_DETAIL.path.replace(
                    ':id',
                    doc.project_for_list && doc.project_for_list.id
                ) +
                '/place-orders' +
                '/update'
            )
        } else if (documentType === BusinessPageEnum.SALES.value) {
            return (
                Routes.private.business.PROJECT_DETAIL.path.replace(':id', doc.project && doc.project.id) +
                '/sales' +
                (isSaleProcessed(doc) || isSaleShipped(doc.project) ? '' : '/update')
            )
        } else if (documentType === BusinessPageEnum.INVOICES.value) {
            return (
                Routes.private.business.PROJECT_DETAIL.path.replace(
                    ':id',
                    (doc.project && doc.project.id) ||
                        (doc.summary_invoice_projects[0] && doc.summary_invoice_projects[0]?.id)
                ) +
                '/invoices' +
                (isInvoiceCreated(doc) ? '/update' : '')
            )
        } else if (documentType === BusinessPageEnum.RED_SLIPS.value) {
            return (
                Routes.private.business.PROJECT_DETAIL.path.replace(':id', doc.project && doc.project.id) +
                '/red-slips' +
                (isRedSlipCreated(doc) ? '/update' : '')
            )
        }
    }

    const editLabel = () => {
        if (documentType === BusinessPageEnum.ESTIMATIONS.value) {
            return t('見積書編集')
        } else if (documentType === BusinessPageEnum.INSTRUCTIONS.value) {
            return t('作業指示書編集')
        } else if (documentType === BusinessPageEnum.PURCHASE_ORDER.value) {
            return t('発注書編集')
        } else if (documentType === BusinessPageEnum.SALES.value) {
            return t('納品書編集')
        } else if (documentType === BusinessPageEnum.INVOICES.value) {
            return t('請求書編集')
        } else if (documentType === BusinessPageEnum.RED_SLIPS.value) {
            return t('赤伝編集')
        }
    }

    return (
        <>
            <InsButton
                icon={<EditIcon size={16} className="color-pantone-2738-c" />}
                buttonStyle="default"
                className="mr-8"
                style={{ minWidth: 112 }}
                buttonType="link"
                linkTo={editLinkByDoc()}
            >
                {editLabel()}
            </InsButton>
        </>
    )
}

export default FloatBarButtonEdit

FloatBarButtonEdit.propTypes = {
    data: PropTypes.any,
    selectedIds: PropTypes.array,
    documentType: PropTypes.any,
}
