import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UnfoldLessIcon, UnfoldMoreIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'

const InsExpandIcon = ({ expanded, onExpand, record }) => {
    const { t } = useTranslation()
    let icon, text
    if (expanded) {
        icon = <UnfoldLessIcon size={16} />
        text = t('common:Close')
    } else {
        icon = <UnfoldMoreIcon size={16} />
        text = t('common:Detail')
    }
    return (
        <InsButton buttonStyle="plain" onClick={e => onExpand(record, e)} suffixIcon={icon}>
            {text}
        </InsButton>
    )
}

InsExpandIcon.propTypes = {
    expanded: PropTypes.bool.isRequired,
    onExpand: PropTypes.func.isRequired,
    record: PropTypes.any.isRequired,
}

export default InsExpandIcon
