import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsPagination from 'components/datatable/InsPagination'
import { AddIcon, ArrowDropdownIcon } from 'components/InsIcon'
import InsTab from 'components/InsTab'
import ShipmentListSearch from 'modules/business/shipment/ShipmentListSearch'
import InsDropdown from 'components/dropdown/InsDropdown'
import history from 'helpers/history.helper'
import Routes from 'routes'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ShipmentProcessModal from 'modules/business/shipment/ShipmentProcessModal'
import IncomingDataTable from 'modules/business/shipment/datatable/IncomingDataTable'
import OutgoingDataTable from 'modules/business/shipment/datatable/OutgoingDataTable'
import {
    getData,
    setData,
    setFilters,
    setSorter,
    setLoading,
    setKeywords,
    setPagination,
} from 'modules/business/shipment/shipment.slice'
import { DisplayShipmentTypeEnum, ShipmentType } from 'enums/shipment.enum'
import { can } from 'helpers/permission.helper'
import { setDefaultState, updateDetail } from 'modules/business/shipment/shipment_process.slice'
import moment from 'moment'
import NavigationEnum from 'enums/navigation.enum'

const ShipmentList = ({ state, setDefaultState }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    let { path, url } = useRouteMatch()
    const [shipmentProcessModal, setShipmentProcessModal] = useState(false)

    const getShipments = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(setData([]))
        dispatch(setLoading(true))
        dispatch(getData({ pagination, filters, sorter }))
    }

    const updateFilters = obj => {
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        const newFilters = Object.assign({}, state.filters, obj)
        dispatch(setPagination(newPagination))
        dispatch(setFilters(newFilters))
        getShipments(newPagination, newFilters)
    }

    const setDefaultFilters = (shipmentType, displayShipmentType) => {
        const filter = {
            'shipment_type': shipmentType,
            'display_shipment_type': displayShipmentType ?? 1, //1:display by product, 2:display by item
            'time:gte': moment().subtract(2, 'months').format('YYYY/MM/DD 00:00:01'),
            'time:lte': moment().format('YYYY/MM/DD 23:59:59'),
        }
        if (
            displayShipmentType === DisplayShipmentTypeEnum.PRODUCT.value &&
            (state?.sorter?.field?.includes('id') || state?.sorter?.field?.includes('delivery_date'))
        ) {
            let orderBy = 'id'
            if (shipmentType === ShipmentType.INCOMING.value) orderBy = 'placeOrder|delivery_date'
            else orderBy = 'delivery_date'
            dispatch(setSorter(Object.assign({}, state.sorter, { field: orderBy })))
        } else {
            dispatch(setSorter(Object.assign({}, state.sorter, { field: 'id' })))
        }
        dispatch(setFilters(filter))
    }

    useEffect(() => {
        if (state.filters['shipment_type'] === ShipmentType.INCOMING.value) {
            history.push(`${Routes.private.business.SHIPMENT.path}/incoming`)
        } else if (state.filters['shipment_type'] === ShipmentType.OUTGOING.value) {
            history.push(`${Routes.private.business.SHIPMENT.path}/outgoing`)
        }
        getShipments()
    }, [state.filters['shipment_type'], state.filters['display_shipment_type']])

    return (
        <>
            <div className="page-container" style={{ marginBottom: 64 }}>
                {shipmentProcessModal && (
                    <ShipmentProcessModal
                        isEdit={false}
                        visible={shipmentProcessModal}
                        onCancel={() => {
                            setShipmentProcessModal(false)
                            updateFilters()
                        }}
                    />
                )}
                <Row className="mt-8 mb-16" justify="space-between">
                    <Col>
                        <h1 className="page-title mb-0">入荷･出荷</h1>
                    </Col>
                    <Col>
                        <Row justify="space-between">
                            <Col>
                                {can('SHIPMENT_EDITABLE') && (
                                    <InsDropdown
                                        onSelect={key => {
                                            if (key.key === 'key1') {
                                                history.push(
                                                    Routes.private.business.PROJECT_CREATE.path +
                                                        '?from=' +
                                                        NavigationEnum.SHIPMENTS,
                                                    {
                                                        is_purchase_order: true,
                                                    }
                                                )
                                            }
                                            if (key.key === 'key2') {
                                                setDefaultState()
                                                setShipmentProcessModal(true)
                                            }
                                        }}
                                        trigger={'click'}
                                        options={[
                                            { key: 'key1', text: '仕入れ発注', disabled: !can('PROJECT_EDITABLE') },
                                            { key: 'key2', text: '出荷処理' },
                                        ]}
                                    >
                                        <InsButton
                                            width={146}
                                            suffixIcon={<ArrowDropdownIcon />}
                                            icon={<AddIcon size={24} />}
                                            buttonStyle="secondary"
                                        >
                                            {t('入出荷処理')}
                                        </InsButton>
                                    </InsDropdown>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Col span={24}>
                        <ShipmentListSearch
                            updateFilters={filters => updateFilters(filters)}
                            onChangeDisplayShipmentType={value => {
                                dispatch(setData([]))
                                dispatch(setLoading(true))
                                dispatch(setKeywords([]))
                                setDefaultFilters(ShipmentType.INCOMING.value, value)
                            }}
                            activeKey={state.filters['display_shipment_type']}
                        />
                    </Col>
                </Row>
                <Row gutter={[8, 16]} align="middle">
                    <Col span={24} className={'shipment-datatable'}>
                        <InsTab
                            type="card"
                            metaComponent={
                                <InsPagination
                                    {...state.pagination}
                                    onChange={pagination => {
                                        getShipments(pagination)
                                    }}
                                    pageSizeOptions={[100, 200, 300]}
                                />
                            }
                            onChange={key => {
                                dispatch(setData([]))
                                dispatch(setLoading(true))
                                dispatch(setKeywords([]))
                                setDefaultFilters(key, state.filters['display_shipment_type'])
                                if (key === ShipmentType.INCOMING.value) {
                                    history.push(`${Routes.private.business.SHIPMENT.path}/incoming`)
                                } else if (key === ShipmentType.OUTGOING.value) {
                                    history.push(`${Routes.private.business.SHIPMENT.path}/outgoing`)
                                }
                                const newPagination = {
                                    current: 1,
                                    pageSize: 100,
                                    total: 1,
                                }
                                dispatch(setPagination(newPagination))
                            }}
                            activeKey={state.filters['shipment_type']}
                        >
                            <Tabs.TabPane tab={ShipmentType.INCOMING.text} key={ShipmentType.INCOMING.value} />
                            <Tabs.TabPane tab={ShipmentType.OUTGOING.text} key={ShipmentType.OUTGOING.value} />
                        </InsTab>
                        <Switch>
                            <Route exact path={path}>
                                <Redirect to={`${url}/incoming`} />
                            </Route>
                            <Route exact={true} path={`${path}/incoming`}>
                                <IncomingDataTable getShipments={getShipments} />
                            </Route>
                            <Route exact={true} path={`${path}/outgoing`}>
                                <OutgoingDataTable getShipments={getShipments} />
                            </Route>
                        </Switch>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapState = state => ({ state: state.shipment })
const mapDispatch = { updateDetail, setDefaultState }
export default connect(mapState, mapDispatch)(ShipmentList)

ShipmentList.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    setDefaultState: PropTypes.func,
}
