import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu } from 'antd'

const { SubMenu } = Menu

const generateMenu = (options, onSelect) => (
    <Menu onClick={item => onSelect(item)}>
        {options.map((opt, index) => {
            if (!opt) return null
            const key = opt.key || index
            if (opt.children) {
                return (
                    <SubMenu key={key} title={opt.text}>
                        {opt.children.map((ch, index) => (
                            <Menu.Item
                                key={ch.key || index}
                                disabled={ch.disabled}
                                className={ch.className}
                                style={ch.style}
                            >
                                {ch.icon}
                                {ch.text}
                            </Menu.Item>
                        ))}
                    </SubMenu>
                )
            }
            return (
                <Menu.Item key={key} className={opt.className} disabled={opt.disabled} style={opt.style}>
                    {opt.icon}
                    {opt.text}
                </Menu.Item>
            )
        })}
    </Menu>
)
// eslint-disable-next-line
const InsDropdown = ({ children, options, onSelect, ...rest }, ref) => {
    const menu = generateMenu(options, onSelect)
    return (
        <Dropdown {...rest} overlay={menu}>
            <div>{children}</div>
        </Dropdown>
    )
}
export default React.forwardRef(InsDropdown)

InsDropdown.propTypes = {
    children: PropTypes.node,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
}
