let classificationsGroupType = {}

let classifications = []

export const getAllClassifications = () => {
    return classifications
}

export const addClassification = record => {
    if (!record) return
    classifications.push(record)
    if (!Object.prototype.hasOwnProperty.call(classificationsGroupType, record.classification_type_code)) {
        classificationsGroupType[record.classification_type_code] = []
    }
    classificationsGroupType[record.classification_type_code].push({
        value: record.classification_item_code,
        text: record.classification_item_name,
        item_value: record.classification_item_value,
    })
}

export const updateClassificationItemName = (id, typeCode, itemCode, itemName, itemValue) => {
    for (let c of classifications) {
        if (c.id === id) {
            c.classification_item_name = itemName
            c.classification_item_value = itemValue
            console.log(c)
            break
        }
    }
    if (classificationsGroupType[typeCode]) {
        for (const item of classificationsGroupType[typeCode]) {
            if (item.value === itemCode) {
                item.text = itemName
                item.item_value = itemValue
                console.log(item)
                break
            }
        }
    }
}

export const setClassifications = records => {
    if (!records || !Array.isArray(records)) {
        return
    }
    if (classifications.length < 1) {
        classifications = records
        for (const c of records) {
            if (!Object.prototype.hasOwnProperty.call(classificationsGroupType, c.classification_type_code)) {
                classificationsGroupType[c.classification_type_code] = []
            }
            classificationsGroupType[c.classification_type_code].push({
                value: c.classification_item_code,
                text: c.classification_item_name,
                item_value: c.classification_item_value,
            })
        }
    }
}

export const getClassificationsByType = typeCode => {
    if (!classificationsGroupType[typeCode]) {
        return []
    }
    return classificationsGroupType[typeCode]
}

export const getClassificationItemName = (typeCode, itemCode) => {
    const tmpArray = getClassificationsByType(typeCode)
    for (const item of tmpArray) {
        if (item.value === itemCode) {
            return item.item_value
        }
    }
    return ''
}

export const getClassificationItemNameFromValue = (typeCode, itemValue) => {
    const tmpArray = getClassificationsByType(typeCode)

    for (const item of tmpArray) {
        if (item.item_value === itemValue) {
            return item.text
        }
    }
    return ''
}

export const deleteClassificationById = clfId => {
    classifications = classifications.filter(clf => clf.id !== clfId)
}
