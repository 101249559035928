import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Select } from 'antd'
import { formatNumber } from 'helpers/number.helper'
import InsSelect from 'components/select/InsSelect'
import { FirstPageIcon, KeyboardArrowLeftIcon, KeyboardArrowRightIcon, LastPageIcon } from 'components/InsIcon'

const InsPagination = ({ current, pageSize, total, onChange, pageSizeOptions = [100, 200, 300] }) => {
    let from = (current - 1) * pageSize + 1
    const to = Math.min(current * pageSize, total)
    const totalPage = Math.ceil(total / pageSize)
    if (total === 0) {
        from = 0
    }

    const updatePagination = option => {
        onChange(Object.assign({ current, pageSize, total }, option))
    }

    return (
        <div className="ins-pagination">
            <Row align="middle">
                <Col className="ins-pagination-size mr-4">
                    <span>表示件数</span>
                </Col>
                <Col className="ins-pagination-size mr-16">
                    <InsSelect
                        selectSize="small"
                        value={pageSize}
                        onSelect={pageSize => updatePagination({ pageSize, current: 1 })}
                    >
                        {pageSizeOptions.map(opt => (
                            <Select.Option key={opt} value={opt}>
                                {opt}
                            </Select.Option>
                        ))}
                    </InsSelect>
                </Col>
                <Col>
                    {formatNumber(from)} - {formatNumber(to)} / {formatNumber(total)}件
                </Col>
                <Col className="ins-pagination-controls">
                    <button className="mr-4" disabled={current === 1} onClick={() => updatePagination({ current: 1 })}>
                        <FirstPageIcon size={24} />
                    </button>
                    <button
                        className="mr-16"
                        disabled={current === 1}
                        onClick={() => updatePagination({ current: current === 1 ? 1 : current - 1 })}
                    >
                        <KeyboardArrowLeftIcon size={24} />
                    </button>
                    <button
                        className="mr-4"
                        disabled={current >= totalPage}
                        onClick={() => updatePagination({ current: current === totalPage ? totalPage : current + 1 })}
                    >
                        <KeyboardArrowRightIcon size={24} />
                    </button>
                    <button disabled={current >= totalPage} onClick={() => updatePagination({ current: totalPage })}>
                        <LastPageIcon size={24} />
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default InsPagination

InsPagination.propTypes = {
    current: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
}
