import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Select } from 'antd'
import InsInput from 'components/InsInput'
import { getClassificationsByType } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsSelect from 'components/select/InsSelect'
import InsInputNumber from 'components/InsInputNumber'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import { CommentIcon, SubdirectoryArrowRightIcon } from 'components/InsIcon'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateDetail } from 'modules/business/placeOrder/place_order_detail.slice'
import InsAutoComplete from 'components/InsAutoComplete'
import { isEmpty, rdStr } from 'helpers/string.helper'
import InsInputNumber2 from 'components/InsInputNumber2'
import { formCalculateDetailAmount } from 'helpers/calculate.helper'
import { isHeadline } from 'helpers/detail.helper'
import InsTextArea from 'components/InsTextArea'

const PlaceOrderDetailRowDelivery = React.memo(({ id, value, updateDetail, currentUser }) => {
    const { t } = useTranslation()
    const [noteVisible, setNoteVisible] = useState(!isEmpty(value.note))
    const [form] = Form.useForm()

    useEffect(() => {
        let values = Object.assign({}, value)
        if (values.unit) {
            values.unit = `${values.unit}`
        }
        form.setFieldsValue(values)
    }, [form])

    let timeout = null
    const changeHandle = () => {
        if (timeout !== null) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            updateDetail({ id, value: form.getFieldsValue() })
        }, 300)
    }

    const calculateDetailAmount = () => formCalculateDetailAmount(form)

    const suggestionList = currentUser.processing_product ? currentUser.processing_product.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const suggestionListSpec = currentUser.processing_spec ? currentUser.processing_spec.split(',') : []
    const [resultSpec, setResultSpec] = useState(suggestionListSpec)
    const handleSuggestionSpec = value => {
        setResultSpec(suggestionListSpec.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const suggestionListSize = currentUser.processing_size ? currentUser.processing_size.split(',') : []
    const [resultSize, setResultSize] = useState(suggestionListSize)
    const handleSuggestionSize = value => {
        setResultSize(suggestionListSize.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    return (
        <Form form={form} onValuesChange={changeHandle}>
            {isHeadline(value) ? (
                <Form.Item name="headline">
                    <InsInput placeholder={t('column:Headline')} />
                </Form.Item>
            ) : (
                <>
                    <Row align="middle">
                        <Form.Item name="title" style={{ flex: 180 }}>
                            <InsAutoComplete options={result} onSearch={handleSuggestion} placeholder="タイトル" />
                        </Form.Item>
                        <Form.Item name="spec" style={{ flex: 174 }}>
                            <InsAutoComplete
                                options={resultSpec}
                                onSearch={handleSuggestionSpec}
                                placeholder={t('column:Spec')}
                            />
                        </Form.Item>
                        <Form.Item name="size" style={{ flex: 116 }}>
                            <InsAutoComplete
                                options={resultSize}
                                onSearch={handleSuggestionSize}
                                placeholder={t('column:Size')}
                            />
                        </Form.Item>
                        <Form.Item name="quantity" style={{ flex: '0 0 74px' }}>
                            <InsInputNumber2
                                min={1}
                                onChange={value => {
                                    form.setFieldsValue({
                                        quantity: value,
                                    })
                                    calculateDetailAmount()
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="unit" className="form-item-select-hide-search" style={{ flex: '0 0 48px' }}>
                            <ClassificationSelect
                                typeEnum={ClassificationTypeEnum.PRODUCT_UNIT}
                                showCreateButton={false}
                            />
                        </Form.Item>
                        <Form.Item name="unit_cost" style={{ flex: '0 0 90px' }}>
                            <InsInputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="unit_price"
                            style={{ flex: '0 0 84px' }}
                            onChange={() => calculateDetailAmount()}
                        >
                            <InsInputNumber min={0} />
                        </Form.Item>
                        <Form.Item name="tax_rate" style={{ flex: '0 0 66px' }}>
                            <InsSelect>
                                {getClassificationsByType(ClassificationTypeEnum.TAX_RATE.value).map(c => (
                                    <Select.Option key={c.item_value} value={c.item_value}>
                                        {`${c.text}%`}
                                    </Select.Option>
                                ))}
                            </InsSelect>
                        </Form.Item>
                        <Form.Item name="amount" style={{ flex: '0 0 84px' }}>
                            <InsInputNumber min={0} disabled />
                        </Form.Item>
                        <CommentIcon
                            className={`mr-4 pointer ${noteVisible ? 'color-pantone-2738-c' : 'color-blue-gray-200'}`}
                            onClick={() => setNoteVisible(!noteVisible)}
                        />
                    </Row>
                    <Row align="middle" className="mt-4" style={{ display: noteVisible ? 'flex' : 'none' }}>
                        <SubdirectoryArrowRightIcon size={20} style={{ marginRight: 2 }} />
                        <div className="fw-bold mr-4">コメント</div>
                        <Form.Item name="note" style={{ flex: 1 }}>
                            <InsTextArea
                                className="color-gray-678 textarea-auto-size"
                                placeholder="コメント入力　※コメントは印刷されません"
                                autoSize={true}
                            />
                        </Form.Item>
                    </Row>
                </>
            )}
        </Form>
    )
})

const mapState = (state, props) => ({
    value: state.placeOrderDetail.details[props.id],
    currentUser: state.auth.user,
})
const mapDispatch = { updateDetail }
export default connect(mapState, mapDispatch)(PlaceOrderDetailRowDelivery)

PlaceOrderDetailRowDelivery.propTypes = {
    id: PropTypes.number,
    value: PropTypes.object,
    updateDetail: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
}
