import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Card, Row, Col, Form, Input, Button } from 'antd'
import Routes from 'routes'
import { login } from 'modules/common/auth/auth.slice'

const LoginPage = ({ authenticated, login }) => {
    const { t } = useTranslation()
    if (authenticated) {
        return <Redirect to={Routes.private.business.PROJECT} />
    }

    const formSubmit = values => {
        login(values.username, values.password)
    }

    return (
        <Row justify="center">
            <Col sm={24} md={8} lg={6}>
                <Card title={t('common:Login')} bordered={false}>
                    <Form layout="vertical" onFinish={formSubmit}>
                        <Form.Item
                            label={t('common:Username')}
                            name="username"
                            validateTrigger={false}
                            rules={[{ required: true, message: t('validation:Please enter username') }]}
                        >
                            <Input placeholder={t('common:Username')} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={t('common:Password')}
                            name="password"
                            validateTrigger={false}
                            rules={[{ required: true, message: t('validation:Please enter password') }]}
                        >
                            <Input.Password placeholder={t('common:Password')} autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item>
                            <Button className="w-100p mt-8" type="primary" htmlType="submit">
                                {t('common:Login')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default connect(state => ({ authenticated: state.auth.user !== null }), { login })(LoginPage)

LoginPage.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
}
