import PropTypes from 'prop-types'
import React from 'react'
import { HelpOutlineIcon } from 'components/InsIcon'

const InsHelpText = ({ hasIcon = true, children, className, ...rest }) => {
    let helpClassName = 'ins-help-text '
    if (className) {
        helpClassName += className
    }
    return (
        <div {...rest} className={helpClassName}>
            {hasIcon && <HelpOutlineIcon className="mr-4" size={16} />}
            {children}
        </div>
    )
}

export default InsHelpText

InsHelpText.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    hasIcon: PropTypes.bool,
}
