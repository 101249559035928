import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import Routes from 'routes'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import { BackIcon, DeleteIcon, EditIcon, LaunchIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsHelpText from 'components/InsHelpText'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { InsBlockLoader } from 'components/InsLoader'
import InsModal from 'components/InsModal'
import { Link } from 'react-router-dom'
import { formatNumber } from 'helpers/number.helper'
import InsDataTable from 'components/datatable/InsDataTable'
import SupplierDetailComponent from 'modules/master/supplier/SupplierDetailComponent'

const ProductDetailComponent = ({ inModal = false, id, onDelete }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [product, setProduct] = useState(null)
    const [deleteProduct, setDeleteProduct] = useState({
        visible: false,
    })

    const [modalProductCostsVisible, setModalProductCostsVisible] = useState(false)
    const productCosts = product?.product_costs || []

    const [modalSupplierDetail, setModalSupplierDetail] = useState({
        supplierId: null,
        visible: false,
    })

    useEffect(() => {
        setLoading(true)
        const getProduct = async id => {
            const data = await api.get(APP_URLS.PRODUCTS_DETAIL.replace(':id', id))
            if (data) {
                setProduct(data)
                setLoading(false)
            }
        }

        getProduct(id)
    }, [id])

    if (loading) {
        return <InsBlockLoader />
    }

    return (
        <>
            {!inModal && (
                <>
                    <InsFloatBar visible={true}>
                        <Row justify="space-between" align="middle">
                            <Col>
                                <InsButton
                                    width="76px"
                                    disabled={!product.can_delete}
                                    icon={<DeleteIcon size={16} />}
                                    onClick={() =>
                                        setDeleteProduct({
                                            visible: true,
                                        })
                                    }
                                >
                                    {t('common:Delete')}
                                </InsButton>
                                <InsHelpText className="ml-8">
                                    {t('Can not delete in-used {{item}}', { item: t('entity:Product') })}
                                </InsHelpText>
                            </Col>

                            <Col className="ins-flex flex-middle">
                                <InsButton
                                    className="fw-500 mr-8"
                                    buttonType="link"
                                    linkTo={Routes.private.master.PRODUCT_LIST.path}
                                    buttonStyle="outline"
                                    buttonSize="big"
                                    width="110px"
                                    icon={<BackIcon />}
                                >
                                    {t('common:Back to list')}
                                </InsButton>
                                <InsButton
                                    className="fw-bold"
                                    buttonType="link"
                                    linkTo={Routes.private.master.PRODUCT_UPDATE.path.replace(':id', id)}
                                    buttonStyle="primary"
                                    buttonSize="big"
                                    icon={<EditIcon size={20} />}
                                >
                                    {t('navigation:{{entity}} information edit', { entity: t('entity:Product') })}
                                </InsButton>
                            </Col>
                        </Row>
                    </InsFloatBar>
                    <InsModal
                        title={t('common:Delete')}
                        visible={deleteProduct.visible}
                        onCancel={() =>
                            setDeleteProduct({
                                visible: false,
                            })
                        }
                        onOk={onDelete}
                        okText="はい"
                        cancelText="いいえ"
                    >
                        <h2>{t('common:Are you sure')}</h2>
                    </InsModal>
                </>
            )}
            <div className="page-title-big mb-24">
                <div className={`${inModal ? '' : 'p-24'}`}>
                    <h1 className="page-title-big">
                        {product.product_name}
                        {!inModal && (
                            <InsButton
                                buttonType="link"
                                buttonStyle="plain"
                                className="ml-8 color-blue-gray-200"
                                icon={<EditIcon size={24} />}
                                linkTo={Routes.private.master.PRODUCT_UPDATE.path.replace(':id', id)}
                            />
                        )}
                    </h1>
                    <section>
                        <table className="ins-info-table">
                            <tbody>
                                <tr>
                                    <th>{t('Product code')}</th>
                                    <td className="fw-bold color-blue-gray-900">{product.product_code}</td>
                                </tr>
                                <tr>
                                    <th>{t('Product name')}</th>
                                    <td className="fw-bold color-blue-gray-900">{product.product_name}</td>
                                </tr>
                                <tr>
                                    <th>{t('Product classification1')}</th>
                                    <td className="fw-bold color-blue-gray-900">
                                        {getClassificationItemName(
                                            ClassificationTypeEnum.PRODUCT_1.value,
                                            product.product_classification1
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Product classification2')}</th>
                                    <td className="fw-bold color-blue-gray-900">
                                        {getClassificationItemName(
                                            ClassificationTypeEnum.PRODUCT_2.value,
                                            product.product_classification2
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Unit')}</th>
                                    <td className="fw-bold color-blue-gray-900">
                                        {getClassificationItemName(
                                            ClassificationTypeEnum.PRODUCT_UNIT.value,
                                            product.unit
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Price')}</th>
                                    <td className="fw-bold color-blue-gray-900">{formatNumber(product.price)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Unit cost')}</th>
                                    <td className="fw-bold color-blue-gray-900">{formatNumber(product.unit_cost)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Purchase cost')}</th>
                                    <td className="fw-bold color-blue-gray-900">
                                        {formatNumber(product.purchase_cost)}
                                        {productCosts.length > 0 && (
                                            <InsButton
                                                className="view-more-button ml-8"
                                                buttonStyle="plain"
                                                onClick={() => setModalProductCostsVisible(true)}
                                            >
                                                {t('原価履歴')}
                                            </InsButton>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Main supplier')}</th>
                                    <td className="fw-bold color-blue-gray-900">
                                        {product.main_supplier ? product.main_supplier.supplier_name : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('column:Note')}</th>
                                    <td className="fw-bold color-blue-gray-900">{product.note}</td>
                                </tr>
                                <tr>
                                    <th>{t('column:Stock control')}</th>
                                    <td className="fw-bold color-blue-gray-900">
                                        {product.is_stock_control === 1 ? t('common:Yes') : t('common:No')}
                                    </td>
                                </tr>
                                {product.is_stock_control === 1 && (
                                    <tr>
                                        <th>{t('column:Stock quantity')}</th>
                                        <td className="fw-bold color-blue-gray-900">
                                            {formatNumber(product.remain_quantity)}
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>{t('column:Is enabled')}</th>
                                    <td className="fw-bold color-blue-gray-900">
                                        {product.is_enabled === 1 ? '有効' : '無効'}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
            {inModal && (
                <Row justify="end">
                    <Link to={Routes.private.master.PRODUCT_DETAIL.path.replace(':id', product.id)} target="_blank">
                        <InsButton
                            icon={<LaunchIcon size={12} className="color-pantone-2738-c" />}
                            buttonStyle="default"
                        >
                            {t('Open new tab')}
                        </InsButton>
                    </Link>
                </Row>
            )}
            <InsModal
                className="ins-detail-modal"
                width={760}
                title="原価履歴"
                visible={modalProductCostsVisible}
                onCancel={() => setModalProductCostsVisible(false)}
                footer={[
                    <InsButton
                        style={{ marginLeft: 'auto' }}
                        centered
                        key="close"
                        onClick={() => setModalProductCostsVisible(false)}
                        buttonStyle="primary"
                    >
                        {'閉じる'}
                    </InsButton>,
                ]}
            >
                <InsDataTable
                    tableStyle="compact"
                    striped
                    bordered
                    columns={[
                        {
                            title: t('column:Register date'),
                            dataIndex: 'register_date',
                            defaultSortOrder: 'descend',
                            sorter: (a, b) => {
                                return a?.register_date.localeCompare(b?.register_date)
                            },
                        },
                        {
                            title: t('column:Cost'),
                            dataIndex: 'cost',
                            sorter: (a, b) => (a?.cost > b?.cost ? 1 : a?.cost < b?.cost ? -1 : 0),
                            render: text => formatNumber(text),
                        },
                        {
                            title: t('column:Supplier name'),
                            dataIndex: 'supplier',
                            render: (text, record) => {
                                return (
                                    <InsButton
                                        buttonStyle="plain"
                                        onClick={() => {
                                            setModalSupplierDetail({
                                                supplierId: record?.supplier?.id,
                                                visible: true,
                                            })
                                        }}
                                    >
                                        {record?.supplier?.supplier_name || ''}
                                    </InsButton>
                                )
                            },
                        },
                    ]}
                    data={productCosts}
                    onChange={() => {}}
                />
            </InsModal>
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalSupplierDetail.visible}
                onCancel={() =>
                    setModalSupplierDetail({
                        supplierId: modalSupplierDetail.supplierId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <SupplierDetailComponent inModal={true} id={modalSupplierDetail.supplierId} />
            </InsModal>
        </>
    )
}

export default ProductDetailComponent

ProductDetailComponent.propTypes = {
    id: PropTypes.any.isRequired,
    inModal: PropTypes.bool,
    onDelete: PropTypes.func,
}

ProductDetailComponent.defaultProps = {
    inModal: false,
}
