export const getEnum = (enumObject, value) => {
    for (const k in enumObject) {
        if (!Object.prototype.hasOwnProperty.call(enumObject, k)) continue
        if (enumObject[k].value === value) {
            return enumObject[k]
        }
    }
    return null
}

export const getEnumText = (enumObject, value) => {
    const enumItem = getEnum(enumObject, value)
    return enumItem ? enumItem.text : ''
}

export const getEnumManyText = (enumObject, values) => {
    const items = []
    values.forEach(value => {
        let text = getEnumText(enumObject, value)
        if (text && text !== '') {
            items.push(text)
        }
    })
    return items
}
