import React, { useState } from 'react'
import { Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import InsModal from 'components/InsModal'

const ModalDemo = () => {
    const [defaultVisible, setDefaultVisible] = useState(false)
    const [customOkVisible, setCustomOkVisible] = useState(false)
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">Modal Demo</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsButton onClick={() => setDefaultVisible(true)}>Default modal</InsButton>
                    <InsModal
                        title="まとめ請求"
                        visible={defaultVisible}
                        onCancel={() => setDefaultVisible(false)}
                        onOk={() => setDefaultVisible(false)}
                        okText="確認"
                    >
                        <p>Modal content</p>
                    </InsModal>
                </Col>
                <Col>
                    <InsButton onClick={() => setCustomOkVisible(true)}>Custom button</InsButton>
                    <InsModal
                        title="まとめ請求"
                        visible={customOkVisible}
                        onCancel={() => setCustomOkVisible(false)}
                        onOk={() => setCustomOkVisible(false)}
                        okText="請求書発行"
                        okButtonStyle="secondary"
                    >
                        <p>Modal content</p>
                    </InsModal>
                </Col>
            </Row>
        </div>
    )
}

export default ModalDemo
