import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { Col, Form, Row } from 'antd'
import Routes from 'routes'
import InsButton from 'components/InsButton'
import { BackIcon, DescriptionIcon, EditIcon, SearchIcon } from 'components/InsIcon'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import {
    checkBusinessStatus,
    checkBusinessStatusForInstruction,
    checkNoDocument,
    checkStatusNonBusiness,
} from 'helpers/business.helper'
import { getClassificationItemName } from 'helpers/classification.helper'
import InsTextArea from 'components/InsTextArea'
import EstimationCreate from 'modules/business/estimation/EstimationCreate'
import EstimationUpdate from 'modules/business/estimation/EstimationUpdate'
import EstimationPreview from 'modules/business/estimation/EstimationPreview'
import history from 'helpers/history.helper'
import BusinessStatusEnum from 'enums/business_status.enum'
import InstructionTab from 'modules/business/instruction/InstructionTab'
import ProjectBusinessTab from 'modules/business/project/ProjectBusinessTab'
import PlaceOrderTab from 'modules/business/placeOrder/PlaceOrderTab'
import InvoiceTab from 'modules/business/invoice/InvoiceTab'
import BusinessPageEnum from 'enums/business_page.enum'
import InsModal from 'components/InsModal'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'
import SaleTab from 'modules/business/sale/SaleTab'
import RedSlipTab from 'modules/business/redSlip/RedSlipTab'
import TrackingTab from 'modules/business/tracking/TrackingTab'
import { can } from 'helpers/permission.helper'
import UserDetailComponent from 'modules/master/user/UserDetailComponent'
import ActionLogListComponent from 'modules/business/actionLog/ActionLogListComponent'
import { showWarning } from 'helpers/notification.helper'
import InsBackLink from 'components/InsBackLink'
import useQuery from 'helpers/query_params.helper'
import NavigationEnum from 'enums/navigation.enum'

const createDocumentFrom = {
    estimation: false,
    instruction_internal: false,
    instruction_outsource: false,
    place_order: false,
    sale: false,
    invoice: false,
}

const ProjectDetail = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const query = useQuery()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [project, setProject] = useState(null)
    const [isCreating, setIsCreating] = useState(true)
    const [activeKey, setActiveKey] = useState(null)
    const [statusTransition, setStatusTransition] = useState(false)
    const [curStatusProject, setCurStatusProject] = useState('')
    const [modalCustomerDetail, setModalCustomerDetail] = useState(false)
    const [modalPicDetail, setModalPicDetail] = useState(false)
    const [modalActionLog, setModalActionLog] = useState({
        visible: false,
        data: null,
    })
    const [modalRsIssued, setModalRsIssued] = useState({
        visible: false,
    })
    let { path, url } = useRouteMatch()
    const canEditProject = can('PROJECT_EDITABLE') && !project?.is_locked
    const getProject = async id => {
        const data = await api.get(APP_URLS.PROJECT_DETAIL.replace(':id', id))
        if (data) {
            if (checkNoDocument(data)) {
                setIsCreating(true)
            } else {
                if (checkStatusNonBusiness) {
                    setIsCreating(false)
                } else {
                    setIsCreating(data.status === BusinessStatusEnum.PROJECT_CREATE.value)
                }
            }
            setProject(data)
            setLoading(false)
        }
    }

    const sleep = milliseconds => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const updatedProjectAndRedirect = async path => {
        await getProject(project.id)
        if (path) {
            history.push(path + location.search)
        }
        if (curStatusProject !== project.status) {
            setCurStatusProject(project.status)
            setStatusTransition(true)
            await sleep(3000)
            setStatusTransition(false)
        }
    }

    useEffect(() => {
        getProject(id).then(r => {})
    }, [])

    useEffect(() => {
        if (location.pathname.indexOf('estimation') > -1) {
            setActiveKey('estimations')
        } else if (location.pathname.indexOf('instruction-internal') > -1) {
            setActiveKey('instruction-internal')
        } else if (location.pathname.indexOf('instruction-outsource') > -1) {
            setActiveKey('instruction-outsource')
        } else if (location.pathname.indexOf('place-orders') > -1) {
            setActiveKey('place-orders')
        } else if (location.pathname.indexOf('sales') > -1) {
            setActiveKey('sales')
        } else if (location.pathname.indexOf('invoices') > -1) {
            setActiveKey('invoices')
        } else if (location.pathname.indexOf('red-slips') > -1) {
            setActiveKey('red-slips')
            // } else if (location.pathname.indexOf('trackings') > -1) {
            //     setActiveKey('trackings')
        } else {
            setActiveKey('')
        }
    }, [location])

    return (
        <div className="page-container mb-64 project-detail">
            <InsButton
                className="ins-back-link"
                buttonStyle="plain"
                icon={<BackIcon size={24} />}
                onClick={() => {
                    switch (query.get('from')) {
                        case NavigationEnum.SUMMARY_INVOICES:
                            history.replace(Routes.private.business.SUMMARY_INVOICE.path)
                            break
                        case NavigationEnum.SHIPMENTS:
                            history.replace(Routes.private.business.SHIPMENT.path)
                            break
                        case NavigationEnum.PAYMENTS:
                            history.replace(Routes.private.business.PAYMENT.path)
                            break
                        case NavigationEnum.REPORTS:
                            history.replace(Routes.private.business.REPORT.path)
                            break
                        case NavigationEnum.LIST_ESTIMATIONS:
                            history.push(Routes.private.business.ESTIMATION.path)
                            break
                        case NavigationEnum.LIST_INSTRUCTIONS:
                            history.push(Routes.private.business.INSTRUCTION.path)
                            break
                        case NavigationEnum.LIST_PLACE_ORDERS:
                            history.push(Routes.private.business.PLACE_ORDER.path)
                            break
                        case NavigationEnum.LIST_SALES:
                            history.push(Routes.private.business.SALE.path)
                            break
                        case NavigationEnum.LIST_INVOICES:
                            history.push(Routes.private.business.INVOICE.path)
                            break
                        case NavigationEnum.LIST_RED_SLIPS:
                            history.push(Routes.private.business.RED_SLIP.path)
                            break
                        default:
                            history.replace(Routes.private.business.PROJECT.path)
                            break
                    }
                }}
            >
                {t('common:Back')}
            </InsButton>
            {loading ? (
                <InsBlockLoader />
            ) : (
                <>
                    <div className="pt-24">
                        <h1 className="page-title-big">
                            {t('Project details')} {project.project_code}
                            {canEditProject && (
                                <>
                                    <InsButton
                                        buttonType="link"
                                        buttonStyle="plain"
                                        className="ml-8 color-blue-gray-200"
                                        icon={<EditIcon size={24} />}
                                        linkTo={Routes.private.business.PROJECT_UPDATE.path.replace(':id', id)}
                                    />
                                    <InsButton
                                        buttonType="link"
                                        icon={<EditIcon size={16} className="color-pantone-2738-c" />}
                                        buttonStyle="default"
                                        linkTo={Routes.private.business.PROJECT_UPDATE.path.replace(':id', id)}
                                        className={'btn-edit-project'}
                                    >
                                        {t('Edit Item')}
                                    </InsButton>
                                </>
                            )}
                        </h1>
                    </div>
                    <div className={'info-project pt-12 pb-24'}>
                        <Row>
                            <Col span={12}>
                                <Form.Item name="status" label={t('column:Status')} className={'custom_label fw-bold'}>
                                    <div
                                        className={`project-status-label ${
                                            statusTransition ? 'project-status-transition' : ''
                                        }`}
                                    >
                                        <span>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.BUSINESS_STATUS.value,
                                                project.status.toString()
                                            )}
                                        </span>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="project_name"
                                    label={t('column:Project name')}
                                    className={'custom_label fw-bold mb-8'}
                                >
                                    <h4 style={{ width: 388 }} className={'fw-bold'}>
                                        {project.project_name}
                                    </h4>
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="customer_id"
                                        label={t('column:Customer name')}
                                        className={'custom_label fw-bold'}
                                    >
                                        {project.customer == null ? (
                                            '-'
                                        ) : (
                                            <>
                                                <InsButton
                                                    onClick={() => {
                                                        setModalCustomerDetail(true)
                                                    }}
                                                    buttonStyle="plain"
                                                    className={'fw-bold'}
                                                >
                                                    {project.customer.customer_name}
                                                </InsButton>
                                                <InsModal
                                                    className="ins-detail-modal"
                                                    width={760}
                                                    visible={modalCustomerDetail}
                                                    onCancel={() => setModalCustomerDetail(false)}
                                                    footer={null}
                                                >
                                                    <CustomerDetailComponent inModal={true} id={project.customer.id} />
                                                </InsModal>
                                            </>
                                        )}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="pic_id"
                                    label={t('column:Pic name')}
                                    className={'custom_label fw-bold'}
                                >
                                    {project.pic == null ? (
                                        '-'
                                    ) : (
                                        <>
                                            <InsButton
                                                onClick={() => {
                                                    setModalPicDetail(true)
                                                }}
                                                buttonStyle="plain"
                                                className={'fw-bold'}
                                            >
                                                {project.pic.pic_name}
                                            </InsButton>
                                            <InsModal
                                                className="ins-detail-modal"
                                                width={760}
                                                visible={modalPicDetail}
                                                onCancel={() => setModalPicDetail(false)}
                                                footer={null}
                                            >
                                                <UserDetailComponent inModal={true} id={project.pic.id} />
                                            </InsModal>
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item name="note" label={t('column:Item memo')} className={'custom_label fw-bold'}>
                                    <InsTextArea
                                        disabled
                                        allowClear={false}
                                        row={8}
                                        defaultValue={project.note}
                                        className={'item-memo-txt-area fw-bold disabled-no-border'}
                                    />

                                    <ActionLogListComponent inModal={false} data={project.action_logs.slice(0, 1)} />

                                    {project.action_logs.length > 1 && (
                                        <InsButton
                                            className="view-more-button"
                                            buttonStyle="plain"
                                            onClick={() =>
                                                setModalActionLog({
                                                    visible: true,
                                                    data: project.action_logs,
                                                })
                                            }
                                        >
                                            {t('もっと見る')}
                                        </InsButton>
                                    )}

                                    <InsModal
                                        className="ins-detail-modal"
                                        width={760}
                                        visible={modalActionLog.visible}
                                        onCancel={() =>
                                            setModalActionLog({
                                                visible: false,
                                                data: project.action_logs,
                                            })
                                        }
                                        footer={null}
                                    >
                                        <ActionLogListComponent inModal={true} data={modalActionLog.data} />
                                    </InsModal>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <InsModal
                        title={t('注意')}
                        visible={modalRsIssued.visible}
                        onCancel={() => setModalRsIssued(false)}
                        footer={[
                            <>
                                <InsButton
                                    centered
                                    key="save"
                                    buttonStyle="primary"
                                    onClick={() =>
                                        setModalRsIssued({
                                            visible: false,
                                        })
                                    }
                                >
                                    閉じる
                                </InsButton>
                            </>,
                        ]}
                    >
                        <p>赤伝が発行されている案件のためドキュメント作成できません。</p>
                        <p>赤伝発行を取り消してからもう一度お試しください。</p>
                    </InsModal>
                    <ProjectBusinessTab
                        isLocked={project.is_locked}
                        isNoDocument={checkNoDocument(project)}
                        url={url}
                        tabs={
                            project.is_purchase_order
                                ? [
                                      {
                                          id: 'place-orders',
                                          text: '発注書',
                                          addIcon: !project?.place_order,
                                      },
                                  ]
                                : [
                                      {
                                          id: 'estimations',
                                          text: '見積書',
                                          addIcon: !project.estimation,
                                      },
                                      {
                                          id: 'instruction-internal',
                                          text: '作業指示書(社内)',
                                          addIcon: !project.instruction_internal,
                                      },
                                      {
                                          id: 'instruction-outsource',
                                          text: '作業指示書(外注)',
                                          addIcon: !project.instruction_outsource,
                                      },
                                      {
                                          id: 'place-orders',
                                          text: '発注書',
                                          addIcon: !project?.place_order,
                                      },
                                      {
                                          id: 'sales',
                                          text: '納品書',
                                          addIcon: !project?.sale,
                                      },
                                      // {
                                      //     id: 'trackings',
                                      //     text: '送り状',
                                      //     addIcon: !project.tracking,
                                      //     isShowTab: project.status >= BusinessStatusEnum.SALE_PROCESSED.value,
                                      //     isShowMenuDocument: false,
                                      // },
                                      {
                                          id: 'invoices',
                                          text: '請求書',
                                          addIcon: !(project.countExistedInvoice || project.countInvoice),
                                          isShowTab: project.status >= BusinessStatusEnum.SALE_PROCESSED.value,
                                          isShowMenuDocument: false,
                                      },
                                      {
                                          id: 'red-slips',
                                          text: '赤伝',
                                          addIcon: !project.red_slip,
                                          isShowTab: project.status >= BusinessStatusEnum.SALE_PROCESSED.value,
                                          isShowMenuDocument: false,
                                      },
                                      {
                                          id: null,
                                          text: t('Create new'),
                                          addIcon: null,
                                      },
                                  ]
                        }
                        onTabChange={(tab, key) => {
                            createDocumentFrom.place_order = key?.key === 'place-orders'
                            createDocumentFrom.estimation = key?.key === 'estimations'
                            createDocumentFrom.instruction_internal = key?.key === 'instruction-internal'
                            createDocumentFrom.instruction_outsource = key?.key === 'instruction-outsource'
                            createDocumentFrom.invoice = key?.key === 'invoices'
                            createDocumentFrom.sale = key?.key === 'sales'
                            if (
                                tab === 'invoices' &&
                                !(project.countExistedInvoice || project.countInvoice) &&
                                project.red_slip &&
                                project.red_slip.status === BusinessStatusEnum.RED_SLIP_ISSUED.value
                            ) {
                                setModalRsIssued({
                                    visible: true,
                                })
                            } else {
                                history.push(
                                    `${Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id)}/${tab}` +
                                        location.search
                                )
                            }

                            //setActiveKey(key)
                        }}
                        activeKey={activeKey}
                    />
                    <div className="ins-tab-content">
                        <Switch>
                            <Route exact path={`${path}/estimations`}>
                                {!!project.estimation ? (
                                    <EstimationPreview
                                        estimation={project.estimation}
                                        project={project}
                                        updateEstimation={async isDeleted => {
                                            let path = isDeleted
                                                ? `${Routes.private.business.PROJECT_DETAIL.path.replace(
                                                      ':id',
                                                      project.id
                                                  )}`
                                                : `${Routes.private.business.PROJECT_DETAIL.path.replace(
                                                      ':id',
                                                      project.id
                                                  )}/estimations`

                                            await updatedProjectAndRedirect(path)
                                        }}
                                        response={async path => {
                                            history.push(
                                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id)
                                            )
                                            return updatedProjectAndRedirect(path)
                                        }}
                                    />
                                ) : (
                                    <Redirect to={`${url}/estimations/create` + location.search} />
                                )}
                            </Route>
                            <Route exact={false} path={`${path}/estimations/create`}>
                                {!project.estimation ? (
                                    <EstimationCreate
                                        project={project}
                                        createDocumentFrom={createDocumentFrom}
                                        response={redirectTo => {
                                            if (!redirectTo) {
                                                redirectTo = `${Routes.private.business.PROJECT_DETAIL.path.replace(
                                                    ':id',
                                                    project.id
                                                )}/estimations`
                                            }
                                            return updatedProjectAndRedirect(redirectTo)
                                        }}
                                    />
                                ) : (
                                    <Redirect to={`${url}/estimations`} />
                                )}
                            </Route>
                            <Route exact path={`${path}/estimations/update`}>
                                {project.estimation ? (
                                    <EstimationUpdate
                                        estimation={project.estimation}
                                        project={project}
                                        response={redirectTo => {
                                            if (!redirectTo) {
                                                redirectTo = `${Routes.private.business.PROJECT_DETAIL.path.replace(
                                                    ':id',
                                                    project.id
                                                )}/estimations`
                                            }
                                            return updatedProjectAndRedirect(redirectTo)
                                        }}
                                    />
                                ) : (
                                    <Redirect to={`${url}/estimations`} />
                                )}
                            </Route>
                            <Route path={`${path}/instruction-internal`}>
                                <InstructionTab
                                    project={project}
                                    createDocumentFrom={createDocumentFrom}
                                    instructionType={1}
                                    response={path => {
                                        return updatedProjectAndRedirect(path)
                                    }}
                                />
                            </Route>
                            <Route path={`${path}/instruction-outsource`}>
                                <InstructionTab
                                    project={project}
                                    createDocumentFrom={createDocumentFrom}
                                    instructionType={2}
                                    response={path => {
                                        return updatedProjectAndRedirect(path)
                                    }}
                                />
                            </Route>
                            <Route path={`${path}/place-orders`}>
                                <PlaceOrderTab
                                    project={project}
                                    createDocumentFrom={createDocumentFrom}
                                    response={path => {
                                        return updatedProjectAndRedirect(path)
                                    }}
                                />
                            </Route>
                            <Route path={`${path}/sales`}>
                                <SaleTab
                                    project={project}
                                    createDocumentFrom={createDocumentFrom}
                                    response={async path => {
                                        return updatedProjectAndRedirect(path)
                                    }}
                                />
                            </Route>
                            <Route path={`${path}/invoices`}>
                                <InvoiceTab
                                    project={project}
                                    createDocumentFrom={createDocumentFrom}
                                    response={async path => {
                                        return updatedProjectAndRedirect(path)
                                    }}
                                />
                            </Route>
                            <Route path={`${path}/red-slips`}>
                                <RedSlipTab
                                    project={project}
                                    response={async path => {
                                        await updatedProjectAndRedirect(path)
                                    }}
                                />
                            </Route>
                            <Route path={`${path}/trackings`}>
                                <TrackingTab
                                    project={project}
                                    response={async path => {
                                        await updatedProjectAndRedirect(path)
                                    }}
                                />
                            </Route>
                            <Route exact path={path}>
                                {project.is_purchase_order ? (
                                    <>
                                        <Redirect to={`${url}/place-orders`} />
                                    </>
                                ) : (
                                    <>
                                        {isCreating ? (
                                            <Row
                                                justify="center"
                                                className="ins-empty-text"
                                                align="middle"
                                                style={{ height: 320 }}
                                            >
                                                <div className="circle">
                                                    <Row justify="center" align="middle" className="icon">
                                                        <div className="icon">
                                                            <DescriptionIcon size={54} className="description-icon" />
                                                            <SearchIcon size={30} className="search-icon" />
                                                        </div>
                                                    </Row>
                                                    <Row justify="center" align="middle" className="text">
                                                        <h4>{t('No document')}</h4>
                                                        <InsButton
                                                            buttonStyle="plain"
                                                            onClick={() => {
                                                                setActiveKey('estimations')
                                                                history.push(`${url}/estimations/create`)
                                                            }}
                                                        >
                                                            {t('見積書作成')}
                                                        </InsButton>
                                                    </Row>
                                                </div>
                                            </Row>
                                        ) : checkBusinessStatus(project.status, BusinessPageEnum.ESTIMATIONS.value) &&
                                          project.estimation ? (
                                            <Redirect to={`${url}/estimations` + location.search} />
                                        ) : project.instruction_internal &&
                                          checkBusinessStatusForInstruction(
                                              project.status,
                                              project.instruction_internal,
                                              project.instruction_outsource
                                          ) ? (
                                            <Redirect to={`${url}/instruction-internal` + location.search} />
                                        ) : project.instruction_outsource &&
                                          checkBusinessStatusForInstruction(
                                              project.status,
                                              project.instruction_outsource,
                                              project.instruction_internal
                                          ) ? (
                                            <Redirect to={`${url}/instruction-outsource` + location.search} />
                                        ) : checkBusinessStatus(
                                              project.status,
                                              BusinessPageEnum.PURCHASE_ORDER.value
                                          ) && project.place_order ? (
                                            <Redirect to={`${url}/place-orders` + location.search} />
                                        ) : checkBusinessStatus(project.status, BusinessPageEnum.SALES.value) &&
                                          project.sale ? (
                                            <Redirect to={`${url}/sales` + location.search} />
                                        ) : // ) : checkBusinessStatus(project.status, BusinessPageEnum.TRACKING.value) &&
                                        //   project.tracking ? (
                                        //     <Redirect to={`${url}/trackings`} />
                                        checkBusinessStatus(project.status, BusinessPageEnum.INVOICES.value) &&
                                          (project.countExistedInvoice > 0 || project.countInvoice > 0) ? (
                                            <Redirect to={`${url}/invoices` + location.search} />
                                        ) : checkBusinessStatus(project.status, BusinessPageEnum.RED_SLIPS.value) &&
                                          project.red_slip ? (
                                            <Redirect to={`${url}/red-slips` + location.search} />
                                        ) : checkBusinessStatus(project.status, BusinessPageEnum.RED_SLIPS.value) &&
                                          project.tracking ? (
                                            <Redirect to={`${url}/tracking` + location.search} />
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            </Route>
                        </Switch>
                    </div>

                    <div className="m-0">
                        <ActionLogListComponent inModal={false} data={project.business_action_logs.slice(0, 5)} />

                        {project.business_action_logs.length > 5 && (
                            <InsButton
                                style={{ display: 'block' }}
                                className="view-more-button mt-8"
                                buttonStyle="plain"
                                onClick={() =>
                                    setModalActionLog({
                                        visible: true,
                                        data: project.business_action_logs,
                                    })
                                }
                            >
                                {t('もっと見る')}
                            </InsButton>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
export default ProjectDetail
