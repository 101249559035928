import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InsButton from 'components/InsButton'
import { AddIcon } from 'components/InsIcon'
import InsDropdown from 'components/dropdown/InsDropdown'
import { addDetail, setDetails, setDetailsOrder } from 'modules/business/instruction/instruction_detail.slice'

const InstructionDetailAddButton = ({
    addDetail,
    destinationsOrder,
    details,
    detailsOrder,
    setDetails,
    setDetailsOrder,
}) => {
    return (
        <InsDropdown
            onSelect={key => {
                let isHeadline = true
                if (key.key === 'product') {
                    isHeadline = false
                }
                if (
                    detailsOrder['destinationUnknown'] &&
                    (detailsOrder['destinationUnknown'].length > 0 || destinationsOrder[0] === 'destinationUnknown')
                ) {
                    addDetail({ isHeadline, processed_product_classification: isHeadline ? null : '1' })
                } else {
                    const currentTime = new Date().getTime().toString()
                    const detailId = isHeadline ? `${currentTime}-headline` : `${currentTime}-product`

                    let newDetails = { ...details }

                    // add to index
                    let addToDesIndex =
                        destinationsOrder.indexOf('destinationUnknown') > -1
                            ? destinationsOrder.length - 2
                            : destinationsOrder.length - 1
                    newDetails[detailId] = { isHeadline, processed_product_classification: isHeadline ? null : '1' }
                    let newDetailsOrder = {
                        ...detailsOrder,
                        [destinationsOrder[addToDesIndex]]: [
                            ...detailsOrder[destinationsOrder[addToDesIndex]],
                            detailId,
                        ],
                    }
                    // newDetailsOrder[destinationsOrder[0]].push(detailId)

                    setDetails(newDetails)
                    setDetailsOrder(newDetailsOrder)
                }
            }}
            options={[
                { key: 'product', text: '商品入力' },
                { key: 'headline', text: '見出し' },
            ]}
            trigger={['click']}
        >
            <InsButton icon={<AddIcon className="mdi-add" />} buttonStyle="default">
                行追加
            </InsButton>
        </InsDropdown>
    )
}

const mapState = state => ({
    destinationsOrder: state.instructionDetail.destinationsOrder,
    details: state.instructionDetail.details,
    detailsOrder: state.instructionDetail.detailsOrder,
})

const mapDispatch = { addDetail, setDetails, setDetailsOrder }

export default connect(mapState, mapDispatch)(InstructionDetailAddButton)

InstructionDetailAddButton.propTypes = {
    addDetail: PropTypes.func.isRequired,
    setDetails: PropTypes.func.isRequired,
    setDetailsOrder: PropTypes.func.isRequired,
    destinationsOrder: PropTypes.array,
    details: PropTypes.object,
    detailsOrder: PropTypes.object,
}
