import axios from 'axios'
import { APP_URLS } from 'constants/url.constant'
import { showError } from 'helpers/notification.helper'

const instance = axios.create({
    baseURL: APP_URLS.BASE_URL,
    responseType: 'json',
})

const instanceBlob = axios.create({
    baseURL: APP_URLS.BASE_URL,
    responseType: 'blob',
})

const api = {
    accessToken: null,
    message: {
        error: 'Error',
        serverError: 'Server error',
        applicationError: 'Application error',
        message: 'Error occurred please contact administrator',
    },
    getHeaders: customHeaders => {
        if (!api.accessToken) return customHeaders
        return Object.assign({ Authorization: `Bearer ${api.accessToken}` }, customHeaders)
    },
    handleError: e => {
        if (e.response && e.response.data) {
            const { status_code: error, message_code: message } = e.response.data
            if (message) {
                if (error) {
                    showError(api.message.error, `[${error}] ${message}`)
                } else {
                    showError(api.message.error, message)
                }
            } else {
                showError(api.message.serverError, api.message.message)
            }
        } else {
            showError(api.message.applicationError, api.message.message)
        }
    },
    get: async (uri, params, headers, throwError = false) => {
        try {
            const response = await instance.get(uri, { params, headers: api.getHeaders(headers) })
            return response.data.data
        } catch (e) {
            if (throwError) {
                throw e
            }
            api.handleError(e)
        }
    },
    getBlob: async (uri, params, headers, throwError = false) => {
        try {
            const response = await instanceBlob.get(uri, { params, headers: api.getHeaders(headers) })
            return response.data
        } catch (e) {
            if (throwError) {
                throw e
            }
            api.handleError(e)
        }
    },
    post: async (uri, data, params, headers, throwError = false) => {
        try {
            const response = await instance.post(uri, data, { params, headers: api.getHeaders(headers) })
            return response.data.data
        } catch (e) {
            if (throwError) {
                throw e
            }
            api.handleError(e)
        }
    },
    put: async (uri, data, params, headers, throwError = false) => {
        try {
            const response = await instance.put(uri, data, { params, headers: api.getHeaders(headers) })
            return response.data.data
        } catch (e) {
            if (throwError) {
                throw e
            }
            api.handleError(e)
        }
    },
    patch: async (uri, data, params, headers, throwError = false) => {
        try {
            const response = await instance.patch(uri, data, { params, headers: api.getHeaders(headers) })
            return response.data.data
        } catch (e) {
            if (throwError) {
                throw e
            }
            api.handleError(e)
        }
    },
    delete: async (uri, params, headers, throwError = false) => {
        try {
            const response = await instance.delete(uri, { params, headers: api.getHeaders(headers) })
            return response.data.data
        } catch (e) {
            if (throwError) {
                throw e
            }
            api.handleError(e)
        }
    },
}

export default api

export const createQuery = (pagination, filters, sorter) => {
    let params = Object.assign(
        {
            page: pagination.current,
            page_size: pagination.pageSize,
        },
        filters
    )
    if (sorter && sorter.field && sorter.order) {
        params.order_by = `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`
    }
    return params
}
