import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import InsCollapse from 'components/InsCollapse'
import InsInputSearch from 'components/InsInputSearch'
import { useTranslation } from 'react-i18next'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import InsSelect from 'components/select/InsSelect'
import { Col, Row, Select } from 'antd'
import InsInput from 'components/InsInput'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import ClassificationSelect from 'modules/master/classification/ClassificationSelect'
import UserSelect from 'modules/master/user/UserSelect'
import {
    setDetailSearch,
    setKeywords,
    updateDetailSearch,
    setFilters,
    getData,
} from 'modules/business/invoice/invoice.slice'
import BusinessSelect from 'components/select/BusinessSelect'
import BusinessPageEnum from 'enums/business_page.enum'
import { getClassificationsByType } from 'helpers/classification.helper'

const InvoiceListSearch = ({
    keywords,
    setKeywords,
    detailSearch,
    setDetailSearch,
    updateDetailSearch,
    onSearch,
    filters,
    getInvoices,
    pagination,
    onReset,
}) => {
    const { t } = useTranslation()
    const collapseRef = useRef()
    const dispatch = useDispatch()

    const invoiceStatus = getClassificationsByType(ClassificationTypeEnum.BUSINESS_STATUS.value).filter(status =>
        status.value.startsWith(BusinessPageEnum.INVOICES.value)
    )

    const createdAtChangeHandler = value => {
        const newFilters = Object.assign({}, filters, {
            'created_at:gte': value[0] + '\t00:00:00',
            'created_at:lte': value[1] + '\t23:59:59',
        })
        dispatch(setFilters(newFilters))
        getInvoices(pagination, newFilters)
    }

    const statusChangeHandler = value => {
        const newFilters = Object.assign({}, filters, { status: value })
        dispatch(setFilters(newFilters))
        getInvoices(pagination, newFilters)
    }

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <>
                    <BusinessSelect pageId={BusinessPageEnum.INVOICES.value} />

                    <InsSelect
                        className="mr-4"
                        value={filters.status}
                        onSelect={value => statusChangeHandler(value)}
                        dropdownInnerStyle={{ minWidth: 150 }}
                    >
                        <Select.Option value={'0'}>全てのステータス</Select.Option>
                        {invoiceStatus.map(s => (
                            <Select.Option key={s.value} value={s.value} title={s.text}>
                                {s.text}
                            </Select.Option>
                        ))}
                    </InsSelect>

                    <InsDateRangePicker
                        className="mr-8"
                        value={[moment.utc(filters['created_at:gte']), moment.utc(filters['created_at:lte'])]}
                        onChange={(mm, value) => createdAtChangeHandler(value)}
                    />

                    <InsInputSearch
                        style={{ width: 380 }}
                        placeholder={t('顧客名, 請求コード, 件名, 等')}
                        value={keywords}
                        onChange={values => setKeywords(values)}
                        onSearch={values => {
                            // setDetailSearch({})
                            onSearch({
                                'keyword': values,
                                'created_at:gte': filters['created_at:gte'],
                                'created_at:lte': filters['created_at:lte'],
                            })
                        }}
                    />
                </>
            }
            className="mb-16"
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{t('column:Customer classification')}</strong>
                </Col>
                <Col>
                    <ClassificationSelect
                        typeEnum={ClassificationTypeEnum.CUSTOMER}
                        showCreateButton={false}
                        style={{ width: 140 }}
                        onChange={value => updateDetailSearch({ field: 'customer|customer_classification', value })}
                        value={detailSearch['customer|customer_classification']}
                    />
                </Col>
                <Col className="ml-12" flex={'90px'}>
                    <strong>{t('Customer name')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        onChange={value => updateDetailSearch({ field: 'customer|customer_name:like', value })}
                        value={detailSearch['customer|customer_name:like']}
                    />
                </Col>
                <Col className="ml-32" flex={'80px'}>
                    <strong>{t('column:Pic name')}</strong>
                </Col>
                <Col>
                    <UserSelect
                        style={{ width: 180 }}
                        dropdownMatchSelectWidth={false}
                        onChange={(value, record) => {
                            updateDetailSearch({ field: 'pic_id', value })
                            updateDetailSearch({ field: 'cache_pic_name', value: (record?.item || record).pic_name })
                        }}
                        value={detailSearch['pic_id']}
                        record={
                            detailSearch['pic_id']
                                ? {
                                      id: detailSearch['pic_id'],
                                      pic_name: detailSearch['cache_pic_name'],
                                  }
                                : undefined
                        }
                        showCreateButton={false}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle">
                <Col flex={'80px'}>
                    <strong>{'納品日'}</strong>
                </Col>
                <Col>
                    <InsDateRangePicker
                        className="mr-8"
                        value={[
                            detailSearch['delivery_date:gte'] ? moment(detailSearch['delivery_date:gte']) : '',
                            detailSearch['delivery_date:lte'] ? moment(detailSearch['delivery_date:lte']) : '',
                        ]}
                        onChange={(mm, value) => {
                            updateDetailSearch({ field: 'delivery_date:gte', value: value[0] })
                            updateDetailSearch({ field: 'delivery_date:lte', value: value[1] })
                        }}
                    />
                </Col>
            </Row>
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    keywords: state.invoice.keywords,
    detailSearch: state.invoice.detailSearch,
    filters: state.invoice.filters,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(InvoiceListSearch)

InvoiceListSearch.propTypes = {
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    getInvoices: PropTypes.func.isRequired,
}
