import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import UserUpdate from 'modules/master/user/UserUpdate'

const AccountSettingUpdate = ({ userSetting, isEdit, initialValues, onSubmit }) => {
    const { t } = useTranslation()

    return <UserUpdate isAccountSetting={true} />
}

export default AccountSettingUpdate

AccountSettingUpdate.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
}
