import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import InsBackLink from 'components/InsBackLink'
import CustomerDetailComponent from 'modules/master/customer/CustomerDetailComponent'

const CustomerDetail = () => {
    const { id } = useParams()

    const deleteCustomer = async () => {
        await api.delete(APP_URLS.CUSTOMERS_DETAIL.replace(':id', id))
        history.push(Routes.private.master.CUSTOMER_LIST.path)
    }

    return (
        <div className="page-container with-sidebar mb-64">
            <InsBackLink />
            <CustomerDetailComponent id={id} onDelete={() => deleteCustomer()} />
        </div>
    )
}

export default CustomerDetail

CustomerDetail.propTypes = {
    match: PropTypes.object.isRequired,
}
