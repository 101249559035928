import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import Routes from 'routes'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import ClassificationForm from 'modules/master/classification/form/ClassificationForm'
import { getAllClassifications, updateClassificationItemName } from 'helpers/classification.helper'
import InsBackLink from 'components/InsBackLink'

const ClassificationUpdate = ({ match }) => {
    const { t } = useTranslation()
    const { id } = match.params

    const updateClassification = async values => {
        const classification = await api.put(APP_URLS.CLASSIFICATIONS_DETAIL.replace(':id', id), values)
        if (classification) {
            updateClassificationItemName(
                id,
                classification.classification_type_code,
                classification.classification_item_code,
                classification.classification_item_name,
                classification.classification_item_value
            )
            // history.push(Routes.private.master.CLASSIFICATION_LIST.CLASSIFICATION_LIST)
            window.location.href = Routes.private.master.CLASSIFICATION_LIST.path
        }
    }

    const classifications = getAllClassifications()
    let record = classifications.filter(c => c.id.toString() === id)
    if (record.length === 0) {
        record = {}
    } else {
        record = record[0]
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">
                {t('navigation:{{entity}} edit', { entity: t('entity:Classification') })}
            </h1>
            <ClassificationForm
                modalConfirmSave={modalConfirmSave}
                setModalConfirmSave={setModalConfirmSave}
                isEdit={true}
                initialValues={record}
                onSubmit={(values, redirectTo, confirmSave = true) => {
                    if (confirmSave) setModalConfirmSave(true)
                    else updateClassification(values, redirectTo, confirmSave)
                }}
            />
        </div>
    )
}

export default ClassificationUpdate

ClassificationUpdate.propTypes = {
    match: PropTypes.object,
}
