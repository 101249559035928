import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import InsModal from 'components/InsModal'
import { Col, Row } from 'antd'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import InsInputSearch from 'components/InsInputSearch'
import InsLoader from 'components/InsLoader'
import InsDataTable from 'components/datatable/InsDataTable'
import InsButton from 'components/InsButton'
import { getData, setFilters, setKeywords } from 'modules/business/invoice/invoiceProjectModal.slice'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import Routes from 'routes'
import { dateToStr } from 'helpers/date.helper'
import { formatNumber } from 'helpers/number.helper'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { AccessTimeIcon, CheckIcon, FolderOpenIcon, LockIcon, OpenInNewIcon } from 'components/InsIcon'

const InvoiceProjectModal = ({
    state,
    title,
    customer,
    exclude_project_ids = [],
    children,
    visible,
    onCancel,
    onOk,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [selectedSales, setSelectedSales] = useState([])
    const [modalOverrideVisible, setModalOverrideVisible] = useState(false)

    const invoiceProjectColumns = [
        {
            title: '案件番号',
            dataIndex: 'project_id',
            sorter: true,
            render: (text, record) => (
                <>
                    {record.project.is_locked ? <LockIcon className="color-blue-gray-600 mr-4" size={14} /> : null}
                    <InsButton
                        buttonStyle="plain"
                        linkTo={
                            Routes.private.business.PROJECT_DETAIL.path.replace(':id', record.project.id) +
                            (record.countForCheckInvoice > 0 ? '/invoices' : '/sales')
                        }
                        buttonType="link"
                        target="_blank"
                    >
                        {record.project.project_code}
                    </InsButton>
                </>
            ),
        },
        {
            title: '顧客名',
            dataIndex: 'customer_name',
            //sorter: true,
            render: (text, record) => record.customer.customer_name,
        },
        {
            title: '件名',
            dataIndex: 'sale_name',
            sorter: true,
        },
        {
            title: '納品日',
            dataIndex: 'delivery_date',
            sorter: true,
            render: text => dateToStr(text, 'YYYY/MM/DD'),
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            sorter: true,
            render: (text, record) =>
                getClassificationItemName(
                    ClassificationTypeEnum.BUSINESS_STATUS.value,
                    record.project.status.toString()
                ),
        },
        {
            title: '請求額',
            dataIndex: 'total',
            sorter: true,
            render: text => formatNumber(text),
            className: 'text-right',
        },
        {
            title: '赤伝',
            width: 45,
            render: (text, r) => (r.project.red_slip ? <span className="color-pantone-213-c">{'赤伝'}</span> : ''),
        },
    ]

    const deliveryDateChangeHandler = value => {
        const newFilters = Object.assign({}, state.filters, {
            'delivery_date:gte': value[0] + '\t00:00:00',
            'delivery_date:lte': value[1] + '\t23:59:59',
        })
        dispatch(setFilters(newFilters))
        getSalesByCustomer(state.pagination, newFilters, state.sorter)
    }

    const getSalesByCustomer = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        dispatch(getData({ pagination, filters, sorter }))
    }

    const updateFilters = obj => {
        const newFilters = Object.assign({}, state.filters, obj)
        const newPagination = Object.assign({}, state.pagination, { current: 1 })
        dispatch(setFilters(newFilters))
        getSalesByCustomer(newPagination, newFilters)
    }

    useEffect(() => {
        const newFilters = Object.assign({}, state.filters, {
            customer_id: customer.id,
            exclude_project_ids: exclude_project_ids.join(','),
        })
        dispatch(setFilters(newFilters))
        getSalesByCustomer(state.pagination, newFilters, state.sorter)
    }, [exclude_project_ids])

    const onOkFunc = () =>
        (selectedSales || []).filter(sSale => sSale.countForCheckInvoice > 0).length > 0
            ? setModalOverrideVisible(true)
            : onOk && onOk(selectedSales)

    const [selectedKeys, setSelectedKeys] = useState([])
    const selectAll = () => {
        const tmpSelectedKeys = state.data.filter(d => !(d.project.is_locked || d.project.red_slip)).map(d => d.id)
        setSelectedKeys(tmpSelectedKeys)
        // manual: fix no-effect
        setSelectedSales(state.data.filter(element => tmpSelectedKeys.indexOf(element.id) > -1))
    }

    const deSelectAll = () => {
        setSelectedKeys([])
        // manual: fix no-effect
        setSelectedSales([])
    }

    const onCancelFunc = () => {
        // clear checkbox on cancel
        setSelectedKeys([])
        setSelectedSales([])
        onCancel()
    }

    return (
        <>
            <InsModal
                className="invoice-project-modal"
                title={title}
                width={'90%'}
                visible={visible}
                onCancel={onCancelFunc}
                onOk={onOkFunc}
                okText={'案件複写'}
                style={{ maxWidth: 950 }}
                footer={[
                    <InsButton
                        key="select-deselect"
                        centered
                        style={{ width: 94 }}
                        icon={<CheckIcon size={16} />}
                        buttonStyle="default"
                        className="select-deselect-footer ml-8"
                        onClick={() => (selectedKeys.length > 0 ? deSelectAll() : selectAll())}
                    >
                        {t(selectedKeys.length > 0 ? '選択解除' : '全て選択')}
                    </InsButton>,
                    <InsButton centered key="cancel" onClick={onCancelFunc} buttonStyle="outline">
                        {'キャンセル'}
                    </InsButton>,
                    <InsButton centered key="ok" onClick={onOkFunc} buttonStyle="primary">
                        {'案件複写'}
                    </InsButton>,
                ]}
            >
                <div className="current-customer">
                    <label>{'請求先名'}</label>
                    <InsButton
                        buttonStyle="plain"
                        buttonType="link"
                        target="_blank"
                        linkTo={Routes.private.master.CUSTOMER_DETAIL.path.replace(':id', customer.id)}
                    >
                        {customer.customer_name}
                    </InsButton>
                </div>
                <Row
                    gutter={[8, 16]}
                    align="middle"
                    style={{
                        background: '#ECEFF1',
                        height: 48,
                        margin: 8,
                    }}
                >
                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                        <label className="fw-bold ml-16 mr-4">{'納品書発行日'}</label>
                        <InsDateRangePicker
                            className="mr-8"
                            defaultValue={[moment().subtract(2, 'months'), moment()]}
                            onChange={(mm, value) => deliveryDateChangeHandler(value)}
                        />
                        <InsInputSearch
                            style={{ width: '258px' }}
                            placeholder={t('案件番号、件名など')}
                            value={state.keywords}
                            onChange={values => dispatch(setKeywords(values))}
                            onSearch={values => {
                                updateFilters({ keyword: values })
                            }}
                        />
                    </Col>
                </Row>
                <div className="ml-8 mr-8">
                    <InsDataTable
                        tableStyle="compact"
                        loading={state.loading ? { indicator: <InsLoader /> } : false}
                        striped
                        columns={invoiceProjectColumns}
                        data={state.data}
                        onChange={(pagination, filter, sorter) => {
                            getSalesByCustomer(state.pagination, state.filter, sorter)
                        }}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        hasSelection={true}
                        onSelectionChanged={keys => {
                            setSelectedKeys(keys)
                            setSelectedSales(state.data.filter(element => keys.indexOf(element.id) > -1))
                        }}
                        checkboxProps={record => ({
                            disabled: record.project.is_locked || record.project.red_slip,
                        })}
                        subSelectedKeys={selectedKeys || []}
                        scroll={{ y: 400 }}
                    />
                </div>
            </InsModal>
            {children}
            <InsModal
                title="上書き保存"
                visible={modalOverrideVisible}
                onCancel={() => setModalOverrideVisible(false)}
                onOk={() => {
                    setModalOverrideVisible(false)
                    onOk && onOk(selectedSales)
                }}
                okText={'上書き保存する'}
            >
                <p>{'選択した案件のうち、すでに請求書が作成されている案件があります。上書き保存しますか？'}</p>
                <div className="invoice-billable-list-auto">
                    <div className="invoice-billable-list">
                        {(selectedSales || [])
                            .filter(sSale => sSale.countForCheckInvoice > 0)
                            .map(ovrSale => (
                                <div className="invoice-billable-item" key={ovrSale.id}>
                                    <FolderOpenIcon size={20} className="mdi-folder-open mr-8" />
                                    <span className="mr-8">{'案件  :'}</span>
                                    <span className="mr-16">
                                        <InsButton
                                            buttonStyle="plain"
                                            linkTo={
                                                Routes.private.business.PROJECT_DETAIL.path.replace(
                                                    ':id',
                                                    ovrSale.project.id
                                                ) + '/invoices'
                                            }
                                            buttonType="link"
                                            target="_blank"
                                        >
                                            <span className="project-group-link mr-8">
                                                {ovrSale.project.project_code}
                                            </span>
                                            <OpenInNewIcon size={16} className="mdi_open_in_new" />
                                        </InsButton>
                                    </span>
                                    <AccessTimeIcon size={20} className="mdi-access-time mr-8" />
                                    <span className="fw-500 spc flex-1">
                                        {dateToStr(ovrSale.delivery_date, 'YYYY/MM/DD')}
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>
            </InsModal>
        </>
    )
}

const mapState = state => ({
    state: state.invoiceProjectModal,
})
export default connect(mapState)(InvoiceProjectModal)

InvoiceProjectModal.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    title: PropTypes.any,
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    customer: PropTypes.object,
    exclude_project_ids: PropTypes.array,
}
