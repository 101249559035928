import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { APP_URLS } from 'constants/url.constant'
import InsRemoteSelect from 'components/select/InsRemoteSelect'
import InsInput from 'components/InsInput'
import { TruckIcon } from 'components/InsIcon'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { connect } from 'react-redux'
import { updateDestination } from 'modules/business/placeOrder/place_order_detail.slice'
import { rdStr } from 'helpers/string.helper'

const PlaceOrderDestinationRow = React.memo(({ id, value, updateDestination }) => {
    const handlerShippingAddressSelect = async (id, option) => {
        let data = {}
        data.delivery_destination_address = `${option.item.post_code || ''} ${getClassificationItemName(
            ClassificationTypeEnum.PREFECTURE.value,
            option.item.prefecture_code
        )}${option.item.address1 || ''}`
        data.delivery_destination_pic_name = option.item.direct_delivery_pic_name
        data.delivery_destination_pic_title = getClassificationItemName(
            ClassificationTypeEnum.CUSTOMER_TITLE.value,
            option.item.shipping_address_title
        )
        form.setFieldsValue(data)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(value)
    }, [])

    let timeout = null
    const changeHandle = () => {
        if (timeout !== null) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            updateDestination({ id, value: form.getFieldsValue() })
        }, 300)
    }

    return (
        <Form className="one-row" form={form} onValuesChange={changeHandle} name={rdStr()}>
            <TruckIcon
                size={20}
                className="color-pantone-2422-c ins-flex flex-middle mr-8"
                style={{ flex: '0 0 20px' }}
            />
            <Form.Item name="delivery_destination_name" style={{ flex: 254 }}>
                <InsRemoteSelect
                    url={`${APP_URLS.SHIPPING_ADDRESS}?page_size=100&is_enabled=1&keyword[]=`}
                    initialOptions={[{ id: '', shipping_address_name1: '' }]}
                    optionIndexKey="id"
                    optionValueKey="shipping_address_name1"
                    optionTextKey="shipping_address_name1"
                    onChange={(id, option) => handlerShippingAddressSelect(id, option)}
                    optionLabelProp="label"
                    optionRenderer={item => (
                        <Select.Option
                            key={item.id}
                            value={item.shipping_address_name1}
                            item={item}
                            label={item.shipping_address_name1}
                        >
                            <span style={{ fontWeight: 'bold' }}>{item.shipping_address_code}</span>
                            {'　'}
                            {item.shipping_address_name1}
                        </Select.Option>
                    )}
                />
            </Form.Item>
            <Form.Item name="delivery_destination_address" style={{ flex: 440 }}>
                <InsInput />
            </Form.Item>
            <Form.Item name="delivery_destination_pic_name" style={{ flex: 110 }}>
                <InsInput />
            </Form.Item>
            <Form.Item name="delivery_destination_pic_title" style={{ flex: 60 }}>
                <InsInput />
            </Form.Item>
        </Form>
    )
})

const mapState = (state, props) => ({
    value: state.placeOrderDetail.destinations[props.id],
})
const mapDispatch = { updateDestination }
export default connect(mapState, mapDispatch)(PlaceOrderDestinationRow)

PlaceOrderDestinationRow.propTypes = {
    id: PropTypes.number,
    value: PropTypes.object,
    updateDestination: PropTypes.func,
}
