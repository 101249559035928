import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import Routes from 'routes'
import { APP_URLS } from 'constants/url.constant'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import UsageStatusEnum from 'enums/usage_status.enum'
import api from 'helpers/api.helper'
import { getClassificationItemName, getClassificationItemNameFromValue } from 'helpers/classification.helper'
import { getEnumText } from 'helpers/enum.helper'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import InsHelpText from 'components/InsHelpText'
import { InsBlockLoader } from 'components/InsLoader'
import { BackIcon, DeleteIcon, EditIcon, LaunchIcon } from 'components/InsIcon'
import { CollectionScheduleDayEnum, CollectionScheduleMonthEnum } from 'enums/collection_schedule.enum'
import { convertLineBreak } from 'helpers/note.helper'
import PropTypes from 'prop-types'
import InsModal from 'components/InsModal'
import { Link } from 'react-router-dom'
import { SupplierBillAddressEnum } from 'enums/bill_address.enum'

const SupplierDetailComponent = ({ inModal = false, id, onDelete }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [modalPicDetail, setModalPicDetail] = useState(false)
    const [supplier, setSupplier] = useState(null)
    const [deleteSupplier, setDeleteSupplier] = useState({
        visible: false,
    })

    useEffect(() => {
        setLoading(true)
        const getSupplier = async id => {
            const data = await api.get(APP_URLS.SUPPLIERS_DETAIL.replace(':id', id))
            if (data) {
                setSupplier(data)
                setLoading(false)
            }
        }

        getSupplier(id)
    }, [id])

    const showPaymentScheduleMonth = scheduleMonth => {
        // if (!scheduleMonth) return ''
        return [
            CollectionScheduleMonthEnum.CURRENT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_MONTH.value,
            CollectionScheduleMonthEnum.NEXT_NEXT_MONTH.value,
        ].indexOf(scheduleMonth) > -1
            ? getEnumText(CollectionScheduleMonthEnum, scheduleMonth)
            : `${scheduleMonth}ヶ月後`
    }

    const showPaymentScheduleDay = scheduleDay => {
        if (!scheduleDay) return ''
        return scheduleDay === CollectionScheduleDayEnum.END_OF_THE_MONTH.value
            ? getEnumText(CollectionScheduleDayEnum, scheduleDay)
            : `${scheduleDay}日`
    }

    if (loading) {
        return <InsBlockLoader />
    }

    return (
        <>
            {!inModal && (
                <>
                    <InsFloatBar visible={true}>
                        <Row justify="space-between">
                            <Row align="middle">
                                <Col>
                                    <InsButton
                                        width="76px"
                                        disabled={!supplier.can_delete}
                                        icon={<DeleteIcon size={16} />}
                                        onClick={() =>
                                            setDeleteSupplier({
                                                visible: true,
                                            })
                                        }
                                    >
                                        {t('common:Delete')}
                                    </InsButton>
                                </Col>
                                <Col>
                                    <InsHelpText className="ml-8">
                                        {t('Can not delete in-used {{item}}', { item: t('entity:Supplier') })}
                                    </InsHelpText>
                                </Col>
                            </Row>

                            <Col>
                                <Row gutter={8} justify="end">
                                    <Col>
                                        <InsButton
                                            className="fw-500"
                                            buttonType="link"
                                            linkTo={Routes.private.master.SUPPLIER_LIST.path}
                                            buttonStyle="outline"
                                            buttonSize="big"
                                            width="110px"
                                            icon={<BackIcon />}
                                        >
                                            {t('common:Back to list')}
                                        </InsButton>
                                    </Col>
                                    <Col>
                                        <InsButton
                                            className="fw-bold"
                                            buttonType="link"
                                            linkTo={Routes.private.master.SUPPLIER_UPDATE.path.replace(':id', id)}
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            width="145px"
                                            icon={<EditIcon size={20} />}
                                        >
                                            {t('navigation:{{entity}} information edit', {
                                                entity: t('entity:Supplier'),
                                            })}
                                        </InsButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </InsFloatBar>
                    <InsModal
                        title={t('common:Delete')}
                        visible={deleteSupplier.visible}
                        onCancel={() =>
                            setDeleteSupplier({
                                visible: false,
                            })
                        }
                        onOk={onDelete}
                        okText="はい"
                        cancelText="いいえ"
                    >
                        <h2>{t('common:Are you sure')}</h2>
                    </InsModal>
                </>
            )}
            <div className={`${inModal ? '' : 'p-24'}`}>
                <h1 className="page-title-big mb-24 ins-flex flex-middle">
                    {supplier.supplier_name}
                    {!inModal && (
                        <InsButton
                            buttonType="link"
                            buttonStyle="plain"
                            className="ml-8 color-blue-gray-200"
                            icon={<EditIcon size={24} />}
                            linkTo={Routes.private.master.SUPPLIER_UPDATE.path.replace(':id', id)}
                        />
                    )}
                </h1>
                <section>
                    <h2 className="section-title">{t('common:Basic information')}</h2>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('Supplier management code')}</th>
                                <td className="fw-bold color-blue-gray-900">{supplier.supplier_code}</td>
                            </tr>
                            <tr>
                                <th>{t('column:Supplier name')}</th>
                                <td className="fw-bold color-blue-gray-900">{supplier.supplier_name}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Furigana')}</th>
                                <td>{supplier.supplier_name_kana}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Usage condition')}</th>
                                <td>{t(`enum:${getEnumText(UsageStatusEnum, supplier.is_enabled)}`)}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Post code')}</th>
                                <td>{supplier.post_code}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Address')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.PREFECTURE.value,
                                        supplier.prefecture_code
                                    )}
                                    &nbsp;
                                    {supplier.address1}&nbsp;{supplier.address2}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('column:Supplier pic name')}</th>
                                <td>{supplier.supplier_pic_name}</td>
                            </tr>
                            <tr>
                                <th>{t('column:Supplier title')}</th>
                                <td>
                                    {getClassificationItemName(
                                        ClassificationTypeEnum.CUSTOMER_TITLE.value,
                                        supplier.supplier_title
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('common:Phone number')}</th>
                                <td>{supplier.phone_number}</td>
                            </tr>
                            <tr>
                                <th>{t('common:Fax number')}</th>
                                <td>{supplier.fax_number}</td>
                            </tr>
                            <tr>
                                <th>{t('column:Note')}</th>
                                <td>{convertLineBreak(supplier.note)}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <h2 className="section-title">{t('common:Payment information')}</h2>
                    <table className="ins-info-table">
                        <tbody>
                            <tr>
                                <th>{t('Payment destination')}</th>
                                <td>
                                    {supplier.payee &&
                                        (supplier.payee_id === supplier.id ? (
                                            getEnumText(
                                                SupplierBillAddressEnum,
                                                SupplierBillAddressEnum.SAME_AS_SUPPLIER_NAME.value
                                            )
                                        ) : (
                                            <>
                                                {`${getEnumText(
                                                    SupplierBillAddressEnum,
                                                    SupplierBillAddressEnum.SET_SEPARATELY.value
                                                    // eslint-disable-next-line no-irregular-whitespace
                                                )}：　`}
                                                <InsButton
                                                    onClick={() => {
                                                        setModalPicDetail(true)
                                                    }}
                                                    buttonStyle="plain"
                                                    className={'fw-bold'}
                                                >
                                                    {supplier.payee ? supplier.payee.supplier_name : null}
                                                </InsButton>
                                                <InsModal
                                                    className="ins-detail-modal"
                                                    width={760}
                                                    visible={modalPicDetail}
                                                    onCancel={() => setModalPicDetail(false)}
                                                    footer={null}
                                                >
                                                    <SupplierDetailComponent
                                                        inModal={true}
                                                        id={supplier.payee ? supplier.payee.id : null}
                                                    />
                                                </InsModal>
                                            </>
                                        ))}
                                </td>
                            </tr>
                            {supplier.payee_id === supplier.id && (
                                <>
                                    <tr>
                                        <th>{t('column:Payment date classification')}</th>
                                        <td>
                                            {getClassificationItemNameFromValue(
                                                ClassificationTypeEnum.PAYMENT_DATE.value,
                                                supplier.payment_date_classification
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Collection schedule')}</th>
                                        <td>
                                            {supplier.payment_schedule_day || supplier.payment_schedule_month
                                                ? `${showPaymentScheduleMonth(
                                                      supplier.payment_schedule_month
                                                      // eslint-disable-next-line no-irregular-whitespace
                                                  )} 　${showPaymentScheduleDay(supplier.payment_schedule_day)}`
                                                : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Collection type')}</th>
                                        <td>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.COLLECTION_SCHEDULE_TYPE.value,
                                                supplier.payment_schedule_payment_type
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('column:Collection method')}</th>
                                        <td>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.COLLECTION_SCHEDULE_METHOD.value,
                                                supplier.payment_schedule_payment_method
                                            )}
                                        </td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <th>{t('Payee name')}</th>
                                <td>{supplier.bank_transfer1_name}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
            {inModal && (
                <Row justify="end">
                    <Link to={Routes.private.master.SUPPLIER_DETAIL.path.replace(':id', supplier.id)} target="_blank">
                        <InsButton
                            icon={<LaunchIcon size={12} className="color-pantone-2738-c" />}
                            buttonStyle="default"
                        >
                            {t('Open new tab')}
                        </InsButton>
                    </Link>
                </Row>
            )}
        </>
    )
}

export default SupplierDetailComponent

SupplierDetailComponent.propTypes = {
    id: PropTypes.any.isRequired,
    inModal: PropTypes.bool,
    onDelete: PropTypes.func,
}

SupplierDetailComponent.defaultProps = {
    inModal: false,
}
