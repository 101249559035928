import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_URLS } from 'constants/url.constant'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import PropTypes from 'prop-types'
import InsBackLink from 'components/InsBackLink'
import CompanyForm from 'modules/master/company/form/CompanyForm'

const CompanyCreate = () => {
    const { t } = useTranslation()

    const createCompany = async values => {
        const data = await api.post(APP_URLS.COMPANIES, values)
        if (data) {
            history.goBack()
        }
    }
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    return (
        <div className="page-container with-sidebar">
            <InsBackLink />
            <h1 className="page-title-big m-24">{t('navigation:Company information registration')}</h1>
            <CompanyForm
                modalConfirmSave={modalConfirmSave}
                setModalConfirmSave={setModalConfirmSave}
                onSubmit={(values, redirectTo, confirmSave = true) => {
                    if (confirmSave) setModalConfirmSave(true)
                    else {
                        createCompany(values, redirectTo, confirmSave)
                        setModalConfirmSave(false)
                    }
                }}
            />
        </div>
    )
}

export default CompanyCreate

CompanyCreate.propTypes = {
    isEdit: PropTypes.bool,
}
