import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { CloseIcon, MenuIcon } from 'components/InsIcon'

const InsDragList = React.memo(({ groupId, data, order, onRemove, rowComponent: Component }) => {
    return order.map((id, index) => {
        const item = data[id]
        if (item?.isUnknown) return null
        return (
            <Draggable key={id} draggableId={id} index={index}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div className="ins-drag-row-wrapper">
                            <div className="ins-drag-row-handle" {...provided.dragHandleProps}>
                                <MenuIcon size={16} />
                            </div>
                            <Component id={id} />
                            <div className="ins-drag-row-delete">
                                <CloseIcon size={24} onClick={() => onRemove(id, groupId)} />
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        )
    })
})

export default InsDragList
