import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Col, Row } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { CloseIcon, ReloadIcon, SearchIcon } from 'components/InsIcon'
import InsInput from 'components/InsInput'
import InsButton from 'components/InsButton'
import InsCollapse from 'components/InsCollapse'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setDetailSearch, setKeywords, updateDetailSearch } from 'modules/master/shippingAddress/shippingAddress.slice'

const ShippingAddressListSearch = ({
    keywords,
    detailSearch,
    setKeywords,
    setDetailSearch,
    updateDetailSearch,
    onSearch,
    onReset,
}) => {
    const { t } = useTranslation()
    const collapseRef = useRef()

    return (
        <InsCollapse
            ref={r => (collapseRef.current = r)}
            header={
                <InsInputSearch
                    style={{ width: '380px' }}
                    placeholder={t('Shipping address code, shipping address name, etc')}
                    value={keywords}
                    onChange={values => setKeywords(values)}
                    onSearch={values => {
                        setDetailSearch({})
                        onSearch({ keyword: values })
                    }}
                />
            }
        >
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'140px'}>
                    <strong>{t('column:Shipping address code')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['shipping_address_code:like']}
                        onChange={value => updateDetailSearch({ field: 'shipping_address_code:like', value })}
                    />
                </Col>
                <Col className="ml-32" flex={'140px'}>
                    <strong>{t('column:Shipping address name')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['shipping_address_name:like']}
                        onChange={value => updateDetailSearch({ field: 'shipping_address_name:like', value })}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="middle">
                <Col flex={'140px'}>
                    <strong>{t('column:Address')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['address:like']}
                        onChange={value => updateDetailSearch({ field: 'address:like', value })}
                    />
                </Col>
                <Col className="ml-32" flex={'140px'}>
                    <strong>{t('column:Phone number')}</strong>
                </Col>
                <Col>
                    <InsInput
                        style={{ width: '180px' }}
                        suffix={<SearchIcon size={16} />}
                        value={detailSearch['phone_number:like']}
                        onChange={value => updateDetailSearch({ field: 'phone_number:like', value })}
                    />
                </Col>
            </Row>
            <Row justify="end">
                <Col className="ins-flex flex-middle">
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<CloseIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            collapseRef.current.setContentExpanded()
                        }}
                    >
                        {t('common:Close')}
                    </InsButton>
                    <InsButton
                        className="mr-4"
                        buttonStyle="default"
                        icon={<ReloadIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            setDetailSearch({})
                            onReset()
                        }}
                    >
                        {t('common:Reset')}
                    </InsButton>
                    <InsButton
                        width={90}
                        buttonStyle="default"
                        icon={<SearchIcon className="color-pantone-2738-c" size={16} width={90} />}
                        onClick={() => {
                            setKeywords([])
                            onSearch(detailSearch)
                        }}
                    >
                        {t('common:Search')}
                    </InsButton>
                </Col>
            </Row>
        </InsCollapse>
    )
}

const mapState = state => ({
    keywords: state.shippingAddress.keywords,
    detailSearch: state.shippingAddress.detailSearch,
})
const mapDispatch = { setKeywords, setDetailSearch, updateDetailSearch }
export default connect(mapState, mapDispatch)(ShippingAddressListSearch)

ShippingAddressListSearch.propTypes = {
    detailSearch: PropTypes.object.isRequired,
    keywords: PropTypes.array.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    setDetailSearch: PropTypes.func.isRequired,
    setKeywords: PropTypes.func.isRequired,
    updateDetailSearch: PropTypes.func.isRequired,
}
