import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InsFloatBar from 'components/InsFloatBar'
import { Col, Row } from 'antd'
import InsButton from 'components/InsButton'
import { DeleteIcon, EditIcon, FileCopyIcon } from 'components/InsIcon'
import ProjectCloneModal from 'modules/business/project/ProjectCloneModal'
import BusinessStatusEnum from 'enums/business_status.enum'
import CloneDocumentEnum from 'enums/clone_document.enum'
import ReactToPrint from 'react-to-print'
import api from 'helpers/api.helper'
import history from 'helpers/history.helper'
import { APP_URLS } from 'constants/url.constant'
import InsModal from 'components/InsModal'
import Routes from 'routes'
import { can } from 'helpers/permission.helper'
import PlaceOrderDOMPrint from 'modules/business/placeOrder/PlaceOrderDOMPrint'
import { InsBlockLoader } from 'components/InsLoader'
import { useLocation } from 'react-router-dom'

const PlaceOrderPreview = ({ placeOrder, project, updateLink, response }) => {
    const [modalCloneVisible, setModalCloneVisible] = useState(false)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const canEditPlaceOrder = can('PLACE_ORDER_EDITABLE') && !project.is_locked
    const { t } = useTranslation()
    const componentRef = useRef()
    const deletePlaceOrder = async () => {
        setLoading(true)
        await api.delete(APP_URLS.PLACE_ORDER_DETAIL.replace(':id', placeOrder.id)).then(r => {
            project.place_order = null
            if (placeOrder.place_order_classification === 3) {
                if (location?.state?.from_shipment) history.goBack()
                else {
                    history.push(Routes.private.business.PROJECT.path)
                    // response(Routes.private.business.PROJECT)
                    setModalDeleteVisible(false)
                    setLoading(false)
                }
            } else {
                history.push(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
                response()
                setModalDeleteVisible(false)
                setLoading(false)
            }
        })
    }

    const updateStatusPlaceOrder = async status => {
        if (project?.place_order?.status !== BusinessStatusEnum.ORDER_NOTE_ISSUED.value) {
            const data = await api.post(APP_URLS.PLACE_ORDER_UPDATE_STATUS, {
                status: status,
                place_order_id: placeOrder.id,
            })
            if (data.isSuccess) {
                response()
                // history.push(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
            }
        }
    }

    return loading ? (
        <InsBlockLoader />
    ) : (
        placeOrder && (
            <div className="page-container">
                <PlaceOrderDOMPrint
                    project={project}
                    placeOrderPrint={placeOrder}
                    componentRef={componentRef}
                    showPreview={true}
                />

                {canEditPlaceOrder && (
                    <InsFloatBar visible={true}>
                        <Row justify="space-between" align="middle">
                            <Col>
                                <InsButton
                                    disabled={placeOrder?.status === BusinessStatusEnum.ORDER_RECEIVED.value}
                                    icon={
                                        <DeleteIcon
                                            size={20}
                                            className={
                                                placeOrder?.status === BusinessStatusEnum.ORDER_RECEIVED.value
                                                    ? ''
                                                    : 'color-pantone-213-c'
                                            }
                                        />
                                    }
                                    buttonStyle="default"
                                    onClick={() => setModalDeleteVisible(true)}
                                >
                                    {t('Place order delete document')}
                                </InsButton>
                                <InsModal
                                    className="ins-modal-w-550"
                                    title="削除"
                                    visible={modalDeleteVisible}
                                    onCancel={() => setModalDeleteVisible(false)}
                                    onOk={deletePlaceOrder}
                                    okText="はい"
                                    cancelText="いいえ"
                                >
                                    <h3>{'発注書を削除してもよろしいですか？'}</h3>
                                    <p>{'※削除されたドキュメントは各ドキュメントの「削除」タブで確認できます。'}</p>
                                </InsModal>
                                <ProjectCloneModal
                                    title={CloneDocumentEnum.PLACE_ORDER.text}
                                    cloneDocument={CloneDocumentEnum.PLACE_ORDER.value}
                                    visible={modalCloneVisible}
                                    project={{ ...Object.assign({}, project), ...{ place_order: placeOrder } }}
                                    onCancel={() => {
                                        setModalCloneVisible(false)
                                    }}
                                >
                                    <InsButton
                                        icon={<FileCopyIcon size={20} className="color-pantone-2738-c" />}
                                        buttonStyle="default"
                                        style={{ marginLeft: 8 }}
                                        onClick={() => {
                                            setModalCloneVisible(true)
                                        }}
                                    >
                                        {t('Place order clone document')}
                                    </InsButton>
                                </ProjectCloneModal>
                                <InsButton
                                    icon={<EditIcon size={20} className="color-pantone-2738-c" />}
                                    buttonStyle="default"
                                    onClick={() => updateLink()}
                                    width="112px"
                                    className="ml-8"
                                >
                                    {t('Place order edit document')}
                                </InsButton>
                            </Col>
                            <Col className="ins-flex flex-middle btn-update-project-bottom">
                                {project?.place_order?.status === BusinessStatusEnum.ORDER_NOTE_CREATING.value && (
                                    <ReactToPrint
                                        trigger={() => (
                                            <InsButton
                                                className="fw-bold"
                                                buttonStyle="primary"
                                                buttonSize="big"
                                                style={{ width: 140 }}
                                                onClick={() => {}}
                                            >
                                                {t('発注書 発行')}
                                            </InsButton>
                                        )}
                                        content={() => componentRef.current}
                                        onBeforePrint={() =>
                                            updateStatusPlaceOrder(BusinessStatusEnum.ORDER_NOTE_ISSUED.value)
                                        }
                                    />
                                )}
                                {project?.place_order?.status === BusinessStatusEnum.ORDER_NOTE_ISSUED.value && (
                                    <>
                                        <ReactToPrint
                                            trigger={() => (
                                                <InsButton
                                                    className="fw-bold"
                                                    buttonStyle="outline"
                                                    buttonSize="big"
                                                    style={{ width: 140 }}
                                                    onClick={() => {}}
                                                >
                                                    {t('発注書 再発行')}
                                                </InsButton>
                                            )}
                                            content={() => componentRef.current}
                                        />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </InsFloatBar>
                )}
            </div>
        )
    )
}
export default PlaceOrderPreview

PlaceOrderPreview.propTypes = {
    placeOrder: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    updateLink: PropTypes.func,
    response: PropTypes.func,
}
