import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BaseStatePropShape } from 'constants/proptypes.constant'
import InsButton from 'components/InsButton'
import InsDataTable from 'components/datatable/InsDataTable'
import { formatNumberComma } from 'helpers/number.helper'
import { connect } from 'react-redux'
import { dateFormat } from 'helpers/date.helper'
import InsLoader from 'components/InsLoader'
import { DisplayShipmentTypeEnum } from 'enums/shipment.enum'
import InsFloatBar from 'components/InsFloatBar'
import { Col, Row } from 'antd'
import { AssignmentIcon } from 'components/InsIcon'
import { CSVLink } from 'react-csv'
import Routes from 'routes'
import InsModal from 'components/InsModal'
import SupplierDetailComponent from 'modules/master/supplier/SupplierDetailComponent'
import ProductDetailComponent from 'modules/master/product/ProductDetailComponent'
import history from 'helpers/history.helper'
import { APP_URLS } from 'constants/url.constant'
import NavigationEnum from 'enums/navigation.enum'

let sumTotal = 0

const IncomingDataTable = ({ state, getShipments }) => {
    const { t } = useTranslation()
    const [incomingData, setIncomingData] = useState(null)
    const [modalSupplierDetail, setModalSupplierDetail] = useState({
        supplierId: null,
        visible: false,
    })

    const incomingProductColumns = [
        {
            title: '入荷日',
            dataIndex: 'placeOrder|delivery_date',
            sorter: true,
            render: (text, r) => dateFormat(r.place_order.delivery_date),
        },
        {
            title: '案件番号',
            dataIndex: 'project|project_code',
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            history.push(
                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.place_order?.project?.id) +
                                    APP_URLS.PLACE_ORDER +
                                    '?from=' +
                                    NavigationEnum.SHIPMENTS,
                                {
                                    from_shipment: true,
                                }
                            )
                        }}
                    >
                        {r.place_order?.project?.project_code}
                    </InsButton>
                )
            },
        },
        {
            title: '仕入先',
            dataIndex: 'supplier_name',
            render: (text, r) => {
                return (
                    <>
                        <InsButton
                            buttonStyle="plain"
                            onClick={() => {
                                setModalSupplierDetail({
                                    supplierId: r.place_order.supplier_id,
                                    visible: true,
                                })
                            }}
                        >
                            {r.place_order.supplier?.supplier_name}
                        </InsButton>
                    </>
                )
            },
        },
        {
            title: '品名',
            dataIndex: 'product|product_name',
            sorter: true,
            render: (text, r) => {
                return (
                    <>
                        <InsButton
                            buttonStyle="plain"
                            onClick={() => {
                                setModalProductDetail({
                                    productId: r.product_id,
                                    visible: true,
                                })
                            }}
                        >
                            {r.product?.product_name}
                        </InsButton>
                    </>
                )
            },
        },
        {
            title: '仕入単価',
            dataIndex: 'unit_price',
            sorter: true,
            render: text => formatNumberComma(text),
        },
        {
            title: '入荷数',
            dataIndex: 'quantity',
            render: text => formatNumberComma(text),
            sorter: true,
        },
        {
            title: '小計',
            dataIndex: 'unit_price,quantity',
            render: (text, r) => (
                <span style={{ fontWeight: 'bold' }}>{formatNumberComma(r.unit_price * r.quantity)}</span>
            ),
            sorter: true,
        },
    ]

    const incomingProjectColumns = [
        {
            title: '案件番号',
            dataIndex: 'project_code',
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            history.push(
                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id) +
                                    APP_URLS.PLACE_ORDER +
                                    '?from=' +
                                    NavigationEnum.SHIPMENTS,
                                {
                                    from_shipment: true,
                                }
                            )
                        }}
                    >
                        {text}
                    </InsButton>
                )
            },
            sorter: true,
        },
        {
            title: '件名',
            dataIndex: 'project_name',
            sorter: true,
            render: (text, r) => {
                return (
                    <InsButton
                        buttonStyle="plain"
                        onClick={() => {
                            history.push(
                                Routes.private.business.PROJECT_DETAIL.path.replace(':id', r.id) +
                                    APP_URLS.PLACE_ORDER +
                                    '?from=' +
                                    NavigationEnum.SHIPMENTS,
                                {
                                    from_shipment: true,
                                }
                            )
                        }}
                    >
                        {text}
                    </InsButton>
                )
            },
        },
        {
            title: '仕入先',
            dataIndex: 'placeOrder|supplier|supplier_name',
            render: (text, r) => {
                return (
                    <>
                        <InsButton
                            buttonStyle="plain"
                            onClick={() => {
                                setModalSupplierDetail({
                                    supplierId: r.place_order.supplier_id,
                                    visible: true,
                                })
                            }}
                        >
                            {r.place_order?.supplier?.supplier_name}
                        </InsButton>
                    </>
                )
            },
        },
        {
            title: '発注日',
            dataIndex: 'placeOrder|issued_date',
            sorter: true,
            render: (text, r) => dateFormat(r.place_order?.issued_date),
        },
        {
            title: '小計',
            dataIndex: 'placeOrder|subtotal',
            render: (text, r) => (
                <span style={{ fontWeight: 'bold' }}>{formatNumberComma(r.place_order?.subtotal)}</span>
            ),
            sorter: true,
        },
    ]

    const convertDatToCSV = () => {
        let csvData = []
        if (incomingData) {
            if (state.filters['display_shipment_type'] === DisplayShipmentTypeEnum.PRODUCT.value) {
                csvData.push(['入荷日', '案件番号', '仕入先', '品名', '仕入単価', '入荷数', '小計'])
                incomingData.forEach(data => {
                    csvData.push([
                        data.place_order.delivery_date,
                        data.place_order.project?.project_code,
                        data.place_order.supplier?.supplier_name,
                        data.title,
                        data.unit_cost,
                        data.quantity,
                        data.unit_cost * data.quantity,
                    ])
                })
            } else {
                csvData.push(['案件番号', '件名', '仕入先', '発注日', '小計'])
                incomingData.forEach(data => {
                    csvData.push([
                        data.project_code,
                        data.product_name,
                        data.place_order.supplier?.supplier_name,
                        data.place_order.issued_date,
                        data.place_order.subtotal,
                    ])
                })
            }
        }
        return csvData
    }

    useEffect(() => {
        if (!state.loading && state.data.length > 0) {
            let data = [...state.data]
            let firstElement = data.shift()
            if (typeof firstElement === 'number') {
                sumTotal = firstElement
                setIncomingData(data)
            } else {
                setIncomingData(state.data)
            }
        }
    }, [state.data])
    const [modalProductDetail, setModalProductDetail] = useState({
        productId: null,
        visible: false,
    })

    return (
        <>
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalProductDetail.visible}
                onCancel={() =>
                    setModalProductDetail({
                        productId: modalProductDetail.productId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <ProductDetailComponent inModal={true} id={modalProductDetail.productId} />
            </InsModal>
            <InsDataTable
                loading={state.loading ? { indicator: <InsLoader /> } : false}
                data={incomingData}
                columns={
                    state.filters['display_shipment_type'] === DisplayShipmentTypeEnum.PRODUCT.value
                        ? incomingProductColumns
                        : incomingProjectColumns
                }
                rowKey={record => record.id}
                onChange={(pagination, filter, sorter) => {
                    getShipments(state.pagination, state.filters, sorter)
                }}
                expandable={
                    state.filters['display_shipment_type'] === DisplayShipmentTypeEnum.PROJECT.value
                        ? {
                              expandedRowRender: record => {
                                  const columns = [
                                      {
                                          title: '',
                                          width: 32,
                                      },
                                      {
                                          title: '品名',
                                          dataIndex: 'title',
                                          key: 'title',
                                          sorter: (a, b) => a.title.length - b.title.length,
                                      },
                                      {
                                          title: '数量',
                                          dataIndex: 'quantity',
                                          key: 'quantity',
                                          sorter: (a, b) => a.quantity - b.quantity,
                                          width: 94,
                                          render: (text, r) => <span>{formatNumberComma(text)}</span>,
                                      },
                                      {
                                          title: '単価',
                                          dataIndex: 'unit_price',
                                          key: 'unit_price',
                                          sorter: (a, b) => a.unit_price - b.unit_price,
                                          width: 94,
                                          render: (text, r) => <span>{formatNumberComma(text)}</span>,
                                      },
                                      {
                                          title: '金額',
                                          dataIndex: 'amount',
                                          key: 'amount',
                                          sorter: (a, b) => a.amount - b.amount,
                                          width: 94,
                                          render: (text, r) => <span>{formatNumberComma(text)}</span>,
                                      },
                                  ]
                                  return (
                                      <div style={{ padding: 4 }}>
                                          <InsDataTable
                                              columns={columns}
                                              data={record.place_order.details.filter(d => d.title)}
                                              rowKey={e => e._id}
                                              pagination={false}
                                              onChange={(pagination, filter, sorter) => {}}
                                          />
                                      </div>
                                  )
                              },
                              rowExpandable: r => r.name !== null,
                              expandIconColumnIndex: 8,
                          }
                        : null
                }
            />
            <InsModal
                className="ins-detail-modal"
                width={760}
                visible={modalSupplierDetail.visible}
                onCancel={() =>
                    setModalSupplierDetail({
                        supplierId: modalSupplierDetail.supplierId,
                        visible: false,
                    })
                }
                footer={null}
            >
                <SupplierDetailComponent inModal={true} id={modalSupplierDetail.supplierId} />
            </InsModal>
            <InsFloatBar visible={true}>
                <Row justify="space-between" align="middle">
                    <Col style={{ paddingLeft: 16 }}>
                        <Row className="mt-4">
                            <InsButton
                                style={{ width: 94 }}
                                icon={<AssignmentIcon size={16} className="color-pantone-2422-c" />}
                                buttonStyle="default"
                                onClick={() => {
                                    convertDatToCSV()
                                }}
                            >
                                <CSVLink data={convertDatToCSV()} filename={'inashin-shipment.csv'} target="_blank">
                                    {t('CSV作成')}
                                </CSVLink>
                            </InsButton>
                        </Row>
                    </Col>
                    <Col style={{ paddingLeft: 16 }}>
                        <span style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <label className="mr-8">抽出された金額合計</label>
                            <span className="project-status-label summary-invoice-label">
                                {formatNumberComma(sumTotal)}
                            </span>
                        </span>
                    </Col>
                </Row>
            </InsFloatBar>
        </>
    )
}

const mapState = state => ({ state: state.shipment })
export default connect(mapState)(IncomingDataTable)

IncomingDataTable.propTypes = {
    state: PropTypes.shape(BaseStatePropShape),
    getShipments: PropTypes.func,
}
