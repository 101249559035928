import React from 'react'
import { Row, Col } from 'antd'
import InsButton from 'components/InsButton'
import { AddIcon, ArrowDropdownIcon, SearchIcon, UnfoldMoreIcon } from 'components/InsIcon'

const ButtonDemo = () => {
    return (
        <div className="page-container">
            <h1 className="page-title mb-32">InsButton</h1>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsButton buttonStyle="default">Default</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<SearchIcon size={24} />} buttonStyle="default">
                        Default
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonStyle="primary">Primary</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<SearchIcon size={24} />} buttonStyle="primary">
                        Primary
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonStyle="secondary">Secondary</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<SearchIcon size={24} />} buttonStyle="secondary">
                        Secondary
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonStyle="outline">Outline</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<SearchIcon size={24} />} buttonStyle="outline">
                        Outline
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonStyle="dark">Dark</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<SearchIcon size={24} />} buttonStyle="dark">
                        Dark
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonStyle="dashed">Dashed</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<SearchIcon size={24} />} buttonStyle="dashed">
                        Dashed
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonStyle="plain">Plain</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<UnfoldMoreIcon size={16} />} buttonStyle="plain">
                        Plain
                    </InsButton>
                </Col>
                <Col>
                    <InsButton disabled>Disabled</InsButton>
                </Col>
                <Col>
                    <InsButton icon={<SearchIcon size={24} />} disabled>
                        Disabled
                    </InsButton>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsButton buttonType="link" buttonStyle="default" linkTo="/">
                        Default link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" icon={<AddIcon size={24} />} buttonStyle="default" linkTo="/">
                        Default link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" buttonStyle="primary" linkTo="/">
                        Primary link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" icon={<AddIcon size={24} />} buttonStyle="primary" linkTo="/">
                        Primary link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" buttonStyle="secondary" linkTo="/">
                        Secondary link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" icon={<AddIcon size={24} />} buttonStyle="secondary" linkTo="/">
                        Secondary link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" buttonStyle="outline" linkTo="/">
                        Outline link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" icon={<AddIcon size={24} />} buttonStyle="outline" linkTo="/">
                        Outline link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" buttonStyle="dark" linkTo="/">
                        Dark link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" icon={<AddIcon size={24} />} buttonStyle="dark" linkTo="/">
                        Dark link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" buttonStyle="dashed" linkTo="/">
                        Dashed link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" icon={<AddIcon size={24} />} buttonStyle="dashed" linkTo="/">
                        Dashed link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" linkTo="/" buttonStyle="plain">
                        Plain link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" linkTo="/" icon={<UnfoldMoreIcon size={16} />} buttonStyle="plain">
                        Plain link
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" disabled linkTo="/">
                        Disabled
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonType="link" icon={<AddIcon size={24} />} disabled linkTo="/">
                        Disabled
                    </InsButton>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsButton buttonSize="small">Size: Small</InsButton>
                </Col>
                <Col>
                    <InsButton buttonSize="medium">Size: Medium</InsButton>
                </Col>
                <Col>
                    <InsButton buttonSize="big">Size: BIGGGGG</InsButton>
                </Col>
                <Col>
                    <InsButton buttonSize="small" icon={<SearchIcon size={16} />} buttonStyle="default">
                        Size: Small
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonSize="medium" icon={<SearchIcon size={24} />} buttonStyle="default">
                        Size: Medium
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonSize="big" icon={<SearchIcon size={32} />} buttonStyle="default">
                        Size: BIGGGGG
                    </InsButton>
                </Col>
            </Row>
            <Row gutter={[8, 16]} align="bottom">
                <Col>
                    <InsButton buttonStyle="default" suffixIcon={<ArrowDropdownIcon size={24} />}>
                        With suffix icon
                    </InsButton>
                </Col>
                <Col>
                    <InsButton
                        icon={<SearchIcon size={24} />}
                        buttonStyle="default"
                        suffixIcon={<ArrowDropdownIcon size={24} />}
                    >
                        With suffix icon
                    </InsButton>
                </Col>
                <Col>
                    <InsButton buttonStyle="primary" suffixIcon={<ArrowDropdownIcon size={24} />}>
                        With suffix icon
                    </InsButton>
                </Col>
                <Col>
                    <InsButton
                        icon={<SearchIcon size={24} />}
                        buttonStyle="primary"
                        suffixIcon={<ArrowDropdownIcon size={24} />}
                    >
                        With suffix icon
                    </InsButton>
                </Col>
            </Row>
        </div>
    )
}

export default ButtonDemo
