import React from 'react'
import PropTypes from 'prop-types'
import InsDataTable from 'components/datatable/InsDataTable'

const InvoiceListExpand = ({ record, columns }) => {
    return (
        <InsDataTable
            data={[record.project, ...record.summary_invoice_projects].filter(p => p)}
            columns={columns}
            bordered={true}
            tableStyle="compact"
            style={{ padding: 12 }}
        />
    )
}

export default InvoiceListExpand

InvoiceListExpand.propTypes = {
    record: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
}
