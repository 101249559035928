import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InsBackLink from 'components/InsBackLink'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'
import { Link, useParams } from 'react-router-dom'
import ShippingAddressDetailComponent from 'modules/master/shippingAddress/ShippingAddressDetailComponent'
import Routes from 'routes'

const ShippingAddressDetail = () => {
    const { id } = useParams()

    const deleteShippingAddress = async () => {
        await api.delete(APP_URLS.SHIPPING_ADDRESS_DETAIL.replace(':id', id))
        history.push(Routes.private.master.SHIPPING_ADDRESS_LIST.path)
    }

    return (
        <div className="page-container with-sidebar mb-64">
            <InsBackLink />
            <ShippingAddressDetailComponent id={id} onDelete={() => deleteShippingAddress()} />
        </div>
    )
}

export default ShippingAddressDetail

ShippingAddressDetail.propTypes = {
    match: PropTypes.object.isRequired,
}
