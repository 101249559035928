import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import { DeleteIcon, EditIcon, TruckIcon } from 'components/InsIcon'
import InsButton from 'components/InsButton'
import InsModal from 'components/InsModal'
import InsFloatBar from 'components/InsFloatBar'
import { getEnumText } from 'helpers/enum.helper'
import { DeliveryTime, DeliveryTime_DM_FLIGHT, PrintType, ProductType, ShippingType } from 'enums/tracking.enum'
import moment from 'moment'
import BusinessStatusEnum from 'enums/business_status.enum'
import { APP_URLS } from 'constants/url.constant'
import history from 'helpers/history.helper'
import Routes from 'routes'
import api from 'helpers/api.helper'

const TrackingPreview = ({ project, tracking, updateLink, response }) => {
    const { t } = useTranslation()
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const dateFormat = 'YYYY/MM/DD'

    const deleteTracking = async () => {
        await api.delete(APP_URLS.TRACKING_DETAIL.replace(':id', tracking.id))
        response()
        history.push(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
        setModalDeleteVisible(false)
    }

    const updateTrackingStatus = async status => {
        const data = await api.post(APP_URLS.TRACKING_UPDATE_STATUS, {
            status: status,
            tracking_id: tracking.id,
        })
        if (data) {
            response()
            history.push(Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id))
        }
    }

    return (
        <>
            <div className="p-24 page-tracking">
                <div className="tracking-head">
                    <h1 className="tracking-title">{t('送り状')}</h1>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <th>
                                        {t('送り状番号')}
                                        <span>:</span>
                                    </th>
                                    <td>{tracking.shipping_code}</td>
                                </tr>
                                <tr>
                                    <th>
                                        {t('請求先顧客コード')}
                                        <span>:</span>
                                    </th>
                                    <td>{tracking.invoice_code}</td>
                                </tr>
                                <tr>
                                    <th>
                                        {t('運賃管理番号')}
                                        <span>:</span>
                                    </th>
                                    <td>{tracking.management_number}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Row className="mb-16">
                    <Col span={12} className="pr-28">
                        <label className="dt-label">{'基本情報'}</label>
                        <table className="dt-table">
                            <tbody>
                                <tr>
                                    <th>{'送り状種類'}</th>
                                    <td>{getEnumText(ShippingType, tracking.shipping_type)}</td>
                                </tr>
                                <tr>
                                    <th>{'出荷予定日'}</th>
                                    <td>
                                        {tracking.shipping_plan_date
                                            ? moment(tracking.shipping_plan_date).format(dateFormat)
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{'お届け予定日'}</th>
                                    <td>
                                        {tracking.delivery_plan_date
                                            ? moment(tracking.delivery_plan_date).format(dateFormat)
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{'配達時間帯'}</th>
                                    <td>
                                        {getEnumText(
                                            tracking.shipping_type !== ShippingType.DM_FLIGHT.value
                                                ? DeliveryTime
                                                : DeliveryTime_DM_FLIGHT,
                                            tracking.delivery_time
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col span={12} className="pl-28">
                        <label className="dt-label">{'ご依頼主情報'}</label>
                        <table className="dt-table">
                            <tbody>
                                <tr>
                                    <th>{'お届け先名称'}</th>
                                    <td className="b15">{tracking.client_delivery_name}</td>
                                </tr>
                                <tr>
                                    <th>{'電話番号'}</th>
                                    <td>{tracking.client_tel}</td>
                                </tr>
                                <tr>
                                    <th>{'郵便番号'}</th>
                                    <td>{tracking.client_postcode}</td>
                                </tr>
                                <tr>
                                    <th>{'住所'}</th>
                                    <td className="pre">
                                        {tracking.client_address}
                                        {'\n'}
                                        {tracking.client_building_name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <div className="box-delivery mb-16">
                    <div className="box-delivery-head">
                        <TruckIcon className="color-pantone-2422-c mr-8" />
                        <label>{'納入先 1'}</label>
                    </div>
                    <Row>
                        <Col span={12}>
                            <table className="dt-table border-right">
                                <tbody>
                                    <tr>
                                        <th>{'お届け先名称'}</th>
                                        <td className="b13">{tracking.shipping1_delivery_name}</td>
                                    </tr>
                                    <tr>
                                        <th>{'電話番号'}</th>
                                        <td>{tracking.shipping1_tel}</td>
                                    </tr>
                                    <tr>
                                        <th>{'郵便番号'}</th>
                                        <td>{tracking.shipping1_postcode}</td>
                                    </tr>
                                    <tr>
                                        <th>{'住所'}</th>
                                        <td>{tracking.shipping1_address}</td>
                                    </tr>
                                    <tr>
                                        <th>{'建物名'}</th>
                                        <td>{tracking.shipping1_building_name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col span={12}>
                            <table className="dt-table">
                                <tbody>
                                    <tr>
                                        <th>{'品名1'}</th>
                                        <td>
                                            {tracking.shipping1_product1_type === ProductType.FREE_INPUT.value
                                                ? tracking.shipping1_product1_name
                                                : getEnumText(ProductType, tracking.shipping1_product1_type)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{'品名2'}</th>
                                        <td>
                                            {tracking.shipping1_product2_type === ProductType.FREE_INPUT.value
                                                ? tracking.shipping1_product2_name
                                                : getEnumText(ProductType, tracking.shipping1_product2_type)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{'出荷予定個数'}</th>
                                        <td>
                                            {tracking.shipping1_quantity}
                                            {`（${getEnumText(PrintType, tracking.shipping1_quantity_print_type)}）`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="h68">{'記事（メモ）'}</th>
                                        <td>{tracking.shipping1_note}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
                <div className="box-delivery">
                    <div className="box-delivery-head">
                        <TruckIcon className="color-pantone-2422-c mr-8" />
                        <label>{'納入先 2'}</label>
                    </div>
                    <Row>
                        <Col span={12}>
                            <table className="dt-table border-right">
                                <tbody>
                                    <tr>
                                        <th>{'お届け先名称'}</th>
                                        <td className="b13">{tracking.shipping2_delivery_name}</td>
                                    </tr>
                                    <tr>
                                        <th>{'電話番号'}</th>
                                        <td>{tracking.shipping2_tel}</td>
                                    </tr>
                                    <tr>
                                        <th>{'郵便番号'}</th>
                                        <td>{tracking.shipping2_postcode}</td>
                                    </tr>
                                    <tr>
                                        <th>{'住所'}</th>
                                        <td>{tracking.shipping2_address}</td>
                                    </tr>
                                    <tr>
                                        <th>{'建物名'}</th>
                                        <td>{tracking.shipping2_building_name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col span={12}>
                            <table className="dt-table">
                                <tbody>
                                    <tr>
                                        <th>{'品名1'}</th>
                                        <td>
                                            {tracking.shipping2_product1_type === ProductType.FREE_INPUT.value
                                                ? tracking.shipping2_product1_name
                                                : getEnumText(ProductType, tracking.shipping2_product1_type)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{'品名2'}</th>
                                        <td>
                                            {tracking.shipping2_product2_type === ProductType.FREE_INPUT.value
                                                ? tracking.shipping2_product2_name
                                                : getEnumText(ProductType, tracking.shipping2_product2_type)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{'出荷予定個数'}</th>
                                        <td>
                                            {tracking.shipping2_quantity}
                                            {`（${getEnumText(PrintType, tracking.shipping2_quantity_print_type)}）`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="h68">{'記事（メモ）'}</th>
                                        <td>{tracking.shipping2_note}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </div>
            <InsFloatBar visible={true} className="pl-16 pr-16">
                <Row justify="space-between" align="middle">
                    <Col>
                        <InsButton
                            disabled={tracking.status === BusinessStatusEnum.TRACKING_ISSUED.value}
                            icon={
                                <DeleteIcon
                                    size={20}
                                    className={
                                        tracking.status === BusinessStatusEnum.TRACKING_ISSUED.value
                                            ? ''
                                            : 'color-pantone-213-c'
                                    }
                                />
                            }
                            buttonStyle="default"
                            onClick={() => setModalDeleteVisible(true)}
                            width="112px"
                        >
                            {t('送り状削除')}
                        </InsButton>
                        <InsModal
                            title="削除を確認"
                            visible={modalDeleteVisible}
                            onCancel={() => setModalDeleteVisible(false)}
                            onOk={deleteTracking}
                        >
                            <p>{'このアイテムを削除しますか？'}</p>
                        </InsModal>
                        <InsButton
                            disabled={tracking.status === BusinessStatusEnum.TRACKING_ISSUED.value}
                            className="ml-8"
                            icon={
                                <EditIcon
                                    size={20}
                                    className={
                                        tracking.status === BusinessStatusEnum.TRACKING_ISSUED.value
                                            ? ''
                                            : 'color-pantone-2738-c'
                                    }
                                />
                            }
                            buttonStyle="default"
                            onClick={() => updateLink()}
                            width="112px"
                        >
                            {t('送り状編集')}
                        </InsButton>
                    </Col>
                    <Col className="ins-flex flex-middle btn-update-project-bottom">
                        <InsButton
                            className="fw-bold"
                            buttonStyle="primary"
                            buttonSize="big"
                            style={{ width: 140 }}
                            onClick={() => {
                                if (tracking.status !== BusinessStatusEnum.TRACKING_ISSUED.value) {
                                    updateTrackingStatus(BusinessStatusEnum.TRACKING_ISSUED.value)
                                }
                            }}
                        >
                            {tracking.status === BusinessStatusEnum.TRACKING_ISSUED.value
                                ? t('送り状 再発行')
                                : t('送り状 発行')}
                        </InsButton>
                    </Col>
                </Row>
            </InsFloatBar>
        </>
    )
}

export default TrackingPreview

TrackingPreview.propTypes = {
    project: PropTypes.object,
    tracking: PropTypes.object,
    updateLink: PropTypes.func,
    response: PropTypes.func,
}
